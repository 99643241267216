import React, { Component } from 'react';
// Translation Higher Order Component
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  // setLanguage,
  translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import {
  // Button,
  // Card,
  // CardBody,
  // CardFooter,
  // CardHeader,
  // Col,
  Form,
  // FormGroup,
  // Input,
  // Label,
  // Row,
} from 'reactstrap';
import {
  // ToastContainer,
  toast
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as myConstClass from './constant.js';
import {
  // EmailShareButton,
  FacebookShareButton,
  // FacebookIcon,
  // InstapaperShareButton,
  // LineShareButton,
  LinkedinShareButton,
  // LivejournalShareButton,
  // MailruShareButton,
  // OKShareButton,
  // PinterestShareButton,
  // PocketShareButton,
  // RedditShareButton,
  // TelegramShareButton,
  // TumblrShareButton,
  TwitterShareButton,
  // ViberShareButton,
  // VKShareButton,
  WhatsappShareButton,
  // WorkplaceShareButton,
} from "react-share";


// Do this two lines only when setting up the application
setTranslations({ en, ma });
setDefaultLanguage('en');

// If you want to remember selected language
setLanguageCookie();
class Footer extends Component {
  constructor(props) {

    super(props);
    this.state = {

      subscribeinfo: {
        first_name: '',
        last_name: '',
        message: '',
        status: '',
        email: '',
      },
      checkemaildetails: {
        email_message: '',
        email_status: '',
      },
      checkemaildetails: [],

    }
    this.key = 'en';
    this.backToTop = this.backToTop.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.validator = new SimpleReactValidator();
    this.validatorNewsletter = new SimpleReactValidator();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSignin = this.handleSignin.bind(this);

  }
  handleScroll(e) {
    var scrollTop = $(window).scrollTop();
    var scrollTrigger = window.innerHeight; // px
    if (scrollTop > scrollTrigger)
      $('#back-to-top').css('display', 'block');
    else
      $('#back-to-top').css('display', 'none');
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }
  subscribe() {
    alert('hi');
  }


  handleSubmit(e) {
    e.preventDefault();
    var user_id = localStorage.getItem('user_id');
    // console.log('this.refs', $('#pform_newsletter').serializeArray());
    if (this.validatorNewsletter.allValid()) {
      const formData = {};
      for (const field in this.refs) {
        formData[field] = this.refs[field].value;
      }
      // console.log('-->', formData);
      fetch(myConstClass.BASE_URL + `users/addSubscriber/` + user_id, {
        method: 'POST',
        body: JSON.stringify($('#pform_newsletter').serializeArray()),

      })
        .then(response => response.json())
        .then(response => {
          this.setState({ subscribeinfo: response })
          var message = this.state.subscribeinfo.message;
          var status = this.state.subscribeinfo.status;
          // console.log('hii' + status);
          if (status == 'true') {
            // $('#address').modal('hide');
            // $('#pform')[0].reset();

            //const numRows = this.state.subscribeinfo.length;

            this.state.subscribeinfo.first_name = this.state.subscribeinfo.first_name;
            //this.state.subscribeinfo.middle_name = this.state.subscribeinfo[0].middle_name;
            this.state.subscribeinfo.last_name = this.state.subscribeinfo.last_name;

            //this.state.subscribeinfo.phone = this.state.subscribeinfo[0].phone;
            this.state.subscribeinfo.email = this.state.subscribeinfo.email;
            //this.state.subscribeinfo.event_type = this.state.subscribeinfo[0].event_type;
            //this.state.subscribeinfo.comment = this.state.subscribeinfo[0].comment;

            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
          } else {
            toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
          }
          $('#subscribeModel').modal('hide');
          //console.log(status);
        })
        .catch(err => console.log(err))
    }
    else {

      this.validatorNewsletter.showMessages();
      // console.log('validations' + this.validatorNewsletter.showMessages());
      e.preventDefault();
      toast('Please fill all required fields.', { containerId: 'B', type: toast.TYPE.ERROR })


    }
  }

  handleSignin(e) {

    // console.log('this.props', this.props.history);
    e.preventDefault();
    $('#floader').css('display', 'inline');
    var user_id = localStorage.getItem('user_id');
    // console.log('this.refs', $('#mpform').serializeArray());
    // START - check email mobile in db
    var message = '';
    var status = '';
    fetch(myConstClass.BASE_URL + `users/checkEmailMobileVerifications/`, {
      method: 'POST',
      body: JSON.stringify($('#mpform').serializeArray()),
    })
      .then(response => response.json())
      .then(response => {
        let status_flag;
        if (response.status) {
          this.setState({ checkemaildetails: response })
          status_flag = 1;
          if (this.validator.fieldValid('email') && this.validator.fieldValid('mobile_no')) {
            const formData = {};
            for (const field in this.refs) {
              formData[field] = this.refs[field].value;
            }
            // console.log('uyu-->', formData);
            fetch(myConstClass.BASE_URL + `users/addFbUser/` + user_id, {
              method: 'POST',
              body: JSON.stringify($('#mpform').serializeArray()),
            })
              .then(response => response.json())
              .then(response => {
                // this.setState({subscribeinfo: response})
                var message = response.message;
                var status = response.status;
                if (status == 'true') {
                  $('#fbModel').modal('hide');
                  localStorage.removeItem('otp_email');
                  localStorage.setItem('otp_email', response.email);
                  setTimeout(this.props.history.push('verifyotp'), 10);
                  toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                } else {
                  toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                  $('#floader').css('display', 'none');
                }
              })
          } else {
            $('#floader').css('display', 'none');
            this.validator.showMessages();
            // console.log('validations' + this.validator.showMessages());
            e.preventDefault();
            toast('Please fill all required fields.', { containerId: 'B', type: toast.TYPE.ERROR })
            // toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR})
          }
        } else {
          toast(response.message, { containerId: 'B', type: toast.TYPE.ERROR })
          this.setState({ checkemaildetails: response })
          var status = response.status;
          status_flag = 0;
        }

      })
    // console.log(this.state.checkemaildetails.status)
    // END - check email mobile in db
    /*
          if (this.validator.fieldValid('email') && this.validator.fieldValid('mobile_no')) {
             const formData = {};

             for (const field in this.refs) {
                 formData[field] = this.refs[field].value;
             }
             console.log('uyu-->', formData);

             fetch(myConstClass.BASE_URL + `users/addFbUser/` + user_id, {
                 method: 'POST',
                 body: JSON.stringify($('#mpform').serializeArray()),

             })
                 .then(response => response.json())
                 .then(response => {
                     // this.setState({subscribeinfo: response})
                     var message = response.message;
                     var status = response.status;
                     //     console.log('hii'+status);
                     if (status == 'true') {
                         $('#fbModel').modal('hide');
                         localStorage.removeItem('otp_email');
                         localStorage.setItem('otp_email', response.email);
                         setTimeout(this.props.history.push('verifyotp'), 10);
                         toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                     } else {
                         toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                         $('#floader').css('display', 'none');
                     }
                 })
         } else {
             //alert('here');
             $('#floader').css('display', 'none');
             this.validator.showMessages();
             console.log('validations' + this.validator.showMessages());
             e.preventDefault();
             toast('Please fill all required fields.', { containerId: 'B', type: toast.TYPE.ERROR })
             // toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR})

         }
          */
  }

  backToTop() {
    $('html,body').animate({
      scrollTop: 0
    }, 700);

    $('#back-to-top').css('display', 'none');

    if ($('#back-to-top').length) {
      var scrollTrigger = window.innerHeight, // px
        backToTop = function() {
          var scrollTop = $(window).scrollTop();
          if (scrollTop > scrollTrigger) {

            $('#back-to-top').addClass('show');
          } else {
            $('#back-to-top').css('display', 'none');
            $('#back-to-top').removeClass('show');
            $('#back-to-top').addClass('hide');
          }
        };
      backToTop();
      $(window).on('scroll', function() {
        backToTop();
      });
      $('#back-to-top').on('click', function(e) {
        // e.preventDefault();
        $('html,body').animate({
          scrollTop: 0
        }, 700);
        $('#back-to-top').css('display', 'none');
      });


    }


  }

  showShareIcons(pathname) {
    const matchPatterns = /[mycart,eventDetails,address,checkout]/g
    if (pathname.match(matchPatterns)) return false
    else return true
  }

  render() {
    const { subscribeinfo } = this.state
    //alert(this.props.description);
    const { t } = this.props;

    const location = window?.location;

    return (
      <div className="mt-5">
        <div className="container-fluid bg-grey mt-4 FooterMarginForMobileView">
          <div className="row grey-text">
            <div className="col-md-1 py-1">
            </div>
            <div className="col-md-2 py-2">
              <Link className="grey-text d-block py-1" to="/events/Residential Study Courses/1">
                {t('residentialsc')}
              </Link>
              {/* <a href="#" className="grey-text d-block py-1">{t('residentialsc')}</a> */}
              <Link className="grey-text d-block py-1" to="/events/1-Day Workshops/2">
                {t('1day')}
              </Link>

              {/* <a href="#" className="grey-text d-block py-1">{t('1day')}</a> */}
              <Link className="grey-text d-block py-1" to="/machine-test/Machine Tests/3">
                {t('machine_tests')}
              </Link>
              {/* <a href="#" className="grey-text d-block py-1">{t('machine_tests')}</a> */}
              <Link className="grey-text d-block py-1" to="/events/Rational Poojas/4">
                {t('rational_poojas')}
              </Link>

              {/* <a href="#" className="grey-text d-block py-1">{t('rational_poojas')}</a> */}
              {/* <Link className="grey-text d-block py-1" to="/events/Free Events/5">
            {t('free_events')}
            </Link> */}
              <Link className="grey-text d-block py-1" to="/cycle-of-good-deeds">
                {t('Cycle of Good Deeds')}
              </Link>
              {/* <a href="#" className="grey-text d-block py-1">{t('free_events')}</a> */}
            </div>
            <div className="col-md-2 py-2">
              <Link className="grey-text d-block py-1" to="/product_type/kits/2">
                {t('kits')}
              </Link>
              <Link className="grey-text d-block py-1" to="/product_type/CDs/3">
                {t('cds')}
              </Link>
              <Link className="grey-text d-block py-1" to="/product_type/books/1">
                {t('books')}
              </Link>
              {/* <Link className="grey-text d-block py-1" to="/product_type/books/1">
        {t('cds')}
        </Link> */}
              {/* <a href="#" className="grey-text d-block py-1">{t('books')}</a> */}

              {/* <a href="#" className="grey-text d-block py-1">{t('cds')}</a> */}

              {/* <a href="#" className="grey-text d-block py-1">{t('kits')}</a> */}
              <Link className="grey-text d-block py-1" to="/product_type/experimental/4">
                {t('exp_products')}
              </Link>
              {/* <a href="#" className="grey-text d-block py-1">{t('exp_products')}</a> */}
              <Link className="grey-text d-block py-1" to="/product_type/ayurvedic/5">
                {t('ayur_products')}
              </Link>
              {/* <a href="#" className="grey-text d-block py-1">{t('ayur_products')}</a> */}
            </div>
            <div className="col-md-2 py-2">
              <a target="_blank" className="grey-text d-block py-1" href="https://mindgym.manashakti.org/">
                {t('Mind Gym')}
              </a>
              <a target="_blank" className="grey-text d-block py-1" href="https://play.google.com/store/apps/details?id=com.snt.manashakti&hl=en_IN">
                {t('Manatarang App')}
              </a>

              {/* <a className="grey-text d-block py-1" target="_blank" href="https://newwayphilosophy.blogspot.com/">{t('New Way Philosophy Blog')}</a> */}

              <a className="grey-text d-block py-1" target="_blank" href="https://onlineselftests.manashakti.org">{t('New Way Philosophy Blog')}</a>
              <a target="_blank" className="grey-text d-block py-1" href="https://www.youtube.com/channel/UC2LM6zmd2jA1Mw-E18P0coA">
                {t('You Tube Channel')}
              </a>
              <Link className="grey-text d-block py-1" to="/news/1">
                {t('home.News')}
              </Link>

            </div>
            <div className="col-md-2 py-2">

              <Link className="grey-text d-block py-1" to="/magazine">
                {t('magzines')}
              </Link>

              <Link className="grey-text d-block py-1" to="/photo-gallery">
                {t('gallary')}
              </Link>

              <Link className="grey-text d-block py-1" to="/download">
                {t('downloads')}
              </Link>

              <Link className="grey-text d-block py-1" to="/faqs">
                {t('faqs')}
              </Link>
              <Link className="grey-text d-block py-1" to="/e-shopping-help">
                {t('e_shopping_help')}
              </Link>

              {/* <a href="#" className="grey-text d-block py-1">{t('faqs')}</a> */}
              {/* <a href="#" className="grey-text d-block py-1">{t('e_shopping_help')}</a> */}



              {/* <a href="#" className="grey-text d-block py-1">{t('home.contact_us')}</a> */}
            </div>
            <div className="col-md-2 py-2">
              <Link className="grey-text d-block py-1" to="/about-us/6">
                {t('home.about_us')}
              </Link>

              <Link className="grey-text d-block py-1" to="/research">
                {t('home.research')}
              </Link>
              <Link className="grey-text d-block py-1" to="/event-calendar-grid">
                {t('home.Events Calendar')}
              </Link>
              {/* <a href="#" className="grey-text d-block py-1">{t('home.event_calendar')}</a> */}

              {/* <a href="#" className="grey-text d-block py-1">{t('feedback')}</a> */}
              <Link className="grey-text d-block py-1" to="/feedback" >{t('feedback')}</Link>

              <Link className="grey-text d-block py-1" to="/contact-us" >
                {t('home.contact_us_small')}
              </Link>
              {/* <a style={{cursor:'pointer'}} className="grey-text d-block py-1"  data-toggle="modal" data-target="#subscribeModel">{t('home.Newsletter')}</a> */}

            </div>
            <div className="col-md-1 py-1">
            </div>
          </div>
        </div>

        <div className="container-fluid bg-black">
          {/* <p className="text-center py-3 grey-text mb-0">

<a target="_blank" href="https://www.facebook.com/manashaktiresearchcentrelonavla"><i class="fab fa-facebook-f text-white m-2"></i></a>
<a target="_blank" href="https://twitter.com/manashakti?lang=en"><i className="fab fa-twitter text-white m-2"></i></a>
<a target="_blank" href="https://in.linkedin.com/in/manashakti-research-centre-a4230290">
<i className="fab fa-linkedin-in text-white m-2"></i>
</a>
<a target="_blank" href="https://www.youtube.com/channel/UC2LM6zmd2jA1Mw-E18P0coA">
<i className="fab fa-youtube text-white m-2"></i>
</a>
    </p> */}
          <p className="text-center py-3 grey-text mb-0">
            {t('footer_text')}
          </p>
        </div>

        {this.showShareIcons(location.pathname) && <div className="position-fixed text-center sticky-social-link z-index-1000 mt-xl-0 mt-lg-0 mt-5">
          <FacebookShareButton url={this.props.share_current_image_url} quote={this.props.description ? this.props.description : 'Manashakti helps people achieve tension-free success and peace of mind through scientific means and social welfare activities.'}>
            <i className="fab fa-facebook-f text-white m-2"></i>
          </FacebookShareButton><br />
          <TwitterShareButton url={this.props.share_current_image_url} title={this.props.description} className="share">
            <i className="fab fa-twitter text-white m-2"></i>
          </TwitterShareButton><br />
          <LinkedinShareButton url={this.props.share_current_image_url} title="Manashakti Research Centre" source="Manashakti Research Centre" summary={this.props.description ? this.props.description : 'Manashakti helps people achieve tension-free success and peace of mind through scientific means and social welfare activities.'} className="share">
            <i className="fab fa-linkedin-in text-white m-2"></i>
          </LinkedinShareButton><br />
          <WhatsappShareButton url={this.props.share_current_image_url} title={this.props.description} separator=" : " className="share">
            <i className="fab fa-whatsapp text-white m-2"></i>
          </WhatsappShareButton>
          <br />
        </div>}


        <a
          onClick={this.backToTop}
          id="back-to-top"
          title={t('home.BACK TO TOP')}
          className="btn btn-outline-brown font-weight-bolder"
          tabindex="-1">
          <i className="fa fa-arrow-alt-circle-up fa-2x"></i>
          <span>
            {/* {t('home.BACK TO TOP')} */}
          </span>
        </a>


        {/* <div className="modal fade" id="subscribeModel" tabindex="-1" role="dialog" aria-labelledby="subscribeModel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
            <div className="modal-content bg-light" style={{overflow:'auto'}}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{t('home.Newsletter')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <i className="far fa-times-circle"></i>
                  </button>
                </div>
                <Form action="" id="pform_newsletter" name="pform_newsletter" onSubmit={this.handleSubmit}  method="post" encType="multipart/form-data" className="form-horizontal">
                <div className="modal-body">


                    <div className="row">
                        <div className="form-group col col-md-4">
                            <label className="color-black font-weight-bolder">{t('home.Name')}<span className="color-red">*</span></label>
                            <input value={subscribeinfo.first_name} onChange={e => {this.setState({ subscribeinfo: {...subscribeinfo, first_name:e.target.value}})}} onBlur={() => this.validatorNewsletter.showMessageFor('first_name')} type="text" id="first_name" name="first_name" className="form-control" placeholder={t('home.Enter First Name')} />


                      {this.validatorNewsletter.message('first_name', this.state.subscribeinfo.first_name, 'required')}

                        </div>
                        <div className="form-group col col-md-4">
                            <label className="color-black font-weight-bolder">{t('home.Last Name')}<span className="color-red">*</span></label>

                            <input value={subscribeinfo.last_name} onBlur={() => this.validator.showMessageFor('last_name')}  onChange={e => {this.setState({ subscribeinfo: {...subscribeinfo, last_name:e.target.value}})}}  type="text" id="last_name" name="last_name" className="form-control" placeholder={t('home.Enter Last Name')} />
                            {this.validatorNewsletter.message('last_name', this.state.subscribeinfo.last_name, 'required')}
                        </div>


                    </div>
                    <div className="row">

                       <div className="form-group col col-md-4">
                           <label className="color-black font-weight-bolder">{t('home.Email')} <span className="color-red">*</span></label>
                           <input  value={subscribeinfo.email} onChange={e => {this.setState({ subscribeinfo: {...subscribeinfo, email:e.target.value}})}}  type="text" className="form-control"  id="email" name="email" placeholder={t('home.Email')} />
                           {this.validatorNewsletter.message('email', this.state.subscribeinfo.email, 'required')}
                       </div>
                   </div>

                </div>
                <div className="modal-footer">


                    <span data-dismiss="modal" aria-label="Close" style={{cursor:'pointer'}}>{t('home.Cancel')}</span>
                    <button type="submit" className="btn btn-outline-brown bg-white btn-sm pl-4 pr-4" style={{color:'black'}}>{t('home.Save')}</button>
                </div>
                </Form>
            </div>

        </div>
    </div> */}

        <div className="modal fade z-index--99999" id="fbModel" tabindex="-1" role="dialog" aria-labelledby="fbModel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
            <div className="modal-content bg-light" style={{ overflow: 'auto' }}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{t('home.SIGNIN')}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="far fa-times-circle"></i>
                </button>
              </div>
              <Form action="" id="mpform" onSubmit={this.handleSignin} method="post" encType="multipart/form-data" className="form-horizontal">
                <div className="modal-body">

                  {/* <div className="row">
                        <div className="col">
                            <p className="text-right color-red">* {t('home.Mandatory Fields')}</p>
                        </div>
                    </div> */}

                  <div className="row">

                    <div className="form-group col col-md-4">
                      <label className="color-black font-weight-bolder">{t('home.Email')} <span className="color-red">*</span></label>
                      <input value={subscribeinfo.email} onChange={e => { this.setState({ subscribeinfo: { ...subscribeinfo, email: e.target.value } }) }} type="text" className="form-control" id="email" name="email" placeholder={t('home.Email')} />
                      {this.validator.message('email', this.state.subscribeinfo.email, 'required')}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col col-md-4">
                      <label className="color-black font-weight-bolder">{t('home.Mobile Number')} <span className="color-red">*</span></label>
                      <input value={subscribeinfo.mobile_no} onChange={e => { this.setState({ subscribeinfo: { ...subscribeinfo, mobile_no: e.target.value } }) }} type="text" className="form-control" id="mobile_no" name="mobile_no" placeholder={t('home.Mobile Number')} />
                      {this.validator.message('mobile_no', this.state.subscribeinfo.mobile_no, 'required')}
                    </div>
                  </div>
                  <input type="hidden" className="form-control" id="postData" name="postData" />

                </div>
                <div className="modal-footer">


                  <span data-dismiss="modal" aria-label="Close" style={{ cursor: 'pointer' }}>{t('home.Cancel')}</span>
                  <button type="submit" className="btn btn-outline-brown bg-white btn-sm pl-4 pr-4" style={{ color: 'black' }}>{t('home.SIGNIN')}</button>
                  <img style={{ display: 'none' }} id="floader" name="floader" src={myConstClass.IMAGE_URL + 'loader.gif'} className="img-fluid" />
                </div>
              </Form>
            </div>

          </div>
        </div>


      </div>);
  }
}

Footer.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(Footer);
