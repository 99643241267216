import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backgroundEvent from './assets/images/machine-tests-bg.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import './assets/fontawesome/css/all.min.css';
import Header from './Header.js';
import Footer from './Footer.js';
import audio from './assets/images/audio.png';
import download from './assets/images/download.png';
import excel from './assets/images/excel.png';
import doc from './assets/images/doc.png';
import * as myConstClass from './constant.js';
import $ from 'jquery';
import { Base64 } from 'js-base64';
import { SRLWrapper } from "simple-react-lightbox";
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import {
    Row,
    Col,
    FormGroup,
    Label,
    Input
} from "reactstrap";

class Photogallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: '',
            eventdetails: {
                event_type: '',
                event_description: '',
            },
            arr_year: [],
            arr_event: [],
            guestinfo: {
                format_date: '',
                event_type: '',
                event_type_id: '',
                topic_id: '',
                sub_topic_id: '',
                event_type_name: '',
                event_id: '0',
                schedule_event_name: '',
                language_id: '',
                researchTitle: '',
                currentYear: '',
                topic_id: '',

            },
            flag: 0,
            quantityValue: '1',
            posts: [],
            arrMagazine: [],
            arr_gallery: [],
            // arr_gallery_copy: [],
            // selectLang: "All",
            eventinfo: [],
            languages: [],
            topic: [],
            eventTypes: [],
            scheduleEvent: [],
            cart: [],
            cartEvent: [],
            removeFromCart: [],
            eventdetails: [],
            month: [],


            types: []
        }
        this.applyFilters = this.applyFilters.bind(this);
        this.applyFiltersDesktop = this.applyFiltersDesktop.bind(this);
        this.applyFiltersMobile = this.applyFiltersMobile.bind(this);
        // this.isEnglishTitle = this.isEnglishTitle.bind(this);

        this.showMoreDesktop = this.showMoreDesktop.bind(this);
        this.showMoreMobile = this.showMoreMobile.bind(this);

    }

    showMoreDesktop() {
        
        var currentlimit = $('#limit').val();
        var newlimit = parseInt(currentlimit) + 10;
        this.state.limit = newlimit
        // $('#limit').val(newlimit);
        this.applyFilters($('#topic_id').val());
    }

    showMoreMobile() {
        
        var currentlimit = $('#limit').val();
        var newlimit = parseInt(currentlimit) + 10;
        this.state.limit = newlimit
        // $('#limit').val(newlimit);
        this.applyFilters($('#topic_id_m').val());
    }

    componentDidMount() {
        this.state.limit = 10;
        var current_language = getLanguage();
        localStorage.setItem('current_language', current_language);
        var currentTime = new Date();
        // var year = currentTime.getFullYear();
        // this.state.guestinfo.currentYear = year;
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }
        var user_id = localStorage.getItem('user_id');



        //alert(research);
        fetch(myConstClass.BASE_URL + 'audio/getAudioGalleryFrontEnd' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => {
                this.setState({ arr_gallery: response });
                // console.log('gallery: ', this.state.arr_gallery.length, this.state.arr_gallery);
                var total = this.state.arr_gallery.length;
                if (total < 1) {
                    $('#notAvailable').css('display', 'contents');
                    $('#showMoreBtn').removeClass('d-xl-block d-lg-block d-md-block');
                    $('#showMoreBtnM').removeClass('d-block');
                } else {
                    $('#notAvailable').css('display', 'none');
                }
                if (total > 0) {
                    var total_downloads = this.state.arr_gallery[0].total_downloads;
                    var limit = this.state.limit;
                    if (total_downloads <= limit) {
                        $('#showMoreBtn').removeClass('d-xl-block d-lg-block d-md-block');
                        $('#showMoreBtnM').removeClass('d-block');
                    } else {
                        $('#showMoreBtn').css('display', 'inline');
                        $('#showMoreBtnM').css('display', 'inline');
                    }
                }
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

            })

            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ topic: response }))
            .catch(err => console.log(err))


        fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ arrMagazine: response }))
            .catch(err => console.log(err))

            .catch(err => console.log(err))


    }

    // isEnglishTitle(title) {
    //     return /[a-zA-Z]/.test(title);
    // }

    componentDidUpdate(prevProps, preState) {

        const { flag } = this.state;
        var current_language = localStorage.getItem('current_language');

        if (current_language != getLanguage() && flag != 1 && current_language != null) {
            //alert(current_language);
            localStorage.removeItem('current_language');
            localStorage.setItem('current_language', getLanguage());
            this.setState({ flag: 1 });
        }
        if (getLanguage() == 'ma' && flag == 1) {
            this.setState({ flag: 0 })
            var user_id = localStorage.getItem('user_id');
            const answer_array = this.props.location.pathname.split('/');

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrMagazine: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))


            var topic_id = $('#topic_id').val();
            if (topic_id == '' || topic_id == undefined) {
                topic_id = 0;
            }
            var year = $('#year').val();
            if (year == '' || year == undefined) {
                year = 0;
            }
            var event_id = $('#event_id').val();
            if (event_id == '' || event_id == undefined) {
                event_id = 0;
            }
            //  alert(filetype);
            var limit = this.state.limit;

            fetch(myConstClass.BASE_URL + 'audio/getAudioGalleryFrontEnd' + '/' + getLanguage() + '/' + limit + '/' + topic_id + '/' + year + '/' + event_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_gallery: response })
                    var total = this.state.arr_gallery.length;
                    if (total < 1) {
                        $('#notAvailable').css('display', 'contents');
                        $('#showMoreBtn').removeClass('d-xl-block d-lg-block d-md-block');
                        $('#showMoreBtnM').removeClass('d-block');
                    } else {
                        $('#notAvailable').css('display', 'none');
                    }
                    if (total > 0) {
                        var total_downloads = this.state.arr_gallery[0].total_downloads;
                        var limit = this.state.limit;
                        //  alert(total_downloads);
                        if (total_downloads <= limit) {
                            $('#showMoreBtn').removeClass('d-xl-block d-lg-block d-md-block');
                            $('#showMoreBtnM').removeClass('d-block');
                        } else {
                            $('#showMoreBtn').css('display', 'inline')
                            $('#showMoreBtnM').css('display', 'inline');
                        }
                    }
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

                })
            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))


        }
        if (getLanguage() == 'en' && (flag == 1)) {
            this.setState({ flag: 0 })
            var user_id = localStorage.getItem('user_id');
            const answer_array = this.props.location.pathname.split('/');
            var research = answer_array['2'];


            fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrMagazine: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))

            var topic_id = $('#topic_id').val();
            if (topic_id == '' || topic_id == undefined) {
                topic_id = 0;
            }
            var year = $('#year').val();
            if (year == '' || year == undefined) {
                year = 0;
            }
            var event_id = $('#event_id').val();
            if (event_id == '' || event_id == undefined) {
                event_id = 0;
            }
            //  alert(filetype);
            var limit = this.state.limit;

            fetch(myConstClass.BASE_URL + 'audio/getAudioGalleryFrontEnd' + '/' + getLanguage() + '/' + limit + '/' + topic_id + '/' + year + '/' + event_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_gallery: response })
                    var total = this.state.arr_gallery.length;
                    if (total < 1) {
                        $('#notAvailable').css('display', 'contents');
                        $('#showMoreBtn').removeClass('d-xl-block d-lg-block d-md-block');
                        $('#showMoreBtnM').removeClass('d-block');
                    } else {
                        $('#notAvailable').css('display', 'none');
                    }
                    if (total > 0) {
                        var total_downloads = this.state.arr_gallery[0].total_downloads;
                        var limit = this.state.limit;
                        //  alert(total_downloads);
                        if (total_downloads <= limit) {
                            $('#showMoreBtn').removeClass('d-xl-block d-lg-block d-md-block');
                            $('#showMoreBtnM').removeClass('d-block');
                        } else {
                            $('#showMoreBtn').css('display', 'inline');
                            $('#showMoreBtnM').css('display', 'inline');
                        }
                    }
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

                })

            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))


            //  fetch(myConstClass.BASE_URL+'photo/getEventList'+'/'+getLanguage())
            //     .then(response => response.json())
            //     .then(response =>this.setState({arr_event: response}))
            //     .catch(err => console.log(err))


        }

        // select language code
        // if (preState.selectLang !== this.state.selectLang) {
        //     let filterData;

        //     if (this.state.selectLang === "Hindi") {
        //         filterData = this.state.arr_gallery_copy.filter(
        //             (el) => !this.isEnglishTitle(el.title)
        //         );
        //     } else if (this.state.selectLang === "English") {
        //         filterData = this.state.arr_gallery_copy.filter(
        //             (el) => this.isEnglishTitle(el.title)
        //         );
        //     } else {
        //         filterData = this.state.arr_gallery_copy;
        //     }
        //     this.setState({
        //         arr_gallery: filterData
        //     });
        // }
    }

    applyFiltersDesktop = (e) => {
        e.preventDefault();
        console.log('desktop: ', $('#topic_id').val(), $('#language_id').val());
        this.applyFilters($('#topic_id').val());
        // this.setState({ selectLang: $('#language_id').val() });
    }

    applyFiltersMobile = (e) => {
        e.preventDefault();
        this.applyFilters($('#topic_id_m').val());
    };


    applyFilters(topic_id) {

        const answer_array = this.props.location.pathname.split('/');

        var year = $('#year').val();
        if (year == '' || year == undefined) {
            year = 0;
        }
        var event_id = $('#event_id').val();
        if (event_id == '' || event_id == undefined) {
            event_id = 0;
        }
        //  alert(filetype);
        var topic_id = $('#topic_id').val();
        if (topic_id > 0) {

        } else {
            var topic_id = $('#topic_id_m').val();
        }
        var limit = this.state.limit;

        fetch(myConstClass.BASE_URL + 'audio/getAudioGalleryFrontEnd' + '/' + getLanguage() + '/' + limit + '/' + topic_id + '/' + year + '/' + event_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ arr_gallery: response });
                var total = this.state.arr_gallery.length;
                if (total < 1) {
                    $('#notAvailable').css('display', 'contents');
                    $('#showMoreBtn').removeClass('d-xl-block d-lg-block d-md-block');
                    $('#showMoreBtnM').removeClass('d-block');
                } else {
                    $('#notAvailable').css('display', 'none');
                }
                if (total > 0) {
                    var total_downloads = this.state.arr_gallery[0].total_downloads;
                    var limit = this.state.limit;
                    //alert(total_downloads);
                    if (total_downloads <= limit) {
                        $('#showMoreBtn').removeClass('d-xl-block d-lg-block d-md-block');
                        $('#showMoreBtnM').removeClass('d-block');
                    } else {
                        $('#showMoreBtn').css('display', 'inline');
                        $('#showMoreBtnM').css('display', 'inline');
                    }
                }
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

            })



    }

    renderType = ({ id, title }) => <div className="row">
        <div className="col">
            <div className="col pt-3">
                <h5 className="font-weight-bold">{title}</h5>
            </div>
        </div>
    </div>

    renderGuest = ({ type_id, title, price, quantity }) =>
        <div><div className="row"><div className="col-md-3 mb-3">
            <div className="col border text-center">
                <div className=" py-3">
                    <img src={require("./assets/images/product1.png")} />
                    <div className="my-2">
                        <span className="mr-2">
                            <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                        </span>
                    </div>
                    <p className="font-weight-bold mb-1">{title}</p>
                    <p className="mb-1">Donation Contribution</p>
                    <p className="font-weight-bolder">INR. {price}/-</p>
                    <div className="qty mb-3">
                        <span>Qty-</span>
                        <span className="minus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-minus"></i>
                            </span>
                        </span>
                        <input type="number" className="count" name="qty" value={quantity} />
                        <span className="plus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-plus"></i>
                            </span>
                        </span>
                    </div>
                    <button className="btn btn-brown btn-sm">ADD TO CART</button>
                </div>
            </div></div></div></div>

    render() {
        const { arr_event, month, arr_year, arrMagazine, arr_gallery, cartEvent, eventdetails, scheduleEvent, eventTypes, posts, types, languages, topic, guestinfo } = this.state
        const { t } = this.props;
        return (
            <div className="App">
                <div>
                    <ToastContainer />
                </div>
                <Header />


                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-9 ">
                            <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb" id="breadcrumb">
                                    <ol class="breadcrumb bg-white mb-0 ml-n2 pb-0  ">
                                        <li class="breadcrumb-item h5 pl-2 ">
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li class="breadcrumb-item h5 pl-2 "><a href="#">{t('home.Gallery')}</a></li>
                                        <li class="breadcrumb-item active h5 d-xl-inline d-lg-inline d-md-inline d-none" aria-current="page">
                                            <a href="#">{t('home.Audio Gallery')}</a>
                                        </li>
                                        <li class="breadcrumb-item active h5 d-xl-none d-lg-none d-md-none d-inline" aria-current="page">
                                            <a href="#">Audio</a>
                                        </li>
                                    </ol>

                                    <div className="d-xl-block d-lg-block d-md-block d-none">

                                        <Link class="btn btn-link orangeColor font-weight-bold" to="/photo-gallery">{t('home.PHOTO GALLERY')}</Link>


                                        <Link class="btn btn-link orangeColor font-weight-bold" to="/video-gallery">{t('home.VIDEO GALLERY')}</Link>


                                        <Link class="text-dark ml-2 pl-1 h5 font-weight-bold" to="/audio-gallery"><b>{t('home.AUDIO GALLERY')}</b></Link>

                                        <Link class="btn btn-link orangeColor font-weight-bold" to="/wallpaper-gallery">
                                            {t('home.WALLPAPER GALLERY')}
                                        </Link>

                                    </div>
                                    <div className="d-xl-none d-lg-none d-md-none d-block">

                                        <Link class="btn btn-link orangeColor font-weight-bold" to="/photo-gallery">PHOTO</Link>


                                        <Link class="btn btn-link orangeColor font-weight-bold" to="/video-gallery">VIDEO</Link>


                                        <Link class="text-dark ml-2 pl-1 h5 " to="/audio-gallery">
                                            <strong>
                                                AUDIO
                                            </strong>
                                        </Link>

                                        <Link class="btn btn-link orangeColor font-weight-bold" to="/wallpaper-gallery">
                                            WALLPAPER
                                        </Link>

                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="row" id="filters123">
                        <div class="col-xl-9 col-lg-9 col-md-9">
                            <Row className="py-2 bg-light">
                                <Col xl="3" lg="3" md="3" className="align-self-center">
                                    {t('home.Select_filters_to_refine_the_results_below')}:
                                </Col>

                                {/* Desktop Design */}
                                <Col xl="5" lg="5" md="5" className="d-xl-flex d-lg-flex d-md-flex d-none">
                                    <div className="form-group">
                                        <label for="">{t('home.Topic')}</label>
                                        <select id="topic_id" name="topic_id" class="form-control" placeholder={t('home.Topic')}>
                                            <option value="">{t('home.Select Topic')}</option>
                                            {
                                                topic.map((info, ii) => {
                                                    if (info.value == guestinfo.topic_id) {
                                                        return (
                                                            <option value={info.value} selected="selected">{info.label}</option>
                                                        )
                                                    } else {
                                                        return (
                                                            <option value={info.value}>{info.label}</option>
                                                        )
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                </Col>

                                {/* select language code */}
                                {/* <Col xl="4" lg="4" md="4" className="d-xl-flex d-lg-flex d-none">
                                    <div className="form-group">
                                        <label>Language</label>
                                        <select id="language_id" name="language_id" className="form-control form-control-sm">
                                            <option>Select Language</option>
                                            <option>Hindi</option>
                                            <option>English</option>
                                        </select>
                                    </div>
                                </Col> */}

                                <Col xl="3" lg="3" md="3" className="d-xl-flex d-lg-flex d-md-flex d-none">
                                    <div class="form-group mt-4">
                                        <label for="exampleFormControlSelect1" style={{ visibility: 'hidden' }}>{t('home.Event')}</label>
                                        <button type="button" class="btn btn-outline-brown " onClick={this.applyFiltersDesktop}>{t('home.APPLY FILTERS')}</button>
                                    </div>
                                    </Col>

                                {/* Mobile Design */}
                                <Col xs="12" sm="12" className="d-xl-none d-lg-none d-md-none d-flex">
                                    <FormGroup className="w-100">
                                        <Label for="">{t('home.Topic')}</Label>
                                        <Input type="select" id="topic_id_m" name="topic_id_m" placeholder={t('home.Topic')} onChange={this.topicChange} bsSize="sm" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                            <option value="">{t('home.Select Topic')}</option>
                                            {topic.map((info, i) => {
                                                if (info.value == guestinfo.topic_id) {
                                                    return (
                                                        <option value={info.value} selected="selected">{info.label}</option>
                                                    )
                                                } else {
                                                    return (
                                                        <option value={info.value}>{info.label}</option>
                                                    )
                                                }

                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col xl="3" lg="3" md="3" className="d-xl-none d-lg-none d-md-none d-flex">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1" style={{ visibility: 'hidden' }}>{t('home.Event')}</label>
                                        <button type="button" class="btn btn-outline-brown btn-sm" onClick={this.applyFiltersMobile}>{t('home.APPLY FILTERS')}  </button>
                                    </div>
                                </Col>
                            </Row>


                            <Row className="my-3">
                                <Col className="d-xl-flex d-lg-flex d-md-flex d-none" >
                                    {$("#topic_id").val() !== '' ? 'Topic : ' + $("#topic_id option:selected").text() : ''}
                                </Col>
                                <Col className="d-xl-none d-lg-none d-md-none d-flex" >
                                    {$("#topic_id_m").val() !== '' ? 'Topic : ' + $("#topic_id_m option:selected").text() : ''}
                                </Col>
                            </Row>
                            <div class="row site_map">
                                <ul class="list-unstyled" style={{ width: '100%' }}>
                                    {arr_gallery.map((gallery, i) => {
                                        if (i == 0) {
                                            return (
                                                <li class="media bg-light border border-warning px-2 mt-2 pt-3">
                                                    <div class="row audioRow">
                                                        <div class="col-lg-1">
                                                            <img src={audio} class="mr-3" alt="..." />
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <h6 class="mt-0 mb-1 audioTitle" ><b>{gallery.title}</b>
                                                                <p class="my_text pt-2">
                                                                    {ReactHtmlParser(gallery.description)}
                                                                </p>
                                                            </h6>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <audio controls class="audioResponsive">
                                                                <source src={gallery.audio_url} type="audio/ogg" />
                                                                <source src={gallery.audio_url} type="audio/mpeg" />
                                                            </audio>
                                                        </div>
                                                        <div class="col-lg-1">
                                                            {/* <a href={gallery.audio_url} target="_blank" ><img src={download} class="mx-2" alt="..." /></a> */}
                                                            {/* <a type="audio/mpeg3"   href={gallery.audio_url}    style={{cursor:'pointer'}} download>
                                    
                                                            </a> */}
                                                        </div>
                                                        <div class="col-lg-2">
                                                            {/* <button class="btn btn-sm px-1 mt-3  btn-outline-brown">
                                                                {t('home.KNOW MORE')}
                                                                </button> */}
                                                            <a target="_blank" href={gallery.know_more_url} class="audioKnowMore">
                                                                <button class="btn btn-sm px-1 mt-3 mb-2  btn-outline-brown ">
                                                                    {t('home.KNOW MORE')}
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        } else {
                                            return (
                                                <li class="media  border border-dark px-2 mt-2 pt-3 ">
                                                    <div class="row audioRow">
                                                        <div class="col-lg-1">
                                                            <img src={audio} class="mr-3" alt="..." />
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <h6 class="mt-0 mb-1 audioTitle" ><b>{gallery.title}</b>
                                                                <p class="my_text pt-2">
                                                                    {ReactHtmlParser(gallery.description)}
                                                                </p>
                                                            </h6>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <audio controls class="audioResponsive">
                                                                <source src={gallery.audio_url} type="audio/ogg" />
                                                                <source src={gallery.audio_url} type="audio/mpeg" />
                                                            </audio>
                                                        </div>
                                                        <div class="col-lg-1">
                                                            {/* <a href={gallery.audio_url} target="_blank" ><img src={download} class="mx-2" alt="..." /></a> */}
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <a target="_blank" href={gallery.know_more_url} class="audioKnowMore">
                                                                <button class="btn btn-sm px-1 mt-3 mb-2  btn-outline-brown">
                                                                    {t('home.KNOW MORE')}
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        }

                                    })
                                    }






                                </ul>
                            </div>






                            <div class="row pt-3 pb-3">
                                <div className="col-xl-5">

                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-2 col-12">
                                    <button type="button" id="showMoreBtn" className="btn btn-outline-brown m-auto d-xl-block d-lg-block d-md-block d-none" onClick={this.showMoreDesktop}>{t('home.Show More')}</button>
                                    <button type="button" id="showMoreBtnM" className="btn btn-outline-brown m-auto d-xl-none d-lg-none d-md-none d-block" onClick={this.showMoreMobile}>{t('home.Show More')}</button>
                                    <input type="hidden" name="limit" id="limit" value={this.state.limit} />
                                    <p id="notAvailable" style={{ display: 'none', marginLeft: '50%' }}>
                                        {t('home.Not available')}
                                    </p>

                                </div>
                            </div>
                        </div>

                        {/* Mobile Design Start */}
                        <Col sm="8" xs="8" className="d-xl-none d-lg-none d-md-none d-flex">
                            <FormGroup>
                                <Label for="">{t('home.Topic')}</Label>

                                <Input type="select" data-dropup-auto="false" id="topic_id_m" name="topic_id_m" bsSize="sm" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                    <option value="">{t('home.All')}</option>
                                    {topic.map((info, ii) => {
                                        if (guestinfo.topic_id == info.value) {
                                            return (
                                                <option className="selectOrange" value={info.value} selected="selected">{info.label}</option>
                                            )
                                        } else {
                                            return (
                                                <option className="selectOrange" value={info.value}>{info.label}</option>
                                            )
                                        }
                                    })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm="4" xs="4" className="d-xl-none d-lg-none d-flex">
                            <FormGroup>
                                <Label for="">{t('home.Language')}</Label>
                                <Input type="select" id="language_id" name="language_id_m" bsSize="sm" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                    <option value="">{t('home.All')}</option>
                                    {languages.map((info, ii) => {
                                        if (info.value != 4) {
                                            if (guestinfo.language_id == info.value) {
                                                return (
                                                    <option value={info.value} selected="selected">{info.label}</option>
                                                )
                                            } else {
                                                return (
                                                    <option value={info.value}>{info.label}</option>
                                                )
                                            }
                                        }

                                    })


                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm="12" xs="12" className="d-xl-none d-lg-none d-flex">
                            <div className="form-group">
                                <button onClick={this.applyFilters} className="btn btn-sm btn btn-outline-brown btn-block ">
                                    {t('home.APPLY FILTERS')}
                                </button>
                            </div>
                        </Col>
                        {/* Mobile Design End */}

                        <div class="col">
                            <div class="pl-2 ">
                                {arrMagazine.map((cell, i) => {
                                    if (cell.magazine_id == 2) {
                                        return (
                                            <div class="col py-2 border border-warning bg-white box-shadow mb-3">

                                                <div class="row d-flex">
                                                    <div class="col-12">
                                                        <strong>
                                                            {cell.magazine_title}
                                                        </strong>
                                                        <p>
                                                            {ReactHtmlParser(cell.description)}

                                                        </p>
                                                        <p>
                                                            <strong>{t('home.Language')}:</strong>

                                                            {cell.language}
                                                        </p>
                                                        <p>

                                                            {cell.images_array.map((info, ii) => {
                                                                if (ii == 0) {
                                                                    if (info.issue_id > 0 && info.issue_id != undefined) {
                                                                        return (
                                                                            <Link to={'/magazine_details/' + info.magazine_id + '/' + info.issue_id}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>

                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <Link to={'/magazine'}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>

                                                                        )
                                                                    }
                                                                } else {
                                                                    return (
                                                                        <Link to={'/magazine'}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>
                                                                    )
                                                                }
                                                            }
                                                            )}



                                                            <Link class="btn btn-brown btn-block btn-sm mb-2 magazineImageButtonRight" to={'/magazine-subscription/' + cell.magazine_id}>{t('home.SUBSCRIBE')}</Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div class="col py-2 border border-warning bg-white box-shadow mb-3">

                                                <div class="row d-flex">
                                                    <div class="col-12">
                                                        <strong>
                                                            {cell.magazine_title}
                                                        </strong>
                                                        <p>
                                                            {ReactHtmlParser(cell.description)}

                                                        </p>
                                                        <p>
                                                            <strong>{t('home.Language')}:</strong>

                                                            {cell.language}
                                                        </p>
                                                        <p>

                                                            {cell.images_array.map((info, ii) => {
                                                                if (ii == 0) {
                                                                    if (info.product_id > 0) {
                                                                        return (
                                                                            <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />

                                                                                <p >
                                                                                    <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight btn-block" to={'/products_details/' + info.title + '/' + info.product_id}>{t('home.BUY NOW')}</Link>
                                                                                </p>
                                                                            </Link>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <Link to={'/products'}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                                <p >
                                                                                    <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight btn-block" to={'/products'}>{t('home.BUY NOW')}</Link>
                                                                                </p>
                                                                            </Link>
                                                                        )
                                                                    }

                                                                } else {
                                                                    if (info.product_id > 0) {
                                                                        return (
                                                                            <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <Link to={'/products'}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>
                                                                        )
                                                                    }
                                                                }
                                                            }
                                                            )}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

Photogallery.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Photogallery);

