import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backgroundEvent from './assets/images/machine-tests-bg.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import './assets/fontawesome/css/all.min.css';
import Header from './Header.js';
import Footer from './Footer.js';
import pdf from './assets/images/pdf.png';
import image from './assets/images/jpg.png';
import excel from './assets/images/excel.png';
import doc from './assets/images/doc.png';
import * as myConstClass from './constant.js';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import $ from 'jquery';
import { Base64 } from 'js-base64';
import { SRLWrapper } from "simple-react-lightbox";
import download from './assets/images/download.png';

import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


class Wallpapergallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            images: [],
            limit: '',
            eventdetails: {
                event_type: '',
                event_description: '',
            },
            arr_year: [],
            arr_event: [],
            guestinfo: {
                format_date: '',
                event_type: '',
                event_type_id: '',
                topic_id: '',
                sub_topic_id: '',
                event_type_name: '',
                event_id: '0',
                schedule_event_name: '',
                language_id: '',
                researchTitle: '',
                currentYear: '',
                topic_id: '',

            },
            flag: 0,
            quantityValue: '1',
            posts: [],
            arrMagazine: [],
            arr_gallery: [],
            eventinfo: [],
            languages: [],
            topic: [],
            eventTypes: [],
            scheduleEvent: [],
            cart: [],
            cartEvent: [],
            removeFromCart: [],
            eventdetails: [],
            month: [],


            types: []
        }
        this.applyFilters = this.applyFilters.bind(this);
        this.showMore = this.showMore.bind(this);
        this.openGallery = this.openGallery.bind(this);
        this.downloadWallpaper = this.downloadWallpaper.bind(this);
    }
    openGallery(e) {
        this.setState({ isOpen: true });
        // var id = e.target.id;
        this.state.photoIndex = e.target.id;
    }

    showMore(e) {
        e.preventDefault();
        var currentlimit = $('#limit').val();
        var newlimit = parseInt(currentlimit) + 9;
        this.state.limit = newlimit
        // $('#limit').val(newlimit);
        this.applyFilters(e);
    }

    componentDidMount() {
        this.state.limit = 9;
        var current_language = getLanguage();
        localStorage.setItem('current_language', current_language);
        var currentTime = new Date();
        // var year = currentTime.getFullYear();
        fetch(myConstClass.BASE_URL + 'wallpaper/getImages' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ images: response }))
            .catch(err => console.log(err))
        // this.state.guestinfo.currentYear = year;
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }
        var user_id = localStorage.getItem('user_id');



        //alert(research);
        fetch(myConstClass.BASE_URL + 'wallpaper/getPhotoGalleryFrontEnd' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => {
                this.setState({ arr_gallery: response })
                var total = this.state.arr_gallery.length;
                if (total < 1) {
                    $('#notAvailable').css('display', 'inline');
                    $('#showMoreBtn').css('display', 'none');
                } else {
                    $('#notAvailable').css('display', 'none');
                }
                if (total > 0) {
                    var total_downloads = this.state.arr_gallery[0].total_downloads;
                    var limit = this.state.limit;
                    //  alert(total_downloads);
                    if (total_downloads <= limit) {
                        $('#showMoreBtn').css('display', 'none');
                    } else {
                        $('#showMoreBtn').css('display', 'inline');
                    }
                }
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

            })

            .catch(err => console.log(err))



        fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ arrMagazine: response }))
            .catch(err => console.log(err))

            .catch(err => console.log(err))


    }

    componentDidUpdate(prevProps) {

        const { flag } = this.state;
        var current_language = localStorage.getItem('current_language');

        if (current_language != getLanguage() && flag != 1 && current_language != null) {
            //alert(current_language);
            localStorage.removeItem('current_language');
            localStorage.setItem('current_language', getLanguage());
            this.setState({ flag: 1 });
        }
        if (getLanguage() == 'ma' && flag == 1) {
            this.setState({ flag: 0 })
            var user_id = localStorage.getItem('user_id');
            const answer_array = this.props.location.pathname.split('/');

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrMagazine: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))


            var topic_id = $('#topic_id').val();
            if (topic_id == '' || topic_id == undefined) {
                topic_id = 0;
            }
            var year = $('#year').val();
            if (year == '' || year == undefined) {
                year = 0;
            }
            var event_id = $('#event_id').val();
            if (event_id == '' || event_id == undefined) {
                event_id = 0;
            }
            //  alert(filetype);
            var limit = this.state.limit;

            fetch(myConstClass.BASE_URL + 'wallpaper/getImages' + '/' + getLanguage() + '/' + limit + '/' + topic_id + '/' + year + '/' + event_id)
                .then(response => response.json())
                .then(response => this.setState({ images: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'wallpaper/getPhotoGalleryFrontEnd' + '/' + getLanguage() + '/' + limit + '/' + topic_id + '/' + year + '/' + event_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_gallery: response })
                    var total = this.state.arr_gallery.length;
                    if (total < 1) {
                        $('#notAvailable').css('display', 'inline');
                    } else {
                        $('#notAvailable').css('display', 'none');
                    }
                    if (total > 0) {
                        var total_downloads = this.state.arr_gallery[0].total_downloads;
                        var limit = this.state.limit;
                        //  alert(total_downloads);
                        if (total_downloads <= limit) {
                            $('#showMoreBtn').css('display', 'none')
                        } else {
                            $('#showMoreBtn').css('display', 'inline')
                        }
                    }
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

                })
            // fetch(myConstClass.BASE_URL+'getAllTopics'+'/'+getLanguage())
            // .then(response => response.json())
            // .then(response =>this.setState({topic: response}))
            // .catch(err => console.log(err))


            //  fetch(myConstClass.BASE_URL+'photo/getEventList'+'/'+getLanguage())
            //     .then(response => response.json())
            //     .then(response =>this.setState({arr_event: response}))
            //     .catch(err => console.log(err))


        }
        if (getLanguage() == 'en' && (flag == 1)) {
            this.setState({ flag: 0 })
            var user_id = localStorage.getItem('user_id');
            const answer_array = this.props.location.pathname.split('/');
            var research = answer_array['2'];


            fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrMagazine: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))

            var topic_id = $('#topic_id').val();
            if (topic_id == '' || topic_id == undefined) {
                topic_id = 0;
            }
            var year = $('#year').val();
            if (year == '' || year == undefined) {
                year = 0;
            }
            var event_id = $('#event_id').val();
            if (event_id == '' || event_id == undefined) {
                event_id = 0;
            }
            //  alert(filetype);
            var limit = this.state.limit;

            fetch(myConstClass.BASE_URL + 'wallpaper/getImages' + '/' + getLanguage() + '/' + limit + '/' + topic_id + '/' + year + '/' + event_id)
                .then(response => response.json())
                .then(response => this.setState({ images: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'wallpaper/getPhotoGalleryFrontEnd' + '/' + getLanguage() + '/' + limit + '/' + topic_id + '/' + year + '/' + event_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_gallery: response })
                    var total = this.state.arr_gallery.length;
                    if (total < 1) {
                        $('#notAvailable').css('display', 'inline');
                    } else {
                        $('#notAvailable').css('display', 'none');
                    }
                    if (total > 0) {
                        var total_downloads = this.state.arr_gallery[0].total_downloads;
                        var limit = this.state.limit;
                        //  alert(total_downloads);
                        if (total_downloads <= limit) {
                            $('#showMoreBtn').css('display', 'none')
                        } else {
                            $('#showMoreBtn').css('display', 'inline')
                        }
                    }
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

                })

            //     fetch(myConstClass.BASE_URL+'getAllTopics'+'/'+getLanguage())
            //     .then(response => response.json())
            //     .then(response =>this.setState({topic: response}))
            //     .catch(err => console.log(err))


            //  fetch(myConstClass.BASE_URL+'photo/getEventList'+'/'+getLanguage())
            //     .then(response => response.json())
            //     .then(response =>this.setState({arr_event: response}))
            //     .catch(err => console.log(err))


        }
    }

    async downloadWallpaper(image_url, description) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = description;
        const name = tempDiv.textContent.trim();

        const outsideRes = await fetch(image_url);

        const blob = await outsideRes.blob();
        const url = window.URL.createObjectURL(blob);
        
        const link = document.createElement("a");
        link.href = url;
        link.download = `${name}.jpg`;
        link.click();
    }

    applyFilters(e) {
        e.preventDefault();
        const answer_array = this.props.location.pathname.split('/');
        var topic_id = $('#topic_id').val();
        if (topic_id == '' || topic_id == undefined) {
            topic_id = 0;
        }
        var year = $('#year').val();
        if (year == '' || year == undefined) {
            year = 0;
        }
        var event_id = $('#event_id').val();
        if (event_id == '' || event_id == undefined) {
            event_id = 0;
        }
        //  alert(filetype);
        var limit = this.state.limit;

        fetch(myConstClass.BASE_URL + 'wallpaper/getImages' + '/' + getLanguage() + '/' + limit + '/' + topic_id + '/' + year + '/' + event_id)
            .then(response => response.json())
            .then(response => this.setState({ images: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'wallpaper/getPhotoGalleryFrontEnd' + '/' + getLanguage() + '/' + limit + '/' + topic_id + '/' + year + '/' + event_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ arr_gallery: response })
                var total = this.state.arr_gallery.length;
                if (total < 1) {
                    $('#notAvailable').css('display', 'inline');
                } else {
                    $('#notAvailable').css('display', 'none');
                }
                if (total > 0) {
                    var total_downloads = this.state.arr_gallery[0].total_downloads;
                    var limit = this.state.limit;
                    //  alert(total_downloads);
                    if (total_downloads <= limit) {
                        $('#showMoreBtn').css('display', 'none')
                    } else {
                        $('#showMoreBtn').css('display', 'inline')
                    }
                }
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

            })



    }

    renderType = ({ id, title }) => <div className="row">
        <div className="col">
            <div className="col pt-3">
                <h5 className="font-weight-bold">{title}</h5>
            </div>
        </div>
    </div>

    renderGuest = ({ type_id, title, price, quantity }) =>
        <div><div className="row"><div className="col-md-3 mb-3">
            <div className="col border text-center">
                <div className=" py-3">
                    <img src={require("./assets/images/product1.png")} />
                    <div className="my-2">
                        <span className="mr-2">
                            <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                        </span>
                    </div>
                    <p className="font-weight-bold mb-1">{title}</p>
                    <p className="mb-1">Donation Contribution</p>
                    <p className="font-weight-bolder">INR. {price}/-</p>
                    <div className="qty mb-3">
                        <span>Qty-</span>
                        <span className="minus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-minus"></i>
                            </span>
                        </span>
                        <input type="number" className="count" name="qty" value={quantity} />
                        <span className="plus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-plus"></i>
                            </span>
                        </span>
                    </div>
                    <button className="btn btn-brown btn-sm">ADD TO CART</button>
                </div>
            </div></div></div></div>

    render() {
        const { photoIndex, isOpen, arr_event, month, arr_year, arrMagazine, arr_gallery, cartEvent, eventdetails, scheduleEvent, eventTypes, posts, types, languages, topic, guestinfo } = this.state
        const { t } = this.props;
        return (
            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />


                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-9 ">
                            <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb" id="breadcrumb">
                                    <ol class="breadcrumb bg-white mb-0 ml-n2 pb-0  ">
                                        <li class="breadcrumb-item h5 pl-2 ">
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li class="breadcrumb-item h5 pl-2 "><a href="#">{t('home.Gallery')}</a></li>
                                        <li class="breadcrumb-item active h5 d-xl-inline d-lg-inline d-md-inline d-none" aria-current="page">
                                            <a href="#">{t('home.Wallpaper Gallery')}</a>
                                        </li>
                                        <li class="breadcrumb-item active h5 d-xl-none d-lg-none d-md-none d-inline" aria-current="page">
                                            <a href="#">Wallpaper</a>
                                        </li>
                                    </ol>

                                    <div className="d-xl-block d-lg-block d-md-block d-none">
                                        <Link class="btn btn-link orangeColor font-weight-bold" to="/photo-gallery">{t('home.PHOTO GALLERY')}</Link>
                                        <Link class="btn btn-link orangeColor font-weight-bold" to="/video-gallery">{t('home.VIDEO GALLERY')}</Link>
                                        <Link class="btn btn-link orangeColor font-weight-bold" to="/audio-gallery">{t('home.AUDIO GALLERY')}</Link>
                                        <Link class="text-dark ml-2 pl-1 h5 font-weight-bold" to="/wallpaper-gallery">
                                            <b>{t('home.WALLPAPER GALLERY')}</b>
                                        </Link>
                                    </div>

                                    <div className="d-xl-none d-lg-none d-md-none d-block">
                                        <Link class="btn btn-link orangeColor font-weight-bold" to="/photo-gallery">PHOTO</Link>
                                        <Link class="btn btn-link orangeColor font-weight-bold" to="/video-gallery">VIDEO</Link>
                                        <Link class="btn btn-link orangeColor font-weight-bold" to="/audio-gallery">AUDIO</Link>
                                        <Link class="text-dark ml-2 pl-1 h5 " to="/wallpaper-gallery">
                                            <strong>
                                            WALLPAPER
                                            </strong>
                                        </Link>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="row" id="filters123">
                        <div class="col-lg-9 ">
                            <div class="row py-4">
                                {arr_gallery.map((gallery, i) => {
                                    return (
                                        <div class="col-lg-4 col-md-4 col-sm-4 mb-3">
                                            <div class="">
                                                {/* <a target="_blank" href={gallery.image_url} download={gallery.image_url} className="btn btn-brown btn-sm" style={{ position: 'absolute', marginLeft: '80%' }}>
                                                    <i className="fa fa-download" aria-hidden="true"></i>
                                                </a> */}
                                                <img style={{ display: 'none' }} />
                                                <img id={i} onClick={this.openGallery} src={gallery.image_url} class="card-img-top galleryResponsiveImage" alt="..." height="250px" width="250px" />
                                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                                    <p class="m-0 mt-1">
                                                        {ReactHtmlParser(gallery.description)}
                                                    </p>
                                                    {/* <a href={gallery.image_url} target="_blank" download={`${gallery.wallpaper_id}.jpg`}>
                                                        <img width="25px" height="25px" src={download} class="mx-2" alt="..." />
                                                    </a> */}
                                                    <p style={{ cursor: 'pointer' }} onClick={() => this.downloadWallpaper(gallery.image_url, gallery.description)}><img width="25px" height="25px" src={download} class="mx-2" alt="..."/>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }

                            </div>





                            <div class="row pt-3 pb-3">
                            <div className="col-xl-5">
                                    
                                    </div>
                                <div className="col-xl-2 col-lg-2 col-md-2 col-12">
                                <button type="button" id="showMoreBtn" class="btn btn-outline-brown m-auto btn-block" onClick={this.showMore}>{t('home.Show More')}</button>
                                <input type="hidden" name="limit" id="limit" value={this.state.limit} />
                                <p id="notAvailable" style={{ display: 'none', marginLeft: '50%' }}>
                                    {t('home.Not available')}
                                </p>
                                </div>
                            </div>
                        </div>


                        <div class="col mt-3" style={{ paddingTop: '8px' }}>
                            <div class="pl-2 ">
                                {arrMagazine.map((cell, i) => {
                                    if (cell.magazine_id == 2) {
                                        return (
                                            <div class="col py-2 border border-warning bg-white box-shadow mb-3">

                                                <div class="row d-flex">
                                                    <div class="col-12">
                                                        <strong>
                                                            {cell.magazine_title}
                                                        </strong>
                                                        <p>
                                                            {ReactHtmlParser(cell.description)}

                                                        </p>
                                                        <p>
                                                            <strong>{t('home.Language')}:</strong>

                                                            {cell.language}
                                                        </p>
                                                        <p>

                                                            {cell.images_array.map((info, ii) => {
                                                                if (ii == 0) {
                                                                    if (info.issue_id > 0 && info.issue_id != undefined) {
                                                                        return (
                                                                            <Link to={'/magazine_details/' + info.magazine_id + '/' + info.issue_id}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>

                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <Link to={'/magazine'}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>

                                                                        )
                                                                    }
                                                                } else {
                                                                    return (
                                                                        <Link to={'/magazine'}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>
                                                                    )
                                                                }
                                                            }
                                                            )}



                                                            <Link class="btn btn-brown btn-sm btn-block mb-2 magazineImageButtonRight" to={'/magazine-subscription/' + cell.magazine_id}>{t('home.SUBSCRIBE')}</Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div class="col py-2 border border-warning bg-white box-shadow mb-3">

                                                <div class="row d-flex">
                                                    <div class="col-12">
                                                        <strong>
                                                            {cell.magazine_title}
                                                        </strong>
                                                        <p>
                                                            {ReactHtmlParser(cell.description)}

                                                        </p>
                                                        <p>
                                                            <strong>{t('home.Language')}:</strong>

                                                            {cell.language}
                                                        </p>
                                                        <p>

                                                            {cell.images_array.map((info, ii) => {
                                                                if (ii == 0) {
                                                                    if (info.product_id > 0) {
                                                                        return (
                                                                            <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />

                                                                                <p >
                                                                                    <Link class="btn btn-brown btn-block btn-sm mb-2 magazineImageButtonRight" to={'/products_details/' + info.title + '/' + info.product_id}>{t('home.BUY NOW')}</Link>
                                                                                </p>
                                                                            </Link>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <Link to={'/products'}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                                <p >
                                                                                    <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight btn-block" to={'/products'}>{t('home.BUY NOW')}</Link>
                                                                                </p>
                                                                            </Link>
                                                                        )
                                                                    }

                                                                } else {
                                                                    if (info.product_id > 0) {
                                                                        return (
                                                                            <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <Link to={'/products'}>
                                                                                <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            </Link>
                                                                        )
                                                                    }
                                                                }
                                                            }
                                                            )}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={this.state.images[photoIndex]['image_url']}
                        nextSrc={this.state.images[(photoIndex + 1) % this.state.images.length]['image_url']}
                        prevSrc={this.state.images[(photoIndex + this.state.images.length - 1) % this.state.images.length]['image_url']}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + this.state.images.length - 1) % this.state.images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % this.state.images.length,
                            })
                        }
                    />
                )}
                <Footer />
            </div>
        );
    }
}

Wallpapergallery.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Wallpapergallery);

