import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/magazine.css';
import './assets/css/rfs.css';
import Header from './Header.js';
import Footer from './Footer.js';
import * as myConstClass from './constant.js';
import { Base64 } from 'js-base64';
import $ from 'jquery';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,

} from 'reactstrap';

import MonthlyMagazine from './assets/images/MonthlyMagazine.jpg';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
//import { toast } from 'react-toastify';




//console.log('.push(',{body3});


// async function getMovies(){
//   await fetch(myConstClass.BASE_URL+'product/getProductsFrontEnd')
//     .then((response) => response.json());
// }

class Magazine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            share_current_image_url: '',
            share_image_url: '',
            social_description: '',
            flag: 0,
            posts: [],
            eventinfo: [],
            review: [],
            review_all: [],
            guestinfo: {
                title: '',
                sub_title: '',
            },
        }
        this.submitLike = this.submitLike.bind(this);
        this.submitReview = this.submitReview.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.readMore = this.readMore.bind(this);
        this.readLess = this.readLess.bind(this);

        this.showMore = this.showMore.bind(this);
        this.showLess = this.showLess.bind(this);


    }




    componentDidMount() {
        const answer_array = this.props.location.pathname.split('/');
        var user_id = localStorage.getItem('user_id');
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }

        fetch(myConstClass.BASE_URL + 'magazine/getMagazineInfoFrontEnd/' + getLanguage() + '/2')
            .then(response => response.json())
            .then(response => this.setState({ guestinfo: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'magazine/getMagazineIssuesImagesFrontEnd' + '/' + getLanguage() + '/' + user_id + '/2')
            .then(response => response.json())
            .then(response => {
                this.setState({ posts: response })
                this.state.posts.map((info1, iii) => {
                    info1.images_array.map((info, ii) => {
                        // alert('coii'+info1.social_description);
                        if (ii == '0') {
                            this.setState({ share_image_url: info.image_name })
                            this.setState({ social_description: info1.social_description })
                            //alert(this.state.social_description);
                            this.setState({ share_current_image_url: window.location.href });
                            if (this.state.share_image_url) {
                                $("meta[property='og\\:image']").attr("content", this.state.share_image_url);
                            }
                            //alert('uiui:'+this.state.share_image_url);
                        }
                    })
                })
            })
            .catch(err => console.log(err))


        fetch(myConstClass.BASE_URL + 'review/getMagazineReviewFrontEnd/2/2')
            .then(response => response.json())
            .then(response => {
                this.setState({ review_all: response })

                const numRows = this.state.review_all.length;
                if (numRows > 1) {
                    $('#ShowAllReviewDiv').css('display', 'inline');
                } else {
                    $('#ShowAllReviewDiv').css('display', 'none');
                }
                if (numRows < 1) {
                    $('#no_review_found').css('display', 'inline');
                }
            })
            .catch(err => console.log(err))


        fetch(myConstClass.BASE_URL + 'review/getMagazineReviewFrontEnd/2/1')
            .then(response => response.json())
            .then(response => {
                this.setState({ review: response })

                const numRows = this.state.review.length;
                //alert(numRows);

                if (numRows < 1) {
                    $('#no_review_found').css('display', 'inline');
                }
            })
            .catch(err => console.log(err))




        var user_id = localStorage.getItem('user_id');


    }


    applyFilters() {
        $('#ShowAllReviewDiv').css('display', 'none');
        const answer_array = this.props.location.pathname.split('/');
        fetch(myConstClass.BASE_URL + 'review/getMagazineReviewFrontEnd/2' + '/200')
            .then(response => response.json())
            .then(response => this.setState({ review: response }))
            .catch(err => console.log(err))
    }


    readMore(e) {
        // var product_id = e.target.id;
        $('#ReadMoreDiv').css('display', 'none');
        $('#ReadLessDiv').css('display', 'block');
    }

    readLess(e) {
        // var product_id = e.target.id;
        $('#ReadMoreDiv').css('display', 'block');
        $('#ReadLessDiv').css('display', 'none');
    }

    showMore(e) {
        var review_id_all = e.target.id;
        var arr_review_id = review_id_all.split('_');
        var review_id = arr_review_id[1];
        $('#showMoreDiv_' + review_id).css('display', 'none');
        $('#showLessDiv_' + review_id).css('display', 'block');
    }

    showLess(e) {
        var review_id_all = e.target.id;
        var arr_review_id = review_id_all.split('_');
        var review_id = arr_review_id[1];
        $('#showMoreDiv_' + review_id).css('display', 'block');
        $('#showLessDiv_' + review_id).css('display', 'none');
    }

    submitReview(e) {

        e.preventDefault();
        console.log('this.refs', $('#pform').serializeArray());
        const formData = {};
        for (const field in this.refs) {
            formData[field] = this.refs[field].value;
        }
        const answer_array = this.props.location.pathname.split('/');
        var user_review_current = $('#user_review').val();
        var user_review = $.trim(user_review_current);
        var user_id = localStorage.getItem('user_id');
        if (user_id != '' && user_id != null && user_id > 0) {
            if (user_review != '') {
                var message = 'Your review added successfully,once admin approval will display it.';

                //fetch(myConstClass.BASE_URL+'review/saveProductReviewFrontEnd/'+user_id+'/'+answer_array['3']+'/'+user_review)
                fetch(myConstClass.BASE_URL + `review/saveMagazineReviewFrontEnd/` + user_id + '/' + answer_array['2'], {
                    method: 'POST',
                    body: JSON.stringify($('#pform').serializeArray()),

                })
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ eventinfo: response })
                        var status = this.state.eventinfo.status;
                        var message = this.state.eventinfo.message;
                        if (status == 'true') {
                            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                            //setTimeout(this.props.history.push('/products'),1000);
                        } else {
                            toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
                            //toast("Wow so easy !");
                        }
                    })
                    .catch(err => console.log(err))
                // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});

                $('#user_review').val('');
            } else {
                var message = "Please enter review.";
                toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
            }


        } else {
            //var error =  'You must have login for submitting review.';
            localStorage.removeItem('error');
            localStorage.setItem('error', 'You must have login for submitting review.');
            // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
            // this.props.history.push('/signin');
            setTimeout(this.props.history.push('/signin'), 1000);
        }

        //alert(user_review);
    }


    submitLike() {
        const answer_array = this.props.location.pathname.split('/');
        var user_id = localStorage.getItem('user_id');
        if (user_id != '' && user_id != null && user_id > 0) {

            fetch(myConstClass.BASE_URL + 'review/saveMagazinetLikeFrontEnd/' + user_id + '/' + answer_array['2'])
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventinfo: response })
                    var status = this.state.eventinfo.status;
                    var message = this.state.eventinfo.message;
                    if (status == 'true') {
                        var magazineLikeCount = $('#magazineLikeCount').html();
                        var magazineLikeCountNew = parseInt(magazineLikeCount) + 1;
                        $('#magazineLikeCount').html(magazineLikeCountNew);
                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        //setTimeout(this.props.history.push('/products'),1000);
                    } else {
                        toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
                        //toast("Wow so easy !");
                    }
                })
                .catch(err => console.log(err))
            // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});


        } else {
            //var error =  'You must have login for submitting review.';
            localStorage.removeItem('error');
            localStorage.setItem('error', 'You must have login for like magazine.');
            // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
            // this.props.history.push('/signin');
            setTimeout(this.props.history.push('/signin'), 1000);
        }

        //alert(user_review);
    }



    componentDidUpdate(prevProps) {
        const answer_array = this.props.location.pathname.split('/');
        const { flag } = this.state
        // alert(answer_array);
        var user_id = localStorage.getItem('user_id');


        var current_language = localStorage.getItem('current_language');

        if (current_language != getLanguage() && flag != 1 && current_language != null) {
            //alert(current_language);
            localStorage.removeItem('current_language');
            localStorage.setItem('current_language', getLanguage());
            this.setState({ flag: 1 });
        }

        if (getLanguage() == 'ma' && flag == 1) {

            // e.preventDefault();
            $('html,body').animate({
                scrollTop: 0
            }, 700);
            this.setState({ flag: 0 })

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineInfoFrontEnd/' + getLanguage() + '/2')
                .then(response => response.json())
                .then(response => this.setState({ guestinfo: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineIssuesImagesFrontEnd' + '/' + getLanguage() + '/' + user_id + '/2')
                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response })
                    this.state.posts.map((info1, iii) => {
                        info1.images_array.map((info, ii) => {
                            if (ii == '0') {
                                this.setState({ share_image_url: info.image_name })
                                this.setState({ social_description: info1.social_description })
                                this.setState({ share_current_image_url: window.location.href });
                                if (this.state.share_image_url) {
                                    $("meta[property='og\\:image']").attr("content", this.state.share_image_url);
                                }
                                //alert('uiui:'+this.state.share_image_url);
                            }
                        })
                    })
                })
                .catch(err => console.log(err))


            fetch(myConstClass.BASE_URL + 'review/getMagazineReviewFrontEnd/2/1')
                .then(response => response.json())
                .then(response => {
                    this.setState({ review: response })

                    const numRows = this.state.review.length;
                    if (numRows < 1) {
                        $('#ShowAllReviewDiv').css('display', 'none')
                        $('#no_review_found').css('display', 'inline')
                    }
                })
                .catch(err => console.log(err))

        }

        if (getLanguage() == 'en' && (flag == 1)) {

            $('html,body').animate({
                scrollTop: 0
            }, 700);
            this.setState({ flag: 0 })

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineInfoFrontEnd/' + getLanguage() + '/2')
                .then(response => response.json())
                .then(response => this.setState({ guestinfo: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineIssuesImagesFrontEnd' + '/' + getLanguage() + '/' + user_id + '/2')
                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response })
                    this.state.posts.map((info1, iii) => {
                        info1.images_array.map((info, ii) => {
                            if (ii == '0') {
                                this.setState({ share_image_url: info.image_name })
                                this.setState({ social_description: info1.social_description })
                                this.setState({ share_current_image_url: window.location.href });
                                if (this.state.share_image_url) {
                                    $("meta[property='og\\:image']").attr("content", this.state.share_image_url);
                                }
                                //alert('uiui:'+this.state.share_image_url);
                            }
                        })
                    })
                })
                .catch(err => console.log(err))


            fetch(myConstClass.BASE_URL + 'review/getMagazineReviewFrontEnd/2/1')
                .then(response => response.json())
                .then(response => {
                    this.setState({ review: response })

                    const numRows = this.state.review.length;
                    if (numRows < 1) {
                        $('#ShowAllReviewDiv').css('display', 'none')
                        $('#no_review_found').css('display', 'inline')
                    }
                })
                .catch(err => console.log(err))


        }
    }

    renderType = ({ id, title }) => <div className="row">
        <div className="col">
            <div className="col pt-3">
                <h5 className="font-weight-bold">{title}</h5>
            </div>
        </div>
    </div>

    renderGuest = ({ type_id, title, price, quantity }) =>
        <div><div className="row"><div className="col-md-3 mb-3">
            <div className="col border text-center">
                <div className=" py-3">
                    <img src={require("./assets/images/product1.png")} />
                    <div className="my-2">
                        <span className="mr-2">
                            <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                        </span>
                    </div>
                    <p className="font-weight-bold mb-1">{title}</p>
                    <p className="mb-1">Donation Contribution</p>
                    <p className="font-weight-bolder">INR. {price}/-</p>
                    <div className="qty mb-3">
                        <span>Qty-</span>
                        <span className="minus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-minus"></i>
                            </span>
                        </span>
                        <input type="number" className="count" name="qty" value={quantity} />
                        <span className="plus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-plus"></i>
                            </span>
                        </span>
                    </div>
                    <button className="btn btn-brown btn-sm">ADD TO CART</button>
                </div>
            </div></div></div></div>

    render() {
        const { review, guestinfo, cartEvent, productIncart, posts, types, languages, topic } = this.state
        const { t } = this.props;
        return (
            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-9">
                            <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb bg-white mb-0 ml-n2">
                                        <li class="breadcrumb-item"><Link to="/">{t('home.home')}</Link></li>
                                        <li class="breadcrumb-item"><Link to="/products">{t('home.Products')}</Link></li>
                                        <li class="breadcrumb-item active" aria-current="page">
                                            {t('home.Magazines')}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="row ">
                                <div class="col-lg-9 col-md-9 col-sm-12 ">
                                    <h4><b>

                                        {guestinfo.title}

                                    </b> <span class="sub_text"><i>

                                        {guestinfo.sub_title}
                                    </i></span> </h4>

                                </div>
                                <div className="col-12">
                                    <div className="d-xl-none d-lg-none d-md-none d-block mb-3">
                                        <img src={MonthlyMagazine} height={85} />
                                    </div>
                                </div>

                                <div class="col-lg-3 col-md-3 col-sm-12 ">
                                    <span class="mr-2 d-inline-block pr-2">
                                        <i class="fas fa-comment-dots orangeColor"></i> <span class="small">
                                            {guestinfo.total_review}
                                        </span>
                                    </span>
                                    <span class="d-inline-block pr-2">
                                        <i class="fas fa-thumbs-up orangeColor"></i> <span class="small" id="magazineLikeCount">
                                            {guestinfo.total_like}
                                        </span>
                                    </span>
                                    <span class="d-inline-block pr-2 text-center" onClick={this.submitLike} style={{ cursor: 'pointer' }}>
                                        <i class="fas fa-thumbs-up color-brown"></i> <br /> <span class="small d-block mt-n1">
                                            {t('home.LIKE THIS')}

                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="container-fluid">

                    {posts.map((cell, i) => {
                        if (i == 0) {
                            return (
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="row">
                                            {cell.images_array.map((info, ii) => {
                                                return (
                                                    <div class="col-lg-6 col-md-6 col-sm-12 pt-2 text-center">

                                                        <Link to={'/magazine_details/' + cell.magazine_id + '/' + info.issue_id}>
                                                            <img src={info.image_name} class="responsiveImage magazineListingImage" />
                                                        </Link>


                                                        <p class="pt-2"><b>
                                                            <Link to={'/magazine_details/' + cell.magazine_id + '/' + info.issue_id}>
                                                                <span style={{ color: 'black' }}>{info.issue_title} </span>
                                                            </Link>
                                                        </b></p>
                                                    </div>
                                                )
                                            }
                                            )}

                                        </div>
                                    </div>

                                    <div class="col-lg-5" id="Features">
                                        <div class="row">
                                            <div class=" mt-2 ml-3 ">

                                                <p>
                                                    {/* {Base64.decode(cell.description)} */}
                                                    {ReactHtmlParser(cell.description)}

                                                </p>

                                                <p>
                                                    <h5>{t('home.Language')} : <span>
                                                        {cell.language}
                                                    </span></h5>
                                                </p>



                                                {/* <ul class="ml-3">
                                        {Base64.decode(cell.description)}

                                        </ul> */}
                                                <Link class="btn btn-brown btn-sm mb-2" to={'/magazine-subscription/' + cell.magazine_id}>{t('home.SUBSCRIBE NOW')}</Link>
                                                {/* <button class="btn btn-brown btn-sm mb-2">{t('home.SUBSCRIBE NOW')}</button> */}
                                            </div>

                                        </div>
                                        <div class="mt-4 border border-dark ">
                                            <div class="pl-4 pb-3 ">
                                                <p>
                                                    <h5>{t('home.E-SUBSCRIPTION')}</h5>
                                                </p>

                                                <p>
                                                    {t('home.magazine_listing_content')}

                                                </p>
                                                <p>
                                                    {t('home.magazine_listing_content_two')}

                                                </p>
                                                <a style={{ textDecoration: 'none' }} target="_blank" href={'https://www.magzter.com/IN/Manashakti/Manashakti/Culture/'}>
                                                    <button class="btn btn-brown btn-sm mb-2 mt-2">{t('home.E-SUBSCRIBE NOW')}</button>
                                                </a>
                                            </div>
                                        </div>

                                        <div class="row">


                                            <div class="col-lg-12" id="Features">
                                                <div class="row">
                                                    <div class="col">
                                                        <hr />
                                                        <p class="lead font-weight-bolder">{t('home.REVIEWS')}</p>
                                                    </div>
                                                </div>
                                                <div class="row" >

                                                    {review.map((info, ii) => {
                                                        if (info.description.length < 210) {
                                                            return (<div class="ml-3">
                                                                <p style={{ minHeight: '10px' }}>{info.description}
                                                                    {/* <span class="orangeColor"><br/>Show more...</span> */}
                                                                </p>
                                                                <p class="font-weight-bolder">{info.name}, {info.address} | {info.created_on}</p>
                                                            </div>
                                                            )
                                                        } else {
                                                            return (

                                                                <div class="ml-3" >
                                                                    <p id={'showMoreDiv_' + info.review_id} style={{ minHeight: '93px' }}>{info.short_description}
                                                                        <span class="orangeColor" id={'showMoreSpan_' + info.review_id} onClick={this.showMore} style={{ cursor: 'pointer' }}><br />{t('home.Show more')}</span>
                                                                    </p>

                                                                    <p id={'showLessDiv_' + info.review_id} style={{ minHeight: '93px', display: 'none' }}>{info.description}
                                                                        <span class="orangeColor" id={'showLesSpan_' + info.review_id} onClick={this.showLess} style={{ cursor: 'pointer' }}><br />{t('home.Show less')}</span>
                                                                    </p>

                                                                    <p class="font-weight-bolder">{info.name}, {info.address} | {info.created_on}</p>
                                                                </div>


                                                            )
                                                        }
                                                    }

                                                    )}
                                                </div>

                                                <p id="no_review_found" style={{ display: 'none' }}>{t('home.Be the first to the review this magazine')}</p>
                                                <div class="row" id="ShowAllReviewDiv" >
                                                    <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                                                        <button class="btn btn-outline-brown btn-block" onClick={this.applyFilters}>{t('home.SHOW ALL')}</button>
                                                    </div>
                                                </div>
                                                <div class="row" >
                                                    <Form action="" id="pform" onSubmit={this.submitReview} method="post" encType="multipart/form-data" className="form-horizontal">
                                                        <div class="col mt-12">
                                                            <div class="form-group" >
                                                                <label for="" class=" font-weight-bolder">{t('home.Please add your review')}</label>
                                                                <textarea id="user_review" name="user_review" class="form-control" placeholder={t('home.Enter your feedback and suggestions')} rows="4" cols="80"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                                                            <button class="btn btn-outline-brown btn-block" >{t('home.SUBMIT')}</button>
                                                        </div>
                                                    </Form>
                                                </div>

                                            </div>


                                        </div>


                                    </div>


                                    <div class="col-lg-3 magazineListingMagazine">
                                        {cell.other_magazine.map((cellMagazine, i) => {
                                            if (cellMagazine.magazine_id == 2) {
                                                return (
                                                    <div class="col py-2 border border-warning bg-white box-shadow mb-3">

                                                        <div class="row d-flex">
                                                            <div class="col-12">
                                                                <strong>
                                                                    {cellMagazine.magazine_title}
                                                                </strong>
                                                                <p>
                                                                    {ReactHtmlParser(cellMagazine.short_description)}

                                                                </p>
                                                                <p>
                                                                    <strong>{t('home.Language')}:</strong>

                                                                    {cellMagazine.language}
                                                                </p>
                                                                <p>

                                                                    {cellMagazine.images_array.map((info, ii) => {
                                                                        if (ii == 0) {
                                                                            return (
                                                                                <Link to={'/magazine'}>
                                                                                    <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                                </Link>

                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <Link to={'/magazine'}>
                                                                                    <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                                </Link>
                                                                            )
                                                                        }
                                                                    }
                                                                    )}




                                                                    <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight" to={'/magazine-subscription/' + cell.magazine_id}>{t('home.SUBSCRIBE')}</Link>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div class="col py-2 border border-warning bg-white box-shadow mb-3">

                                                        <div class="row d-flex">
                                                            <div class="col-12">
                                                                <strong>
                                                                    {cellMagazine.magazine_title}
                                                                </strong>
                                                                <p>
                                                                    {ReactHtmlParser(cellMagazine.short_description)}

                                                                </p>
                                                                <p>
                                                                    <strong>{t('home.Language')}:</strong>

                                                                    {cellMagazine.language}
                                                                </p>
                                                                <p>

                                                                    {cellMagazine.images_array.map((info, ii) => {
                                                                        if (ii == 0) {
                                                                            if (info.product_id > 0) {
                                                                                return (
                                                                                    <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                                        <img className="mb-2 magazineImageRight" src={info.image_name} />

                                                                                        <p style={{ marginTop: '10px' }}>
                                                                                            <Link class="btn btn-brown btn-block btn-sm mb-2 magazineImageButtonRight" to={'/products_details/' + info.title + '/' + info.product_id}>{t('home.BUY NOW')}</Link>
                                                                                        </p>
                                                                                    </Link>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <Link to={'/products'}>
                                                                                        <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                                        <p style={{ marginTop: '10px' }}>
                                                                                            <Link class="btn btn-brown btn-block btn-sm mb-2 magazineImageButtonRight" to={'/products'}>{t('home.BUY NOW')}</Link>
                                                                                        </p>
                                                                                    </Link>
                                                                                )
                                                                            }

                                                                        } else {
                                                                            if (info.product_id > 0) {
                                                                                return (
                                                                                    <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                                        <img style={{ marginLeft: '52%', height: '217px' }} className="image_css" src={info.image_name} />
                                                                                    </Link>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <Link to={'/products'}>
                                                                                        <img style={{ marginLeft: '52%', height: '217px' }} className="image_css" src={info.image_name} />
                                                                                    </Link>
                                                                                )
                                                                            }
                                                                        }
                                                                    }
                                                                    )}
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>

                                </div>
                            )
                        }

                        //}
                    })}




                </div>

                <Footer share_current_image_url={this.state.share_current_image_url} share_image_url={this.state.share_image_url} description={this.state.social_description} />
                {/* <div className="container-fluid bg-grey mt-4">
        <div className="row grey-text">
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Residential Study Courses</a>
                <a href="#" className="grey-text d-block py-1">1-day Courses</a>
                <a href="#" className="grey-text d-block py-1">Machine Tests</a>
                <a href="#" className="grey-text d-block py-1">Rational Poojas</a>
                <a href="#" className="grey-text d-block py-1">Free Events</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Books</a>
                <a href="#" className="grey-text d-block py-1">CDs</a>
                <a href="#" className="grey-text d-block py-1">Kits (Set of Books)</a>
                <a href="#" className="grey-text d-block py-1">Experimental Products</a>
                <a href="#" className="grey-text d-block py-1">Ayurvedic Products</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Magazines</a>
                <a href="#" className="grey-text d-block py-1">Gallery</a>
                <a href="#" className="grey-text d-block py-1">Downloads</a>
                <a href="#" className="grey-text d-block py-1">FAQs</a>
                <a href="#" className="grey-text d-block py-1">E-Shopping Help</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">About Us</a>
                <a href="#" className="grey-text d-block py-1">Research</a>
                <a href="#" className="grey-text d-block py-1">Event Calendar</a>
                <a href="#" className="grey-text d-block py-1">Feedback</a>
                <a href="#" className="grey-text d-block py-1">Contact Us</a>
            </div>
        </div>
    </div> */}
                {/* <div className="container-fluid bg-black">
        <p className="text-center py-3 grey-text mb-0">
            © 2000-2017 Manashakti Rest New Way, | All Rights Reserved Worldwide
        </p>
    </div> */}
            </div>
        );
    }
}


Magazine.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Magazine);
