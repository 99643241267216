import $ from "jquery";
import PropTypes from "prop-types";
import React, { Component } from "react";
import BigPicture from "react-bigpicture";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import { getLanguage, translate } from "react-switch-lang";
import { ToastContainer, toast } from "react-toastify";
import Footer from "./Footer.js";
import Header from "./Header.js";
import * as myConstClass from "./constant.js";

import "react-toastify/dist/ReactToastify.css";
import "./assets/dist/css/bootstrap.css";
import "./assets/css/rfs.css";
import "./assets/css/style.css";

import Hotjar from '@hotjar/browser';
import ContactForm from "./components/ContactForm.jsx";

const slideImages = [
  "images/slide_2.jpg",
  "images/slide_3.jpg",
  "images/slide_4.jpg",
];

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true,
  onChange: (oldIndex, newIndex) => {
    // console.log(`slide transition from ${oldIndex} to ${newIndex}`);
  },
};
class App extends Component {
  constructor(props) {
    super(props);
    this.product_id = 0;
    this.state = {
      youtubecountinfo: {
        subscriberCount: 0,
      },
      isCookie: false,
      subscriberCount: 0,
      advert3_image: [],
      share_image_url: "",
      share_current_image_url: "",
      sliderImage: [],
      advert1_image: [],
      latestNews: "",
      magazineIssues: "",
      quotes: "",
      flag: 0,
    };

    // this.checkCookie = this.checkCookie.bind(this);
  }

  componentDidMount() {
    document.cookie = "auth_cookie=1;"
    // this.checkCookie();
    const isCookieEnabled = navigator.cookieEnabled;
    // console.log("isCookieEnabled: ", isCookieEnabled);
    
    this.setState({ isCookie: true });
    // console.log('isCookie: ', this.state.isCookie);

    const siteId = 3797361;
    const hotjarVersion = 6;
    
    Hotjar.init(siteId, hotjarVersion);

    var message = localStorage.getItem("success");
    if (message != "" && message != null) {
      toast("" + message, { containerId: "B", type: toast.TYPE.SUCCESS });
      localStorage.removeItem("success");
    }
    //alert(getLanguage());
    if (getLanguage() == "ma") {
      $("#googlePlayEn").css("display", "none");
      $("#googlePlayMr").css("display", "inline");
    } else {
      $("#googlePlayEn").css("display", "inline");
      $("#googlePlayMr").css("display", "none");
    }
    fetch(myConstClass.BASE_URL + "cms/getHomeInfo/" + getLanguage())
      .then((response) => response.json())
      .then((response) => {
        this.setState({ sliderImage: response.slider_image });
        response.slider_image.map((item, ii) => {
          if (ii == "0") {
            this.setState({ share_image_url: item.image_url });
            this.setState({ share_current_image_url: window.location.href });
            if (this.state.share_image_url) {
              $("meta[property='og\\:image']").attr(
                "content",
                this.state.share_image_url
              );
            }
            //alert('asd'+this.state.share_image_url);
          }
        });
        this.setState({ advert1_image: response.advert1_image });
        this.setState({ advert2_image: response.advert2_image });
        this.setState({ advert3_image: response.advert3_image });
      });

    fetch(myConstClass.BASE_URL + "news/getLatestNews/" + getLanguage())
      .then((response) => response.json())
      .then((response) => {
        this.setState({ latestNews: response });
        var description = this.state.latestNews.description;
        // alert('hi'+description);
        if (description == "" || description == undefined) {
          $(".readMoreReadLessDiv").css("display", "none");
        } else {
          $(".readMoreReadLessDiv").css("display", "flex");
        }
      });

    fetch(myConstClass.BASE_URL + "magazine/magazineIssues/" + getLanguage())
      .then((response) => response.json())
      .then((response) => {
        this.setState({ magazineIssues: response });
      });

    fetch(myConstClass.BASE_URL + "cms/getdailythoughts/" + getLanguage())
      .then((response) => response.json())
      .then((response) => {
        this.setState({ quotes: response });
      });

    fetch(myConstClass.BASE_URL + "cms/youTubeCountInfo")
      .then((response) => response.json())
      .then((response) => {
        this.setState({ youTubeCountInfo: response });
        // console.log(this.state.youTubeCountInfo.subscriberCount);
        //  alert(this.state.youTubeCountInfo.subscriberCount);
        this.state.subscriberCount =
          this.state.youTubeCountInfo.subscriberCount;
      });
    fetch(myConstClass.BASE_URL + "cms/youTubeCountInfo")
      .then((response) => response.json())
      .then((response) => {
        this.setState({ youTubeCountInfo: response });
        // console.log(this.state.youTubeCountInfo.subscriberCount);
        //  alert(this.state.youTubeCountInfo.subscriberCount);
        this.state.subscriberCount =
          this.state.youTubeCountInfo.subscriberCount;
      });
  }

  componentDidUpdate(prevProps) {
    //alert(getLanguage());
    // this.checkCookie();
    const answer_array = this.props.location.pathname.split("/");
    //alert(answer_array['2']);
    const { flag } = this.state;

    if (getLanguage() == "ma") {
      $("#googlePlayEn").css("display", "none");
      $("#googlePlayMr").css("display", "inline");
    } else {
      $("#googlePlayEn").css("display", "inline");
      $("#googlePlayMr").css("display", "none");
    }
    var language_id = $("#language_id").val();
    if (language_id == "") {
      language_id = 0;
    }
    var old_language_id = localStorage.getItem("old_language_id");
    localStorage.setItem("old_language_id", language_id);

    //const { flag} = this.state
    if (getLanguage() == "ma" && flag == 0) {
      this.setState({ flag: 1 });

      fetch(myConstClass.BASE_URL + "cms/getHomeInfo/" + getLanguage())
        .then((response) => response.json())
        .then((response) => {
          this.setState({ sliderImage: response.mslider_image });
          response.slider_image.map((item, ii) => {
            if (ii == "0") {
              this.setState({ share_image_url: item.image_url });
              this.setState({ share_current_image_url: window.location.href });
              if (this.state.share_image_url) {
                $("meta[property='og\\:image']").attr(
                  "content",
                  this.state.share_image_url
                );
              }
              //alert('asd'+this.state.share_image_url);
            }
          });
          this.setState({ advert1_image: response.madvert1_image });
          this.setState({ advert2_image: response.advert2_image });
          this.setState({ advert3_image: response.advert3_image });
        });

      fetch(myConstClass.BASE_URL + "news/getLatestNews/" + getLanguage())
        .then((response) => response.json())
        .then((response) => {
          this.setState({ latestNews: response });
          var description = this.state.latestNews.description;
          if (description == "" || description == undefined) {
            $(".readMoreReadLessDiv").css("display", "none");
          } else {
            $(".readMoreReadLessDiv").css("display", "flex");
          }
        });
      fetch(myConstClass.BASE_URL + "magazine/magazineIssues/" + getLanguage())
        .then((response) => response.json())
        .then((response) => {
          this.setState({ magazineIssues: response });
        });

      fetch(myConstClass.BASE_URL + "cms/getdailythoughts/" + getLanguage())
        .then((response) => response.json())
        .then((response) => {
          this.setState({ quotes: response });
        });
    }

    if (getLanguage() == "en" && flag == 1) {
      this.setState({ flag: 0 });
      fetch(myConstClass.BASE_URL + "cms/getHomeInfo/" + getLanguage())
        .then((response) => response.json())
        .then((response) => {
          this.setState({ sliderImage: response.slider_image });
          response.slider_image.map((item, ii) => {
            if (ii == "0") {
              this.setState({ share_image_url: item.image_url });
              this.setState({ share_current_image_url: window.location.href });
              if (this.state.share_image_url) {
                $("meta[property='og\\:image']").attr(
                  "content",
                  this.state.share_image_url
                );
              }
              //alert('asd'+this.state.share_image_url);
            }
          });
          this.setState({ advert1_image: response.advert1_image });
          this.setState({ advert2_image: response.advert2_image });
          this.setState({ advert3_image: response.advert3_image });
        });
      fetch(myConstClass.BASE_URL + "news/getLatestNews/" + getLanguage())
        .then((response) => response.json())
        .then((response) => {
          this.setState({ latestNews: response });
          var description = this.state.latestNews.description;
          if (description == "" || description == undefined) {
            $(".readMoreReadLessDiv").css("display", "none");
          } else {
            $(".readMoreReadLessDiv").css("display", "flex");
          }
        });
      fetch(myConstClass.BASE_URL + "magazine/magazineIssues/" + getLanguage())
        .then((response) => response.json())
        .then((response) => {
          this.setState({ magazineIssues: response });
        });
      fetch(myConstClass.BASE_URL + "cms/getdailythoughts/" + getLanguage())
        .then((response) => response.json())
        .then((response) => {
          this.setState({ quotes: response });
        });
    }
  }

  // checkCookie() {
  //   const cookies = document.cookie.split(';');
  //   const cookieExists = cookies.some(cookie => cookie.trim().startsWith('auth_cookie='));

  //   if (cookieExists) {
  //     this.setState({ isCookie: true });
  //     console.log('auth_cookie exists: ', cookieExists);
  //   } else {
  //     console.log('auth_cookie does not exists');
  //   }
  // }

  render() {
    const { youtubecountinfo, sliderImage, advert1_image } = this.state;
    const { t } = this.props;

    return (
      <div className="App">
        <div>
          <ToastContainer />
        </div>
        <Header />

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 mt-xl-4 mt-lg-4 mt-md-5 mt-5">
              <div
                id="carouselExampleSlidesOnly"
                className="carousel slide"
                data-ride="carousel"
              >
                <div className="carousel-inner">
                  <Slide {...properties}>
                    {sliderImage.map((item, ii) => {
                      if (
                        item.image_redirecturl != undefined &&
                        item.image_redirecturl != ""
                      ) {
                        return (
                          <div className="each-slide">
                            <a href={item.image_redirecturl} target="_blank">
                              {/* <div style={{'backgroundImage': `url(${item.image_url})`,height:'322px'}}> */}
                              <div>
                                <img
                                  class="homeSliderImageFitNew"
                                  src={item.image_url}
                                />
                              </div>
                            </a>
                          </div>
                        );
                      } else {
                        return (
                          <div className="each-slide">
                            <div>
                              <img
                                class="homeSliderImageFitNew"
                                src={item.image_url}
                              />
                            </div>
                          </div>
                        );
                      }
                    })}
                  </Slide>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-xl-4 mt-lg-4 mt-md-5 mt-3">
              {advert1_image.map((item, ii) => {
                if (getLanguage() == "ma") {
                  return (
                    <span>
                      <a href={item.advert1redirecturl} target="_blank">
                        <img
                          id="homeConferenceImageNew"
                          src={item.madvert1url}
                        />
                      </a>
                    </span>
                  );
                } else {
                  return (
                    <span>
                      <a href={item.advert1redirecturl} target="_blank">
                        <img
                          id="homeConferenceImageNew"
                          src={item.advert1url}
                        />
                      </a>
                    </span>
                  );
                }
              })}
            </div>
          </div>
          <div className="row pt-4 row-flex">
            <div className="col-lg-4 home-box-mb-20">
              <div className="home-box">
                <h5 className="blockHeading">
                  {this.state.advert2_image ? (
                    this.state.advert2_image.map(function (item, i) {
                      if (i == "0") {
                        if (getLanguage() == "ma") {
                          return <span>{item.mind_title_mr}</span>;
                        } else {
                          return <span>{item.mind_title}</span>;
                        }
                      }
                    })
                  ) : (
                    <img src={require("./assets/images/icon9.png")} />
                  )}
                </h5>
                {/* <div className="row">
                        <div className="col">
                            <p> The Mind Museum is a science museum. It is located in Manashakti Shanti Kendra, Lonavla,</p>
                            <a href="#" className="orangeColor">Know More</a>
                        </div>
                    </div> */}
                {this.state.advert2_image ? (
                  this.state.advert2_image.map(function (item, i) {
                    // console.log('test');
                    if (i == "0") {
                      if (getLanguage() == "ma") {
                        return (
                          <div>
                            {item.mind_description_mr}
                            <br />
                            <a href={item.advert2redirecturl} target="_blank">
                              <span
                                class="orangeColor"
                                style={{ textDecoration: "none" }}
                              >
                                {t("home.Know more")}
                              </span>
                              <img
                                class="mindGYMHomeNew"
                                src={item.advert2url}
                              />
                            </a>
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            {item.mind_description}
                            <br />
                            <a href={item.advert2redirecturl} target="_blank">
                              <span
                                class="orangeColor"
                                style={{ textDecoration: "none" }}
                              >
                                {t("home.Know more")}
                              </span>
                              <img
                                class="mindGYMHomeNew"
                                src={item.advert2url}
                              />
                            </a>
                          </div>
                        );
                      }
                    }
                  })
                ) : (
                  <img src={require("./assets/images/icon9.png")} />
                )}
              </div>
            </div>
            <div className="col-lg-4 home-box-mb-20">
              <div className="home-box">
                <div className="book-box">
                  <h5 className="blockHeading Book-online-title">
                    {t("home.Buy Online")}
                  </h5>
                  <div className="d-flex mt-3">
                    <div
                      className="col d-blokc d-flex justify-content-start"
                      style={{ textAlign: "center" }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "#212529" }}
                        to="machine-test/Machine%20Tests/3"
                      >
                        <img src={require("./assets/images/icon3.png")} />
                        <p className="small mt-2 buyOnlineSmall">
                          {t("home.Machine")}
                          <br />
                          {t("home.Test")}
                        </p>
                      </Link>
                    </div>

                    <div
                      className="col homeBuyOnline"
                      style={{ textAlign: "center" }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "#212529" }}
                        to="events/Residential%20Study%20Courses/1"
                      >
                        <img src={require("./assets/images/icon4.png")} />
                        <p className="small mt-2 buyOnlineSmall">
                          {t("home.Residential Study")} <br />
                          {t("home.Courses")}
                        </p>
                      </Link>
                    </div>
                    <div
                      className="col d-blokc d-flex justify-content-end"
                      style={{ textAlign: "center" }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "#212529" }}
                        to="events/1-Day%20Workshops/2"
                      >
                        <img src={require("./assets/images/icon5.png")} />
                        <p className="small mt-2 buyOnlineSmall">
                          {t("home.1-day")} <br />
                          {t("home.Workshops")}
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div
                      className="col d-flex justify-content-start"
                      style={{ textAlign: "center" }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "#212529" }}
                        to="events/Rational%20Poojas/4"
                      >
                        <img src={require("./assets/images/icon6.png")} />
                        <p className="small mb-0 buyOnlineSmall">
                          {t("home.Rational")}
                          <br />
                          {t("home.Poojas")}
                        </p>
                      </Link>
                    </div>
                    <div
                      className="col homeBuyOnline"
                      style={{ textAlign: "center" }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "#212529" }}
                        to="/event_details/83"
                      >
                        <img src={require("./assets/images/icon7.png")} />
                        <p className="small mb-0 buyOnlineSmall">
                          {t("home.Psycho-Feedback Therapy")}
                        </p>
                      </Link>
                    </div>
                    <div
                      className="col d-flex justify-content-end"
                      style={{ textAlign: "center" }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "#212529" }}
                        to="products"
                      >
                        <img src={require("./assets/images/icon8.png")} />
                        <p className="small mb-0 buyOnlineSmall">
                          {t("home.Products")}
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 home-box-mb-20">
              <div className="home-box">
                <div className="">
                  <div className="">
                    <div className="">
                      <h5 className="blockHeading">
                        {t("home.Magazine")}
                        {/* {this.state.magazineIssues.title} */}
                      </h5>
                    </div>
                    <div className="col text-right">
                      {/* <span style={{color:'#9d0b0e'}}>
                            {t('home.SUBSCRIBERS')}:&nbsp;
                            {this.state.subscriberCount}
                           
                            </span> */}
                      <span className="mr-2">
                        <i className="fas fa-comment-dots orangeColor"></i>{" "}
                        <span className="small">
                          {this.state.magazineIssues.total_review}
                        </span>
                      </span>
                      <span>
                        <i className="fas fa-thumbs-up orangeColor"></i>{" "}
                        <span className="small">
                          {this.state.magazineIssues.total_like}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5">
                      {this.state.magazineIssues.image_name ? (
                        <img
                          src={this.state.magazineIssues.image_name}
                          className="img-fluid homeMagazineImage"
                        />
                      ) : (
                        <img
                          src={require("./assets/images/mag1.png")}
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <div className="col-7 pl-0">
                      <p class="pl-1 magazineImageM">
                        {ReactHtmlParser(this.state.magazineIssues.description)}
                        {/* {this.state.magazineIssues.description} */}
                      </p>
                      <p class="pl-1 magazineImageM">
                        <Link
                          style={{ textDecoration: "none" }}
                          to={
                            "magazine_details/2/" +
                            this.state.magazineIssues.issue_id
                          }
                          className="orangeColor"
                        >
                          {t("home.Know more")}
                        </Link>
                      </p>

                      <div className="d-flex magazineImageM">
                        <div className="col pl-0 pr-2 mb-2">
                          <Link
                            style={{ textDecoration: "none" }}
                            to="magazine-subscription/2"
                          >
                            <button className="subscribe-btn btn btn-brown btn-block btn-sm">
                              {t("home.SUBSCRIBE")}
                            </button>
                          </Link>
                        </div>
                        <div className="col mb-2 homeMagazineEsubscribe">
                          <a
                            style={{ textDecoration: "none" }}
                            target="_blank"
                            href={
                              "https://www.magzter.com/IN/Manashakti/Manashakti/Culture/"
                            }
                          >
                            <button className="btn btn-outline-brown btn-block btn-sm subscribe-btn p-1">
                              {t("home.E-SUBSCRIBE")}
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-flex">
            <div className="col-lg-4 home-box-mb-20">
              <div className="home-box">
                <div className="row">
                  <div className="col">
                    <img src={require("./assets/images/icon10.png")} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-5 mt-2">
                    <img
                      src={require("./assets/images/img3.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-7 pl-0">
                    <p className=" font-weight-bolder">
                      {t("home.Knowledge through Audio-Visuals")}
                    </p>
                    <p>
                      {t(
                        "home. Manashakti App for listening, watching audio-video CDs"
                      )}
                    </p>

                    <p className="orangeColor">
                      <a
                        class="orangeColor"
                        href="https://play.google.com/store/apps/details?id=com.snt.manashakti&hl=en_IN"
                        target="_blank"
                      >
                        {t("home.Download From")}
                      </a>
                    </p>

                    <a
                      href="https://play.google.com/store/apps/details?id=com.snt.manashakti&hl=en_IN"
                      target="_blank"
                    >
                      <img
                        id="googlePlayEn"
                        style={{ maxWidth: "150px" }}
                        class="mb-2 responsiveImage googlePlayEn"
                        src={require("./assets/images/g_play_logo.png")}
                      />

                      <img
                        id="googlePlayMr"
                        style={{ maxWidth: "150px" }}
                        class="mb-2 responsiveImage googlePlayMr"
                        src={require("./assets/images/g_play_logo_mr.png")}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 home-box-mb-20 home-bg-section home-box-img">
              <div className="home-box">
                <div className=" py-4 w-100 mt-2">
                  <h4
                    className="text-center mt-4 ml-4 blockHeading"
                    style={{ fontSize: "25px" }}
                  >
                    {t("home.Cycle of")}
                    <br />
                    {t("home.Good Deeds")}
                  </h4>
                  <div className="text-center ">
                    {/* <button className="btn btn-outline-brown mt-2 mb-5">
                            {t('home.KNOW MORE')}
                                </button> */}
                    <Link
                      className="btn btn-outline-brown ml-4 mt-4 mb-5"
                      to="/cycle-of-good-deeds/11"
                    >
                      {t("home.KNOW MORE")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 home-box-mb-20">
              <div className="home-box">
                <div className="row">
                  <div className="col">
                    <h5 className="blockHeading">
                      <i
                        className="fab fa-youtube color-red"
                        style={{ fontSize: "33px" }}
                      ></i>
                      &nbsp;&nbsp;
                      <span style={{ verticalAlign: "super" }}>
                        {t("home.YouTube Channel")}
                      </span>
                    </h5>
                  </div>
                  <span style={{ color: "#9d0b0e" }} class="pr-2">
                    {t("home.SUBSCRIBERS")}:&nbsp;
                    {this.state.subscriberCount}
                    {/* {youtubecountinfo.map((info, ii) => {
                            return(
                                <label>
                                    hh {info.subscriberCount}
                                </label>
                            )
                        })
                                    } */}
                  </span>
                  {/* <div className="col text-right">
                        <span className="mr-2">
                            <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                        </span>
                    </div> */}
                </div>
                <div className="row">
                  <div className="col">
                    {this.state.advert3_image.map(function (item, i) {
                      return (
                        <BigPicture
                          //   src={'https://www.youtube.com/watch?v=PYZJcWyYB3s'}
                          src={item.advert3url}
                          caption={t("home.YouTube Channel")}
                          type="youtube" // "video", "youtube", "vimeo", "iframe", "audio"
                          noLoader
                        >
                          <img
                            src="https://img.youtube.com/vi/PYZJcWyYB3s/hqdefault.jpg"
                            class="responsiveImage"
                            style={{ cursor: "pointer" }}
                          />
                        </BigPicture>
                      );
                    })}
                    {this.state.advert3_image ? (
                      this.state.advert3_image.map(function (item, i) {
                        return (
                          <div>
                            {/* <BigPicture
        type="youtube"
        
            src={item.advert3url}
    >
        
    </BigPicture> */}

                            <div className="mt-3">
                              <a href={item.subscription3url} target="_blank">
                                <button className="btn btn-brown btn-block btn-sm">
                                  {t("home.SUBSCRIBEHOME")}
                                </button>
                              </a>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>
                        {/* 
<BigPicture
        type="youtube"
        
            src="https://www.youtube.com/watch?v=PYZJcWyYB3s"
            >
        
        <i className="fab fa-youtube color-red homeYouTubeIcon"></i>
        <img src={'https://img.youtube.com/vi/8F55x08LpNc/hqdefault.jpg'} class="responsiveImage"  style={{cursor:'pointer'}}/>
    </BigPicture> */}

                        <div className="my-3">
                          <a
                            target="_blank"
                            class="btn btn-brown"
                            href="https://www.youtube.com/watch?v=PYZJcWyYB3s"
                          >
                            {t("home.SUBSCRIBE")}
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col pl-0 ml-2">
                    {/* <p><strong>
                        {t('home.Manashakti Documentary')}
                            </strong></p> */}
                    <p>
                      {this.state.advert3_image
                        ? this.state.advert3_image.map(function (item, i) {
                            return item.advert3_description;
                          })
                        : ""}
                    </p>
                    <p>
                      {/* {t('home.It can be purchased from Manashakti Research Center')} */}
                      <br />
                      <a
                        target="_blank"
                        className="orangeColor"
                        href="https://www.youtube.com/watch?v=PYZJcWyYB3s"
                      >
                        {t("home.Know more")}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-flex">
            <div className="col-lg-4 home-box-mb-20">
              <div className="home-box">
                <div className="row">
                  <div className="col">
                    <h5 className="blockHeading">{t("home.Latest News")}</h5>
                  </div>
                  <div
                    className="col text-right "
                    style={{ display: "table-cell" }}
                  >
                    <span className="mr-2">
                      <i className="fas fa-comment-dots orangeColor"></i>{" "}
                      <span className="small">
                        {this.state.latestNews.total_review}
                      </span>
                    </span>
                    <span>
                      <i className="fas fa-thumbs-up orangeColor"></i>{" "}
                      <span className="small">
                        {this.state.latestNews.total_like}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="row readMoreReadLessDiv">
                  <div className="col">
                    <img
                      src={this.state.latestNews.image_name}
                      className="img-fluid homeNewsImage"
                      style={{ maxHeight: "222px", textAlign: "center" }}
                    />
                  </div>
                  <div className="col pl-0">
                    <p className="text-black-50 small">
                      {this.state.latestNews.date}
                    </p>
                    <p>
                      <Link
                        to={
                          "news-details/" +
                          this.state.latestNews.news_article_id
                        }
                        class="color-black"
                      >
                        <strong>{this.state.latestNews.title}</strong>
                      </Link>
                    </p>
                    <p>
                      {/* {this.state.latestNews.description} */}
                      {ReactHtmlParser(
                        this.state.latestNews.description + "..."
                      )}
                    </p>
                  </div>
                </div>
                {/* <div className="row ">
                        <div className="col">
                           
                            { ReactHtmlParser(this.state.latestNews.description) }   
                            </div>
                    </div> */}
                <div className="row readMoreReadLessDiv">
                  <div className="col pl-0 text-left mb-2">
                    <Link to="/news/1">
                      <a className="orangeColor">{t("home.Read All News")}</a>
                    </Link>
                  </div>
                  <div className="col text-right">
                    <Link
                      to={
                        "news-details/" + this.state.latestNews.news_article_id
                      }
                    >
                      <a className="orangeColor">{t("home.Read More")}</a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 home-box-mb-20">
              <div className="home-box p-0 border-0">
                <div className="h-100 newsHomeImages">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    <div className="carousel-inner">
                      {this.state.quotes ? (
                        this.state.quotes.map(function (item, i) {
                          // console.log("test");
                          if (i == "0") {
                            if (item.language == 2) {
                              return (
                                <div className="carousel-item active">
                                  <img
                                    className="d-block w-100 carsolHomeForMobileNew"
                                    src={require("./assets/images/index.png")}
                                    alt="First slide"
                                  />
                                  <div
                                    class="carousel-caption"
                                    style={{ top: "50px" }}
                                  >
                                    <h5 class="thoughtHome"> {item.quote}</h5>
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div className="carousel-item active">
                                  <img
                                    className="d-block w-100 carsolHomeForMobileNew"
                                    src={require("./assets/images/index1.png")}
                                    alt="First slide"
                                  />
                                  <div
                                    class="carousel-caption"
                                    style={{ top: "50px" }}
                                  >
                                    <h5 class="thoughtHome"> {item.quote}</h5>
                                  </div>
                                </div>
                              );
                            }
                          } else {
                            if (item.language == 2) {
                              return (
                                <div className="carousel-item">
                                  <img
                                    className="d-block w-100 carsolHomeForMobileNew"
                                    src={require("./assets/images/index.png")}
                                    alt="First slide"
                                  />
                                  <div
                                    class="carousel-caption"
                                    style={{ top: "50px" }}
                                  >
                                    <h5
                                      style={{ fontSize: "24px" }}
                                      class="thoughtHome"
                                    >
                                      {" "}
                                      {item.quote}
                                    </h5>
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div className="carousel-item">
                                  <img
                                    className="d-block w-100 carsolHomeForMobileNew"
                                    src={require("./assets/images/index1.png")}
                                    alt="First slide"
                                  />
                                  <div
                                    class="carousel-caption"
                                    style={{ top: "50px" }}
                                  >
                                    <h5
                                      style={{ fontSize: "24px" }}
                                      class="thoughtHome"
                                    >
                                      {" "}
                                      {item.quote}
                                    </h5>
                                  </div>
                                </div>
                              );
                            }
                          }
                        })
                      ) : (
                        <div className="carousel-item active">
                          <img
                            src={require("./assets/images/slide1.jpg")}
                            className="img-fluid"
                          />{" "}
                        </div>
                      )}
                      {/* <div className="carousel-item active">
                            <img className="d-block w-100" src={require("./assets/images/index.png")} alt="First slide"/>
                            </div>
                            <div className="carousel-item">
                                <img className="d-block w-100" src={require("./assets/images/index.png")} alt="First slide"/>
                            </div> */}
                    </div>
                    <a
                      className="carousel-control-prev"
                      href="#carouselExampleControls"
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">{t("home.Previous")}</span>
                    </a>
                    <a
                      className="carousel-control-next"
                      href="#carouselExampleControls"
                      role="button"
                      data-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">{t("home.Next")}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 home-box-mb-20">
              <div className="home-box">
                <div className="row">
                  <div className="col">
                    <h5 className="blockHeading">
                      {t("home.comments_of_words_thinker")}
                    </h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>{t("home.forward_to_cure_without_medicine")}</p>
                    <p className=" font-weight-bolder commentOfWorldThinkerFor">
                      {t("home.dr_little_physics")}

                      <br />
                      {t("home.Harvard University")}
                    </p>
                    <Link to="about-us/5">
                      <button className="btn btn-outline-brown btn-sm mb-3">
                        {t("home.READ MORE")}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
        <Footer
          share_image_url={this.state.share_image_url}
          share_current_image_url={this.state.share_current_image_url}
        />
        {/* <div className="container-fluid bg-grey mt-4">
        <div className="row grey-text">
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Residential Study Courses</a>
                <a href="#" className="grey-text d-block py-1">1-day Courses</a>
                <a href="#" className="grey-text d-block py-1">Machine Tests</a>
                <a href="#" className="grey-text d-block py-1">Rational Poojas</a>
                <a href="#" className="grey-text d-block py-1">Free Events</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Books</a>
                <a href="#" className="grey-text d-block py-1">CDs</a>
                <a href="#" className="grey-text d-block py-1">Kits (Set of Books)</a>
                <a href="#" className="grey-text d-block py-1">Experimental Products</a>
                <a href="#" className="grey-text d-block py-1">Ayurvedic Products</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Magazines</a>
                <a href="#" className="grey-text d-block py-1">Gallery</a>
                <a href="#" className="grey-text d-block py-1">Downloads</a>
                <a href="#" className="grey-text d-block py-1">FAQs</a>
                <a href="#" className="grey-text d-block py-1">E-Shopping Help</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">About Us</a>
                <a href="#" className="grey-text d-block py-1">Research</a>
                <a href="#" className="grey-text d-block py-1">Event Calendar</a>
                <a href="#" className="grey-text d-block py-1">Feedback</a>
                <a href="#" className="grey-text d-block py-1">Contact Us</a>
            </div>
        </div>
    </div> */}
        {/* <div className="container-fluid bg-black">
        <p className="text-center py-3 grey-text mb-0">
            © 2000-2017 Manashakti Rest New Way, | All Rights Reserved Worldwide
        </p>
    </div> */}
      </div>
    );
  }
}
App.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(App);
