import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backgroundEvent from './assets/images/machine-tests-bg.png';
import backgroundEvent_mr from './assets/images/Machine_Tests_Marathi_mr.jpg';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import backgroundEvent1 from './assets/images/machine_test.jpg';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import './assets/fontawesome/css/all.min.css';
import Header from './Header.js';
import Footer from './Footer.js';
import * as myConstClass from './constant.js';
import $ from 'jquery';
import thumbImage from './assets/images/thumb.png';
import ReactTooltip from 'react-tooltip';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
//import { toast } from 'react-toastify';


import MachineTests_mr from './assets/images/Machine_Tests_m_mr.jpg';
import MachineTests from './assets/images/MachineTests.jpg';

import {
    Col,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';


function DesktopDesign(props) {
    return (
        <Row className="d-xl-flex d-lg-flex d-md-flex d-none">
            <Col xl="5" lg="5" md="5">
                <h4>
                    <Link style={{ color: '#212529' }} to={"/event_details/" + props.cell.event_id}>
                        {props.cell.title}
                    </Link>
                </h4>
                <Col xl="12" lg="12" md="12">
                    {props.cell.topic.map((info, ii) => {

                        if (ii == 0) {
                            return (
                                <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                    <Label className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                        {info.name}
                                    </Label>
                                </Link>
                            )
                        } else {
                            return (
                                <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                    <Label style={{ marginLeft: '5px' }} className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                        {info.name}
                                    </Label>
                                </Link>
                            )
                        }
                    }
                    )}
                </Col>
            </Col>
            <Col xl="7" lg="7" md="7">
                <Row>
                    <Col xl="2" lg="2" md="2">
                        <span className="mr-2">
                            <i className="far fa-comment-dots orangeColor"></i> <span className="small">{props.cell.total_review}</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{props.cell.total_like}</span>
                        </span>
                    </Col>

                    <Col xl="4" lg="4" md="4">
                        <Label className="d-xl-block d-lg-block d-inline">
                            {props.t('home.Age Group')}&nbsp;
                        </Label>
                        <Label className="d-xl-block d-lg-block d-inline">
                            {props.cell.age_group}
                        </Label>
                        <p className="mb-0 d-xl-block d-lg-block d-none">
                            <Link style={{ textDecoration: 'underline' }} to={"/event_details/" + props.cell.event_id}>{props.t('home.Know More')}</Link>
                        </p>
                    </Col>
                    <Col xl="2" lg="2" md="2">
                        <Label>{props.cell.first_price_for}</Label>
                        <input type="hidden" name="topicevent_id" id="topicevent_id" value={props.cell.topic_id_value} />
                        <select className="form-control form-control-sm" name={"parent" + props.cell.event_id} id={"parent" + props.cell.topic_id_value}   >
                            <option value="0" selected="selected">00</option>
                            <option value="1" >01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </select>
                    </Col>

                    <Col xl="4" lg="4" md="4" className="text-right">
                        <h5>
                            {props.t('home.INR')} {props.cell.first_price}/- <small style={{ fontWeight: '700', textTransform: 'lowercase' }}>{props.t('home.per')} {props.cell.first_price_for}</small>
                        </h5>


                        {props.cell.added_in_cart.map((carts, ii) => {
                            if (carts.cart_id > 0) {
                                return (
                                    <label>
                                        <button className="btn btn-sm mt-4 btn-block" style={{ fontWeight: '700', backgroundColor: '#C0C0C0', color: 'black' }} name={props.cell.event_id} id={props.cell.event_id} custom-attribute={props.cell.topic_id_value}  onClick={props.addToCart}>{props.t('home.ADDED TO CART')}</button>
                                    </label>

                                )
                            } else {
                                return (
                                    <label>
                                        <button className="btn btn-sm btn-outline-brown color-red mt-4 btn-block" name={props.cell.event_id} id={props.cell.event_id} onClick={props.addToCart} custom-attribute={props.cell.topic_id_value}  style={{ fontWeight: '700' }}>{props.t('home.ADD TO CART')}</button>
                                        <span id={'added_in_cart_' + props.cell.event_id} style={{ display: 'none', fontWeight: '700', width: '112px' }} className="btn btn-sm btn-light mt-4" >{props.t('home.ADDED TO CART')}</span>
                                    </label>
                                )
                            }
                        })
                        }

                    </Col>
                </Row>
            </Col>
        </Row>
    )

}

function MobileDesign(props) {
    return (
        <Row className="d-xl-none d-lg-none d-md-none d-flex">
            <input type="hidden" name="MachineTestModalObjectm" id="MachineTestModalObjectm" value="" />
            <Col xs="12" sm="12">
                <Link to={"/event_details/" + props.cell.event_id}>
                    <h5>
                        {props.cell.title}
                    </h5>
                </Link>
            </Col>
            <Col sm="12" xs="12">
                <Row>
                    <Col sm="12" xs="12">
                        {props.cell.topic.map((info, ii) => {

                            if (ii == 0) {
                                return (
                                    <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                        <Label className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                            {info.name}
                                        </Label>
                                    </Link>
                                )
                            } else {
                                return (
                                    <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                        <Label style={{ marginLeft: '5px' }} className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                            {info.name}
                                        </Label>
                                    </Link>
                                )
                            }
                        }
                        )}
                    </Col>
                    <Col sm="12" xs="12">
                        <Label>
                            {props.t('home.Age Group')}&nbsp;
                    </Label>
                        <Label className="font-weight-normal">
                            {props.cell.age_group}
                        </Label>
                    </Col>
                    <Col sm="6" xs="6" className="align-self-center">
                        <span className="font-weight-bold">
                            {props.t('home.INR')}
                            {props.cell.first_price}/-
                    </span>
                        <small className="text-lowercase ">
                            {props.t('home.per')} {props.cell.first_price_for}
                        </small>
                    </Col>
                    <Col sm="3" xs="3">
                        <Label>{props.cell.first_price_for}</Label>
                        <select className="form-control form-control-sm" name={"parentm" + props.cell.event_id} id={"parentm" + props.cell.event_id}>
                            <option value="0" selected="selected">00</option>
                            <option value="1" >01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </select>
                    </Col>
                </Row>

            </Col>
            <Col sm="12" xs="12">
                <Row>
                    <Col sm="6" xs="6" className="align-self-start">
                        <span className="mr-2">
                            <i className="far fa-comment-dots orangeColor"></i>
                            <span className="small">{props.cell.total_review}</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{props.cell.total_like}</span>
                        </span>
                    </Col>
                    <Col sm="6" xs="6" className="align-self-start">
                        {props.cell.added_in_cart.map((carts, ii) => {
                            if (carts.cart_id > 0) {
                                return (
                                    <label>
                                        <button className="btn btn-sm mt-4 btn-block" style={{ fontWeight: '700', backgroundColor: '#C0C0C0', color: 'black', width: '115px' }} name={props.cell.event_id} id={props.cell.event_id} onClick={props.addToCart}>{props.t('home.ADDED TO CART')}</button>
                                    </label>

                                )
                            } else {
                                return (
                                    <label>
                                        <button className="btn btn-sm btn-outline-brown color-red mt-4 btn-block" name={props.cell.event_id} id={props.cell.event_id} onClick={props.addToCart} style={{ fontWeight: '700' }}>{props.t('home.ADD TO CART')}</button>
                                        <span id={'added_in_cart_' + props.cell.event_id} style={{ display: 'none', fontWeight: '700', width: '112px' }} className="btn btn-sm btn-light mt-4" >{props.t('home.ADDED TO CART')}</span>
                                    </label>
                                )
                            }
                        })
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    )

}

class Machine_test extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventdetails: {
                event_type: '',
                event_description: '',
            },
            guestinfo: {
                event_type: '',
                event_type_id: '',
                topic_id: '',
                event_type_name: '',
                event_id: '0',
                schedule_event_name: '',
                language_id: '',

            },
            flag: 0,
            quantityValue: '1',
            posts: [],
            eventinfo: [],
            languages: [],
            topic: [],
            eventTypes: [],
            scheduleEvent: [],
            cart: [],
            cartEvent: [],
            removeFromCart: [],
            eventdetails: [],

            types: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.increaseQuantity = this.increaseQuantity.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.deleteFromCart = this.deleteFromCart.bind(this);
        //this.showSchedule = this.showSchedule.bind(this);
        this.showMoreEvents = this.showMoreEvents.bind(this);
        this.addToCartContinue = this.addToCartContinue.bind(this);
        this.goToCart = this.goToCart.bind(this);
    }


    goToCart() {
        var user_id = localStorage.getItem('user_id');
        if (user_id > 0 && user_id != undefined) {
            if(parseInt(localStorage.getItem("cartCount")) > 0) {
                this.props.history.push('/mycart');
            } else {
                this.props.history.push('/mycart');
                // if (getLanguage() == 'ma') {
                //     var message = "कार्यक्रमाचे वेळापत्रक उपलब्ध नाही.";
                // } else {
                //     var message = "Event schedule not available.";
                // }
                // toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
            }
        } else {
            var message = "Please login to our website.";
            localStorage.removeItem('error');
            localStorage.setItem('error', message);
            this.props.history.push('/signin')
            //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

        }
    }
    handleChange(evt) {
        // check it out: we get the evt.target.name (which will be either "email" or "password")
        // and use it to target the key on our `state` object with the same name, using bracket syntax
        this.setState({ [evt.target.name]: evt.target.value });
    }
    increaseQuantity(evt) { //console.log(evt);
        var qty = evt.target.value;
        var qty = parseInt(qty) + 1; //alert(evt.target.value);
        // check it out: we get the evt.target.name (which will be either "email" or "password")
        // and use it to target the key on our `state` object with the same name, using bracket syntax
        this.setState({ [evt.target.name]: qty });
    }
    componentDidMount() {
        if (getLanguage() == 'ma') {
            $('#background_2').css('display', 'block');
            $('#background_1').css('display', 'none');
        } else {
            $('#background_1').css('display', 'block');
            $('#background_2').css('display', 'none');
        }

        if (getLanguage() == 'ma') {
            this.state.MachineTests = MachineTests_mr;
        } else {
            this.state.MachineTests = MachineTests;
        }
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }
        var user_id = localStorage.getItem('user_id');
        const answer_array = this.props.location.pathname.split('/');
        var event_type = answer_array['3'];
        var topic_id = answer_array['4'];

        if (user_id == '' || user_id == undefined) {
            user_id = 0;
        }
        //var language_id =  answer_array['5'];

        if (topic_id > 0) {
            this.state.guestinfo.topic_id = topic_id;
        } else {
            var topic_id = 0;
        }

        // if(language_id > 0){
        //     this.state.guestinfo.language_id = language_id; 
        // }else{
        //     var language_id = 0;
        // }

        // alert(topic_id);
        if (event_type > 0) {
            this.state.guestinfo.event_type_id = event_type;
        } else {
            var event_type = 0;
        }

        console.log("Url : ", myConstClass.BASE_URL + 'event/getEventsFrontEnd' + '/' + getLanguage() + '/' + event_type + '/' + topic_id + '/0/' + user_id)

        fetch(myConstClass.BASE_URL + 'event/getEventsFrontEnd' + '/' + getLanguage() + '/' + event_type + '/' + topic_id + '/0/' + user_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ posts: response })
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                this.state.guestinfo.event_type_name = answer_array['2'];
                this.state.guestinfo.event_type = answer_array['2'];
                this.state.guestinfo.event_id = this.state.posts[0].event_id;
                if (this.state.posts.length > 0) {
                    var numRows = this.state.posts[0].arr_events.length;
                    if (numRows < 1) {
                        $('#productNotAvailable').css('display', 'block');
                    } else {
                        $('#productNotAvailable').css('display', 'none');
                    }
                } else {
                    $('#productNotAvailable').css('display', 'block');
                }
            })
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'event/getEventTypeDetails' + '/' + getLanguage() + '/' + answer_array['3'] + '/' + user_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ eventdetails: response })
                //const numRows = this.state.scheduleEvent.length;

            })


        fetch(myConstClass.BASE_URL + 'cart/getMachineTestEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage())
            .then(response => response.json())
            .then(response => {
                this.setState({ cartEvent: response })

                const numRows = this.state.cartEvent.length;
                localStorage.setItem("cartCount",numRows)
                if (numRows > 0) {
                    $('#noItemInCart').css('display', 'none');
                } else {
                    $('#noItemInCart').css('display', 'block');
                }

            })


        fetch(myConstClass.BASE_URL + 'event/getEventTypes')
            .then(response => response.json())
            .then(response => this.setState({ eventTypes: response }))
            .catch(err => console.log(err))


        fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ topic: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'product/getProductLanguages')
            .then(response => response.json())
            .then(response => this.setState({ languages: response }))
            .catch(err => console.log(err))

    }

    componentDidUpdate(prevProps) {
        var user_id = localStorage.getItem('user_id');
        if (user_id == '' || user_id == undefined) {
            user_id = 0;
        }
        if (getLanguage() == 'ma') {
            $('#background_2').css('display', 'block');
            $('#background_1').css('display', 'none');
        } else {
            $('#background_1').css('display', 'block');
            $('#background_2').css('display', 'none');
        }
        const answer_array = this.props.location.pathname.split('/');
        //alert(answer_array['2']);
        const { flag } = this.state
        var current_product_type = answer_array['3'];
        //alert(answer_array['3']);
        var old_event_type = localStorage.getItem('old_event_type');
        localStorage.setItem('old_event_type', current_product_type);

        var topic_id = $('#topic_id').val();
        if (topic_id == '') {
            topic_id = 0;
        }
        var old_topic_id = localStorage.getItem('old_topic_id');
        localStorage.setItem('old_topic_id', topic_id);

        //alert(old_event_type);
        if (old_event_type != '' && flag != 1 && (current_product_type != old_event_type || topic_id != old_topic_id) && topic_id > 0 && old_event_type != null && old_event_type > 0) {
            this.setState({ flag: 1 });
            //alert('hi');
        }
        else {
            if (current_product_type == 0 && flag != 1 && (current_product_type != old_event_type || topic_id != old_topic_id) && old_event_type != null) {
                //alert('hii')
                this.setState({ flag: 1 });
            }
        }



        var current_topic_id = answer_array['4'];

        var old_topic_id = localStorage.getItem('old_topic_id');
        if (old_topic_id == undefined) {
            old_topic_id = answer_array['4'];
        }
        //alert('current_topic_id'+current_topic_id+'old_topic_id'+old_topic_id);
        localStorage.setItem('old_topic_id', current_topic_id);

        if (old_topic_id != '' && flag != 1 && current_topic_id != old_topic_id && old_topic_id != null && old_topic_id > 0 && answer_array['4'] > 0) {
            this.setState({ flag: 1 });
            //alert('hi');
        }

        var event_type = answer_array['3'];

        var topic_id = answer_array['4'];
        if (topic_id > 0) {
            this.state.guestinfo.topic_id = topic_id;
        } else {
            var topic_id = 0;
        }
        // alert(event_type);
        if (event_type > 0) {
            this.state.guestinfo.event_type_id = event_type;
        } else {
            var event_type = 0;
        }
        //const { flag} = this.state  
        if (getLanguage() == 'ma' && prevProps.history.location.pathname !== prevProps.location.pathname) {
            if (getLanguage() == 'ma') {
                this.state.MachineTests = MachineTests_mr;
            } else {
                this.state.MachineTests = MachineTests;
            }
            this.setState({ flag: 1 })
            fetch(myConstClass.BASE_URL + 'event/getEventsFrontEnd' + '/' + getLanguage() + '/' + event_type + '/' + topic_id + '/0/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response })
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                    this.state.guestinfo.event_type_name = answer_array['2'];
                    if (this.state.posts.length > 0) {
                        var numRows = this.state.posts[0].arr_events.length;
                        if (numRows < 1) {
                            $('#productNotAvailable').css('display', 'block');
                        } else {
                            $('#productNotAvailable').css('display', 'none');
                        }
                    } else {
                        $('#productNotAvailable').css('display', 'block');
                    }
                })

            fetch(myConstClass.BASE_URL + 'event/getEventTypeDetails' + '/' + getLanguage() + '/' + answer_array['3'])
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventdetails: response })
                    //const numRows = this.state.scheduleEvent.length;

                })


            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))
        }

        if (getLanguage() == 'en' && (prevProps.history.location.pathname !== prevProps.location.pathname)) {
            if (getLanguage() == 'ma') {
                this.state.MachineTests = MachineTests_mr;
            } else {
                this.state.MachineTests = MachineTests;
            }
            this.setState({ flag: 0 })
            fetch(myConstClass.BASE_URL + 'event/getEventsFrontEnd' + '/' + getLanguage() + '/' + event_type + '/' + topic_id + '/0/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response })
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                    this.state.guestinfo.event_type_name = answer_array['2'];
                    if (this.state.posts.length > 0) {
                        var numRows = this.state.posts[0].arr_events.length;
                        if (numRows < 1) {
                            $('#productNotAvailable').css('display', 'block');
                        } else {
                            $('#productNotAvailable').css('display', 'none');
                        }
                    } else {
                        $('#productNotAvailable').css('display', 'block');
                    }
                })

            fetch(myConstClass.BASE_URL + 'event/getEventTypeDetails' + '/' + getLanguage() + '/' + answer_array['3'])
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventdetails: response })
                    //const numRows = this.state.scheduleEvent.length;

                })

            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))


        }
        this.state.guestinfo.event_type_name = answer_array['2'];
    }

    changeQuantity(e, product_id) {
        //this.state.quantityValue = event.target.value;
        // this.setState(
        //     {quantityValue : event.target.value}
        // );
        //alert(product_id);
        // this.setState({quantityValue: e.target.value});
        // document.getElementById("qty"+product_id).value = e;

    }

    // showSchedule(e){
    //     var schedule_event_schedule_id = e.target.id;
    //     var arr_event_schedule = schedule_event_schedule_id.split('_');
    //     var event_id = arr_event_schedule[1];
    //     var user_id = localStorage.getItem('user_id');


    //     fetch(myConstClass.BASE_URL+'event/getAllScheduleEventFrontEnd'+'/'+getLanguage()+'/'+event_id+'/'+user_id)
    //     .then(response => response.json())
    //     .then(response =>{this.setState({scheduleEvent: response})
    //     const numRows = this.state.scheduleEvent.length;
    //     $('#scheduleMainDiv').css('display','block');
    //     $('#withoutScheduleDiv').css('display','none');
    //     $('#eventRightMenuMain').css('margin-top','0px');
    //     if(numRows < 1){
    //         $('#noScheduleAvailable').css('display','block');
    //         $('#eventRightMenuMain').css('margin-top','85px')
    //     }else{
    //         this.state.guestinfo.schedule_event_name = this.state.scheduleEvent[0].title;
    //         $('#noScheduleAvailable').css('display','none');

    //     }
    // })
    //     .catch(err => console.log(err))

    // }

    showMoreEvents(e) {
        var show_more_event_type_id = e.target.id;
        var arr_show_more_event_type = show_more_event_type_id.split('_');
        var current_event_type_id = arr_show_more_event_type[1];
        $('.showMoreEventsDiv_' + current_event_type_id).css('display', 'block');
        $('#showMoreEvents_' + current_event_type_id).css('display', 'none');
    }


    deleteFromCart(e) {
        var delete_event_schedule_id = e.target.id;
        var arr_event_schedule = delete_event_schedule_id.split('_');
        var event_id = arr_event_schedule[1];
        // var event_schedule_id = event_id+'_'+schedule_id;
        // console.log(e.target.id);
        //alert(event_id);

        var user_id = localStorage.getItem('user_id');
        //console.log(product_quantity);

        if ((event_id > 0) && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/removeProductFromCart/' + event_id + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ removeFromCart: response })
                    var message = this.state.removeFromCart.message;
                    var status = this.state.removeFromCart.status;

                    if (status == 'true') {
                        //alert(event_id);
                        $('#added_in_cart_' + event_id).css('display', 'none');
                        $('#remove' + event_id).css('display', 'none');
                        $('#' + event_id).css('display', 'inline'); //for delete


                   //     $("button[custom-attribute='"+topicevent_id+"']").addClass('btn-outline-brown');
                   //     $("button[custom-attribute='"+topicevent_id+"']").removeClass('btn-sm btn-light');

                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });

                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                })


                            fetch(myConstClass.BASE_URL + 'cart/getMachineTestEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage())
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartEvent: response })
                                    const numRows = this.state.cartEvent.length;
                                    localStorage.setItem("cartCount",numRows)
                                    if (numRows > 0) {
                                        $('#noItemInCart').css('display', 'none');
                                    } else {
                                        $('#noItemInCart').css('display', 'block');
                                    }
                                })
                        }


                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                toast('Error occure.', { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
                //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

            }

        }
        //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
        //this.props.offline();    
    }

    addToCart(e) {

        var custome_id = (e.currentTarget.getAttribute('custom-attribute'));
 
        //$('#MachineTestModalObject').val(e);
        var event_schedule_id = e.target.id;
        $('#MachineTestModalObject').val(event_schedule_id);
        $('#MachineTestModalObjectm').val(event_schedule_id);
        $('#topicevent_id').val(custome_id);
        var arr_event_schedule = event_schedule_id.split('_');
        var event_id = arr_event_schedule[0];

        var previous_event_id = localStorage.getItem('current_schedule_id');
        //alert(previous_event_id);
        // $(".eventrow").removeClass("border-warning ");
        $(".eventrow").removeClass("bg-white");
        $(".eventrow").addClass("border-dark bg-light-light");

        $("#schedule_" + previous_event_id).removeClass("btn-light");
        $("#schedule_" + previous_event_id).addClass("btn-outline-brown");
        $("#schedule_" + previous_event_id).css("color", 'red');

        $("#schedule_" + event_id).removeClass("btn-outline-brown");
        $("#schedule_" + event_id).addClass("btn-light");
        $("#schedule_" + event_id).css("color", 'black');


        $(".Row_" + event_id).removeClass("border-dark bg-light-light");
        $(".Row_" + event_id).addClass("border-warning bg-white");

        localStorage.removeItem('current_schedule_id');
        localStorage.setItem('current_schedule_id', event_id);


        $('#MachineTestModal').modal('show');
        //alert(e);
    }

    addToCartContinue() {
        $('#MachineTestModal').modal('hide');
        var event_id = $('#MachineTestModalObject').val();
        if (event_id > 0) {
 
        } else {
            var event_id = $('#MachineTestModalObjectm').val();
        }
 
//      var parent = $('#parent' + event_id).val();
        var topicevent_id = $('#topicevent_id').val();
        var parent = $('#parent' + topicevent_id).val();
        if (parent > 0) {

        } else {
            var parent = $('#parentm' + event_id).val();
        }

        var child = $('#child' + event_id).val();
        if (child > 0) {

        } else {
            var child = $('#childm' + event_id).val();
        }
        var user_id = localStorage.getItem('user_id');

        if (child == undefined) {
            child = 0;
        }

        if ((parent > 0 || child > 0) && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/addMachineTestEventToCart/' + event_id + '/' + user_id + '/' + parent + '/' + child)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cart: response })
                    var message = this.state.cart.message;
                    var status = this.state.cart.status;
                    console.log('status' + status);
                    if (status == 'true') {

                        //$('#'+event_id).css('display','none');
                        $('#' + event_id).removeClass('btn-outline-brown');
                        $('#' + event_id).addClass('btn-sm btn-light');

                        $("button[custom-attribute='"+topicevent_id+"']").removeClass('btn-outline-brown');
                        $("button[custom-attribute='"+topicevent_id+"']").addClass('btn-sm btn-light');

                        if (getLanguage() == 'ma') {
                            $('#' + event_id).html('अधिक घ्या');
                            $("button[custom-attribute='"+topicevent_id+"']").html('अधिक घ्या');
                        } else {
                            $('#' + event_id).html('ADD MORE');
                                
                            $("button[custom-attribute='"+topicevent_id+"']").html('ADD MORE');
                        }


                        //$('#added_in_cart_'+event_id).css('display','block');
                        // $('#delete_'+event_schedule_id).css('display','contents');

                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                })


                            fetch(myConstClass.BASE_URL + 'cart/getMachineTestEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage())
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartEvent: response })
                                    const numRows = this.state.cartEvent.length;
                                    localStorage.setItem("cartCount",numRows)
                                    if (numRows > 0) {
                                        $('#noItemInCart').css('display', 'none');
                                    } else {
                                        $('#noItemInCart').css('display', 'block');
                                    }
                                })

                        }

                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                if (getLanguage() == 'ma') {
                    var message = "कृपया सहभागींची संख्या निवडा.";
                } else {
                    var message = "Please select the number of participants.";
                }
                toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
            }

        }
    }

    mobileApplyFilter = () => {
        this.applyFilters($('#topic_id_m').val(), $('#language_id_m').val())
    }

    desktopApplyFilter = () => {
        this.applyFilters($('#topic_id').val(), $('#language_id').val())
    }

    applyFilters(topic_id, language_id) {
        var user_id = localStorage.getItem('user_id');
        const answer_array = this.props.location.pathname.split('/');
        if (topic_id == '') {
            topic_id = 0;
        }
        if (language_id == '') {
            language_id = 0;
        }
        var event_type = answer_array['3'];
        if (event_type == 1) {
            var path = 'Residential Study Courses/1' + '/' + topic_id + '/0/' + user_id;
        } else if (event_type == 2) {
            var path = "1-Day Workshops/2" + '/' + topic_id + '/0/' + user_id;
        } else if (event_type == 4) {
            var path = "Rational Poojs/4" + '/' + topic_id + '/0/' + user_id;
        } else if (event_type == 3) {
            var path = "Machine Tests/3" + '/' + topic_id + '/0/' + user_id;
        } else if (event_type == 5) {
            var path = "Free Events/5" + '/' + topic_id + '/0/' + user_id;
        } else if (event_type == 6) {
            var path = "Organize a Trip/6" + '/' + topic_id + '/0/' + user_id;
        } else {
            var path = "Events/0" + '/' + topic_id + '/0/' + user_id;
        }
        //alert(event_type);
        if (event_type == 3) {
            this.props.history.push('/machine-test/' + path);
        } else {
            this.props.history.push('/events/' + path);
        }

        if (topic_id == '' || topic_id == 0) {
            fetch(myConstClass.BASE_URL + 'event/getEventsFrontEnd' + '/' + getLanguage() + '/' + event_type + '/' + topic_id + '/0/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response })
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                    this.state.guestinfo.event_type_name = answer_array['2'];
                    if (this.state.posts.length > 0) {
                        var numRows = this.state.posts[0].arr_events.length;
                        if (numRows < 1) {
                            $('#productNotAvailable').css('display', 'block');
                        } else {
                            $('#productNotAvailable').css('display', 'none');
                        }
                    } else {
                        $('#productNotAvailable').css('display', 'block');
                    }
                })

            fetch(myConstClass.BASE_URL + 'event/getEventTypeDetails' + '/' + getLanguage() + '/' + answer_array['3'])
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventdetails: response })
                    //const numRows = this.state.scheduleEvent.length;

                })

            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))

        }

        //this.props.history.push('/events/'+path);
        //    const answer_array = this.props.location.pathname.split('/');
        //    var event_type =  $('#event_type_id').val();


        //    //var event_type =  answer_array['3'];
        //      // alert(event_type);
        //      if(event_type > 0){
        //         this.state.guestinfo.event_type_id = event_type; 
        //      }else{
        //         var event_type = 0; 
        //      }

        //    fetch(myConstClass.BASE_URL+'event/getEventsFrontEnd'+'/'+getLanguage()+'/'+event_type)
        //    .then(response => response.json())
        //    .then(response =>{this.setState({posts: response})
        //        this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
        //        console.log('hi'+this.state.posts[0].event_type);
        //    })

        // this.componentDidMount();
        //console.log(this.state.posts);
        //
        //console.log(this.state.eventinfo);
        //this.state.posts = this.state.eventinfo;
        //this.setState({ posts: this.state.eventinfo });
    }

    renderType = ({ id, title }) => <div className="row">
        <div className="col">
            <div className="col pt-3">
                <h5 className="font-weight-bold">{title}</h5>
            </div>
        </div>
    </div>

    renderGuest = ({ type_id, title, price, quantity }) =>
        <div>
            <div className="row">
                <div className="col-md-3 mb-3">
                    <div className="col border text-center">
                        <div className=" py-3">
                            <img src={require("./assets/images/product1.png")} />
                            <div className="my-2">
                                <span className="mr-2">
                                    <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                                </span>
                                <span>
                                    <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                                </span>
                            </div>
                            <p className="font-weight-bold mb-1">{title}</p>
                            <p className="mb-1">Donation Contribution</p>
                            <p className="font-weight-bolder">INR. {price}/-</p>
                            <div className="qty mb-3">
                                <span>Qty-</span>
                                <span className="minus border">
                                    <span className="d-flex align-items-center ico-hld">
                                        <i className="fas fa-minus"></i>
                                    </span>
                                </span>
                                <input type="number" className="count" name="qty" value={quantity} />
                                <span className="plus border">
                                    <span className="d-flex align-items-center ico-hld">
                                        <i className="fas fa-plus"></i>
                                    </span>
                                </span>
                            </div>
                            <button className="btn btn-brown btn-sm">ADD TO CART</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    render() {
        const { cartEvent, eventdetails, scheduleEvent, eventTypes, posts, types, languages, topic, guestinfo } = this.state
        const { t } = this.props;
        return (
            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />

                <div className="container-fluid">
                    <div className={this.props.location.pathname.split("/")[4] !== undefined ? "d-block" : "d-xl-block d-lg-block d-md-block d-none"}>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb bg-white mb-0">
                                            <li className="breadcrumb-item">
                                                <Link to="/">{t('home.home')}</Link>
                                                {/* <a href="#">{t('home.home')}</a> */}
                                            </li>
                                            <li className="breadcrumb-item">

                                                {/* <Link   to="/events">{t('home.events')}</Link> */}
                                                <a href="javascript:void(0)">{t('home.events')}</a>

                                                {/* <a href="#">{t('home.events')}
                                </a> */}
                                            </li>
                                            <li className="breadcrumb-item active"><a href="#">
                                                {eventdetails.map((infos, ii) => {
                                                    return (
                                                        <span>{infos.event_type} </span>
                                                    )
                                                })
                                                }
                                            </a></li>
                                        </ol>
                                    </nav>



                                </div>
                                <div className="row">
                                    <div className="col d-xl-flex d-lg-flex d-md-flex d-none">

                                        <div id="background_1" className="col p-3 text-white mb-4 relatedProductsImage manashaktiTestEnglish" style={{ backgroundImage: "url(" + backgroundEvent1 + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'none' }} >



                                        </div>
                                        <div id="background_2" className="col p-3 text-white mb-4 relatedProductsImage manashaktiTestMarathi" style={{ backgroundImage: "url(" + backgroundEvent_mr + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'none' }} >



                                        </div>
                                    </div>
                                    <div className="col d-xl-none d-lg-none d-md-none d-flex my-3">
                                        <img src={this.state.MachineTests} height={77} />
                                    </div>

                                </div>
                                <div className="row">
                                    {eventdetails.map((infos, ii) => {
                                        return (
                                            <span>
                                                <div className="col-md-12">
                                                    <p className="fontWeight700">
                                                        {t('home.For Happiness & Balance')}
                                                    </p>
                                                    <p style={{ columnCount: '2' }} className="d-xl-block d-lg-block d-md-block d-none">
                                                        {infos.event_description}
                                                    </p>
                                                </div>
                                            </span>
                                        )
                                    })
                                    }
                                </div>
                                <Row className="bg-light m-0 py-2">
                                    <Col xl="4" lg="4" md="4" className="text-left" style={{ marginTop: '33px' }}>
                                        {t('home.Select_filters_to_refine_the_results_below')}:
                                </Col>

                                    {/* Desktop Design */}
                                    <Col xl="4" lg="4" md="4" className="d-xl-flex d-lg-flex d-md-flex d-none">
                                        <div className="form-group" style={{ width: '100%' }}>
                                            <label for="">{t('home.Topic')}</label>

                                            <select data-dropup-auto="false" id="topic_id" name="topic_id" className=" form-control form-control-sm">
                                                <option value="">{t('home.All')}</option>
                                                {topic.map((info, ii) => {
                                                    if (guestinfo.topic_id == info.value) {
                                                        return (
                                                            <option value={info.value} selected="selected">{info.label}</option>
                                                        )
                                                    } else {
                                                        return (
                                                            <option value={info.value}>{info.label}</option>
                                                        )
                                                    }
                                                })
                                                }
                                            </select>

                                        </div>
                                    </Col>

                                    <Col xl="2" lg="2" md="2" className="d-xl-flex d-lg-flex d-md-flex d-none">
                                        <div className="form-group">
                                            <button style={{ marginTop: '28px' }} onClick={this.desktopApplyFilter} className="btn btn-sm btn btn-outline-brown btn-block ">
                                                {t('home.APPLY FILTERS')}

                                            </button>
                                        </div>
                                    </Col>

                                    <Col sm="12" xs="8" className="d-xl-none d-lg-none d-md-none d-flex">
                                        <FormGroup>
                                            <Label for="">{t('home.Topic')}</Label>

                                            <Input type="select" data-dropup-auto="false" id="topic_id_m" name="topic_id_m" bsSize="sm" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                                <option value="">{t('home.All')}</option>
                                                {topic.map((info, ii) => {
                                                    if (guestinfo.topic_id == info.value) {
                                                        return (
                                                            <option className="selectOrange" value={info.value} selected="selected">{info.label}</option>
                                                        )
                                                    } else {
                                                        return (
                                                            <option className="selectOrange" value={info.value}>{info.label}</option>
                                                        )
                                                    }
                                                })
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12" xs="12" className="d-xl-none d-lg-none d-flex">
                                        <div className="form-group">
                                            <button onClick={this.mobileApplyFilter} className="btn btn-sm btn btn-outline-brown btn-block ">
                                                {t('home.APPLY FILTERS')}
                                            </button>
                                        </div>
                                    </Col>
                                    {/* Mobile Design End */}
                                </Row>
                                <div className="row">
                                    <div className="col-12">
                                        <h6 className="color-red my-2 fontWeight700">
                                            {t('home.Step 1')}:&nbsp;
                                        {t('home.Please_call_and_take_and_appointment_before_booking_the_Test.')}


                                        </h6>
                                        <p>
                                            {t('home.Contact on Machine')} : {t('home.Monday_to_Friday')}


                                            <br /> {t('home.Phone')}: {t('home.Phone_all')}

                                        </p>
                                    </div>
                                </div>
                            </div>




                            <div className="col-lg-9 mt-3" style={{ maxHeight: '900px', overflowY: 'scroll' }}>
                                {posts.map((info, ii) => {
                                    if (info.arr_events.length > 4) {
                                        return (
                                            <div >
                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <h4 style={{ fontSize: '1.4rem' }}>
                                                            {/* {info.topic_title} */}
                                                            {info.topic_title}
                                                        </h4>
                                                        <h6 className="color-red my-2 mb-4 fontWeight700">
                                                            {t('home.Step 2')}:&nbsp;

                         {t('home.Click on Show Schedule of the workshop you intend to attend')}

                                                        </h6>
                                                    </div>
                                                </div>

                                                {info.arr_events.map((cell, i) => {
                                                    if (i < 4) {
                                                        return (
                                                            <div className="row px-3">
                                                                <div className={'col-12 py-2 mb-2 border border-dark bg-light-light eventrow Row_' + cell.event_id}>
                                                                    <DesktopDesign
                                                                        cell={cell}
                                                                        t={(name) => t(name)}
                                                                        showSchedule={this.showSchedule}
                                                                        addToCart={this.addToCart}
                                                                    />

                                                                    <MobileDesign
                                                                        cell={cell}
                                                                        t={(name) => t(name)}
                                                                        showSchedule={this.showSchedule}
                                                                        addToCart={this.addToCart}
                                                                    />
                                                                </div>


                                                            </div>
                                                        )
                                                        //}
                                                    } else {
                                                        //if(cell.first_price > 0 && cell.second_price > 0){    
                                                        return (
                                                            <div className={'row px-3 showMoreEventsDiv_' + info.topic_id} style={{ display: 'none' }}>
                                                                <div className={'col-12 py-2 mb-2 border border-dark bg-light-light eventrow Row_' + cell.event_id}>
                                                                    <DesktopDesign
                                                                        cell={cell}
                                                                        t={(name) => t(name)}
                                                                        showSchedule={this.showSchedule}
                                                                        addToCart={this.addToCart}
                                                                    />
                                                                    <MobileDesign
                                                                        cell={cell}
                                                                        t={(name) => t(name)}
                                                                        showSchedule={this.showSchedule}
                                                                        addToCart={this.addToCart}
                                                                    />
                                                                </div>




                                                            </div>
                                                        )
                                                        //}
                                                    }
                                                })}


                                                <div className="row">
                                                    <div className="w-100 text-right mb-2 mt-3">
                                                        <button id={'showMoreEvents_' + info.topic_id} onClick={this.showMoreEvents} className="btn btn-sm btn-brown" style={{ marginRight: '12px' }}>{t('home.SHOW MORE')}</button>
                                                        <hr className="border-dark" />
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div >
                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <h4 style={{ fontSize: '1.4rem' }}>
                                                            {info.topic_title}
                                                        </h4>
                                                        <h6 className="color-red my-2 mb-4 fontWeight700">
                                                            {t('home.Step 2')}:&nbsp;

                                 {t('home.Select_the_tests_which_have_been_prescribed_to_you')}

                                                        </h6>
                                                    </div>
                                                </div>

                                                {info.arr_events.map((cell, i) => {
                                                    // if(cell.first_price > 0 && cell.second_price > 0){  
                                                    return (
                                                        <div className="row px-3">
                                                            <div className={'col-12 py-2 mb-2 border border-dark bg-light-light eventrow Row_' + cell.event_id}>
                                                                <DesktopDesign
                                                                    cell={cell}
                                                                    t={(name) => t(name)}
                                                                    showSchedule={this.showSchedule}
                                                                    addToCart={this.addToCart}
                                                                />
                                                                <MobileDesign
                                                                    cell={cell}
                                                                    t={(name) => t(name)}
                                                                    showSchedule={this.showSchedule}
                                                                    addToCart={this.addToCart}
                                                                />
                                                            </div>




                                                        </div>
                                                    )

                                                })}


                                                <hr className="border-dark mx-n2 mt-2" />


                                            </div>
                                        )
                                    }
                                })}


                                <div className="row">
                                    <p id="productNotAvailable" style={{ display: 'none', marginLeft: '20px' }}>
                                        {t('home.Not available')}
                                    </p>
                                </div>

                            </div>






                            <div className="col-lg-3 pt-3" id="eventRightMenuMain" style={{ marginTop: '8px' }}>
                                <div id="withoutScheduleDiv">
                                    <div className="row">
                                        <div className="col">
                                            <h6 className="color-red fontWeight700">{t('home.Step 3')}:&nbsp;
                        {t('home.Machine test in the cart')}
                                            </h6>
                                            <p className="color-red">
                                                {t('home.Note')}:&nbsp; {t('home.To_change_quantity_remove_from_cart_and_add_again')}

                                            </p>
                                            {/* <p className="color-red">Note: To change quantity remove from cart and add again. </p> */}
                                        </div>
                                    </div>

                                    {/* <div className="col py-2 border border-warning bg-white box-shadow mb-3">
                    
                    
                    <div className="row d-flex">
                        <div className="col-12">
                        {t('home.Click on Show Schedule of the Study Course you intend to attend')}
                        </div>
                        
                    </div>
                   
                   
                </div> */}

                                </div>




                                {/* <div style={{display:'none'}} id="scheduleMainDiv">
                <div className="row">
                    <div className="col">
                    {scheduleEvent.map((cell, ii) => {
                         return(
                             <div>
                        <h6 className="color-red"> {t('home.Step 3')}:&nbsp;

                        
                        
                        </h6>
                        <p className="color-red">
                        {t('home.Note')}:  {t('home.To_change_quantity_remove_from_cart_and_add_again.')}
                            
                             </p>
                            </div>
                         )
                              })
                            }
                    </div>
                </div>
                <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-1">
                    
                    {scheduleEvent.map((cell, ii) => {
                        if(ii == 0){
                                return(
                    <span>
                    <p className="font-weight-bolder">{t('home.Upcoming Courses for')} &nbsp;
                    {cell.title}</p>
                   
                    </span>                
                    )}
                })
                }                       
                {scheduleEvent.map((info, i) => {
                        
                                return(
                    <span>
                         <hr className="border-dark mx-n2" />
                    <p className="orangeColor mb-0">{info.date}</p>
                    {info.arr_schedule.map((cell, ii) => {
                        if(cell.available_in_cart == 1){
                                return(  
                    <span> 
                    <hr className="border-dark mx-n2 mt-2" />
                    <div className="row d-flex align-items-center">
                        <div className="col-6">
                            <p>{cell.start_date_format} - {cell.end_date_format} <br/>
                                <a href="#">
                               
                                {cell.city_name}
                                </a>
                            </p>
                        </div>
                        <div className="col-6 pl-0 align-content-center">
                            <p className="text-right">
                            <span   className="mr-3" style={{cursor:'pointer'}}><i className="fas fa-trash-alt" name={'delete_'+cell.event_id+'_'+cell.schedule_id} id={'delete_'+cell.event_id+'_'+cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                <button style={{display:'none'}} className="btn btn-sm btn-outline-brown" name={cell.event_id+'_'+cell.schedule_id} id={cell.event_id+'_'+cell.schedule_id}  onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                <span name={'remove'+cell.schedule_id} id={'remove'+cell.schedule_id} className="text-muted">{t('home.Added in cart')}</span>
                            </p>
                        </div>
                    </div>
                    </span>                           
                        )}else{
                            return(  
                                <span> 
                                <hr className="border-dark mx-n2 mt-2" />
                                <div className="row d-flex align-items-center">
                                    <div className="col-6">
                                        <p>{cell.start_date_format} - {cell.end_date_format} <br/>
                                            <a href="#">
                                           
                                            {cell.city_name}
                                            </a>
                                        </p>
                                    </div>
                                    <div className="col-6 pl-0 align-content-center">
                                        <p className="text-right">
                                            <span style={{cursor:'pointer'}}  className="mr-3"><i className="fas fa-trash-alt" name={'delete_'+cell.event_id+'_'+cell.schedule_id} id={'delete_'+cell.event_id+'_'+cell.schedule_id} style={{display:'none',padding:'10px'}} onClick={this.deleteFromCart}></i></span>
                                           
                                            <button className="btn btn-sm btn-outline-brown" name={cell.event_id+'_'+cell.schedule_id} id={cell.event_id+'_'+cell.schedule_id}  onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                            
                                            <span className="text-muted" name={'remove'+cell.schedule_id} id={'remove'+cell.schedule_id} style={{display:'none'}}>{t('home.Added in cart')}</span>
                                        </p>
                                    </div>
                                </div>
                                </span>                           
                                    )
                        }
                    })
                    } 
                    </span>                               
                    )


                    })
                    }    


                   
                   
                   
                    
                    <hr className="border-dark mx-n2 mt-2" />

                    <span style={{marginBottom:'10px',display:'none'}} name="noScheduleAvailable" id="noScheduleAvailable" className="text-muted">{t('home.No schedule available')}</span>
                    <br/>
                   
                    <Link  className="btn btn-brown btn-sm mb-2"  to="/mycart">{t('home.GO TO CART')}</Link>
                    
                </div>

                </div> */}




                                {/* <h6 className="color-red">{t('home.Machine test in the cart')}</h6> */}
                                <div className="col py-2 mt-1 border border-dark bg-white  mb-3">
                                    {cartEvent.map((info, i) => {
                                        return (
                                            <div>
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-6 mt-2">
                                                        <p>{i + 1}. {info.title}
                                                        </p>
                                                    </div>
                                                    <div className="col-3 pl-0 align-content-center">
                                                        <p className="text-right">
                                                            {t('home.Qty')}:{info.parent}
                                                        </p>
                                                    </div>
                                                    <div className="col-3 pl-0 align-content-center">
                                                        <p className="text-right">
                                                            <span className="mr-3">
                                                                <i style={{ cursor: 'pointer' }} className="fas fa-trash-alt" name={'delete_cart_' + info.event_id} id={'delete_' + info.event_id} onClick={this.deleteFromCart}></i>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr className="border-dark mx-n2 mt-1" />
                                            </div>
                                        )
                                    })
                                    }

                                    <span style={{ marginBottom: '10px', display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted">{t('home.No items in the cart')}</span>

                                    {/* <Link  className="btn btn-brown btn-sm mb-2"  to="/mycart">{t('home.GO TO CART')}</Link> */}
                                    <button className="btn btn-brown btn-sm mb-2" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                                </div>




                            </div>
                        </div>

                    </div>

                    <div className={this.props.location.pathname.split("/")[4] === undefined ? "d-xl-none d-lg-none d-md-none d-block" : "d-none"}>
                        <Row className="mt-4">
                            <Col sm="12" xs="12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-white mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                {t('home.home')}
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item ">
                                            <a href="javascript:void(0)">
                                                {t('home.events')}
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <a href="#">
                                                {eventdetails.map((infos, ii) => {
                                                    return (
                                                        <span className="CurrentEventType">{infos.event_type} </span>
                                                    )
                                                })
                                                }
                                            </a>
                                        </li>
                                    </ol>
                                </nav>

                            </Col>
                            <Col sm="12" xs="12" className="mt-1">
                                <img src={this.state.MachineTests} height={77} />
                            </Col>
                            <Col sm="12" xs="12" className="my-3">
                                {eventdetails.map((infos, ii) => {
                                    return (
                                        <span>
                                            {infos.event_description}
                                        </span>
                                    )
                                })
                                }
                            </Col>
                            <Col sm="12" xs="12" className="my-3">
                                <h5>
                                    ALL TOPICS
                            </h5>
                            </Col>
                        </Row>

                        <Row className="px-1">
                            {
                                this.state.topic.map((topic) =>
                                    <Col sm="12" xs="12" className="bg-light py-2 border mb-2" key={topic.value}>
                                        <Link className="w-100" to={`${this.props.location.pathname}/${topic.value}`}>
                                            <div className="float-left text-dark">
                                                {topic.label}
                                            </div>
                                            <div className="float-right text-dark">
                                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                                            </div>
                                        </Link>
                                    </Col>
                                )
                            }

                        </Row>
                    </div>


                </div>


                <div className="modal fade z-index--99999" id="MachineTestModal">
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
                        <div className="modal-content bg-light-light">
                            <div className="modal-header" style={{ background: '#9d0b0e', height: '15%' }}>
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '-5px', color: 'white', fontSize: '15px' }}>

                                    {t('home.APPOINTMENT ALERT')}
                                </h5>
                                <span style={{ color: 'white', cursor: 'pointer', marginTop: '-10px' }} data-dismiss="modal" aria-label="Close">X</span>
                            </div>
                            <div className="modal-body">
                                <Row>
                                    <input type="hidden" name="MachineTestModalObject" id="MachineTestModalObject" value="" />
                                  
                                    <Col xl="12" lg="12" md="12" sm="12" xs="12">
                                        &nbsp;
                                        <img src={this.state.MachineTests} height={77} className="img-fluid d-xl-none d-lg-none d-md-none d-block" />
                                        {/* <img height="20" width="20" className="img-fluid d-xl-none d-lg-none d-md-none d-inline" src={thumbImage} /> */}
                                        <img height="36" width="36" className="img-fluid d-xl-inline d-lg-inline d-md-inline d-none" src={thumbImage} />
                                        &nbsp;  {t('home.thank you for choosing Machine Tests')}

                                        <br />
                                        <p style={{ marginTop: '13px' }}>
                                            {t('home.research_center')}
                                            <br />
                                            {t('home.avoid inconvenience to you')}


                                        </p>
                                        <p>
                                            {t('home.If you have taken the appointment please')}

                                        </p>


                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="12" lg="12" md="12" className="d-xl-flex d-lg-flex d-md-flex d-none">
                                        <button type="button" className="btn btn-brown btn-sm mb-2" onClick={this.addToCartContinue}>
                                            {t('home.CONTINUE Machine Test')}
                                        </button>
                                    </Col>
                                    <Col xl="12" lg="12" md="12" className="d-xl-flex d-lg-flex d-md-flex d-none">
                                        {t('home.call_on_machine')}
                                    </Col>
                                    <Col xl="12" lg="12" md="12" className="d-xl-flex d-lg-flex d-md-flex d-none">
                                        <button style={{ color: 'white', cursor: 'pointer' }} className="btn btn-brown btn-sm mb-2" data-dismiss="modal" aria-label="Close">
                                            {t('home.CLOSE Machine Test')}
                                        </button>
                                    </Col>

                                    <Col sm="12" xs="12" className="d-xl-none d-lg-none d-md-none d-flex">
                                        <button type="button" className="btn btn-brown btn-sm mb-2 btn-block" onClick={this.addToCartContinue}>
                                            {t('home.CONTINUE Machine Test')}
                                        </button>
                                    </Col>
                                    <Col sm="12" xs="12" className="d-xl-none d-lg-none d-md-none d-flex">
                                        {t('home.call_on_machine')}
                                    </Col>
                                    <Col sm="12" xs="12" className="d-xl-none d-lg-none d-md-none d-flex">
                                        <button className="btn btn-outline-brown btn-sm mb-2 btn-block" data-dismiss="modal" aria-label="Close">
                                            {t('home.CLOSE Machine Test')}
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
                {/* <div className="container-fluid bg-grey mt-4">
        <div className="row grey-text">
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Residential Study Courses</a>
                <a href="#" className="grey-text d-block py-1">1-day Courses</a>
                <a href="#" className="grey-text d-block py-1">Machine Tests</a>
                <a href="#" className="grey-text d-block py-1">Rational Poojas</a>
                <a href="#" className="grey-text d-block py-1">Free Events</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Books</a>
                <a href="#" className="grey-text d-block py-1">CDs</a>
                <a href="#" className="grey-text d-block py-1">Kits (Set of Books)</a>
                <a href="#" className="grey-text d-block py-1">Experimental Products</a>
                <a href="#" className="grey-text d-block py-1">Ayurvedic Products</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Magazines</a>
                <a href="#" className="grey-text d-block py-1">Gallery</a>
                <a href="#" className="grey-text d-block py-1">Downloads</a>
                <a href="#" className="grey-text d-block py-1">FAQs</a>
                <a href="#" className="grey-text d-block py-1">E-Shopping Help</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">About Us</a>
                <a href="#" className="grey-text d-block py-1">Research</a>
                <a href="#" className="grey-text d-block py-1">Event Calendar</a>
                <a href="#" className="grey-text d-block py-1">Feedback</a>
                <a href="#" className="grey-text d-block py-1">Contact Us</a>
            </div>
        </div>
    </div> */}
                {/* <div className="container-fluid bg-black">
        <p className="text-center py-3 grey-text mb-0">
            © 2000-2017 Manashakti Rest New Way, | All Rights Reserved Worldwide
        </p>
    </div> */}
            </div>
        );
    }
}

Machine_test.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Machine_test);

