import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Link, BrowserRouter as Router } from "react-router-dom";

import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { loadReCaptcha } from "react-recaptcha-google";

import "./assets/dist/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/rfs.css";
import * as myConstClass from "./constant.js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Header from "./Header.js";
import Footer from "./Footer.js";
import facebook_sign_in from "./assets/images/facebook_sign_in.png";
import google_sign_in from "./assets/images/google_sign_in.png";
import $ from "jquery";
import SimpleReactValidator from "simple-react-validator";
import GoogleLogin from "react-google-login";
//import FacebookLogin from 'react-facebook-login';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
//import TiSocialFacebookCircular from 'react-icons/lib/ti/social-facebook-circular';

// Translation Higher Order Component
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  getLanguage,
  translate,
} from "react-switch-lang";
import en from "./en.json";
import ma from "./ma.json";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventinfo: {
        user_id: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        email: "",
        mobile: "",
      },
      guestinfo: {
        email: "",
        password: "",
      },
      flag: 0,
      google_link: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGoogleClick = this.handleGoogleClick.bind(this);
    this.validator = new SimpleReactValidator();
  }

  notify = () => toast("Wow so easy !");
  componentDidMount() {
    var message = localStorage.getItem("success");
    if (message != "" && message != null) {
      toast("" + message, { containerId: "B", type: toast.TYPE.SUCCESS });

      localStorage.removeItem("success");
    }

    var error = localStorage.getItem("error");
    if (error != "" && error != null) {
      toast("" + error, { containerId: "B", type: toast.TYPE.ERROR });
      localStorage.removeItem("error");
    }
    // get Google Link URL
    fetch(myConstClass.BASE_URL + "users/GetGoogleLoginURL/")
      .then((response) => response.json())
      .then((response) => this.setState({ google_link: response.google_link }));

    //       fetch(myConstClass.BASE_URL+'product/getAvailProdTypes')
    //       .then(response => response.json())
    //       .then(response =>this.setState({types: response}))
    //       .catch(err => console.log(err))

    //       const answer_array = this.props.location.pathname.split('/');
    //        // alert(answer_array);
    //     if(answer_array['2']){

    //   fetch(myConstClass.BASE_URL+'product/getProductInfo/'+answer_array['2']+'/'+getLanguage())
    //   .then(response => response.json())
    //   .then(response =>this.setState({guestinfo: response}))
    //   .catch(err => console.log(err))

    //   fetch(myConstClass.BASE_URL+'product/getRelatedProducts/'+answer_array['2'])
    //       .then(response => response.json())
    //       .then(response =>this.setState({posts: response}))
    //       .catch(err => console.log(err))

    //     }
  }

  componentDidUpdate(prevProps) {
    const answer_array = this.props.location.pathname.split("/");
    const { flag } = this.state;

    // alert(answer_array);
    //    if(answer_array['2'] && getLanguage()=='ma' && flag==0){
    //     this.setState({flag:1})
    //  fetch(myConstClass.BASE_URL+'product/getProductInfo/'+answer_array['2']+'/'+getLanguage())
    //  .then(response => response.json())
    //  .then(response =>this.setState({guestinfo: response}))
    //  .catch(err => console.log(err))
    //    }

    //    if(answer_array['2'] && getLanguage()=='en' && (flag==1)){
    //     this.setState({flag:0})
    //  fetch(myConstClass.BASE_URL+'product/getProductInfo/'+answer_array['2']+'/'+getLanguage())
    //  .then(response => response.json())
    //  .then(response =>this.setState({guestinfo: response}))
    //  .catch(err => console.log(err))
    //    }
  }

  async handleGoogleClick(e) {
    //Code for recaptcha added on 31.03.23 SGH
    e.preventDefault();

    const token = await window.grecaptcha.execute(
      "6LdMWkElAAAAADiLK_36r8e9ZzJPLOoz1mSWVo-J"
    );

    //console.log("Token is "+token);

    if (!token) {
      alert("Please verify that you are not a robot.");
      return;
    }

    //Rest of the Code follows 31.03.23 SGH

    fetch(myConstClass.BASE_URL + "users/googleLogin/");
    //.then(response => response.json())
    //.then(response =>this.setState({guestinfo: response}))
    //.catch(err => console.log(err))

    // Reset the reCAPTCHA widget
    if (typeof window.grecaptcha !== "undefined") {
      window.grecaptcha.reset();
    }
  }

  async handleSubmit(e) {
    //Code for recaptcha added on 31.03.23 SGH
    // document.cookie = "auth_cookie=1; path=/;";
    e.preventDefault();
    const token = await window.grecaptcha.execute(
      "6LdMWkElAAAAADiLK_36r8e9ZzJPLOoz1mSWVo-J"
    );

    // console.log("Token is " + token);

    if (!token) {
      alert("Please verify that you are not a robot.");
      return;
    }
    //Rest of the Code follows 31.03.23 SGH

    $("#btn_click").css("display", "none");
    $("#loader").css("display", "inline");
    if (this.validator.allValid()) {
      // e.preventDefault();
      // console.log('this.refs', $('#pform').serializeArray());
      const formData = {};
      for (const field in this.refs) {
        formData[field] = this.refs[field].value;
      }
      // console.log("-->", formData);
      // console.log("deploy success");
      fetch(myConstClass.BASE_URL + `users/loginUser/`, {
        method: "POST",
        body: JSON.stringify($("#pform").serializeArray())
        //})
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({ eventinfo: response });
          //console.log('this.state.eventinfo.user_id',this.state.eventinfo.user_details.user_id)
          //localStorage.setItem('user_id');
          var status = this.state.eventinfo.status;
          var message = this.state.eventinfo.message;
          if (status == "true") {
            $("#btn_click").css("display", "inline");
            $("#loader").css("display", "none");
            var user_id = this.state.eventinfo.user_details.user_id;
            var first_name = this.state.eventinfo.user_details.first_name;
            var middle_name = this.state.eventinfo.user_details.middle_name;
            var last_name = this.state.eventinfo.user_details.last_name;
            var email = this.state.eventinfo.user_details.email;
            var profile_picture =
              this.state.eventinfo.user_details.profile_picture;
            var is_verify_sadhak_no =
              this.state.eventinfo.user_details.is_verify_sadhak_no;

            localStorage.setItem("user_id", user_id);
            localStorage.setItem("first_name", first_name);
            localStorage.setItem("middle_name", middle_name);
            localStorage.setItem("last_name", last_name);
            localStorage.setItem("email", email);
            localStorage.setItem("success", message);
            localStorage.setItem("profile_picture", profile_picture);
            localStorage.setItem("is_verify_sadhak_no", is_verify_sadhak_no);
            toast("" + message, { containerId: "B", type: toast.TYPE.SUCCESS });
            setTimeout(this.props.history.push('/'),1000);
            // document.cookie = "auth_cookie=1; path=/";
          } else {
            $("#btn_click").css("display", "inline");
            $("#loader").css("display", "none");
            toast(message, { containerId: "B", type: toast.TYPE.ERROR });
            //toast("Wow so easy !");
          }
        })
        //.then(toast('User Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS}),setTimeout(this.props.history.push('signin'),100))
        .catch();
    } else {
      $("#btn_click").css("display", "inline");
      $("#loader").css("display", "none");
      //alert('here');
      this.validator.showMessages();
      console.log("validations" + this.validator.showMessages());
      // e.preventDefault();
      toast("Please fill all required fields.", {
        containerId: "B",
        type: toast.TYPE.ERROR,
      });
      // toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR})
    }
  }

  signup(res, type) {
    let postData;
    if (type === "facebook" && res.id) {
      postData = {
        name: res.name,
        provider: type,
        email: res.email,
        provider_id: res.id,
        token: res.accessToken,
        //  provider_pic: res.picture.data.url
      };
    }
    //if (type === 'google'){
    if (type === "google" && res.profileObj.googleId) { 
      postData = {
        name: res.profileObj.name,
        provider: type,
        email: res.profileObj.email,
        provider_id: res.profileObj.googleId,
      };
    }

    if (postData) {
      //alert(JSON.stringify(postData));
      console.log("PostData : ", postData);
      fetch(myConstClass.BASE_URL + `users/socialLoginUser/`, {
        method: "POST",
        body: JSON.stringify(postData),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({ eventinfo: response });
          var status = this.state.eventinfo.status;
          var message = this.state.eventinfo.message;
          if (status == "true") {
            var user_id = this.state.eventinfo.user_details.user_id;
            var first_name = this.state.eventinfo.user_details.first_name;
            var middle_name = this.state.eventinfo.user_details.middle_name;
            var last_name = this.state.eventinfo.user_details.last_name;
            var email = this.state.eventinfo.user_details.email;
            var profile_picture =
              this.state.eventinfo.user_details.profile_picture;
            var is_verify_sadhak_no =
              this.state.eventinfo.user_details.is_verify_sadhak_no;
            localStorage.setItem("user_id", user_id);
            localStorage.setItem("first_name", first_name);
            localStorage.setItem("middle_name", middle_name);
            localStorage.setItem("last_name", last_name);
            localStorage.setItem("email", email);
            localStorage.setItem("success", message);
            localStorage.setItem("profile_picture", profile_picture);
            localStorage.setItem("is_verify_sadhak_no", is_verify_sadhak_no);
            toast("" + message, { containerId: "B", type: toast.TYPE.SUCCESS });
            //setTimeout(this.props.history.push('/'),1000);
            //            setTimeout(this.props.history.push('/'), 1000);
            setTimeout(this.props.history.goBack, 1000);
          } else {
            if (this.state.eventinfo.mstatus == "true") {
              //  alert('pop up');
              $("#fbModel").modal("show");
              $("#postData").val(JSON.stringify(postData));
            } else {
              toast(message, { containerId: "B", type: toast.TYPE.ERROR });
            }
          }
        });
    } else {
    }
  }

  render() {
    const { location } = this.props;
    const isRegisterPage = location.pathname === "/signin";
    // console.log("isRegisterPage: ", isRegisterPage);
    const PagePath2 = location.pathname;
    // console.log("PagePath2: ", PagePath2);

    const responseFacebook = (response) => {
      console.log("facebook console");
      console.log(response);
      this.signup(response, "facebook");
    };
    const responseGoogle = (response) => {
      console.log("google console");
      console.log(response);
      this.signup(response, "google");
    };

    const { posts, guestinfo, toast } = this.state;
    const { t } = this.props;
    return (
      <>
        {isRegisterPage && (
          <Helmet>
            <script
              src="https://www.google.com/recaptcha/api.js?render=6LdMWkElAAAAADiLK_36r8e9ZzJPLOoz1mSWVo-J"
              async
              defer
            ></script>
          </Helmet>
        )}

        {/*Start of App*/}
        <div className="App">
          <div>
            {/* <button onClick={this.notify}>Notify !</button> */}
            <ToastContainer />
          </div>
          <Header />

          {/*Start of Container*/}
          <div class="container-fluid my-5">
            <Row className="px-xl-5 px-lg-5 px-md-5 py-md-3 px-2">
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Form
                  action=""
                  id="pform"
                  onSubmit={this.handleSubmit}
                  method="post"
                  encType="multipart/form-data"
                  className="form-horizontal"
                >
                  {/*Start of First Row - Google Login*/}
                  <div class="row">
                    <div class="col-md-3 text-center align-content-center d-flex align-items-center align-middle"></div>

                    <div class="col-md-6">
                      {" "}
                      {/* border p-3*/}
                      <h5 class="text-center" hidden>
                        {t("home.LOGIN USING")}
                      </h5>
                      <h5 class="text-center">
                        Click Below to Login with Gmail (Google)
                      </h5>
                      {/*<p class="text-center mt-2"></p>*/}
                      <p class="text-center mt-4">
                        <GoogleLogin
                          clientId="800455904093-eonvlc21ffngqlg3b2u7kguff1gtbpa1.apps.googleusercontent.com"
                          //buttonText="LOGIN WITH GOOGLE"
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                          //className="signInButton"
                          render={(renderProps) => (
                            <span onClick={renderProps.onClick}>
                              <img
                                style={{ cursor: "pointer" }}
                                id="facebook_icon"
                                src={
                                  myConstClass.IMAGE_URL + "google_sign_in.png"
                                }
                                class="img-fluid"
                              />
                            </span>
                          )}
                        />
                      </p>
                    </div>

                    <div class="col-md-3 text-center align-content-center d-flex align-items-center align-middle"></div>
                  </div>
                  {/*End of First Row - Google Login*/}

                  {/*Start of Second Row - OR*/}
                  <div class="row">
                    <div class="col-md-5 text-center align-content-center d-flex align-items-center align-middle"></div>
                    <div class="col-md-2 text-center align-content-center d-flex align-items-center align-middle">
                      <h5 class="text-center d-block w-100">{t("home.OR")}</h5>
                      <br />
                    </div>
                    <div class="col-md-5 text-center align-content-center d-flex align-items-center align-middle"></div>
                  </div>
                  {/*End of Second Row - OR*/}

                  {/*Start of Third Row - Login Form*/}
                  <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-10">
                      <div class="row">
                        {/*Blank Space on Left*/}
                        <div class="col-md-3 text-center align-content-center d-flex align-items-center align-middle"></div>

                        {/*Beginning of Login Form*/}
                        <div class="col-md-6 ">
                          {" "}
                          {/*border  px-5 py-3*/}
                          <h5 class="text-center" hidden>
                            {t("home.LOGIN")}
                          </h5>
                          <div class="row">
                            <div class="form-group col col-md-12">
                              <label class="color-black font-weight-bolder">
                                {t("home.Registered Email ID")}
                              </label>
                              <input
                                onChange={(e) => {
                                  this.setState({
                                    guestinfo: {
                                      ...guestinfo,
                                      email: e.target.value,
                                    },
                                  });
                                }}
                                autoFocus
                                type="text"
                                class="form-control"
                                id="email"
                                name="email"
                                placeholder={t("home.Enter_email_ID")}
                                onBlur={() =>
                                  this.validator.showMessageFor("email")
                                }
                              />
                              {this.validator.message(
                                "email",
                                this.state.guestinfo.email,
                                "required"
                              )}
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group col col-md-12">
                              <label class="color-black font-weight-bolder">
                                {t("home.Password")}
                              </label>
                              <input
                                type="password"
                                class="form-control"
                                id="password"
                                name="password"
                                placeholder={t("home.Enter password")}
                                onChange={(e) => {
                                  this.setState({
                                    guestinfo: {
                                      ...guestinfo,
                                      password: e.target.value,
                                    },
                                  });
                                }}
                                onBlur={() =>
                                  this.validator.showMessageFor("password")
                                }
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                              />
                              {this.validator.message(
                                "password",
                                this.state.guestinfo.password,
                                "required"
                              )}
                              <p class="text-right mb-0">
                                <Link to="/forgot-password">
                                  {t("home.Forgot Password?")}
                                </Link>
                              </p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group col col-md-12 newUserRegisterNow">
                              <Link to="/signup">
                                {t("home.New user?")}&nbsp;
                                {t("home.Register now")}
                              </Link>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="form-group form-check pl-0 mt-n3">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customCheck"
                                    name="example1"
                                  />
                                  <label
                                    class="custom-control-label remember-me"
                                    for="customCheck"
                                  >
                                    {t("home.Remember me")}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-3 col-lg-3 col-md-3"></div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                              <button
                                id="btn_click"
                                name="btn_click"
                                class="btn btn-brown px-4 btn-block"
                              >
                                {t("home.LOGIN")}
                              </button>
                              <img
                                style={{ display: "none" }}
                                id="loader"
                                name="loader"
                                src={myConstClass.IMAGE_URL + "loader.gif"}
                                class="img-fluid"
                              />
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3"></div>
                          </div>
                        </div>
                        {/*End of Login Form*/}

                        {/*Blank Space Right Side*/}
                        <div class="col-md-3 text-center align-content-center d-flex align-items-center align-middle"></div>
                        {/*Blank Space Right Side*/}
                      </div>
                    </div>
                    <div class="col-md-1"></div>
                  </div>
                  {/*End of Third Row - Login Form*/}
                </Form>
              </Col>
            </Row>
          </div>
          {/*End of Container*/}

          <Footer history={this.props.history} />
        </div>
        {/*End of App*/}
      </>
    );
  }
}
Signin.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(Signin);
