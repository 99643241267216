//For Deployment to Main Site, change the URLs to the following :
//export const BASE_URL = "https://www.manashakti.org/admin/backend/index.php/";
//export const IMAGE_URL = "https://www.manashakti.org/admin/backend/uploads/";

//For Deployment to Prepod, change the URLs to the following :
// export const BASE_URL = "http://192.168.29.112/admin/backend/index.php/";
// export const BASE_URL = "https://www.manashakti.org/admin/backend/index.php/";
// export const IMAGE_URL = "https://www.manashakti.org/admin/backend/uploads/";
export const BASE_URL =
  "https://www.manashakti.org/admin/backend/index.php/";
export const IMAGE_URL = "https://www.manashakti.org/admin/backend/uploads/";

export const NodeAPI = 'https://www.manashakti.org/api/download_gallery';

//For Localhost, change the URLs to the following :
//export const BASE_URL = "localhost/admin/backend/index.php/";
//export const IMAGE_URL = "localhost/admin/backend/uploads/";

// Extra Stuff only for reference :

//export const BASE_URL = "http://127.0.0.1:8000/admin/backend/index.php/";
//export const IMAGE_URL = "https://127.0.0.1:8000/admin/backend/uploads/";

//export const BASE_URL_1 = window.location.href;
//export const BASE_URL_SPLIT= BASE_URL_1.split('/');
//export const BASE_URL= BASE_URL_SPLIT[0]+"//"+BASE_URL_SPLIT[2]+ "/admin/backend/index.php/";
//export const IMAGE_URL= BASE_URL_SPLIT[0]+"//"+BASE_URL_SPLIT[2]+ "/admin/backend/uploads/";

//export const BASE_URL = "http://localhost/admin/backend/index.php/";
//export const IMAGE_URL = "http://localhost/admin/backend/uploads/";
