import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./assets/dist/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/rfs.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Header from "./Header.js";
import Footer from "./Footer.js";
import SimpleReactValidator from "simple-react-validator";

import { translate } from "react-switch-lang";
import PropTypes from "prop-types";
import { Form } from "reactstrap";
import "react-day-picker/lib/style.css";

class FormValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    };
    this.validator = new SimpleReactValidator();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.validator.allValid()) {
        console.log('validate: ', this.validator.allValid());
        console.log(this.state);
    } else {
        console.log('error: ', this.validator.showMessages());
    }
  }

  render() {
    return (
      <div className="App">
        <Header />

        <Form className="mt-5" onSubmit={this.handleSubmit}>
            <h1>Profile Form</h1>

            <div className="m-2 p-2">
              <input
                onChange={e => { this.setState({ first_name: e.target.value }) }}
                onBlur={() => this.validator.showMessageFor('first_name')}
                type="text" id="first_name" name="first_name" value={ this.state.first_name } placeholder='Enter First Name'
              />
              { this.validator.message('first_name', this.state.first_name, 'required|alpha|string|min:2|max:50|regex:^[A-Za-z]+$') }
            </div>

            <div className="m-2 p-2">
              <input
                onChange={e => { this.setState({ last_name: e.target.value }) }}
                onBlur={() => this.validator.showMessageFor('last_name')}
                type="text" id="last_name" name="last_name" value={ this.state.last_name } placeholder='Enter Last Name'
              />
              { this.validator.message('last_name', this.state.first_name, 'required|alpha|string|min:2|max:50|regex:^[A-Za-z]+$') }
            </div>

            <div className="m-2 p-2">
              <input
                onChange={e => { this.setState({ email: e.target.value }) }}
                onBlur={() => this.validator.showMessageFor('email')}
                type="email" id="email" name="email" value={ this.state.email } placeholder='Enter Email'
              />
              { this.validator.message('email', this.state.email, 'required|email') }
            </div>

            <div className="m-2 p-2">
              <input
                onChange={e => { this.setState({ password: e.target.value }) }}
                onBlur={() => this.validator.showMessageFor('password')}
                type="password" id="password" name="password" value={ this.state.password } placeholder='Enter Password'
              />
              { this.validator.message('password', this.state.password, 'required') }
            </div>

            <div className="m-2 p-2">
              <button>Submit</button>
            </div>
        </Form>

        <Footer />
      </div>
    );
  }
}

FormValidation.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(FormValidation);