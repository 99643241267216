import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../constant";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    values: null,
    productCategories: null,
    eventCategories: null,
  },
  reducers: {
    setProductCatagories(state, action) {
      state.productCategories = action.payload;
    },
    setEventCategories(state, action) {
      state.eventCategories = action.payload;
    },
  },
});

export const { setProductCatagories, setEventCategories } =
  categorySlice.actions;

export const fetchCategoriesAsync = createAsyncThunk(
  "category",
  async (_, thunkAPI) => {
    let productCategoryRes = await fetch(
      BASE_URL + "product/getAvailProdTypesFrontEnd/0/en"
    );
    let productCategoryData = await productCategoryRes.json();
    thunkAPI.dispatch(setProductCatagories(productCategoryData));

    let eventCategoryRes = await fetch(BASE_URL + "event/getcourses");
    let eventCategoryData = await eventCategoryRes.json();
    thunkAPI.dispatch(setEventCategories(eventCategoryData));
  }
);

export const selectProductCategories = (state) =>
  state.category.productCategories;
export const selectEventCategories = (state) => state.category.eventCategories;

export default categorySlice.reducer;
