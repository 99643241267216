import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { deleteAddress, fetchAddresses } from "../store/savedAddress";
import "./carousel.css";

const AddressCarousel = ({
  savedAddresses,
  // selectAddressId,
  handleSetFormFields,
  onSavedAddressSelect,
}) => {
  const dispatch = useDispatch();

  const [selectAddressId, setSelectAddressId] = useState(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const itemsPerSlide =
    windowSize.width >= 1200
      ? 4
      : windowSize.width >= 992
      ? 3
      : windowSize.width >= 768
      ? 2
      : 1;

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // let singleBoxArray;

  // if (selectAddressId) {
  //   singleBoxArray = savedAddresses.filter(
  //     (item) => item.id === selectAddressId
  //   );
  // }

  useEffect(() => {
    if (!savedAddresses?.length) return;
    const address = savedAddresses[0];
    setSelectAddressId(address.id);
    if (onSavedAddressSelect) onSavedAddressSelect(address);
  }, [savedAddresses]);

  const handleDeleteAddress = (id) => {
    dispatch(deleteAddress(id)).then(() => {
      dispatch(fetchAddresses());
    });
  };

  return (
    <div className="container px-0">
      <div className="text-caption" style={{ padding: "1rem", width: "100%" }}>
        <div className="m-2">
          <h3 className="">
            <b> Previous Shipping Addresses</b>
          </h3>
        </div>
      </div>

      {/* <pre>{JSON.stringify({ selectAddressId }, null, 2)}</pre> */}

      <Carousel className="my-2 px-2" interval={null}>
        {[...Array(Math.ceil(savedAddresses?.length / itemsPerSlide))].map(
          (_, index) => (
            <Carousel.Item key={index}>
              <div className="w-100 row mx-auto px-2">
                {savedAddresses
                  .slice(index * itemsPerSlide, (index + 1) * itemsPerSlide)
                  .map((address, key) => (
                    <div
                      key={key}
                      className="col-12 col-md-6 col-lg-4 col-xl-3"
                      onClick={() => {
                        // console.log("clicked on address");
                        setSelectAddressId(address.id);
                        if (handleSetFormFields) handleSetFormFields(address);
                        if (onSavedAddressSelect) onSavedAddressSelect(address);
                      }}
                    >
                      <div
                        className={
                          "h-100 p-2 rounded shadow-sm position-relative d-flex flex-column justify-content-between cursor-pointer" +
                          (address.id === selectAddressId ? " selected" : "")
                        }
                      >
                        <span className="position-absolute top-0 right-0 pt-1 pr-1">
                          <div className="badge p-2 rounded-pill bg-accent text-white">
                            {address?.label}
                          </div>
                        </span>
                        <div className="">
                          <h6>
                            <b>
                              {address.shipping_first_name}{" "}
                              {address.shipping_last_name}
                            </b>
                          </h6>
                          <div>
                            Phone: <b>{address.shipping_phone}</b>
                          </div>
                          <div>
                            <b>
                              <u>{address.shipping_address_line_1}</u>
                            </b>
                            , {address.shipping_state_name},{" "}
                            <b>{address.shipping_pincode}</b>
                          </div>
                          <div></div>
                        </div>
                        <div className="align-self-end">
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => {
                              handleDeleteAddress(address.id);
                            }}
                          >
                            <i className="fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </Carousel.Item>
          )
        )}
      </Carousel>

      {selectAddressId === null ? (
        windowSize.width >= 1440 && savedAddresses.length <= 4 ? (
          <div className="d-flex justify-content-center">
            {/* {saved_addresses.map((el) => (
              <div className="carousel-item-wrapper" key={el.id}>
                <div className="address-box">
                  <h5>
                    {el.shipping_first_name} {el.shipping_last_name}
                  </h5>
                  <p>Phone: {el.shipping_phone}</p>
                  <p>Address: {el.shipping_address_line_1}</p>
                  <p>State: {el.shipping_state_name}</p>
                  <p>Pincode: {el.shipping_pincode}</p>
                </div>
                <div className="address-use-delete-box">
                  <button onClick={() => handleSetFormFields(el)}>Use</button>
                  <button onClick={() => handleDeleteAddress(el.id)}>
                    Delete
                  </button>
                </div>
              </div>
            ))} */}
          </div>
        ) : windowSize.width >= 1024 && savedAddresses.length <= 3 ? (
          <div className="d-flex justify-content-center">
            {/* {saved_addresses.map((el) => (
              <div className="carousel-item-wrapper" key={el.id}>
                <div className="address-box">
                  <h5>
                    {el.shipping_first_name} {el.shipping_last_name}
                  </h5>
                  <p>Phone: {el.shipping_phone}</p>
                  <p>Address: {el.shipping_address_line_1}</p>
                  <p>State: {el.shipping_state_name}</p>
                  <p>Pincode: {el.shipping_pincode}</p>
                </div>
                <div className="address-use-delete-box">
                  <button onClick={() => handleSetFormFields(el)}>Use</button>
                  <button onClick={() => handleDeleteAddress(el.id)}>
                    Delete
                  </button>
                </div>
              </div>
            ))} */}
          </div>
        ) : windowSize.width >= 768 && savedAddresses.length <= 2 ? (
          <div className="d-flex justify-content-center">
            {/* {saved_addresses.map((el) => (
              <div className="carousel-item-wrapper" key={el.id}>
                <div className="address-box">
                  <h5>
                    {el.shipping_first_name} {el.shipping_last_name}
                  </h5>
                  <p>Phone: {el.shipping_phone}</p>
                  <p>Address: {el.shipping_address_line_1}</p>
                  <p>State: {el.shipping_state_name}</p>
                  <p>Pincode: {el.shipping_pincode}</p>
                </div>
                <div className="address-use-delete-box">
                  <button onClick={() => handleSetFormFields(el)}>Use</button>
                  <button onClick={() => handleDeleteAddress(el.id)}>
                    Delete
                  </button>
                </div>
              </div>
            ))} */}
          </div>
        ) : windowSize.width >= 320 && savedAddresses.length <= 1 ? (
          <div className="carousel-item-wrapper m-auto">
            {/* <h5>
              {singleBoxArray[0].shipping_first_name}{" "}
              {singleBoxArray[0].shipping_last_name}
            </h5>
            <p>Phone: {singleBoxArray[0].shipping_phone}</p>
            <p>Address: {singleBoxArray[0].shipping_address_line_1}</p>
            <p>State: {singleBoxArray[0].shipping_state_name}</p>
            <p>Pincode: {singleBoxArray[0].shipping_pincode}</p> */}
          </div>
        ) : (
          <>
            {/* <Carousel className="mt-4" interval={null}>
              {[...Array(Math.ceil(saved_addresses.length / itemsPerSlide))].map(
                (_, index) => (
                  <Carousel.Item key={index}>
                    <div className="d-flex justify-content-center">
                      {saved_addresses
                        .slice(
                          index * itemsPerSlide,
                          (index + 1) * itemsPerSlide
                        )
                        .map((user, userIndex) => (
                          <div
                            key={userIndex}
                            className="carousel-item-wrapper"
                            onClick={() => {
                              handleSetFormFields(user);
                            }}
                          >
                            <div className="address-box">
                              <h5>
                                {user.shipping_first_name}{" "}
                                {user.shipping_last_name}
                              </h5>
                              <p>
                                <span>Phone</span>: {user.shipping_phone}
                              </p>
                              <p>
                                <span>Address</span>:{" "}
                                {user.shipping_address_line_1}
                              </p>
                              <p>
                                <span>State</span>: {user.shipping_state_name}
                              </p>
                              <p>
                                <span>Pincode</span>: {user.shipping_pincode}
                              </p>
                            </div>

                            <button
                              onClick={() => handleDeleteAddress(user.id)}
                            >
                              Del
                            </button>
                          </div>
                        ))}
                    </div>
                  </Carousel.Item>
                )
              )}
            </Carousel> */}
          </>
        )
      ) : (
        <></>
        // <div className="carousel-item-wrapper m-auto">
        //   <h5>
        //     {singleBoxArray[0].shipping_first_name}{" "}
        //     {singleBoxArray[0].shipping_last_name}
        //   </h5>
        //   <p>Phone: {singleBoxArray[0].shipping_phone}</p>
        //   <p>Address: {singleBoxArray[0].shipping_address_line_1}</p>
        //   <p>State: {singleBoxArray[0].shipping_state_name}</p>
        //   <p>Pincode: {singleBoxArray[0].shipping_pincode}</p>
        // </div>
      )}
    </div>
  );
};

export default AddressCarousel;
