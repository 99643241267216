import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useHistory } from "react-router-dom";

const PageNotFound = () => {
    const history = useHistory();

  return (
    <div>
      <Header />

      <div class="text-center mx-auto my-5 p-5">
        <h1>404 - Page Not Found</h1>
        <p>We're sorry, but the page you're looking for does not exist.</p>
        <p>Please contact store@manashakti.org in case you are facing any errors.</p>
        <div class="d-flex justify-content-center align-items-center">
            <button
                onClick={() => history.goBack()}
                class="btn btn-outline-danger mr-1 px-2 py-1" style={{ borderRadius: '0.2rem' }}
            >
                ← Go Back
            </button>
            <button
                class="bg-danger border-0 ml-1 px-2 py-1 rounded"
            >
                <Link class="text-decoration-none text-white" to='/'>
                    ⇱ Go to Home
                </Link>
            </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PageNotFound;