import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/images/logo.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import Header from './Header.js';
import Footer from './Footer.js';
import { Editor } from 'react-draft-wysiwyg';
import $ from 'jquery';

// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import { Base64 } from 'js-base64';
import {
    ListGroup,
    ListGroupItem,
    UncontrolledCollapse,
    FormGroup,
    Label
} from 'reactstrap';
import checkoutImage from './assets/images/mycart.png';

class Interactions extends Component {
    constructor(props) {
        super(props);
        this.product_id = 0;
        this.state = {
            cart: [],
            flag: 0,
            value: 1,
            allInteractions: [],
            openOrders: [],
            pastOrders: [],
            cancelOrders: [],
            guestinfo: {

            },
            eventinfo: {


            },
            removeinfo: {
                message: '',
                status: '',
            },
            editorState: EditorState.createEmpty(),
        }

        this.handleChange = this.handleChange.bind(this);

    }


    componentDidMount() {
        //this.state.guestinfo.product_total_price = 0;
        const answer_array = this.props.location.pathname.split('/');

        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });


            localStorage.removeItem('success');
        }

        var error = localStorage.getItem('error');
        if (error != '' && error != null) {
            toast('' + error, { containerId: 'B', type: toast.TYPE.ERROR });
            localStorage.removeItem('error');
        }

        var user_id = localStorage.getItem('user_id');
        if (user_id > 0) {
            fetch(myConstClass.BASE_URL + 'users/getMyInteractionsEvents/' + user_id + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ allInteractions: response })
                    const numRows = this.state.allInteractions.length;

                })
                .catch(err => console.log(err))
        }



    }

    componentDidUpdate(prevProps) {

        var user_id = localStorage.getItem('user_id');
        const answer_array = this.props.location.pathname.split('/');
        const { flag } = this.state
        if (getLanguage() == 'ma' && flag == 0) {
            this.setState({ flag: 1 })

            var user_id = localStorage.getItem('user_id');
            if (user_id > 0) {
                fetch(myConstClass.BASE_URL + 'users/getMyInteractionsEvents/' + user_id + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ allInteractions: response })
                        const numRows = this.state.allInteractions.length;

                    })
                    .catch(err => console.log(err))
            }


        }

        if (getLanguage() == 'en' && (flag == 1)) {
            this.setState({ flag: 0 })

            var user_id = localStorage.getItem('user_id');
            if (user_id > 0) {
                fetch(myConstClass.BASE_URL + 'users/getMyInteractionsEvents/' + user_id + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ allInteractions: response })
                        const numRows = this.state.allInteractions.length;

                    })
                    .catch(err => console.log(err))
            }


        }

    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }




    render() {

        const { posts, guestinfo, cart, eventinfo, allInteractions, openOrders, pastOrders, cancelOrders } = this.state
        const { t } = this.props;
        return (

            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb bg-white mb-0">
                                        {/* <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item"><a href="#">Order History</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Order History</li> */}
                                        <li class="breadcrumb-item">
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li class="breadcrumb-item active" aria-current="page">{t('home.My Account')}</li>
                                        <li class="breadcrumb-item" aria-current="page">{t('home.My Interactions')}</li>

                                    </ol>
                                </nav>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h5 style={{ fontSize: '1.4rem' }}>{t('home.MY INTERACTIONS')}</h5>
                                </div>
                            </div>
                            <div className="d-xl-block d-lg-block d-md-block d-none">
                                <div class="row mt-3">
                                    <div class="col">
                                        <div class="tab-content">
                                            <div id="all_orders" class="tab-pane active">
                                                <table class="table table-bordered">
                                                    <thead class="bg-light">
                                                        <tr>
                                                            <th scope="col ">{t('home.sr_no')}</th>
                                                            <th scope="col ">{t('home.Item Name')}</th>
                                                            <th scope="col ">{t('home.Type')}</th>
                                                            <th scope="col ">{t('home.Review/Comment')}</th>
                                                            <th scope="col ">{t('home.Date')}</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {allInteractions.map((info, ii) => {
                                                            if (info.review_for == 1) {
                                                                return (
                                                                    <tr>
                                                                        <td style={{ width: '8%' }}>{ii + 1}</td>
                                                                        <td style={{ width: '10%' }}>
                                                                            <Link to={'/products_details/' + info.item_name + '/' + info.product_event_id_fk} style={{ cursor: 'pointer' }}>{info.item_name}</Link>
                                                                        </td>
                                                                        <td style={{ width: '17%' }}>{t('home.Product')}
                                                                            <br />
                                                                            {info.type}
                                                                        </td>
                                                                        <td>{info.description}</td>
                                                                        <td>{info.created_on}</td>
                                                                    </tr>
                                                                )
                                                            } else if (info.review_for == 2) {
                                                                return (
                                                                    <tr>
                                                                        <td style={{ width: '8%' }}>{ii + 1}</td>
                                                                        <td style={{ width: '17%' }}>
                                                                            <Link to={'/event_details/' + info.product_event_id_fk} style={{ cursor: 'pointer' }}> {info.item_name}</Link>
                                                                        </td>
                                                                        <td style={{ width: '10%' }}>{t('home.Event')}
                                                                            <br />
                                                                            {info.type}
                                                                        </td>
                                                                        <td>{info.description}</td>
                                                                        <td>{info.created_on}</td>
                                                                    </tr>
                                                                )
                                                            } else if (info.review_for == 3) {
                                                                return (
                                                                    <tr>
                                                                        <td style={{ width: '8%' }}>{ii + 1}</td>
                                                                        <td style={{ width: '17%' }}>
                                                                            <Link to={'/magazine/' + info.product_event_id_fk} style={{ cursor: 'pointer' }}> {info.item_name}</Link>
                                                                        </td>
                                                                        <td style={{ width: '10%' }}>{t('home.Magazine')}

                                                                        </td>
                                                                        <td>{info.description}</td>
                                                                        <td>{info.created_on}</td>
                                                                    </tr>
                                                                )
                                                            } else if (info.review_for == 4) {
                                                                return (
                                                                    <tr>
                                                                        <td style={{ width: '8%' }}>{ii + 1}</td>
                                                                        <td style={{ width: '17%' }}>
                                                                            <Link to={'/news-details/' + info.product_event_id_fk} style={{ cursor: 'pointer' }}> {info.item_name}</Link>
                                                                        </td>
                                                                        <td style={{ width: '10%' }}>{t('home.News/Article')}

                                                                        </td>
                                                                        <td>{info.description}</td>
                                                                        <td>{info.created_on}</td>
                                                                    </tr>
                                                                )
                                                            }


                                                        })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-xl-none d-lg-none d-md-none d-block">
                                <ListGroup>
                                    {
                                        allInteractions.map((info, ii) =>

                                            <ListGroupItem key={info.review_id} className="p-0 my-2">
                                                <div className="bg-light py-3 px-3 clearfix" id={'reviewFor_' + info.review_id}>
                                                    <h6 className="float-left">
                                                        {info.item_name}
                                                    </h6>
                                                    <img style={{ height: '20px', width: '20px', float: 'right', cursor: 'pointer' }} className="image_main_carosal" src={checkoutImage}  />
                                                </div>
                                                <UncontrolledCollapse toggler={'reviewFor_' + info.review_id} className="px-3 py-3">
                                                    <FormGroup>
                                                        <Label className="font-weight-bold">
                                                            {t('home.Item Name')}&nbsp;:&nbsp;
                                                        </Label>
                                                        <Label>
                                                            {info.review_for === "1" && <Link to={'/products_details/' + info.item_name + '/' + info.product_event_id_fk} style={{ cursor: 'pointer' }}>{info.item_name}</Link>}
                                                            {info.review_for === "2" && <Link to={'/event_details/' + info.product_event_id_fk} style={{ cursor: 'pointer' }}> {info.item_name}</Link>}
                                                            {info.review_for === "3" && <Link to={'/magazine/' + info.product_event_id_fk} style={{ cursor: 'pointer' }}> {info.item_name}</Link>}
                                                            {info.review_for === "4" && <Link to={'/news-details/' + info.product_event_id_fk} style={{ cursor: 'pointer' }}> {info.item_name}</Link>}
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label className="font-weight-bold">
                                                            {t('home.Date')}&nbsp;:&nbsp;
                                                        </Label>
                                                        <Label>
                                                            {info.created_on}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label className="font-weight-bold">
                                                            {info.review_for === "1" && t('home.Product')}
                                                            {info.review_for === "2" && t('home.Event')}
                                                            {info.review_for === "3" && t('home.Magazine')}
                                                            {info.review_for === "4" && t('home.News/Article')}
                                                            &nbsp;{t('home.Type')}&nbsp;:&nbsp;
                                                        </Label>
                                                        <Label>
                                                            {info.type}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label className="font-weight-bold">
                                                            {t('home.Review/Comment')}&nbsp;:&nbsp;
                                                        </Label>
                                                        <Label>
                                                            {info.description}
                                                        </Label>
                                                    </FormGroup>


                                                </UncontrolledCollapse>
                                            </ListGroupItem>


                                        )

                                    }
                                </ListGroup>
                            </div>


                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        );
    }
}
Interactions.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Interactions);
