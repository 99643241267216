import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import Header from './Header.js';
import Footer from './Footer.js';
import * as myConstClass from './constant.js';
import $ from 'jquery';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';
//import { toast } from 'react-toastify';




//console.log('.push(',{body3});


// async function getMovies(){
//   await fetch(myConstClass.BASE_URL+'product/getProductsFrontEnd')
//     .then((response) => response.json());
// }

class Event_calendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            quantityValue: '1',
            selected_city_id: '0',
            selected_topic_id: '0',
            flag: 0,
            defaultmonth: '',
            monthvalue: '',
            posts: [],
            arr_events: [],
            eventinfo: [],
            month: [],
            removeFromCart: [],
            topic: [],
            res: [],
            cart: [],
            cartEvent: [],
            productIncart: [],
            arrCity: [],
            guestinfo: {
                title: '',
                type_id: '',
                topic: '',
                monthYearName: '',
            },
        }
        this.handleChange = this.handleChange.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.applyFiltersMobile = this.applyFiltersMobile.bind(this);
        this.applyFiltersDesktop = this.applyFiltersDesktop.bind(this);
    }

    removeFromCart(e) {
        var product_id = e.target.id;
        // console.log(e.target.id);
        var product_quantity = $('#qty' + product_id).val();
        var user_id = localStorage.getItem('user_id');
        console.log(product_quantity);

        if (product_id != '' && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/removeProductFromCart/' + product_id + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ removeinfo: response })
                    var message = this.state.removeinfo.message;
                    var status = this.state.removeinfo.status;
                    if (status == 'true') {
                        //$('#DIV_'+product_id).css('display','none');
                        $('#' + product_id).css('display', 'inherit');
                        $('#addedInCart_' + product_id).css('display', 'none');
                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);

                                })


                            if (user_id > 0) {
                                fetch(myConstClass.BASE_URL + 'cart/getAllMyCartData/' + user_id + '/' + getLanguage())
                                    .then(response => response.json())
                                    .then(response => {
                                        this.setState({ productIncart: response })
                                        //var totalRecords =  this.state.cart[0].title;
                                        const numRows = this.state.productIncart.length;
                                        if (numRows < 1) {
                                            $('#productIncart').css('display', 'block');
                                        } else {
                                            $('#productIncart').css('display', 'none');
                                        }
                                    })
                            } else {
                                $('#productIncart').css('display', 'block');
                            }
                        }




                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                toast('You must have to login', { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
                //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

            }

        }
        //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
        //this.props.offline();    
    }

    handleChange(evt) {
        // check it out: we get the evt.target.name (which will be either "email" or "password")
        // and use it to target the key on our `state` object with the same name, using bracket syntax
        this.setState({ [evt.target.name]: evt.target.value });
        //console.log(evt);
    }
    increaseQuantity(product_id) {
        var qty = parseInt($('#qty' + product_id).val()) + 1;
        console.log(qty);
        $('#qty' + product_id).val(qty);
    }
    decreaseQuantity(product_id) {
        var qty = parseInt($('#qty' + product_id).val()) - 1;
        if (qty < 0) {
            qty = 0;
        }
        console.log(qty);
        $('#qty' + product_id).val(qty);
    }
    componentDidMount() {

        var user_id = localStorage.getItem('user_id');
        var monthvalue = localStorage.getItem('selected_month_value');
        //alert(monthvalue);
        localStorage.removeItem('selected_month_value');
        var d = new Date(),
            month = d.getMonth(),
            year = d.getFullYear();
        this.state.defaultmonth = month;
        var defaultdate = "";
        if (monthvalue != '' && monthvalue != undefined) {
            this.state.monthvalue = monthvalue;
            defaultdate = monthvalue;
        }

        // alert(year);
        localStorage.removeItem('selected_topic_id');
        var selected_city_id = localStorage.getItem('selected_city_id');
        if (selected_city_id > 0 && selected_city_id != undefined) {
            this.state.selected_city_id = selected_city_id;
        } else {
            var selected_city_id = 0;
        }
        var selected_topic_id = localStorage.getItem('selected_topic_id');
        if (selected_topic_id > 0 && selected_topic_id != undefined) {
            this.state.selected_topic_id = selected_topic_id;
        } else {
            var selected_topic_id = 0;
        }

        localStorage.removeItem('selected_topic_id');
        localStorage.removeItem('selected_city_id');

        if (getLanguage() == 'ma') {
            var monthNames = ["जानेवारी", "फेब्रुवारी", "मार्च", "एप्रिल", "मे", "जून",
                "जुलै", "ऑगस्ट", "सप्टेंबर", "ऑक्टोबर", "नोव्हेंबर", "डिसेंबर"
            ];
        } else {
            var monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
        }

        this.state.guestinfo.monthYearName = monthNames[d.getMonth()] + ' ' + year;

        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }

        fetch(myConstClass.BASE_URL + 'event/getEventLocationFrontEnd/'+getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ arrCity: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'event/getEventsForCalendarFrontEnd' + '/' + getLanguage() + '/' + selected_topic_id + '/' + selected_city_id + '/' + defaultdate)
            .then(response => response.json())
            .then(response => {
                this.setState({ posts: response })
                const numRows = this.state.posts.length;
                //    alert(numRows);
                if (numRows < 1) {
                    $('#notAvailableDiv').css('display', 'table-row');
                } else {
                    $('#notAvailableDiv').css('display', 'none');
                }
            })
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'event/getEventCalendarMonth' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ month: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ topic: response }))
            .catch(err => console.log(err))

        var user_id = localStorage.getItem('user_id');



    }


    applyFiltersDesktop() {
        this.applyFilters(
            $('#month').val(),
            $('#topic_id').val(),
            $('#city_id').val()
        );
    }

    applyFiltersMobile() {
        this.applyFilters(
            $('#month_m').val(),
            $('#topic_id_m').val(),
            $('#city_id_m').val()
        );
    }

    applyFilters(month, topic_id, city_id) {

        var user_id = localStorage.getItem('user_id');
        $('#applyFilterBtn').css('display', 'none');
        $('#loader').css('display', 'inline-block');


        //alert(language_id);
        if (topic_id == '') {
            topic_id = 0;
        }
        if (city_id == '') {
            city_id = 0;
        }


        if (month != '') {
            var resss = month.split('-');
            var d = resss[1] - 1;
            //alert(d)
            var year = resss[0];
            // alert(getLanguage());
            if (getLanguage() == 'ma') {
                var monthNames = ["जानेवारी", "फेब्रुवारी", "मार्च", "एप्रिल", "मे", "जून",
                    "जुलै", "ऑगस्ट", "सप्टेंबर", "ऑक्टोबर", "नोव्हेंबर", "डिसेंबर"
                ];
            } else {
                var monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];
            }
            this.state.guestinfo.monthYearName = monthNames[d] + ' ' + year;
        } else {
            this.state.guestinfo.monthYearName = "";
            var month = "all";
        }


        fetch(myConstClass.BASE_URL + 'event/getEventsForCalendarFrontEnd/' + getLanguage() + '/' + topic_id + '/' + city_id + '/' + month)
            .then(response => response.json())
            .then(response => {
                this.setState({ posts: response })
                const numRows = this.state.posts.length;
                //    alert(numRows);
                if (numRows < 1) {
                    $('#notAvailableDiv').css('display', 'table-row');
                } else {
                    $('#notAvailableDiv').css('display', 'none');
                }
            })

            .catch(err => console.log(err))


        setTimeout(function () {
            $('#applyFilterBtn').css('display', 'inline-block');
            $('#loader').css('display', 'none');
        }, 100);

    }

    componentDidUpdate(prevProps) {
        var user_id = localStorage.getItem('user_id');
        const answer_array = this.props.location.pathname.split('/');
        const { flag } = this.state
        if (getLanguage() == 'ma' && flag == 0) {
            this.setState({ flag: 1 })
            var topic_id = $('#topic_id').val();
            var month = $('#month').val();
            var city_id = $('#city_id').val();
            //alert(language_id);
            if (topic_id == '') {
                topic_id = 0;
            }
            if (city_id == '') {
                city_id = 0;
            }



            fetch(myConstClass.BASE_URL + 'event/getEventsForCalendarFrontEnd/' + getLanguage() + '/' + topic_id + '/' + city_id + '/' + month)
                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response })
                    const numRows = this.state.posts.length;
                    //    alert(numRows);
                    if (numRows < 1) {
                        $('#notAvailableDiv').css('display', 'table-row');
                    } else {
                        $('#notAvailableDiv').css('display', 'none');
                    }

                })
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'event/getEventCalendarMonth' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ month: response }))
                .catch(err => console.log(err))

            var d = new Date(),
                month = d.getMonth(),
                year = d.getFullYear();

            if (getLanguage() == 'ma') {
                var monthNames = ["जानेवारी", "फेब्रुवारी", "मार्च", "एप्रिल", "मे", "जून",
                    "जुलै", "ऑगस्ट", "सप्टेंबर", "ऑक्टोबर", "नोव्हेंबर", "डिसेंबर"
                ];
            } else {
                var monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];
            }
            var selected_month = $('#month').val();
            if (selected_month != '') {
                this.state.guestinfo.monthYearName = monthNames[d.getMonth()] + ' ' + year;
            } else {
                this.state.guestinfo.monthYearName = '';
            }
            fetch(myConstClass.BASE_URL + 'event/getEventLocationFrontEnd/'+getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ arrCity: response }))
            .catch(err => console.log(err))


        }

        if (getLanguage() == 'en' && (flag == 1)) {
            this.setState({ flag: 0 })
            var topic_id = $('#topic_id').val();
            var month = $('#month').val();
            var city_id = $('#city_id').val();
            //alert(language_id);
            if (topic_id == '') {
                topic_id = 0;
            }
            if (city_id == '') {
                city_id = 0;
            }
            fetch(myConstClass.BASE_URL + 'event/getEventsForCalendarFrontEnd/' + getLanguage() + '/' + topic_id + '/' + city_id + '/' + month)

                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response })
                    const numRows = this.state.posts.length;
                    //    alert(numRows);
                    if (numRows < 1) {
                        $('#notAvailableDiv').css('display', 'table-row');
                    } else {
                        $('#notAvailableDiv').css('display', 'none');
                    }
                })
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'event/getEventCalendarMonth' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ month: response }))
                .catch(err => console.log(err))

            var d = new Date(),
                month = d.getMonth(),
                year = d.getFullYear();

            if (getLanguage() == 'ma') {
                var monthNames = ["जानेवारी", "फेब्रुवारी", "मार्च", "एप्रिल", "मे", "जून",
                    "जुलै", "ऑगस्ट", "सप्टेंबर", "ऑक्टोबर", "नोव्हेंबर", "डिसेंबर"
                ];
            } else {
                var monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];
            }

            var selected_month = $('#month').val();
            if (selected_month != '') {
                this.state.guestinfo.monthYearName = monthNames[d.getMonth()] + ' ' + year;
            } else {
                this.state.guestinfo.monthYearName = '';
            }

            fetch(myConstClass.BASE_URL + 'event/getEventLocationFrontEnd/'+getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ arrCity: response }))
            .catch(err => console.log(err))

        }
    }

    renderType = ({ id, title }) => <div className="row">
        <div className="col">
            <div className="col pt-3">
                <h5 className="font-weight-bold">{title}</h5>
            </div>
        </div>
    </div>

    renderGuest = ({ type_id, title, price, quantity }) =>
        <div><div className="row"><div className="col-md-3 mb-3">
            <div className="col border text-center">
                <div className=" py-3">
                    <img src={require("./assets/images/product1.png")} />
                    <div className="my-2">
                        <span className="mr-2">
                            <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                        </span>
                    </div>
                    <p className="font-weight-bold mb-1">{title}</p>
                    <p className="mb-1">Donation Contribution</p>
                    <p className="font-weight-bolder">INR. {price}/-</p>
                    <div className="qty mb-3">
                        <span>Qty-</span>
                        <span className="minus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-minus"></i>
                            </span>
                        </span>
                        <input type="number" className="count" name="qty" value={quantity} />
                        <span className="plus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-plus"></i>
                            </span>
                        </span>
                    </div>
                    <button className="btn btn-brown btn-sm">ADD TO CART</button>
                </div>
            </div></div></div></div>

    render() {
        const { guestinfo, cartEvent, productIncart, posts, arrCity, month, topic } = this.state
        const { t } = this.props;
        return (
            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 px-3">
                            <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-white mb-0">
                                        <li className="breadcrumb-item">
                                            {/* <a href="#">{t('home.home')}</a> */}
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            {/* <a href="#">Products</a> */}
                                            <a href="javascript:void(0)" >{t('home.Events Calendar')}</a>
                                        </li>

                                    </ol>
                                </nav>
                            </div>

                            <Row className="mt-2">
                                <Col xl={{ size: 10 }} lg={{ size: 10 }} md={{ size: 9 }} sm={{ size: 12 }} xs={{ size: 12 }} className="text-xl-left text-lg-left text-md-left text-center">
                                    <h4 style={{ fontSize: '25px' }}>
                                        {t('home.EVENTS CALENDAR')}
                                    </h4>

                                </Col>
                                <Col xl={{ size: 2 }} lg={{ size: 2 }} md={{ size: 3 }} sm={{ size: 2 }} xs={{ size: 2 }} className="d-xl-flex d-lg-flex d-md-flex d-none">
                                    <Link class="btn btn-brown btn-sm" to={"/event-calendar-grid"}   >{t('home.CALENDAR VIEW')}</Link>
                                </Col>
                            </Row>

                            <Row className="mt-2 bg-light py-2">
                                <Col xl="2" lg="2" md="3" sm="12" xs="12" className="align-self-center">
                                    {t('home.Select_filters_to_refine_the_results_below')}:
                                    </Col>

                                {/* Desktop Design Start */}
                                <Col xl="4" lg="4" className="d-xl-flex d-lg-flex d-none">
                                    <div className="form-group w-100">
                                        <label for="">{t('home.Topic')}</label>
                                        <select id="topic_id" name="topic_id" className=" form-control form-control-sm">
                                            <option value="">{t('home.All Topic')}</option>
                                            {topic.map((info, ii) => {
                                                if (this.state.selected_topic_id == info.value) {
                                                    return (
                                                        <option value={info.value} selected="selected">{info.label}</option>
                                                    )
                                                } else {
                                                    return (
                                                        <option value={info.value}>{info.label}</option>
                                                    )
                                                }

                                            })
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xl="2" lg="2" className="d-xl-flex d-lg-flex d-none">
                                    <div className="form-group w-100">
                                        <label for="">{t('home.Month')}</label>
                                        <select id="month" name="month" className="form-control form-control-sm">
                                            <option value="">{t('home.All Month')}</option>
                                            {month.map((info, ii) => {
//                                                if ((this.state.defaultmonth == info.month) || (info.value == this.state.monthvalue)) {
//                                                    return (
//                                                        <option value={info.value} selected="selected">{info.label}</option>
//                                                    )
//                                                } else {
                                                    return (
                                                        <option value={info.value}>{info.label}</option>
                                                    )
//                                                }

                                            })
                                            }
                                        </select>
                                    </div>

                                </Col>
                                <Col xl="2" lg="2"  className="d-xl-flex d-lg-flex d-none">
                                    <div className="form-group w-100">
                                        <label for="">{t('home.Location')}</label>
                                        <select id="city_id" name="city_id" className="form-control form-control-sm">
                                            <option value="">{t('home.All Location')}</option>
                                            {arrCity.map((info, ii) => {
                                                if (this.state.selected_city_id == info.city_id) {
                                                    return (
                                                        <option value={info.city_id} selected="selected">{info.city_name}</option>
                                                    )
                                                } else {
                                                    return (
                                                        <option value={info.city_id}>{info.city_name}</option>
                                                    )
                                                }
                                            })
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xl="2" lg="2"  className="d-xl-flex d-lg-flex d-none">
                                    <div className="form-group w-100">
                                        <label for="" className="invisible">{t('home.Apply Filter')}</label>
                                        <button onClick={this.applyFiltersDesktop} id="applyFilterBtn" className="btn btn-sm btn btn-outline-brown btn-block ">
                                            {t('home.APPLY FILTERS')}
                                        </button>
                                        <img id="loader" style={{ display: 'none', marginTop: '0px' }} src={myConstClass.IMAGE_URL + 'loader.gif'} />
                                    </div>
                                </Col>
                                {/* Desktop Design End */}

                                {/* Mobile Design Start */}
                                <Col md={{ size: 3, order: 2 }} sm={{ size: 12, order: 2 }} xs={{ size: 12, order: 2 }} className="d-xl-none d-lg-none d-flex">
                                    <FormGroup className="w-100">
                                        <Label for="">{t('home.Topic')}</Label>
                                        <Input type="select" bsSize="sm" id="topic_id_m" name="topic_id" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                            <option value="">{t('home.All Topic')}</option>
                                            {topic.map((info, ii) => {
                                                if (this.state.selected_topic_id == info.value) {
                                                    return (
                                                        <option value={info.value} selected="selected">{info.label}</option>
                                                    )
                                                } else {
                                                    return (
                                                        <option value={info.value}>{info.label}</option>
                                                    )
                                                }

                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col md={{ size: 3, order: 1 }} sm={{ size: 6, order: 1 }} xs={{ size: 6, order: 1 }} className="d-xl-none d-lg-none d-flex">
                                    <div className="form-group w-100">
                                        <Label>
                                            {t('home.Month')}
                                        </Label>
                                        <Input type="select" bsSize="sm" id="month_m" name="month" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                            <option value="">{t('home.All Month')}</option>
                                            {month.map((info, ii) => {
                                                if ((this.state.defaultmonth == info.month) || (info.value == this.state.monthvalue)) {
                                                    return (
                                                        <option value={info.value} selected="selected">{info.label}</option>
                                                    )
                                                } else {
                                                    return (
                                                        <option value={info.value}>{info.label}</option>
                                                    )
                                                }

                                            })
                                            }
                                        </Input>
                                    </div>
                                </Col>

                                <Col md={{ size: 3, order: 3 }} sm={{ size: 12, order: 3 }} xs={{ size: 12, order: 3 }} className="d-xl-none d-lg-none d-flex">
                                    <div className="form-group w-100">
                                        <Label for="">{t('home.Location')}</Label>
                                        <Input type="select" id="city_id_m" name="city_id" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                            <option value="">{t('home.All Location')}</option>
                                            {arrCity.map((info, ii) => {
                                                if (this.state.selected_city_id == info.city_id) {
                                                    return (
                                                        <option value={info.city_id} selected="selected">{info.city_name}</option>
                                                    )
                                                } else {
                                                    return (
                                                        <option value={info.city_id}>{info.city_name}</option>
                                                    )
                                                }
                                            })
                                            }
                                        </Input>
                                    </div>
                                </Col>

                                <Col md={{ size: 3, order: 4 }} sm={{ size: 12, order: 4 }} xs={{ size: 12, order: 4 }} className="d-xl-none d-lg-none d-flex">
                                    <div className="form-group w-100">
                                        <Label className="invisible">
                                            {t('home.Apply Filter')}
                                        </Label>
                                        <button onClick={this.applyFiltersMobile} id="applyFilterBtn" className="btn btn-sm btn btn-outline-brown btn-block ">
                                            {t('home.APPLY FILTERS')}
                                        </button>
                                        <img id="loader" style={{ display: 'none', marginTop: '0px' }} src={myConstClass.IMAGE_URL + 'loader.gif'} />
                                    </div>
                                </Col>
                                {/* Mobile Design End */}
                            </Row>

                        </div>


                        <div className="col-lg-12 mt-3">
                            <div class="row">
                                <p style={{ marginLeft: '10px' }}><strong>{guestinfo.monthYearName}</strong></p>
                            </div>

                            { /* Desktop Design  */}
                            <div className="d-xl-block d-lg-block d-none">
                                <div class="row">
                                    <div class="col">
                                        <table class="table table-sm table-bordered tableResponsive">
                                            <thead class="bg-light ">
                                                <tr class="bg-dark-grey ">
                                                    <th scope="col ">{t('home.Date')}</th>
                                                    <th scope="col ">{t('home.Duration')}</th>
                                                    <th scope="col ">{t('home.Name of Event')}</th>
                                                    <th scope="col ">{t('home.Venue')}</th>
                                                    <th scope="col ">{t('home.Status Calendar')}</th>
                                                    <th scope="col "></th>
                                                </tr>
                                            </thead>

                                            {posts.map((infoss, ii) => {
                                                return (
                                                    <tbody>
                                                        {infoss.arr_event.map((cell, i) => {
                                                            if (i == 0) {
                                                                var rowspan = infoss.arr_event.length;
                                                                if (cell.is_full == 0) {
                                                                    return (
                                                                        <tr>
                                                                            <td style={{ verticalAlign: 'middle' }} rowspan={rowspan}>{infoss.date}</td>
                                                                            <td style={{ verticalAlign: 'middle' }}>{cell.duration}&nbsp;{t('home.Days')}</td>
                                                                            <td style={{ verticalAlign: 'middle' }}>{cell.title}</td>
                                                                            <td style={{ width: '400px', verticalAlign: 'middle' }}>{cell.venue_address}</td>
                                                                            <td style={{ verticalAlign: 'middle' }}>
                                                                                {t('home.Open')}
                                                                            </td>
                                                                            <td  >

                                                                                <Link class="btn btn-brown btn-sm" to={"/event_details/" + cell.event_id}   >{t('home.VIEW DETAILS')}</Link>

                                                                            </td>
                                                                        </tr>

                                                                    )
                                                                } else {
                                                                    return (
                                                                        <tr>
                                                                            <td style={{ verticalAlign: 'middle' }} rowspan="2">{infoss.date}</td>
                                                                            <td style={{ verticalAlign: 'middle' }}>{cell.duration}&nbsp;{t('home.Days')}</td>
                                                                            <td style={{ verticalAlign: 'middle' }}>{cell.title}</td>
                                                                            <td style={{ width: '400px', verticalAlign: 'middle' }}>{cell.venue_address}</td>
                                                                            <td style={{ verticalAlign: 'middle' }}>
                                                                                {t('home.FULL')}
                                                                            </td>
                                                                            <td  >

                                                                                <Link class="btn btn-brown btn-sm" to={"/event_details/" + cell.event_id}   >{t('home.VIEW DETAILS')}</Link>

                                                                            </td>
                                                                        </tr>

                                                                    )
                                                                }

                                                            } else {
                                                                if (cell.is_full == 0) {
                                                                    return (
                                                                        <tr>
                                                                            <td style={{ verticalAlign: 'middle' }}>{cell.duration}&nbsp;{t('home.Days')}</td>
                                                                            <td style={{ verticalAlign: 'middle' }}>{cell.title}</td>
                                                                            <td style={{ width: '400px', verticalAlign: 'middle' }}>{cell.venue_address}</td>
                                                                            <td style={{ verticalAlign: 'middle' }}>
                                                                                {t('home.Open')}
                                                                            </td>
                                                                            <td  >

                                                                                <Link class="btn btn-brown btn-sm" to={"/event_details/" + cell.event_id}   >{t('home.VIEW DETAILS')}</Link>

                                                                            </td>
                                                                        </tr>

                                                                    )
                                                                } else {
                                                                    return (
                                                                        <tr>
                                                                            <td style={{ verticalAlign: 'middle' }}>{cell.duration}&nbsp;{t('home.Days')}</td>
                                                                            <td style={{ verticalAlign: 'middle' }}>{cell.title}</td>
                                                                            <td style={{ width: '400px', verticalAlign: 'middle' }}>{cell.venue_address}</td>
                                                                            <td style={{ verticalAlign: 'middle' }}>
                                                                                {t('home.FULL')}
                                                                            </td>
                                                                            <td  >

                                                                                <Link class="btn btn-brown btn-sm" to={"/event_details/" + cell.event_id}   >{t('home.VIEW DETAILS')}</Link>

                                                                            </td>
                                                                        </tr>

                                                                    )
                                                                }

                                                            }

                                                        })
                                                        }
                                                    </tbody>
                                                )

                                            })
                                            }
                                            <tbody>
                                                <tr id="notAvailableDiv" style={{ display: 'none' }}>
                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                        {t('home.No events available')}
                                                    </td>
                                                </tr>
                                            </tbody>


                                        </table>
                                    </div>
                                </div>
                            </div>


                            { /* Mobile Design  */}
                            <div className="d-xl-none d-lg-none d-block">
                                {posts.map((infoss, ii) =>

                                    infoss.arr_event.map((cell, i) =>
                                        <Card key={i} className="bg-light my-3">
                                            <Row className="px-3">
                                                <Col sm="12" xs="12" className="border-bottom py-2">
                                                    <h6 className="mx-0 text-left">
                                                        {infoss.date}
                                                    </h6>
                                                </Col>
                                                <Col sm="12" xs="12">
                                                    <Row className="py-2">
                                                        <Col sm="12" xs="12">
                                                            <Link to={"/event_details/" + cell.event_id} style={{ textDecoration: 'underline' }}>
                                                                {cell.title}
                                                            </Link>
                                                        </Col>
                                                        <Col sm="12" xs="12">
                                                            <small>Duration&nbsp;:&nbsp;{cell.duration}&nbsp;{t('home.Days')}</small>
                                                        </Col>
                                                        <Col sm="12" xs="12">
                                                            <small>Status&nbsp;:&nbsp;{cell.is_full == 0 ? t('home.Open') : t('home.FULL')}</small>
                                                        </Col>
                                                        <Col sm="12" xs="12">
                                                            {cell.venue_address}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    )

                                )
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
                {/* <div className="container-fluid bg-grey mt-4">
        <div className="row grey-text">
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Residential Study Courses</a>
                <a href="#" className="grey-text d-block py-1">1-day Courses</a>
                <a href="#" className="grey-text d-block py-1">Machine Tests</a>
                <a href="#" className="grey-text d-block py-1">Rational Poojas</a>
                <a href="#" className="grey-text d-block py-1">Free Events</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Books</a>
                <a href="#" className="grey-text d-block py-1">CDs</a>
                <a href="#" className="grey-text d-block py-1">Kits (Set of Books)</a>
                <a href="#" className="grey-text d-block py-1">Experimental Products</a>
                <a href="#" className="grey-text d-block py-1">Ayurvedic Products</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Magazines</a>
                <a href="#" className="grey-text d-block py-1">Gallery</a>
                <a href="#" className="grey-text d-block py-1">Downloads</a>
                <a href="#" className="grey-text d-block py-1">FAQs</a>
                <a href="#" className="grey-text d-block py-1">E-Shopping Help</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">About Us</a>
                <a href="#" className="grey-text d-block py-1">Research</a>
                <a href="#" className="grey-text d-block py-1">Event Calendar</a>
                <a href="#" className="grey-text d-block py-1">Feedback</a>
                <a href="#" className="grey-text d-block py-1">Contact Us</a>
            </div>
        </div>
    </div> */}
                {/* <div className="container-fluid bg-black">
        <p className="text-center py-3 grey-text mb-0">
            © 2000-2017 Manashakti Rest New Way, | All Rights Reserved Worldwide
        </p>
    </div> */}
            </div>
        );
    }
}


Event_calendar.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Event_calendar);
