import React , { Component }from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { Editor} from 'react-draft-wysiwyg';
import { EditorState,convertToRaw,convertFromRaw  } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateToHTML} from 'draft-js-export-html'; 
import Header from './Header.js';
import Footer from './Footer.js';
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';

// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,getLanguage,
    translate,
  } from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,  
    Label,
    Row,
    
  } from 'reactstrap';
class Verifyotp extends Component {
    constructor(props) {
      super(props);
      this.product_id=0;
      this.state = {
        isLoading: false,
        flag:0,
        guestinfo: {
            user_id: '',
            otp_email:'',
          },
          otpDetails:[],
      }
      this.handleSubmit = this.handleSubmit.bind(this);
      this.resendOTP = this.resendOTP.bind(this);
      this.resendEmailOTP = this.resendEmailOTP.bind(this);
      this.validator = new SimpleReactValidator();
  }

  resendOTP(){
    var otp_email =  localStorage.getItem('otp_email');
    fetch(myConstClass.BASE_URL+'users/resendOTP/'+otp_email)
    .then(response => response.json())
    .then(response =>{this.setState({eventinfo: response})
    var status =  this.state.eventinfo.status;
    var message =  this.state.eventinfo.message;
    if(status == 'true'){
        toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});
       // setTimeout(this.props.history.push('/'),1000);
    } else {
        toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
        //toast("Wow so easy !");
    } 
    })
      .catch(err => console.log(err))
  }

  resendEmailOTP() {
    const email = localStorage.getItem("otp_email");
    const emailArr = [{ name: "email", value: email }];
    // console.log('email: ',emailArr);
    this.setState({ isLoading: true });
    fetch(myConstClass.BASE_URL+`users/forgotPassword/`, {
      method: "POST",
      body: JSON.stringify(emailArr)
    })
    .then((res) => res.json())
    .then((res) => {
      this.setState({ isLoading: false });
      toast.success(res.message);
    })
    .catch((error) => console.log('error: ',error))
  }

  componentDidMount() {
    var otp_email =  localStorage.getItem('otp_email');
    if(otp_email != '' && otp_email != undefined){
     // alert(otp_email);
      this.state.guestinfo.otp_email = otp_email;
    } else {
      setTimeout(this.props.history.push(''),10);
    }
    var message =  localStorage.getItem('success');
    if(message != '' && message != null ){
      toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});

      localStorage.removeItem('success');
    }
    var error =  localStorage.getItem('error');
    if(error != '' && error != null ){
      toast(''+error, {containerId: 'B',type: toast.TYPE.ERROR});
      localStorage.removeItem('error');
    }
//       fetch(myConstClass.BASE_URL+'product/getAvailProdTypes')
//       .then(response => response.json())
//       .then(response =>this.setState({types: response}))
//       .catch(err => console.log(err))
          
      
//       const answer_array = this.props.location.pathname.split('/');
//        // alert(answer_array);
//     if(answer_array['2']){
     
//   fetch(myConstClass.BASE_URL+'product/getProductInfo/'+answer_array['2']+'/'+getLanguage())
//   .then(response => response.json())
//   .then(response =>this.setState({guestinfo: response}))  
//   .catch(err => console.log(err))

//   fetch(myConstClass.BASE_URL+'product/getRelatedProducts/'+answer_array['2'])
//       .then(response => response.json())
//       .then(response =>this.setState({posts: response}))
//       .catch(err => console.log(err))

//     }  
  }

  componentDidUpdate(prevProps) {
      const answer_array = this.props.location.pathname.split('/');
      const { flag} = this.state  
      // alert(answer_array);
//    if(answer_array['2'] && getLanguage()=='ma' && flag==0){
//     this.setState({flag:1})
//  fetch(myConstClass.BASE_URL+'product/getProductInfo/'+answer_array['2']+'/'+getLanguage())
//  .then(response => response.json())
//  .then(response =>this.setState({guestinfo: response}))  
//  .catch(err => console.log(err))
//    }

//    if(answer_array['2'] && getLanguage()=='en' && (flag==1)){
//     this.setState({flag:0})
//  fetch(myConstClass.BASE_URL+'product/getProductInfo/'+answer_array['2']+'/'+getLanguage())
//  .then(response => response.json())
//  .then(response =>this.setState({guestinfo: response}))  
//  .catch(err => console.log(err))
//    }
  }

  handleSubmit(e) {
    $('#btn_click').css('display','none');
    $('#loader').css('display','inline');
    e.preventDefault();
    var otp_email =  localStorage.getItem('otp_email');
    if (this.validator.allValid()) {
    // console.log('this.refs',$('#pform').serializeArray());
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
    // console.log('-->', formData);
    fetch(myConstClass.BASE_URL+`users/verifyOTP`+'/'+otp_email, {
    method: 'POST',
    body: JSON.stringify($('#pform').serializeArray())
}).then(response => response.json())
.then(response =>{this.setState({otpDetails: response})
var status =  this.state.otpDetails[0].status;
var message =  this.state.otpDetails[0].message;
if(status == 'true'){
    var user_id =  this.state.otpDetails[0].user_id;
    var first_name =  this.state.otpDetails[0].first_name;
    var middle_name =  this.state.otpDetails[0].middle_name;
    var last_name =  this.state.otpDetails[0].last_name;
    var email =  this.state.otpDetails[0].email;
    var is_verify_sadhak_no =   this.state.otpDetails[0].is_verify_sadhak_no;
  localStorage.setItem('is_verify_sadhak_no', is_verify_sadhak_no);
    localStorage.setItem('user_id', user_id);
    localStorage.setItem('first_name', first_name);
    localStorage.setItem('middle_name', middle_name);
    localStorage.setItem('last_name', last_name);
    localStorage.setItem('success', message);
    localStorage.setItem('email', email);
    toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});
    setTimeout(this.props.history.push('/'),1000);
} else {
  $('#btn_click').css('display','inline');
  $('#loader').css('display','none');
    toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
    //toast("Wow so easy !");
} 
})
//}).then(toast('User Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS}),setTimeout(this.props.history.push('/'),100))
    .catch();
    }else{
      $('#btn_click').css('display','inline');
      $('#loader').css('display','none');
        //alert('here');
        this.validator.showMessages();
        console.log('validations'+this.validator.showMessages());
        e.preventDefault();
        toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR})
       // toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR})
    }
  }

  render() {
    const { posts,guestinfo,toast} = this.state  
    const { t } = this.props;
  return (
    <div className="App">
         <div>
        {/* <button onClick={this.notify}>Notify !</button> */}
          <ToastContainer />
        </div>
    <Header />
    <div class="container-fluid">
   
        <div class="row">
            <div class="col-lg-12 mt-3">
                <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb bg-white mb-0">
                            <li class="breadcrumb-item"><Link   to="/">{t('home.home')}</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">{t('home.Verify OTP')}</li>
                        </ol>
                    </nav>
                </div>
                <div class="row">
                    <div class="col">
                        <h4>{t('home.Verify OTP')}</h4>
                        <p>{t('home.Please fill in the details below')}: <span class="color-red">* {t('home.Mandatory Fields')}</span></p>
                    </div>
                </div>
            </div>

            <div class="col col-lg-10 mt-3">
                {/* <form> */}
                <Form action="" id="pform" onSubmit={this.handleSubmit}  method="post" encType="multipart/form-data" className="form-horizontal">
                    
                    <div class="row">
                        <div class="form-group col col-md-4">
                            <label class="color-black font-weight-bolder">{t('home.Email OTP')}<span class="color-red">*</span></label>
                            {/* <input onBlur={() => this.validator.showMessageFor('email')} type="text" class="form-control" id="email" name="email"  placeholder={t('home.Enter Email ID')} /> */}
                            {/* <input type="text" name="otp_email" id="otp_email" value={guestinfo.otp_email} /> */}
                            <input value={guestinfo.email} onChange={e => {this.setState({ guestinfo: {...guestinfo, email:e.target.value}})}} onBlur={() => this.validator.showMessageFor('email')} type="text" id="email" name="email" class="form-control" placeholder={t('home.Enter Email OTP')} />
                            {this.validator.message('email', this.state.guestinfo.email, 'required')}
                        </div>
                        <div class="form-group col col-md-4">
                        {this.state.isLoading ?
                          <img id="loader" name="loader" src={myConstClass.IMAGE_URL+'loader.gif'} class="img-fluid" style={{ position:'absolute', marginTop: '10%' }} />
                          :
                          <a disabled="disabled" onClick={this.resendEmailOTP} style={{textDecoration:'underline',cursor:'pointer',color:'blue',marginTop:'12%',position:'absolute'}} >{t('home.Resend OTP')}</a>}
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="form-group col col-md-4">
                            <label class="color-black font-weight-bolder">{t('home.Mobile OTP')}</label>
                            {/* <input type="number" class="form-control" id="mobile" name="mobile"  placeholder={t('home.Enter Mobile Number')} /> */}
                            <input onChange={e => {this.setState({ guestinfo: {...guestinfo, mobile:e.target.value}})}}  type="number" id="mobile" name="mobile" class="form-control" placeholder={t('home.Enter Mobile OTP')} />
                            
                            {/* {this.validator.message('mobile', this.state.guestinfo.mobile, 'required')} */}
                        </div>
                        <div class="form-group col col-md-4">
                          <label class="color-black font-weight-bolder"></label>
                          <a onClick={this.resendOTP} style={{textDecoration:'underline',cursor:'pointer',color:'blue',marginTop:'12%',position:'absolute'}} >{t('home.Resend OTP')}</a>
                        </div>
                    </div>
                    
                    
                    <div class="row">
                        <div class="form-group col col-md-4">
                          <label>Mobile OTP verification is currently only for India Users. It is not compulsory, but better if done.</label>
                            <button id="btn_click" name="btn_click" class="btn btn-brown">{t('home.Verify')}</button>
                            <img style={{display:'none'}} id="loader" name="loader" src={myConstClass.IMAGE_URL+'loader.gif'} class="img-fluid" />
                        </div>
                    </div>
                    </Form>

            </div>
        </div>
    </div>
    <Footer />
    </div>
  );
}
}
Verifyotp.propTypes = {
    t: PropTypes.func.isRequired,
  };
export default translate(Verifyotp);
