import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backgroundEvent from './assets/images/machine-tests-bg.png';
import { Route, Link, BrowserRouter as Router, HashRouter, } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import './assets/fontawesome/css/all.min.css';
import logo from './assets/images/logo.png';
import logomr from './assets/images/logomr.png';
import Footer from './Footer.js';
import * as myConstClass from './constant.js';
import $ from 'jquery';
import { Base64 } from 'js-base64';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import pdf from './assets/images/pdf.png';
import image from './assets/images/jpg.png';
import excel from './assets/images/excel.png';
import doc from './assets/images/doc.png';

import {
    Input
} from 'reactstrap';
import ResidentialStudyCourses from './assets/images/ResidentialStudyCourses.jpg';
import OneDayWorkshop from './assets/images/OneDayWorkshop.jpg';
import RationalPoojas from './assets/images/RationalPoojas.jpg';
import FreeEvents from './assets/images/FreeEvents.jpg';
import MachineTests from './assets/images/MachineTests.jpg';

import AyurvedicProducts from './assets/images/AyurvedicProducts.jpg';
import BalDiwali from './assets/images/BalDiwali.jpg';
import Books from './assets/images/Books.jpg';
import CDs from './assets/images/CDs.jpg';
import DIWALIMAGAZINE from './assets/images/DIWALIMAGAZINE.jpg';
import ExperimentalProducts from './assets/images/ExperimentalProducts.jpg';
import KITs from './assets/images/KITs.jpg';
import Miscellaneous from './assets/images/Miscellaneous.jpg';
import ResidentialStudyCourses_mr from './assets/images/Residential_Study_Courses_m_mr.jpg';
import OneDayWorkshop_mr from './assets/images/One_Day_Workshop_m_mr.jpg';
import RationalPoojas_mr from './assets/images/Rational_Poojas_m_mr.jpg';
import FreeEvents_mr from './assets/images/Free_Events_m_mr.jpg';
import MachineTests_mr from './assets/images/Machine_Tests_m_mr.jpg';
import AyurvedicProducts_mr from './assets/images/Ayurvedic_Products_m_mr.jpg';
import BalDiwali_mr from './assets/images/Bal_Diwali_m_mr.jpg';
import Books_mr from './assets/images/Books_m_mr.jpg';
import CDs_mr from './assets/images/CDs_m_mr.jpg';
import DIWALIMAGAZINE_mr from './assets/images/DIWALI_MAGAZINE_m_mr.jpg';
import ExperimentalProducts_mr from './assets/images/Experimental_Products_m_mr.jpg';
import KITs_mr from './assets/images/KITs_m_mr.jpg';
import Miscellaneous_mr from './assets/images/Miscellaneous_m_mr.jpg';

setTranslations({ en, ma });
setDefaultLanguage('en');
setLanguageCookie();

//console.log('.push(',{body3});

// async function getMovies(){
//   await fetch(myConstClass.BASE_URL+'product/getProductsFrontEnd')
//     .then((response) => response.json());
// }

class Sitesearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noResultFound: false,
            searchNotEmpty: false,
            ResidentialStudyCourses: '',
            OneDayWorkshop: '',
            RationalPoojas: '',
            FreeEvents: '',
            MachineTests: '',
            AyurvedicProducts: '',
            BalDiwali: '',
            Books: '',
            CDs: '',
            DIWALIMAGAZINE: '',
            ExperimentalProducts: '',
            KITs: '',
            others_available: 0,
            redirect: false,
            arrResearch: [],
            arrNews: [],
            arrOther: [],
            arrOtherAboutUs: [],
            arr_photo_gallery: [],
            arr_gallery_photo: [],
            arr_gallery_video: [],
            arr_gallery_audio: [],
            arr_gallery_wallpaper: [],
            arr_downloads: [],
            userinfo: {
                first_name: '',
                middle_name: '',
                last_name: '',
                profile_picture: 'profile.jpg',
            },
            cartcount: {
                totalcartcount: '0',
            },
            searchResultFor: '',
            eventdetails: {
                event_type: '',
                event_description: '',
            },
            guestinfo: {
                event_type: '',
                event_type_id: '',
                topic_id: '',
                sub_topic_id: '',
                event_type_name: '',
                event_id: '0',
                schedule_event_name: '',
                language_id: '',
            },
            flag: 0,
            quantityValue: '1',
            posts: [],
            arrMagazine: [],
            products: [],
            eventinfo: [],
            languages: [],
            topic: [],
            eventTypes: [],
            scheduleEvent: [],
            cart: [],
            cartEvent: [],
            removeFromCart: [],
            eventdetails: [],
            types: [],
            subMenuLevel1: false,
            subMenuLevel1Heading: '',
            subMenuLevel1List: [],
            subMenuLevel2Heading: '',
            subMenuLevel2: false,
            subMenuLevel2List: []
        }
        this.key = 'en';
        this.handleChange = this.handleChange.bind(this);

        this.applyFilters = this.applyFilters.bind(this);
        this.rightArrow = this.rightArrow.bind(this);
        this.leftArrow = this.leftArrow.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleKeyPressOther = this.handleKeyPressOther.bind(this);
        this.goToback = this.goToback.bind(this);
        this.handleSearchReset = this.handleSearchReset.bind(this);
        this.handleSetLanguageNew = this.handleSetLanguageNew.bind(this);
        this.checkNoResults = this.checkNoResults.bind(this);
        this.handleSearchResult = this.handleSearchResult.bind(this);
    }

    showSubMenuLevel2(subMenu) {
        var docs = [];

        if (this.state.subMenuLevel1Heading == this.props.t('home.TOPICS')) {
            if (subMenu === this.props.t('home.Newly Wed Couples')) {
                docs.push(
                    <Link className="dropdown-item" to="/topics/Newly Wed Couples/1" onClick={() => this.closeNav()}>
                        All Subtopics
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Newly Wed Couples/1/1" onClick={() => this.closeNav()}>
                        {this.props.t('home.Happy Marriage')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Newly Wed Couples/1/2" onClick={() => this.closeNav()}>
                        {this.props.t('home.Super Procreation (Suprajanan)')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Newly Wed Couples/1/3" onClick={() => this.closeNav()}>
                        {
                            this.props.t('home.Regarding Infertility')
                        }
                    </Link>
                )
                this.setState({
                    subMenuLevel2: true,
                    subMenuLevel2List: docs,
                    subMenuLevel2Heading: this.props.t('home.Newly Wed Couples')
                })
            } else if (subMenu === 'Garbha Sanskar') {
                docs.push(
                    <Link className="dropdown-item" to="/topics/Garbha Sanskar (Prenatal Education)/2" onClick={() => this.closeNav()}>
                        All Subtopics
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Garbha Sanskar (Prenatal Education)/2/4" onClick={() => this.closeNav()}>
                        {this.props.t('home.Garbha Sanskar')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Garbha Sanskar (Prenatal Education)/2/5" onClick={() => this.closeNav()}>
                        {this.props.t('home.Music for Unborn')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Garbha Sanskar (Prenatal Education)/2/6" onClick={() => this.closeNav()}>
                        {this.props.t('home.Yoga during Pregnancy')}
                    </Link>
                )
                this.setState({
                    subMenuLevel2: true,
                    subMenuLevel2List: docs,
                    subMenuLevel2Heading: this.props.t('home.Garbha Sanskar') + ' ' + this.props.t('home.(Prenatal Education)')
                })
            } else if (subMenu === 'Nurturing Kids Birth up to 7 years') {
                docs.push(
                    <Link className="dropdown-item" to="/topics/Nurturing Kids-Birth up to 7 years/3" onClick={() => this.closeNav()}>
                        All Subtopics
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Nurturing Kids-Birth up to 7 years/3/7" onClick={() => this.closeNav()}>
                        {this.props.t('home.For Kids')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Nurturing Kids-Birth up to 7 years/3/8" onClick={() => this.closeNav()}>
                        {this.props.t('home.For Parents')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Nurturing Kids-Birth up to 7 years/3/9" onClick={() => this.closeNav()}>
                        {this.props.t('home.For Mentally Challenged')}
                    </Link>
                )
                this.setState({
                    subMenuLevel2: true,
                    subMenuLevel2List: docs,
                    subMenuLevel2Heading: this.props.t('home.Nurturing Kids') + ' ' + this.props.t('home.Birth up to 7 years')
                })
            } else if (subMenu === 'Child Development 8 to 14 years') {
                docs.push(
                    <Link className="dropdown-item" to="/topics/Child Development-8 to 14 years/4" onClick={() => this.closeNav()}>
                        All Subtopics
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Child Development-8 to 14 years/4/10" onClick={() => this.closeNav()}>
                        {this.props.t('home.For Children')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Child Development-8 to 14 years/4/11" onClick={() => this.closeNav()}>
                        {this.props.t('home.For Parents')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Child Development-8 to 14 years/4/12" onClick={() => this.closeNav()}>
                        {this.props.t('home.For Teachers')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Child Development-8 to 14 years/4/12" onClick={() => this.closeNav()}>
                        {this.props.t('home.For Mentally Challenged')}
                    </Link>
                )

                this.setState({
                    subMenuLevel2: true,
                    subMenuLevel2List: docs,
                    subMenuLevel2Heading: this.props.t('home.Child Development') + ' ' + this.props.t('home.8 to 14 years')
                })
            } else if (subMenu === 'Youth Power 15 to 28 years') {
                docs.push(
                    <Link className="dropdown-item" to="/topics/Youth Power-15 to 28 years/5" onClick={() => this.closeNav()}>
                        All Subtopics
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Youth Power-15 to 28 years/5/14" onClick={() => this.closeNav()}>
                        {this.props.t('home.For Teenagers: 15 to 21 years')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Youth Power-15 to 28 years/5/15" onClick={() => this.closeNav()}>
                        {this.props.t('home.For Parents of Teenagers')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Youth Power-15 to 28 years/5/16" onClick={() => this.closeNav()}>
                        {this.props.t('home.For Youths: 22 to 28 years')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Youth Power-15 to 28 years/5/17" onClick={() => this.closeNav()}>
                        {this.props.t('home.Pre-Marriage Adjustment')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Youth Power-15 to 28 years/5/40" onClick={() => this.closeNav()}>
                        {this.props.t('home.Post-Marriage Adjustment')}
                    </Link>
                )
                this.setState({
                    subMenuLevel2: true,
                    subMenuLevel2List: docs,
                    subMenuLevel2Heading: this.props.t('home.Youth Power:') + ' ' + this.props.t('home.15 to 28 years')
                })
            } else if (subMenu === 'Stress Management') {
                docs.push(
                    <Link className="dropdown-item" to="/topics/Managing Stress/6" onClick={() => this.closeNav()}>
                        All Subtopics
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Managing Stress/6/18" onClick={() => this.closeNav()}>
                        {this.props.t('home.Managing Stress')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Managing Stress/6/19" onClick={() => this.closeNav()}>
                        {this.props.t('home.Relief from Jealousy')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Managing Stress/6/20" onClick={() => this.closeNav()}>
                        {this.props.t('home.De-Addiction,')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Managing Stress/6/39" onClick={() => this.closeNav()}>
                        {this.props.t('home.Success in Occupation')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Managing Stress/6/21" onClick={() => this.closeNav()}>
                        {this.props.t('home.Music Therapy')}
                    </Link>
                )
                this.setState({
                    subMenuLevel2: true,
                    subMenuLevel2List: docs,
                    subMenuLevel2Heading: this.props.t('home.Stress Management')
                })
            } else if (subMenu === 'Health Care') {
                docs.push(
                    <Link className="dropdown-item" to="/topics/Health Care/7" onClick={() => this.closeNav()}>
                        All Subtopics
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Health Care/7/22" onClick={() => this.closeNav()}>
                        {this.props.t('home.Cure without Medicine')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Health Care/7/23" onClick={() => this.closeNav()}>
                        {this.props.t('home.For Senior Citizens')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Health Care/7/24" onClick={() => this.closeNav()}>
                        {this.props.t('home.Plant Therapy')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Health Care/7/25" onClick={() => this.closeNav()}>
                        {this.props.t('home.Ayurvedic Products Header')}
                    </Link>
                )
                this.setState({
                    subMenuLevel2: true,
                    subMenuLevel2List: docs,
                    subMenuLevel2Heading: this.props.t('home.Health Care')
                })
            } else if (subMenu === 'Meditation & Yoga') {
                docs.push(
                    <Link className="dropdown-item" to="/topics/Meditation & Yoga/8" onClick={() => this.closeNav()}>
                        All Subtopics
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Meditation & Yoga/8/26" onClick={() => this.closeNav()}>
                        {this.props.t('home.Meditation')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Meditation & Yoga/8/27" onClick={() => this.closeNav()}>
                        {this.props.t('home.Yoga')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Meditation & Yoga/8/28" onClick={() => this.closeNav()}>
                        {this.props.t('home.Scientific Pranayam')}
                    </Link>
                )
                this.setState({
                    subMenuLevel2: true,
                    subMenuLevel2List: docs,
                    subMenuLevel2Heading: this.props.t('home.Meditation & Yoga')
                })
            } else if (subMenu === 'Science of Spirituality') {
                docs.push(
                    <Link className="dropdown-item" to="/topics/Science of Spirituality/9" onClick={() => this.closeNav()}>
                        All Subtopics
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Science of Spirituality/9/29" onClick={() => this.closeNav()}>
                        {this.props.t('home.Mind')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Science of Spirituality/9/30" onClick={() => this.closeNav()}>
                        {this.props.t('home.God')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Science of Spirituality/9/31" onClick={() => this.closeNav()}>
                        {this.props.t('home.Mantra')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Science of Spirituality/9/32" onClick={() => this.closeNav()}>
                        {this.props.t('home.Religion')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Science of Spirituality/9/33" onClick={() => this.closeNav()}>
                        {this.props.t('home.Fortune')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Science of Spirituality/9/34" onClick={() => this.closeNav()}>
                        {this.props.t('home.Prayer')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Science of Spirituality/9/35" onClick={() => this.closeNav()}>
                        {this.props.t('home.Yadnya')}
                    </Link>
                )
                this.setState({
                    subMenuLevel2: true,
                    subMenuLevel2List: docs,
                    subMenuLevel2Heading: this.props.t('home.Science of Spirituality')
                })
            } else if (subMenu === 'Life after Death') {
                docs.push(
                    <Link className="dropdown-item" to="/topics/Life after Death/10" onClick={() => this.closeNav()}>
                        All Subtopics
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Life after Death/10/36" onClick={() => this.closeNav()}>
                        {this.props.t('home.Rebirth/Reincarnation')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/topics/Life after Death/10/37" onClick={() => this.closeNav()}>
                        {this.props.t('home.Shraddha')}
                    </Link>
                )
                this.setState({
                    subMenuLevel2: true,
                    subMenuLevel2List: docs,
                    subMenuLevel2Heading: this.props.t('home.Life after Death')
                })
            }
        }
        else if (this.state.subMenuLevel1Heading === this.props.t('home.PRODUCTS')) {
            if (subMenu === 'Books') {
                docs.push(
                    <Link className="dropdown-item" to="/product_type/books/1" onClick={() => this.closeNav()}>
                        All Topics
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/books/1/1" onClick={() => this.closeNav()}>
                        {this.props.t('home.Newly Wed Couples')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/books/1/2" onClick={() => this.closeNav()}>
                        {this.props.t('home.Pre Natal(Garbha Sanskar)')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/books/1/3" onClick={() => this.closeNav()}>{this.props.t('home.Nurturing Kids')}<br />
                        {this.props.t('home.Birth up to 7 years')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/books/1/4" onClick={() => this.closeNav()}>
                        {this.props.t('home.Child Development & Parenting - Birth 8 to 14 years')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/books/1/5" onClick={() => this.closeNav()}>
                        {this.props.t('home.Youth Power')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/books/1/6" onClick={() => this.closeNav()}>
                        {this.props.t('home.Stress Management')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/books/1/7" onClick={() => this.closeNav()}>
                        {this.props.t('home.Health Care')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/books/1/8" onClick={() => this.closeNav()}>
                        {this.props.t('home.Meditation & Yoga')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/books/1/9" onClick={() => this.closeNav()}>
                        {this.props.t('home.Science of Spirituality')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/books/1/10" onClick={() => this.closeNav()}>
                        {this.props.t('home.Life after Death')}  </Link>
                )
                this.setState({
                    subMenuLevel2: true,
                    subMenuLevel2List: docs,
                    subMenuLevel2Heading: this.props.t('home.Books')
                })
            } else if (subMenu === 'Kits') {
                docs.push(
                    <Link className="dropdown-item" to="/product_type/kits/2" onClick={() => this.closeNav()}>
                        All Topics
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/kits/2/1" onClick={() => this.closeNav()}>
                        {this.props.t('home.Newly Wed Couples')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/kits/2/2" onClick={() => this.closeNav()}>
                        {this.props.t('home.Pre Natal(Garbha Sanskar)')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/kits/2/3" onClick={() => this.closeNav()}>{this.props.t('home.Nurturing Kids')}<br />
                        {this.props.t('home.Birth up to 7 years')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/kits/2/4" onClick={() => this.closeNav()}>
                        {this.props.t('home.Child Development & Parenting - Birth 8 to 14 years')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/kits/2/5" onClick={() => this.closeNav()}>
                        {this.props.t('home.Youth Power')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/kits/2/6" onClick={() => this.closeNav()}>
                        {this.props.t('home.Stress Management')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/kits/2/7" onClick={() => this.closeNav()}>
                        {this.props.t('home.Health Care')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/kits/2/8" onClick={() => this.closeNav()}>
                        {this.props.t('home.Meditation & Yoga')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/kits/2/9" onClick={() => this.closeNav()}>
                        {this.props.t('home.Science of Spirituality')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/kits/2/10" onClick={() => this.closeNav()}>
                        {this.props.t('home.Life after Death')}
                    </Link>
                )
                this.setState({
                    subMenuLevel2: true,
                    subMenuLevel2List: docs,
                    subMenuLevel2Heading: this.props.t('home.Kits')
                })
            } else if (subMenu === 'CDs') {
                docs.push(
                    <Link className="dropdown-item" to="/product_type/CDs/3" onClick={() => this.closeNav()}>
                        All Topics
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/CDs/3/1" onClick={() => this.closeNav()}>
                        {this.props.t('home.Newly Wed Couples')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/CDs/3/2" onClick={() => this.closeNav()}>
                        {this.props.t('home.Pre Natal(Garbha Sanskar)')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/CDs/3/3" onClick={() => this.closeNav()}>{this.props.t('home.Nurturing Kids')}<br />
                        {this.props.t('home.Birth up to 7 years')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/CDs/3/4" onClick={() => this.closeNav()}>
                        {this.props.t('home.Child Development & Parenting - Birth 8 to 14 years')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/CDs/3/5" onClick={() => this.closeNav()}>
                        {this.props.t('home.Youth Power')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/CDs/3/6" onClick={() => this.closeNav()}>
                        {this.props.t('home.Stress Management')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/CDs/3/7" onClick={() => this.closeNav()}>
                        {this.props.t('home.Health Care')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/CDs/3/8" onClick={() => this.closeNav()}>
                        {this.props.t('home.Meditation & Yoga')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/CDs/3/9" onClick={() => this.closeNav()}>
                        {this.props.t('home.Science of Spirituality')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/CDs/3/10" onClick={() => this.closeNav()}>
                        {this.props.t('home.Life after Death')}
                    </Link>
                )
                this.setState({
                    subMenuLevel2: true,
                    subMenuLevel2List: docs,
                    subMenuLevel2Heading: this.props.t('home.CDs')
                })
            } else if (subMenu === 'Experimentals Products') {
                docs.push(
                    <Link className="dropdown-item" to="/product_type/experimental/4" onClick={() => this.closeNav()}>
                        All Topics
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/experimental/4/1" onClick={() => this.closeNav()}>
                        {this.props.t('home.Newly Wed Couples')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/experimental/4/2" onClick={() => this.closeNav()}>
                        {this.props.t('home.Pre Natal(Garbha Sanskar)')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/experimental/4/3" onClick={() => this.closeNav()}>{this.props.t('home.Nurturing Kids')}<br />
                        {this.props.t('home.Birth up to 7 years')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/experimental/4/4" onClick={() => this.closeNav()}>
                        {this.props.t('home.Child Development & Parenting - Birth 8 to 14 years')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/experimental/4/5" onClick={() => this.closeNav()}>
                        {this.props.t('home.Youth Power')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/experimental/4/6" onClick={() => this.closeNav()}>
                        {this.props.t('home.Stress Management')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/experimental/4/7" onClick={() => this.closeNav()}>
                        {this.props.t('home.Health Care')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/experimental/4/8" onClick={() => this.closeNav()}>
                        {this.props.t('home.Meditation & Yoga')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/experimental/4/9" onClick={() => this.closeNav()}>
                        {this.props.t('home.Science of Spirituality')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/experimental/4/10" onClick={() => this.closeNav()}>
                        {this.props.t('home.Life after Death')}
                    </Link>
                )
                this.setState({
                    subMenuLevel2: true,
                    subMenuLevel2List: docs,
                    subMenuLevel2Heading: this.props.t('home.Experimentals Products')
                })
            } else if (subMenu === 'Ayurvedic') {
                docs.push(
                    <Link className="dropdown-item" to="/product_type/ayurvedic/5" onClick={() => this.closeNav()}>
                        All Topics
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/ayurvedic/5/1" onClick={() => this.closeNav()}>
                        {this.props.t('home.Newly Wed Couples')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/ayurvedic/5/2" onClick={() => this.closeNav()}>
                        {this.props.t('home.Pre Natal(Garbha Sanskar)')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/ayurvedic/5/3" onClick={() => this.closeNav()}>{this.props.t('home.Nurturing Kids')}<br />
                        {this.props.t('home.Birth up to 7 years')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/ayurvedic/5/4" onClick={() => this.closeNav()}>
                        {this.props.t('home.Child Development & Parenting - Birth 8 to 14 years')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/ayurvedic/5/5" onClick={() => this.closeNav()}>
                        {this.props.t('home.Youth Power')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/ayurvedic/5/6" onClick={() => this.closeNav()}>
                        {this.props.t('home.Stress Management')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/ayurvedic/5/7" onClick={() => this.closeNav()}>
                        {this.props.t('home.Health Care')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/ayurvedic/5/8" onClick={() => this.closeNav()}>
                        {this.props.t('home.Meditation & Yoga')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/ayurvedic/5/9" onClick={() => this.closeNav()}>
                        {this.props.t('home.Science of Spirituality')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/product_type/ayurvedic/5/10" onClick={() => this.closeNav()}>
                        {this.props.t('home.Life after Death')}
                    </Link>
                )
                this.setState({
                    subMenuLevel2: true,
                    subMenuLevel2List: docs,
                    subMenuLevel2Heading: this.props.t('home.Ayurvedic')
                })
            }
        } else if (this.state.subMenuLevel1Heading == this.props.t('home.MORE')) {
            if (subMenu === 'Gallery') {
                docs.push(
                    <Link className="dropdown-item" to="/photo-gallery" onClick={() => this.closeNav()}>
                        {this.props.t('home.Photo Gallery')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/video-gallery" onClick={() => this.closeNav()}>
                        {this.props.t('home.Video Gallery')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/audio-gallery" onClick={() => this.closeNav()}>
                        {this.props.t('home.Audio Gallery')}
                    </Link>
                )
                docs.push(
                    <Link className="dropdown-item" to="/wallpaper-gallery" onClick={() => this.closeNav()}>
                        {this.props.t('home.Wallpaper Gallery')}
                    </Link>
                )
                this.setState({
                    subMenuLevel2: true,
                    subMenuLevel2List: docs,
                    subMenuLevel2Heading: this.props.t('home.Gallery')
                })
            }
        }
    }

    showSubMenuLevel1(subMenu) {
        let docs = [];

        if (subMenu === 'events') {
            docs.push(
                <a className="dropdown-item" href="https://onlinecourses.manashakti.org/" target="_blank" onClick={() => this.closeNav()} >
                    {this.props.t('home.Online Courses')}
                </a>
            )
            docs.push(
                <a className="dropdown-item" href="https://onlineselftests.manashakti.org/" target="_blank" onClick={() => this.closeNav()}>
                    {this.props.t('home.Online Tests')}
                </a>
            )
            docs.push(
                <Link className="dropdown-item" to="/events/Residential Study Courses/1" onClick={() => this.closeNav()}>
                    {this.props.t('home.Residential Study Courses')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/events/1-Day Workshops/2" onClick={() => this.closeNav()}>
                    {this.props.t('home.1-Day Workshops')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/machine-test/Machine Tests/3" onClick={() => this.closeNav()}>
                    {this.props.t('home.Machine Tests')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/event_details/83" onClick={() => this.closeNav()}>
                    {this.props.t('home.Psycho-Feedback Therapy')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/events/Rational Poojas/4" onClick={() => this.closeNav()}>
                    {this.props.t('home.Rational Poojs')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/events/Free Events/5" onClick={() => this.closeNav()}>
                    {this.props.t('home.Free Events')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/organize-a-trip/7" onClick={() => this.closeNav()}>
                    {this.props.t('home.Organize a Trip')}
                </Link>
            )
            this.setState({
                subMenuLevel1: true,
                subMenuLevel1List: docs,
                subMenuLevel1Heading: this.props.t('home.EVENTS')
            })
        } else if (subMenu === 'topics') {
            docs.push(
                <Link className="dropdown-item" onClick={() => this.showSubMenuLevel2('Newly Wed Couples')}>
                    {this.props.t('home.Newly Wed Couples')}<i className="fa fa-angle-right float-right mx-3"></i>
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" onClick={() => this.showSubMenuLevel2('Garbha Sanskar')}>
                    {this.props.t('home.Garbha Sanskar')}<i className="fa fa-angle-right float-right mx-3"></i>
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" onClick={() => this.showSubMenuLevel2('Nurturing Kids Birth up to 7 years')}>
                    {this.props.t('home.Nurturing Kids') + ' ' + this.props.t('home.Birth up to 7 years')}<i className="fa fa-angle-right float-right mx-3"></i>
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" onClick={() => this.showSubMenuLevel2('Child Development 8 to 14 years')}>
                    {this.props.t('home.Child Development') + ' ' + this.props.t('home.8 to 14 years')}<i className="fa fa-angle-right float-right mx-3"></i>
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" onClick={() => this.showSubMenuLevel2('Youth Power 15 to 28 years')}>
                    {this.props.t('home.Youth Power:') + ' ' + this.props.t('home.15 to 28 years')}<i className="fa fa-angle-right float-right mx-3"></i>
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" onClick={() => this.showSubMenuLevel2('Stress Management')}>
                    {this.props.t('home.Stress Management')}<i className="fa fa-angle-right float-right mx-3"></i>
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" onClick={() => this.showSubMenuLevel2('Health Care')}>
                    {this.props.t('home.Health Care')}<i className="fa fa-angle-right float-right mx-3"></i>
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" onClick={() => this.showSubMenuLevel2('Meditation & Yoga')}>
                    {this.props.t('home.Meditation & Yoga')}<i className="fa fa-angle-right float-right mx-3"></i>
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" onClick={() => this.showSubMenuLevel2('Science of Spirituality')}>
                    {this.props.t('home.Science of Spirituality')}<i className="fa fa-angle-right float-right mx-3"></i>
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" onClick={() => this.showSubMenuLevel2('Life after Death')}>
                    {this.props.t('home.Life after Death')}<i className="fa fa-angle-right float-right mx-3"></i>
                </Link>
            )
            this.setState({
                subMenuLevel1: true,
                subMenuLevel1List: docs,
                subMenuLevel1Heading: this.props.t('home.TOPICS')
            })
        } else if (subMenu === 'products') {
            docs.push(
                <Link className="dropdown-item" onClick={() => this.showSubMenuLevel2('Books')}>
                    {this.props.t('home.Books')}<i className="fa fa-angle-right float-right mx-3"></i>
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" onClick={() => this.showSubMenuLevel2('Kits')}>
                    {this.props.t('home.Kits')}<i className="fa fa-angle-right float-right mx-3"></i>
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" onClick={() => this.showSubMenuLevel2('CDs')}>
                    {this.props.t('home.CDs')}<i className="fa fa-angle-right float-right mx-3"></i>
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" onClick={() => this.showSubMenuLevel2('Experimentals Products')}>
                    {this.props.t('home.Experimentals Products')}<i className="fa fa-angle-right float-right mx-3"></i>
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" onClick={() => this.showSubMenuLevel2('Ayurvedic')}>
                    {this.props.t('home.Ayurvedic')}<i className="fa fa-angle-right float-right mx-3"></i>
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/magazine">
                    {this.props.t('home.Magazine')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/product_type/diwali/6">
                    {this.props.t('home.Diwali Issue')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/product_type/bal-diwali/7">
                    {this.props.t('home.Bal Diwali Issue')}
                </Link>
            )
            this.setState({
                subMenuLevel1: true,
                subMenuLevel1List: docs,
                subMenuLevel1Heading: this.props.t('home.PRODUCTS')
            })
        } else if (subMenu === 'myAccount') {
            docs.push(
                <Link className="dropdown-item" to="/order-history" onClick={() => this.closeNav()}>
                    {this.props.t('home.Order History')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/participants" onClick={() => this.closeNav()}>
                    {this.props.t('home.Master List of Participants')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/my-interactions" onClick={() => this.closeNav()}>
                    {this.props.t('home.My Interactions')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/change-password" onClick={() => this.closeNav()}>
                    {this.props.t('home.Change Password')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/profile" onClick={() => this.closeNav()}>
                    {this.props.t('home.Profile')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/donation-contribution" onClick={() => this.closeNav()}>
                    {this.props.t('home.donation_contribution')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/logout" onClick={() => this.closeNav()}>
                    {this.props.t('home.Sign Out')}
                </Link>
            )
            this.setState({
                subMenuLevel1: true,
                subMenuLevel1List: docs,
                subMenuLevel1Heading: this.props.t('home.My Account')
            })
        } else if (subMenu === 'more') {
            docs.push(
                <Link className="dropdown-item" onClick={() => this.showSubMenuLevel2('Gallery')}>
                    {this.props.t('home.Gallery')}<i className="fa fa-angle-right float-right mx-3"></i>
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/news/1" onClick={() => this.closeNav()}>
                    {this.props.t('home.News')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/download" onClick={() => this.closeNav()}>
                    {this.props.t('home.Downloads')}
                </Link>
            )
            docs.push(
                <a style={{ cursor: 'pointer' }} className="dropdown-item" data-toggle="modal" data-target="#subscribeModel" onClick={() => this.closeNav()}>{this.props.t('home.Newsletter')}</a>
            )
            docs.push(
                <a target="_blank" href="https://newwayphilosophy.blogspot.com/" style={{ cursor: 'default' }} className="dropdown-item" onClick={() => this.closeNav()}>{this.props.t('home.Blog')}</a>
            )
            docs.push(
                <Link className="dropdown-item" to="/faqs" onClick={() => this.closeNav()}>
                    {this.props.t('home.FAQs')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/e-shopping-help" onClick={() => this.closeNav()}>
                    {this.props.t('e_shopping_help')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/site-map" onClick={() => this.closeNav()}>
                    {this.props.t('home.Site Map')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/feedback" onClick={() => this.closeNav()}>
                    {this.props.t('home.Feedback')}
                </Link>
            )
            this.setState({
                subMenuLevel1: true,
                subMenuLevel1List: docs,
                subMenuLevel1Heading: this.props.t('home.MORE')
            })
        } else if (subMenu === 'aboutUs') {
            docs.push(
                <Link className="dropdown-item" to="/about-us/6" onClick={() => this.closeNav()}>
                    {this.props.t('home.ABOUT US')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/about-us/1" onClick={() => this.closeNav()}>
                    {this.props.t('home.Vission & Mission')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/about-us/2" onClick={() => this.closeNav()}>
                    {this.props.t('home.First Thinker')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/about-us/8" onClick={() => this.closeNav()}>
                    {this.props.t('home.New Way Philosopy')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/about-us/3" onClick={() => this.closeNav()}>
                    {this.props.t('home.Values')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/about-us/4" onClick={() => this.closeNav()}>
                    {this.props.t('home.Work Areas')}
                </Link>
            )
            docs.push(
                <Link className="dropdown-item" to="/about-us/5" onClick={() => this.closeNav()}>
                    {this.props.t('home.Comments Of World Thinkers')}
                </Link>
            )
            this.setState({
                subMenuLevel1: true,
                subMenuLevel1List: docs,
                subMenuLevel1Heading: this.props.t('home.ABOUT US')
            })
        }
    }

    closeNav() {
        this.setState({
            subMenuLevel1: false,
            subMenuLevel1Heading: '',
            subMenuLevel1List: [],
            subMenuLevel2Heading: '',
            subMenuLevel2: false,
            subMenuLevel2List: [],
        })
        document.getElementById("mySidenav").style.width = "0";
    }
    openNav() {
        document.getElementById("mySidenav").style.width = '90%';
    }

    goToback() {
        //hashHistory.push('/premontessori');
    }
    handleSetLanguage = (key) => () => {
        if (key == 'en') {
            this.key = 'ma';
        } else {
            this.key = 'en';
        }
        setLanguage(this.key);
        setLanguageCookie('lang_key');
    };

    handleSetLanguageNew = (key) => () => {
        if (key == 'en') {
            this.key = 'ma';
            $('#logoEnglish').css('display', 'none');
            $('#logoMarathi').css('display', 'inline');
        } else {
            $('#logoEnglish_m').css('display', 'inline');
            $('#logoMarathi_m').css('display', 'none');
            this.key = 'en';
        }
        setLanguage(this.key);
        setLanguageCookie('lang_key');
        localStorage.removeItem('key');
        localStorage.setItem('key', key);
        this.handleSetLanguage(key);
    }

    rightArrow() {
        var view = $("#tslshow");
        var move = "100px";
        var sliderLimit = -111750
        var currentPosition = parseInt(view.css("left"));
        if (currentPosition >= sliderLimit) view.stop(false, true).animate({ left: "-=" + move }, { duration: 400 })
    }

    leftArrow() {
        var view = $("#tslshow");
        var move = "100px";
        var sliderLimit = -111750
        var currentPosition = parseInt(view.css("left"));
        if (currentPosition < 0) view.stop(false, true).animate({ left: "+=" + move }, { duration: 400 })
    }

    handleChange(evt) {
        // check it out: we get the evt.target.name (which will be either "email" or "password")
        // and use it to target the key on our `state` object with the same name, using bracket syntax
        this.setState({ [evt.target.name]: evt.target.value });
    }

    increaseQuantity(evt) { //console.log(evt);
        var qty = evt.target.value;
        var qty = parseInt(qty) + 1; //alert(evt.target.value);
        // check it out: we get the evt.target.name (which will be either "email" or "password")
        // and use it to target the key on our `state` object with the same name, using bracket syntax
        this.setState({ [evt.target.name]: qty });
    }

    checkNoResults = (resultsArray) => {
        // Check if all results arrays are empty
        return resultsArray.every(array => array.length === 0);
    };

    handleKeyPressOther() {
        //   alert('hi');
        var search_area = $('#search_area').val();
        if (search_area != '') {
            $('#searchResultDiv').css('display', 'flex');
            this.setState({ searchNotEmpty: true });
        }
        if (search_area != '') {
            fetch(myConstClass.BASE_URL + 'sitesearch/getEventsFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response });
                    // console.log('search posts1: ',response);
                })

            fetch(myConstClass.BASE_URL + 'sitesearch/getProductsFrontEnd' + '/' + getLanguage() + '/' + Base64.encode(search_area))
                .then(response => response.json())
                .then(response => {
                    this.setState({ products: response })
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                    // console.log('search products1: ',response);
                })

            fetch(myConstClass.BASE_URL + 'sitesearch/getResearchNewsList/' + getLanguage() + '/2' + '/' + Base64.encode(search_area))
                .then(response => response.json())
                .then(response => {
                    this.setState({ arrResearch: response });
                    // console.log('search research1: ',response);
                    var total = this.state.arrResearch.length;
                    if (total > 0) {
                        $('.MISCELLANEOUSDiv').css('display', 'inline');
                    }
                    // if(total < 18){
                    //   $('.research_show_more').css('display','none');
                    // }else{
                    //   $('.research_show_more').css('display','inline');
                    // }
                })

            fetch(myConstClass.BASE_URL + 'sitesearch/getResearchNewsList/' + getLanguage() + '/1' + '/' + Base64.encode(search_area))
                .then(response => response.json())
                .then(response => {
                    this.setState({ arrNews: response })
                    // console.log('search news1: ',response);
                    var total = this.state.arrNews.length;
                    if (total > 0) {
                        $('.MISCELLANEOUSDiv').css('display', 'inline');
                    }
                    // if(total < 18){
                    //   $('.news_show_more').css('display','none');
                    // }else{
                    //   $('.news_show_more').css('display','inline');
                    // }
                })

            fetch(myConstClass.BASE_URL + 'sitesearch/getOthersCMSList/' + getLanguage() + '/' + Base64.encode(search_area))
                .then(response => response.json())
                .then(response => {
                    this.setState({ arrOther: response });
                    // console.log('search other1: ',response);
                    var total = this.state.arrOther.length;
                    if (total > 0) {
                        $('.MISCELLANEOUSDiv').css('display', 'inline');
                    }
                    // if(total < 18){
                    //   $('.other_show_more').css('display','none');
                    // }else{
                    //   $('.other_show_more').css('display','inline');
                    // }
                })
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'sitesearch/getOthersAboutUsList/' + getLanguage() + '/' + Base64.encode(search_area))
                .then(response => response.json())
                .then(response => {
                    this.setState({ arrOtherAboutUs: response });
                    // console.log('search other about1: ',response);
                    var total = this.state.arrOtherAboutUs.length;
                    if (total > 0) {
                        $('.MISCELLANEOUSDiv').css('display', 'inline');
                    }
                })

            fetch(myConstClass.BASE_URL + 'sitesearch/getDownloadFilesFrontEnd/' + getLanguage() + '/100/' + Base64.encode(search_area))
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_downloads: response });
                    // console.log('search download1: ',response);
                    var total = this.state.arr_downloads.length;
                    if (this.state.others_available == 1) {
                    }
                    if (total > 0) {
                        $('.MISCELLANEOUSDiv').css('display', 'inline');
                        this.state.others_available = 1;
                    }

                })

            fetch(myConstClass.BASE_URL + 'sitesearch/getPhotoGalleryFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_gallery_photo: response });
                    // console.log('search gallery photo1: ',response);
                    var total = this.state.arr_gallery_photo.length;
                    if (this.state.others_available == 1) {
                    }
                    if (total > 0) {
                        $('.MISCELLANEOUSDiv').css('display', 'inline');
                        this.state.others_available = 1;
                    }

                })

            fetch(myConstClass.BASE_URL + 'sitesearch/getVideoGalleryFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_gallery_video: response });
                    // console.log('search gallery video1: ',response);
                    var total = this.state.arr_gallery_video.length;
                    if (this.state.others_available == 1) {
                    }
                    if (total > 0) {
                        $('.MISCELLANEOUSDiv').css('display', 'inline');
                        this.state.others_available = 1;
                    }
                })

            fetch(myConstClass.BASE_URL + 'sitesearch/getAudioGalleryFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_gallery_audio: response });
                    // console.log('search gallery audio1: ', response);
                    var total = this.state.arr_gallery_audio.length;
                    if (this.state.others_available == 1) {
                    }
                    if (total > 0) {
                        $('.MISCELLANEOUSDiv').css('display', 'inline');
                        this.state.others_available = 1;
                    }
                })

            fetch(myConstClass.BASE_URL + 'sitesearch/getWallpaperGalleryFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_gallery_wallpaper: response });
                    // console.log('search gallery wallpaper1: ',response);
                    var total = this.state.arr_gallery_wallpaper.length;
                    if (this.state.others_available == 1) {
                    }
                    if (total > 0) {
                        $('.MISCELLANEOUSDiv').css('display', 'inline');
                        this.state.others_available = 1;
                    }
                })
            this.state.searchResultFor = search_area;
            // this.setState({
            //     searchResults: [
            //         this.state.arrResearch,
            //         this.state.arrNews,
            //         this.state.arrOther,
            //         this.state.arrOtherAboutUs,
            //         this.state.arr_downloads,
            //         this.state.arr_gallery_photo,
            //         this.state.arr_gallery_video,
            //         this.state.arr_gallery_audio,
            //         this.state.arr_gallery_wallpaper
            //     ],
            //     noResultFound: this.checkNoResults([
            //         this.state.arrResearch,
            //         this.state.arrNews,
            //         this.state.arrOther,
            //         this.state.arrOtherAboutUs,
            //         this.state.arr_downloads,
            //         this.state.arr_gallery_photo,
            //         this.state.arr_gallery_video,
            //         this.state.arr_gallery_audio,
            //         this.state.arr_gallery_wallpaper
            //     ])
            // })
        }
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            var search_area = $('#search_area').val() || $('#searchSite').val();
            if (search_area != '') {
                $('#searchResultDiv').css('display', 'flex');
                this.setState({ searchNotEmpty: true });
            }
            if (search_area != '') {
                fetch(myConstClass.BASE_URL + 'sitesearch/getEventsFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ posts: response })
                        console.log('search posts2: ',response);
                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getProductsFrontEnd' + '/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ products: response })
                        console.log('search products2: ',response);
                        //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getResearchNewsList/' + getLanguage() + '/2' + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arrResearch: response });
                        console.log('search research2: ',response);
                        var total = this.state.arrResearch.length;
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                        }
                        // if(total < 18){
                        //   $('.research_show_more').css('display','none');
                        // }else{
                        //   $('.research_show_more').css('display','inline');
                        // }
                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getResearchNewsList/' + getLanguage() + '/1' + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arrNews: response });
                        console.log('search news2: ',response);
                        var total = this.state.arrNews.length;
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                        }
                        // if(total < 18){
                        //   $('.news_show_more').css('display','none');
                        // }else{
                        //   $('.news_show_more').css('display','inline');
                        // }
                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getOthersCMSList/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arrOther: response });
                        console.log('search other2: ',response);
                        var total = this.state.arrOther.length;
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                        }
                        // if(total < 18){
                        //   $('.other_show_more').css('display','none');
                        // }else{
                        //   $('.other_show_more').css('display','inline');
                        // }
                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getOthersAboutUsList/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arrOtherAboutUs: response });
                        console.log('search other about2: ',response);
                        var total = this.state.arrOtherAboutUs.length;
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                        }
                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getDownloadFilesFrontEnd/' + getLanguage() + '/100/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arr_downloads: response });
                        console.log('search download2: ',response);
                        var total = this.state.arr_downloads.length;
                        if (this.state.others_available == 1) {
                        }
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }
                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getPhotoGalleryFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arr_gallery_photo: response });
                        console.log('search gallery photo2: ',response);
                        var total = this.state.arr_gallery_photo.length;
                        if (this.state.others_available == 1) {
                        }
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }
                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getVideoGalleryFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arr_gallery_video: response });
                        console.log('search gallery video2: ',response);
                        var total = this.state.arr_gallery_video.length;
                        if (this.state.others_available == 1) {
                        }
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }
                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getAudioGalleryFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arr_gallery_audio: response });
                        console.log('search gallery audio2: ', response);
                        var total = this.state.arr_gallery_audio.length;
                        if (this.state.others_available == 1) {
                        }
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }
                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getWallpaperGalleryFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arr_gallery_wallpaper: response });
                        console.log('search gallery wallpaper2: ',response);
                        var total = this.state.arr_gallery_wallpaper.length;
                        if (this.state.others_available == 1) {
                        }
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }
                    })
                    .catch(err => console.log(err))
                this.state.searchResultFor = search_area;
            }
        }
    }

    handleSearchReset() {
        $('#search_area').val('');
        $('#search_area').focus();
    }

    componentDidMount() {
        $('#searchSite').focus();

        if (getLanguage() == 'ma') {
            this.state.ResidentialStudyCourses = ResidentialStudyCourses_mr;
            this.state.OneDayWorkshop = OneDayWorkshop_mr;
            this.state.RationalPoojas = RationalPoojas_mr;
            this.state.FreeEvents = FreeEvents_mr;
            this.state.MachineTests = MachineTests_mr;

            this.state.AyurvedicProducts = AyurvedicProducts_mr;
            this.state.BalDiwali = BalDiwali_mr;
            this.state.Books = Books_mr;
            this.state.CDs = CDs_mr;
            this.state.DIWALIMAGAZINE = DIWALIMAGAZINE_mr;
            this.state.ExperimentalProducts = ExperimentalProducts_mr;
            this.state.KITs = KITs_mr;
        } else {
            this.state.ResidentialStudyCourses = ResidentialStudyCourses;
            this.state.OneDayWorkshop = OneDayWorkshop;
            this.state.RationalPoojas = RationalPoojas;
            this.state.FreeEvents = FreeEvents;
            this.state.MachineTests = MachineTests;

            this.state.AyurvedicProducts = AyurvedicProducts;
            this.state.BalDiwali = BalDiwali;
            this.state.Books = Books;
            this.state.CDs = CDs;
            this.state.DIWALIMAGAZINE = DIWALIMAGAZINE;
            this.state.ExperimentalProducts = ExperimentalProducts;
            this.state.KITs = KITs;
        }

        $('#searchResultDiv').css('display', 'none');
        $('#search_area').focus();
        window.scrollTo(0, 0);
        var key = localStorage.getItem('key');
        if (key == 'en') {
            this.key = 'ma';
            $('#logoEnglish').css('display', 'none');
            $('#logoMarathi').css('display', 'inline');
            $('#logoEnglish_m').css('display', 'none');
            $('#logoMarathi_m').css('display', 'inline');
        } else {
            $('#logoEnglish').css('display', 'inline');
            $('#logoMarathi').css('display', 'none');
            $('#logoEnglish_m').css('display', 'inline');
            $('#logoMarathi_m').css('display', 'none');
            this.key = 'en';
        }
        setLanguage(this.key);
        setLanguageCookie('lang_key');
        var user_id = localStorage.getItem('user_id');
        var profile_picture = localStorage.getItem('profile_picture');
        if (profile_picture != '' && profile_picture != undefined) {
            this.state.userinfo.profile_picture = profile_picture;
        }
        if (user_id > 0 && user_id != null) {
            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cartcount: response })
                    var totalcartcount = this.state.cartcount.totalcartcount;
                    $('#lblCartCount').html(totalcartcount);
                    if (totalcartcount > 0) {
                        $('#ContinueToCheckoutDiv').css('display', 'none');
                        // $('#itemsInTheCart').css('display','block');
                        // $('#productItemThead').css('display','block');
                        // $('#productItemShippingThead').css('display','block');
                    } else {
                        $('#instructionsDiv').css('display', 'none');
                        $('#ContinueToCheckoutDiv').css('display', 'block');
                        $('#ContinueToCheckoutMainDiv').css('display', 'none');

                        $('#itemsInTheCart').css('display', 'none');
                        $('#productItemThead').css('display', 'none');
                        $('#productItemShippingThead').css('display', 'none');
                    }
                })

            $('#signUpButton').css('display', 'none');
            $('#signInButton').css('display', 'none');
            $('#logoutButton').css('display', 'block');
            $('#myCartButton').css('display', 'block');

            var first_name = localStorage.getItem('first_name');
            var middle_name = localStorage.getItem('middle_name');
            var last_name = localStorage.getItem('last_name');
            var is_verify_sadhak_no = localStorage.getItem('is_verify_sadhak_no');
            if (is_verify_sadhak_no == 1) {
                $('.donationContribution').css('display', 'block');
            } else {
                $('.donationContribution').css('display', 'none');
            }
            // this.state.userinfo.first_name = first_name;
            // this.state.userinfo.middle_name = middle_name;
            // this.state.userinfo.last_name = last_name;
            $('#user_first_name').html(first_name);
            $('#user_last_name').html(last_name);
            console.log(last_name);

        } else {
            $('#lblCartCount').html('0');
            $('#logoutButton').css('display', 'none');
            $('#myCartButton').css('display', 'none');
        }


        var searchResultFor = this.state.searchResultFor;
        if (searchResultFor == '' || searchResultFor == undefined) {
            // $('#searchResultDiv').css('display','none');
        }
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }
        var user_id = localStorage.getItem('user_id');


        //  fetch(myConstClass.BASE_URL+'event/getTopicSubtopicDetailsFrontEnd'+'/'+getLanguage())
        //  .then(response => response.json())
        //  .then(response =>{this.setState({eventdetails: response})

        //  })

        // fetch(myConstClass.BASE_URL+'event/getAllSubtopicsFrontEnd'+'/'+getLanguage()+'/'+topic_id)
        // .then(response => response.json())
        // .then(response =>this.setState({topic: response}))

        fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ arrMagazine: response }))
            .catch(err => console.log(err))

            .catch(err => console.log(err))

    }

    /*
    handleSearchResult(arrays) {
        const {arrResearch, arrNews, arrOther, arrOtherAboutUs, arr_downloads, arr_gallery_photo, arr_gallery_video, arr_gallery_audio, arr_gallery_wallpaper } = arrays;

        if (arrResearch.length === 0 && arrNews.length === 0 && arrOther.length === 0 && arrOtherAboutUs.length === 0 && arr_downloads.length === 0 && arr_gallery_photo.length === 0 && arr_gallery_video.length === 0 && arr_gallery_audio.length === 0 && arr_gallery_wallpaper.length === 0) {
            // console.log('no result found');
            // this.state.noResultFound = true;
            this.setState({ noResultFound: true });
        } else {
            // console.log('search result found');
            // this.state.noResultFound = false;
            this.setState({ noResultFound: false });
        }
        // console.log('noresultfound: ', this.state.noResultFound, 'searchinput: ', this.state.searchNotEmpty);
    }
    */
    handleSearchResult(arrays) {
        const {
          arrResearch,
          arrNews,
          arrOther,
          arrOtherAboutUs,
          arr_downloads,
          arr_gallery_photo,
          arr_gallery_video,
          arr_gallery_audio,
          arr_gallery_wallpaper,
        } = arrays;
      
        const noResultFound =
          arrResearch.length === 0 &&
          arrNews.length === 0 &&
          arrOther.length === 0 &&
          arrOtherAboutUs.length === 0 &&
          arr_downloads.length === 0 &&
          arr_gallery_photo.length === 0 &&
          arr_gallery_video.length === 0 &&
          arr_gallery_audio.length === 0 &&
          arr_gallery_wallpaper.length === 0;
      
        // Check if the new state differs from the current state
        if (noResultFound !== this.state.noResultFound) {
          // Update state only if necessary
          this.setState({ noResultFound });
        }
    }      

    componentDidUpdate(prevProps, preState) {
        const arrays = this.state;
        this.handleSearchResult(arrays);

        const answer_array = this.props.location.pathname.split('/');
        //alert(answer_array['2']);
        const { flag } = this.state
        var current_topic_id = answer_array['3'];
        //alert(answer_array['3']);
        var topic_id = localStorage.getItem('topic_id');
        localStorage.setItem('topic_id', current_topic_id);

        var sub_topic_id = answer_array['4'];
        if (sub_topic_id == '' || sub_topic_id == undefined) {
            sub_topic_id = 0;
        }

        if (sub_topic_id > 0) {
            this.state.guestinfo.sub_topic_id = sub_topic_id;
            $('#subTopicName').css('display', 'inline');
        } else {
            $('#subTopicName').css('display', 'none');
        }

        var old_sub_topic_id = localStorage.getItem('old_sub_topic_id');
        localStorage.setItem('old_sub_topic_id', sub_topic_id);



        var current_topic_id = answer_array['3'];

        var old_topic_id = localStorage.getItem('old_topic_id');

        if (old_topic_id == undefined) {
            old_topic_id = answer_array['3'];
        }
        //alert('current_topic_id'+current_topic_id+'old_topic_id'+old_topic_id);
        localStorage.setItem('old_topic_id', current_topic_id);

        //alert('old_sub_topic_id'+old_sub_topic_id+'sub_topic_id'+sub_topic_id);

        if (old_topic_id != '' && flag != 1 && (current_topic_id != old_topic_id || (old_sub_topic_id != sub_topic_id && sub_topic_id > 0 && sub_topic_id != undefined)) && old_topic_id != null && old_topic_id > 0) {
            this.setState({ flag: 1 });
            //alert('hi');
        }



        var topic_id = answer_array['3'];
        if (topic_id > 0) {
            this.state.guestinfo.topic_id = topic_id;
        } else {
            var topic_id = 0;
        }

        //const { flag} = this.state  
        if (getLanguage() == 'ma' && flag == 0) {
            if (getLanguage() == 'ma') {
                this.state.ResidentialStudyCourses = ResidentialStudyCourses_mr;
                this.state.OneDayWorkshop = OneDayWorkshop_mr;
                this.state.RationalPoojas = RationalPoojas_mr;
                this.state.FreeEvents = FreeEvents_mr;
                this.state.MachineTests = MachineTests_mr;

                this.state.AyurvedicProducts = AyurvedicProducts_mr;
                this.state.BalDiwali = BalDiwali_mr;
                this.state.Books = Books_mr;
                this.state.CDs = CDs_mr;
                this.state.DIWALIMAGAZINE = DIWALIMAGAZINE_mr;
                this.state.ExperimentalProducts = ExperimentalProducts_mr;
                this.state.KITs = KITs_mr;
            } else {
                this.state.ResidentialStudyCourses = ResidentialStudyCourses;
                this.state.OneDayWorkshop = OneDayWorkshop;
                this.state.RationalPoojas = RationalPoojas;
                this.state.FreeEvents = FreeEvents;
                this.state.MachineTests = MachineTests;

                this.state.AyurvedicProducts = AyurvedicProducts;
                this.state.BalDiwali = BalDiwali;
                this.state.Books = Books;
                this.state.CDs = CDs;
                this.state.DIWALIMAGAZINE = DIWALIMAGAZINE;
                this.state.ExperimentalProducts = ExperimentalProducts;
                this.state.KITs = KITs;
            }
            this.setState({ flag: 1 })
            var search_area = $('#search_area').val();
            // alert(search_area);
            if (search_area != '' && search_area != undefined) {
                fetch(myConstClass.BASE_URL + 'sitesearch/getEventsFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ posts: response })
                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getProductsFrontEnd' + '/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ products: response })
                        //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

                    })


                fetch(myConstClass.BASE_URL + 'sitesearch/getResearchNewsList/' + getLanguage() + '/2' + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arrResearch: response })
                        var total = this.state.arrResearch.length;
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                        }
                        // if(total < 18){
                        //   $('.research_show_more').css('display','none');
                        // }else{
                        //   $('.research_show_more').css('display','inline');
                        // }
                    })


                fetch(myConstClass.BASE_URL + 'sitesearch/getResearchNewsList/' + getLanguage() + '/1' + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arrNews: response })
                        var total = this.state.arrNews.length;
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                        }
                        // if(total < 18){
                        //   $('.news_show_more').css('display','none');
                        // }else{
                        //   $('.news_show_more').css('display','inline');
                        // }
                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getOthersCMSList/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arrOther: response })
                        var total = this.state.arrOther.length;
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                        }
                        // if(total < 18){
                        //   $('.other_show_more').css('display','none');
                        // }else{
                        //   $('.other_show_more').css('display','inline');
                        // }
                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getOthersAboutUsList/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arrOtherAboutUs: response })
                        var total = this.state.arrOtherAboutUs.length;
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                        }

                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getDownloadFilesFrontEnd/' + getLanguage() + '/100/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arr_downloads: response })
                        var total = this.state.arr_downloads.length;
                        if (this.state.others_available == 1) {

                        }
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }

                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getPhotoGalleryFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arr_gallery_photo: response })
                        var total = this.state.arr_gallery_photo.length;
                        if (this.state.others_available == 1) {

                        }
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }

                    })
                fetch(myConstClass.BASE_URL + 'sitesearch/getVideoGalleryFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arr_gallery_video: response })
                        var total = this.state.arr_gallery_video.length;
                        if (this.state.others_available == 1) {

                        }
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }

                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getAudioGalleryFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arr_gallery_audio: response })
                        var total = this.state.arr_gallery_audio.length;
                        if (this.state.others_available == 1) {

                        }
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }

                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getWallpaperGalleryFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arr_gallery_wallpaper: response })
                        var total = this.state.arr_gallery_wallpaper.length;
                        if (this.state.others_available == 1) {

                        }
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }

                    })


                    .catch(err => console.log(err))



                this.state.searchResultFor = search_area;

                //  fetch(myConstClass.BASE_URL+'event/getAllSubtopicsFrontEnd'+'/'+getLanguage()+'/'+topic_id)
                //  .then(response => response.json())
                //  .then(response =>this.setState({topic: response}))
                //  .catch(err => console.log(err))

                fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => this.setState({ arrMagazine: response }))
                    .catch(err => console.log(err))
            }


        }

        if (getLanguage() == 'en' && (flag == 1)) {
            if (getLanguage() == 'ma') {
                this.state.ResidentialStudyCourses = ResidentialStudyCourses_mr;
                this.state.OneDayWorkshop = OneDayWorkshop_mr;
                this.state.RationalPoojas = RationalPoojas_mr;
                this.state.FreeEvents = FreeEvents_mr;
                this.state.MachineTests = MachineTests_mr;

                this.state.AyurvedicProducts = AyurvedicProducts_mr;
                this.state.BalDiwali = BalDiwali_mr;
                this.state.Books = Books_mr;
                this.state.CDs = CDs_mr;
                this.state.DIWALIMAGAZINE = DIWALIMAGAZINE_mr;
                this.state.ExperimentalProducts = ExperimentalProducts_mr;
                this.state.KITs = KITs_mr;
            } else {
                this.state.ResidentialStudyCourses = ResidentialStudyCourses;
                this.state.OneDayWorkshop = OneDayWorkshop;
                this.state.RationalPoojas = RationalPoojas;
                this.state.FreeEvents = FreeEvents;
                this.state.MachineTests = MachineTests;

                this.state.AyurvedicProducts = AyurvedicProducts;
                this.state.BalDiwali = BalDiwali;
                this.state.Books = Books;
                this.state.CDs = CDs;
                this.state.DIWALIMAGAZINE = DIWALIMAGAZINE;
                this.state.ExperimentalProducts = ExperimentalProducts;
                this.state.KITs = KITs;
            }
            this.setState({ flag: 0 })


            var search_area = $('#search_area').val();
            // alert(search_area);
            if (search_area != '' && search_area != undefined) {
                fetch(myConstClass.BASE_URL + 'sitesearch/getEventsFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ posts: response })
                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getProductsFrontEnd' + '/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ products: response })
                        //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

                    })


                fetch(myConstClass.BASE_URL + 'sitesearch/getResearchNewsList/' + getLanguage() + '/2' + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arrResearch: response })
                        var total = this.state.arrResearch.length;
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                        }
                        // if(total < 18){
                        //   $('.research_show_more').css('display','none');
                        // }else{
                        //   $('.research_show_more').css('display','inline');
                        // }
                    })


                fetch(myConstClass.BASE_URL + 'sitesearch/getResearchNewsList/' + getLanguage() + '/1' + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arrNews: response })
                        var total = this.state.arrNews.length;
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                        }
                        // if(total < 18){
                        //   $('.news_show_more').css('display','none');
                        // }else{
                        //   $('.news_show_more').css('display','inline');
                        // }
                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getOthersCMSList/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arrOther: response })
                        var total = this.state.arrOther.length;
                        if (total > 0) {
                            alert(total + 'arrOther');
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }

                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getOthersAboutUsList/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arrOtherAboutUs: response })
                        var total = this.state.arrOtherAboutUs.length;
                        if (this.state.others_available == 1) {

                        }
                        if (total > 0) {

                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }
                    })


                fetch(myConstClass.BASE_URL + 'sitesearch/getDownloadFilesFrontEnd/' + getLanguage() + '/100/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arr_downloads: response })
                        var total = this.state.arr_downloads.length;
                        if (this.state.others_available == 1) {

                        }
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }

                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getPhotoGalleryFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arr_gallery_photo: response })
                        var total = this.state.arr_gallery_photo.length;
                        if (this.state.others_available == 1) {

                        }
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }

                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getVideoGalleryFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arr_gallery_video: response })
                        var total = this.state.arr_gallery_video.length;
                        if (this.state.others_available == 1) {

                        }
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }

                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getAudioGalleryFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arr_gallery_audio: response })
                        var total = this.state.arr_gallery_audio.length;
                        if (this.state.others_available == 1) {

                        }
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }

                    })

                fetch(myConstClass.BASE_URL + 'sitesearch/getWallpaperGalleryFrontEnd/' + getLanguage() + '/' + Base64.encode(search_area))
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arr_gallery_wallpaper: response })
                        var total = this.state.arr_gallery_wallpaper.length;
                        if (this.state.others_available == 1) {

                        }
                        if (total > 0) {
                            $('.MISCELLANEOUSDiv').css('display', 'inline');
                            this.state.others_available = 1;
                        }

                    })


                    .catch(err => console.log(err))



                this.state.searchResultFor = search_area;

                fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => this.setState({ arrMagazine: response }))
                    .catch(err => console.log(err))


            }
        }
        // this.state.guestinfo.event_type_name =  answer_array['2'];
    }

    applyFilters() {
        const answer_array = this.props.location.pathname.split('/');
        var topic_id = $('#topic_id').val();
        if (topic_id == '') {
            topic_id = 0;
        }
        var language_id = $('#language_id').val();
        if (language_id == '') {
            language_id = 0;
        }
        var event_type = answer_array['3'];
        if (event_type == 1) {
            var path = 'Residential Study Courses/1' + '/' + topic_id + '/' + language_id;
        } else if (event_type == 2) {
            var path = "1-Day Workshops/2" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 4) {
            var path = "Rational Poojs/4" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 3) {
            var path = "Machine Tests/3" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 5) {
            var path = "Free Events/5" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 6) {
            var path = "Organize a Trip/6" + '/' + topic_id + '/' + language_id;
        } else {
            var path = "Events/0" + '/' + topic_id + '/' + language_id;
        }
        if (event_type == 3) {
            this.props.history.push('/machine-test/' + path);
        } else {
            this.props.history.push('/events/' + path);
        }

    }

    renderType = ({ id, title }) => <div className="row">
        <div className="col">
            <div className="col pt-3">
                <h5 className="font-weight-bold">{title}</h5>
            </div>
        </div>
    </div>

    renderGuest = ({ type_id, title, price, quantity }) =>
        <div>
        </div>

    render() {

        const { userinfo, posts, products, arrResearch, arrNews, arrOther, arrOtherAboutUs, arr_gallery_photo, arr_gallery_audio, arr_gallery_video, arr_gallery_wallpaper, arr_downloads, arrMagazine } = this.state;
        
        const { t } = this.props;

        return (

            <div className="App">
                <div>
                    <ToastContainer />
                </div>
                {/* <Header /> */}

                <div className="fixed-top">
                    <div className="container-fluid top-menu pt-1 pb-1">
                        <ul className="nav justify-content-end">
                            <li style={{ marginRight: '65.9%' }} id="liveLi">
                                {/* <a target="_blank" href={settinginfo.url}> */}
                                {/* <img src={live} id="liveEnglish"/> */}
                                {/* </a> */}
                            </li>
                            <li style={{ marginRight: '10px' }}>

                                <a target="_blank" href="https://www.facebook.com/manashaktiresearchcentrelonavla"><i className="fab fa-facebook-f text-white m-2"></i></a>
                                <a target="_blank" href="https://twitter.com/manashakti?lang=en"><i className="fab fa-twitter text-white m-2"></i></a>
                                <a target="_blank" href="https://in.linkedin.com/in/manashakti-research-centre-a4230290">
                                    <i className="fab fa-linkedin-in text-white m-2"></i>
                                </a>
                                <a target="_blank" href="https://www.youtube.com/channel/UC2LM6zmd2jA1Mw-E18P0coA">
                                    <i className="fab fa-youtube text-white m-2"></i>
                                </a>
                            </li>

                            <li style={{ cursor: 'pointer' }} className="nav-item mr-2 nav-link btn btn--outline-light btn-sm py-05 pl-2 pr-2" onClick={this.handleSetLanguageNew(this.key)}>
                                {t('home.language')}
                            </li>
                            <li className="nav-item">
                                {/* <a className=" btn text-light py-05 pl-2 pr-2" href="#"><small>{t('home.contact_us')}</small></a> */}
                                <Link className="btn text-light py-05 pl-2 pr-2" to="/contact-us"><small style={{ fontSize: '100%' }}>{t('home.contact_us')}</small></Link>
                            </li>
                        </ul>
                    </div>
                    <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom main-nav d-xl-flex d-lg-flex  d-none">
                        <Link to="/" className="navbar-brand" style={{ zIndex: 99999999 }}>
                            <img src={logo} height={77} />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">

                                {/* <li className="nav-item dropdown" style={{ position: "relative", display: "flex", alignItems: "center" }} >
                                    <input onKeyPress={this.handleKeyPress} style={{ minWidth: '610px', marginLeft: '20px' }} type="text" className="form-control" name="search_area" id="search_area" placeholder={t('home.Search')} />
                                    <i onClick={this.handleSearchReset} className="fa fa-times" style={{ position: "absolute", left: "95%", cursor: "pointer" }}>
                                    </i>
                                </li>
                                <li className="nav-item" style={{ margin: '9px 13px 0px 18px' }} onClick={this.handleKeyPressOther}>
                                    <i className="fa fa-search" style={{ marginLeft: '20px', cursor: 'pointer' }}></i>
                                </li> */}

                                <li className="nav-item dropdown" style={{ position: "relative", display: "flex", alignItems: "center" }} >
                                    <input 
                                      onKeyPress={this.handleKeyPress} style={{ minWidth: '610px', marginLeft: '20px' }} type="text" className="form-control" name="search_area" maxLength={40} id="search_area" placeholder={t('home.Search')}
                                    />
                                    <i onClick={this.handleSearchReset} className="fa fa-times" style={{ position: "absolute", left: "90%", cursor: "pointer" }}>
                                    </i>
                                    <i className="fa fa-search" onClick={this.handleKeyPressOther} style={{ marginLeft: '20px', cursor: 'pointer', position: "absolute", left: "90%", borderLeft: "1px solid", padding: "6px" }}>
                                    </i>
                                </li>

                                {/* <li className="nav-item" style={{ margin: '9px 13px 0px 0px' }}>
                                    <a onClick={this.props.history.goBack} style={{ backgroundColor: 'none !important', cursor: 'pointer' }}>
                                        <i className="fa fa-times" style={{ marginLeft: '5px', cursor: 'pointer', minWidth: '15px' }}></i>
                                    </a>
                                </li> */}

                                <li id="myCartButton" className="nav-item" style={{ margin: '9px 13px 0px 18px', color: 'red !important', cursor: 'pointer' }}>
                                    <span id="lblCartCount" style={{ fontSize: '12px', background: '#ff0000', color: '#fff', padding: '0 6px', verticleAlign: 'top', position: 'absolute' }}>0</span>
                                    <Link style={{ color: 'red' }} to="/mycart">
                                        <i className="fas fa-shopping-cart"></i>
                                    </Link>
                                </li>


                                <li className="nav-item" id="signUpButton" style={{ margin: '9px 13px 0px 18px', color: 'red !important', cursor: 'pointer' }}>

                                    <Link style={{ color: 'red' }} to="/signin">{t('home.Login')}</Link> | <Link style={{ color: 'red' }} to="/signup">{t('home.Register')}</Link>
                                </li>

                                <div id="logoutButton">
                                    <li className="nav-item" id="logoutButton" style={{ marginTop: '9px' }}>
                                        |&nbsp; <span id="user_first_name"></span>&nbsp;<span id="user_last_name"></span>
                                    </li>


                                    <li className="nav-item dropdown" style={{ position: 'absolute', marginLeft: '13%', marginTop: '-33px' }}>

                                        <a className="nav-link dropdown-toggle orangeColor" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                            <img className="" style={{ width: '35px', height: '34px', borderRadius: '50%' }} src={myConstClass.IMAGE_URL + 'user/' + userinfo.profile_picture} />
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ marginLeft: '-119px' }}>
                                            <Link className="dropdown-item profileMenuDiv" to="/order-history" >{t('home.Order History')}</Link>
                                            <Link className="dropdown-item profileMenuDiv" to="/participants">{t('home.Master List of Participants')}</Link>
                                            {/* <a className="dropdown-item profileMenuDiv"  to="javascript:void(0)">{t('home.My Interactions')}</a> */}
                                            {/* <a className="dropdown-item"  href="javascript:void(0)">{t('home.')}</a> */}
                                            <Link className="dropdown-item profileMenuDiv" to="/my-interactions">{t('home.My Interactions')}</Link>
                                            <Link className="dropdown-item profileMenuDiv" to="/change-password">{t('home.Change Password')}</Link>
                                            <Link className="dropdown-item profileMenuDiv" to="/profile">{t('home.Profile')}</Link>
                                            {/* <Link  to="/order-history"className="dropdown-item"  >{t('home.Order History')}</Link> */}
                                            <Link to="/donation-contribution" className="dropdown-item donationContribution"  >{t('home.donation_contribution')}</Link>
                                            <Link to="/logout" className="dropdown-item profileMenuDiv"  >{t('home.Sign Out')}</Link>

                                        </div>
                                    </li>

                                </div>

                            </ul>
                        </div>
                    </nav>

                    <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom main-nav d-xl-none d-lg-none d-flex">
                        <button className="navbar-toggler" type="button" onClick={() => this.openNav()}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <Link className="navbar-brand mx-auto" to="/">
                            <img src={logo} id="logoEnglish_m" style={{ width: 150 }} />
                            <img src={logomr} id="logoMarathi_m" style={{ width: 150 }} />
                        </Link>
                        <div className="d-xl-none d-lg-none d-inline position-absolute absolute-l--10-px">
                            <input
                                onKeyPress={this.handleKeyPress}
                                type="search"
                                name="search"
                                maxLength={40}
                                className="search-site"
                                id="searchSite"
                                placeholder={t('home.Search')}
                                ref={input => input && input.focus()}
                            />
                        </div>
                        <div id="mySidenav" className="sidenav">

                            <a href="javascript:void(0)" class="closebtn" onClick={() => this.closeNav()}>&times;</a>

                            <a>
                                Welcome
                                &nbsp;&nbsp;
                                <span>
                                    {localStorage.getItem('first_name')}&nbsp;&nbsp;
                                    {localStorage.getItem('last_name')}
                                </span>
                                &nbsp;&nbsp;
                                <img className="rounded-circle" height={30} width={30} src={myConstClass.IMAGE_URL + 'user/' + userinfo.       profile_picture} alt="Profile Image" />
                            </a>
                            <div style={{ borderWidth: .1, borderColor: 'white', borderStyle: 'solid' }}>
                            </div>

                            {
                                this.state.subMenuLevel1 ?
                                    <div>
                                        {
                                            this.state.subMenuLevel2 ?
                                                <>
                                                    <a className="d-inline" onClick={() => this.setState({ subMenuLevel1: false, subMenuLevel2: false, subMenuLevel1List: [], subMenuLevel2List: [], subMenuLevel1Heading: '', subMenuLevel2Heading: '' })}>
                                                        <i className="fa fa-angle-left ml-n4"></i>&nbsp;&nbsp;{t('home.HOME')}
                                                    </a>
                                                    <a className="d-inline" onClick={() => this.setState({ subMenuLevel1: true, subMenuLevel2: false, subMenuLevel2List: [], subMenuLevel2Heading: '' })}>
                                                        <i className="fa fa-angle-left ml-n4"></i>&nbsp;&nbsp;{this.state.subMenuLevel1Heading}
                                                    </a>
                                                    <a className="d-inline ml-n4" onClick={() => this.setState({ subMenuLevel1: true, subMenuLevel2: false, subMenuLevel2List: [], subMenuLevel2Heading: '' })}>
                                                        /&nbsp;&nbsp;{this.state.subMenuLevel2Heading}
                                                    </a>
                                                    {
                                                        this.state.subMenuLevel2List
                                                    }
                                                </>
                                                :
                                                <>
                                                    <a className="d-inline" onClick={() => this.setState({ subMenuLevel1: false, subMenuLevel2: false, subMenuLevel1List: [], subMenuLevel2List: [], subMenuLevel1Heading: '', subMenuLevel2Heading: '' })}>
                                                        <i className="fa fa-angle-left ml-n4"></i>&nbsp;&nbsp;{t('home.HOME')}
                                                    </a>
                                                    <a className="d-inline ml-n4">
                                                        /&nbsp;&nbsp;{this.state.subMenuLevel1Heading}
                                                    </a>
                                                    {
                                                        this.state.subMenuLevel1List
                                                    }
                                                </>
                                        }

                                    </div>
                                    :
                                    <div>
                                        <a onClick={() => this.showSubMenuLevel1('aboutUs')}>
                                            {t('home.ABOUT US')} <i className="fa fa-angle-right float-right mx-3"></i>
                                        </a>
                                        <a onClick={() => this.showSubMenuLevel1('topics')}>
                                            {t('home.TOPICS')}  <i className="fa fa-angle-right float-right mx-3"></i>
                                        </a>
                                        <a onClick={() => this.showSubMenuLevel1('events')}>
                                            {t('home.EVENTS')} <i className="fa fa-angle-right float-right mx-3"></i>
                                        </a>
                                        <a onClick={() => this.showSubMenuLevel1('products')}>
                                            {t('home.PRODUCTS')}  <i className="fa fa-angle-right float-right mx-3"></i>
                                        </a>
                                        <Link to="/research" >
                                            {t('home.RESEARCH')}
                                        </Link>
                                        <Link to="/event-calendar" >
                                            {t('home.EVENT CALENDAR')}
                                        </Link>
                                        <Link to="/booknow/Residential Study Courses/1">
                                            {t('home.BOOK NOW')}
                                        </Link>
                                        <a onClick={() => this.showSubMenuLevel1('more')}>
                                            {t('home.MORE')}  <i className="fa fa-angle-right float-right mx-3"></i>
                                        </a>
                                        <div style={{ borderWidth: .1, borderColor: 'white', borderStyle: 'solid' }}>
                                        </div>
                                        {
                                            localStorage.getItem('user_id') ?
                                                (
                                                    <a onClick={() => this.showSubMenuLevel1('myAccount')}>
                                                        {t('home.My Account')} <i className="fa fa-angle-right float-right mx-3"></i>
                                                    </a>
                                                ) :
                                                (
                                                    null
                                                )
                                        }
                                        <div style={{ borderWidth: .1, borderColor: 'white', borderStyle: 'solid' }}>
                                        </div>
                                        {
                                            localStorage.getItem('user_id') ? (
                                                null
                                            ) : (
                                                    <Link to="/signin">{t('home.Login')}</Link>
                                                )
                                        }
                                        {
                                            localStorage.getItem('user_id') ? (
                                                null
                                            ) : (
                                                    <Link to="/signup">{t('home.Register')}</Link>
                                                )
                                        }

                                    </div>
                            }
                        </div>

                    </nav>

                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-9 col-lg-9 col-12">
                            <div className="row my-1">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-white mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <a href="javascript:void(0)">{t('home.Search Results')}
                                            </a>
                                        </li>

                                    </ol>
                                </nav>

                            </div>
                            <div className="row my-1" id="searchResultDiv">
                                <div className="col">
                                    <h4>
                                        {t('home.Search Results for')} "{this.state.searchResultFor}" &nbsp;{t('home.Search Results for mr')}
                                    </h4>
                                </div>
                            </div>

                            { this.state.noResultFound && this.state.searchNotEmpty && <h1>No Result Found</h1> }

                            <div className="searchMainDiv">
                                {posts.map((eventType, ii) => {
                                    if (eventType.arr_events.length > 0) {
                                        return (
                                            <div className="row">
                                                <div className="col">
                                                    <div className="col p-3 text-white mt-3 relatedProductsImage d-xl-flex d-lg-flex d-md-flex d-none" style={{ backgroundImage: "url(" + myConstClass.IMAGE_URL + eventType.background_image + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} >
                                                    </div>
                                                    {
                                                        eventType.event_type_id === "1" ?
                                                            (<img src={this.state.ResidentialStudyCourses} height={77} className="d-xl-none d-lg-none d-md-none d-block" />)
                                                            :
                                                            (null)
                                                    }
                                                    {
                                                        eventType.event_type_id === "2" ?
                                                            (<img src={this.state.OneDayWorkshop} height={77} className="d-xl-none d-lg-none d-md-none d-block" />)
                                                            :
                                                            (null)
                                                    }
                                                    {
                                                        eventType.event_type_id === "3" ?
                                                            (<img src={this.state.MachineTests} height={77} className="d-xl-none d-lg-none d-md-none d-block" />)
                                                            :
                                                            (null)
                                                    }
                                                    {
                                                        eventType.event_type_id === "4" ?
                                                            (<img src={this.state.RationalPoojas} height={77} className="d-xl-none d-lg-none d-md-none d-block" />)
                                                            :
                                                            (null)
                                                    }
                                                    {
                                                        eventType.event_type_id === "5" ?
                                                            (<img src={this.state.FreeEvents} height={77} className="d-xl-none d-lg-none d-md-none d-block" />)
                                                            :
                                                            (null)
                                                    }

                                                    {eventType.arr_events.map((info, ii) => {
                                                        if (info.event_type_id == 2) {
                                                            if (info.is_available == 1) {
                                                                return (
                                                                    <div>
                                                                        {info.arr_english.map((lang, iiiiii) => {
                                                                            if (iiiiii == 0) {
                                                                                return (
                                                                                    <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>
                                                                                        {t('home.English Language Name')}
                                                                                        <br />
                                                                                        <Link to={"/event_details/" + lang.event_id} style={{ textDecoration: 'underline' }}>{lang.event_title}</Link>
                                                                                    </label>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                                        <br />
                                                                                        <Link to={"/event_details/" + lang.event_id} style={{ textDecoration: 'underline' }}>{lang.event_title}</Link>
                                                                                    </label>
                                                                                )
                                                                            }
                                                                        })
                                                                        }
                                                                        <br />
                                                                        {info.arr_marathi.map((lang, iiiiii) => {
                                                                            if (iiiiii == 0) {
                                                                                return (
                                                                                    <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>
                                                                                        {t('home.Marathi Language Name')}
                                                                                        <br />
                                                                                        <Link to={"/event_details/" + lang.event_id} style={{ textDecoration: 'underline' }}>{lang.event_title}</Link>
                                                                                    </label>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                                        <br />
                                                                                        <Link to={"/event_details/" + lang.event_id} style={{ textDecoration: 'underline' }}>{lang.event_title}</Link>
                                                                                    </label>
                                                                                )
                                                                            }
                                                                        })
                                                                        }

                                                                        <br />
                                                                        {info.arr_hindi.map((lang, iiiiii) => {
                                                                            if (iiiiii == 0) {
                                                                                return (
                                                                                    <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>
                                                                                        {t('home.Hindi Language Name')}
                                                                                        <br />
                                                                                        <Link to={"/event_details/" + lang.event_id} style={{ textDecoration: 'underline' }}>{lang.event_title}</Link>
                                                                                    </label>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                                        <br />
                                                                                        <Link to={"/event_details/" + lang.event_id} style={{ textDecoration: 'underline' }}>{lang.event_title}</Link>
                                                                                    </label>
                                                                                )
                                                                            }
                                                                        })
                                                                        }

                                                                        <br />
                                                                        {info.arr_gujrati.map((lang, iiiiii) => {
                                                                            if (iiiiii == 0) {
                                                                                return (
                                                                                    <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>
                                                                                        {t('home.Gujarati Language Name')}
                                                                                        <br />
                                                                                        <Link to={"/event_details/" + lang.event_id} style={{ textDecoration: 'underline' }}>{lang.event_title}</Link>
                                                                                    </label>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                                        <br />
                                                                                        <Link to={"/event_details/" + lang.event_id} style={{ textDecoration: 'underline' }}>{lang.event_title}</Link>
                                                                                    </label>
                                                                                )
                                                                            }
                                                                        })
                                                                        }




                                                                    </div>
                                                                )
                                                            }

                                                        } else {
                                                            return (
                                                                <label className="mt-3" style={{ marginLeft: '10px', textDecoration: 'underline', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                    <Link to={"/event_details/" + info.event_id}   >{info.event_title}</Link>
                                                                </label>

                                                            )
                                                        }

                                                    })
                                                    }

                                                </div>
                                            </div>
                                        )
                                    }
                                })
                                }



                                {products.map((productType, ii) => {
                                    if (productType.arr_products.length > 0) {
                                        return (
                                            <div className="row">
                                                <div className="col">
                                                    <div className="col p-3 text-white mt-3 relatedProductsImage d-xl-flex d-lg-flex d-md-flex d-none" style={{ backgroundImage: "url(" + myConstClass.IMAGE_URL + productType.background_image + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} >
                                                    </div>
                                                    {
                                                        productType.product_type_id === "1" ?
                                                            (<img src={this.state.Books} height={77} className="d-xl-none d-lg-none d-md-none d-block" />)
                                                            :
                                                            (null)
                                                    }
                                                    {
                                                        productType.product_type_id === "2" ?
                                                            (<img src={this.state.KITs} height={77} className="d-xl-none d-lg-none d-md-none d-block" />)
                                                            :
                                                            (null)
                                                    }
                                                    {
                                                        productType.product_type_id === "3" ?
                                                            (<img src={this.state.CDs} height={77} className="d-xl-none d-lg-none d-md-none d-block" />)
                                                            :
                                                            (null)
                                                    }
                                                    {
                                                        productType.product_type_id === "4" ?
                                                            (<img src={this.state.ExperimentalProducts} height={77} className="d-xl-none d-lg-none d-md-none d-block" />)
                                                            :
                                                            (null)
                                                    }
                                                    {
                                                        productType.product_type_id === "5" ?
                                                            (<img src={AyurvedicProducts} height={77} className="d-xl-none d-lg-none d-md-none d-block" />)
                                                            :
                                                            (null)
                                                    }
                                                    {
                                                        productType.product_type_id === "6" ?
                                                            (<img src={this.state.DIWALIMAGAZINE} height={77} className="d-xl-none d-lg-none d-md-none d-block" />)
                                                            :
                                                            (null)
                                                    }
                                                    {
                                                        productType.product_type_id === "7" ?
                                                            (<img src={this.state.BalDiwali} height={77} className="d-xl-none d-lg-none d-md-none d-block" />)
                                                            :
                                                            (null)
                                                    }
                                                    {productType.arr_products.map((info, ii) => {
                                                        if (productType.product_type_id == 2) {
                                                            if (info.is_available == 1) {
                                                                return (
                                                                    <div>
                                                                        {info.arr_english.map((lang, iiiiii) => {
                                                                            if (iiiiii == 0) {
                                                                                return (
                                                                                    <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>
                                                                                        {t('home.English Language Name')}
                                                                                        <br />
                                                                                        <Link to={"/products_details/" + info.product_title + '/' + info.product_id} style={{ textDecoration: 'underline' }}>{lang.product_title}</Link>
                                                                                    </label>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                                        <br />
                                                                                        <Link to={"/products_details/" + info.product_title + '/' + info.product_id} style={{ textDecoration: 'underline' }}>{lang.product_title}</Link>
                                                                                    </label>
                                                                                )
                                                                            }
                                                                        })
                                                                        }
                                                                        <br />
                                                                        {info.arr_marathi.map((lang, iiiiii) => {
                                                                            if (iiiiii == 0) {
                                                                                return (
                                                                                    <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>
                                                                                        {t('home.Marathi Language Name')}
                                                                                        <br />
                                                                                        <Link to={"/products_details/" + info.product_title + '/' + info.product_id} style={{ textDecoration: 'underline' }}>{lang.product_title}</Link>
                                                                                    </label>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                                        <br />
                                                                                        <Link to={"/products_details/" + info.product_title + '/' + info.product_id} style={{ textDecoration: 'underline' }}>{lang.product_title}</Link>
                                                                                    </label>
                                                                                )
                                                                            }
                                                                        })
                                                                        }

                                                                        <br />
                                                                        {info.arr_hindi.map((lang, iiiiii) => {
                                                                            if (iiiiii == 0) {
                                                                                return (
                                                                                    <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>
                                                                                        {t('home.Hindi Language Name')}
                                                                                        <br />
                                                                                        <Link to={"/products_details/" + info.product_title + '/' + info.product_id} style={{ textDecoration: 'underline' }}>{lang.product_title}</Link>
                                                                                    </label>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                                        <br />
                                                                                        <Link to={"/products_details/" + info.product_title + '/' + info.product_id} style={{ textDecoration: 'underline' }}>{lang.product_title}</Link>
                                                                                    </label>
                                                                                )
                                                                            }
                                                                        })
                                                                        }

                                                                        <br />
                                                                        {info.arr_gujrati.map((lang, iiiiii) => {
                                                                            if (iiiiii == 0) {
                                                                                return (
                                                                                    <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>
                                                                                        {t('home.Gujarati Language Name')}
                                                                                        <br />
                                                                                        <Link to={"/products_details/" + info.product_title + '/' + info.product_id} style={{ textDecoration: 'underline' }}>{lang.product_title}</Link>
                                                                                    </label>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                                        <br />
                                                                                        <Link to={"/products_details/" + info.product_title + '/' + info.product_id} style={{ textDecoration: 'underline' }}>{lang.product_title}</Link>
                                                                                    </label>
                                                                                )
                                                                            }
                                                                        })
                                                                        }




                                                                    </div>
                                                                )
                                                            }

                                                        } else {
                                                            return (
                                                                <label className="mt-3" style={{ marginLeft: '10px', textDecoration: 'underline', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                    <Link to={"/products_details/" + info.product_title + '/' + info.product_id}   >{info.product_title}</Link>
                                                                </label>

                                                            )
                                                        }

                                                    })
                                                    }

                                                </div>
                                            </div>
                                        )


                                    }
                                })
                                }



                                <div className="row MISCELLANEOUSDiv" style={{ display: 'none' }}>
                                    <div className="col">
                                        <div className="col p-3 text-white mt-3 d-xl-flex d-lg-flex d-md-flex d-none" style={{ backgroundImage: "url(" + backgroundEvent + ")", }} >
                                            <h1 className="m-0 font-weight-light">
                                                {t('home.MISCELLANEOUS')}
                                            </h1>
                                        </div>
                                        <img src={this.state.Miscellaneous} height={77} className="d-xl-none d-lg-none d-md-none d-block" />
                                    </div>
                                </div>

                                {arrResearch.map((research, ii) => {
                                    if (ii == 0) {
                                        return (
                                            <div className="row">
                                                <div className="col">
                                                    <div className="col p-3 mt-3"  >
                                                        <h1 className="m-0 font-weight-light">
                                                            <b>{t('home.Research')}</b>
                                                        </h1>
                                                    </div>
                                                    {arrResearch.map((other, ii) => {

                                                        return (
                                                            <label className="mt-3" style={{ marginLeft: '10px', textDecoration: 'underline', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                <Link to={"/research-details" + '/' + other.news_article_id}>
                                                                    {other.title}
                                                                </Link>
                                                            </label>

                                                        )

                                                    })
                                                    }

                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className="row">
                                                <div className="col">

                                                    {arrResearch.map((other, ii) => {

                                                        return (
                                                            <label className="mt-3" style={{ marginLeft: '10px', textDecoration: 'underline', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                <Link to={"/research-details" + '/' + other.news_article_id}>
                                                                    {other.title}
                                                                </Link>
                                                            </label>

                                                        )

                                                    })
                                                    }

                                                </div>
                                            </div>
                                        )


                                    }
                                })
                                }


                                {arrNews.map((research, ii) => {
                                    if (ii == 0) {
                                        return (
                                            <div className="row">
                                                <div className="col">
                                                    <div className="col p-3 mt-3"  >
                                                        <h1 className="m-0 font-weight-light">
                                                            <b>{t('home.News')}</b>
                                                        </h1>
                                                    </div>
                                                    {arrNews.map((other, ii) => {

                                                        return (
                                                            <label className="mt-3" style={{ marginLeft: '10px', textDecoration: 'underline', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                <Link to={"/news-details" + '/' + other.news_article_id}>
                                                                    {other.title}
                                                                </Link>
                                                            </label>

                                                        )

                                                    })
                                                    }

                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className="row">
                                                <div className="col">

                                                    {arrResearch.map((other, ii) => {

                                                        return (
                                                            <label className="mt-3" style={{ marginLeft: '10px', textDecoration: 'underline', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                <Link to={"/news-details" + '/' + other.news_article_id}>
                                                                    {other.title}
                                                                </Link>
                                                            </label>

                                                        )

                                                    })
                                                    }

                                                </div>
                                            </div>
                                        )


                                    }
                                })
                                }




                                {arrOther.map((research, ii) => {
                                    if (ii == 0) {
                                        return (
                                            <div className="row">
                                                <div className="col othersDiv">
                                                    <div className="col p-3 mt-3"  >
                                                        <h1 className="m-0 font-weight-light">
                                                            <b>{t('home.Others')}</b>
                                                        </h1>
                                                    </div>
                                                    {arrOther.map((other, ii) => {

                                                        return (
                                                            <label className="mt-3" style={{ marginLeft: '10px', textDecoration: 'underline', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                <a target="_blank" href={other.url}>
                                                                    {other.title}
                                                                </a>
                                                            </label>

                                                        )

                                                    })
                                                    }

                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className="row">
                                                <div className="col">

                                                    {arrOther.map((other, ii) => {

                                                        return (
                                                            <label className="mt-3" style={{ marginLeft: '10px', textDecoration: 'underline', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                <a target="_blank" href={other.url}>
                                                                    {other.title}
                                                                </a>
                                                            </label>

                                                        )

                                                    })
                                                    }

                                                </div>
                                            </div>
                                        )


                                    }
                                })
                                }



                                {/* {arrOtherAboutUs.map((research, ii) => {
                                    if (ii == 0) {
                                        return (
                                        )
                                    } else {
                                        return (
                                            <div className="row">
                                                <div className="col">
                                                    {
                                                        arrOtherAboutUs.map((other, ii) => {

                                                            return (
                                                                <label className="mt-3" style={{ marginLeft: '10px', textDecoration: 'underline', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                                    <a target="_blank" href={'about-us/' + other.type}>
                                                                        {ReactHtmlParser(other.title + '...')}
                                                                    </a>
                                                                </label>

                                                            )

                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                                } */}

                                {
                                    arrOtherAboutUs.length ?
                                        <div className="row">
                                            <div className="col ">
                                                <div className="col p-3 mt-3 othersDivAboutUs"  >
                                                    <h1 className="m-0 font-weight-light">
                                                        <b>{t('home.About Us')}</b>
                                                    </h1>
                                                </div>
                                                {
                                                    arrOtherAboutUs.map((other, ii) => {
                                                        return (
                                                            <label className="mt-0" style={{ marginLeft: '10px', textDecoration: 'underline' }}>
                                                                <a target="_blank" href={'about-us/' + other.type}>
                                                                    {ReactHtmlParser(other.title + '...')}
                                                                </a>
                                                            </label>

                                                        )

                                                    })
                                                }

                                            </div>
                                        </div>

                                        : (null)
                                }



                                {
                                    arr_gallery_photo.length ?
                                        <div className="row">
                                            <div className="col ">
                                                <div className="col p-3 mt-3 othersDivPhotoGallery"  >
                                                    <h1 className="m-0 font-weight-light">
                                                        <b>{t('home.Photo Gallery')}</b>
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }


                                <div className="row">
                                    <div className="col">
                                        {arr_gallery_photo.map((other, ii) => {
                                            return (
                                                <label className="mt-3" style={{ marginLeft: '10px', textDecoration: 'underline', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>
                                                    <a target="_blank" href={other.image_url}>
                                                        <img id={ii} src={other.image_url} className="card-img-top galleryResponsiveImage" alt="..." />
                                                    </a>
                                                </label>

                                            )
                                        })
                                        }
                                    </div>
                                </div>


                                {
                                    arr_gallery_video.length ?
                                        <div className="row">
                                            <div className="col ">
                                                <div className="col p-3 mt-3 othersDivPhotoGallery"  >
                                                    <h1 className="m-0 font-weight-light">
                                                        <b>{t('home.Video Gallery')}</b>
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }


                                <div className="row">
                                    <div className="col">
                                        {arr_gallery_video.map((other, ii) => {
                                            return (
                                                <label className="mt-3" style={{ marginLeft: '10px', textDecoration: 'underline', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>
                                                    <div className="div1">
                                                        <a target="_blank" href={other.image_url}>
                                                            <img id={ii} src={other.you_tube_url} className="card-img-top galleryResponsiveImage" alt="..." />
                                                        </a>
                                                        <div className="overlay">
                                                            <a target="_blank" href={other.image_url} className="icon" title="">
                                                                <i className="fab fa-youtube color-red" onClick={this.openGallery}></i>
                                                            </a>

                                                        </div>
                                                    </div>
                                                </label>
                                            )
                                        })
                                        }
                                    </div>
                                </div>


                                {
                                    arr_gallery_audio.length ?
                                        <div className="row">
                                            <div className="col ">
                                                <div className="col p-3 mt-3 othersDivPhotoGallery"  >
                                                    <h1 className="m-0 font-weight-light">
                                                        <b>{t('home.Audio Gallery')}</b>
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }


                                <div className="row">
                                    <div className="col">
                                        {arr_gallery_audio.map((other, ii) => {

                                            return (
                                                <label className="mt-3" style={{ marginLeft: '10px', textDecoration: 'underline', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                    <a target="_blank" href={other.know_more_url}>
                                                        {ReactHtmlParser(other.title)}
                                                    </a>&nbsp;&nbsp;&nbsp;
                                                </label>

                                            )

                                        })
                                        }
                                    </div>
                                </div>


                                {
                                    arr_gallery_wallpaper.length ?
                                        <div className="row">
                                            <div className="col ">
                                                <div className="col p-3 mt-3 othersDivPhotoGallery"  >
                                                    <h1 className="m-0 font-weight-light">
                                                        <b>{t('home.Wallpaper Gallery')}</b>
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }

                                <div className="row">
                                    <div className="col">

                                        {arr_gallery_wallpaper.map((other, ii) => {

                                            return (
                                                <label className="mt-3" style={{ marginLeft: '10px', textDecoration: 'underline', maxWidth: '30%', minWidth: '30%', verticalAlign: 'top' }}>

                                                    <a target="_blank" href={other.image_url}>
                                                        <img id={ii} src={other.image_url} className="card-img-top galleryResponsiveImage" alt="..." />
                                                    </a>
                                                </label>

                                            )

                                        })
                                        }

                                    </div>
                                </div>

                                <div className="row pt-2">

                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        {arr_downloads.map((downloads, i) => {
                                            return (
                                                <div className="col-12 border border-dark my-3 mr-1 ">
                                                    <div className="ml-3">
                                                        <div className="row">
                                                            <h5 className="pt-3 pb-1">{downloads.title}</h5>
                                                        </div>
                                                        {downloads.arr_files.map((files, ii) => {
                                                            if (files.type == 1) {
                                                                return (
                                                                    <div className="row">
                                                                        <div className="col-lg-2 pt-2">
                                                                            <img src={pdf} width="23px" className="mb-2" />
                                                                        </div>
                                                                        <div className="col-lg-8 pt-8">
                                                                            <span className="ml-2">
                                                                                {files.filename}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-lg-2 pt-2">
                                                                            <a target="_blank" href={files.image_name} download={files.image_name} className="downloadButtonDiv">
                                                                                <button className="btn btn-brown btn-sm mx-auto mt-3">{t('home.Download')}</button>
                                                                            </a>
                                                                        </div>

                                                                    </div>
                                                                )
                                                            } else if (files.type == 2) {
                                                                return (
                                                                    <div className="row mb-2">
                                                                        <div className="col-lg-2 pt-2">
                                                                            <img src={image} style={{ width: '26px', height: '41px' }} width="24px" />
                                                                        </div>
                                                                        <div className="col-lg-8 pt-8">
                                                                            <span className="ml-2">
                                                                                {files.filename}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-lg-2 pt-2">
                                                                            <a href="" download={files.image_name} className="downloadButtonDiv">
                                                                                <button type="submit" className="btn btn-brown btn-sm mx-auto mt-3" download>{t('home.Download')}</button>
                                                                            </a>
                                                                        </div>

                                                                    </div>
                                                                )
                                                            } else if (files.type == 3) {
                                                                return (
                                                                    <div className="row">
                                                                        <div className="col-lg-2 pt-2">
                                                                            <img src={excel} style={{ width: '26px', height: '41px' }} />
                                                                        </div>
                                                                        <div className="col-lg-8 pt-8">
                                                                            <span className="ml-2">
                                                                                {files.filename}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-lg-2 pt-2">
                                                                            <a target="_blank" href={files.image_name} className="downloadButtonDiv">
                                                                                <button className="btn btn-brown btn-sm mx-auto mt-3">{t('home.Download')}</button>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div className="row">
                                                                        <div className="col-lg-2 pt-2">
                                                                            <img src={doc} style={{ width: '26px', height: '34px' }} />
                                                                        </div>
                                                                        <div className="col-lg-8 pt-8">
                                                                            <span className="ml-2">
                                                                                {files.filename}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-lg-2 pt-2">
                                                                            <a target="_blank" href={files.image_name} className="downloadButtonDiv">
                                                                                <button className="btn btn-brown btn-sm mx-auto mt-3">{t('home.Download')}</button>
                                                                            </a>
                                                                        </div>

                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                        }

                                                    </div>

                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>


                            </div>
                        </div>








                        <div className="col-3 d-xl-block d-lg-block d-none pt-3" id="eventRightMenuMain" style={{ marginTop: '76px' }}>
                            {arrMagazine.map((cell, i) => {
                                if (cell.magazine_id == 2) {
                                    return (
                                        <div className="col py-2 border border-warning bg-white box-shadow mb-3">

                                            <div className="row d-flex">
                                                <div className="col-12">
                                                    <strong>
                                                        {cell.magazine_title}
                                                    </strong>
                                                    <p>
                                                        {ReactHtmlParser(cell.description)}

                                                    </p>
                                                    <p>
                                                        <strong>{t('home.Language')}:</strong>

                                                        {cell.language}
                                                    </p>
                                                    <p>

                                                        {cell.images_array.map((info, ii) => {
                                                            if (ii == 0) {
                                                                if (info.issue_id > 0 && info.issue_id != undefined) {
                                                                    return (
                                                                        <Link to={'/magazine_details/' + info.magazine_id + '/' + info.issue_id}>
                                                                            <img style={{ height: '217px', width: '183px', marginLeft: '48px' }} className="image_css img-fluid" src={info.image_name} />
                                                                        </Link>

                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Link to={'/magazine'}>
                                                                            <img style={{ height: '217px', width: '183px', marginLeft: '48px' }} className="image_css" src={info.image_name} />
                                                                        </Link>

                                                                    )
                                                                }
                                                            } else {
                                                                return (
                                                                    <Link to={'/magazine'}>
                                                                        <img style={{ marginLeft: '52%', height: '217px' }} className="image_css" src={info.image_name} />
                                                                    </Link>
                                                                )
                                                            }
                                                        }
                                                        )}


                                                    </p>
                                                    <p>

                                                        <Link className="btn btn-brown btn-sm mb-2" style={{ marginLeft: '26%' }} to={'/magazine-subscription/' + cell.magazine_id}>{t('home.SUBSCRIBE NOW')}</Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="col py-2 border border-warning bg-white box-shadow mb-3">

                                            <div className="row d-flex">
                                                <div className="col-12">
                                                    <strong>
                                                        {cell.magazine_title}
                                                    </strong>
                                                    <p>
                                                        {ReactHtmlParser(cell.description)}

                                                    </p>
                                                    <p>
                                                        <strong>{t('home.Language')}:</strong>

                                                        {cell.language}
                                                    </p>
                                                    <p>

                                                        {cell.images_array.map((info, ii) => {
                                                            if (ii == 0) {
                                                                if (info.product_id > 0) {
                                                                    return (
                                                                        <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                            <img style={{ height: '217px', width: '183px', marginLeft: '48px' }} className="image_css" src={info.image_name} />

                                                                            <p style={{ marginTop: '10px' }}>
                                                                                <Link className="btn btn-brown btn-sm mb-2" style={{ marginLeft: '34%' }} to={'/products_details/' + info.title + '/' + info.product_id}>{t('home.BUY NOW')}</Link>
                                                                            </p>
                                                                        </Link>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Link to={'/products'}>
                                                                            <img style={{ height: '217px', width: '183px', marginLeft: '48px' }} className="image_css" src={info.image_name} />
                                                                            <p style={{ marginTop: '10px' }}>
                                                                                <Link className="btn btn-brown btn-sm mb-2" style={{ marginLeft: '34%' }} to={'/products'}>{t('home.BUY NOW')}</Link>
                                                                            </p>
                                                                        </Link>
                                                                    )
                                                                }

                                                            } else {
                                                                if (info.product_id > 0) {
                                                                    return (
                                                                        <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                            <img style={{ marginLeft: '52%', height: '217px' }} className="image_css" src={info.image_name} />
                                                                        </Link>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Link to={'/products'}>
                                                                            <img style={{ marginLeft: '52%', height: '217px' }} className="image_css" src={info.image_name} />
                                                                        </Link>
                                                                    )
                                                                }
                                                            }
                                                        }
                                                        )}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>







                    </div>
                </div>

                <Footer />


                {/* <div className="container-fluid bg-grey mt-4">
        <div className="row grey-text">
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Residential Study Courses</a>
                <a href="#" className="grey-text d-block py-1">1-day Courses</a>
                <a href="#" className="grey-text d-block py-1">Machine Tests</a>
                <a href="#" className="grey-text d-block py-1">Rational Poojas</a>
                <a href="#" className="grey-text d-block py-1">Free Events</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Books</a>
                <a href="#" className="grey-text d-block py-1">CDs</a>
                <a href="#" className="grey-text d-block py-1">Kits (Set of Books)</a>
                <a href="#" className="grey-text d-block py-1">Experimental Products</a>
                <a href="#" className="grey-text d-block py-1">Ayurvedic Products</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Magazines</a>
                <a href="#" className="grey-text d-block py-1">Gallery</a>
                <a href="#" className="grey-text d-block py-1">Downloads</a>
                <a href="#" className="grey-text d-block py-1">FAQs</a>
                <a href="#" className="grey-text d-block py-1">E-Shopping Help</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">About Us</a>
                <a href="#" className="grey-text d-block py-1">Research</a>
                <a href="#" className="grey-text d-block py-1">Event Calendar</a>
                <a href="#" className="grey-text d-block py-1">Feedback</a>
                <a href="#" className="grey-text d-block py-1">Contact Us</a>
            </div>
        </div>
    </div> */}
                {/* <div className="container-fluid bg-black">
        <p className="text-center py-3 grey-text mb-0">
            © 2000-2017 Manashakti Rest New Way, | All Rights Reserved Worldwide
        </p>
    </div> */}
            </div>
        );
    }
}

Sitesearch.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Sitesearch);

