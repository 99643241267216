import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/images/logo.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import Header from './Header.js';
import Footer from './Footer.js';
import { Editor } from 'react-draft-wysiwyg';
import $ from 'jquery';
import checkoutImage from './assets/images/mycart.png';
import { Helmet } from "react-helmet";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

// Translation Higher Order Component
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage, getLanguage,
  translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Label
} from 'reactstrap';

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.product_id = 0;
    this.state = {
      languagePdf: '',
      cart: [],
      arrMagazine: [],
      flag: 0,
      language: 'en',
      value: 1,
      eventinfoarray: [],
      guestinfoarray: [],
      cartEvent: [],
      cartMachineTestEvent: [],
      delivery_type: '1',
      magazineinfo: {
        totalMagazinePrice: 0,
        // totalMagazinePriceNew:0,
      },
      termsinfo: {
        description_html: '',
        description_mr_html: '',
        description: '',
      },
      guestinfo: {
        first_name: '',
        middle_name: '',
        last_name: '',
        address_line_1: '',
        address_line_2: '',
        landmark: '',
        country: '',
        state: '',
        city: '',
        pincode: '',
        phone: '',
        email: '',
        country_name: '',
        state_name: '',
        city_name: '',
        product_total_price: '',
        // totalMagazinePrice:0,
        total_shipping_charges: 0,
        total_shipping_charges_express: 0,
        country_id: '',
        shipping_message: '',
        total_weight_in_kg: '',
        country_name: '',
      },
      eventinfo: {
        first_name_shipping: '',
        middle_name: '',
        last_name: '',
        address_line_1: '',
        address_line_2: '',
        landmark: '',
        country: '',
        state: '',
        city: '',
        pincode: '',
        phone: '',
        email: '',
        country_name: '',
        state_name: '',
        cart_total_price: '',
        //totalMagazinePrice:'',


      },

      removeinfo: {
        message: '',
        status: '',
        encrypted_data: '',
        access_code: '',

      },
      cartTotal: {
        message: '',
        status: '',
        cart_total_price: '',
      },
      editorState: EditorState.createEmpty(),
    }

    this.handleChange = this.handleChange.bind(this);
    //this.removeFromCart = this.removeFromCart.bind(this);
    this.proceedToPayment = this.proceedToPayment.bind(this);
    this.handleChangeDelivery = this.handleChangeDelivery.bind(this);
    this.getTotal = this.getTotal.bind(this);
    this.cartTotalPrice = this.cartTotalPrice.bind(this);
    // this.checkout = this.checkout.bind(this);
    this.changeArrowDirection = this.changeArrowDirection.bind(this);
  }

  getTotal() {
    if (this.state.arrMagazine.length > 0) {
      return this.state.arrMagazine[0].total_donation_contribution;
    } else {
      return 0;
    }

  }

  cartTotalPrice() {
    if (this.state.eventinfo.cart_total_price > 0) {
      var regular_shipping_charges = this.state.guestinfo.total_shipping_charges;
      var total_price = this.state.eventinfo.cart_total_price;
      //  return (parseFloat(total_price) + parseFloat(regular_shipping_charges));
      return total_price;
    } else {
      return 0;
    }

  }

  handleChangeDelivery(event) {
    // this.state.magazineinfo.totalMagazinePriceNew = 0;
    //   this.state.magazineinfo.totalMagazinePriceNew = this.state.magazineinfo.totalMagazinePrice;
    //   this.state.magazineinfo.totalMagazinePrice = 0;
    this.setState({
      delivery_type: event.target.value
    });

    // console.log(this.state.guestinfo, this.state.cartTotal)

    if (event.target.value == 1) {
      //alert('one');
      var regular_shipping_charges = this.state.guestinfo.total_shipping_charges;
      var express_shipping_charges = this.state.guestinfo.total_shipping_charges_express;
      // var current_cart_total_price = this.state.eventinfo.cart_total_price;
      var current_cart_total_price = this.state.cartTotal.cart_total_price;
      // this.state.eventinfo.cart_total_price = (parseFloat(current_cart_total_price) + parseFloat(regular_shipping_charges) - parseFloat(express_shipping_charges)).toFixed(2);
      this.state.eventinfo.cart_total_price = (parseFloat(current_cart_total_price)).toFixed(2);

    } else {
      //alert('two');
      var regular_shipping_charges = this.state.guestinfo.total_shipping_charges;
      var express_shipping_charges = this.state.guestinfo.total_shipping_charges_express;
      // var current_cart_total_price = this.state.eventinfo.cart_total_price;
      var current_cart_total_price = this.state.cartTotal.cart_total_price;
      this.state.eventinfo.cart_total_price = (parseFloat(current_cart_total_price) + parseFloat(express_shipping_charges) - parseFloat(regular_shipping_charges)).toFixed(2);

    }

  }
  proceedToPayment(e) {
    var delivery_type = $("input[name='delivery_type']:checked").val();
    if (delivery_type == 1) {
      var total_shipping_charges = this.state.guestinfo.total_shipping_charges;
    } else {
      var total_shipping_charges = this.state.guestinfo.total_shipping_charges_express;
    }

    //alert(total_shipping_charges);
    // if($("#customControlInline").prop('checked') == true){
    $('#btn_click').css('display', 'none');
    $('#loader').css('display', 'inline');
    var user_id = localStorage.getItem('user_id');
    var total_price = this.state.cartTotal.cart_total_price;
    //var total_price = 1;
    if (total_price != '' && user_id != '' && user_id > 0 && total_price > 0) {
      var exampleFormControlTextarea1 = $('#exampleFormControlTextarea1').val();

      var is_same_address = localStorage.getItem('is_same_address');
      // alert(myConstClass.BASE_URL+`payment/goToPayment/`+user_id+'/'+is_same_address);
      //alert(is_same_address)
      // if(is_same_address > 0){

      // }else{
      //     is_same_address = 0;
      // }

      // console.log(exampleFormControlTextarea1);
      // fetch(myConstClass.BASE_URL+'payment/goToPayment/'+user_id+'/'+exampleFormControlTextarea1)
      fetch(myConstClass.BASE_URL + `payment/goToPayment/` + user_id + '/' + is_same_address + '/' + delivery_type + '/' + total_shipping_charges, {
        method: 'POST',
        // body: JSON.stringify($('#exampleFormControlTextarea1').serializeArray()),
        body: JSON.stringify($('#captcha').serializeArray()),

      })
        .then(response => response.json())
        .then(response => {
          this.setState({ removeinfo: response })
          var message = this.state.removeinfo.message;
          var status = this.state.removeinfo.status;
          var order_id = this.state.removeinfo.order_id;
          if (status == 'true') {
            $('#btn_click').css('display', 'inline');
            $('#loader').css('display', 'none');
            // $('#TR'+product_id).css('display','none');
            //toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});
            var message = "Your order completed successfully.";
            localStorage.removeItem('success');
            // localStorage.setItem('success',message);
            //var encrypted_data = this.state.removeinfo.encrypted_data;
            this.state.removeinfo.encrypted_data = this.state.removeinfo.encrypted_data;

            // var access_code = this.state.removeinfo.access_code;
            this.state.removeinfo.access_code = this.state.removeinfo.access_code;



            $("#redirect").submit();

            //this.props.history.push('/order-details/'+order_id)
          } else {
            $('#btn_click').css('display', 'inline');
            $('#loader').css('display', 'none');
            localStorage.removeItem('error');
            // localStorage.setItem('error',message);
            toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
            //this.props.history.push('/order-details/'+order_id);
          }
          //console.log(status);
        })
        .catch(err => console.log(err))


    } else {
      //toastr.warning('Product quantity must be greater than 0')
      if (user_id > 0) {
        if (total_price == '' || total_price == undefined) {
          toast('You must have to add products in your cart.', { containerId: 'B', type: toast.TYPE.ERROR });
        }

      } else {
        var message = "Please login to our website.";
        localStorage.removeItem('error');
        localStorage.setItem('error', message);
        this.props.history.push('/signin')
        //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR});

      }

    }

    // }else{
    //     var message = "Please confirm you are not a robot";
    //     toast(''+message, {containerId: 'B',type: toast.TYPE.ERROR});
    // }
  }

  componentDidMount() {
    var user_id = localStorage.getItem("user_id");
    if (user_id) {
      localStorage.removeItem("error");
    } else {
      localStorage.setItem("error", "please login first");
      return this.props.history.replace('/signin')
    }
    var current_language = getLanguage();
    localStorage.setItem('current_language', current_language);
    this.state.language = getLanguage();
    if (getLanguage() == 'ma') {
      this.state.languagePdf = "Terms_conditions_marathi.pdf";
    } else {
      this.state.languagePdf = "Terms_conditions.pdf";
    }
    //this.state.guestinfo.product_total_price = 0;
    const answer_array = this.props.location.pathname.split('/');

    if (user_id > 0) {

      fetch(myConstClass.BASE_URL + 'cart/getCartTotalPrice/' + user_id)
        .then(response => response.json())
        .then(response => {
          this.setState({ cartTotal: response })
          //var totalRecords =  this.state.cart[0].title;
          //const numRows = this.state.cartTotal.length;
          // this.state.eventinfo.cart_total_price = parseFloat(this.state.cartTotal.cart_total_price).toFixed(2);
          this.state.eventinfo.cart_total_price = parseFloat(this.state.cartTotal.cart_total_price).toFixed(2);
          var product_shipping_charges = parseFloat(this.state.cartTotal.cart_total_price).toFixed(2);
          // alert(product_shipping_charges);
          localStorage.removeItem('cart_total_price');
          localStorage.setItem('cart_total_price', product_shipping_charges);

        })

      fetch(myConstClass.BASE_URL + 'cart/getAllMyCartData/' + user_id + '/' + getLanguage())
        .then(response => response.json())
        .then(response => {
          this.setState({ cart: response })
          const numRows = this.state.cart.length;

          if (numRows < 1) {
            // var current_cart_total_price = localStorage.getItem('cart_total_price');
            // this.state.eventinfo.cart_total_price = parseFloat(current_cart_total_price).toFixed(2);
            $('#noRecordsFoundTR').css('display', 'contents');
            $('#productTotalPrice').css('display', 'none');
            $('#allShippingCharges').css('display', 'none');
            $('#noShippingCharges').css('display', 'inline');
            $('#RegularTypeDiv').css('display', 'none');
            $('.productTable').css('display', 'none');
            $('#noShippingChargesEventOnly').css('display', 'inline');
            $('.totalIncludingShippingChargesClass').click();
          } else {
            $('#noShippingChargesEventOnly').css('display', 'none');
            this.state.guestinfo.product_total_price = this.state.cart[0].total_price;
            this.state.guestinfo.shipping_message = this.state.cart[0].shipping_message;
            this.state.guestinfo.country_name = this.state.cart[0].country_name;
            this.state.guestinfo.total_weight_in_kg = this.state.cart[0].total_weight_in_kg;
            var total_shipping_charges = this.state.cart[0].total_shipping_charges;
            var current_shipping_charges = this.state.guestinfo.total_shipping_charges;
            this.state.guestinfo.total_shipping_charges = parseFloat(total_shipping_charges) + parseFloat(current_shipping_charges);

            var current_cart_total_price = localStorage.getItem('cart_total_price');

            var cart_total_price = (parseFloat(current_cart_total_price) + parseFloat(total_shipping_charges) + parseFloat(current_shipping_charges));


            var country_id = this.state.cart[0].country_id;

            if (country_id == 101) {
              $('#total_shipping_charges_express_row').css('display', 'flex');
            }
            this.state.guestinfo.total_shipping_charges_express = this.state.cart[0].total_shipping_charges_express;
          }
        })

      fetch(myConstClass.BASE_URL + 'cart/getAllMyCartDataForMachineTestEventsCheckout/' + user_id + '/' + getLanguage())
        .then(response => response.json())
        .then(response => {
          this.setState({ cartMachineTestEvent: response })
          const numRows = this.state.cartMachineTestEvent.length;
          if (numRows < 1) {
            $('#noRecordsFoundEventTR').css('display', 'block');
          }
        })

      fetch(myConstClass.BASE_URL + 'terms/termsConditionsHTMLCheckout/' + user_id + '/' + getLanguage())
        .then(response => response.json())
        .then(response => {
          this.setState({ termsinfo: response })

        })

      fetch(myConstClass.BASE_URL + 'cart/getAllMyCartDataForEventsCheckout/' + user_id + '/' + getLanguage())
        .then(response => response.json())
        .then(response => {
          this.setState({ cartEvent: response })

          const numRows = this.state.cartEvent.length;
          if (numRows < 1) {
            $('#noRecordsFoundEventTR').css('display', 'block');
          }
        })






      var billing_address_id = 1;
      fetch(myConstClass.BASE_URL + 'users/getBillingAddress/' + billing_address_id + '/' + user_id)
        .then(response => response.json())
        .then(response => {
          this.setState({ guestinfoarray: response })
          const numRows = this.state.guestinfoarray.length;
          //if(numRows > 0){

          //}

          // //var totalRecords =  this.state.cart[0].title;
          // const numRows = this.state.cart.length;
          // if(numRows < 1){
          //     $('#noRecordsFoundTR').css('display','block');
          // }
        })


      var billing_address_id = 2;
      fetch(myConstClass.BASE_URL + 'users/getBillingAddress/' + billing_address_id + '/' + user_id)
        .then(response => response.json())
        .then(response => {
          this.setState({ eventinfoarray: response })
          const numRows = this.state.eventinfoarray.length;
          //if(numRows > 0){


          // }
          // //var totalRecords =  this.state.cart[0].title;
          // const numRows = this.state.cart.length;
          // if(numRows < 1){
          //     $('#noRecordsFoundTR').css('display','block');
          // }
        })

      fetch(myConstClass.BASE_URL + 'cart/getAllMyCartDataForMagazine/' + user_id + '/' + getLanguage())
        .then(response => response.json())
        .then(response => {
          this.setState({ arrMagazine: response })
          //var totalRecords =  this.state.cart[0].title;
          const numRows = this.state.arrMagazine.length;
          //alert(numRows);
          if (numRows > 0) {
            this.state.magazineinfo.totalMagazinePrice = this.state.arrMagazine[0].total_donation_contribution;
          } else {
            // console.log(this.state.arrMagazine[0].total_donation_contribution);
            //alert('here');
            $('.magazineTable').css('display', 'none');
          }
        })

        .catch(err => console.log(err))
    }



  }

  componentDidUpdate(prevProps) {
    var current_language = localStorage.getItem('current_language');
    const { flag } = this.state
    if (current_language != getLanguage() && flag != 1 && current_language != null) {
      this.setState({ flag: 1 });

      // alert(this.state.languagePdf);
      localStorage.removeItem('current_language');
      localStorage.setItem('current_language', getLanguage());
    }
    this.state.language = getLanguage();
    if (getLanguage() == 'ma') {
      this.state.languagePdf = "Terms_conditions_marathi.pdf";

    } else {
      this.state.languagePdf = "Terms_conditions.pdf";
    }

    if (getLanguage() == 'ma' && flag == 1) {
      // this.state.guestinfo.total_shipping_charges = 0;
      // this.state.guestinfo.total_shipping_charges_express = 0;
      // this.state.eventinfo.cart_total_price = 0;
      //localStorage.removeItem('cart_total_price');
      //this.state.eventinfo.cart_total_price = 0;
      this.setState({ flag: 0 })
      var user_id = localStorage.getItem('user_id');
      if (user_id > 0) {

        fetch(myConstClass.BASE_URL + 'cart/getCartTotalPrice/' + user_id)
          .then(response => response.json())
          .then(response => {
            this.setState({ cartTotal: response })
            //var totalRecords =  this.state.cart[0].title;
            //const numRows = this.state.cartTotal.length;
            // this.state.eventinfo.cart_total_price = parseFloat(this.state.cartTotal.cart_total_price).toFixed(2);
            // this.state.eventinfo.cart_total_price = parseFloat(this.state.cartTotal.cart_total_price).toFixed(2);
            var product_shipping_charges = parseFloat(this.state.cartTotal.cart_total_price).toFixed(2);
            localStorage.removeItem('cart_total_price');
            localStorage.setItem('cart_total_price', product_shipping_charges);

          })

        fetch(myConstClass.BASE_URL + 'cart/getAllMyCartData/' + user_id + '/' + getLanguage())
          .then(response => response.json())
          .then(response => {
            this.setState({ cart: response })
            const numRows = this.state.cart.length;

            if (numRows < 1) {
              var current_cart_total_price = localStorage.getItem('cart_total_price');
              this.state.eventinfo.cart_total_price = parseFloat(current_cart_total_price).toFixed(2);
              $('#noRecordsFoundTR').css('display', 'contents');
              $('#productTotalPrice').css('display', 'none');
              $('#allShippingCharges').css('display', 'none');
              $('#noShippingCharges').css('display', 'inline');
              $('#RegularTypeDiv').css('display', 'none');
              $('.productTable').css('display', 'none');
            } else {

              this.state.guestinfo.product_total_price = this.state.cart[0].total_price;
              this.state.guestinfo.shipping_message = this.state.cart[0].shipping_message;
              this.state.guestinfo.country_name = this.state.cart[0].country_name;
              // this.state.guestinfo.total_weight_in_kg = this.state.cart[0].total_weight_in_kg;
              // var total_shipping_charges = this.state.cart[0].total_shipping_charges;

              // var current_shipping_charges = this.state.guestinfo.total_shipping_charges;
              // this.state.guestinfo.total_shipping_charges = parseFloat(total_shipping_charges) + parseFloat(current_shipping_charges);
              //  var current_cart_total_price = localStorage.getItem('cart_total_price');

              // var cart_total_price = (parseFloat(current_cart_total_price) + parseFloat(total_shipping_charges) + parseFloat(current_shipping_charges));
              // this.state.eventinfo.cart_total_price = cart_total_price.toFixed(2);

              // console.log(cart_total_price);


              var country_id = this.state.cart[0].country_id;
              if (country_id == 101) {
                $('#total_shipping_charges_express_row').css('display', 'flex');
              }
              this.state.guestinfo.total_shipping_charges_express = this.state.cart[0].total_shipping_charges_express;
            }
          })

        fetch(myConstClass.BASE_URL + 'cart/getAllMyCartDataForMachineTestEventsCheckout/' + user_id + '/' + getLanguage())
          .then(response => response.json())
          .then(response => {
            this.setState({ cartMachineTestEvent: response })
            //var totalRecords =  this.state.cart[0].title;
            const numRows = this.state.cartMachineTestEvent.length;
            if (numRows < 1) {
              $('#noRecordsFoundEventTR').css('display', 'block');
            }
          })

        fetch(myConstClass.BASE_URL + 'cart/getAllMyCartDataForEventsCheckout/' + user_id + '/' + getLanguage())
          .then(response => response.json())
          .then(response => {
            this.setState({ cartEvent: response })
            //var totalRecords =  this.state.cart[0].title;
            const numRows = this.state.cartEvent.length;
            if (numRows < 1) {
              $('#noRecordsFoundEventTR').css('display', 'block');
            }
          })






        var billing_address_id = 1;
        fetch(myConstClass.BASE_URL + 'users/getBillingAddress/' + billing_address_id + '/' + user_id)
          .then(response => response.json())
          .then(response => {
            this.setState({ guestinfoarray: response })
            const numRows = this.state.guestinfoarray.length;
            //if(numRows > 0){

            //}

            // //var totalRecords =  this.state.cart[0].title;
            // const numRows = this.state.cart.length;
            // if(numRows < 1){
            //     $('#noRecordsFoundTR').css('display','block');
            // }
          })


        var billing_address_id = 2;
        fetch(myConstClass.BASE_URL + 'users/getBillingAddress/' + billing_address_id + '/' + user_id)
          .then(response => response.json())
          .then(response => {
            this.setState({ eventinfoarray: response })
            const numRows = this.state.eventinfoarray.length;
            //if(numRows > 0){


            // }
            // //var totalRecords =  this.state.cart[0].title;
            // const numRows = this.state.cart.length;
            // if(numRows < 1){
            //     $('#noRecordsFoundTR').css('display','block');
            // }
          })

        fetch(myConstClass.BASE_URL + 'cart/getAllMyCartDataForMagazine/' + user_id + '/' + getLanguage())
          .then(response => response.json())
          .then(response => {
            this.setState({ arrMagazine: response })
            //var totalRecords =  this.state.cart[0].title;
            const numRows = this.state.arrMagazine.length;
            //alert(numRows);
            if (numRows > 0) {
              this.state.magazineinfo.totalMagazinePrice = this.state.arrMagazine[0].total_donation_contribution;
            } else {
              // console.log(this.state.arrMagazine[0].total_donation_contribution);
              //alert('here');
              $('.magazineTable').css('display', 'none');
            }
          })

          .catch(err => console.log(err))
      }


    }

    if (getLanguage() == 'en' && (flag == 1)) {
      // this.state.guestinfo.total_shipping_charges = 0;
      // this.state.guestinfo.total_shipping_charges_express = 0;
      // this.state.eventinfo.cart_total_price = 0;
      // localStorage.removeItem('cart_total_price');
      this.setState({ flag: 0 })



      var user_id = localStorage.getItem('user_id');
      if (user_id > 0) {

        fetch(myConstClass.BASE_URL + 'cart/getCartTotalPrice/' + user_id)
          .then(response => response.json())
          .then(response => {
            this.setState({ cartTotal: response })
            //var totalRecords =  this.state.cart[0].title;
            //const numRows = this.state.cartTotal.length;
            this.state.eventinfo.cart_total_price = parseFloat(this.state.cartTotal.cart_total_price).toFixed(2);
            var product_shipping_charges = parseFloat(this.state.cartTotal.cart_total_price).toFixed(2);
            localStorage.removeItem('cart_total_price');
            localStorage.setItem('cart_total_price', product_shipping_charges);

          })

        fetch(myConstClass.BASE_URL + 'cart/getAllMyCartData/' + user_id + '/' + getLanguage())
          .then(response => response.json())
          .then(response => {
            this.setState({ cart: response })
            const numRows = this.state.cart.length;

            if (numRows < 1) {
              var current_cart_total_price = localStorage.getItem('cart_total_price');
              this.state.eventinfo.cart_total_price = parseFloat(current_cart_total_price).toFixed(2);
              $('#noRecordsFoundTR').css('display', 'contents');
              $('#productTotalPrice').css('display', 'none');
              $('#allShippingCharges').css('display', 'none');
              $('#noShippingCharges').css('display', 'inline');
              $('#RegularTypeDiv').css('display', 'none');
              $('.productTable').css('display', 'none');
            } else {

              this.state.guestinfo.product_total_price = this.state.cart[0].total_price;
              this.state.guestinfo.shipping_message = this.state.cart[0].shipping_message;
              this.state.guestinfo.country_name = this.state.cart[0].country_name;
              // this.state.guestinfo.total_weight_in_kg = this.state.cart[0].total_weight_in_kg;
              // var total_shipping_charges = this.state.cart[0].total_shipping_charges;

              // var current_shipping_charges = this.state.guestinfo.total_shipping_charges;
              // this.state.guestinfo.total_shipping_charges = parseFloat(total_shipping_charges) + parseFloat(current_shipping_charges);
              //  var current_cart_total_price = localStorage.getItem('cart_total_price');

              // var cart_total_price = (parseFloat(current_cart_total_price) + parseFloat(total_shipping_charges) + parseFloat(current_shipping_charges));
              // this.state.eventinfo.cart_total_price = cart_total_price.toFixed(2);

              // console.log(cart_total_price);


              var country_id = this.state.cart[0].country_id;
              if (country_id == 101) {
                $('#total_shipping_charges_express_row').css('display', 'flex');
              }
              this.state.guestinfo.total_shipping_charges_express = this.state.cart[0].total_shipping_charges_express;
            }
          })

        fetch(myConstClass.BASE_URL + 'cart/getAllMyCartDataForMachineTestEventsCheckout/' + user_id + '/' + getLanguage())
          .then(response => response.json())
          .then(response => {
            this.setState({ cartMachineTestEvent: response })
            //var totalRecords =  this.state.cart[0].title;
            const numRows = this.state.cartMachineTestEvent.length;
            if (numRows < 1) {
              $('#noRecordsFoundEventTR').css('display', 'block');
            }
          })

        fetch(myConstClass.BASE_URL + 'cart/getAllMyCartDataForEventsCheckout/' + user_id + '/' + getLanguage())
          .then(response => response.json())
          .then(response => {
            this.setState({ cartEvent: response })
            //var totalRecords =  this.state.cart[0].title;
            const numRows = this.state.cartEvent.length;
            if (numRows < 1) {
              $('#noRecordsFoundEventTR').css('display', 'block');
            }
          })






        var billing_address_id = 1;
        fetch(myConstClass.BASE_URL + 'users/getBillingAddress/' + billing_address_id + '/' + user_id)
          .then(response => response.json())
          .then(response => {
            this.setState({ guestinfoarray: response })
            const numRows = this.state.guestinfoarray.length;
            //if(numRows > 0){

            //}

            // //var totalRecords =  this.state.cart[0].title;
            // const numRows = this.state.cart.length;
            // if(numRows < 1){
            //     $('#noRecordsFoundTR').css('display','block');
            // }
          })


        var billing_address_id = 2;
        fetch(myConstClass.BASE_URL + 'users/getBillingAddress/' + billing_address_id + '/' + user_id)
          .then(response => response.json())
          .then(response => {
            this.setState({ eventinfoarray: response })
            const numRows = this.state.eventinfoarray.length;
            //if(numRows > 0){


            // }
            // //var totalRecords =  this.state.cart[0].title;
            // const numRows = this.state.cart.length;
            // if(numRows < 1){
            //     $('#noRecordsFoundTR').css('display','block');
            // }
          })

        fetch(myConstClass.BASE_URL + 'cart/getAllMyCartDataForMagazine/' + user_id + '/' + getLanguage())
          .then(response => response.json())
          .then(response => {
            this.setState({ arrMagazine: response })
            //var totalRecords =  this.state.cart[0].title;
            const numRows = this.state.arrMagazine.length;
            //alert(numRows);
            if (numRows > 0) {
              this.state.magazineinfo.totalMagazinePrice = this.state.arrMagazine[0].total_donation_contribution;
            } else {
              // console.log(this.state.arrMagazine[0].total_donation_contribution);
              //alert('here');
              $('.magazineTable').css('display', 'none');
            }
          })

          .catch(err => console.log(err))
      }

    }

    const answer_array = this.props.location.pathname.split('/');
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }


  changeArrowDirection(event) {
    if ($("#" + event.target.id).hasClass('rotate180')) {
      $("#" + event.target.id).addClass('rotate0');
      $("#" + event.target.id).removeClass('rotate180');
    } else {
      $(".image_main_carosal").addClass('rotate0');
      $(".image_main_carosal").removeClass('rotate180');

      $("#" + event.target.id).addClass('rotate180');
      $("#" + event.target.id).removeClass('rotate0');
    }


    const checkoutHeaderTop = document.getElementById('checkoutHeader').getBoundingClientRect().top;
    setTimeout(() => {
      window.scrollTo({
        top: checkoutHeaderTop,
        behavior: "smooth",
      })
    }, 250)

  }

  render() {
    const { termsinfo, magazineinfo, arrMagazine, removeinfo, cartMachineTestEvent, cartEvent, posts, guestinfo, cart, eventinfo, cartTotal, eventinfoarray, guestinfoarray } = this.state
    const { t } = this.props;
    let ship_charg_value;
    ship_charg_value = parseFloat(this.state.cartTotal.cart_total_price).toFixed(2);
    if (ship_charg_value) {
      ship_charg_value = ship_charg_value;
    } else {
      ship_charg_value = "0.00";
    }


    return (

      <div className="App">
        <div>
          {/* <button onClick={this.notify}>Notify !</button> */}
          <ToastContainer />

        </div>
        <Header />
        <div className="container-fluid">
          <Helmet>
            <script src="https://www.google.com/recaptcha/api.js" async defer></script>

          </Helmet>
          <div className="row">
            <div className="col-lg-12">
              <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb bg-white mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/">{t('home.home')}</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/mycart">{t('home.Shopping Cart')}</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">{t('home.Checkout')}</li>

                  </ol>
                </nav>
              </div>
              <div className="row" id='checkoutHeader'>
                <div className="col-12">
                  <h5 style={{ fontSize: '20px', }}>{t('home.CHECKOUT')}</h5>
                </div>
              </div>
              <div className="row" >
                <div className="col-lg-10">
                  <div id="accordion">
                    <div className="card my-3">
                      <div className="card-header bg-dark-grey-old" id="headingOne" aria-expanded="false" aria-controls="collapseOne">
                        <h5 className="mb-0">
                          {t('home.ITEMS IN THE CART')}

                        </h5>

                        <img
                          id="arrowOne"
                          onClick={this.changeArrowDirection}
                          style={{ height: '20px', width: '20px', float: 'right', marginTop: '-20px', cursor: 'pointer', cursor: 'pointer', cursor: 'pointer', cursor: 'pointer', cursor: 'pointer', cursor: 'pointer', cursor: 'pointer' }} className="image_main_carosal" src={checkoutImage}
                          data-toggle="collapse"
                          data-target="#collapseOne" />

                      </div>

                      <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="p-0 d-xl-block d-lg-block d-none">

                          <table className="table table-bordered">


                            {cartMachineTestEvent.map((infos, i) => {
                              return (
                                <tbody>
                                  {/* <tr>
                                                <th colspan="12" style={{border:'none'}}>{t('home.Participants details for')} &nbsp; {infos.event_type}</th>
                                            </tr> */}

                                  {infos.arr_cart.map((cella, ii) => {
                                    return (
                                      <tbody>
                                        <tr className="bg-light">
                                          <th colspan="4" style={{ width: '56%' }}>{t('home.TESTS')}</th>
                                          <th colspan="3">{t('home.LOCATION')}</th>
                                          {/* <th colspan="3">{t('home.Price')}</th> */}
                                          <th colspan="2">{t('home.Qty')}</th>
                                          <th colspan="3">{t('home.Donation Contribution')}</th>
                                        </tr>
                                        <tr className=" bg-extra-light">
                                          <th colspan="1">{i + 1}</th>
                                          <th colspan="3">{cella.title}</th>

                                          <th colspan="3">
                                            {/* {cell.venue_address} */}
                                            {t('home.Lonavla')}
                                          </th>
                                          {/* <th colspan="3" style={{width:'16%'}}>
                                                    {t('home.INR')}&nbsp;{cell.first_price}/{cell.first_price_for}
                                        <br/>
                                        {t('home.INR')} &nbsp;{cell.second_price}/{cell.second_price_for}
                                                    </th> */}
                                          <th colspan="2" style={{ width: '16%' }}>{cella.parent * 1 + cella.child * 1}
                                          </th>
                                          <th colspan="3">
                                            {t('home.INR')}&nbsp;{(cella.parent * cella.first_price) + (cella.child * cella.second_price)}
                                          </th>
                                        </tr>
                                        <tr>
                                          <th colspan="12">{t('home.Participants details for')} &nbsp; {cella.title}</th>
                                        </tr>

                                        <tr>
                                          <th colspan="1">{t('home.Sr No')}</th>
                                          <th colspan="1">{t('home.First Name')}<span className=" color-red">*</span></th>
                                          <th colspan="1">{t('home.Middle Name')}</th>
                                          <th colspan="1">{t('home.Last Name')}<span className=" color-red">*</span></th>
                                          <th colspan="1">{t('home.Date of Birth')}<span className=" color-red">*</span></th>
                                          <th colspan="1">{t('home.Age')}<span className=" color-red">*</span></th>
                                          <th colspan="1">{t('home.Gender')}<span className=" color-red">*</span></th>
                                          <th colspan="1">{t('home.Mobile')}<span className=" color-red">*</span></th>
                                          <th colspan="2">{t('home.Email')}<span className=" color-red">*</span></th>
                                          {/* <th>{t('home.Donation Contribution')}</th> */}
                                          <th colspan="2">{t('home.Address')}<span className=" color-red">*</span></th>
                                        </tr>

                                        {cella.arr_participants.map((cellss, iii) => {
                                          if (cellss.age >= 21) {
                                            return (
                                              <tr>
                                                <td colspan="1">{iii + 1}</td>
                                                <td colspan="1">{cellss.first_name}</td>
                                                <td colspan="1">{cellss.middle_name}</td>
                                                <td colspan="1">{cellss.last_name}</td>
                                                <td colspan="1">{cellss.date_of_birth}</td>
                                                <td colspan="1">{cellss.age}</td>
                                                <td colspan="1">{cellss.gender}</td>
                                                <td colspan="1">{cellss.mobile}</td>
                                                <td colspan="2">{cellss.email}</td>
                                                {/* <td>
                                                                {t('home.INR')} {cellss.first_price}/-
                                                                </td> */}
                                                <td colspan="2">
                                                  {cellss.address}
                                                </td>
                                              </tr>

                                            )

                                          } else {
                                            return (
                                              <tr>
                                                <td colspan="1">{iii + 1}</td>
                                                <td colspan="1">{cellss.first_name}</td>
                                                <td colspan="1">{cellss.middle_name}</td>
                                                <td colspan="1">{cellss.last_name}</td>
                                                <td colspan="1">{cellss.date_of_birth}</td>
                                                <td colspan="1">{cellss.age}</td>
                                                <td colspan="1">{cellss.gender}</td>
                                                <td colspan="1">{cellss.mobile}</td>
                                                <td colspan="2">{cellss.email}</td>
                                                {/* <td>
                                                                {t('home.INR')} {cellss.second_price}/-
                                                                </td> */}
                                                <td colspan="2">{cellss.address}</td>
                                              </tr>
                                            )
                                          }
                                        })
                                        }

                                      </tbody>
                                    )
                                  })
                                  }

                                </tbody>
                              )
                            })
                            }


                          </table>

                          <table className="table table-bordered">


                            {cartEvent.map((info, i) => {
                              return (
                                <tbody>
                                  {/* <tr>
                                                <th colspan="12" style={{border:'none'}}>{t('home.Participants details for')} &nbsp; {info.event_type}</th>
                                            </tr> */}

                                  {info.arr_cart.map((cell, ii) => {
                                    return (
                                      <tbody>
                                        <tr className="bg-light">
                                          {/* <th colspan="4" style={{ width: '56%' }}>{t('home.STUDY COURSES')}</th> */}
                                          <th colspan="4" style={{ width: '56%' }}>{cell.event_type}</th>
                                          <th colspan="3">{t('home.LOCATION')}</th>
                                          {/* <th colspan="3">{t('home.Price')}</th> */}
                                          <th colspan="2">{t('home.Qty')}</th>
                                          <th colspan="3">{t('home.Donation Contribution')}</th>
                                        </tr>
                                        <tr className=" bg-extra-light">
                                          <th colspan="1">{i + 1}</th>
                                          <th colspan="3">{cell.title}</th>

                                          <th colspan="3">{cell.venue_address}</th>
                                          {/* <th colspan="3" style={{width:'16%'}}>
                                                    {t('home.INR')}&nbsp;{cell.first_price}/{cell.first_price_for}
                                        <br/>
                                        {t('home.INR')} &nbsp;{cell.second_price}/{cell.second_price_for}
                                                    </th> */}
                                          <th colspan="2" style={{ width: '16%' }}>{cell.parent * 1 + cell.child * 1}
                                          </th>
                                          <th colspan="3">
                                            {t('home.INR')}&nbsp;{(cell.parent * cell.first_price) + (cell.child * cell.second_price)}
                                          </th>
                                        </tr>
                                        <tr>
                                          <th colspan="12">{t('home.Participants details for')} &nbsp; {cell.title}</th>
                                        </tr>

                                        <tr>
                                          <th colspan="1">{t('home.Sr No')}</th>
                                          <th colspan="1">{t('home.First Name')}<span className=" color-red">*</span></th>
                                          <th colspan="1">{t('home.Middle Name')}</th>
                                          <th colspan="1">{t('home.Last Name')}<span className=" color-red">*</span></th>
                                          <th colspan="1">{t('home.Date of Birth')}<span className=" color-red">*</span></th>
                                          <th colspan="1">{t('home.Age')}<span className=" color-red">*</span></th>
                                          <th colspan="1">{t('home.Gender')}<span className=" color-red">*</span></th>
                                          <th colspan="1">{t('home.Mobile')}<span className=" color-red">*</span></th>
                                          <th colspan="2">{t('home.Email')}<span className=" color-red">*</span></th>
                                          {/* <th>{t('home.Donation Contribution')}</th> */}
                                          <th colspan="2">{t('home.Address')}<span className=" color-red">*</span></th>
                                        </tr>

                                        {cell.arr_participants.map((cells, iii) => {
                                          if (cells.age >= 21) {
                                            return (
                                              <tr>
                                                <td colspan="1">{iii + 1}</td>
                                                <td colspan="1">{cells.first_name}</td>
                                                <td colspan="1">{cells.middle_name}</td>
                                                <td colspan="1">{cells.last_name}</td>
                                                <td colspan="1">{cells.date_of_birth}</td>
                                                <td colspan="1">{cells.age}</td>
                                                <td colspan="1">{cells.gender}</td>
                                                <td colspan="1">{cells.mobile}</td>
                                                <td colspan="2">{cells.email}</td>
                                                {/* <td>
                                                                {t('home.INR')} {cells.first_price}/-
                                                                </td> */}
                                                <td colspan="2">{cells.address}</td>
                                              </tr>

                                            )

                                          } else {
                                            return (
                                              <tr>
                                                <td colspan="1">{iii + 1}</td>
                                                <td colspan="1">{cells.first_name}</td>
                                                <td colspan="1">{cells.middle_name}</td>
                                                <td colspan="1">{cells.last_name}</td>
                                                <td colspan="1">{cells.date_of_birth}</td>
                                                <td colspan="1">{cells.age}</td>
                                                <td colspan="1">{cells.gender}</td>
                                                <td colspan="1">{cells.mobile}</td>
                                                <td colspan="2">{cells.email}</td>
                                                {/* <td>
                                                                {t('home.INR')} {cells.second_price}/-
                                                                </td> */}
                                                <td colspan="2">{cells.address}</td>
                                              </tr>
                                            )
                                          }
                                        })
                                        }

                                      </tbody>
                                    )
                                  })
                                  }

                                </tbody>
                              )
                            })
                            }


                          </table>

                          <table className="table table-bordered productTable">
                            <thead clas="productTableThead">

                              <tr>
                                <th colspan="12" style={{ border: 'none' }}>{t('home.Products')} </th>
                              </tr>
                              <tr className="bg-light">
                                <th colspan="2">{t('home.Sr No')}</th>
                                <th colspan="2">{t('home.Category')}</th>
                                <th colspan="2">{t('home.Product Name')}</th>
                                {/* <th colspan="3">{t('home.Trust')}</th> */}
                                <th colspan="2">{t('home.Author')}</th>
                                <th colspan="2">{t('home.Qty')}</th>
                                <th colspan="2">{t('home.Donation Contribution')}</th>
                              </tr>
                            </thead>

                            {cart.map((info, ii) => {
                              return (
                                <tbody>
                                  <tr className=" bg-extra-light" id={'TR' + info.product_id}>
                                    <th colspan="2">{ii + 1}</th>
                                    <th colspan="2">{info.type_name}</th>
                                    <th colspan="2">{info.title}</th>
                                    {/* <th colspan="3">{info.trust_name}</th> */}
                                    <th colspan="2">{info.author}</th>
                                    <th colspan="2">{info.quantity}</th>
                                    <th colspan="2">{t('home.INR')} {info.price}/-</th>
                                  </tr>
                                </tbody>
                              )

                            })
                            }

                            <tr className=" bg-extra-light" id="productTotalPrice">

                              <th colspan="10"><span style={{ float: 'right', textAlign: 'right' }}>{t('home.Total')}</span></th>
                              <th colspan="2">{t('home.INR')} {this.state.guestinfo.product_total_price}/-</th>
                            </tr>
                            <tbody>

                              {/* <thead  className="bg-light text-center" style={{display:'none'}}> */}
                              <tr id="noRecordsFoundTR" style={{ display: 'none', width: '140%' }}>
                                <th style={{ borderRight: 'none' }} colspan="12">
                                  {t('home.No Items in the Cart')}.
                                  <Link style={{ cursor: 'pointer', color: 'rgb(0, 123, 255)', borderRight: 'none' }} to={"/products"}>
                                    {t('home.Continue browsing')}
                                  </Link>
                                </th>


                              </tr>
                            </tbody>


                          </table>

                          <table className="table table-sm table-bordered magazineTable">
                            <thead className="bg-light border-0" id="magazineItemThead">
                              <td className="border-0 font-weight-bold p-2 " colspan="6">{t('home.Magazine')}</td>

                            </thead>
                            <thead className="bg-light text-center" >
                              <tr>
                                <th>#</th>
                                <th scope="col"><span className="float-left d-inline-flex ">{t('home.Magazine')}</span>

                                  {/* <a className="float-right font-weight-normal" style={{cursor:'pointer',textDecoration:'underline',color:'#007bff'}}  onClick={this.goToMagazine}>{t('home.Click here to Add more Magazine')}</a> */}


                                </th>
                                <th scope="col">{t('home.Package')}</th>
                                <th scope="col">{t('home.Subscription')}</th>
                                <th scope="col">{t('home.Donation Contribution')}</th>

                                {/* <th scope="col">{t('home.Action')}</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {arrMagazine.map((info, iiiii) => {
                                return (

                                  <tr id={'MAGAZINETR' + info.package_id}>
                                    <td scope="row ">{iiiii + 1}</td>
                                    <td >
                                      {/* <Link to={"/magazine_details/"+info.magazine_id}>
                                        {info.magazine_title} <i className="fas fa-external-link-alt"></i>
                                        </Link> */}

                                      {info.magazine_title}

                                    </td>
                                    <td className="text-center">{info.package_title}</td>
                                    <td className="text-center">{info.subscription_type}</td>
                                    <td className="text-center">{t('home.INR')} {info.donation_contribution}/-
                                      {/* {guestinfo.totalMagazinePrice}{'magazinetotal'+magazineTotal}{'default'+totalMagazinePrice} */}
                                    </td>

                                    {/* <td className="text-center" ><i name={info.package_id} id={info.package_id} onClick={this.removeMagazineFromCart} className="fa fa-trash-alt" style={{cursor:'pointer'}} ></i></td> */}
                                  </tr>

                                )
                              })
                              }
                              <tr className=" bg-extra-light" >

                                <th colspan="4"><span style={{ float: 'right', textAlign: 'right' }}>{t('home.Total')}</span></th>
                                <th>{t('home.INR')}
                                  {/* {magazineinfo.totalMagazinePrice} */}
                                  {arrMagazine.map((infos, iiiiv) => {
                                    if (iiiiv == 0) {
                                      return (
                                        <span>
                                          {infos.total_donation_contribution}
                                        </span>
                                      )
                                    }

                                  })
                                  }
                                  {/* {magazineinfo.totalMagazinePrice} */}
                                  {/* {this.getTotal()} */}
                                  /-</th>
                              </tr>
                            </tbody>
                          </table>

                        </div>

                        {/* Mobile Design */}
                        <div className="d-xl-none d-lg-none  d-inline-block px-3">

                          {/* Machine Test */}
                          {cartMachineTestEvent.map((infos, i) =>
                            <Row key={infos.event_type_id} className="">
                              <Col sm="12" xs="12" className="bg-light py-2">
                                <h6>
                                  {infos.event_type}
                                </h6>
                              </Col>
                              <Col sm="12" xs="12">
                                {infos.arr_cart.map((cella) =>
                                  <Row className="border-bottom">
                                    <Col sm="12" xs="12" className="font-weight-bold py-2">
                                      {cella.title}
                                    </Col>
                                    <Col sm="12" xs="12">
                                      <Label className="font-weight-bold">
                                        {t('home.LOCATION')}&nbsp;:&nbsp;
                                      </Label>
                                      <Label>
                                        {t('home.Lonavla')}
                                      </Label>
                                    </Col>

                                    <Col sm="12" xs="12" >
                                      <Label className="font-weight-bold">
                                        {t('home.Qty')}&nbsp;:&nbsp;
                                      </Label>
                                      <Label>
                                        {cella.parent * 1 + cella.child * 1}
                                      </Label>
                                    </Col>
                                    <Col sm="12" xs="12">
                                      <Label className="font-weight-bold">
                                        {t('home.Donation Contribution')}&nbsp;:&nbsp;
                                      </Label>
                                      <Label>
                                        {t('home.INR')}&nbsp;{(cella.parent * cella.first_price) + (cella.child * cella.second_price)}
                                      </Label>
                                    </Col>

                                    <Col sm="12" xs="12" className="font-weight-bold">
                                      Participants
                                    </Col>
                                    {
                                      cella.arr_participants.map((cellss, iii) =>
                                        <Col sm="12" xs="12" keys={cellss.participant_id} className="py-2 border-bottom">
                                          <Row>
                                            <Col sm="12" xs="12">
                                              <Label className="font-weight-bold">
                                                {t('home.First Name')}&nbsp;:&nbsp;
                                              </Label>
                                              <Label>
                                                {cellss.first_name}&nbsp;
                                              </Label>
                                            </Col>
                                            <Col sm="12" xs="12">
                                              <Label className="font-weight-bold">
                                                {t('home.Middle Name')}&nbsp;:&nbsp;
                                              </Label>
                                              <Label>
                                                {cellss.middle_name}&nbsp;
                                              </Label>
                                            </Col>
                                            <Col sm="12" xs="12">
                                              <Label className="font-weight-bold">
                                                {t('home.Last Name')}&nbsp;:&nbsp;
                                              </Label>
                                              <Label>
                                                {cellss.last_name}&nbsp;
                                              </Label>
                                            </Col>
                                            <Col sm="12" xs="12">
                                              <Label className="font-weight-bold">
                                                {t('home.Date of Birth')}&nbsp;:&nbsp;
                                              </Label>
                                              <Label>
                                                {cellss.date_of_birth}
                                              </Label>
                                            </Col>
                                            <Col sm="12" xs="12">
                                              <Label className="font-weight-bold">
                                                {t('home.Age')}&nbsp;:&nbsp;
                                              </Label>
                                              <Label>
                                                {cellss.age}
                                              </Label>
                                            </Col>
                                            <Col sm="12" xs="12">
                                              <Label className="font-weight-bold">
                                                {t('home.Gender')}&nbsp;:&nbsp;
                                              </Label>
                                              <Label>
                                                {cellss.gender}
                                              </Label>
                                            </Col>
                                            <Col sm="12" xs="12">
                                              <Label className="font-weight-bold">
                                                {t('home.Mobile')}&nbsp;:&nbsp;
                                              </Label>
                                              <Label>
                                                {cellss.mobile}
                                              </Label>
                                            </Col>
                                            <Col sm="12" xs="12">
                                              <Label className="font-weight-bold">
                                                {t('home.Email')}&nbsp;:&nbsp;
                                              </Label>
                                              <Label>
                                                {cellss.email}
                                              </Label>
                                            </Col>
                                            <Col sm="12" xs="12">
                                              <Label className="font-weight-bold">
                                                {t('home.Address')}&nbsp;:&nbsp;
                                              </Label>
                                              <Label>
                                                {cellss.address}
                                              </Label>
                                            </Col>
                                          </Row>

                                        </Col>

                                      )
                                    }
                                  </Row>
                                )
                                }
                              </Col>
                            </Row>

                          )
                          }

                          {/* Events */}
                          {cartEvent.map((infos, i) =>
                            <Row key={infos.event_type_id} className="">
                              <Col sm="12" xs="12" className="bg-light py-2">
                                <h6>
                                  {infos.event_type}
                                </h6>
                              </Col>
                              <Col sm="12" xs="12">
                                {infos.arr_cart.map((cella) =>
                                  <Row className="border-bottom">
                                    <Col sm="12" xs="12" className="font-weight-bold py-2">
                                      {cella.title}
                                    </Col>
                                    <Col sm="12" xs="12">
                                      <Label className="font-weight-bold">
                                        {t('home.LOCATION')}&nbsp;:&nbsp;
                                      </Label>
                                      <Label>
                                        {cella.venue_address}
                                      </Label>
                                    </Col>
                                    <Col sm="12" xs="12" >
                                      <Label className="font-weight-bold">
                                        {t('home.Qty')}&nbsp;:&nbsp;
                                      </Label>
                                      <Label>
                                        {cella.parent * 1 + cella.child * 1}
                                      </Label>
                                    </Col>
                                    <Col sm="12" xs="12">
                                      <Label className="font-weight-bold">
                                        {t('home.Donation Contribution')}&nbsp;:&nbsp;
                                      </Label>
                                      <Label>
                                        {t('home.INR')}&nbsp;{(cella.parent * cella.first_price) + (cella.child * cella.second_price)}
                                      </Label>
                                    </Col>

                                    <Col sm="12" xs="12" className="font-weight-bold">
                                      Participants
                                    </Col>
                                    {
                                      cella.arr_participants.map((cellss, iii) =>
                                        <Col sm="12" xs="12" keys={cellss.participant_id} className="py-2">
                                          {cellss.first_name}&nbsp;
                                          {cellss.middle_name}&nbsp;
                                          {cellss.last_name}&nbsp;
                                        </Col>

                                      )
                                    }
                                  </Row>
                                )
                                }
                              </Col>
                            </Row>
                          )
                          }

                          {/* Products */}
                          {cart.length ? <Row>
                            <Col sm="12" xs="12" className="bg-light py-2">
                              <h6>
                                Products
                              </h6>
                            </Col>
                            {cart.map((info, i) =>
                              <Col sm="12" xs="12" className="">
                                <Row className="border-bottom">
                                  <Col sm="12" xs="12" className="font-weight-bold py-2">
                                    {info.title}
                                  </Col>
                                  <Col sm="12" xs="12">
                                    <Label className="font-weight-bold">
                                      {t('home.Category')}&nbsp;:&nbsp;
                                    </Label>
                                    <Label>
                                      {info.type_name}
                                    </Label>
                                  </Col>
                                  <Col sm="12" xs="12" >
                                    <Label className="font-weight-bold">
                                      {t('home.Qty')}&nbsp;:&nbsp;
                                    </Label>
                                    <Label>
                                      {info.quantity}
                                    </Label>
                                  </Col>
                                  <Col sm="12" xs="12" >
                                    <Label className="font-weight-bold">
                                      {t('home.Author')}&nbsp;:&nbsp;
                                    </Label>
                                    <Label>
                                      {info.author}
                                    </Label>
                                  </Col>
                                  <Col sm="12" xs="12" >
                                    <Label className="font-weight-bold">
                                      {t('home.Donation Contribution')}&nbsp;:&nbsp;
                                    </Label>
                                    <Label>
                                      {t('home.INR')} {info.price}/-
                                    </Label>
                                  </Col>

                                </Row>
                              </Col>

                            )
                            }
                          </Row> : (null)
                          }

                          {/* Magazine */}
                          {arrMagazine.length ? <Row>
                            <Col sm="12" xs="12" className="bg-light py-2">
                              <h6>
                                {t('home.Magazine')}
                              </h6>
                            </Col>
                            {arrMagazine.map((info, i) =>
                              <Col sm="12" xs="12" className="">
                                <Row className="border-bottom">
                                  <Col sm="12" xs="12" className="font-weight-bold py-2">
                                    {info.magazine_title}
                                  </Col>
                                  <Col sm="12" xs="12">
                                    <Label className="font-weight-bold">
                                      {t('home.Package')}&nbsp;:&nbsp;
                                    </Label>
                                    <Label>
                                      {info.package_title}
                                    </Label>
                                  </Col>
                                  <Col sm="12" xs="12" >
                                    <Label className="font-weight-bold">
                                      {t('home.Subscription')}&nbsp;:&nbsp;
                                    </Label>
                                    <Label>
                                      {info.subscription_type}
                                    </Label>
                                  </Col>
                                  <Col sm="12" xs="12" >
                                    <Label className="font-weight-bold">
                                      {t('home.Donation Contribution')}&nbsp;:&nbsp;
                                    </Label>
                                    <Label>
                                      {t('home.INR')} {info.donation_contribution}/-
                                    </Label>
                                  </Col>

                                </Row>
                              </Col>

                            )
                            }
                          </Row> : (null)
                          }
                        </div>
                      </div>
                    </div>
                    <div className="card my-3">
                      <div className="card-header bg-dark-grey-old collapsed" id="headingTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <h5 className="mb-0">
                          {t('home.BILLING & SHIPPING ADDRESS')}
                        </h5>
                        {/* <span  style={{fontSize: '11px',background: 'white', padding: '0px 6px', borderRadius: '54%', marginTop: '-17px', marginLeft: '94%', position: 'absolute'}}>
                                    <div className="arrow-down" style={{width:'0px',height:'0px',borderLeft:'6px solid transparent',borderRight:'6px solid transparent',borderTop:'8px solid #9d0b0e',marginTop:'6px',marginBottom:'3px'}}></div>
                                    </span> */}

                        <img
                          onClick={this.changeArrowDirection}
                          id="arrowTwo"
                          style={{ height: '20px', width: '20px', float: 'right', marginTop: '-20px', cursor: 'pointer' }} className="image_main_carosal" src={checkoutImage} data-toggle="collapse" data-target="#collapseTwo" />

                      </div>
                      <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div className="card-body">
                          <div className="row">
                            {guestinfoarray.map((info, ii) => {
                              return (
                                <div className="col-12 col-lg-6 border-right">
                                  <h5 className="modal-title mb-3" id="exampleModalLabel">{t('home.BILLING ADDRESS')}</h5>

                                  <div className="row">
                                    <div className="form-group col-12 col-md-4">
                                      <label className="color-black font-weight-bolder">{t('home.First Name')}<span className="color-red">*</span></label>
                                      <input type="text" className="form-control form-control-sm display-input" value={info.first_name} disabled />
                                    </div>
                                    <div className="form-group col-12 col-md-4">
                                      <label className="color-black font-weight-bolder">{t('home.Middle Name')}</label>
                                      <input type="text" className="form-control form-control-sm display-input" value={info.middle_name} disabled />
                                    </div>
                                    <div className="form-group col-12 col-md-4">
                                      <label className="color-black font-weight-bolder">{t('home.Last Name')}<span className="color-red">*</span></label>
                                      <input type="text" className="form-control form-control-sm display-input" value={info.last_name} disabled />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-12 col-md-12">
                                      <label className="color-black font-weight-bolder">{t('home.Company / Institution / Organization Name')}</label>
                                      <input type="text" className="form-control form-control-sm display-input" value={info.company} disabled />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-12 col-md-6">
                                      <label className="color-black font-weight-bolder">{t('home.Address Line 1')}<span className="color-red">*</span></label>
                                      <input type="text" className="form-control form-control-sm display-input" value={info.address_line_1} disabled />
                                    </div>
                                    <div className="form-group col-12 col-md-6">
                                      <label className="color-black font-weight-bolder">{t('home.Address Line 2')}</label>
                                      <input type="text" className="form-control form-control-sm display-input" value={info.address_line_2} disabled />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-12 col-md-12">
                                      <label className="color-black font-weight-bolder">{t('home.Landmark')}</label>
                                      <input type="text" className="form-control form-control-sm display-input" value={info.landmark} disabled />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-12 col-md-6">
                                      <label className="color-black font-weight-bolder">{t('home.City')}<span className="color-red">*</span></label>
                                      <input type="text" className="form-control form-control-sm display-input" value={info.city_name} disabled />
                                    </div>
                                    <div className="form-group col-12 col-md-6">
                                      <label className="color-black font-weight-bolder">{t('home.State / Province')}<span className="color-red">*</span></label>
                                      <input type="text" className="form-control form-control-sm display-input" value={info.state_name} disabled />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-12 col-md-6">
                                      <label className="color-black font-weight-bolder">{t('home.Country')}<span className="color-red">*</span></label>
                                      <input type="text" className="form-control form-control-sm display-input" value={info.country_name} disabled />
                                    </div>
                                    <div className="form-group col-12 col-md-6">
                                      <label className="color-black font-weight-bolder">{t('home.Pincode')}<span className="color-red">*</span></label>
                                      <input type="text" className="form-control form-control-sm display-input" value={info.pincode} disabled />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-12 col-md-6">
                                      <label className="color-black font-weight-bolder">{t('home.Phone')}<span className="color-red">*</span></label>
                                      <input type="text" className="form-control form-control-sm display-input" value={info.phone} disabled />
                                    </div>
                                    <div className="form-group col-12 col-md-6">
                                      <label className="color-black font-weight-bolder">{t('home.Email')}<span className="color-red">*</span></label>
                                      <input type="text" className="form-control form-control-sm display-input" value={info.email} disabled />
                                    </div>
                                  </div>

                                </div>
                              )
                            })
                            }
                            <div className="col-12 col-lg-6">
                              <div className="row">
                                <div className="col-lg-6">
                                  <h5 className="modal-title mb-3" id="exampleModalLabel">{t('home.SHIPPING ADDRESS')}</h5>
                                </div>
                                {/* <div className="col-lg-8">
                                                        <div className="form-group form-check pl-0 pt-1">
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input form-control-sm" id="customCheck1" name="example1" />
                                                                <label className="custom-control-label remember-me" for="customCheck1">{t('home.Same as Billing Address')}</label>
                                                            </div>
                                                        </div>
                                                    </div> */}
                              </div>
                              {eventinfoarray.map((infoss, ii) => {
                                return (
                                  <div>
                                    <div className="row">
                                      <div className="form-group col-12 col-md-4">
                                        <label className="color-black font-weight-bolder">{t('home.First Name')}<span className="color-red">*</span></label>
                                        <input type="text" className="form-control form-control-sm display-input" value={infoss.first_name} disabled />
                                      </div>
                                      <div className="form-group col-12 col-md-4">
                                        <label className="color-black font-weight-bolder">{t('home.Middle Name')}</label>
                                        <input type="text" className="form-control form-control-sm display-input" value={infoss.middle_name} disabled />
                                      </div>
                                      <div className="form-group col-12 col-md-4">
                                        <label className="color-black font-weight-bolder">{t('home.Last Name')}<span className="color-red">*</span></label>
                                        <input type="text" className="form-control form-control-sm display-input" value={infoss.last_name} disabled />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="form-group col-12 col-md-12">
                                        <label className="color-black font-weight-bolder">{t('home.Company / Institution / Organization Name')}</label>
                                        <input type="text" className="form-control form-control-sm display-input" value={infoss.company} disabled />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="form-group col-12 col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.Address Line 1')}<span className="color-red">*</span></label>
                                        <input type="text" className="form-control form-control-sm display-input" value={infoss.address_line_1} disabled />
                                      </div>
                                      <div className="form-group col-12 col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.Address Line 2')}</label>
                                        <input type="text" className="form-control form-control-sm display-input" value={infoss.address_line_2} disabled />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="form-group col-12 col-md-12">
                                        <label className="color-black font-weight-bolder">{t('home.Landmark')}</label>
                                        <input type="text" className="form-control form-control-sm display-input" value={infoss.landmark} disabled />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="form-group col-12 col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.City')}<span className="color-red">*</span></label>
                                        <input type="text" className="form-control form-control-sm display-input" value={infoss.city_name} disabled />
                                      </div>
                                      <div className="form-group col-12 col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.State / Province')}<span className="color-red">*</span></label>
                                        <input type="text" className="form-control form-control-sm display-input" value={infoss.state_name} disabled />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="form-group col-12 col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.Country')}<span className="color-red">*</span></label>
                                        <input type="text" className="form-control form-control-sm display-input" value={infoss.country_name} disabled />
                                      </div>
                                      <div className="form-group col-12 col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.Pincode')}<span className="color-red">*</span></label>
                                        <input type="text" className="form-control form-control-sm display-input" value={infoss.pincode} disabled />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="form-group col-12 col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.Phone')}<span className="color-red">*</span></label>

                                        <input type="text" className="form-control form-control-sm display-input" value={infoss.phone} disabled />
                                      </div>
                                      <div className="form-group col-12 col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.Email')}<span className="color-red">*</span></label>
                                        <input type="text" className="form-control form-control-sm display-input" value={infoss.email} disabled />
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card my-3">
                      <div className="card-header bg-dark-grey-old collapsed" id="headingThree" aria-expanded="true" aria-controls="collapseThree">
                        <h5 className="mb-0">
                          {t('home.SHIPPING CHARGES')}
                        </h5>
                        <img
                          id="arrowThree"
                          style={{ height: '20px', width: '20px', float: 'right', marginTop: '-20px', cursor: 'pointer' }}
                          className={cart.length || arrMagazine.length ? "image_main_carosal rotate180" : "image_main_carosal"}
                          src={checkoutImage} data-toggle="collapse" data-target="#collapseThree" onClick={this.changeArrowDirection} />
                      </div>
                      <div id="collapseThree" className={cart.length || arrMagazine.length ? "collapse show" : "collapse"} aria-labelledby="headingThree" data-parent="#accordion">
                        {cart.map((info, iiiiiii) => {
                          if (iiiiiii == 0) {
                            if (this.state.guestinfo.total_weight_in_kg > 0 && this.state.guestinfo.total_weight_in_kg != undefined) {
                              return (
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-12" id="RegularTypeDiv"> <input style={{ width: '28px', height: '16px' }} type="radio" id="delivery_type" name="delivery_type" value="1"
                                      checked={this.state.delivery_type == 1}
                                      onChange={this.handleChangeDelivery}
                                    />
                                      {t('home.Regular')} &nbsp;
                                      {this.state.guestinfo.total_weight_in_kg}
                                      &nbsp;{t('home.kg in')} &nbsp;

                                      {/* {guestinfo.country_name} */}
                                      <strong id="allShippingCharges" style={{ fontSize: '1.25rem' }}>
                                        {t('home.INR')} {this.state.guestinfo.total_shipping_charges}/-

                                        {this.state.guestinfo.shipping_message}


                                        <label style={{ fontSize: '1.25rem', display: 'none' }} id="noShippingCharges">
                                          {t('home.No shipping charges.')}
                                        </label>
                                      </strong>

                                    </div>
                                    {/* <div className="col-6 text-right">


                                                    </div>
                                                    */}
                                  </div>

                                  <div className="row" id="total_shipping_charges_express_row" style={{ display: 'none' }}>
                                    <div className="col-12"> <input style={{ width: '28px', height: '16px' }} type="radio" id="delivery_type" name="delivery_type" value="2"
                                      checked={this.state.delivery_type == 2}
                                      onChange={this.handleChangeDelivery}
                                    />
                                      {t('home.Express')} &nbsp;

                                      {this.state.guestinfo.total_weight_in_kg}
                                      &nbsp;{t('home.kg in')} &nbsp;
                                      {/*  {this.state.guestinfo.country_name} */}

                                      <strong style={{ fontSize: '1.25rem' }}> {t('home.INR')}

                                        {this.state.guestinfo.total_shipping_charges_express}/-


                                        {this.state.guestinfo.shipping_message}
                                      </strong>

                                    </div>
                                    {/* <div className="col-6 text-right">
                                                          <h5>
                                                        {t('home.INR')}

                                                        {this.state.guestinfo.total_shipping_charges_express}/-
                                                        <br/>

                                                        {this.state.guestinfo.shipping_message}

                                                            </h5>

                                                    </div> */}
                                  </div>
                                </div>
                              )
                            } else {
                              return (
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-6" id="RegularTypeDiv"> <input style={{ width: '28px', height: '16px' }} type="radio" id="delivery_type" name="delivery_type" value="1"
                                      checked={this.state.delivery_type == 1}
                                      onChange={this.handleChangeDelivery}
                                    />
                                      {t('home.Regular')} &nbsp;
                                      {info.total_weight_in_kg} &nbsp;{t('home.kg in')} &nbsp; {info.country_name}
                                    </div>
                                    <div className="col-6 text-right">
                                      <h5 id="allShippingCharges">
                                        {t('home.INR')} {info.total_shipping_charges}/-
                                        <br />
                                        {info.shipping_message}

                                      </h5>
                                      <h5 id="noShippingCharges" style={{ display: 'none' }}>
                                        {t('home.No shipping charges.')}
                                      </h5>

                                    </div>
                                  </div>

                                  <div className="row" id="total_shipping_charges_express_row" style={{ display: 'none' }}>
                                    <div className="col-6"> <input style={{ width: '28px', height: '16px' }} type="radio" id="delivery_type" name="delivery_type" value="2"
                                      checked={this.state.delivery_type == 2}
                                      onChange={this.handleChangeDelivery}
                                    />
                                      {t('home.Express')} &nbsp;
                                      {info.total_weight_in_kg}
                                      &nbsp;{t('home.kg in')} &nbsp;
                                      {info.country_name}
                                    </div>
                                    <div className="col-6 text-right">
                                      <h5>
                                        {t('home.INR')}

                                        {info.total_shipping_charges_express}/-
                                        <br />

                                        {info.shipping_message}

                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          }
                        })
                        }
                        <div className="col-12 text-right" id="noShippingChargesEventOnly">

                          <h5 id="noShippingChargesMessage" >
                            {t('home.No shipping charges.')}
                          </h5>

                        </div>
                      </div>
                    </div>
                    <div className="card my-3">
                      <div className="card-header bg-dark-grey-old collapsed " id="headingFour" aria-expanded="false" aria-controls="collapseFour">
                        <h5 className="mb-0 totalIncludingShippingChargesClass">
                          {t('home.TOTAL (Including Shipping Charges)')}
                        </h5>
                        {/* <span  style={{fontSize: '11px',background: 'white', padding: '0px 6px', borderRadius: '54%', marginTop: '-17px', marginLeft: '94%', position: 'absolute'}}>
                                    <div className="arrow-down" style={{width:'0px',height:'0px',borderLeft:'6px solid transparent',borderRight:'6px solid transparent',borderTop:'8px solid #9d0b0e',marginTop:'6px',marginBottom:'3px'}}></div>
                                    </span> */}
                        <img
                          onClick={this.changeArrowDirection}
                          id="arrowFour"
                          style={{ height: '20px', width: '20px', float: 'right', marginTop: '-20px', cursor: 'pointer' }}
                          className={cart.length === 0 && arrMagazine.length == 0 ? "image_main_carosal rotate180" : "image_main_carosal"}
                          src={checkoutImage} data-toggle="collapse" data-target="#collapseFour" />

                      </div>
                      <div id="collapseFour" className={cart.length === 0 && arrMagazine.length == 0 ? "collapse show" : "collapse"} aria-labelledby="headingFour" data-parent="#accordion">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-6">
                              {/*  * {t('home.Service Charges will be added on Payment page')}.
                                      */}

                            </div>
                            <div className="col-6 text-right">
                              <h5>{t('home.INR')}
                                {eventinfo.cart_total_price}
                                {/* {this.cartTotalPrice()} */}
                                {/* {ship_charg_value} */}
                                /-</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card my-3 d-none">
                      <div className="card-header bg-dark-grey-old collapsed" id="headingSix" data-toggle="collapse" data-target="#collapseSix6" aria-expanded="false" aria-controls="collapseSix6">
                        <h5 className="mb-0">
                          {/* <a  target="_blank" href={myConstClass.BASE_URL+'terms/termsConditionsPDF/'+this.state.language}> */}
                          <a target="_blank" href={myConstClass.IMAGE_URL + 'invoices/' + this.state.languagePdf}>
                            {t('home.TERMS & CONDITIONS')}
                          </a>
                        </h5>
                        {/* <span  style={{fontSize: '11px',background: 'white', padding: '0px 6px', borderRadius: '54%', marginTop: '-17px', marginLeft: '94%', position: 'absolute'}}>
                                                  <div className="arrow-down" style={{width:'0px',height:'0px',borderLeft:'6px solid transparent',borderRight:'6px solid transparent',borderTop:'8px solid #9d0b0e',marginTop:'6px',marginBottom:'3px'}}></div>
                                                </span>
                                                <img style={{ height: '20px', width: '20px', float: 'right', marginTop: '-20px', cursor: 'pointer' }} className="image_main_carosal" src={checkoutImage} /> */}

                      </div>

                      <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                        <div className="card-body">
                          <div className="row" style={{ marginTop: '-10px' }}>
                            <div className="col-12">
                              <div className="form-group">
                                {termsinfo.title}<br />
                                {ReactHtmlParser(termsinfo.description)}
                                {/* <label for="exampleFormControlTextarea1">{t('home.Use this area for special instructions or questions regarding your order')}</label> */}

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <form id="captcha" name="captcha">
                      <div className="card my-3">
                        <div className="card-header bg-dark-grey-old collapsed" id="headingFive" aria-expanded="false" aria-controls="collapseFive">
                          <h5 className="mb-0">
                            {t('home.SPECIAL COMMENTS')}
                          </h5>
                          {/* <span  style={{fontSize: '11px',background: 'white', padding: '0px 6px', borderRadius: '54%', marginTop: '-17px', marginLeft: '94%', position: 'absolute'}}>
                                    <div className="arrow-down" style={{width:'0px',height:'0px',borderLeft:'6px solid transparent',borderRight:'6px solid transparent',borderTop:'8px solid #9d0b0e',marginTop:'6px',marginBottom:'3px'}}></div>
                                    </span> */}
                          <img style={{ height: '20px', width: '20px', float: 'right', marginTop: '-20px', cursor: 'pointer' }} className="image_main_carosal" src={checkoutImage} data-toggle="collapse" data-target="#collapseFive" />

                        </div>
                        <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion" >
                          <div className="card-body">
                            <div className="row" style={{ marginTop: '-10px' }}>
                              <div className="col-12">
                                <div className="form-group">
                                  <label for="exampleFormControlTextarea1">{t('home.Use this area for special instructions or questions regarding your order')}</label>
                                  <textarea className="form-control" name="exampleFormControlTextarea1" id="exampleFormControlTextarea1" rows="3" placeholder={t('home.Enter your comments')}></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="col border p-3">
                            <div className="custom-control custom-checkbox my-1 mr-sm-2">
                              {/* <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                            <label className="custom-control-label" for="customControlInline">{t('home.I am not a robot')}</label> */}


                              <div className="g-recaptcha" data-sitekey="6Lczr9EUAAAAAGrmJxhRbIE2F4gvajaG1dxu8gQM"></div>
                              <input type="submit" name="btn_submit_captcha" id="btn_submit_captcha" style={{ display: 'none' }} />

                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="row my-3">
                      <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                        <button id="btn_click" name="btn_click" className="btn btn-sm btn-brown btn-block" onClick={this.proceedToPayment}>{t('home.PROCEED TO PAYMENT')}</button>
                        <img style={{ display: 'none' }} id="loader" name="loader" src={myConstClass.IMAGE_URL + 'loader.gif'} className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>


          <form method="post" id="redirect" name="redirect" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
            <input type='hidden' name='encRequest' value={removeinfo.encrypted_data} />
            <input type='hidden' name='access_code' value={removeinfo.access_code} />
            <input type="submit" name="btn_submit" id="btn_submit" style={{ display: 'none' }} />
          </form>

        </div>
        <Footer />
      </div>
    );
  }
}
Checkout.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(Checkout);
