import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  fetchBillingAddressById,
  fetchCitiesByState,
  fetchCountries,
  fetchStatesByCountry,
} from "./utils";
import { hasError, validateField, validateMessages } from "./validation";

const BillingFieldsEnum = {
  billing_first_name: "first_name",
  billing_middle_name: "middle_name",
  billing_last_name: "last_name",
  billing_company: "company",
  billing_address_line_1: "address_line_1",
  billing_address_line_2: "address_line_2",
  billing_landmark: "landmark",
  billing_country: "country",
  billing_country_id: "country_id",
  billing_country_id_fk: "country_id_fk",
  billing_state: "state",
  billing_state_id: "state_id",
  billing_state_id_fk: "state_id_fk",
  billing_city: "city",
  billing_city_id: "city_id",
  billing_city_id_fk: "city_id_fk",
  billing_pincode: "pincode",
  billing_phone: "phone",
  billing_email: "email",
};
const bfe = BillingFieldsEnum;

const billingInitialState = {
  first_name: "",
  middle_name: "",
  last_name: "",
  company: "",
  address_line_1: "",
  address_line_2: "",
  landmark: "",
  country: "",
  country_id: "",
  country_id_fk: "",
  state: "",
  state_id_fk: "",
  cite: "",
  city_id_fk: "",
  pincode: "",
  phone: "",
  email: "",
};

const BillingAddressForm = forwardRef(function BillingAddressForm(
  { billingAddress, sameAsShipping, onFormValueChange },
  ref
) {
  const [state, setState] = useState({ ...billingInitialState });
  const [errors, setErrors] = useState({ ...billingInitialState });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    (async () => {
      const [_billingAddress, _countries] = await Promise.all([
        fetchBillingAddressById(),
        fetchCountries(),
      ]);
      setCountries(_countries);

      if (!_billingAddress) return;
      setState(_billingAddress);
      onFormValueChange(_billingAddress);

      const { country_id_fk, state_id_fk, city_id_fk } = _billingAddress;
      const [_states, _cities] = await Promise.all([
        fetchStatesByCountry(country_id_fk),
        fetchCitiesByState(state_id_fk),
      ]);
      setStates(_states);
      setCities(_cities);
    })();
  }, []);

  useEffect(() => {
    setState(billingAddress);
  }, [billingAddress]);

  useEffect(() => {
    // console.log(billingAddress);

    (async () => {
      const [_states, _cities] = await Promise.all([
        fetchStatesByCountry(billingAddress.country_id_fk),
        fetchCitiesByState(billingAddress.state_id_fk),
      ]);
      setStates(_states);
      setCities(_cities);
    })();
  }, [billingAddress.country_id_fk, billingAddress.state_id_fk]);

  function handleInputOnChange(key, value) {
    const _state = { ...state, [key]: value };
    setState(_state);
    onFormValueChange(_state);
  }

  useImperativeHandle(ref, () => ({
    validate() {
      const _errors = {};
      _errors[bfe.billing_first_name] = validateField(
        state[bfe.billing_first_name],
        "First Name",
        {
          required: true,
          regex: {
            pattern: /^[A-Za-z]+$/,
            message: validateMessages.onlyAlphabets,
          },
        }
      );
      _errors[bfe.billing_last_name] = validateField(
        state[bfe.billing_last_name],
        "Last Name",
        {
          required: true,
          regex: {
            pattern: /^[A-Za-z]+$/,
            message: validateMessages.onlyAlphabets,
          },
        }
      );

      _errors[bfe.billing_address_line_1] = validateField(
        state[bfe.billing_address_line_1],
        "Address",
        { required: true, regex: null }
      );

      // console.log(
      //   state[bfe.billing_country_id],
      //   state[bfe.billing_state_id_fk],
      //   state[bfe.billing_city_id_fk]
      // );
      _errors[bfe.billing_country] = validateField(
        state[bfe.billing_country_id],
        "Country",
        { required: true, regex: null }
      );
      _errors[bfe.billing_state] = validateField(
        state[bfe.billing_state_id_fk],
        "State",
        { required: true, regex: null }
      );
      _errors[bfe.billing_city] = validateField(
        state[bfe.billing_city_id_fk],
        "City",
        { required: true, regex: null }
      );

      _errors[bfe.billing_pincode] = validateField(
        state[bfe.billing_pincode],
        "Pincode",
        {
          required: true,
          regex: { pattern: /^\d{4,6}$/, message: validateMessages.pincode },
        }
      );
      _errors[bfe.billing_phone] = validateField(
        state[bfe.billing_phone],
        "Phone",
        {
          required: true,
          regex: { pattern: /^\d{10}$/, message: validateMessages.phone },
        }
      );
      _errors[bfe.billing_email] = validateField(
        state[bfe.billing_email],
        "Email",
        {
          required: true,
          regex: {
            // pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: validateMessages.email,
          },
        }
      );

      setErrors(_errors);
      // console.log("Billing has errors", _errors, hasError(_errors));
      return !hasError(_errors);
    },
  }));

  return (
    <div>
      {/* <pre>{JSON.stringify({ errors }, null, 2)}</pre> */}
      <form className="row">
        <div className="col-lg-4 form-group">
          <label htmlFor="first_name" className="form-label fw-bold required">
            First Name
          </label>
          <input
            className={`form-control w-100 ${
              errors.first_name ? "is-invalid" : ""
            }`}
            placeholder="Enter First Name"
            id="first_name"
            disabled={sameAsShipping}
            value={state.first_name}
            onChange={(e) => {
              handleInputOnChange(bfe.billing_first_name, e.target.value);
            }}
          />
          {errors.first_name && (
            <div className="invalid-feedback">{errors.first_name}</div>
          )}
        </div>

        <div className="col-lg-4 form-group">
          <label htmlFor="middle_name" className="form-label fw-bold">
            Middle Name
          </label>
          <input
            className={`form-control w-100 ${
              errors.middle_name ? "is-invalid" : ""
            }`}
            placeholder="Enter Middle Name"
            id="middle_name"
            disabled={sameAsShipping}
            value={state.middle_name}
            onChange={(e) => {
              handleInputOnChange(bfe.billing_middle_name, e.target.value);
            }}
          />
          {errors.middle_name && (
            <div className="invalid-feedback">{errors.middle_name}</div>
          )}
        </div>

        <div className="col-lg-4 form-group">
          <label htmlFor="last_name" className=" form-label fw-bold required">
            Last Name
          </label>
          <input
            className={`form-control w-100 ${
              errors.last_name ? "is-invalid" : ""
            }`}
            placeholder="Enter Last Name"
            id="last_name"
            disabled={sameAsShipping}
            value={state.last_name}
            onChange={(e) => {
              handleInputOnChange(bfe.billing_last_name, e.target.value);
            }}
          />
          {errors.last_name && (
            <div className="invalid-feedback">{errors.last_name}</div>
          )}
        </div>

        <div className="col-12 form-group">
          <label htmlFor="company" className=" form-label fw-bold">
            Company / Institution / Organization Name
          </label>
          <input
            className={`form-control w-100 ${
              errors.company ? "is-invalid" : ""
            }`}
            placeholder="Enter Company Name"
            id="company"
            disabled={sameAsShipping}
            value={state.company}
            onChange={(e) => {
              handleInputOnChange(bfe.billing_company, e.target.value);
            }}
          />
          {errors.company && (
            <div className="invalid-feedback">{errors.company}</div>
          )}
        </div>

        <div className="col-12 form-group">
          <label
            htmlFor="address_line_1"
            className=" form-label fw-bold required"
          >
            Address Line 1
          </label>
          <input
            className={`form-control w-100 ${
              errors.address_line_1 ? "is-invalid" : ""
            }`}
            placeholder="Enter Flat No./House No."
            id="address_line_1"
            disabled={sameAsShipping}
            value={state.address_line_1}
            onChange={(e) => {
              handleInputOnChange(bfe.billing_address_line_1, e.target.value);
            }}
          />
          {errors.address_line_1 && (
            <div className="invalid-feedback">{errors.address_line_1}</div>
          )}
        </div>

        <div className="col-lg-6 form-group">
          <label htmlFor="address_line_2" className=" form-label fw-bold">
            Address Line 2
          </label>
          <input
            className={`form-control w-100 ${
              errors.address_line_2 ? "is-invalid" : ""
            }`}
            placeholder="Enter Apartment/Society Name"
            id="address_line_2"
            disabled={sameAsShipping}
            value={state.address_line_2}
            onChange={(e) => {
              handleInputOnChange(bfe.billing_address_line_2, e.target.value);
            }}
          />
          {errors.address_line_2 && (
            <div className="invalid-feedback">{errors.address_line_2}</div>
          )}
        </div>

        <div className="col-lg-6 form-group">
          <label htmlFor="landmark" className=" form-label fw-bold">
            Landmark
          </label>
          <input
            id="landmark"
            disabled={sameAsShipping}
            className={`form-control w-100 ${
              errors.landmark ? "is-invalid" : ""
            }`}
            placeholder="Enter Nearest Landmark"
            value={state.landmark}
            onChange={(e) => {
              handleInputOnChange(bfe.billing_landmark, e.target.value);
            }}
          />
          {errors.landmark && (
            <div className="invalid-feedback">{errors.landmark}</div>
          )}
        </div>

        <div className="col-lg-6 form-group">
          <label htmlFor="country" className="form-label fw-bold required">
            Country
          </label>
          <select
            className={`form-control w-100 ${
              errors.country ? "is-invalid" : ""
            }`}
            id="country"
            disabled={sameAsShipping}
            value={state?.country_id_fk}
            onChange={(e) => {
              const _state = {
                ...state,
                [bfe.billing_country_id]: e.target.value,
                [bfe.billing_country_id_fk]: e.target.value,
                [bfe.billing_country]: countries?.find(
                  (el) => el.id === e.target.value
                )?.name,

                [bfe.billing_state_id]: "",
                [bfe.billing_state_id_fk]: "",
                [bfe.billing_state]: "",

                [bfe.billing_city_id]: "",
                [bfe.billing_city_id_fk]: "",
                [bfe.billing_city]: "",
              };

              setState(_state);
              onFormValueChange(_state);

              fetchStatesByCountry(e.target.value).then((data) => {
                setStates(data);
              });
            }}
          >
            <option value="">Select Country</option>
            {countries?.map((country) => (
              <option key={country.id} value={country.id}>
                {country.name}
              </option>
            ))}
          </select>
          {errors.country && (
            <div className="invalid-feedback">{errors.country}</div>
          )}
        </div>

        <div className="col-lg-6 form-group">
          <label htmlFor="state" className="form-label fw-bold required">
            State / Province
          </label>
          <select
            className={`form-control w-100 ${errors.state ? "is-invalid" : ""}`}
            id="state"
            disabled={sameAsShipping}
            value={state?.state_id_fk}
            onChange={(e) => {
              const _state = {
                ...state,
                [bfe.billing_state_id]: e.target.value,
                [bfe.billing_state_id_fk]: e.target.value,
                [bfe.billing_state]: states?.find(
                  (el) => el.id === e.target.value
                )?.name,

                [bfe.billing_city_id]: "",
                [bfe.billing_city_id_fk]: "",
                [bfe.billing_city]: "",
              };

              setState(_state);
              onFormValueChange(_state);

              fetchCitiesByState(e.target.value).then((data) => {
                setCities(data);
              });
            }}
          >
            <option value="">Select State</option>
            {states?.map((state) => (
              <option key={state.id} value={state.id}>
                {state.name}
              </option>
            ))}
          </select>
          {errors.state && (
            <div className="invalid-feedback">{errors.state.message}</div>
          )}
        </div>

        <div className="col-lg-6 form-group">
          <label htmlFor="city" className="form-label fw-bold required">
            City
          </label>
          <select
            className={`form-control w-100 ${errors.city ? "is-invalid" : ""}`}
            id="city"
            disabled={sameAsShipping}
            value={state?.city_id_fk}
            onChange={(e) => {
              const _state = {
                ...state,
                [bfe.billing_city_id]: e.target.value,
                [bfe.billing_city_id_fk]: e.target.value,
                [bfe.billing_city]: states?.find(
                  (el) => el.id === e.target.value
                )?.name,
              };

              setState(_state);
              onFormValueChange(_state);
            }}
          >
            <option value="">Select City</option>
            {cities?.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
          {errors.city && (
            <div className="invalid-feedback">{errors.city.message}</div>
          )}
        </div>

        <div className="col-lg-6 form-group">
          <label htmlFor="pincode" className=" form-label fw-bold required">
            Pincode
          </label>
          <input
            className={`form-control w-100 ${
              errors.pincode ? "is-invalid" : ""
            }`}
            minLength={4}
            maxLength={6}
            placeholder="Enter Pincode"
            id="pincode"
            disabled={sameAsShipping}
            value={state.pincode}
            onChange={(e) => {
              handleInputOnChange(bfe.billing_pincode, e.target.value);
            }}
          />
          {errors.pincode && (
            <div className="invalid-feedback">{errors.pincode}</div>
          )}
        </div>

        <div className="col-lg-6 form-group">
          <label htmlFor="phone" className=" form-label fw-bold required">
            Phone Number
          </label>
          <input
            className={`form-control w-100 ${errors.phone ? "is-invalid" : ""}`}
            placeholder="Enter Phone No."
            id="phone"
            disabled={sameAsShipping}
            value={state.phone}
            onChange={(e) => {
              handleInputOnChange(bfe.billing_phone, e.target.value);
            }}
          />
          {errors.phone && (
            <div className="invalid-feedback">{errors.phone}</div>
          )}
        </div>

        <div className="col-lg-6 form-group">
          <label
           htmlFor="email"
           className=" form-label fw-bold required"
          >
            Email
          </label>

          <input
            className={`form-control w-100 ${errors.email ? "is-invalid" : ""}`}
            placeholder="Enter Email Address"
            id="email"
            type="email"
            disabled={sameAsShipping}
            value={state.email}
            onChange={(e) => {
              handleInputOnChange(bfe.billing_email, e.target.value);
            }}
          />
          {errors.email && (
            <div className="invalid-feedback">{errors.email}</div>
          )}
        </div>
      </form>
      {/* <pre>{JSON.stringify({ state }, null, 2)}</pre> */}
    </div>
  );
});

export default BillingAddressForm;
