/* The above code is a JavaScript code that sets up the routing for a React application using React
Router. It imports various components and sets up routes for each component. The routing is then
rendered using ReactDOM. The code also includes the necessary imports for Bootstrap, jQuery, and
Popper.js. Finally, it includes code for service worker registration, which is used for offline
functionality in Progressive Web Apps. */

import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router,
  Switch
} from "react-router-dom";

import { Provider } from "react-redux";
import { store } from "./store";

// import { ToastContainer } from "react-toastify";
import Aboutus from "./Aboutus";
import App from "./App";
import Audiogallery from "./Audiogallery";
import Booknow from "./Booknow";
import Branches from "./Branches";
import Centersinmaharashtra from "./Centersinmaharashtra";
import Centersoutsideindia from "./Centersoutsideindia";
import Centersoutsidemaharashtra from "./Centersoutsidemaharashtra";
import Changepassword from "./Changepassword";
import Checkout from "./Checkout";
import Contactus from "./Contactus";
import Deeds from "./Deeds";
import Donationcontribution from "./Donationcontribution";
import Donationcontributionlist from "./Donationcontributionlist";
import Download from "./Download";
import Eshopping from "./Eshopping";
import Event_calendar from "./Event_calendar";
import Event_calendar_grid from "./Event_calendar_grid";
import Event_details from "./Event_details";
import Events from "./Events";
import Faq from "./Faq";
import Feedback from "./Feedback";
import Forgotpassword from "./Forgotpassword";
import Interactions from "./Interactions";
import Logout from "./Logout";
import Machine_test from "./Machine_test";
import Magazine from "./Magazine";
import Magazine_details from "./Magazine_details";
import Mycart from "./Mycart";
import News_details from "./News_details";
import Orderdetails from "./Orderdetails";
import Orders from "./Orders";
import Organizeatrip from "./Organizeatrip";
import Participants from "./Participants";
import Photogallery from "./Photogallery";
import Product_type from "./Product_type";
import Products from "./Products";
import Users from "./Products_details";
import Profile from "./Profile";
import Research from "./Research";
import Research_details from "./Research_details";
import Researchmain from "./Researchmain";
import Resetpassword from "./Resetpassword";
import Signin from "./Signin";
import Signup from "./Signup";
import Sitemap from "./Sitemap";
import Sitesearch from "./Sitesearch";
import Subscription from "./Subscription";
import Topics from "./Topics";
import Verifyotp from "./Verifyotp";
import Videogallery from "./Videogallery";
import Wallpapergallery from "./Wallpapergallery";
import AddressPage from "./address";
import EventDetails from "./eventDetails";
import * as serviceWorker from "./serviceWorker";

import "./index.css";
import "./assets/css/rfs.css";
import "./assets/css/style.css";
import "./assets/fontawesome/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";

// import "./styles/bs-modal.css";
import "./assets/dist/js/bootstrap.bundle";
import FormValidator from "./FormValidation";
import PageNotFound from "./PageNotFound";

const routing = (
  <Provider store={store}>
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={App} />
          <Route path="/products_details" component={Users} />
          <Route path="/event_details" component={Event_details} />
          <Route path="/signup" component={Signup} />
          <Route path="/verifyotp" component={Verifyotp} />
          <Route path="/profile" component={Profile} />
          <Route path="/contact-us" component={Contactus} />
          <Route path="/branches" component={Branches} />
          <Route
            path="/centers-in-maharashtra"
            component={Centersinmaharashtra}
          />
          <Route
            path="/centers-outside-of-maharashtra"
            component={Centersoutsidemaharashtra}
          />
          <Route
            path="/centers-outside-india"
            component={Centersoutsideindia}
          />
          <Route path="/feedback" component={Feedback} />
          <Route path="/site-map" component={Sitemap} />
          <Route path="/site-search" component={Sitesearch} />
          <Route path="/change-password" component={Changepassword} />
          <Route path="/forgot-password" component={Forgotpassword} />
          <Route
            path="/donation-contribution"
            component={Donationcontribution}
          />
          <Route path="/reset-password" component={Resetpassword} />
          <Route path="/participants" component={Participants} />
          <Route
            path="/donation-contribution-list"
            component={Donationcontributionlist}
          />
          <Route path="/signin" component={Signin} />
          <Route path="/logout" component={Logout} />
          <Route path="/products" component={Products} />
          <Route path="/magazine" component={Magazine} />
          <Route path="/magazine_details" component={Magazine_details} />
          <Route path="/product_type" component={Product_type} />
          <Route path="/event-calendar" component={Event_calendar} />
          <Route path="/event-calendar-grid" component={Event_calendar_grid} />
          <Route path="/events" component={Events} />
          <Route path="/machine-test" component={Machine_test} />
          <Route path="/mycart" component={Mycart} />
          <Route path="/eventDetails" component={EventDetails} />
          <Route path="/address" component={AddressPage} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/magazine-subscription" component={Subscription} />
          <Route path="/order-history" component={Orders} />
          <Route path="/my-interactions" component={Interactions} />
          <Route path="/order-details" component={Orderdetails} />
          <Route path="/booknow" component={Booknow} />
          <Route path="/topics" component={Topics} />
          <Route path="/news" component={Research} />
          <Route path="/download" component={Download} />
          <Route path="/photo-gallery" component={Photogallery} />
          <Route path="/video-gallery" component={Videogallery} />
          <Route path="/wallpaper-gallery" component={Wallpapergallery} />
          <Route path="/audio-gallery" component={Audiogallery} />
          <Route path="/research" component={Researchmain} />
          <Route path="/news-details" component={News_details} />
          <Route path="/about-us" component={Aboutus} />
          <Route path="/cycle-of-good-deeds" component={Deeds} />
          <Route path="/faqs" component={Faq} />
          <Route path="/e-shopping-help" component={Eshopping} />
          <Route path="/organize-a-trip" component={Organizeatrip} />
          <Route path="/research-details" component={Research_details} />
          <Route path="/form-validator" component={FormValidator} />
          <Route component={PageNotFound} />
        </Switch>
      </div>
    </Router>
  </Provider>
);

ReactDOM.render(routing, document.getElementById("root"));
//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
