import React , { Component }from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { Editor} from 'react-draft-wysiwyg';
import { EditorState,convertToRaw,convertFromRaw  } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateToHTML} from 'draft-js-export-html'; 
import Header from './Header.js';
import Footer from './Footer.js';
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';

// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,getLanguage,
    translate,
  } from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row
  } from 'reactstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
class Resetpassword extends Component {
    constructor(props) {
      super(props);
      this.product_id=0;
      this.state = {
        isLoading: false,
        flag:0,
        guestinfo: {
            user_id: '',
            email: '',
            new_password: '',
            confirm_password: '',
            otp: '',
          },
          eventinfo: {
            old_password: '',
            new_password:'',
            confirm_password:'',
            email: '',
          },
          removeinfo:{
            message: '',
            status: '',
          },
          country:[],
          city:[],
          shipping_cityshipping:[],
          shipping_countrystate:[],
      }
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleResendOtp = this.handleResendOtp.bind(this);
    //   this.handleDayChange = this.handleDayChange.bind(this);
    //   this.handleToDayChange = this.handleToDayChange.bind(this);
      this.validator = new SimpleReactValidator();
      this.billing_type = "1";
  }

//   handleDayChange(day) { alert(day);
//     var utcDate = day.toLocaleDateString();
//     $('#birth_date').val(utcDate);
//     this.state.guestinfo.birth_date_old = utcDate;
    
//     this.setState({ selectedDay: day });
//   }

//   handleToDayChange(day) { //alert(day);
//     var utcDate = day.toLocaleDateString();
//     $('#end_date').val(utcDate);
//     this.state.eventinfo.end_date = utcDate;
//     this.setState({ selectedDay: day });
//   }

  componentDidMount() {
      var message =  localStorage.getItem('success');
      var message1 = 'OTP is successfully send on your email id,so please check it.';
      if(message != '' && message != null ){
        toast(''+message1, {containerId: 'B',type: toast.TYPE.SUCCESS});
        localStorage.removeItem('success');
      }
    const answer_array = this.props.location.pathname.split('/');
    var user_id = localStorage.getItem('user_id');
    var email = localStorage.getItem('reset_password_email');
    $('#email').val(email);
    //alert(email);
    this.state.eventinfo.email = email;
    // console.log(this.state.eventinfo.email);
    if(email != '' && email != undefined){
      // setTimeout(this.props.history.push('/'),10);
    }else{
          setTimeout(this.props.history.push('/'),10);
    }
  }

  componentDidUpdate(prevProps) {
      const answer_array = this.props.location.pathname.split('/');
      const { flag} = this.state
  }

  handleSubmit(e) {
    e.preventDefault();
    var user_id = localStorage.getItem('user_id');
    // console.log('this.refs',$('#pform').serializeArray());

    const { new_password, confirm_password } = this.state.guestinfo;
    // console.log('password: ',new_password, confirm_password);

    const isPassword = /^(?!.*\s)(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/.test(new_password);

    if (this.validator.allValid() && isPassword) {
        const formData = {};
        for (const field in this.refs) {
        formData[field] = this.refs[field].value;
        }
        // console.log('-->', formData);
        fetch(myConstClass.BASE_URL+`users/resetPasswordFrontEnd`, {
        method: 'POST',
        body: JSON.stringify($('#pform').serializeArray()),

        })
        .then(response => response.json())
        .then(response =>{this.setState({guestinfo: response})
        var message =  this.state.guestinfo.message;
        var status =  this.state.guestinfo.status;
        //console.log('hii'+this.state.guestinfo);
        if(status == 'true'){
        // $('#address').modal('hide');
        // $('#pform')[0].reset();

            
          var message = "Your password has been changed successfully.";
          localStorage.setItem('success', message);
          toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});
          // setTimeout(this.props.history.push('/signin'),100);
          setTimeout(this.props.history.replace('/signin'), 100);
        }else{
            toast(''+message, {containerId: 'B',type: toast.TYPE.ERROR});
        }
        //console.log(status);
        })  
        .catch(err => console.log(err))
        }
    else{
      if (!isPassword) {
        toast.error("Invalid password format. Password must be at least 8 characters long, contain at least one letter, one digit, and no spaces")
      } else {
      toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR})
      }
     //alert('here');
     this.validator.showMessages();
     console.log('validations'+this.validator.showMessages());
     e.preventDefault();
    // toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR})
    }
  }

  handleResendOtp() {
    const email = localStorage.getItem("reset_password_email");
    const emailArr = [{ name: "email", value: email }];
    // console.log('email: ',emailArr);
    this.setState({ isLoading: true });

    fetch(myConstClass.BASE_URL+`users/forgotPassword/`, {
      method: "POST",
      body: JSON.stringify(emailArr)
    })
    .then((res) => res.json())
    .then((res) => {
      this.setState({ isLoading: false });
      // console.log('res: ',res)
      toast.success(res.message);
    })
    .catch((error) => console.log('error: ',error))
  }

  render() {
      
    const {country,shipping_countrystate,city,eventinfo, posts,guestinfo,toast} = this.state  
    const { t } = this.props;
  return (
    
    <div className="App">
         <div>
        {/* <button onClick={this.notify}>Notify !</button> */}
          <ToastContainer />
        </div>
    <Header />
    <div class="container-fluid">
   
        <div class="row" style={{marginTop:'10px'}}>
            <div class="col-lg-12 ">
                <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb bg-white mb-0">
                            <li class="breadcrumb-item">
                            <Link   to="/">{t('home.home')}</Link>
                              </li>
                            {/* <li class="breadcrumb-item"><a href="#">{t('home.My Account')}</a></li> */}
                            <li class="breadcrumb-item active" aria-current="page">{t('home.Reset Password')}</li>
                        </ol>
                    </nav>
                </div>
                <div class="row">
                    <div class="col">
                        <h4>{t('home.Reset Password')}</h4>
                        <p><span class="color-red">* {t('home.Mandatory Fields')}</span></p>
                    </div>
                </div>
            </div>

            <div class="col col-lg-10 ">
                {/* <form> */}
                <Form action="" id="pform" onSubmit={this.handleSubmit}  method="post" encType="multipart/form-data" className="form-horizontal">

                <div class="row" style={{marginTop:'-10px',display:'none'}}>
                        <div class="form-group col col-md-4">
                            <label class="color-black font-weight-bolder">{t('home.Registered Email ID')}<span class="color-red">*</span></label>
                            <input value={eventinfo.email}    type="hidden" id="email" name="email" class="form-control" />
                        </div>
                </div>

                {/* <div class="row" style={{marginTop:'-10px'}}>
                        <div class="form-group col col-md-4">
                            <label class="color-black font-weight-bolder">{t('home.Existing Password')}<span class="color-red">*</span></label>
                            
                            <input value={guestinfo.old_password}  onChange={e => {this.setState({ guestinfo: {...guestinfo, old_password:e.target.value}})}} onBlur={() => this.validator.showMessageFor('old_password')} type="text" id="old_password" name="old_password" class="form-control" placeholder={t('home.Enter your password')} />
                            {this.validator.message('old_password', this.state.guestinfo.old_password, 'required')}
                        </div>
                    </div> */}

                    <div class="row" style={{marginTop:'-10px'}}>
                        <div class="form-group col col-md-4">
                            <label class="color-black font-weight-bolder">{t('home.OTP')}<span class="color-red">*</span></label>
                            {/* <input onBlur={() => this.validator.showMessageFor('first_name')} type="text" class="form-control" id="first_name" name="first_name"  placeholder={t('home.Enter First Name')} /> */}
                            <input value={guestinfo.otp}  onChange={e => {this.setState({ guestinfo: {...guestinfo, otp:e.target.value}})}} onBlur={() => this.validator.showMessageFor('otp')} type="text" id="new_password" name="otp" class="form-control" placeholder={t('home.Enter your OTP')} />
                            {this.validator.message('otp', this.state.guestinfo.otp, 'required')}
                        </div>
                    </div>

                    <div class="row" style={{marginTop:'-10px'}}>
                        <div class="form-group col col-md-4">
                            <label class="color-black font-weight-bolder">{t('home.New Password')}<span class="color-red">*</span></label>
                            {/* <input onBlur={() => this.validator.showMessageFor('first_name')} type="text" class="form-control" id="first_name" name="first_name"  placeholder={t('home.Enter First Name')} /> */}
                            <input 
                              value={guestinfo.new_password}
                              onChange={e => {
                                const val = e.target.value.replace(" ", "");
                                this.setState({ guestinfo: {...guestinfo, new_password: val }})
                              }}
                              onBlur={() => this.validator.showMessageFor('new_password')} type="password" id="new_password" name="new_password" class="form-control" placeholder={t('home.Enter your password')}
                              onCopy={(e) => e.preventDefault()}
                              onPaste={(e) => e.preventDefault()}
                            />
                            {this.validator.message('new_password', this.state.guestinfo.new_password, 'required|min:8')}
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col col-md-4">
                            <label class="color-black font-weight-bolder">{t('home.Confirm New Password')}<span class="color-red">*</span></label>
                            {/* <input onBlur={() => this.validator.showMessageFor('first_name')} type="text" class="form-control" id="first_name" name="first_name"  placeholder={t('home.Enter First Name')} /> */}
                            <input
                              value={guestinfo.confirm_password}
                              onChange={e => {
                                const val = e.target.value.replace(" ", "");
                                this.setState({ guestinfo: {...guestinfo, confirm_password: val }})
                              }}
                              onBlur={() => this.validator.showMessageFor('confirm_password')} type="password" id="confirm_password" name="confirm_password" class="form-control" placeholder={t('home.Enter your password')}
                              onCopy={(e) => e.preventDefault()}
                              onPaste={(e) => e.preventDefault()}
                           />
                            {this.validator.message('confirm_password', this.state.guestinfo.confirm_password, 'required|min:8')}
                        </div>
                    </div>
                   
                    <div class="row">
                        <div class="form-group col col-md-3" style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <button class="btn btn-brown">{t('home.Save')}</button>
                            {this.state.isLoading ? <img id="loader" name="loader" src={myConstClass.IMAGE_URL+'loader.gif'} class="img-fluid" /> :
                            <span class="btn btn-brown" onClick={this.handleResendOtp} style={{ cursor: "pointer" }}>{t('home.Resend OTP')}</span> }
                            <Link class="btn btn-light" to="/">{t('home.Cancel')}</Link>
                        </div>
                    </div>
                  </Form>
            </div>
        </div>
    </div>
    <Footer />
    </div>
  );
}
}

Resetpassword.propTypes = {
    t: PropTypes.func.isRequired,
  };
export default translate(Resetpassword);
