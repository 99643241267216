import React , { Component }from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { Editor} from 'react-draft-wysiwyg';
import { EditorState,convertToRaw,convertFromRaw  } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateToHTML} from 'draft-js-export-html'; 
import Header from './Header.js';
import Footer from './Footer.js';
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';

// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,getLanguage,
    translate,
  } from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,  
    Label,
    Row,
    
  } from 'reactstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

class Feedback extends Component {
    
    constructor(props) {
       
      super(props);
      this.product_id=0;
      this.state = {
        flag:0,
        eventTypes:[],
        guestinfo: {
            user_id: '',
            first_name: '',
            last_name: '',
            email: '',
            mobile: '',
            event_type:'',
            comment:'',
           
          },
          eventinfo: {
            shipping_first_name: '',
            shipping_middle_name:'',
            shipping_company:'',
            shipping_last_name: '',
            shipping_address_line_1: '',
            shipping_address_line_2:'',
            shipping_landmark:'',
            shipping_country:'',
            shipping_state:'',
            shipping_city:'',
            shipping_pincode:'',
            shipping_phone:'',
            shipping_email:'',
            shipping_countryname:'',
            shipping_statename:'',
            shipping_cityname:'',
            is_shipping_address_available:'',
            sadhak_number:'',
            birth_date:'',
            birth_date_old:'',
          },
          removeinfo:{
            message: '',
            status: '',
          },
          country:[],
          city:[],
          shipping_cityshipping:[],
          shipping_countrystate:[],
      }
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleDayChange = this.handleDayChange.bind(this);
      this.handleToDayChange = this.handleToDayChange.bind(this);
      this.validator = new SimpleReactValidator();
      this.billing_type = "1";
  }

  handleDayChange(day) { 
    var utcDate = day.toLocaleDateString();
    $('#birth_date').val(utcDate);
    this.state.guestinfo.birth_date_old = utcDate;
    
    this.setState({ selectedDay: day });
  }

  handleToDayChange(day) { //alert(day);
    var utcDate = day.toLocaleDateString();
    $('#end_date').val(utcDate);
    this.state.eventinfo.end_date = utcDate;
    this.setState({ selectedDay: day });
  }

  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    var user_id = localStorage.getItem('user_id');
    
    //this.getCountry();
    
    //var billing_address_id = 1;
    if(user_id > 0){
        fetch(myConstClass.BASE_URL+'users/getUserProfileFeedback/'+user_id)
        .then(response => response.json())
        .then(response =>{this.setState({guestinfo: response})
        const numRows = this.state.guestinfo.length;
        if(this.state.guestinfo.first_name != '' && this.state.guestinfo.first_name != undefined){
                this.state.guestinfo.first_name = this.state.guestinfo.first_name;
                this.state.guestinfo.middle_name = this.state.guestinfo.middle_name;
                this.state.guestinfo.last_name = this.state.guestinfo.last_name;
                this.state.guestinfo.phone = this.state.guestinfo.phone;
                this.state.guestinfo.email = this.state.guestinfo.email;
                
                }
            })
        }
    
fetch(myConstClass.BASE_URL+'event/getEventTypes/'+getLanguage())
    .then(response => response.json())
    .then(response =>this.setState({eventTypes: response}))
    .catch(err => console.log(err))
//       fetch(myConstClass.BASE_URL+'product/getAvailProdTypes')
//       .then(response => response.json())
//       .then(response =>this.setState({types: response}))
//       .catch(err => console.log(err))
          
      
//       const answer_array = this.props.location.pathname.split('/');
//        // alert(answer_array);
//     if(answer_array['2']){
     
//   fetch(myConstClass.BASE_URL+'product/getProductInfo/'+answer_array['2']+'/'+getLanguage())
//   .then(response => response.json())
//   .then(response =>this.setState({guestinfo: response}))  
//   .catch(err => console.log(err))

//   fetch(myConstClass.BASE_URL+'product/getRelatedProducts/'+answer_array['2'])
//       .then(response => response.json())
//       .then(response =>this.setState({posts: response}))
//       .catch(err => console.log(err))

//     }
          
  }

  getCityStateWise = _ => {
    var shipping_stateid = $('#state').val();
   // alert(shipping_countryid);
    fetch(myConstClass.BASE_URL+'getCityStateWise/'+shipping_stateid)
    .then(response => response.json())
    .then(response => this.setState({ city: response}))
    .catch(err => console.log(err))
   
  }

  
  getStateCountryWise = _ => {
    var shipping_countryid = $('#country').val();
    //alert(shipping_countryid);
    fetch(myConstClass.BASE_URL+'getStateCountryWise/'+shipping_countryid)
    .then(response => response.json())
    .then(response => this.setState({ shipping_countrystate: response}))
    .catch(err => console.log(err))
   
  }
  getStateCountryWiseEdit(shipping_countryid){
    //var shipping_countryid = $('#country').val();
    //alert(shipping_countryid);
    fetch(myConstClass.BASE_URL+'getStateCountryWise/'+shipping_countryid)
    .then(response => response.json())
    .then(response => this.setState({ shipping_countrystate: response}))
    .catch(err => console.log(err))
   
  }

  getCityStateWiseEdit(shipping_stateid){
    fetch(myConstClass.BASE_URL+'getCityStateWise/'+shipping_stateid)
    .then(response => response.json())
    .then(response => this.setState({ city: response}))
    .catch(err => console.log(err))
  }

  getCountry = _ => {
    fetch(myConstClass.BASE_URL+'getAllCountry')
    .then(response => response.json())
    .then(response => this.setState({ country: response}))
    .catch(err => console.log(err))
   // console.log("this.setState.data",this.state.country);
   
  }

  componentDidUpdate(prevProps) {
     
      const answer_array = this.props.location.pathname.split('/');
      const { flag} = this.state  
      // alert(answer_array);
//    if(answer_array['2'] && getLanguage()=='ma' && flag==0){
//     this.setState({flag:1})
//  fetch(myConstClass.BASE_URL+'product/getProductInfo/'+answer_array['2']+'/'+getLanguage())
//  .then(response => response.json())
//  .then(response =>this.setState({guestinfo: response}))  
//  .catch(err => console.log(err))
//    }

//    if(answer_array['2'] && getLanguage()=='en' && (flag==1)){
//     this.setState({flag:0})
//  fetch(myConstClass.BASE_URL+'product/getProductInfo/'+answer_array['2']+'/'+getLanguage())
//  .then(response => response.json())
//  .then(response =>this.setState({guestinfo: response}))  
//  .catch(err => console.log(err))
//    }
  }

  handleSubmit(e) {
    e.preventDefault();
    var user_id = localStorage.getItem('user_id');
    // console.log('this.refs',$('#pform').serializeArray());
    const { comment, email, event_type, first_name, last_name, phone } = this.state.guestinfo;

    const isEmailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
    const isFirstNameValid = /^[A-Za-z]{2,50}$/.test(first_name);
    const isLastNameValid = /^[A-Za-z]{2,50}$/.test(last_name);
    const isPhoneValid = /^\d{8,15}$/.test(phone);

    if (this.validator.allValid()) {
      const formData = {};
      for (const field in this.refs) {
        formData[field] = this.refs[field].value;
      }
      // console.log('-->', formData);
      fetch(myConstClass.BASE_URL+`users/addFeedback/`+user_id, {
      method: 'POST',
      body: JSON.stringify($('#pform').serializeArray()),
      })
        .then(response => response.json())
        .then(response =>{this.setState({guestinfo: response})
        // console.log('response: ', response);
      var message =  this.state.guestinfo.message;
      var status =  this.state.guestinfo.status;
      //console.log('hii'+this.state.guestinfo);
      if(status == 'true'){
        // $('#address').modal('hide');
        // $('#pform')[0].reset();
        $('#comment').val('');
        $('#event_type').val('');
        const numRows = this.state.guestinfo.length;
        if(numRows > 0){
            this.state.is_billing_address_available = 1;
        }
        this.state.guestinfo.first_name = this.state.guestinfo.first_name;
        // this.state.guestinfo.middle_name = this.state.guestinfo.middle_name;
        this.state.guestinfo.last_name = this.state.guestinfo.last_name;
        // this.state.guestinfo.company = this.state.guestinfo.company;
        // this.state.guestinfo.address_line_1 = this.state.guestinfo.address_line_1;
        // this.state.guestinfo.address_line_2 = this.state.guestinfo.address_line_2;
        // this.state.guestinfo.landmark = this.state.guestinfo.landmark;
        // this.state.guestinfo.country = this.state.guestinfo.country_id_fk;
        // this.state.guestinfo.state = this.state.guestinfo.state_id_fk;
        // this.state.guestinfo.city = this.state.guestinfo.city_id_fk;
        // this.state.guestinfo.pincode = this.state.guestinfo.pincode;
        this.state.guestinfo.phone = this.state.guestinfo.phone;
        this.state.guestinfo.email = this.state.guestinfo.email;
        this.state.guestinfo.event_type = this.state.guestinfo.event_type;
        this.state.guestinfo.comment = this.state.guestinfo.comment;
        // this.state.guestinfo.shipping_countryname = this.state.guestinfo.country_name;
        // this.state.guestinfo.shipping_statename = this.state.guestinfo.state_name;
        // this.state.guestinfo.shipping_cityname = this.state.guestinfo.city_name;
        // this.state.guestinfo.birth_date = this.state.guestinfo.birth_date;
          toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});
      } else {
        toast(''+message, {containerId: 'B',type: toast.TYPE.ERROR});
      }
        //console.log(status);
    })
    .catch(err => console.log(err))
    }
    else {
      this.validator.showMessages();
      if (!email && !first_name && !last_name && !phone && !email ) {
        toast.error("Please fill all required fields.");
      }
      else if (!isFirstNameValid || !first_name) {
        toast.error("Invalid first name format. Please enter letters only.");
      }
      else if (!isLastNameValid || !last_name) {
        toast.error("Invalid last name format. Please enter letters only.");
      }
      else if (!isPhoneValid || !phone) {
        toast.error("Invalid phone number format. Please enter 10 digits.");
      }
      else if (!isEmailValid || !email) {
        toast.error("Invalid email format. Please enter email.");
      }
      // console.log('validations'+this.validator.showMessages());
      // toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR})
    }
  }

  render() {      

    const { eventTypes, country, shipping_countrystate, city, eventinfo, posts, guestinfo, toast } = this.state  
    const { t } = this.props;

    return (
      <div className="App">
        <div>
          {/* <button onClick={this.notify}>Notify !</button> */}
          <ToastContainer />
        </div>
        <Header />
        <div class="container-fluid">
   
          <div class="row">
            <div class="col-lg-12 mt-3">
              <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb bg-white mb-0">
                    <li class="breadcrumb-item">
                      <Link   to="/">{t('home.home')}</Link>
                    </li>
                    {/* <li class="breadcrumb-item"><a href="#">{t('home.My Account')}</a></li> */}
                    <li class="breadcrumb-item active" aria-current="page">{t('feedback')}</li>
                  </ol>
                </nav>
              </div>
              <div class="row">
                <div class="form-group col col-md-3">
                  <h4>{t('home.Leave a Comment')}</h4>
                </div>
                {/* <div class="col col-md-3" style={{marginLeft:'6%'}}>
                  <p><span class="color-red">* {t('home.Mandatory Fields')}</span></p>
                </div> */}
              </div>
            </div>

            <div class="col col-lg-10">
                {/* <form> */}
              <Form action="" id="pform" onSubmit={this.handleSubmit}  method="post" encType="multipart/form-data" className="form-horizontal">

                <div class="row" style={{marginTop:'-10px'}}>
                  <div class="form-group col col-md-4">
                    <label class="color-black font-weight-bolder">{t('home.First Name')}<span class="color-red">*</span></label>
                    {/* <input onBlur={() => this.validator.showMessageFor('first_name')} type="text" class="form-control" id="first_name" name="first_name"  placeholder={t('home.Enter First Name')} /> */}
                    <input
                      onChange={e => {this.setState({ guestinfo: {...guestinfo, first_name:e.target.value}})}}
                      onBlur={() => this.validator.showMessageFor('first_name')}
                      type="text" id="first_name" name="first_name" class="form-control" value={guestinfo.first_name} placeholder={t('home.Enter First Name')}
                    />
                    {this.validator.message('first_name', this.state.guestinfo.first_name, 'required|alpha|string|min:2|max:50')}
                  </div>
                  
                  <div class="form-group col col-md-4">
                    <label class="color-black font-weight-bolder">{t('home.Last Name')}<span class="color-red">*</span></label>
                    {/* <input type="text" class="form-control" id="last_name" name="last_name" placeholder={t('home.Enter Last Name')} /> */}
                    <input
                      onBlur={() => this.validator.showMessageFor('last_name')}
                      onChange={e => {this.setState({ guestinfo: {...guestinfo, last_name:e.target.value}})}}
                      type="text" id="last_name" name="last_name" class="form-control" value={guestinfo.last_name} placeholder={t('home.Enter Last Name')}
                    />
                    {this.validator.message('last_name', this.state.guestinfo.last_name, 'required|alpha|string|min:2|max:50')}
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col col-md-4">
                    <label class="color-black font-weight-bolder">{t('home.Phone')} <span class="color-red">*</span></label>
                    <input value={guestinfo.phone} onChange={e => {this.setState({ guestinfo: {...guestinfo, phone:e.target.value}})}}  type="number" class="form-control"  id="phone" name="phone" placeholder={t('home.Phone')} />
                    {this.validator.message('phone', this.state.guestinfo.phone, 'required|min:8|max: 15')}
                  </div>
                  <div class="form-group col col-md-6">
                    <label class="color-black font-weight-bolder"></label>
                    <p style={{marginTop:'10px'}}>
                      {t('home.For Example +91-20-24619880 (for landline) or +91-9665050454(for mobile number)')}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col col-md-4">
                    <label class="color-black font-weight-bolder">{t('home.Email')} <span class="color-red">*</span></label>
                    <input
                      onChange={e => {this.setState({ guestinfo: {...guestinfo, email:e.target.value}})}}
                      type="email" class="form-control"  id="email" name="email" value={guestinfo.email} placeholder={t('home.Email')}
                    />
                    {this.validator.message('email', this.state.guestinfo.email, 'required|email|regex:^\\S+@\\S+\\.\\S+$')}
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col col-md-4">
                  <label class="color-black font-weight-bolder">{t('home.Comment Related to')}</label>
                    <select
                      value={guestinfo.event_type} id="event_type" name="event_type" class="form-control"
                      onChange={e => {this.setState({ guestinfo: {...guestinfo, event_type:e.target.value}})}}
                    >
                      <option value="">{t('home.Select an option')}</option>
                      {eventTypes.map((info, ii) => {
                        if(guestinfo.event_type == info.event_type_id){
                          return(
                            <option value={info.event_type} selected="selected">{info.event_type}</option>
                          )
                        } else {
                            return (
                              <option value={info.event_type}>{info.event_type}</option>
                            ) 
                        }
                      })}
                      <option value="Products">{t('home.Products')}</option>
                      <option value="Other">{t('home.Other')}</option>
                    </select>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col col-md-8">
                  <label class="color-black font-weight-bolder">{t('home.Comment')} </label>
                    <textarea
                      onChange={e => {this.setState({ guestinfo: {...guestinfo, comment:e.target.value}})}}
                      rows="4" cols="50" name="comment" id="comment" class="form-control" placeholder={t('home.Enter your comments')} value={guestinfo.comment}
                    >
                    </textarea>
                  </div>
                </div>
                
                <div class="row">
                  <div class="form-group col col-md-3">
                    <button class="btn btn-brown">{t('home.Submit')}</button>
                    {/* <Link  class="btn btn-light"  to="/" style={{marginLeft:'10px'}}>{t('home.Cancel')}</Link> */}  
                  </div>
                </div>

              </Form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

Feedback.propTypes = {
    t: PropTypes.func.isRequired,
  };
export default translate(Feedback);
