import React , { Component }from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/images/logo.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { EditorState,convertToRaw,convertFromRaw  } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateToHTML} from 'draft-js-export-html'; 
import Header from './Header.js';
import Footer from './Footer.js';
import { Editor} from 'react-draft-wysiwyg';
import $ from 'jquery';
import { Base64 } from 'js-base64';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

// Translation Higher Order Component
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,  
    Label,
    Row,
    
  } from 'reactstrap';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,getLanguage,
    translate,
  } from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import YouTubePlayer from 'react-player/lib/players/YouTube';
import ReactPlayer from 'react-player';



// import 'react-awesome-slider/dist/styles.css';
// import AwesomeSlider from 'react-awesome-slider';
// //import AwsSliderStyles from 'react-awesome-slider/src/styles.scss';

class Sitemap extends Component {
    constructor(props) {
      super(props);
      this.event_id=0;
      this.state = {
        posts: [],
        types :[],
        english_pdf_url:'',
        hindi_pdf_url:'',
        marathi_pdf_url:'',
        selected:3,
        scheduleEvent:[],
        eventtopic:[],
        flag:0,
        review:[],
        feedback:[],
        arrVideo:[],
        arrImages:[],
        cartEvent:[],
        arrMagazine:[],
        arrEvents:[],
        arrProducts:[],
        arrResearch:[], 
        arrNews:[], 
        arrOther:[], 
        machineCartEvent:[],
        guestinfo: {
          pdfUrl:'',
            type_id: '',
            no_of_pages: '',
            total_like:'',
            total_review:'',
            title: '',
            author:'',
            quantity:'',
            price:'',
            description:'',
            images_array:[],
            arr_event_languages:[],
            current_event_type_id:'',
          },
          editorState: EditorState.createEmpty(),
      }
      this.handleChange = this.handleChange.bind(this);
    
      this.showMoreEvent = this.showMoreEvent.bind(this);
      this.showMoreProduct = this.showMoreProduct.bind(this);
      this.showMoreOthers = this.showMoreOthers.bind(this);
      this.showMoreResearch = this.showMoreResearch.bind(this);
      this.showMoreNews = this.showMoreNews.bind(this);
  }

  showMoreEvent(e){
    e.preventDefault();
    var event_type_id_current = e.target.id;
    
    var arr_type_id = event_type_id_current.split('_');
    var event_type_id = arr_type_id[1];
    console.log(event_type_id);
    $('.events_'+event_type_id).css('display','block');
    $('.event_show_more_'+event_type_id).css('display','none');
    // $('.event_show_less_'+event_type_id).css('display','none');
  }

  showMoreProduct(e){
    e.preventDefault();
    var type_id_current = e.target.id;
    
    var arr_type_id = type_id_current.split('_');
    var type_id = arr_type_id[1];
    // console.log(event_type_id);
    $('.products_'+type_id).css('display','block');
    $('.product_show_more_'+type_id).css('display','none');
    // $('.event_show_less_'+event_type_id).css('display','none');
  }

  showMoreOthers(e){
    e.preventDefault();
    
    $('.others').css('display','block');
    $('.other_show_more').css('display','none');
    // $('.event_show_less_'+event_type_id).css('display','none');
  }
  showMoreResearch(e){
    e.preventDefault();
    
    $('.research').css('display','block');
    $('.research_show_more').css('display','none');
  }
  showMoreNews(e){
    e.preventDefault();
    
    $('.news').css('display','block');
    $('.news_show_more').css('display','none');
  }

  changeURL(){
    var radioValue = $("input[name='gridRadios']:checked").val();
    var english_pdf_url = this.state.english_pdf_url;
    var hindi_pdf_url = this.state.hindi_pdf_url;
    var marathi_pdf_url = this.state.marathi_pdf_url;
    if(radioValue == 1){
      window.open(marathi_pdf_url,'_blank');
    }else if(radioValue == 2){
      window.open(hindi_pdf_url,'_blank');
    }else{
      window.open(english_pdf_url,'_blank');
    }
    
    
  }

  showallReview(){
    $('#ShowAllReviewDiv').css('display','none');
    const answer_array = this.props.location.pathname.split('/');
    fetch(myConstClass.BASE_URL+'review/getNewsReviewFrontEnd/'+answer_array['2']+'/200')
    .then(response => response.json())
    .then(response =>this.setState({review: response}))
    .catch(err => console.log(err))
          
}


  showMore(e){
    var review_id_all = e.target.id;
    var arr_review_id = review_id_all.split('_');
    var review_id = arr_review_id[1];
    $('#showMoreDiv_'+review_id).css('display','none');
    $('#showLessDiv_'+review_id).css('display','block');
  }

  showLess(e){
   var review_id_all = e.target.id;
   var arr_review_id = review_id_all.split('_');
   var review_id = arr_review_id[1];
   $('#showMoreDiv_'+review_id).css('display','block');
   $('#showLessDiv_'+review_id).css('display','none');
 }

 showMoreFeedback(e){
    var review_id_all = e.target.id;
    var arr_review_id = review_id_all.split('_');
    var review_id = arr_review_id[1];
    $('#showMoreFeedbackDiv_'+review_id).css('display','none');
    $('#showLessFeedbackDiv_'+review_id).css('display','block');
  }

  showLessFeedback(e){
   var review_id_all = e.target.id;
   var arr_review_id = review_id_all.split('_');
   var review_id = arr_review_id[1];
   $('#showMoreFeedbackDiv_'+review_id).css('display','block');
   $('#showLessFeedbackDiv_'+review_id).css('display','none');
 }

  readMore(e){
    // var product_id = e.target.id;
     $('#ReadMoreDiv').css('display','none');
     $('#ReadLessDiv').css('display','block');
   }
 
   readLess(e){
     // var product_id = e.target.id;
      $('#ReadMoreDiv').css('display','block');
      $('#ReadLessDiv').css('display','none');
    }



  submitLike(){
    const answer_array = this.props.location.pathname.split('/');
    var user_id = localStorage.getItem('user_id');
    if(user_id != '' && user_id != null && user_id > 0){
            
    fetch(myConstClass.BASE_URL+'review/saveNewLikeFrontEnd/'+user_id+'/'+answer_array['2'])
    .then(response => response.json())
    .then(response =>{this.setState({eventinfo: response})
        var status =  this.state.eventinfo.status;
        var message =  this.state.eventinfo.message;
        if(status == 'true'){
           var productLikeCount =  $('#productLikeCount').html();
            var productLikeCountNew = parseInt(productLikeCount)+1;
            $('#productLikeCount').html(productLikeCountNew);
            toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});
            //setTimeout(this.props.history.push('/products'),1000);
        }else{
            toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
            //toast("Wow so easy !");
        }
    })
    .catch(err => console.log(err))
   // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});

    
    }else{
        //var error =  'You must have login for submitting review.';
        localStorage.removeItem('error');
        localStorage.setItem('error','You must have login for like product.');
       // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
       // this.props.history.push('/signin');
        setTimeout(this.props.history.push('/signin'),1000);
    }
    
    //alert(user_review);
  }

  submitReview(e){
    e.preventDefault();
    console.log('this.refs',$('#pform').serializeArray());
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
    const answer_array = this.props.location.pathname.split('/');
    var user_review_current = $('#user_review').val();
    var user_review = $.trim(user_review_current);
    //alert(user_review);
    var user_id = localStorage.getItem('user_id');
    if(user_id != '' && user_id != null && user_id > 0){
        if(user_review != ''){
        var message =  'Your review added successfully,once admin approval will display it.';
            
       // fetch(myConstClass.BASE_URL+'review/saveEventReviewFrontEnd/'+user_id+'/'+answer_array['2']+'/'+user_review)
        fetch(myConstClass.BASE_URL+`review/saveNewsReviewFrontEnd/`+user_id+'/'+answer_array['2'], {
            method: 'POST',
            body: JSON.stringify($('#pform').serializeArray()),
        
        })
    .then(response => response.json())
    .then(response =>{this.setState({eventinfo: response})
        var status =  this.state.eventinfo.status;
        var message =  this.state.eventinfo.message;
        if(status == 'true'){
            toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});
            //setTimeout(this.props.history.push('/products'),1000);
        }else{
            toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
            //toast("Wow so easy !");
        }
    })
    .catch(err => console.log(err))
   // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});

    $('#user_review').val('');
        }else{
            var message = "Please enter review.";
            toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
        }


    }else{
        //var error =  'You must have login for submitting review.';
        localStorage.removeItem('error');
        localStorage.setItem('error','You must have login for submitting review.');
       // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
       // this.props.history.push('/signin');
        setTimeout(this.props.history.push('/signin'),1000);
    }
    
    //alert(user_review);
  }




handleChange(event) {
    this.setState({value: event.target.value});
  }

  
  componentDidMount() {
    var old_product_type =  getLanguage();
    localStorage.setItem('old_product_type',old_product_type);

    //   fetch(myConstClass.BASE_URL+'product/getAvailProdTypes')
    //   .then(response => response.json())
    //   .then(response =>this.setState({types: response}))
    //   .catch(err => console.log(err))
          
    var user_id = localStorage.getItem('user_id');  
   
  fetch(myConstClass.BASE_URL+'sitemap/getEventList/'+getLanguage())
  .then(response => response.json())
  .then(response =>{this.setState({arrEvents: response})

    })

    fetch(myConstClass.BASE_URL+'sitemap/getProductList/'+getLanguage())
  .then(response => response.json())
  .then(response =>{this.setState({arrProducts: response})

    })

    fetch(myConstClass.BASE_URL+'sitemap/getOthersCMSList/'+getLanguage())
  .then(response => response.json())
  .then(response =>{this.setState({arrOther: response})
    var total = this.state.arrOther.length;
    if(total < 18){
      $('.other_show_more').css('display','none');
    }else{
      $('.other_show_more').css('display','inline');
    }
    })

    fetch(myConstClass.BASE_URL+'sitemap/getResearchNewsList/'+getLanguage()+'/1')
  .then(response => response.json())
  .then(response =>{this.setState({arrResearch: response})
    var total = this.state.arrResearch.length;
    if(total < 18){
      $('.research_show_more').css('display','none');
    }else{
      $('.research_show_more').css('display','inline');
    }
    })


    fetch(myConstClass.BASE_URL+'sitemap/getResearchNewsList/'+getLanguage()+'/2')
  .then(response => response.json())
  .then(response =>{this.setState({arrNews: response})
    var total = this.state.arrNews.length;
    if(total < 18){
      $('.news_show_more').css('display','none');
    }else{
      $('.news_show_more').css('display','inline');
    }
    })

  .catch(err => console.log(err))
  
}

  componentDidUpdate(prevProps) {
    const answer_array = this.props.location.pathname.split('/');
    const { flag} = this.state  
    var current_product_type = answer_array['2'];

    //$('#'+current_product_type).css('font-size','16px');
    var old_product_type =  localStorage.getItem('old_product_type');
    localStorage.setItem('old_product_type',current_product_type);

    //alert(old_product_type);
    if(old_product_type != '' && current_product_type != old_product_type && old_product_type != null && old_product_type > 0){
        this.setState({flag:1});
        //alert('hi');
    }else{
      var current_language =  localStorage.getItem('current_language');
        
        if(current_language != getLanguage() && flag != 1 && current_language != null){
         //alert(current_language);
             localStorage.removeItem('current_language');
             localStorage.setItem('current_language',getLanguage());
             this.setState({flag:1});
        }
    }
  
    var user_id = localStorage.getItem('user_id');
    if(getLanguage()=='ma' && flag==1){
        this.setState({flag:0})
      
       
      
  fetch(myConstClass.BASE_URL+'sitemap/getEventList/'+getLanguage())
  .then(response => response.json())
  .then(response =>{this.setState({arrEvents: response})

    })

    fetch(myConstClass.BASE_URL+'sitemap/getProductList/'+getLanguage())
  .then(response => response.json())
  .then(response =>{this.setState({arrProducts: response})

    })

    fetch(myConstClass.BASE_URL+'sitemap/getOthersCMSList/'+getLanguage())
  .then(response => response.json())
  .then(response =>{this.setState({arrOther: response})
    var total = this.state.arrOther.length;
    if(total < 18){
      $('.other_show_more').css('display','none');
    }else{
      $('.other_show_more').css('display','inline');
    }
    })

    fetch(myConstClass.BASE_URL+'sitemap/getResearchNewsList/'+getLanguage()+'/1')
  .then(response => response.json())
  .then(response =>{this.setState({arrResearch: response})
    var total = this.state.arrResearch.length;
    if(total < 18){
      $('.research_show_more').css('display','none');
    }else{
      $('.research_show_more').css('display','inline');
    }
    })


    fetch(myConstClass.BASE_URL+'sitemap/getResearchNewsList/'+getLanguage()+'/2')
  .then(response => response.json())
  .then(response =>{this.setState({arrNews: response})
    var total = this.state.arrNews.length;
    if(total < 18){
      $('.news_show_more').css('display','none');
    }else{
      $('.news_show_more').css('display','inline');
    }
    })

  .catch(err => console.log(err))

        
    }

    if(getLanguage()=='en' && (flag==1)){ 
        this.setState({flag:0})
        
      
  fetch(myConstClass.BASE_URL+'sitemap/getEventList/'+getLanguage())
  .then(response => response.json())
  .then(response =>{this.setState({arrEvents: response})

    })

    fetch(myConstClass.BASE_URL+'sitemap/getProductList/'+getLanguage())
  .then(response => response.json())
  .then(response =>{this.setState({arrProducts: response})

    })

    fetch(myConstClass.BASE_URL+'sitemap/getOthersCMSList/'+getLanguage())
  .then(response => response.json())
  .then(response =>{this.setState({arrOther: response})
    var total = this.state.arrOther.length;
    if(total < 18){
      $('.other_show_more').css('display','none');
    }else{
      $('.other_show_more').css('display','inline');
    }
    })

    fetch(myConstClass.BASE_URL+'sitemap/getResearchNewsList/'+getLanguage()+'/2')
  .then(response => response.json())
  .then(response =>{this.setState({arrResearch: response})
    var total = this.state.arrResearch.length;
    if(total < 18){
      $('.research_show_more').css('display','none');
    }else{
      $('.research_show_more').css('display','inline');
    }
    })


    fetch(myConstClass.BASE_URL+'sitemap/getResearchNewsList/'+getLanguage()+'/1')
  .then(response => response.json())
  .then(response =>{this.setState({arrNews: response})
    var total = this.state.arrNews.length;
    if(total < 18){
      $('.news_show_more').css('display','none');
    }else{
      $('.news_show_more').css('display','inline');
    }
    })

  .catch(err => console.log(err)) 


  }
  }
  
  render() {
      
    const {arrResearch,arrNews,selected,arrOther,cartEvent,machineCartEvent,arrImages,arrVideo,feedback, arrProducts,arrEvents,posts,guestinfo,eventtopic} = this.state  
    const { t } = this.props;
  return (
    
    <div className="App">
         <div>
        {/* <button onClick={this.notify}>Notify !</button> */}
          <ToastContainer />
        </div>
    <Header />
    <div class="container-fluid ml-3">
        <div class="row">
            <h4> <b>{t('home.SITE MAP')}</b> </h4>
        </div>
    </div>


    <div class="container-fluid ml-3" id="site_map">
        <div class="row mb-3">
        <b>{t('home.EVENTS')}</b>
        </div>
        {arrEvents.map((type, ii) => {
          if(type.arr_events.length > 18){

            return(
              <div>
              <div class="row">
                <h5>{type.event_type}</h5>
              </div>
              <div class="row">
              {type.arr_events.map((event, ii) => {
                if(ii <= 17){
                  return(
                    <div class="col-lg-2 col-mg-2 col-sm-4  site_map">
                        <ul>
                        <Link   to={"/event_details/"+event.event_id}>
                          <li class="py-1">{event.title}</li>
                        </Link>
                            
                        </ul>
                    </div>
                      )
                }else{
                  return(
                    <div class={"col-lg-2 col-mg-2 col-sm-4  site_map "+'events_'+type.event_type_id}  style={{display:'none'}} >
                        <ul>
                        <Link   to={"/event_details/"+event.event_id}>
                          <li class="py-1">{event.title}</li>
                        </Link>
                            
                        </ul>
                    </div>
                      )
                }
                

                  })
                }
      
                  <div class={"col-lg-12 col-md-12 col-sm-12 "+'event_show_more_'+type.event_type_id}>
                      <ul class="nav justify-content-end pr-5">
                          <li class="pr-3"><a href="javascript:void(0)" onClick={this.showMoreEvent} id={'events_'+type.event_type_id}>{t('home.SHOW MORE')}</a></li>
                      </ul>
                  </div>
      
              </div>
              </div>
                )

          }else{

            return(
              <div>
              <div class="row">
                <h5>{type.event_type}</h5>
              </div>
              <div class="row">
              {type.arr_events.map((event, ii) => {
                 return(
                  <div class="col-lg-2 col-mg-2 col-sm-4  site_map">
                      <ul>
                      <Link   to={"/event_details/"+event.event_id}>
                        <li class="py-1">{event.title}</li>
                      </Link>
                          
                      </ul>
                  </div>
                    )
                  })
                }
      
                  {/* <div class="col-lg-12 col-md-12 col-sm-12 ">
                      <ul class="nav justify-content-end pr-5">
                          <li class="pr-3"><a href="">SHOW MORE...</a></li>
                      </ul>
                  </div> */}
      
              </div>
              </div>
                )
          }
          
          })
        }
       
        
        

        
        
       
        
        
    </div>


    <div class="container-fluid ml-3" id="site_map">
            <div class="row mb-3">
            <b>
            {t('home.PRODUCTS')}
              </b>
            </div>
            {arrProducts.map((type, ii) => {
          if(type.arr_products.length > 18){

            return(
              <div>
              <div class="row">
                <h5>{type.product_type}</h5>
              </div>
              <div class="row">
              {type.arr_products.map((product, ii) => {
                if(ii <= 17){
                  return(
                    <div class="col-lg-2 col-mg-2 col-sm-4  site_map">
                        <ul>
                        <Link   to={"/products_details/"+product.title+'/'+product.product_id}>
                          <li class="py-1">{product.title}</li>
                        </Link>
                            
                        </ul>
                    </div>
                      )
                }else{
                  return(
                    <div class={"col-lg-2 col-mg-2 col-sm-4  site_map "+'products_'+type.product_type_id}  style={{display:'none'}} >
                        <ul>
                        <Link   to={"/products_details/"+product.title+'/'+product.product_id}>
                          <li class="py-1">{product.title}</li>
                        </Link>
                            
                        </ul>
                    </div>
                      )
                }
                

                  })
                }
      
                  <div class={"col-lg-12 col-md-12 col-sm-12 "+'product_show_more_'+type.product_type_id}>
                      <ul class="nav justify-content-end pr-5">
                          <li class="pr-3"><a href="javascript:void(0)" onClick={this.showMoreProduct} id={'products_'+type.product_type_id}>{t('home.SHOW MORE')}</a></li>
                      </ul>
                  </div>
      
              </div>
              </div>
                )

          }else{

            return(
              <div>
              <div class="row">
                <h5>{type.product_type}</h5>
              </div>
              <div class="row">
              {type.arr_products.map((product, ii) => {
                 return(
                  <div class="col-lg-2 col-mg-2 col-sm-4  site_map">
                      <ul>
                      <Link   to={"/products_details/"+product.title+'/'+product.product_id}>
                        <li class="py-1">{product.title}</li>
                      </Link>
                          
                      </ul>
                  </div>
                    )
                  })
                }
      
                  {/* <div class="col-lg-12 col-md-12 col-sm-12 ">
                      <ul class="nav justify-content-end pr-5">
                          <li class="pr-3"><a href="">SHOW MORE...</a></li>
                      </ul>
                  </div> */}
      
              </div>
              </div>
                )
          }
          
          })
        }


           
           
            
           
            
           
           
            
           
           
            
{/*             
            <div class="row">
                    <h5>Magazines</h5>
                </div>
                <div class="row">
                    <div class="col-lg-2 col-mg-2 col-sm-4  ">
                        <ul>
                            <a href="">
                                <li class="py-1">Suprajanan Test</li>
                            </a>
                            <a href="">
                                <li class="py-1">Suprajanan Test</li>
                            </a>
                            <a href="">
                                <li class="py-1">Suprajanan Test</li>
                            </a>
                        </ul>
                    </div>
        
                    <div class="col-lg-2 col-mg-2 col-sm-4  ">
        
                        <ul>
                            <a href="">
                                <li class="py-1">Neurocognitive Testt</li>
                            </a>
                            <a href="">
                                <li class="py-1">Neurocognitive Testt</li>
                            </a>
                            <a href="">
                                <li class="py-1">Neurocognitive Test</li>
                            </a>
                        </ul>
                    </div>
        
                    <div class="col-lg-2 col-mg-2 col-sm-4  ">
        
                        <ul>
                            <a href="">
                                <li class="py-1">Depression Tests</li>
                            </a>
                            <a href="">
                                <li class="py-1">Depression Tests</li>
                            </a>
                            <a href="">
                                <li class="py-1">Depression Tests</li>
                            </a>
                        </ul>
                    </div>
        
                    <div class="col-lg-2 col-mg-2 col-sm-4  ">
        
                        <ul>
                            <a href="">
                                <li class="py-1">Anxiety Test</li>
                            </a>
                            <a href="">
                                <li class="py-1">Anxiety Test</li>
                            </a>
                            <a href="">
                                <li class="py-1">Anxiety Test</li>
                            </a>
                        </ul>
                    </div>
        
                    <div class="col-lg-2 col-mg-2 col-sm-4  ">
        
                        <ul>
                            <a href="">
                                <li class="py-1">Depression Tests</li>
                            </a>
                            <a href="">
                                <li class="py-1">Depression Tests</li>
                            </a>
                            <a href="">
                                <li class="py-1">Depression Tests</li>
                            </a>
                        </ul>
                    </div>
        
                    <div class="col-lg-2 col-mg-2 col-sm-4  ">
        
                        <ul>
                            <a href="">
                                <li class="py-1">Brain Dominance Test</li>
                            </a>
                            <a href="">
                                <li class="py-1">Brain Dominance Test</li>
                            </a>
                            <a href="">
                                <li class="py-1">Brain Dominance Test</li>
                            </a>
                        </ul>
                    </div>
        
                    <div class="col-lg-12 col-md-12 col-sm-12 ">
                        <ul class="nav justify-content-end pr-5">
                            <li class="pr-3"><a href="">SHOW MORE...</a></li>
                        </ul>
                    </div>
        
                </div> */}
    
    </div>


    <div class="container-fluid ml-3" id="site_map">
            <div class="row mb-3">
            <b>{t('home.MISCELLANEOUS')}
              </b>
            </div>
           
            <div class="row">
                <h5>
                {t('home.Research')}
                  </h5>
            </div>
            <div class="row site_map">
                
            {arrResearch.map((other, ii) => {
              if(arrResearch.length > 17){
                if(ii <= 17){
                  return(
                    <div class="col-lg-2 col-mg-2 col-sm-4  site_map">
                        <ul>
                        <Link   to={"/research-details"+'/'+other.news_article_id}>
                          <li class="py-1">{other.title}</li>
                        </Link>
                            
                        </ul>
                    </div>
                      )
                }else{
                  return(
                    <div class={"col-lg-2 col-mg-2 col-sm-4  site_map "+'others'}>
                        <ul>
                        <Link   to={"/research-details"+'/'+other.news_article_id}>
                          <li class="py-1">{other.title}</li>
                        </Link>
                            
                        </ul>
                    </div>
                      )
                }
                
              }else{
                return(
                  <div class="col-lg-2 col-mg-2 col-sm-4  site_map">
                      <ul>
                      <Link   to={"/research-details"+'/'+other.news_article_id}>
                        <li class="py-1">{other.title}</li>
                      </Link>
                          
                      </ul>
                  </div>
                    )
              }
                 
                  })
                }
                <div class="col-lg-12 col-md-12 col-sm-12 research_show_more">
                    <ul class="nav justify-content-end pr-5">
                        <li class="pr-3"><a href="javascript:void(0)" onClick={this.showMoreResearch}>{t('home.SHOW MORE')} </a></li>
                    </ul>
                </div>
    
               
    
            </div>
            <div class="row">
                <h5>{t('home.News')}</h5>
            </div>
            <div class="row">
               
            {arrNews.map((other, ii) => {
              if(arrNews.length > 17){
                if(ii <= 17){
                  return(
                    <div class="col-lg-2 col-mg-2 col-sm-4  site_map">
                        <ul>
                        <Link   to={"/news-details"+'/'+other.news_article_id}>
                          <li class="py-1">{other.title}</li>
                        </Link>
                            
                        </ul>
                    </div>
                      )
                }else{
                  return(
                    <div class={"col-lg-2 col-mg-2 col-sm-4  site_map "+'others'}>
                        <ul>
                        <Link   to={"/news-details"+'/'+other.news_article_id}>
                          <li class="py-1">{other.title}</li>
                        </Link>
                            
                        </ul>
                    </div>
                      )
                }
                
              }else{
                return(
                  <div class="col-lg-2 col-mg-2 col-sm-4  site_map">
                      <ul>
                      <Link   to={"/news-details"+'/'+other.news_article_id}>
                        <li class="py-1">{other.title}</li>
                      </Link>
                          
                      </ul>
                  </div>
                    )
              }
                 
                  })
                }
                <div class="col-lg-12 col-md-12 col-sm-12 news_show_more">
                    <ul class="nav justify-content-end pr-5">
                        <li class="pr-3"><a href="javascript:void(0)" onClick={this.showMoreNews}>{t('home.SHOW MORE')} </a></li>
                    </ul>
                </div> 
               
    
            </div>
            <div class="row mb-3">
                <h5>{t('home.Other')}</h5>
            </div>
            <div class="row">
            {arrOther.map((other, ii) => {
              if(arrOther.length > 17){
                if(ii <= 17){
                  return(
                    <div class="col-lg-2 col-mg-2 col-sm-4  site_map">
                        <ul>
                        <a target="_blank"  href={other.url}>
                          <li class="py-1">{other.title}</li>
                        </a>
                            
                        </ul>
                    </div>
                      )
                }else{
                  return(
                    <div class={"col-lg-2 col-mg-2 col-sm-4  site_map "+'others'}>
                        <ul>
                        <a target="_blank"  href={other.url}>
                          <li class="py-1">{other.title}</li>
                        </a>
                            
                        </ul>
                    </div>
                      )
                }
                
              }else{
                return(
                  <div class="col-lg-2 col-mg-2 col-sm-4  site_map">
                      <ul>
                      <a target="_blank"  href={other.url}>
                        <li class="py-1">{other.title}</li>
                      </a>
                          
                      </ul>
                  </div>
                    )
              }
                 
                  })
                }
                <div class="col-lg-12 col-md-12 col-sm-12 other_show_more">
                    <ul class="nav justify-content-end pr-5">
                        <li class="pr-3"><a href="javascript:void(0)" onClick={this.showMoreOthers}>{t('home.SHOW MORE')} </a></li>
                    </ul>
                </div>
    
            </div>
    </div>

    <Footer />
    </div>
  );
}
}
Sitemap.propTypes = {
    t: PropTypes.func.isRequired,
  };
export default translate(Sitemap);
