import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backgroundEvent from './assets/images/machine-tests-bg.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import './assets/fontawesome/css/all.min.css';
import Header from './Header.js';
import Footer from './Footer.js';
import * as myConstClass from './constant.js';
import $ from 'jquery';
import { Base64 } from 'js-base64';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


import ResidentialStudyCourses from './assets/images/ResidentialStudyCourses.jpg';
import OneDayWorkshop from './assets/images/OneDayWorkshop.jpg';
import RationalPoojas from './assets/images/RationalPoojas.jpg';
import MachineTests from './assets/images/MachineTests.jpg';
import FreeEvents from './assets/images/FreeEvents.jpg';

import AyurvedicProducts from './assets/images/AyurvedicProducts.jpg';
import Books from './assets/images/Books.jpg';
import CDs from './assets/images/CDs.jpg';
import ExperimentalProducts from './assets/images/ExperimentalProducts.jpg';
import KITs from './assets/images/KITs.jpg';

class Topics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            share_current_image_url: '',
            social_description: '',
            share_image_url: '',
            eventdetails: {
                event_type: '',
                event_description: '',
            },
            guestinfo: {
                event_type: '',
                event_type_id: '',
                topic_id: '',
                sub_topic_id: '',
                event_type_name: '',
                event_id: '0',
                schedule_event_name: '',
                language_id: '',

            },
            flag: 0,
            quantityValue: '1',
            posts: [],
            arrMagazine: [],
            products: [],
            eventinfo: [],
            languages: [],
            topic: [],
            eventTypes: [],
            scheduleEvent: [],
            cart: [],
            cartEvent: [],
            removeFromCart: [],
            eventdetails: [],

            types: []
        }
        this.handleChange = this.handleChange.bind(this);

        this.applyFilters = this.applyFilters.bind(this);
        this.rightArrow = this.rightArrow.bind(this);
        this.leftArrow = this.leftArrow.bind(this);

    }


    rightArrow() {
        var view = $("#tslshow");
        var move = "100px";
        var sliderLimit = -111750
        var currentPosition = parseInt(view.css("left"));
        if (currentPosition >= sliderLimit) view.stop(false, true).animate({ left: "-=" + move }, { duration: 400 })
    }

    leftArrow() {
        var view = $("#tslshow");
        var move = "100px";
        var sliderLimit = -111750
        var currentPosition = parseInt(view.css("left"));
        if (currentPosition < 0) view.stop(false, true).animate({ left: "+=" + move }, { duration: 400 })
    }

    handleChange(evt) {
        // check it out: we get the evt.target.name (which will be either "email" or "password")
        // and use it to target the key on our `state` object with the same name, using bracket syntax
        this.setState({ [evt.target.name]: evt.target.value });
    }
    increaseQuantity(evt) { //console.log(evt);
        var qty = evt.target.value;
        var qty = parseInt(qty) + 1; //alert(evt.target.value);
        // check it out: we get the evt.target.name (which will be either "email" or "password")
        // and use it to target the key on our `state` object with the same name, using bracket syntax
        this.setState({ [evt.target.name]: qty });
    }
    componentDidMount() {
        var current_language = getLanguage();
        localStorage.setItem('current_language', current_language);
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }
        var user_id = localStorage.getItem('user_id');
        const answer_array = this.props.location.pathname.split('/');
        var topic_id = answer_array['3'];
        //alert(topic_id);
        var sub_topic_id = answer_array['4'];

        //var language_id =  answer_array['5'];

        if (topic_id > 0) {
            this.state.guestinfo.topic_id = topic_id;
        } else {
            var topic_id = 0;
        }
        if (sub_topic_id > 0) {

            this.state.guestinfo.sub_topic_id = sub_topic_id;
            $('#subTopicName').css('display', 'inline');
        } else {
            var sub_topic_id = 0;
            $('#subTopicName').css('display', 'none');
        }

        this.setState({
            guestinfo: {
                ...this.state.guestinfo,
                topic_id: topic_id,
                sub_topic_id: sub_topic_id
            }
        })

        fetch(myConstClass.BASE_URL + 'event/getEventsTopicSubtopicWiseFrontEnd' + '/' + getLanguage() + '/' + topic_id + '/' + sub_topic_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ posts: response })
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

            })


        fetch(myConstClass.BASE_URL + 'product/getProductsTopicSubtopicWiseFrontEnd' + '/' + getLanguage() + '/' + topic_id + '/' + sub_topic_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ products: response })
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

            })



            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'event/getTopicSubtopicDetailsFrontEnd' + '/' + getLanguage() + '/' + topic_id + '/' + sub_topic_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ eventdetails: response })
                // var topic_name = this.state.eventdetails.topic_name;
                this.state.eventdetails.map((infos, ii) => {
                    if (ii == '0') {
                        this.setState({ share_image_url: infos.image_name });
                        this.setState({ share_current_image_url: window.location.href });
                        this.setState({ social_description: infos.topic_title_en });
                    }
                })


            })

        fetch(myConstClass.BASE_URL + 'event/getAllSubtopicsFrontEnd' + '/' + getLanguage() + '/' + topic_id)
            .then(response => response.json())
            .then(response => this.setState({ topic: response }))

        fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ arrMagazine: response }))
            .catch(err => console.log(err))

            .catch(err => console.log(err))


    }

    componentDidUpdate(prevProps) {

        const answer_array = this.props.location.pathname.split('/');
        //alert(answer_array['2']);
        const { flag } = this.state
        var current_topic_id = answer_array['3'];
        //alert(answer_array['3']);
        var topic_id = localStorage.getItem('topic_id');
        localStorage.setItem('topic_id', current_topic_id);

        var sub_topic_id = answer_array['4'];


        if (sub_topic_id == '' || sub_topic_id == undefined) {
            sub_topic_id = 0;
            this.state.guestinfo.sub_topic_id = sub_topic_id;
        } else {
            // alert(sub_topic_id);
            this.state.guestinfo.sub_topic_id = sub_topic_id;
        }

        if (sub_topic_id > 0) {
            this.state.guestinfo.sub_topic_id = sub_topic_id;
            // alert(sub_topic_id);
            $('#subTopicName').css('display', 'inline');
        } else {
            $('#subTopicName').css('display', 'none');
        }

        var old_sub_topic_id = localStorage.getItem('old_sub_topic_id');
        localStorage.setItem('old_sub_topic_id', sub_topic_id);



        var current_topic_id = answer_array['3'];

        var old_topic_id = localStorage.getItem('old_topic_id');

        if (old_topic_id == undefined) {
            old_topic_id = answer_array['3'];
        }
        //alert('current_topic_id'+current_topic_id+'old_topic_id'+old_topic_id);
        localStorage.setItem('old_topic_id', current_topic_id);

        //  alert('current_topic_id'+current_topic_id+'old_topic_id'+old_topic_id+'old_sub_topic_id'+old_sub_topic_id);

        if (old_topic_id != '' && (prevProps.history.location.pathname !== prevProps.location.pathname) && ((current_topic_id > 0 && old_sub_topic_id > 0 && old_sub_topic_id != sub_topic_id) || current_topic_id != old_topic_id || (old_sub_topic_id != sub_topic_id && sub_topic_id > 0 && sub_topic_id != undefined)) && old_topic_id != null && old_topic_id > 0) {
            this.setState({ flag: 1 });
            //alert('hi');
        }
        var current_language = localStorage.getItem('current_language');

        if (current_language != getLanguage() && flag != 1 && current_language != null) {
            //alert(current_language);
            localStorage.removeItem('current_language');
            localStorage.setItem('current_language', getLanguage());
            this.setState({ flag: 1 });
        }



        var topic_id = answer_array['3'];
        if (topic_id > 0) {
            this.state.guestinfo.topic_id = topic_id;
        } else {
            var topic_id = 0;
        }

        //const { flag} = this.state  
        if (getLanguage() == 'ma' && prevProps.history.location.pathname !== prevProps.location.pathname) {
            this.setState({ flag: 0 })

            if (sub_topic_id == '' || sub_topic_id == undefined) {
                this.setState({
                    ...this.state.guestinfo,
                    sub_topic_id: 0
                })
            } else {
                this.setState({
                    ...this.state.guestinfo,
                    sub_topic_id: sub_topic_id
                })
            }
            fetch(myConstClass.BASE_URL + 'event/getEventsTopicSubtopicWiseFrontEnd' + '/' + getLanguage() + '/' + topic_id + '/' + sub_topic_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response })
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

                })

            fetch(myConstClass.BASE_URL + 'product/getProductsTopicSubtopicWiseFrontEnd' + '/' + getLanguage() + '/' + topic_id + '/' + sub_topic_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ products: response })
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

                })

                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'event/getAllSubtopicsFrontEnd' + '/' + getLanguage() + '/' + topic_id)
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))

            fetch(myConstClass.BASE_URL + 'event/getTopicSubtopicDetailsFrontEnd' + '/' + getLanguage() + '/' + topic_id + '/' + sub_topic_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventdetails: response })
                    // var topic_name = this.state.eventdetails.topic_name;
                    this.state.eventdetails.map((infos, ii) => {
                        if (ii == '0') {
                            this.setState({ share_image_url: infos.image_name });
                            this.setState({ share_current_image_url: window.location.href });
                            this.setState({ social_description: infos.topic_title_en });
                        }
                    })

                })



            fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrMagazine: response }))
                .catch(err => console.log(err))


        }

        if (getLanguage() == 'en' && (prevProps.history.location.pathname !== prevProps.location.pathname)) {
            if (sub_topic_id == '' || sub_topic_id == undefined) {
                sub_topic_id = 0;
                this.setState({
                    ...this.state.guestinfo,
                    sub_topic_id: 0
                })
            } else {
                this.setState({
                    ...this.state.guestinfo,
                    sub_topic_id: sub_topic_id
                })
            }
            fetch(myConstClass.BASE_URL + 'event/getEventsTopicSubtopicWiseFrontEnd' + '/' + getLanguage() + '/' + topic_id + '/' + sub_topic_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response })
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

                })

            fetch(myConstClass.BASE_URL + 'product/getProductsTopicSubtopicWiseFrontEnd' + '/' + getLanguage() + '/' + topic_id + '/' + sub_topic_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ products: response })
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;

                })


                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'event/getTopicSubtopicDetailsFrontEnd' + '/' + getLanguage() + '/' + topic_id + '/' + sub_topic_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventdetails: response })
                    // var topic_name = this.state.eventdetails.topic_name;
                    this.state.eventdetails.map((infos, ii) => {
                        if (ii == '0') {
                            this.setState({ share_image_url: infos.image_name });
                            this.setState({ share_current_image_url: window.location.href });
                            this.setState({ social_description: infos.topic_title_en });
                        }
                    })

                })

            fetch(myConstClass.BASE_URL + 'event/getAllSubtopicsFrontEnd' + '/' + getLanguage() + '/' + topic_id)
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrMagazine: response }))
                .catch(err => console.log(err))


        }
        // this.state.guestinfo.event_type_name =  answer_array['2'];
    }



    applyFilters() {
        const answer_array = this.props.location.pathname.split('/');
        var topic_id = $('#topic_id').val();
        if (topic_id == '') {
            topic_id = 0;
        }
        var language_id = $('#language_id').val();
        if (language_id == '') {
            language_id = 0;
        }
        var event_type = answer_array['3'];
        if (event_type == 1) {
            var path = 'Residential Study Courses/1' + '/' + topic_id + '/' + language_id;
        } else if (event_type == 2) {
            var path = "1-Day Workshops/2" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 4) {
            var path = "Rational Poojs/4" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 3) {
            var path = "Machine Tests/3" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 5) {
            var path = "Free Events/5" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 6) {
            var path = "Organize a Trip/6" + '/' + topic_id + '/' + language_id;
        } else {
            var path = "Events/0" + '/' + topic_id + '/' + language_id;
        }
        if (event_type == 3) {
            this.props.history.push('/machine-test/' + path);
        } else {
            this.props.history.push('/events/' + path);
        }

    }

    renderType = ({ id, title }) => <div className="row">
        <div className="col">
            <div className="col pt-3">
                <h5 className="font-weight-bold">{title}</h5>
            </div>
        </div>
    </div>

    renderGuest = ({ type_id, title, price, quantity }) =>
        <div><div className="row"><div className="col-md-3 mb-3">
            <div className="col border text-center">
                <div className=" py-3">
                    <img src={require("./assets/images/product1.png")} />
                    <div className="my-2">
                        <span className="mr-2">
                            <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                        </span>
                    </div>
                    <p className="font-weight-bold mb-1">{title}</p>
                    <p className="mb-1">Donation Contribution</p>
                    <p className="font-weight-bolder">INR. {price}/-</p>
                    <div className="qty mb-3">
                        <span>Qty-</span>
                        <span className="minus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-minus"></i>
                            </span>
                        </span>
                        <input type="number" className="count" name="qty" value={quantity} />
                        <span className="plus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-plus"></i>
                            </span>
                        </span>
                    </div>
                    <button className="btn btn-brown btn-sm">ADD TO CART</button>
                </div>
            </div></div></div></div>

    render() {
        const { arrMagazine, products, cartEvent, eventdetails, scheduleEvent, eventTypes, posts, types, languages, topic, guestinfo } = this.state
        const { t } = this.props;
        return (
            <div className="App">
                <div>
                    <ToastContainer />
                </div>
                <Header />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-white mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">{t('home.home')}</Link>

                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0)">{t('home.Topics')}
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            {eventdetails.map((infos, ii) => {
                                                return (
                                                    <span id="CurrentEventType">
                                                        <Link style={{ color: 'black' }} to={'/topics/' + infos.topic_title_en + '/' + infos.topic_id}>
                                                            {infos.topic_title_en}
                                                        </Link>
                                                        <span id="subTopicName" style={{ color: 'black' }}>&nbsp;</span> {infos.sub_topic_name}</span>
                                                )
                                            })
                                            }
                                        </li>

                                    </ol>
                                </nav>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {eventdetails.map((infos, ii) => {
                                        return (
                                            <span id="CurrentEventType" className="topicCMS">{infos.topic_title_en}: {infos.sub_topic_name}</span>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            <br />
                            <div><label>{t('home.Please_select_the_subtopics_given_below_to_show_related_events_and_products')}</label></div>
                            <div className="my-4 d-xl-block d-lg-block d-md-block d-none" >
                                <div className="row flex-row overflow-auto py-2 navigate-scrollbar">
                                    {topic.map((info, ii) => {
                                        if (this.state.guestinfo.sub_topic_id == info.sub_topic_id) {
                                            return (
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                                                    <Link className="SubtopicFont subtopicSelected" to={"/topics/" + info.subtopic_title_english + '/' + info.topic_id + '/' + info.sub_topic_id}   >{info.subtopic_title}</Link>
                                                </div>

                                            )
                                        } else {
                                            return (
                                                <div className={ ii>3 ?"col-xl-3 col-lg-3 col-md-3 col-6 mt-4":"col-xl-3 col-lg-3 col-md-3 col-6"} >
                                                    <Link className="SubtopicFont" style={{ color: '#ed9a16' }} to={"/topics/" + info.subtopic_title_english + '/' + info.topic_id + '/' + info.sub_topic_id}   >{info.subtopic_title}</Link>
                                                </div>

                                            )
                                        }

                                    })
                                    }
                                </div>
                            </div>

                            <div className="my-4 d-xl-none d-lg-none d-md-none d-block">
                                <div id="subTopicsCarousel" className="carousel slide" data-ride="carousel" data-interval="false">
                                    <div className="row">
                                        <div className="col-1 offset-1 text-center">
                                            <a href="#subTopicsCarousel" role="button" data-slide="prev">
                                                <i className="color-black fa fa-angle-left"></i>
                                            </a>
                                        </div>
                                        <div className="col-8 text-center">
                                            <div class="carousel-inner">
                                                {topic.map((info, ii) => {
                                                    if (this.state.guestinfo.sub_topic_id == info.sub_topic_id) {
                                                        return (
                                                            <div className="active carousel-item">
                                                                <Link className="SubtopicFont subtopicSelected" to={"/topics/" + info.subtopic_title_english + '/' + info.topic_id + '/' + info.sub_topic_id}   >{info.subtopic_title}</Link>
                                                            </div>

                                                        )
                                                    } else {
                                                        return (
                                                            <div className={ii === 0 && !this.state.guestinfo.sub_topic_id ? "active carousel-item" : "carousel-item"}>
                                                                <Link className="SubtopicFont" style={{ color: '#ed9a16' }} to={"/topics/" + info.subtopic_title_english + '/' + info.topic_id + '/' + info.sub_topic_id}>{info.subtopic_title}</Link>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                }
                                            </div>
                                        </div>
                                        <div className="col-1 text-center">
                                            <a href="#subTopicsCarousel" role="button" data-slide="next">
                                                <i className="color-black fa fa-angle-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {posts.map((eventType, ii) => {
                                if (eventType.arr_events.length > 0) {
                                    return (
                                        <div className="row">
                                            <div className="col">


                                                <div className="col d-xl-none d-lg-none d-md-none d-flex px-0">
                                                    {eventType.event_type_id === '1' && <img src={ResidentialStudyCourses} height={77} />}
                                                    {eventType.event_type_id === '2' && <img src={OneDayWorkshop} height={77} />}
                                                    {eventType.event_type_id === '3' && <img src={MachineTests} height={77} />}
                                                    {eventType.event_type_id === '4' && <img src={RationalPoojas} height={77} />}
                                                    {eventType.event_type_id === '5' && <img src={FreeEvents} height={77} />}
                                                </div>
                                                <div className="col p-3 text-white mt-5 relatedProductsImage d-xl-flex d-lg-flex d-md-flex d-none" style={{ backgroundImage: "url(" + myConstClass.IMAGE_URL + eventType.background_image + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} >

                                                </div>

                                                {eventType.arr_events.map((info, ii) => {
                                                    if (info.event_type_id == 2) {
                                                        if (info.is_available == 1) {
                                                            return (
                                                                <div>
                                                                    {info.arr_english.map((lang, iiiiii) => {
                                                                        if (iiiiii == 0) {
                                                                            return (
                                                                                <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>
                                                                                    {t('home.Language')}: &nbsp; {t('home.English Language Name')}
                                                                                    <br />
                                                                                    <Link to={"/event_details/" + lang.event_id} style={{ textDecoration: 'underline' }}>{lang.event_title}</Link>
                                                                                </label>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>

                                                                                    <br />
                                                                                    <Link to={"/event_details/" + lang.event_id} style={{ textDecoration: 'underline' }}>{lang.event_title}</Link>
                                                                                </label>
                                                                            )
                                                                        }
                                                                    })
                                                                    }
                                                                    <br />
                                                                    {info.arr_marathi.map((lang, iiiiii) => {
                                                                        if (iiiiii == 0) {
                                                                            return (
                                                                                <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>
                                                                                    {t('home.Language')}:&nbsp; {t('home.Marathi Language Name')}
                                                                                    <br />
                                                                                    <Link to={"/event_details/" + lang.event_id} style={{ textDecoration: 'underline' }}>{lang.event_title}</Link>
                                                                                </label>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>

                                                                                    <br />
                                                                                    <Link to={"/event_details/" + lang.event_id} style={{ textDecoration: 'underline' }}>{lang.event_title}</Link>
                                                                                </label>
                                                                            )
                                                                        }
                                                                    })
                                                                    }

                                                                    <br />
                                                                    {info.arr_hindi.map((lang, iiiiii) => {
                                                                        if (iiiiii == 0) {
                                                                            return (
                                                                                <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>
                                                                                    {t('home.Language')}:&nbsp; {t('home.Hindi Language Name')}
                                                                                    <br />
                                                                                    <Link to={"/event_details/" + lang.event_id} style={{ textDecoration: 'underline' }}>{lang.event_title}</Link>
                                                                                </label>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>

                                                                                    <br />
                                                                                    <Link to={"/event_details/" + lang.event_id} style={{ textDecoration: 'underline' }}>{lang.event_title}</Link>
                                                                                </label>
                                                                            )
                                                                        }
                                                                    })
                                                                    }

                                                                    <br />
                                                                    {info.arr_gujrati.map((lang, iiiiii) => {
                                                                        if (iiiiii == 0) {
                                                                            return (
                                                                                <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>
                                                                                    {t('home.Language')}:&nbsp; {t('home.Gujarati Language Name')}
                                                                                    <br />
                                                                                    <Link to={"/event_details/" + lang.event_id} style={{ textDecoration: 'underline' }}>{lang.event_title}</Link>
                                                                                </label>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>

                                                                                    <br />
                                                                                    <Link to={"/event_details/" + lang.event_id} style={{ textDecoration: 'underline' }}>{lang.event_title}</Link>
                                                                                </label>
                                                                            )
                                                                        }
                                                                    })
                                                                    }




                                                                </div>
                                                            )
                                                        }

                                                    } else {
                                                        return (
                                                            <label className="mt-3" style={{ marginLeft: '10px', textDecoration: 'underline', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>

                                                                <Link to={"/event_details/" + info.event_id}   >{info.event_title}</Link>
                                                            </label>

                                                        )
                                                    }

                                                })
                                                }

                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (

                                        <div className="row">
                                            <div className="col ">

                                                <div className="col p-3 text-white mt-5 relatedProductsImage d-xl-flex d-lg-flex d-md-flex d-none" style={{ backgroundImage: "url(" + myConstClass.IMAGE_URL + eventType.background_image + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} >

                                                </div>


                                                <div className="col d-xl-none d-lg-none d-md-none d-flex px-0">
                                                    {eventType.event_type_id === '1' && <img src={ResidentialStudyCourses} height={77} />}
                                                    {eventType.event_type_id === '2' && <img src={OneDayWorkshop} height={77} />}
                                                    {eventType.event_type_id === '3' && <img src={MachineTests} height={77} />}
                                                    {eventType.event_type_id === '4' && <img src={RationalPoojas} height={77} />}
                                                    {eventType.event_type_id === '5' && <img src={FreeEvents} height={77} />}
                                                </div>

                                                <label className="my-3" style={{ marginLeft: '10px', textDecoration: 'none', maxWidth: '90%', fontSize: '16px', minWidth: '90%', marginBottom: '0px', verticalAlign: 'top' }}>
                                                    {t('home.No events available')}
                                                </label>


                                            </div>
                                        </div>
                                    )
                                }
                            })
                            }



                            {products.map((productType, ii) => {
                                if (productType.arr_products.length > 0) {
                                    return (
                                        <div className="row">
                                            <div className="col">

                                                <div className="col d-xl-none d-lg-none d-md-none d-flex px-0">
                                                    {productType.product_type_id === '1' && <img src={Books} height={77} />}
                                                    {productType.product_type_id === '2' && <img src={KITs} height={77} />}
                                                    {productType.product_type_id === '3' && <img src={CDs} height={77} />}
                                                    {productType.product_type_id === '4' && <img src={ExperimentalProducts} height={77} />}
                                                    {productType.product_type_id === '5' && <img src={AyurvedicProducts} height={77} />}
                                                </div>
                                                <div className="col p-3 text-white mt-5 relatedProductsImage d-xl-flex d-lg-flex d-md-flex d-none" style={{ backgroundImage: "url(" + myConstClass.IMAGE_URL + productType.background_image + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} >

                                                </div>
                                                {productType.arr_products.map((info, ii) => {
                                                    if (productType.product_type_id == 2) {
                                                        if (info.is_available == 1) {
                                                            return (
                                                                <div>
                                                                    {info.arr_english.map((lang, iiiiii) => {
                                                                        if (iiiiii == 0) {
                                                                            return (
                                                                                <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>
                                                                                    {t('home.Language')}:&nbsp; {t('home.English Language Name')}
                                                                                    <br />
                                                                                    <Link to={"/products_details/" + info.product_title + '/' + info.product_id} style={{ textDecoration: 'underline' }}>{lang.product_title}</Link>
                                                                                </label>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>

                                                                                    <br />
                                                                                    <Link to={"/products_details/" + info.product_title + '/' + info.product_id} style={{ textDecoration: 'underline' }}>{lang.product_title}</Link>
                                                                                </label>
                                                                            )
                                                                        }
                                                                    })
                                                                    }
                                                                    <br />
                                                                    {info.arr_marathi.map((lang, iiiiii) => {
                                                                        if (iiiiii == 0) {
                                                                            return (
                                                                                <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>
                                                                                    {t('home.Language')}:&nbsp;{t('home.Marathi Language Name')}
                                                                                    <br />
                                                                                    <Link to={"/products_details/" + info.product_title + '/' + info.product_id} style={{ textDecoration: 'underline' }}>{lang.product_title}</Link>
                                                                                </label>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>

                                                                                    <br />
                                                                                    <Link to={"/products_details/" + info.product_title + '/' + info.product_id} style={{ textDecoration: 'underline' }}>{lang.product_title}</Link>
                                                                                </label>
                                                                            )
                                                                        }
                                                                    })
                                                                    }

                                                                    <br />
                                                                    {info.arr_hindi.map((lang, iiiiii) => {
                                                                        if (iiiiii == 0) {
                                                                            return (
                                                                                <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>
                                                                                    {t('home.Language')}:&nbsp; {t('home.Hindi Language Name')}
                                                                                    <br />
                                                                                    <Link to={"/products_details/" + info.product_title + '/' + info.product_id} style={{ textDecoration: 'underline' }}>{lang.product_title}</Link>
                                                                                </label>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>

                                                                                    <br />
                                                                                    <Link to={"/products_details/" + info.product_title + '/' + info.product_id} style={{ textDecoration: 'underline' }}>{lang.product_title}</Link>
                                                                                </label>
                                                                            )
                                                                        }
                                                                    })
                                                                    }

                                                                    <br />
                                                                    {info.arr_gujrati.map((lang, iiiiii) => {
                                                                        if (iiiiii == 0) {
                                                                            return (
                                                                                <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>
                                                                                    {t('home.Language')}:&nbsp;{t('home.Gujarati Language Name')}
                                                                                    <br />
                                                                                    <Link to={"/products_details/" + info.product_title + '/' + info.product_id} style={{ textDecoration: 'underline' }}>{lang.product_title}</Link>
                                                                                </label>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <label className="mt-3" style={{ marginLeft: '10px', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>

                                                                                    <br />
                                                                                    <Link to={"/products_details/" + info.product_title + '/' + info.product_id} style={{ textDecoration: 'underline' }}>{lang.product_title}</Link>
                                                                                </label>
                                                                            )
                                                                        }
                                                                    })
                                                                    }




                                                                </div>
                                                            )
                                                        }

                                                    } else {
                                                        return (
                                                            <label className="mt-3" style={{ marginLeft: '10px', textDecoration: 'underline', maxWidth: '30%', fontSize: '16px', minWidth: '30%', verticalAlign: 'top' }}>

                                                                <Link to={"/products_details/" + info.product_title + '/' + info.product_id}   >{info.product_title}</Link>
                                                            </label>

                                                        )
                                                    }

                                                })
                                                }

                                            </div>
                                        </div>
                                    )


                                } else {
                                    return (

                                        <div className="row">
                                            <div className="col">

                                                <div className="col d-xl-none d-lg-none d-md-none d-flex px-0">
                                                    {productType.product_type_id === '1' && <img src={Books} height={77} />}
                                                    {productType.product_type_id === '2' && <img src={KITs} height={77} />}
                                                    {productType.product_type_id === '3' && <img src={CDs} height={77} />}
                                                    {productType.product_type_id === '4' && <img src={ExperimentalProducts} height={77} />}
                                                    {productType.product_type_id === '5' && <img src={AyurvedicProducts} height={77} />}
                                                </div>
                                                <div className="col p-3 text-white mt-5 relatedProductsImage d-xl-flex d-lg-flex d-md-flex d-none" style={{ backgroundImage: "url(" + myConstClass.IMAGE_URL + productType.background_image + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} >

                                                </div>


                                                <label className="my-3" style={{ marginLeft: '10px', textDecoration: 'none', maxWidth: '90%', fontSize: '16px', minWidth: '90%', marginBottom: '0px', verticalAlign: 'top' }}>

                                                    {t('home.No products available')}
                                                </label>


                                            </div>
                                        </div>
                                    )
                                }
                            })
                            }

                        </div>
                        <div className="col-lg-3 pt-3" id="eventRightMenuMain" style={{ marginTop: '154px' }}>
                            {arrMagazine.map((cell, i) => {
                                if (cell.magazine_id == 2) {
                                    return (
                                        <div className="col py-2 border border-warning bg-white box-shadow mb-3">

                                            <div className="row">
                                                <div className="col-12">
                                                    <strong>
                                                        {cell.magazine_title}
                                                    </strong>
                                                    <p>
                                                        {ReactHtmlParser(cell.description)}

                                                    </p>
                                                    <p>
                                                        <strong>{t('home.Language')}:</strong>

                                                        {cell.language}
                                                    </p>
                                                    <p>

                                                        {cell.images_array.map((info, ii) => {
                                                            if (ii == 0) {
                                                                if (info.issue_id > 0 && info.issue_id != undefined) {
                                                                    return (
                                                                        <Link to={'/magazine_details/' + info.magazine_id + '/' + info.issue_id}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>

                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Link to={'/magazine'}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>

                                                                    )
                                                                }
                                                            } else {
                                                                return (
                                                                    <Link to={'/magazine'}>
                                                                        <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                    </Link>
                                                                )
                                                            }
                                                        }
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="col-12 text-center">
                                                    <Link className="btn btn-brown btn-sm btn-block magazineImageButtonRight" to={'/magazine-subscription/' + cell.magazine_id}>{t('home.SUBSCRIBE')}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="col py-2 border border-warning bg-white box-shadow mb-3">

                                            <div className="row">
                                                <div className="col-12">
                                                    <strong>
                                                        {cell.magazine_title}
                                                    </strong>
                                                    <p>
                                                        {ReactHtmlParser(cell.description)}

                                                    </p>
                                                    <p>
                                                        <strong>{t('home.Language')}:</strong>

                                                        {cell.language}
                                                    </p>
                                                    <p>

                                                        {cell.images_array.map((info, ii) => {
                                                            if (ii == 0) {
                                                                if (info.product_id > 0) {
                                                                    return (
                                                                        <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />

                                                                            <p >
                                                                                <Link className="btn btn-brown btn-sm mb-2 btn-block magazineImageButtonRight" to={'/products_details/' + info.title + '/' + info.product_id}>{t('home.BUY NOW')}</Link>
                                                                            </p>
                                                                        </Link>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Link to={'/products'}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            <p >
                                                                                <Link className="btn btn-brown btn-sm mb-2 btn-block magazineImageButtonRight" to={'/products'}>{t('home.BUY NOW')}</Link>
                                                                            </p>
                                                                        </Link>
                                                                    )
                                                                }

                                                            } else {
                                                                if (info.product_id > 0) {
                                                                    return (
                                                                        <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Link to={'/products'}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>
                                                                    )
                                                                }
                                                            }
                                                        }
                                                        )}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
                <Footer share_image_url={this.state.share_image_url} description={guestinfo.social_description} share_current_image_url={this.state.share_current_image_url} />
            </div>
        );
    }
}

Topics.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Topics);
