import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  CloseButton,
  Form,
  ListGroup,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import CustomDatePicker from "./DatePicker";
import Footer from "./Footer.js";
import Header from "./Header.js";
import Stepper from "./Stepper";
import FixedBottomSummary from "./components/_FixedBottomSummary";
import * as myConstClass from "./constant.js";
import { BASE_URL } from "./constant.js";
import "./eventDetails.css";
import {
  getCartTotalPriceAsync,
  manageHasEventsAsync,
  selectCheckoutSteps,
  selectTncEventsChecked,
  selectTotalPrice,
  stepsEnum,
} from "./store/checkoutSlice";
import "./styles/bs-accordion.css";
import "./styles/bs-button.css";
import "./styles/bs-overrides.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { sub } from "date-fns";

const EventDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const checkoutSteps = useSelector(selectCheckoutSteps);
  const totalPrice = useSelector(selectTotalPrice);
  const tncEventsChecked = useSelector(selectTncEventsChecked);

  const bottomSummaryRef = useRef(null);

  const [fullName, setFullName] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [enableSave, setEnableSave] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [persons, setPersons] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [active, setActive] = useState("");
  const [schedule_id, setSchedule_id] = useState(null);
  const [event_id, setEvent_id] = useState(null);
  const [isParticipantsSelected, setIsParticipantsSelected] = useState({});

  const [isAgreed, setIsAgreed] = useState(false);

  const [showModal, setShowModal] = useState(false);

  // State for selected persons of all events.
  const [activeEventParticipants, setActiveEventParticipants] = useState([]);

  useEffect(() => {
    // console.log(localStorage.getItem("user_id"))
    const user_id = localStorage.getItem("user_id");
    if (user_id) {
      localStorage.removeItem("error");
    } else {
      localStorage.setItem("error", "please login first");
      return history.replace("/signin");
    }
  }, []);

  useEffect(() => {
    dispatch(getCartTotalPriceAsync());
    dispatch(manageHasEventsAsync());
    loadEvents();
  }, [dispatch]);

  function handleConfirmation() {
    // windows, android & evertyhing normal
    const url = "TnCEvents.pdf";
    const link = document.createElement("a");
    link.rel = "noopener noreferrer";
    link.href = url;
    link.target = "_blank";
    link.download = "Terms & Conditions for Events.pdf";
    document.body.appendChild(link);
    link.click();

    // ios workaround
    // window.open(url, "_blank", "popup");
    window.open(url, "_blank", "toolbar=no,location=no,status=no,menubar=no");

    // history.push("/address");
    // const _location = new URL(window.location)
    // window.history.pushState({}, null, "/address");
    // window.history.replaceState({}, null, "/address");
    // window.location.href = "/address";
  }

  async function loadEvents() {
    try {
      const user_id = localStorage.getItem("user_id");
      const generalEventsUrl = `${BASE_URL}cart/getAllMyCartDataForEvents/${user_id}/en`;
      const generalEventsData = await (await fetch(generalEventsUrl)).json();
      const machineEventsUrl = `${BASE_URL}cart/getAllMyCartDataForMachineTestEvents/${user_id}/en`;
      const machineEventsData = await (await fetch(machineEventsUrl)).json();

      let _allEvents = [...generalEventsData, ...machineEventsData];
      setEventsData(_allEvents);

      let _participantsSelectionState = {};
      _allEvents = _allEvents.map((evs) => evs.arr_cart).flat();
      _allEvents.forEach((key) => {
        _participantsSelectionState["" + key.event_id] = false;
      });
      setIsParticipantsSelected(_participantsSelectionState);

      console.log(_allEvents);
      setActive(_allEvents[0]?.event_id ?? null);
      // setEvent_id(_allEvents[0]?.event_id ?? null);
      // setSchedule_id(_allEvents[0]?.schedule_id ?? null);
    } catch (error) {
      console.error(error);
    }
  }

  async function loadParticipants() {
    try {
    } catch (error) {}
  }

  // nothing here ...
  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
  }, []);

  function isSelectionComplete(_state) {
    for (const key in _state) {
      if (_state[key] === false) return false;
    }

    if (Object.keys(_state).length > 0) {
      setTimeout(() => {
        const footerPosition =
          bottomSummaryRef.current?.getBoundingClientRect()?.top;
        window.scrollTo({
          top: footerPosition * -1,
          behavior: "smooth",
        });
      }, 100);
    }

    return true;
  }

  async function getParticipantsForSchedule() {
    try {
      const user_id = localStorage.getItem("user_id");

      const participantsUrl = `${BASE_URL}event/getAllExistingParticipates/${schedule_id}/${user_id}/en`;
      const participantsReqData = await (await fetch(participantsUrl)).json();
      setPersons(participantsReqData?.reverse());

      const _tempParticipants = participantsReqData.filter(
        (el) => +el?.participants_temp_id === 1
      );
      setActiveEventParticipants([..._tempParticipants]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getParticipantsForMachineTest() {
    try {
      const user_id = localStorage.getItem("user_id");

      const participantsUrl = `${BASE_URL}event/getAllExistingParticipatesMachineTest/${event_id}/${user_id}/en`;
      const participantsReqData = await (await fetch(participantsUrl)).json();
      setPersons(participantsReqData?.reverse());

      const _tempParticipants = participantsReqData.filter(
        (el) => +el?.participants_temp_id === 1
      );
      setActiveEventParticipants([..._tempParticipants]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (!active) return;
    let _allEvents = [...eventsData];
    _allEvents = _allEvents.map((eg) => eg.arr_cart).flat();
    const _event = _allEvents.find((e) => e.event_id === active);
    setEvent_id(_event?.event_id ?? null);
    setSchedule_id(_event?.schedule_id ?? null);
  }, [active]);

  useEffect(() => {
    if (event_id) {
      if (schedule_id) {
        getParticipantsForSchedule();
      } else {
        getParticipantsForMachineTest();
      }
    }
  }, [event_id, schedule_id]);

  const calculateAge = (birthDate) => {
    var dob = new Date(birthDate);
    var currentDate = new Date();

    // Do This with date-fns formatDistance
    // better to use standard library that doing own calc

    var ageInMilliseconds = currentDate - dob;

    // Average days in a month
    var ageInMonths = Math.floor(
      ageInMilliseconds / (30.44 * 24 * 60 * 60 * 1000)
    );

    if (ageInMonths < 12) {
      if (ageInMonths === 1) {
        return `${ageInMonths} Month`;
      } else {
        return `${ageInMonths} Months`;
      }
    } else {
      var ageInYears = Math.floor(ageInMonths / 12);
      if (ageInYears === 1) {
        return `${ageInYears} Year`;
      } else {
        return `${ageInYears} Years`;
      }
    }
  };

  useEffect(() => {
    if (fullName && gender) setEnableSave(true);
    else setEnableSave(false);
  }, [fullName, gender]);

  const saveParticipant = async (event) => {
    event.preventDefault();
    const user_id = localStorage.getItem("user_id");

    let _dateOfBirth;
    if (!dateOfBirth) {
      _dateOfBirth = sub(new Date(), { years: 10, days: 1 })
        .toISOString()
        .split("T")[0];
    } else {
      console.log("typeof", typeof dateOfBirth);
      _dateOfBirth = dateOfBirth.toISOString().split("T")[0];
    }

    console.log("_dateOfBirth", _dateOfBirth);

    const newPerson = [
      { name: "participate_first_name", value: fullName },
      { name: "participate_gender", value: gender },
      { name: "birth_date", value: _dateOfBirth },
      { name: "participate_age", value: calculateAge(_dateOfBirth) },
    ];

    const addParticipantUrl = `${myConstClass.BASE_URL}event/addParticipants/${user_id}`;
    const addParticipantsData = await (
      await fetch(addParticipantUrl, {
        method: "POST",
        body: JSON.stringify(newPerson),
      })
    ).json();

    if (addParticipantsData.status) {
      toast("" + addParticipantsData?.message, {
        containerId: "B",
        type: toast.TYPE.SUCCESS,
      });

      const _existing = persons.map((el) => el.participant_id);

      const participantsUrl = `${BASE_URL}event/getAllExistingParticipates/${schedule_id}/${user_id}/en`;
      const participantsReqData = await (await fetch(participantsUrl)).json();

      setPersons(participantsReqData?.reverse());
      const newAdded = participantsReqData.filter(
        (el) => !_existing.includes(el.participant_id)
      );

      setActiveEventParticipants([...activeEventParticipants, ...newAdded]);
      setFormSubmitted(true);

      setFullName("");
      setGender("");
      setDateOfBirth(null);
      setEnableSave(false);
      setFormSubmitted(false);
    } else {
      toast("" + addParticipantsData?.message, {
        containerId: "B",
        type: toast.TYPE.ERROR,
      });
    }
  };

  async function confirmParticipantsForEvent(event) {
    try {
      console.log(event.event_id);

      const user_id = localStorage.getItem("user_id");

      let _allEvents = [...eventsData];
      _allEvents = _allEvents.map((eg) => eg.arr_cart).flat();
      const _event = _allEvents.find((e) => e.event_id === active);

      let fetchUrl = "";
      if (_event.schedule_id) {
        fetchUrl = `${myConstClass.BASE_URL}event/addScheduleParticipants/${schedule_id}/${user_id}`;
        setSchedule_id(_event.schedule_id);
      } else if (_event.event_id) {
        fetchUrl = `${myConstClass.BASE_URL}event/addMachineTestParticipants/${event_id}/${user_id}`;
      } else {
        throw new Error("No Event Selected");
      }

      const payload = activeEventParticipants.map((el) => ({
        name: el.participant_id,
        value: el.participant_id,
      }));

      const saveParticipantsData = await (
        await fetch(fetchUrl, {
          method: "POST",
          headers: {
            "Content-Type": "text/plain;charset=UTF-8",
          },
          body: JSON.stringify(payload),
        })
      ).json();

      if (saveParticipantsData.status === "true") {
        toast("" + saveParticipantsData?.message, {
          containerId: "B",
          type: toast.TYPE.SUCCESS,
        });

        // let currentEventIndex = _allEvents.findIndex(
        //   (e) => e.event_id === active
        // );

        // if (currentEventIndex < 0) throw new Error("Bad Event Selected");

        setIsParticipantsSelected((prev) => {
          prev["" + active] = true;
          return { ...prev };
        });

        // next key
        const _participantsSelectionState = { ...isParticipantsSelected };
        for (const key in _participantsSelectionState) {
          // console.log("forin key", key);
          if (_participantsSelectionState[key] === false) {
            setActive(key);
            return null;
          } else {
            setActive(null);
          }
        }
      } else {
        toast("" + saveParticipantsData?.message, {
          containerId: "B",
          type: toast.TYPE.ERROR,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handlePersonSelect = (selectedPerson, isSelected) => {
    let newActiveParticipants = [];
    if (isSelected) {
      newActiveParticipants = [...activeEventParticipants, selectedPerson];
    } else {
      newActiveParticipants = activeEventParticipants.filter(
        (person) => person.participant_id !== selectedPerson.participant_id
      );
    }
    setActiveEventParticipants(newActiveParticipants);
  };

  const disableSave = useCallback(() => {
    try {
      if (!eventsData.length) return true;
      if (!active) return true;

      let _allEvents = [...eventsData];
      _allEvents = _allEvents.map((eg) => eg.arr_cart).flat();
      let _currentEvent = _allEvents.find((e) => e.event_id === active);

      const child = +_currentEvent?.child ?? 0,
        // parent = +_currentEvent?.parent ?? 0,
        // person = +_currentEvent?.person ?? 0,
        parent_couple = +_currentEvent?.parent_couple ?? 0;

      // :TODO This need to revisit for types of participants
      // look for these keys in the response first_price_for, second_price_for this often defines
      // wheather parent, parent_couple, child or person key to use to calculate
      if (parent_couple + child === activeEventParticipants.length) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }, [activeEventParticipants]);

  function handleProceed() {
    setShowModal(true);
  }

  const handleDateChange = (date) => {
    setDateOfBirth(date);
    // setAge(calculateAge(date));
  };

  useEffect(() => {
    setIsAgreed(tncEventsChecked);
  }, [tncEventsChecked]);

  return (
    <>
      <ToastContainer />
      <Header />

      {/* <pre className="mt-5">
        {JSON.stringify(
          {
            active,
            event_id,
            schedule_id,
            isParticipantsSelected,
            activeEventParticipants,
          },
          null,
          2
        )}
      </pre> */}

      <div className="mt-4 pt-2 mt-sm-4 pt-sm-4 mt-md-4 pt-md-4 mt-xl-1">
        <Stepper steps={checkoutSteps} currentStep={stepsEnum[1]} />
      </div>

      <div className="container container-xl mb-5">
        <Accordion
          activeKey={active}
          onSelect={(e) => {
            setActive(e);
          }}
        >
          {eventsData?.map((eventGroup) => (
            <>
              {eventGroup.arr_cart?.map((event) => (
                <>
                  <Accordion.Item
                    key={event.event_id}
                    eventKey={event.event_id}
                    className="mb-1"
                  >
                    <Accordion.Header>
                      <div>
                        <h5>
                          <b>{event.title}</b>
                        </h5>
                        <strong className="d-block">{event.city_name}</strong>
                        <strong className="d-block pt-2">
                          Add {+event.parent_couple + +event.child} Participants
                          or Select from list of Saved Participants
                        </strong>
                      </div>
                    </Accordion.Header>

                    <Accordion.Body>
                      <div className="row no-gutter">
                        <Form
                          onSubmit={saveParticipant}
                          className="col-md-6 col-12"
                        >
                          <h5 className="mb-3">Add new Participant</h5>
                          <Form.Group controlId="formName">
                            <Form.Label className="mb-1">
                              Full Name
                              <span className="red-asterisk fw-bold">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={fullName}
                              placeholder="Enter Full Name"
                              className="mb-3 mt-0"
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const nameRegex = /^[A-Za-z\s]+$/;
                                if (
                                  inputValue === "" ||
                                  nameRegex.test(inputValue)
                                ) {
                                  setFullName(inputValue);
                                }
                              }}
                              required
                            />
                            {fullName !== "" &&
                              !/^[A-Za-z\s]+$/.test(fullName) && (
                                <Form.Text className="text-danger">
                                  Enter a valid Full Name (letters and spaces
                                  only).
                                </Form.Text>
                              )}
                          </Form.Group>

                          <Form.Group controlId="formGender">
                            <Form.Label className="mb-1">
                              Gender<span class="red-asterisk fw-bold">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              value={gender}
                              required
                              className="mb-3 mt-0"
                              onChange={(e) => {
                                setGender(e.target.value);
                              }}
                            >
                              <option value="">Select Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </Form.Control>
                          </Form.Group>

                          {/* <CustomDatePicker
                            label="Date Of Birth (Optional, but please fill for children)"
                            selectedDate={date_of_birth}
                            onDateChange={handleDateChange}
                            formSubmitted={formSubmitted}
                          />
                           */}

                          <Form.Group controlId="formDOB" className="mb-4">
                            <Form.Label className="mb-1">
                              Date of Birth
                            </Form.Label>
                            <div className="form-control">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                  disableFuture
                                  className="w-100"
                                  openTo="year"
                                  format="dd/MM/yyyy"
                                  id="birth_date"
                                  name="birth_date"
                                  placeholder="dd/mm/yyyy"
                                  views={["year", "month", "date"]}
                                  value={dateOfBirth}
                                  onChange={handleDateChange}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                          </Form.Group>

                          <div className="mb-4">
                            <button
                              type="submit"
                              className="btn px-5 py-2 add-person-btn"
                              disabled={!enableSave}
                            >
                              Save
                            </button>
                          </div>
                        </Form>

                        <div className="col-md-6 col-12">
                          <h5 className="mb-3">
                            Saved Participants
                            <span>
                              {activeEventParticipants.length <
                              +event.parent_couple + +event.child
                                ? ` - Select ${
                                    +event.parent_couple +
                                    +event.child -
                                    activeEventParticipants.length
                                  }`
                                : ""}
                            </span>
                            <span>
                              {activeEventParticipants.length >
                              +event.parent_couple + +event.child
                                ? ` - Remove ${
                                    activeEventParticipants.length -
                                    +event.parent_couple +
                                    +event.child
                                  }`
                                : ""}
                            </span>
                          </h5>

                          <ListGroup className="masterList">
                            {persons.map((person, index) => (
                              <ListGroup.Item
                                key={index}
                                className="p-3 border"
                              >
                                <div className="custom-control position-static custom-checkbox">
                                  <input
                                    id={person.participant_id}
                                    type="checkbox"
                                    className="custom-control-input"
                                    checked={activeEventParticipants.some(
                                      (activePerson) =>
                                        activePerson.participant_id ===
                                        person.participant_id
                                    )}
                                    onChange={(e) => {
                                      handlePersonSelect(
                                        person,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                  <label
                                    htmlFor={person.participant_id}
                                    className="custom-control-label w-100"
                                  >
                                    <div className="d-flex justify-content-between">
                                      <div className="">
                                        <b>{person.first_name}</b>,{" "}
                                        <small>{person.gender}</small>
                                      </div>
                                      <div className="">
                                        {person.age}{" "}
                                        {new Date(
                                          person.date_of_birth
                                        ).getFullYear() ===
                                        new Date().getFullYear()
                                          ? person.age > 1
                                            ? "Months"
                                            : "Month"
                                          : person.age > 1
                                          ? "Years"
                                          : "Year"}
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </ListGroup.Item>
                            ))}
                          </ListGroup>

                          <div className="my-3 d-flex justify-content-start justify-content-between align-items-center  flex-wrap">
                            <Link to="/participants" className="btn btn-link">
                              Edit/Delete Participants
                            </Link>

                            <button
                              className="btn px-5 py-2 add-person-btn mr-3"
                              onClick={() => {
                                // console.log({ event: event.event_id });
                                confirmParticipantsForEvent(event);
                              }}
                              // disabled={!isConfirmButtonEnabled}
                              disabled={disableSave()}
                            >
                              Confirm {/*[{event?.event_id}]*/}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </>
              ))}
            </>
          ))}
        </Accordion>
      </div>

      <div ref={bottomSummaryRef}>
        <FixedBottomSummary totalPrice={totalPrice}>
          <div>
            <button
              className="w-100 btn py-2 proceed-button text-nowrap"
              onClick={handleProceed}
              disabled={!isSelectionComplete(isParticipantsSelected)}
            >
              <span className="mr-1 fw-bold">Next</span>
              <i className="fas fa-chevron-right fw-bold"></i>
            </button>
          </div>
        </FixedBottomSummary>
      </div>

      <Footer />

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="modal-box"
      >
        <Modal.Header>
          <Modal.Title>Instructions</Modal.Title>
          <CloseButton
            className="btn-sm"
            onClick={() => {
              setShowModal(false);
            }}
          ></CloseButton>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          <div>
            <b>
              Very Important Instructions for Events (Please download & read the
              pdf to avoid inconvenience){" "}
            </b>
            <br />

            <ul className="pl-3">
              <li>
                Arrival for Course at Lonavla by 9.00 am. Vehicle available for
                Trains from Mumbai: Indrayani, Intercity, Deccan Express & local
                train from Pune at 9:30 am outside platform no.1 (Lonavla West).
                Courses end by 4.30 pm to 5.00 pm, Vehicle is available to
                Railway or Bus station.
              </li>
              <li>
                For Residential Courses, Do pack separate bags (with clothing,
                tooth paste-brush, soap, oil etc.) for children and parents.
                Lodging for boys, girls, men, women parents is separate
                (Exception Mendu-Kranti small kids). Do carry your medicines
                along. Clinic is available.
              </li>
              <li>
                Do keep your PAN /AADHAR Nos. handy while coming for the course.
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <a
            href="/address"
            onClick={() => {
              handleConfirmation();
            }}
            className={" btn btn-primary agree-tc "}
          >
            <span className="me-2 mr-2">
              Download Events Terms & Conditions
            </span>

            <i class="fas fa-file-download"></i>
          </a>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EventDetails;
