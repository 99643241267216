import { toast } from "react-toastify";

export function validateField(target, field, { required = false, regex }) {
  let result = `${field}`;

  if (required && !target) {
    result += validateMessages.required;
    toast(result, {
      type: toast.TYPE.ERROR,
    });
    return result;
  }

  if (regex?.pattern && !new RegExp(regex?.pattern).test(target)) {
    result += `${regex?.message}`;
    toast(result, {
      type: toast.TYPE.ERROR,
    });
    return result;
  }
}

export function hasError(errors) {
  // console.log('has error: ',errors);
  for (const key in errors) {
    if (errors[key]) return true;
  }
  return false;
}

export const validateMessages = {
  required: " is required.",
  onlyAlphabets: " must only contain alphabets.",
  pincode: " must be a valid pincode.",
  phone: " must be a valid phone number.",
  email: " must be a valid email address.",
};
