import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import Header from './Header.js';
import Footer from './Footer.js';
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';

// Translation Higher Order Component
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage, getLanguage,
  translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,

} from 'reactstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

class Changepassword extends Component {

  constructor(props) {
    var email = localStorage.getItem('email');
    super(props);
    this.product_id = 0;
    this.state = {
      flag: 0,
      type: 'password',
      type1: 'password',
      type2: 'password',
      guestinfo: {
        user_id: '',
        email: '',
        type: 'password',
        type1: 'password',
        type2: 'password',
      },
      eventinfo: {
        old_password: '',
        new_password: '',
        confirm_password: '',
        email: email,
      },
      removeinfo: {
        message: '',
        status: '',
      },
      country: [],
      city: [],
      shipping_cityshipping: [],
      shipping_countrystate: [],
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    //   this.handleDayChange = this.handleDayChange.bind(this);
    //   this.handleToDayChange = this.handleToDayChange.bind(this);
    this.validator = new SimpleReactValidator();
    this.billing_type = "1";
  }

  //   handleDayChange(day) { alert(day);
  //     var utcDate = day.toLocaleDateString();
  //     $('#birth_date').val(utcDate);
  //     this.state.guestinfo.birth_date_old = utcDate;

  //     this.setState({ selectedDay: day });
  //   }

  //   handleToDayChange(day) { //alert(day);
  //     var utcDate = day.toLocaleDateString();
  //     $('#end_date').val(utcDate);
  //     this.state.eventinfo.end_date = utcDate;
  //     this.setState({ selectedDay: day });
  //   }

  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    var user_id = localStorage.getItem('user_id');
    var email = localStorage.getItem('email');
    $('#email').val(email);
    //alert(email);
    this.state.eventinfo.email = email;
    console.log(this.state.eventinfo.email);
    if (user_id > 0) {

    } else {
      setTimeout(this.props.history.push('signin'), 10);
    }

  }
  handleClick = (e) => {
    this.setState(({ type }) => ({

      type: type == 'text' ? 'password' : 'text'
    }))
    if (e.target.id == 'old_password_hide') {
      $('#old_password_show').css('display', 'inline');
      $('#old_password_hide').css('display', 'none');
    } else {
      $('#old_password_show').css('display', 'none');
      $('#old_password_hide').css('display', 'inline');
    }
  }


  handleClick1 = (e) => {
    this.setState(({ type1 }) => ({

      type1: type1 == 'text' ? 'password' : 'text'
    }))
    if (e.target.id == 'old_password_hide1') {
      $('#old_password_show1').css('display', 'inline');
      $('#old_password_hide1').css('display', 'none');
    } else {
      $('#old_password_show1').css('display', 'none');
      $('#old_password_hide1').css('display', 'inline');
    }
  }

  handleClick2 = (e) => {
    this.setState(({ type2 }) => ({

      type2: type2 == 'text' ? 'password' : 'text'
    }))
    if (e.target.id == 'old_password_hide2') {
      $('#old_password_show2').css('display', 'inline');
      $('#old_password_hide2').css('display', 'none');
    } else {
      $('#old_password_show2').css('display', 'none');
      $('#old_password_hide2').css('display', 'inline');
    }
  }


  componentDidUpdate(prevProps) {

    const answer_array = this.props.location.pathname.split('/');
    const { flag } = this.state;

  }

  handleSubmit(e) {
    e.preventDefault();
    var user_id = localStorage.getItem('user_id');
    console.log('this.refs', $('#pform').serializeArray());
    if (this.validator.allValid()) {
      const formData = {};
      for (const field in this.refs) {
        formData[field] = this.refs[field].value;
      }
      // console.log('-->', formData);
      fetch(myConstClass.BASE_URL + `users/changePasswordFrontEnd/` + '/' + user_id, {
        method: 'POST',
        body: JSON.stringify($('#pform').serializeArray()),

      })
        .then(response => response.json())
        .then(response => {
          console.log('res: ',response);
          this.setState({ guestinfo: response })
          var message = this.state.guestinfo.message;
          var status = this.state.guestinfo.status;
          //console.log('hii'+this.state.guestinfo);
          if (status == 'true') {
            // $('#address').modal('hide');
            // $('#pform')[0].reset();

            const numRows = this.state.guestinfo.length;
            if (numRows > 0) {
              this.state.is_billing_address_available = 1;
            }


            var message = "Your password has been changed successfully.";
            localStorage.setItem('success', message);
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            setTimeout(this.props.history.push('/'), 100);
          } else {
            toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
          }
          //console.log(status);
        })
        .catch(err => console.log(err))
    }
    else {
      //alert('here');
      this.validator.showMessages();
      console.log('validations' + this.validator.showMessages());
      e.preventDefault();
      toast('Please fill all required fields.', { containerId: 'B', type: toast.TYPE.ERROR })
      // toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR})

    }
  }


  render() {

    const { country, shipping_countrystate, city, eventinfo, posts, guestinfo, toast } = this.state
    const { t } = this.props;
    return (

      <div className="App">
        <div>
          {/* <button onClick={this.notify}>Notify !</button> */}
          <ToastContainer />
        </div>
        <Header />
        <div class="container-fluid">

          <div class="row" style={{ marginTop: '10px' }}>
            <div class="col-lg-12 ">
              <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb bg-white mb-0">
                    <li class="breadcrumb-item">
                      <Link to="/">{t('home.home')}</Link>
                    </li>
                    <li class="breadcrumb-item"><a href="#">{t('home.My Account')}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{t('home.Change Password')}</li>
                  </ol>
                </nav>
              </div>
              <div class="row">
                <div class="col">
                  <h4>{t('home.Change Password')}</h4>
                  <p><span class="color-red">* {t('home.Mandatory Fields')}</span></p>
                </div>
              </div>
            </div>

            <div class="col col-lg-10 ">
              {/* <form> */}
              <Form action="" id="pform" onSubmit={this.handleSubmit} method="post" encType="multipart/form-data" className="form-horizontal">

                <div class="row" style={{ marginTop: '-10px' }}>
                  <div class="form-group col col-md-4">
                    <label class="color-black font-weight-bolder">{t('home.Registered Email ID')}<span class="color-red">*</span></label>
                    {/* <input onBlur={() => this.validator.showMessageFor('first_name')} type="text" class="form-control" id="first_name" name="first_name"  placeholder={t('home.Enter First Name')} /> */}
                    <input value={eventinfo.email} type="text" id="email" name="email" class="form-control" disabled />

                  </div>

                </div>
                <div class="row" >
                  <div class="form-group col col-md-4">
                    <label class="color-black font-weight-bolder">{t('home.Existing Password')}<span class="color-red">*</span></label>
                    {/* <input onBlur={() => this.validator.showMessageFor('first_name')} type="text" class="form-control" id="first_name" name="first_name"  placeholder={t('home.Enter First Name')} /> */}
                    <input value={guestinfo.old_password}
                      style={{
                        "right": "-1px",
                        "position": "absolute",
                        "top": "5px", "padding-right": "30px", "position": "relative"
                      }}
                      onChange={e => { this.setState({ guestinfo: { ...guestinfo, old_password: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('old_password')} type={this.state.type} id="old_password" name="old_password" class="form-control" placeholder={t('home.Enter your password')}
                      onPaste={(e) => e.preventDefault()}
                    />
                    {this.validator.message('old_password', this.state.guestinfo.old_password, 'required')}
                    <span id="old_password_show" class="fa fa-fw fa-eye field-icon eyeicon old_password_show"

                      style={{
                        "right": "31px",
                        "position": "absolute",
                        "top": "50px"
                      }}

                      onClick={this.handleClick}></span>

                    <span id="old_password_hide" class="fa fa-eye-slash old_password_hide"

                      style={{
                        "right": "31px",
                        "position": "absolute",
                        "top": "50px"
                      }}

                      onClick={this.handleClick}></span>


                  </div>

                </div>

                <div class="row">
                  <div class="form-group col col-md-4">
                    <label class="color-black font-weight-bolder">{t('home.New Password')}<span class="color-red">*</span></label>
                    {/* <input onBlur={() => this.validator.showMessageFor('first_name')} type="text" class="form-control" id="first_name" name="first_name"  placeholder={t('home.Enter First Name')} /> */}
                    <input value={guestinfo.new_password}
                      style={{
                        "right": "-1px",
                        "position": "absolute",
                        "top": "5px", "padding-right": "30px", "position": "relative"
                      }}
                      onChange={e => {
                        const val = e.target.value.replace(" ", "");
                        this.setState({ guestinfo: { ...guestinfo, new_password: val } }); 
                      }}
                      onBlur={() => this.validator.showMessageFor('new_password')} type={this.state.type1} id="new_password" name="new_password" class="form-control" placeholder={t('home.Enter your password')}
                      onCopy={(e) => e.preventDefault()}
                      onPaste={(e) => e.preventDefault()}
                    />
                    {this.validator.message('new_password', this.state.guestinfo.new_password, 'required')}
                    <span id="old_password_show1" class="fa fa-fw fa-eye field-icon eyeicon neweye old_password_show1"
                      style={{
                        "right": "31px",
                        "position": "absolute",
                        "top": "50px"
                      }}
                      onClick={this.handleClick1}>{this.state.type1 === 'text'}</span>

                    <span id="old_password_hide1" class="fa fa-eye-slash old_password_hide1"
                      style={{
                        "right": "31px",
                        "position": "absolute",
                        "top": "50px"
                      }}
                      onClick={this.handleClick1}></span>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col col-md-4">
                    <label class="color-black font-weight-bolder">{t('home.Confirm New Password')}<span class="color-red">*</span></label>
                    {/* <input onBlur={() => this.validator.showMessageFor('first_name')} type="text" class="form-control" id="first_name" name="first_name"  placeholder={t('home.Enter First Name')} /> */}
                    <input value={guestinfo.confirm_password}
                      style={{
                        "right": "-1px",
                        "position": "absolute",
                        "top": "5px", "padding-right": "30px", "position": "relative"
                      }}
                      onChange={e => {
                        const val = e.target.value.replace(" ", "");
                        this.setState({ guestinfo: { ...guestinfo, confirm_password: val } });
                      }}
                      onBlur={() => this.validator.showMessageFor('confirm_password')} type={this.state.type2} id="confirm_password" name="confirm_password" class="form-control" placeholder={t('home.Enter your password')}
                      onCopy={(e) => e.preventDefault()}
                      onPaste={(e) => e.preventDefault()}
                    />
                    {this.validator.message('confirm_password', this.state.guestinfo.confirm_password, 'required')}
                    <span id="old_password_show2" class="fa fa-fw fa-eye field-icon eyeicon old_password_show2"
                      style={{
                        "right": "31px",
                        "position": "absolute",
                        "top": "50px"
                      }}
                      onClick={this.handleClick2}>{this.state.type2 === 'text'}</span>

                    <span id="old_password_hide2" class="fa fa-eye-slash old_password_hide2"
                      style={{
                        "right": "31px",
                        "position": "absolute",
                        "top": "50px"
                      }}
                      onClick={this.handleClick2}></span>
                  </div>
                </div>

                <Row>
                  <div className="form-group col-12 col-xl-2 col-lg-2 col-md-2">
                    <button class="btn btn-brown btn-block">{t('home.Save')}</button>
                  </div>
                  <div className="form-group col-12 col-xl-2 col-lg-2 col-md-2">
                  <Link class="btn btn-light btn-block" to="/">{t('home.Cancel')}</Link>
                  </div>
                </Row>

              </Form>

            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
Changepassword.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(Changepassword);
