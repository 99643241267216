import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "./assets/images/logo.png";
import { Route, Link, BrowserRouter as Router } from "react-router-dom";
import "./assets/dist/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/rfs.css";
import * as myConstClass from "./constant.js";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { Editor } from "react-draft-wysiwyg";
import $ from "jquery";
import Swal from "sweetalert2";
// import 'sweetalert2/src/sweetalert2.scss';
import "sweetalert2/dist/sweetalert2.min.css";

// Translation Higher Order Component
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  getLanguage,
  translate,
} from "react-switch-lang";
import en from "./en.json";
import ma from "./ma.json";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import SimpleReactValidator from "simple-react-validator";
import ReactPagination from "react-js-pagination";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import ModernDatepicker from "react-modern-datepicker";
import moment from "moment";

class Donationcontributionlist extends Component {
  constructor(props) {
    super(props);
    this.product_id = 0;
    this.state = {
      activePage: 1,
      itemsCountPerPage: 10,
      totalItemsCount: 1,
      startDate: "",
      cart: [],
      flag: 0,
      value: 1,
      arr_donation_contribution: [],
      donation_count: "",
      guestinfo: {
        first_name: "",
        middle_name: "",
        last_name: "",
        address: "",
        birth_date_old: "",
        birth_date: "",
        age: "",
        gender: "",
        mobile: "",
        email: "",
        participant_id: "",
        birth_date: "",
      },
      eventinfo: {},
      removeinfo: {
        message: "",
        status: "",
      },
      editorState: EditorState.createEmpty(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.goToEdit = this.goToEdit.bind(this);
    this.remove = this.remove.bind(this);
    this.validator = new SimpleReactValidator();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addNewParticipant = this.addNewParticipant.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    // this.handlePageChange = this.handlePageChange.bind(this);
    //   this.handleChangeNew = this.handleChangeNew.bind(this);
  }
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
    var pageNumber = pageNumber - 1;
    var pageNumber = 10 * pageNumber;
    var user_id = localStorage.getItem("user_id");
    var limit = 10;
    if (user_id > 0) {
      fetch(
        myConstClass.BASE_URL +
          "payment/getAllDonationContributionFrontEnd/" +
          limit +
          "/" +
          pageNumber +
          "/" +
          user_id +
          "/" +
          getLanguage()
      )
        .then((response) => response.json())
        .then((response) => {
          this.setState({ arr_donation_contribution: response });
          //var totalRecords =  this.state.cart[0].title;
          const numRows = this.state.arr_donation_contribution.length;
          if (numRows < 1) {
            $("#noRecordsFoundParticipatesTR").css("display", "block");
            $("#existingParticipateHeadTR").css("display", "none");
          } else {
            $("#noRecordsFoundParticipatesTR").css("display", "none");
            $("#existingParticipateHeadTR").css("display", "contents");
          }
        });
    }
  }
  handleChange(date) {
    this.setState({
      startDate: date,
    });

    var today = new Date();
    var birthDate = date;
    var arr = date.split("-");
    var m = today.getMonth() - arr[1];
    var age = today.getFullYear() - arr[2];
    if (m < 0 || (m === 0 && today.getDate() < arr[0])) {
      age--;
    }
    this.state.guestinfo.birth_date = date;
    this.state.guestinfo.age = age;
  }

  addNewParticipant() {
    localStorage.removeItem("participate_id");
    var user_id = localStorage.getItem("user_id");
    var participate_id = 0;
    if (user_id > 0) {
      $("#first_name").val("");
      $("#middle_name").val("");
      $("#last_name").val("");
      $("#age").val("");
      $("#mobile").val("");
      $("#email_participant").val("");
      $("#address").val("");
      $("#birth_date_old").val("");
      $("#birth_date").val("");
      $(".DayPickerInput input").val("");
      this.state.guestinfo.first_name = "";
      this.state.guestinfo.middle_name = "";
      this.state.guestinfo.last_name = "";
      this.state.guestinfo.age = "";
      this.state.guestinfo.gender = "";
      this.state.guestinfo.mobile = "";
      this.state.guestinfo.email = "";
      this.state.guestinfo.address = "";
      //this.state.guestinfo.birth_date = this.state.guestinfo.date_of_birth;
      this.state.guestinfo.birth_date_old = "";
      this.state.guestinfo.birth_date = "";
      this.state.startDate = "";
      $("#addparticipantsLabel").css("display", "inline");
      $("#newParticipantsLabel").css("display", "inline");
      $("#editparticipantsLabel").css("display", "none");
      $("#EXISTING-PARTICIPANTS").modal("show");
    }
  }

  goToEdit(e) {
    //alert(e);
    var user_id = localStorage.getItem("user_id");

    var participate_id_all = e.target.id;
    console.log(participate_id_all);
    var arr_participate_id = participate_id_all.split("_");
    this.state.guestinfo.participate_id = arr_participate_id[1];
    var participate_id = arr_participate_id[1];
    localStorage.removeItem("participate_id");
    localStorage.setItem("participate_id", participate_id);
    //alert(participate_id);
    if (user_id > 0) {
      fetch(
        myConstClass.BASE_URL +
          "event/getParticipantDetailsFrontEnd/" +
          participate_id +
          "/" +
          getLanguage()
      )
        .then((response) => response.json())
        .then((response) => {
          this.setState({ guestinfo: response });

          this.state.guestinfo.first_name = this.state.guestinfo.first_name;
          this.state.guestinfo.middle_name = this.state.guestinfo.middle_name;
          this.state.guestinfo.last_name = this.state.guestinfo.last_name;
          this.state.guestinfo.age = this.state.guestinfo.age;
          this.state.guestinfo.gender = this.state.guestinfo.gender;
          this.state.guestinfo.mobile = this.state.guestinfo.mobile;
          this.state.guestinfo.email = this.state.guestinfo.email;
          this.state.guestinfo.address = this.state.guestinfo.address;
          //this.state.guestinfo.birth_date = this.state.guestinfo.date_of_birth;
          //    this.state.guestinfo.birth_date_old = this.state.guestinfo.birth_date_old;
          this.state.guestinfo.birth_date = this.state.guestinfo.birth_date;
          this.state.startDate = this.state.guestinfo.birth_date;
          console.log(this.state.startDate);
          this.state.startDate = this.state.guestinfo.birth_date;
          $("#addparticipantsLabel").css("display", "none");
          $("#newParticipantsLabel").css("display", "none");
          $("#editparticipantsLabel").css("display", "inline");
          $("#EXISTING-PARTICIPANTS").modal("show");
        });
    }
  }

  handleDayChange(day) {
    //alert(day);
    var utcDate = day.toLocaleDateString();
    $("#birth_date").val(utcDate);
    this.state.guestinfo.birth_date = utcDate;

    this.setState({ selectedDay: day });
  }
  handleSubmit(e) {
    e.preventDefault();
    var filter =
      /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    var email = $("#email_participant").val();
    // alert(email);
    if (!filter.test(email)) {
      //    alert('hi');
      e.preventDefault();
      toast("Invalid email address", {
        containerId: "B",
        type: toast.TYPE.ERROR,
      });
    } else {
      var user_id = localStorage.getItem("user_id");
      console.log("this.refs", $("#pform").serializeArray());
      var participant_id = localStorage.getItem("participate_id");
      if (this.validator.allValid()) {
        const formData = {};
        for (const field in this.refs) {
          formData[field] = this.refs[field].value;
        }
        console.log("-->", formData);
        fetch(
          myConstClass.BASE_URL +
            `event/addUpdateParticipantFrontEnd/` +
            user_id +
            "/" +
            participant_id,
          {
            method: "POST",
            body: JSON.stringify($("#pform").serializeArray()),
          }
        )
          .then((response) => response.json())
          .then((response) => {
            this.setState({ eventinfo: response });
            var message = this.state.eventinfo.message;
            var status = this.state.eventinfo.status;
            $("#EXISTING-PARTICIPANTS").modal("show");
            //console.log('hii'+this.state.guestinfo);
            if (status == "true") {
              //this.state.startDate= this.state.eventinfo.birth_date;
              var user_id = localStorage.getItem("user_id");
              if (user_id > 0) {
                fetch(
                  myConstClass.BASE_URL +
                    "payment/getAllDonationContributionFrontEnd" +
                    "/" +
                    user_id +
                    "/" +
                    getLanguage()
                )
                  .then((response) => response.json())
                  .then((response) => {
                    this.setState({ arr_donation_contribution: response });
                    //var totalRecords =  this.state.cart[0].title;
                    const numRows = this.state.arr_donation_contribution.length;
                    if (numRows < 1) {
                      $("#noRecordsFoundParticipatesTR").css(
                        "display",
                        "block"
                      );
                      $("#existingParticipateHeadTR").css("display", "none");
                    } else {
                      $("#noRecordsFoundParticipatesTR").css("display", "none");
                      $("#existingParticipateHeadTR").css(
                        "display",
                        "contents"
                      );
                    }
                  });
              }

              //$('#address').modal('hide');
              $("#EXISTING-PARTICIPANTS").modal("hide");
              toast("" + message, {
                containerId: "B",
                type: toast.TYPE.SUCCESS,
              });
            } else {
              toast("" + message, { containerId: "B", type: toast.TYPE.ERROR });
            }
            //console.log(status);
          })
          .catch((err) => console.log(err));
      } else {
        //alert('here');
        this.validator.showMessages();
        console.log("validations" + this.validator.showMessages());
        e.preventDefault();
        toast("Please fill all required fields.", {
          containerId: "B",
          type: toast.TYPE.ERROR,
        });
        // toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR})
      }
    }
  }

  remove(e) {
    //alert(e);
    console.log(e);

    var user_id = localStorage.getItem("user_id");
    var participate_id_all = e.target.id;
    var arr_participate_id = participate_id_all.split("_");
    this.state.guestinfo.participate_id = arr_participate_id[1];
    var participate_id = arr_participate_id[1];

    if (getLanguage() == "ma") {
      var are_you_sure = "तुला खात्री आहे?";
      var revert = "आपण हे परत करण्यात सक्षम होणार नाही!";
      var deleteConfirm = "होय, ते हटवा!";
      var cancel = "रद्द करा";
    } else {
      var are_you_sure = "Are you sure?";
      var revert = "You won't be able to revert this!";
      var deleteConfirm = "Yes, delete it!";
      var cancel = "Cancel";
    }

    Swal.fire({
      title: are_you_sure,
      text: revert,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: cancel,
      confirmButtonText: deleteConfirm,
    }).then((result) => {
      if (result.value) {
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
        this.flag = 1;
        fetch(
          myConstClass.BASE_URL + `event/deleteParticipant/` + participate_id,
          {
            method: "POST",
            body: e,
          }
        )
          .then(
            toast("Participant Deleted Successfully..!", {
              containerId: "B",
              type: toast.TYPE.SUCCESS,
            })
          )
          .then(
            // $('#TR_'+participate_id).css('display','none');
            $("#TR_" + participate_id).css("display", "none")
          )
          .catch();
      }
    });
  }

  componentDidMount() {
    //this.state.guestinfo.product_total_price = 0;
    const answer_array = this.props.location.pathname.split("/");

    var message = localStorage.getItem("success");
    if (message != "" && message != null) {
      toast("" + message, { containerId: "B", type: toast.TYPE.SUCCESS });

      localStorage.removeItem("success");
    }

    var error = localStorage.getItem("error");
    if (error != "" && error != null) {
      toast("" + error, { containerId: "B", type: toast.TYPE.ERROR });
      localStorage.removeItem("error");
    }

    var user_id = localStorage.getItem("user_id");
    if (user_id > 0) {
      fetch(
        myConstClass.BASE_URL +
          "payment/getAllDonationContributionCountFrontEnd" +
          "/" +
          user_id +
          "/" +
          getLanguage()
      )
        .then((response) => response.json())
        .then((response) => {
          this.setState({ donation_count: response });
          //var totalRecords =  this.state.cart[0].title;
          this.state.totalItemsCount = this.state.donation_count.count;
        });

      fetch(
        myConstClass.BASE_URL +
          "payment/getAllDonationContributionFrontEnd" +
          "/10/0/" +
          user_id +
          "/" +
          getLanguage()
      )
        .then((response) => response.json())
        .then((response) => {
          this.setState({ arr_donation_contribution: response });
          //var totalRecords =  this.state.cart[0].title;
          const numRows = this.state.arr_donation_contribution.length;
          if (numRows < 1) {
            $("#noRecordsFoundParticipatesTR").css("display", "block");
            $("#existingParticipateHeadTR").css("display", "none");
          } else {
            $("#noRecordsFoundParticipatesTR").css("display", "none");
            $("#existingParticipateHeadTR").css("display", "contents");
          }
        });
    }
  }

  componentDidUpdate(prevProps) {
    const answer_array = this.props.location.pathname.split("/");
  }

  //   handleChange(event) {
  //     this.setState({value: event.target.value});
  //   }

  render() {
    const {
      posts,
      guestinfo,
      cart,
      eventinfo,
      arr_donation_contribution,
      openOrders,
      pastOrders,
      cancelOrders,
    } = this.state;
    const { t } = this.props;
    return (
      <div className="App">
        <div>
          {/* <button onClick={this.notify}>Notify !</button> */}
          <ToastContainer />
        </div>
        <Header />
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-5 mt-sm-5">
                <nav aria-label="breadcrumb">
                  {/* <ol class="breadcrumb bg-white mb-0">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item"><a href="#">Order History</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Order History</li>
                        </ol> */}
                  <ol class="breadcrumb bg-white mb-0">
                    {/* <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item"><a href="#">Order History</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Order History</li> */}
                    <li class="breadcrumb-item">
                      <Link to="/">{t("home.home")}</Link>
                    </li>
                    <li class="breadcrumb-item">
                      <a href="javascript:void(0);">{t("home.My Account")}</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {t("home.DONATION CONTRIBUTION LIST")}
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-xl-block d-lg-block d-md-block d-none">
                <div class="row">
                  <div class="col-12">
                    <h5>{t("home.DONATION CONTRIBUTION LIST")}</h5>
                    {/* <button class="btn btn-brown" style={{float:'right'}} onClick={this.addNewParticipant}>{t('home.Add')}</button> */}
                  </div>
                </div>
              </div>

              {/* <div class="row">
                    <div class="col">
                        <ul class="nav nav-tabs order-tabs border-bottom-0" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#all_orders">{t('home.ALL ORDERS')}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#open_orders">{t('home.OPEN ORDERS')}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#past_orders">{t('home.PAST ORDERS')}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#cancelled_orders">{t('home.CANCELLED ORDERS')}</a>
                            </li>
                        </ul>
                    </div>
                </div> */}
              <div className="d-xl-block d-lg-block d-md-block d-none">
                <div class="row">
                  <div class="col">
                    <div class="tab-content">
                      <div id="all_orders" class="tab-pane active">
                        <table class="table table-bordered">
                          <thead class="bg-light">
                            <tr id="existingParticipateHeadTR">
                              <th scope="col-1">{t("home.Sr.")}</th>
                              <th scope="col-1">{t("home.From")}</th>
                              <th scope="col ">{t("home.To")}</th>
                              <th scope="col ">
                                {t("home.Donation Contribution(INR)")}
                              </th>
                              <th scope="col ">{t("home.Transaction ID")}</th>
                              <th scope="col ">{t("home.Status")}</th>
                              <th scope="col ">{t("home.Transaction Date")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {arr_donation_contribution.map((info, ii) => {
                              return (
                                <tr id={"TR_" + info.donation_contribution_id}>
                                  <td>{ii + 1}</td>
                                  <td>{info.from}</td>
                                  <td>{info.to}</td>
                                  <td>{info.donation_amount}</td>
                                  <td>{info.transaction_id}</td>
                                  <td>{info.status}</td>
                                  <td>{info.created_on}</td>
                                </tr>
                              );
                            })}
                            <tr id="noRecordsFoundParticipatesTR">
                              <td colspan="11" style={{ border: "none" }}>
                                {t("home.No Previous Records Found")}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ReactPagination
                          itemClass="page-item"
                          linkClass="page-link"
                          hideNavigation
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemsCountPerPage}
                          totalItemsCount={this.state.totalItemsCount}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-xl-none d-lg-none d-md-none d-block">
                <ListGroup>
                  <ListGroupItem classNmae="bg-light">
                    <h5>{t("home.DONATION CONTRIBUTION LIST")}</h5>
                  </ListGroupItem>
                  {arr_donation_contribution.map((info, ii) => (
                    <ListGroupItem
                      key={info.donation_contribution_id}
                      className="border-bottom"
                    >
                      <FormGroup>
                        <Label className="font-weight-bold">
                          {t("home.From")}&nbsp;:&nbsp;
                        </Label>
                        <Label>{info.from}</Label>
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold">
                          {t("home.To")}&nbsp;:&nbsp;
                        </Label>
                        <Label>{info.to}</Label>
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold">
                          {t("home.Donation Contribution(INR)")}&nbsp;:&nbsp;
                        </Label>
                        <Label>{info.donation_amount}</Label>
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold">
                          {t("home.Transaction ID")}&nbsp;:&nbsp;
                        </Label>
                        <Label>{info.transaction_id}</Label>
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold">
                          {t("home.Status")}&nbsp;:&nbsp;
                        </Label>
                        <Label>{info.status}</Label>
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold">
                          {t("home.Transaction Date")}&nbsp;:&nbsp;
                        </Label>
                        <Label>{info.created_on}</Label>
                      </FormGroup>
                    </ListGroupItem>
                  ))}
                  {!arr_donation_contribution.length && (
                    <ListGroupItem>
                      {t("home.No Previous Records Found")}
                    </ListGroupItem>
                  )}
                </ListGroup>
                <ReactPagination
                  itemClass="page-item"
                  linkClass="page-link"
                  hideNavigation
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.itemsCountPerPage}
                  totalItemsCount={this.state.totalItemsCount}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade z-index--99999"
          id="EXISTING-PARTICIPANTS"
          tabindex="-1"
          role="dialog"
          aria-labelledby="address"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl"
            role="document"
          >
            <div class="modal-content bg-light">
              <div class="modal-header">
                <h5 class="modal-title" id="addparticipantsLabel">
                  {/* {t('home.MASTER LIST OF EXISTING PARTICIPANTS')} */}
                  {t("home.Add participants details")} &nbsp;
                  <span id="scheduleTitle"></span>
                </h5>
                <h5 class="modal-title" id="editparticipantsLabel">
                  {/* {t('home.MASTER LIST OF EXISTING PARTICIPANTS')} */}
                  {t("home.Edit participants details")} &nbsp;
                  <span id="scheduleTitle"></span>
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="far fa-times-circle"></i>
                </button>
              </div>

              <div class="modal-body">
                <div class="row">
                  <div class="col">
                    <Form
                      autoComplete="off"
                      action=""
                      id="pform"
                      onSubmit={this.handleSubmit}
                      method="post"
                      encType="multipart/form-data"
                      className="form-horizontal"
                    >
                      <table class="table bg-white tableTDNoBorder">
                        <thead class="">
                          <tr class="bg-light border-0">
                            <td class="border-0 pt-3" colspan="5">
                              <h5 class="modal-title" id="newParticipantsLabel">
                                {t("home.New Participants Details")}
                              </h5>
                            </td>
                            <td class="border-0 pt-3" colspan="5">
                              <p class="color-red text-right">
                                *{t("home.Mandatory Fields")}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            {/* <th scope="col">{t('home.Label')}</th> */}
                            <td colspan="6" style={{ borderTop: "none" }}>
                              {t("home.First Name")}
                              <span class="color-red">*</span>
                              <input
                                value={guestinfo.first_name}
                                onChange={(e) => {
                                  this.setState({
                                    guestinfo: {
                                      ...guestinfo,
                                      first_name: e.target.value,
                                    },
                                  });
                                }}
                                onBlur={() =>
                                  this.validator.showMessageFor("first_name")
                                }
                                style={{ marginTop: "5px" }}
                                type="text"
                                id="first_name"
                                name="first_name"
                                class="form-control form-control-sm"
                                placeholder={t("home.First Name")}
                                required
                              />
                              {this.validator.message(
                                "first_name",
                                this.state.guestinfo.first_name,
                                "required"
                              )}
                            </td>
                            <td colspan="6" style={{ borderTop: "none" }}>
                              {t("home.Middle Name")}
                              <input
                                value={guestinfo.middle_name}
                                style={{ marginTop: "5px" }}
                                onChange={(e) => {
                                  this.setState({
                                    guestinfo: {
                                      ...guestinfo,
                                      middle_name: e.target.value,
                                    },
                                  });
                                }}
                                type="text"
                                id="middle_name"
                                name="middle_name"
                                class="form-control form-control-sm"
                                placeholder={t("home.Middle Name")}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colspan="6" style={{ borderTop: "none" }}>
                              {t("home.Last Name")}
                              <span class="color-red">*</span>
                              <input
                                value={guestinfo.last_name}
                                onChange={(e) => {
                                  this.setState({
                                    guestinfo: {
                                      ...guestinfo,
                                      last_name: e.target.value,
                                    },
                                  });
                                }}
                                onBlur={() =>
                                  this.validator.showMessageFor("last_name")
                                }
                                style={{ marginTop: "5px" }}
                                type="text"
                                id="last_name"
                                name="last_name"
                                class="form-control form-control-sm"
                                placeholder={t("home.Last Name")}
                                required
                              />
                              {this.validator.message(
                                "last_name",
                                this.state.guestinfo.last_name,
                                "required"
                              )}
                            </td>
                            <td colspan="6" style={{ borderTop: "none" }}>
                              {t("home.Date of Birth")}

                              <Input
                                type="hidden"
                                onChange={(e) => {
                                  this.setState({
                                    guestinfo: {
                                      ...guestinfo,
                                      birth_date: e.target.value,
                                    },
                                  });
                                }}
                                value={guestinfo.birth_date}
                                id="birth_date"
                                name="birth_date"
                                placeholder="Birth Date"
                              />

                              <ModernDatepicker
                                id="datePickerParticipant"
                                date={guestinfo.birth_date}
                                value={guestinfo.birth_date}
                                format={"DD-MM-YYYY"}
                                showBorder
                                onChange={(date) => this.handleChange(date)}
                                placeholder={t("home.Date of Birth")}
                              />
                              {/* <DayPickerInput id="datePickerParticipant" value={guestinfo.date_of_birth}  style={{marginTop:'5px',width:'100%'}}  readOnly     class="form-control "     onDayChange={this.handleDayChange} /> */}
                            </td>
                          </tr>

                          <tr>
                            <td colspan="6" style={{ borderTop: "none" }}>
                              {t("home.Age")}
                              <span class="color-red">*</span>
                              <input
                                value={guestinfo.age}
                                onChange={(e) => {
                                  this.setState({
                                    guestinfo: {
                                      ...guestinfo,
                                      age: e.target.value,
                                    },
                                  });
                                }}
                                onBlur={() =>
                                  this.validator.showMessageFor("age")
                                }
                                style={{ marginTop: "5px" }}
                                type="number"
                                id="age"
                                name="age"
                                class="form-control form-control-sm"
                                placeholder={t("home.Age")}
                                required
                              />
                              {this.validator.message(
                                "age",
                                this.state.guestinfo.age,
                                "required"
                              )}
                            </td>
                            <td colspan="6" style={{ borderTop: "none" }}>
                              {t("home.Gender")}

                              <select
                                value={guestinfo.gender}
                                onChange={(e) => {
                                  this.setState({
                                    guestinfo: {
                                      ...guestinfo,
                                      gender: e.target.value,
                                    },
                                  });
                                }}
                                style={{ marginTop: "5px" }}
                                id="gender"
                                name="gender"
                                class="form-control form-control-sm"
                              >
                                <option value="Male">{t("home.Male")}</option>
                                <option value="Female">
                                  {t("home.Female")}
                                </option>
                              </select>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="6" style={{ borderTop: "none" }}>
                              {t("home.Mobile")}
                              <span class="color-red">*</span>
                              <input
                                type="text"
                                required=""
                                pattern="[0-9]{10}"
                                value={guestinfo.mobile}
                                onChange={(e) => {
                                  this.setState({
                                    guestinfo: {
                                      ...guestinfo,
                                      mobile: e.target.value,
                                    },
                                  });
                                }}
                                onBlur={() =>
                                  this.validator.showMessageFor("mobile")
                                }
                                style={{ marginTop: "5px" }}
                                id="mobile"
                                name="mobile"
                                class="form-control form-control-sm"
                                placeholder={t("home.Mobile")}
                                title="Please enter this field in the appropriate format."
                              />
                              {this.validator.message(
                                "mobile",
                                this.state.guestinfo.mobile,
                                "required"
                              )}
                            </td>
                            <td colspan="6" style={{ borderTop: "none" }}>
                              {t("home.Email")}
                              <span class="color-red">*</span>
                              <input
                                type="email"
                                value={guestinfo.email}
                                onChange={(e) => {
                                  this.setState({
                                    guestinfo: {
                                      ...guestinfo,
                                      email: e.target.value,
                                    },
                                  });
                                }}
                                onBlur={() =>
                                  this.validator.showMessageFor("email")
                                }
                                style={{ marginTop: "5px" }}
                                id="email_participant"
                                name="email"
                                class="form-control form-control-sm"
                                placeholder={t("home.Email")}
                                required
                              />
                            </td>
                          </tr>

                          <tr>
                            <td
                              colspan="6"
                              style={{ width: "50%", borderTop: "none" }}
                            ></td>

                            <td colspan="6" style={{ borderTop: "none" }}>
                              {t("home.Address")}
                              <span class="color-red">*</span>
                              <br />
                              <textarea
                                value={guestinfo.address}
                                onChange={(e) => {
                                  this.setState({
                                    guestinfo: {
                                      ...guestinfo,
                                      address: e.target.value,
                                    },
                                  });
                                }}
                                onBlur={() =>
                                  this.validator.showMessageFor("address")
                                }
                                style={{ marginTop: "5px", width: "100%" }}
                                rows="4"
                                cols="50"
                                id="address"
                                name="address"
                                required
                              >
                                {guestinfo.address}
                              </textarea>
                              {this.validator.message(
                                "address",
                                this.state.guestinfo.address,
                                "required"
                              )}
                            </td>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                      <div class="modal-footer">
                        <span data-dismiss="modal" aria-label="Close">
                          {t("home.Cancel")}
                        </span>
                        <button
                          type="submit"
                          class="btn btn-sm btn-outline-brown"
                        >
                          {t("home.Save")}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
Donationcontributionlist.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(Donationcontributionlist);
