import React, { Fragment, useEffect, useState } from "react";
import "./Stepper.css";
import { useDispatch, useSelector } from "react-redux";
import { selectCheckoutSteps } from "./store/checkoutSlice";

export default function Stepper({
  progress = 1,
  steps = [],
  currentStep = "",
}) {
  const dispatch = useDispatch();
  const checkoutSteps = useSelector(selectCheckoutSteps);

  // console.log(checkoutSteps);

  const numSteps = steps.length;

  const [dots, setDots] = useState(() => Array(numSteps).fill(false));
  const [connectors, setConnectors] = useState(() =>
    Array(numSteps - 1).fill(false)
  );

  if (currentStep) {
    // console.log(steps, currentStep);
    const foundIndex = steps.findIndex((el) => el === currentStep);
    if (foundIndex > -1) {
      progress = foundIndex + 1;
    }
  }

  useEffect(() => {
    function updateDots(progress) {
      let _progress = progress - 1;
      let _dots = [];
      let _connectors = [];

      for (let i = 0; i < numSteps; i++) {
        if (i < _progress) {
          _dots.push(true);
        } else {
          _dots.push(false);
        }
        if (i < _progress) {
          _connectors.push(true);
        } else {
          _connectors.push(false);
        }
      }

      setDots(_dots);
      setConnectors(_connectors);
    }

    updateDots(progress);
  }, [progress, numSteps]);

  return (
    <div className="container">
      <div className="stepper-container stepper-container-layout">
        {dots.map((data, key) => (
          <Fragment key={key}>
            <div
              className={
                "position-relative baseDot " +
                (data
                  ? " bg-accent border-accent text-white activeDot"
                  : dots[key - 1]
                  ? " border-accent text-accent prevDot"
                  : key === 0
                  ? " border-accent text-accent prevDot"
                  : " text-muted nextDot")
              }
            >
              <span className="m-auto">
                {/* {key + 1 < numSteps ? <div>{key + 1}</div> : <div></div>} */}
                {<div>{key + 1}</div>}
              </span>
              <div className="position-absolute text-center text-accent text-nowrap dot-text">
                <small>{steps[key]}</small>
              </div>
            </div>

            {key + 1 < numSteps && (
              <div
                className={
                  " border-2 flex-grow baseConnector" +
                  (connectors[key]
                    ? " border-orange-400 border-accent"
                    : " border-gray-200 border-muted")
                }
              ></div>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
}
