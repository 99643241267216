import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import icon from './assets/images/calendar.png';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { Media } from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import Header from './Header.js';
import Footer from './Footer.js';

import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import dateFormat from 'dateformat';
// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from 'reactstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import ModernDatepicker from 'react-modern-datepicker';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from "@material-ui/pickers";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

// import Grid from '@material-ui/core/Grid';
// import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    //     KeyboardTimePicker,
    //     KeyboardDatePicker,
} from '@material-ui/pickers';

// ...or load this specific CSS file using a <link> tag in your document

const intAllowedExtensions = ['jpeg', 'jpg', 'gif', 'png', 'doc', 'docx', 'bmp', 'tif', 'pdf', 'xls', 'xlsx', 'odt'];
const intUploadFolderFlag = 0;
const initMultiple = false;
const intItemLimit = 1;
class Profile extends Component {
    constructor(props) {
        super(props);
        const { allowedExtensions, multiple, itemLimit } = this.props;
        this.product_id = 0;
        this.state = {
            pictures: [],
            arr_userInfo: [],
            profile_picture: '',
            userinfo: {
                profile_picture: 'profile.jpg',
            },
            startDate: '',
            flag: 0,
            guestinfo: [],
            editProfile: false,
            guestinfo: {
                user_id: '',
                birth_date: '',
                birth_date_old: '',
                profile_picture: 'profile.jpg',
            },
            birth_date_value :'',
            eventinfo: {
                shipping_first_name: '',
                shipping_middle_name: '',
                shipping_company: '',
                shipping_last_name: '',
                shipping_address_line_1: '',
                shipping_address_line_2: '',
                shipping_landmark: '',
                shipping_country: '',
                shipping_state: '',
                shipping_city: '',
                shipping_pincode: '',
                shipping_phone: '',
                shipping_email: '',
                shipping_countryname: '',
                shipping_statename: '',
                shipping_cityname: '',
                is_shipping_address_available: '',
                sadhak_number: '',
                is_verify_sadhak_no:'',
                birth_date: '',
                birth_date_old: '',
                birth_date_default: '',
            },
            removeinfo: {
                message: '',
                status: '',
            },
            country: [],
            city: [],
            shipping_cityshipping: [],
            shipping_countrystate: [],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleToDayChange = this.handleToDayChange.bind(this);
        this.validator = new SimpleReactValidator();
        this.billing_type = "1";
        this.handleChange = this.handleChange.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.goToEdit = this.goToEdit.bind(this);
        var user_id = localStorage.getItem('user_id');
        this.importFile = this.importFile.bind(this);
        this.handleChangeNew = this.handleChangeNew.bind(this);
    }

    onDrop(pictureFiles, pictureDataURLs) {
        this.setState({
            pictures: this.state.pictures.concat(pictureFiles),
        });
    }

    handleFileChange = e => {
        // console.log(e.target.files[0]);
        this.setState({
            [e.target.name]: e.target.files[0],
        })
        $('#profile_btn').css('display', 'inline');
        // $('#profile_btn').click();
        // e.preventDefault();
    }

    goToEdit() {
        $(".readonly").prop("readonly", false);
        $('#birth_date').css('background-color', '#FFFFFF');
        var is_verify_sadhak_no = localStorage.getItem('is_verify_sadhak_no');
        //alert(is_verify_sadhak_no);
        if (is_verify_sadhak_no == 1) {
            $("#sadhak_number").prop("readonly", true);
            $('.verifiedAddhar').css('display', 'inline');
        } else {
            $('.verifiedAddhar').css('display', 'none');
        }
        $('.sc-bxivhb').css('background-color', '#FFFFFF !important');
        $('#saveButtonDiv').css('display', 'block');
        $('#editButtonDiv').css('display', 'none');
        $('#mandatoryDiv').css('display', 'inline');
        $('#country').removeAttr('disabled');
        $('#state').removeAttr('disabled');
        $('#city').removeAttr('disabled');
        this.setState({
            editProfile: true
        })
    }
    
    handleChangeNew() {
        alert('hi');
    }

    handleChange(date) {
        alert(this.state.guestinfo.birth_date)
        var date = new Date(this.state.birth_date).getDate() + '-' + (new Date(this.state.birth_date).getMonth() + 1) + "-" + new Date(this.state.birth_date).getFullYear();
        this.setState({
            guestinfo: {
                ...this.state.guestinfo,
                birth_date: date
            }
        })
        // this.setState({
        //     startDate: date
        // });
        // var today = new Date();
        // var birthDate = date;
        // var arr = date.split("-");
        // var m = today.getMonth() - arr[1];
        // var age = today.getFullYear() - arr[2];
        // if (m < 0 || (m === 0 && today.getDate() < arr[0])) {
        //     age--;
        // }
        // this.state.guestinfo.birth_date_old = date;
        // this.state.guestinfo.birth_date = date;
        // this.state.guestinfo.date_of_birth = date;
        // $('#birth_date').val(date);
        // this.state.participateinfo.participate_age = age;
    }

    handleDayChange(day) {
        var utcDate = day.toLocaleDateString();
        $('#birth_date').val(utcDate);
        this.state.guestinfo.birth_date_old = utcDate;
        this.setState({ selectedDay: day });
    }

    handleToDayChange(day) { //alert(day);
        var utcDate = day.toLocaleDateString();
        $('#end_date').val(utcDate);
        this.state.eventinfo.end_date = utcDate;
        this.setState({ selectedDay: day });
    }

    handleDateChange = (date) => {
        const { guestinfo } = this.state;
        guestinfo['birth_date'] = dateFormat(date, "dd-mm-yyyy");;
        guestinfo['birth_date_value'] = date;
        this.setState({ guestinfo });
        this.setState({ birth_date_value:date });
    };

    componentDidMount() {
        $('#birth_date').css('background-color', '#e9ecef');
        var profile_picture = localStorage.getItem('profile_picture');
        // console.log('local_profile: ',profile_picture);
        if (profile_picture != '' && profile_picture != undefined) {
            this.state.userinfo.profile_picture = profile_picture;
        }
        const answer_array = this.props.location.pathname.split('/');
        var user_id = localStorage.getItem('user_id');
        this.getCountry();

        var billing_address_id = 1;
        fetch(myConstClass.BASE_URL + 'users/getBillingAddressProfile/' + billing_address_id + '/' + user_id)
            .then(response => response.json())
            .then(response => {
                // console.log('response1: ', response);
                this.setState({ birth_date_value: response.birth_date });
                this.setState({ guestinfo: response });

                const numRows = this.state.guestinfo.length;
                if (this.state.guestinfo.first_name != '' && this.state.guestinfo.first_name != undefined) {     
                    this.setState({ birth_date_value: this.state.guestinfo.birth_date });
                    this.state.is_billing_address_available = 1;
                    this.state.guestinfo.first_name = this.state.guestinfo.first_name;
                    // alert(this.state.guestinfo.first_name);
                    this.state.guestinfo.middle_name = this.state.guestinfo.middle_name;
                    this.state.guestinfo.last_name = this.state.guestinfo.last_name;
                    this.state.guestinfo.company = this.state.guestinfo.company;
                    this.state.guestinfo.address_line_1 = this.state.guestinfo.address_line_1;
                    this.state.guestinfo.address_line_2 = this.state.guestinfo.address_line_2;
                    this.state.guestinfo.landmark = this.state.guestinfo.landmark;
                    this.state.guestinfo.country = this.state.guestinfo.country_id_fk;
                    this.state.guestinfo.state = this.state.guestinfo.state_id_fk;
                    this.state.guestinfo.city = this.state.guestinfo.city_id_fk;
                    this.state.guestinfo.pincode = this.state.guestinfo.pincode;
                    this.state.guestinfo.phone = this.state.guestinfo.phone;
                    this.state.guestinfo.email = this.state.guestinfo.email;
                    this.state.guestinfo.shipping_countryname = this.state.guestinfo.country_name;
                    this.state.guestinfo.shipping_statename = this.state.guestinfo.state_name;
                    this.state.guestinfo.shipping_cityname = this.state.guestinfo.city_name;
                    this.state.guestinfo.sadhak_number = this.state.guestinfo.sadhak_number;
                    this.state.guestinfo.birth_date = this.state.guestinfo.birth_date;
                    this.state.guestinfo.birth_date_old = this.state.guestinfo.birth_date_old;
                    this.state.guestinfo.birth_date_default = this.state.guestinfo.birth_date_default;
                    this.state.guestinfo.is_verify_sadhak_no = this.state.guestinfo.is_verify_sadhak_no;
                    var profile_picture_current = this.state.guestinfo.profile_picture;
                    var is_verify_sadhak_no = localStorage.getItem('is_verify_sadhak_no');
                    // $('#birth_date').val(this.state.guestinfo.birth_date_default);
               //    alert(is_verify_sadhak_no);
                    if (is_verify_sadhak_no == 1) {
                        $("#sadhak_number").prop("readonly", true);
                        $('.verifiedAddhar').css('display', 'inline');
                       $('#sadak_circle').css('display', 'inline');
                        
                    } else {
                        $('.verifiedAddhar').css('display', 'none');
                        $('#sadak_circle').css('display', 'none');
                    }
                    // alert(profile_picture_current);
                    //    console.log('rahul'+profile_picture_current);
                    //    var profile_picture =  localStorage.getItem('profile_picture');
                    //    if(profile_picture_current != '' && profile_picture_current != undefined){
                    //     this.state.guestinfo.profile_picture = profile_picture_current;
                    //     }else if(profile_picture != '' && profile_picture != undefined){
                    //         this.state.guestinfo.profile_picture = profile_picture;
                    //     }
                    //     else{
                    //         this.state.guestinfo.profile_picture = 'profile.jpg';
                    //     }
                    // console.log('rahul'+this.state.guestinfo.profile_picture);
                    // alert(this.state.guestinfo.birth_date);
                    this.state.startDate = this.state.guestinfo.birth_date;
                    if (this.state.guestinfo.country > 0) {
                        this.getStateCountryWiseEdit(this.state.guestinfo.country);
                        this.getCityStateWiseEdit(this.state.guestinfo.state);
                        //this.eventinfo.city_id_fk = city;
                    }
                } else {
                    var user_id = localStorage.getItem('user_id');
                    if (user_id > 0) {
                        fetch(myConstClass.BASE_URL + 'users/getUserProfileInfo/' + user_id)
                            .then(response => response.json())
                            .then(response => {
                                // console.log('response2: ', response);
                                this.setState({ guestinfo: response })
                                const numRows = this.state.arr_userInfo.length;
                                if (numRows > 0) {
                                    //alert(numRows);
                                    this.state.guestinfo.first_name = this.state.guestinfo.first_name;
                                    this.state.guestinfo.middle_name = this.state.guestinfo.middle_name;
                                    this.state.guestinfo.last_name = this.state.guestinfo.last_name;
                                    this.state.guestinfo.phone = this.state.guestinfo.phone;
                                    this.state.guestinfo.email = this.state.guestinfo.email;
                                    this.state.guestinfo.sadhak_number = this.state.guestinfo.sadhak_number;
                                    this.state.guestinfo.is_verify_sadhak_no = this.state.guestinfo.is_verify_sadhak_no;
                                    var is_verify_sadhak_no = this.state.guestinfo.is_verify_sadhak_no;
                                    localStorage.removeItem('is_verify_sadhak_no');
                                    localStorage.setItem('is_verify_sadhak_no', is_verify_sadhak_no);
                                    //alert(is_verify_sadhak_no);
                                    if (is_verify_sadhak_no == 1) {
                                        $("#sadhak_number").prop("readonly", true);
                                        $('.verifiedAddhar').css('display', 'inline');
                                    } else {
                                        $('.verifiedAddhar').css('display', 'none');
                                    }
                                }
                            }
                        )
                    }
                }
            }
        )
    }

    getCityStateWise = _ => {
        var shipping_stateid = $('#state').val();
        // alert(shipping_countryid);
        fetch(myConstClass.BASE_URL + 'getCityStateWise/' + shipping_stateid)
            .then(response => response.json())
            .then(response => this.setState({ city: response }))
            .catch(err => console.log(err))
    }

    getStateCountryWise = _ => {
        var shipping_countryid = $('#country').val();
        //alert(shipping_countryid);
        fetch(myConstClass.BASE_URL + 'getStateCountryWise/' + shipping_countryid)
            .then(response => response.json())
            .then(response => this.setState({ shipping_countrystate: response }))
            .catch(err => console.log(err))
    }

    getStateCountryWiseEdit(shipping_countryid) {
        //var shipping_countryid = $('#country').val();
        //alert(shipping_countryid);
        fetch(myConstClass.BASE_URL + 'getStateCountryWise/' + shipping_countryid)
            .then(response => response.json())
            .then(response => this.setState({ shipping_countrystate: response }))
            .catch(err => console.log(err))
    }

    getCityStateWiseEdit(shipping_stateid) {
        fetch(myConstClass.BASE_URL + 'getCityStateWise/' + shipping_stateid)
            .then(response => response.json())
            .then(response => this.setState({ city: response }))
            .catch(err => console.log(err))
    }

    getCountry = _ => {
        fetch(myConstClass.BASE_URL + 'getAllCountry')
            .then(response => response.json())
            .then(response => this.setState({ country: response }))
            .catch(err => console.log(err))
        // console.log("this.setState.data",this.state.country);
    }

    componentDidUpdate(prevProps) {
        const answer_array = this.props.location.pathname.split('/');
        const { flag } = this.state
    }

    importFile(e) {
        e.preventDefault();
        const formData = new FormData();
        for (let name in this.state) {
            formData.append(name, this.state[name]);
        }
        // headers.append('GET', 'POST', 'OPTIONS');
        var user_id = localStorage.getItem('user_id');
        fetch(myConstClass.BASE_URL + `users/uploadProfileImage/` + user_id, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(response => {
                // console.log('response: ',response);
                // console.log('url1: ',`${myConstClass.IMAGE_URL}user/${this.state.userinfo.profile_picture}`);
                // console.log('url2: ',`${myConstClass.IMAGE_URL}user/${response.profile_picture}`);
                this.setState({ userinfo: response });
                var message = this.state.userinfo.message;
                var status = this.state.userinfo.status;
                //console.log('hii'+this.state.guestinfo);
                if (status == 'true') {
                    var profile_picture = this.state.userinfo.profile_picture;
                    localStorage.removeItem('profile_picture');
                    this.state.userinfo.profile_picture = profile_picture;
                    // console.log('api_profile: ', profile_picture);
                    localStorage.setItem('profile_picture', profile_picture);
                    $('#profile_btn').css('display', 'none');
                    // this.state.guestinfo.profile_picture = profile_picture;
                    toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                } else {
                    toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                }
                //console.log(status);
            })
    }

    handleSubmit(e) {
        e.preventDefault();
        var user_id = localStorage.getItem('user_id');
        const { first_name, last_name, birth_date, address_line_1, address_line_2, landmark, country, state, city, pincode, phone } = this.state.guestinfo;

        const firstNameValid = /^[A-Za-z]{2,50}$/.test(first_name);
        const lastNameValid = /^[A-Za-z]{2,50}$/.test(last_name);
        const birthDateValid = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/.test(birth_date);
        const address1Valid = /^[a-zA-Z0-9\s.,#/-]*$/.test(address_line_1);
        const address2Valid = /^[a-zA-Z0-9\s.,#/-]*$/.test(address_line_2);
        const landmarkValid = /^[a-zA-Z0-9\s.,#/-]*$/.test(landmark);
        const pincodeValid = /^(\d{4}|\d{6})$/.test(pincode);
        const phoneValid = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/.test(phone);

        if (this.validator.allValid()) {
            const formData = {};
            for (const field in this.refs) {
                // formData[field] = this.refs[field].value;
                formData.append('arr[]', this.refs[field].value);
            }
            fetch(myConstClass.BASE_URL + `users/addAddressProfile/` + this.billing_type + '/' + user_id, {
                method: 'POST',
                body: JSON.stringify($('#pform').serializeArray())
            })
                .then(response => response.json())
                .then(response => {
                    this.setState({ guestinfo: response, editProfile: false });
                    var message = this.state.guestinfo.message;
                    var status = this.state.guestinfo.status;
                    //console.log('hii'+this.state.guestinfo);
                    if (status == 'true') {
                        // $('#address').modal('hide');
                        // $('#pform')[0].reset();
                        $(".readonly").prop("readonly", true);
                        $('#birth_date').css('background-color', '#e9ecef');
                        // $('.sc-bxivhb').css('background-color','#FFFFFF !important');
                        $('#saveButtonDiv').css('display', 'none');
                        $('#editButtonDiv').css('display', 'inline');
                        $('#mandatoryDiv').css('display', 'none');
                        $('#country').attr("disabled", true);
                        $('#state').attr("disabled", true);
                        $('#city').attr("disabled", true);

                        // const numRows = this.state.guestinfo.length;
                        if (this.state.guestinfo.first_name != '') {
                            this.state.is_billing_address_available = 1;
                        }

                        this.state.guestinfo.first_name = this.state.guestinfo.first_name;
                        this.state.guestinfo.middle_name = this.state.guestinfo.middle_name;
                        this.state.guestinfo.last_name = this.state.guestinfo.last_name;

                        localStorage.removeItem('first_name');
                        localStorage.removeItem('last_name');

                        var first_name = this.state.guestinfo.first_name;
                        localStorage.setItem('first_name', first_name);

                        var last_name = this.state.guestinfo.last_name;
                        localStorage.setItem('last_name', last_name);

                        var middle_name = this.state.guestinfo.middle_name;
                        localStorage.setItem('middle_name', middle_name);

                        this.state.guestinfo.company = this.state.guestinfo.company;
                        this.state.guestinfo.address_line_1 = this.state.guestinfo.address_line_1;
                        this.state.guestinfo.address_line_2 = this.state.guestinfo.address_line_2;
                        this.state.guestinfo.landmark = this.state.guestinfo.landmark;
                        this.state.guestinfo.country = this.state.guestinfo.country_id_fk;
                        this.state.guestinfo.state = this.state.guestinfo.state_id_fk;
                        this.state.guestinfo.city = this.state.guestinfo.city_id_fk;
                        this.state.guestinfo.pincode = this.state.guestinfo.pincode;
                        this.state.guestinfo.phone = this.state.guestinfo.phone;
                        this.state.guestinfo.email = this.state.guestinfo.email;
                        this.state.guestinfo.shipping_countryname = this.state.guestinfo.country_name;
                        this.state.guestinfo.shipping_statename = this.state.guestinfo.state_name;
                        this.state.guestinfo.shipping_cityname = this.state.guestinfo.city_name;
                        this.state.guestinfo.birth_date = this.state.guestinfo.birth_date;
                        this.state.guestinfo.birth_date_old = this.state.guestinfo.birth_date;
                        this.state.startDate = this.state.guestinfo.birth_date;
                        if (this.state.guestinfo.country > 0) {
                            this.getStateCountryWiseEdit(this.state.guestinfo.country);
                            this.getCityStateWiseEdit(this.state.guestinfo.state);
                        }

                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err));
        } else {
            e.preventDefault();
            this.validator.showMessages();
            
            if (!firstNameValid || !first_name) {
                toast.error('Please provide valid first name. First name is required');
            } else if (!lastNameValid || !last_name) {
                toast.error('Please provide valid last name. Last name is required');
            } else if (!birthDateValid || !birth_date) {
                toast.error('Please provide valid date of birth name. Date of birth is required');
            } else if (!address1Valid || !address_line_1) {
                toast.error('Please provide valid address line 1. Address line 1 is required');
            } else if (!address2Valid || !address_line_2) {
                toast.error('Please provide valid address line 2. Address line 2 is required');
            } else if (!landmarkValid || !landmark) {
                toast.error('Please provide valid landmark. Lanmark is required');
            } else if (!country) {
                toast.error('Please select the country');
            } else if (!state) {
                toast.error('Please select the state');
            } else if (!city) {
                toast.error('Please select the city');
            } else if (!pincodeValid || !pincode) {
                toast.error('Please provide valid pincode. Pincode is required');
            } else if (!phoneValid || !phone) {
                toast.error('Please provide valid phone number. Phone number is required');
            }
            // toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR});
        }
    }

    render() {
        const { userinfo, country, shipping_countrystate, city, eventinfo, posts, guestinfo, toast ,birth_date_value} = this.state;
        const { t } = this.props;
        let classNameSadhk = ' fa fa-check-circle div_display_none ';
        if (guestinfo.is_verify_sadhak_no == "1") {
            classNameSadhk  = ' fa fa-check-circle verifiedAddhar div_display ';
        }
        
        let dob;
        const dateParts = guestinfo.birth_date;
        if(guestinfo.birth_date!=null) {
            const day =   dateParts[0]+dateParts[1];
            const month = dateParts[3]+dateParts[4];
            const year =  dateParts[6]+dateParts[7]+dateParts[8]+dateParts[9];
            dob =  year+"-"+month+"-"+day;
        }
        
        const datefield = <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disabled={!this.state.editProfile}
                  name="birth_date"                        
                  value={dob}
                  format="dd-MM-yyyy"
                  onChange={this.handleDateChange}
                  maxDate={new Date()}
                />
            </MuiPickersUtilsProvider>
        </div>;

        return (
            //     <script src="https://cdnjs.cloudflare.com/ajax/libs/react/15.1.0/react.min.js"></script>
            // <script src="https://cdnjs.cloudflare.com/ajax/libs/react/15.1.0/react-dom.min.js"></script>

            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />
                <div className="container-fluid">
                    <Row className="my-5">
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                            <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-white mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li className="breadcrumb-item"><a href="#">{t('home.My Account')}</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">{t('home.Profile')}</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="row">
                                <div className="form-group col-9 col-xl-6 col-lg-6 col-md-6">
                                    <h4>{t('home.Profile')}</h4>
                                </div>
                                {/* <div className="col col-md-3" style={{ display: 'none' }} id="mandatoryDiv">

                                    <p><span className="color-red">* {t('home.Mandatory Fields')}</span></p>
                                </div> */}

                                <div className="col-2 text-right" id="editButtonDiv">
                                    <p>
                                        <i className="fa fa-edit" style={{ fontSize: '27px', color: '#807b7b', fontWeight: '20', cursor: 'pointer' }} onClick={this.goToEdit}></i>
                                    </p>
                                </div>
                            </div>
                        </Col>

                        <Col xl={{ size: 8, order: 1 }} lg={{ size: 8, order: 1 }} md={{ size: 8, order: 1 }} sm={{ size: 12, order: 2 }} xs={{ size: 12, order: 2 }} className="px-4">
                            {/* <form> */}
                            <Form autoComplete="off" action="" id="pform" onSubmit={this.handleSubmit} method="post" encType="multipart/form-data" className="form-horizontal">

                                <div className="row mt-2">
                                    <div className="form-group col-12 col-xl-4 col-lg-4 col-md-4">
                                        <label className="color-black font-weight-bolder ">{t('home.First Name')}<span className="color-red">*</span></label>
                                        {/* <input onBlur={() => this.validator.showMessageFor('first_name')} type="text" className="form-control" id="first_name" name="first_name"  placeholder={t('home.Enter First Name')} /> */}
                                        <input
                                          onChange={e => { this.setState({ guestinfo: { ...guestinfo, first_name: e.target.value } }) }}
                                          onBlur={() => this.validator.showMessageFor('first_name')}
                                          type="text" id="first_name" name="first_name" value={guestinfo.first_name} className="form-control readonly" readonly="readonly" placeholder={t('home.Enter First Name')}
                                        />
                                        {this.validator.message('first_name', this.state.guestinfo.first_name, 'required|alpha|string|min:2|max:50|regex:^[A-Za-z]+$')}
                                    </div>
                                    <div className="form-group col-12 col-xl-4 col-lg-4 col-md-4">
                                        <label className="color-black font-weight-bolder">{t('home.Middle Name')}</label>
                                        <input
                                          onChange={e => { this.setState({ guestinfo: { ...guestinfo, middle_name: e.target.value } }) }}
                                          onBlur={() => this.validator.showMessageFor('middle_name')}
                                          value={guestinfo.middle_name} type="text" className="form-control readonly" id="middle_name" name="middle_name" placeholder={t('home.Enter Middle Name')} readonly="readonly"
                                        />
                                        {this.validator.message('middle_name', this.state.guestinfo.middle_name, 'alpha|string|min:2|max:50|regex:^[A-Za-z]+$')}
                                    </div>
                                    <div className="form-group col-12 col-xl-4 col-lg-4 col-md-4">
                                        <label className="color-black font-weight-bolder">{t('home.Last Name')}<span className="color-red">*</span></label>
                                        {/* <input type="text" className="form-control" id="last_name" name="last_name" placeholder={t('home.Enter Last Name')} /> */}
                                        <input value={guestinfo.last_name} onBlur={() => this.validator.showMessageFor('last_name')} onChange={e => { this.setState({ guestinfo: { ...guestinfo, last_name: e.target.value } }) }} type="text" id="last_name" name="last_name" className="form-control readonly" readonly="readonly" placeholder={t('home.Enter Last Name')} />
                                        {this.validator.message('last_name', this.state.guestinfo.last_name, 'required|alpha|string|min:2|max:50|regex:^[A-Za-z]+$')}
                                    </div>
                                </div>

                                <div className="row birthDateDiv" style={{ maxHeight: '84px' }}>
                                    <div className="form-group col col-md-4">
                                        <label className="color-black font-weight-bolder">{t('home.Date of Birth')}&nbsp;<small>(dd-mm-yyyy)</small><span className="color-red">*</span></label>
                                        {/* <input
                                          onBlur={() => this.validator.showMessageFor('email')}
                                          type="text" className="form-control" id="email" name="email" placeholder={t('home.Enter Email ID')}
                                        />
                                       <Input 
                                          type="hidden" value={guestinfo.birth_date_old} id="birth_date" name="birth_date" placeholder="Birth Date"
                                        />
                                        */}
                                        {/* <DatePicker
                                            onChange={e => { this.setState({ guestinfo: { ...guestinfo, birth_date: e.target.value } }) }}
                                            value={guestinfo.birth_date}
                                            id="birth_date"
                                            placeholder={t('home.Date of Birth')}

                                            onBlur={() => this.validator.showMessageFor('birth_date')}
                                            name="birth_date"
                                            className="form-control readonly"
                                        /> */}

                                       {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                disableFuture
                                                disabled={!this.state.editProfile}
                                                openTo="year"
                                                format="dd-MM-yyyy"
                                                id="birth_date"
                                                name="birth_date"
                                                placeholder="dd-mm-yyyy"
                                                views={["year", "month", "date"]}
                                                value={guestinfo.birth_date ? new Date(guestinfo.birth_date.split('-')[2] + '-' + guestinfo.birth_date.split('-')[1] + '-' + guestinfo.birth_date.split('-')[0]) : null}
                                                onChange={e => this.setState({ guestinfo: { ...guestinfo, birth_date: new Date(e).getDate() + '-' + (new Date(e).getMonth() + 1) + '-' + new Date(e).getFullYear() } })}
                                            />
                                        </MuiPickersUtilsProvider> */}
                                        {datefield}
                                        {/* <input
                                            value={guestinfo.birth_date}
                                            onBlur={() => this.validator.showMessageFor('birth_date')}
                                            readonly
                                            onChange={e => { this.setState({ guestinfo: { ...guestinfo, birth_date: e.target.value } }) }}
                                            type="date"
                                            id="birth_date"
                                            name="birth_date"
                                            max="9999-12-31"
                                            className="form-control readonly"
                                        /> */}
                                        {this.validator.message('birth_date', this.state.guestinfo.birth_date, 'required')}
                                        <br />
                                        <br />
                                        {/* <input type="date" max={new Date().toISOString().split('T')[0]} /> */}
                                        {/* <i className="fa fa-calendar" aria-hidden="true"></i> */}
                                        {/* <DayPickerInput className="form-control" value={guestinfo.birth_date} readOnly    className="form-control" onDayChange={this.handleDayChange} /> */}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col col-md-12">
                                        <label className="color-black font-weight-bolder">{t('home.Company / Institution / Organization Name')}</label>
                                        {/* <input type="number" className="form-control" id="mobile" name="mobile"  placeholder={t('home.Enter Mobile Number')} /> */}
                                        <input value={guestinfo.company} onChange={e => { this.setState({ guestinfo: { ...guestinfo, company: e.target.value } }) }} type="text" id="company" name="company" className="form-control readonly" readonly="readonly" placeholder={t('home.Enter Company Name')} />
                                        {this.validator.message('company', this.state.guestinfo.company, 'min:2|max:60')}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.Address Line 1')}<span className="color-red">*</span></label>
                                        <input value={guestinfo.address_line_1} onChange={e => { this.setState({ guestinfo: { ...guestinfo, address_line_1: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('address_line_1')} type="text" className="form-control readonly" readonly="readonly" id="address_line_1" name="address_line_1" placeholder={t('home.Enter Flat No/ House No')} />
                                        {this.validator.message('address_line_1', this.state.guestinfo.address_line_1, 'required|min:2|max:100')}
                                    </div>
                                    <div className="form-group col col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.Address Line 2')}<span className="color-red">*</span></label>
                                        <input value={guestinfo.address_line_2} onChange={e => { this.setState({ guestinfo: { ...guestinfo, address_line_2: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('address_line_2')} type="text" className="form-control readonly" readonly="readonly" id="address_line_2" name="address_line_2" placeholder={t('home.Enter Apartment / Society Name')} />
                                        {this.validator.message('address_line_2', this.state.guestinfo.address_line_2, 'required|min:2|max:100')}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col col-md-12">
                                        <label className="color-black font-weight-bolder">{t('home.Landmark')} <span className="color-red">*</span></label>
                                        <input value={guestinfo.landmark} onChange={e => { this.setState({ guestinfo: { ...guestinfo, landmark: e.target.value } }) }} type="text" className="form-control readonly" readonly="readonly" id="landmark" name="landmark" placeholder={t('home.Landmark')} />
                                        {this.validator.message('landmark', this.state.guestinfo.landmark, 'required|min:2|max:100')}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.Country')} <span className="color-red">*</span></label>
                                        <select value={guestinfo.country} onChange={e => { this.getStateCountryWise(); this.setState({ guestinfo: { ...guestinfo, country: e.target.value } }) }} type="text" id="country" name="country" className="form-control form-control-sm " disabled="disabled">
                                            <option value="" >{t('home.Select Country')}</option>
                                            {country.map((info, ii) => {
                                                return (
                                                    <option value={info.id} >{info.name}</option>
                                                )
                                            })
                                            }
                                        </select>
                                        {this.validator.message('country', this.state.guestinfo.country, 'required')}
                                    </div>

                                    <div className="form-group col col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.home.State / Province')} <span className="color-red">*</span></label>
                                        <select value={guestinfo.state} onChange={e => { this.getCityStateWise({ guestinfo: { ...guestinfo, state: e.target.value } }); this.setState({ guestinfo: { ...guestinfo, state: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('state')} type="select" id="state" name="state" className="form-control form-control-sm " disabled="disabled" placeholder={t('home.Select State / Province')} >
                                            <option value="" >{t('home.Select State/Province')}</option>
                                            {shipping_countrystate.map((info, ii) => {
                                                return (
                                                    <option value={info.id} >{info.name}</option>
                                                )
                                            })
                                            }
                                        </select>
                                        {this.validator.message('state', this.state.guestinfo.state, 'required')}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.City')} <span className="color-red">*</span></label>
                                        <select value={guestinfo.city} onChange={e => { this.setState({ guestinfo: { ...guestinfo, city: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('city')} type="text" id="city" name="city" className="form-control form-control-sm" disabled="disabled" placeholder={t('home.Enter City Name')} >
                                            <option value="" >{t('home.Select City')}</option>
                                            {city.map((info, ii) => {
                                                return (
                                                    <option value={info.id} >{info.name}</option>
                                                )
                                            })
                                            }
                                        </select>
                                    </div>

                                    <div className="form-group col col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.Pincode')} <span className="color-red">*</span></label>
                                        <input value={guestinfo.pincode} onChange={e => { this.setState({ guestinfo: { ...guestinfo, pincode: e.target.value } }) }} type="number" minLength={4} maxLength={6} className="form-control readonly" readonly="readonly" id="pincode" name="pincode" placeholder={t('home.Pincode')} />
                                        {this.validator.message('pincode', this.state.guestinfo.pincode, 'required|min:4|max:6')}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.Mobile Number')} <span className="color-red">*</span></label>
                                        <input value={guestinfo.phone} onChange={e => { this.setState({ guestinfo: { ...guestinfo, phone: e.target.value } }) }} type="number" className="form-control readonly" readonly="readonly" id="phone" name="phone" placeholder={t('home.Phone')} />
                                        {this.validator.message('phone', this.state.guestinfo.phone, 'required|min:8|max:15 ')}
                                    </div>

                                    <div className="form-group col col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.Email')} <span className="color-red">*</span></label>
                                        <input readonly="readonly" value={guestinfo.email} onChange={e => { this.setState({ guestinfo: { ...guestinfo, email: e.target.value } }) }} type="text" className="form-control" id="email" name="email" placeholder={t('home.Email')} />
                                        {this.validator.message('email', this.state.guestinfo.email, 'required|email')}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-10 col-xl-6 col-lg-6 col-md-6">
                                        <label className="color-black font-weight-bolder">{t('home.Sadhak No')} <span className="color-red" style={{ fontSize: '15px' }}>({t('home.For Manashakti Sadhaks only')})</span></label>
                                        <input value={guestinfo.sadhak_number} onChange={e => { this.setState({ guestinfo: { ...guestinfo, sadhak_number: e.target.value } }) }} type="text" className="form-control readonly" readonly="readonly" id="sadhak_number" name="sadhak_number" placeholder={t('home.Enter Sadhak Number')} />
                                        {/* {this.validator.message('sadhak_number', this.state.guestinfo.sadhak_number, 'required')} */}
                                    </div>
                                    <div className="form-group col-2 col-xl-6 col-lg-6 col-md-6 align-self-end">

                                        <label className="color-black font-weight-bolder"> <span className="color-red">&nbsp;</span></label>

                                        <i className= {classNameSadhk}  id="sadak_circle"  ></i>

                                    </div>
                                </div>

                                <div id="saveButtonDiv" style={{ display: 'none' }}>
                                    <Row>
                                        <div className="form-group col-12 col-xl-2 col-lg-2 col-md-2">
                                            <button className="btn btn-brown btn-block">{t('home.Save')}</button>
                                        </div>
                                        <div className="form-group col-12 col-xl-2 col-lg-2 col-md-2">
                                            <Link className="btn btn-light btn-block" to="/">{t('home.Cancel')}</Link>
                                        </div>
                                    </Row>
                                </div>
                            </Form>
                        </Col>

                        <Col xl={{ size: 4, order: 2 }} lg={{ size: 4, order: 2 }} md={{ size: 4, order: 2 }} sm={{ size: 12, order: 1 }}
                             xs={{ size: 12, order: 1 }} className="px-4 text-center">
                            <div style={{ width: "100px", height: "100px", margin: "auto" }}>
                                <img
                                    className="img-fluid rounded-circle-custom mw-100"
                                    src={myConstClass.IMAGE_URL + 'user/' + userinfo.profile_picture} />
                            </div>
                            <Form id="profile" name="profile" onSubmit={this.importFile}>
                                <input
                                  onChange={this.handleFileChange}
                                  type="file" accept="image/*" name="profile_picture" id="profile_picture" style={{ width: '210px' }}
                                />
                                <input
                                  style={{ width: '100px', marginTop: '14px', display: 'none' }}
                                  type="submit" name="profile_btn" id="profile_btn" value={t('home.Upload')}
                                />
                            </Form>
                        </Col>
                    </Row>
                </div>
                <Footer />
            </div>
        );
    }
}
Profile.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Profile);
