import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/images/logo.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import Header from './Header.js';
import Footer from './Footer.js';
import * as myConstClass from './constant.js';
import $ from 'jquery';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
//import { toast } from 'react-toastify';

// New-Styles
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input
} from 'reactstrap';


import AyurvedicProducts from './assets/images/AyurvedicProducts.jpg';
import BalDiwali from './assets/images/BalDiwali.jpg';
import Books from './assets/images/Books.jpg';
import CDs from './assets/images/CDs.jpg';
import DIWALIMAGAZINE from './assets/images/DIWALIMAGAZINE.jpg';
import ExperimentalProducts from './assets/images/ExperimentalProducts.jpg';
import KITs from './assets/images/KITs.jpg';

//console.log('.push(',{body3});


// async function getMovies(){
//   await fetch(myConstClass.BASE_URL+'product/getProductsFrontEnd')
//     .then((response) => response.json());
// }

class Product_type extends Component {
    constructor(props) {
        super(props);
        this.state = {
            share_current_image_url: '',
            share_image_url: '',
            social_description: '',
            guestinfo: {
                title: '',
                type_id: '',
                topic: '',
            },
            quantityValue: '1',
            flag: 0,
            posts: [],
            eventinfo: [],
            languages: [],
            removeFromCart: [],
            topic: [],
            cart: [],
            cartEvent: [],
            productIncart: [],
            types: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.increaseQuantity = this.increaseQuantity.bind(this);
        this.decreaseQuantity = this.decreaseQuantity.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.removeFromCart = this.removeFromCart.bind(this);
    }



    removeFromCart(e) {
        var product_id = e.target.id;
        // console.log(e.target.id);
        var product_quantity = $('#qty' + product_id).val();
        var user_id = localStorage.getItem('user_id');
        console.log(product_quantity);

        if (product_id != '' && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/removeProductFromCart/' + product_id + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ removeinfo: response })
                    var message = this.state.removeinfo.message;
                    var status = this.state.removeinfo.status;
                    if (status == 'true') {
                        $('#' + product_id).css('display', 'inherit');
                        $('#' + product_id).removeClass('btn-light');
                        $('#' + product_id).addClass('btn-brown');

                        if (getLanguage() == 'en') {
                            $('#' + product_id).html('ADD TO CART');
                        } else {
                            $('#' + product_id).html('कार�?टमध�?ये टाका');
                        }

                        $('#addedInCart_' + product_id).css('display', 'none');
                        //$('#DIV_'+product_id).css('display','none');
                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);

                                })


                            if (user_id > 0) {
                                fetch(myConstClass.BASE_URL + 'cart/getAllMyCartData/' + user_id + '/' + getLanguage())
                                    .then(response => response.json())
                                    .then(response => {
                                        this.setState({ productIncart: response })
                                        //var totalRecords =  this.state.cart[0].title;
                                        const numRows = this.state.productIncart.length;
                                        if (numRows < 1) {
                                            $('#productIncart').css('display', 'block');
                                        } else {
                                            $('#productIncart').css('display', 'none');
                                        }
                                    })
                            } else {
                                $('#productIncart').css('display', 'block');
                            }
                        }




                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                toast('You must have to login', { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
                //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

            }

        }
        //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
        //this.props.offline();    
    }

    handleChange(evt) {
        // check it out: we get the evt.target.name (which will be either "email" or "password")
        // and use it to target the key on our `state` object with the same name, using bracket syntax
        this.setState({ [evt.target.name]: evt.target.value });
        $(evt.target.id).val(evt.target.value);
    }
    increaseQuantity(product_id) {
        var qty = parseInt($('#qty' + product_id).val()) + 1;
        console.log(qty);
        $('#qty' + product_id).val(qty);
    }
    decreaseQuantity(product_id) {
        var qty = parseInt($('#qty' + product_id).val()) - 1;
        if (qty < 0) {
            qty = 0;
        }
        console.log(qty);
        $('#qty' + product_id).val(qty);
    }
    componentDidMount() {
        var user_id = localStorage.getItem('user_id');
        if (user_id == '' || user_id == undefined) {
            $('#goToCart').css('display', 'none');
            $('#goToLogin').css('display', 'inline');
        }
        var current_language = getLanguage();
        localStorage.setItem('current_language', current_language);
        const answer_array = this.props.location.pathname.split('/');

        var topic_id = answer_array['4'];

        var type_id = answer_array['3'];
        // alert(type_id);
        if (type_id > 0) {
            this.state.guestinfo.type_id = type_id;
        } else {
            var type_id = 0;
        }

        if (topic_id > 0) {
            this.state.guestinfo.topic_id = topic_id;
        } else {
            var topic_id = 0;
        }
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }

        fetch(myConstClass.BASE_URL + 'product/getAvailProdTypesFrontEnd/0/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ types: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'product/getProductsFrontEnd' + '/' + getLanguage() + '/' + topic_id + '/0/' + answer_array['3'] + '/0/' + user_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ posts: response })
                this.state.posts[0].arr_product.map((cell, ii) => {

                    if (ii == '0') {
                        this.setState({ share_image_url: cell.image_name });
                        this.setState({ social_description: cell.social_description });
                        this.setState({ share_current_image_url: window.location.href });
                        if (this.state.share_image_url) {
                            $("meta[property='og\\:image']").attr("content", this.state.share_image_url);
                        }
                        // alert('1'+this.state.social_description);
                    }
                });
                var numRows = this.state.posts[0].arr_product.length;
                if (numRows < 1) {
                    $('#productNotAvailable').css('display', 'block');
                } else {
                    $('#productNotAvailable').css('display', 'none');
                }
            })
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'product/getProductLanguages')
            .then(response => response.json())
            .then(response => this.setState({ languages: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ topic: response }))
            .catch(err => console.log(err))


        if (answer_array['3']) {
            fetch(myConstClass.BASE_URL + 'product/getProductTypeInfoFrontEnd/' + getLanguage() + '/' + answer_array['3'] + '/' + topic_id)
                .then(response => response.json())
                .then(response => this.setState({ guestinfo: response }))

                .catch(err => console.log(err))


        }

        var user_id = localStorage.getItem('user_id');

        if (user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/getAllMyCartData/' + user_id + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ productIncart: response })
                    //var totalRecords =  this.state.cart[0].title;
                    const numRows = this.state.productIncart.length;
                    if (numRows < 1) {
                        $('#productIncart').css('display', 'block');
                    } else {
                        $('#productIncart').css('display', 'none');
                    }
                })
        } else {
            $('#productIncart').css('display', 'block');
        }


        fetch(myConstClass.BASE_URL + 'cart/getRelatedEventsForProductFrontEnd/' + user_id + '/' + getLanguage())
            .then(response => response.json())
            .then(response => {
                this.setState({ cartEvent: response })
                //var totalRecords =  this.state.cart[0].title;
                const numRows = this.state.cartEvent.length;
                if (numRows < 1) {
                    $('#noRecordsFoundEventTR').css('display', 'block');
                }
            })

    }

    changeQuantity(e, product_id) {
        //this.state.quantityValue = event.target.value;
        // this.setState(
        //     {quantityValue : event.target.value}
        // );
        //alert(product_id);
        // this.setState({quantityValue: e.target.value});
        // document.getElementById("qty"+product_id).value = e;

    }
    addToCart(e) {
        var product_id = e.target.id;
        // console.log(e.target.id);
        var product_quantity = $('#qty' + product_id).val();
        var user_id = localStorage.getItem('user_id');
        const answer_array = this.props.location.pathname.split('/');
        console.log(product_quantity);

        if (product_quantity > 0 && product_id != '' && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/addProductToCart/' + product_id + '/' + product_quantity + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cart: response })
                    var message = this.state.cart.message;
                    var status = this.state.cart.status;
                    if (status == 'true') {
                        //$('#'+product_id).css('display','none');
                        //// $('#addedInCart_'+product_id).css('display','inherit');
                        $('#' + product_id).removeClass('btn-brown');
                        $('#' + product_id).addClass('btn-light');
                        //    $('#'+product_id).html('ADD MORE');
                        if (getLanguage() == 'en') {
                            $('#' + product_id).html('ADD MORE');
                        } else {
                            $('#' + product_id).html('अधिक जोडा');
                        }

                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });




                        if (user_id > 0 && user_id != null) {

                            fetch(myConstClass.BASE_URL + 'cart/getAllMyCartData/' + user_id + '/' + getLanguage())
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ productIncart: response })
                                    //var totalRecords =  this.state.cart[0].title;
                                    const numRows = this.state.productIncart.length;
                                    if (numRows < 1) {
                                        $('#productIncart').css('display', 'block');
                                    } else {
                                        $('#productIncart').css('display', 'none');
                                    }
                                })

                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                })
                        }
                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                toast('Product quantity must be greater than 0.', { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
                //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

            }

        }
        //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
        //this.props.offline();    
    }

    applyFilters() {
        var user_id = localStorage.getItem('user_id');
        $('#applyFilterBtn').css('display', 'none');
        $('#loader').css('display', 'inline-block');
        var topic_id = $('#topic_id').val();
        var language_id = $('#language_id').val();
        var type_id = $('#type_id').val();
        //alert(language_id);
        if (topic_id == '') {
            topic_id = 0;
        } if (language_id == '') {
            language_id = 0;
        }
        if (type_id == '') {
            type_id = 0;
        }

        fetch(myConstClass.BASE_URL + 'product/getAvailProdTypesFrontEnd/0' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ types: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'product/getProductsFrontEnd/' + getLanguage() + '/' + topic_id + '/' + language_id + '/' + type_id + '/0/' + user_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ posts: response })
                this.state.posts[0].arr_product.map((cell, ii) => {

                    if (ii == '0') {
                        this.setState({ share_image_url: cell.image_name });
                        this.setState({ social_description: cell.social_description });
                        this.setState({ share_current_image_url: window.location.href });
                        if (this.state.share_image_url) {
                            $("meta[property='og\\:image']").attr("content", this.state.share_image_url);
                        }
                        // alert('1'+this.state.social_description);
                    }
                });

                var numRows = this.state.posts[0].arr_product.length;
                //   alert(numRows);
                if (numRows < 1) {
                    $('#productNotAvailable').css('display', 'block');
                } else {
                    $('#productNotAvailable').css('display', 'none');
                }
            })

            .catch(err => console.log(err))

        if (type_id > 0) {
            fetch(myConstClass.BASE_URL + 'product/getProductTypeInfoFrontEnd/' + getLanguage() + '/' + type_id + '/' + topic_id)
                .then(response => response.json())
                .then(response => this.setState({ guestinfo: response }))
                .catch(err => console.log(err))
        }
        setTimeout(function () {
            $('#applyFilterBtn').css('display', 'inline-block');
            $('#loader').css('display', 'none');
        }, 100);

        var topic_id = $('#topic_id').val();
        if (topic_id == '' || topic_id == undefined) {
            topic_id = 0;
        }
        fetch(myConstClass.BASE_URL + 'cart/getRelatedEventsForProductFrontEnd/' + user_id + '/' + getLanguage() + '/' + topic_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ cartEvent: response })
                //var totalRecords =  this.state.cart[0].title;
                const numRows = this.state.cartEvent.length;
                if (numRows < 1) {
                    $('#noRecordsFoundEventTR').css('display', 'block');
                }
            })
        // this.componentDidMount();
        //console.log(this.state.posts);
        //
        //console.log(this.state.eventinfo);
        //this.state.posts = this.state.eventinfo;
        //this.setState({ posts: this.state.eventinfo });
    }

    componentDidUpdate(prevProps) {
        //alert('hi');
        //console.log(prevProps);
        const answer_array = this.props.location.pathname.split('/');
        const { flag } = this.state
        var current_product_type = answer_array['3'];
        var old_product_type = localStorage.getItem('old_product_type');
        localStorage.setItem('old_product_type', current_product_type);

        //alert(old_product_type);
        if (old_product_type != '' && current_product_type != old_product_type && old_product_type != null && old_product_type > 0) {
            this.setState({ flag: 1 });
            //alert('hi');
        } else {
            var current_language = localStorage.getItem('current_language');

            if (current_language != getLanguage() && flag != 1 && current_language != null) {
                //alert(current_language);
                localStorage.removeItem('current_language');
                localStorage.setItem('current_language', getLanguage());
                this.setState({ flag: 1 });
            }
        }

        var type_id = answer_array['3'];
        if (type_id == '' || type_id == undefined) {
            type_id = 0;
        }

        var current_topic_id = answer_array['4'];
        var topic_id = answer_array['4'];
        //alert(topic_id);
        if (topic_id == '' || topic_id == undefined) {
            topic_id = 0;
        }
        var old_topic_id = localStorage.getItem('old_topic_id');
        localStorage.setItem('old_topic_id', current_topic_id);

        if (old_topic_id != '' && flag != 1 && current_topic_id != old_topic_id && old_topic_id != null && old_topic_id > 0) {
            this.setState({ flag: 1 });
            //alert('hi');
        }
        var user_id = localStorage.getItem('user_id');
        if (getLanguage() == 'ma' && flag == 1) {
            this.setState({ flag: 0 })
            //var topic_id =  $('#topic_id').val();
            //var language_id =  $('#language_id').val();
            language_id = "";
            var current_topic_id = answer_array['4'];
            var old_topic_id = localStorage.getItem('old_topic_id');
            localStorage.setItem('old_topic_id', current_topic_id);

            if (old_topic_id != '' && flag != 1 && current_topic_id != old_topic_id && old_topic_id != null && old_topic_id > 0) {
                this.setState({ flag: 1 });
                //alert('hi');
            }
            if (language_id == '') {
                language_id = 0;
            }
            // if(type_id == '' || type_id == undefined){
            //  type_id = 0;
            // }

            fetch(myConstClass.BASE_URL + 'product/getAvailProdTypesFrontEnd/0/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ types: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'product/getProductsFrontEnd/' + getLanguage() + '/' + topic_id + '/' + language_id + '/' + type_id + '/0/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response })
                    this.state.posts[0].arr_product.map((cell, ii) => {

                        if (ii == '0') {
                            this.setState({ share_image_url: cell.image_name });
                            this.setState({ social_description: cell.social_description });
                            this.setState({ share_current_image_url: window.location.href });
                            if (this.state.share_image_url) {
                                $("meta[property='og\\:image']").attr("content", this.state.share_image_url);
                            }
                            // alert('1'+this.state.social_description);
                        }
                    });
                    var numRows = this.state.posts[0].arr_product.length;
                    if (numRows < 1) {
                        $('#productNotAvailable').css('display', 'block');
                    } else {
                        $('#productNotAvailable').css('display', 'none');
                    }
                })
                .catch(err => console.log(err))
            if (answer_array['3']) {
                fetch(myConstClass.BASE_URL + 'product/getProductTypeInfoFrontEnd/' + getLanguage() + '/' + answer_array['3'] + '/' + topic_id)
                    .then(response => response.json())
                    .then(response => this.setState({ guestinfo: response }))
                    .catch(err => console.log(err))

                fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => this.setState({ topic: response }))
                    .catch(err => console.log(err))
            }


            fetch(myConstClass.BASE_URL + 'cart/getRelatedEventsForProductFrontEnd/' + user_id + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ cartEvent: response })
                    //var totalRecords =  this.state.cart[0].title;
                    const numRows = this.state.cartEvent.length;
                    if (numRows < 1) {
                        $('#noRecordsFoundEventTR').css('display', 'block');
                    }
                })


        }

        if (getLanguage() == 'en' && (flag == 1)) {
            this.setState({ flag: 0 })
            //var topic_id =  $('#topic_id').val();
            var language_id = $('#language_id').val();
            //var type_id =  $('#type_id').val();
            //alert(language_id);
            if (language_id == '') {
                language_id = 0;
            }
            if (type_id == '') {
                type_id = 0;
            }

            fetch(myConstClass.BASE_URL + 'product/getAvailProdTypesFrontEnd/0/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ types: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'product/getProductsFrontEnd' + '/' + getLanguage() + '/' + topic_id + '/' + language_id + '/' + type_id + '/0/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response })
                    this.state.posts[0].arr_product.map((cell, ii) => {

                        if (ii == '0') {
                            this.setState({ share_image_url: cell.image_name });
                            this.setState({ social_description: cell.social_description });
                            this.setState({ share_current_image_url: window.location.href });
                            if (this.state.share_image_url) {
                                $("meta[property='og\\:image']").attr("content", this.state.share_image_url);
                            }
                            // alert('1'+this.state.social_description);
                        }
                    });
                    var numRows = this.state.posts[0].arr_product.length;
                    if (numRows < 1) {
                        $('#productNotAvailable').css('display', 'block');
                    } else {
                        $('#productNotAvailable').css('display', 'none');
                    }
                })
                .catch(err => console.log(err))

            if (answer_array['3']) {
                fetch(myConstClass.BASE_URL + 'product/getProductTypeInfoFrontEnd/' + getLanguage() + '/' + answer_array['3'] + '/' + topic_id)
                    .then(response => response.json())
                    .then(response => this.setState({ guestinfo: response }))
                    .catch(err => console.log(err))

                fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                    .then(response => response.json())
                    .then(response => this.setState({ topic: response }))
                    .catch(err => console.log(err))
            }

            fetch(myConstClass.BASE_URL + 'cart/getRelatedEventsForProductFrontEnd/' + user_id + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ cartEvent: response })
                    //var totalRecords =  this.state.cart[0].title;
                    const numRows = this.state.cartEvent.length;
                    if (numRows < 1) {
                        $('#noRecordsFoundEventTR').css('display', 'block');
                    }
                })

        }

    }

    renderType = ({ id, title }) => <div className="row">
        <div className="col">
            <div className="col pt-3">
                <h5 className="font-weight-bold">{title}</h5>
            </div>
        </div>
    </div>

    renderGuest = ({ type_id, title, price, quantity }) =>
        <div><div className="row"><div className="col-md-3 mb-3">
            <div className="col border text-center">
                <div className=" py-3">
                    <img src={require("./assets/images/product1.png")} />
                    <div className="my-2">
                        <span className="mr-2">
                            <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                        </span>
                    </div>
                    <p className="font-weight-bold mb-1">{title}</p>
                    <p className="mb-1">Donation Contribution</p>
                    <p className="font-weight-bolder">INR. {price}/-</p>
                    <div className="qty mb-3">
                        <span>Qty-</span>
                        <span className="minus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-minus"></i>
                            </span>
                        </span>
                        <input type="text" className="count" name="qty" value={quantity} />
                        <span className="plus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-plus"></i>
                            </span>
                        </span>
                    </div>
                    <button className="btn btn-brown btn-sm">ADD TO CART</button>
                </div>
            </div></div></div></div>

    render() {
        const { cartEvent, productIncart, posts, types, languages, topic, guestinfo } = this.state
        const { t } = this.props;
        return (
            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 mt-3">
                            <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-white mb-0">
                                        <li className="breadcrumb-item">
                                            {/* <a href="#">{t('home.home')}</a> */}
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            {/* <a href="#">Products</a> */}
                                            <Link to="/products">{t('home.Products')}</Link>
                                        </li>

                                        <li className="breadcrumb-item">
                                            {/* <a href="#">Products</a> */}
                                            <Link   >{guestinfo.title}</Link>
                                        </li>

                                        <li className="breadcrumb-item active" aria-current="page">{guestinfo.topic}</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h4>{guestinfo.title} : {guestinfo.topic}</h4>
                                </div>
                            </div>
                            <div className="d-xl-none d-lg-none d-md-none d-block mb-3">
                                {guestinfo.title === 'Ayurvedic' && <img src={AyurvedicProducts} height={85} />}
                                {guestinfo.title === 'Bal Diwali Issue' && <img src={BalDiwali} height={85} />}
                                {guestinfo.title === 'Books' && <img src={Books} height={85} />}
                                {guestinfo.title === 'CDs' && <img src={CDs} height={85} />}
                                {guestinfo.title === 'Diwali Issue' && <img src={DIWALIMAGAZINE} height={85} />}
                                {guestinfo.title === 'Experimental' && <img src={ExperimentalProducts} height={85} />}
                                {guestinfo.title === 'Kits (Set of Books)' && <img src={KITs} height={85} />}
                            </div>
                            <Row className="bg-light m-0 py-2">
                                <Col xl="3" lg="3" md="12" className="align-items-center">
                                    <Label>
                                        {t('home.Select_filters_to_refine_the_results_below')}
                                    </Label>
                                </Col>


                                {/* Desktop Design Start */}
                                <Col xl="3" lg="3" md="8" className="">
                                    <div className="form-group">
                                        <label for="">{t('home.Topic')}</label>
                                        <select style={{ width: '100%' }} onClick={this.applyFilters} id="topic_id" name="topic_id" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0 ">
                                            <option value="">{t('home.All')}</option>
                                            {topic.map((info, ii) => {
                                                if (info.value == guestinfo.topic_id) {
                                                    return (
                                                        <option value={info.value} selected="selected">{info.label}</option>
                                                    )
                                                } else {
                                                    return (
                                                        <option value={info.value}>{info.label}</option>
                                                    )
                                                }

                                            })
                                            }
                                        </select>
                                    </div>
                                </Col>

                                <Col xl="3" lg="3" md="4" className="">
                                    <div className="form-group"  >
                                        <label for="">{t('home.Language')}</label>
                                        <select style={{ width: '100%' }} onClick={this.applyFilters} id="language_id" name="language_id" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0 ">
                                            <option value="">{t('home.All')}</option>
                                            {languages.map((info, ii) => {
                                                return (
                                                    <option value={info.value}>{info.label}</option>
                                                )
                                            })
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xl="3" lg="3" className="d-xl-flex d-lg-flex d-none">
                                    <div className="form-group"  >
                                        <label for="">{t('home.Product Type')}</label>
                                        <select style={{ width: '100%' }} onClick={this.applyFilters} id="type_id" name="type_id" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0 ">
                                            <option value="">{t('home.All')}</option>
                                            {types.map((info, ii) => {
                                                if (guestinfo.type_id == info.id) {
                                                    return (
                                                        <option value={info.id} selected="selected">{info.title}</option>
                                                    )
                                                } else {
                                                    return (
                                                        <option value={info.id}>{info.title}</option>
                                                    )
                                                }

                                            })
                                            }
                                        </select>
                                    </div>
                                </Col>
                                {/*<Col xl="3" lg="3" onClick={this.applyFilters} className="d-xl-flex d-lg-flex d-none">
                                    <div className="form-group">
                                        <label for="" className="invisible">{t('home.Apply Filter')}</label>
                                        <button id="applyFilterBtn" className="btn btn-sm btn btn-outline-brown btn-block ">{t('home.APPLY FILTERS')}</button>
                                        <img id="loader" style={{ display: 'none', marginTop: '0px' }} src={myConstClass.IMAGE_URL + 'loader.gif'} />
                                    </div>
                                </Col>
                              */}
                                {/* Desktop Design End */}


                                {/* Mobile Design Start */}
                                {/*<Col md="8" xs="8" className="d-xl-none d-lg-none">
                                    <FormGroup>
                                        <Label for="">{t('home.Topic')}</Label>
                                        <Input type="select" name="select" id="topic_id" name="topic_id"  bsSize="sm" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                            <option value="">{t('home.All')}</option>
                                            { 
                                               topic.map((info, ii) => {
                                                if (info.value == guestinfo.topic_id) {
                                                    return (
                                                        <option value={info.value} selected="selected">{info.label}</option>
                                                    )
                                                } else {
                                                    return (
                                                        <option value={info.value}>{info.label}</option>
                                                    )
                                                }

                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col md="4" xs="4" className="d-xl-none d-lg-none">
                                    <FormGroup>
                                        <Label for="">{t('home.Language')}</Label>
                                        <Input type="select" id="language_id" name="language_id"  bsSize="sm" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                            <option value="">{t('home.All')}</option>
                                            {
                                                languages.map((info, ii) => {
                                                return (
                                                    <option value={info.value}>{info.label}</option>
                                                )
                                            })
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>

                              */}
                                {/* Mobile Design End */}

                            </Row>
                        </div>

                        <div className="col-lg-8 mt-4">
                            {posts.map((cell, i) => {
                                //if(cell.arr_product.length == 4){
                                return (
                                    <Container fluid={true} className="mb-3">
                                        {/* <div className="row">
                    <div className="col">
                        <div className="col pt-3">
                            <h5 className="font-weight-bold">{cell.title}</h5>
                        </div>
                    </div>
                </div> */}
                                        <Row>
                                            {cell.arr_product.map((info, ii) => {
                                                return (
                                                    <Col xl="3" lg="3" md="6" sm="6" xs="6" className="mb-3">
                                                        <Card className="text-center">
                                                            <Link to={"/products_details/" + info.title_url + '/' + info.product_id}>
                                                                <div className="image-container px-2 py-2">
                                                                    <img className="image_css_product" src={info.image_name} />
                                                                </div>
                                                            </Link>
                                                            <CardBody className="py-3">
                                                                <Row>
                                                                    <Col xs="12">
                                                                        <span className="mr-2">
                                                                            <i className="far fa-comment-dots orangeColor"></i> <span className="small">{info.total_review}</span>
                                                                        </span>
                                                                        <span>
                                                                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{info.total_like}</span>
                                                                        </span>
                                                                    </Col>
                                                                    <Col xs="12" className="px-1 text-truncate mh--100-px">
                                                                        <Link className="font-weight-bold" style={{ color: 'black' }} to={"/products_details/" + info.title_url + '/' + info.product_id}>{info.short_title}</Link>
                                                                    </Col>
                                                                    <Col xs="12" className="px-1">
                                                                        {t('home.donation_contribution')}
                                                                    </Col>
                                                                    <Col xs="12" className="font-weight-bolder">
                                                                        {t('home.INR')}{info.price}/-
                                </Col>
                                                                    <Col xs="12" className="qty mb-3 px-0">
                                                                        <span>{t('home.Qty')}&nbsp;</span>
                                                                        <span className="minus border" onClick={this.decreaseQuantity.bind(this, info.product_id)}>
                                                                            <span className="d-flex align-items-center ico-hld">
                                                                                <i className="fas fa-minus"></i>
                                                                            </span>
                                                                        </span>
                                                                        <input type="text" className="count" defaultValue="1" name={"qty" + info.product_id} id={"qty" + info.product_id} onChange={this.handleChange} />
                                                                        <span className="plus border" name={"qty_inc" + info.product_id} id={"qty_inc" + info.product_id} onClick={this.increaseQuantity.bind(this, info.product_id)}>
                                                                            <span className="d-flex align-items-center ico-hld"  >
                                                                                <i className="fas fa-plus"></i>
                                                                            </span>
                                                                        </span>
                                                                    </Col>
                                                                    <Col xs="12" className="px-0">
                                                                        {info.added_in_cart.map((carts, j) => {
                                                                            if (carts.cart_id > 0) {
                                                                                return (
                                                                                    <span>
                                                                                        {/* <button id={'addedInCart_'+info.product_id} class="btn btn-sm btn-light" onClick={this.addToCart}>{t('home.ADD MORE')}</button> */}
                                                                                        <button className="btn btn-sm btn-light" name={info.product_id} id={info.product_id} onClick={this.addToCart}>{t('home.ADD MORE')}</button>
                                                                                    </span>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <span>
                                                                                        <button style={{ display: 'none' }} id={'addedInCart_' + info.product_id} class="btn btn-sm btn-light">{t('home.ADD MORE')}</button>
                                                                                        {
                                                                                            info.out_of_stock === '1' ?
                                                                                                <button className="btn btn-brown btn-sm">OUT OF STOCK</button>
                                                                                                :
                                                                                                <button className="btn btn-brown btn-sm" name={info.product_id} id={info.product_id} onClick={this.addToCart}>
                                                                                                    {t('home.ADD TO CART')}
                                                                                                </button>
                                                                                        }
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        })
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>)
                                            })
                                            }
                                        </Row>
                                        {/* <div class="row">
                    <div class="col text-center">
                    <Link class="btn btn-outline-brown"  to={"/product_type/"+cell.title.toString().toLowerCase()+'/'+cell.id}>
                    {t('home.SHOW MORE')}
                    </Link>
                       
                    </div>
                </div> */}
                                    </Container>
                                )
                                //}
                            })}


                            <div class="row">
                                <p id="productNotAvailable" style={{ display: 'none', marginLeft: '20px' }}>
                                    {t('home.Not available')}
                                </p>
                            </div>

                            {

                                posts.map((cell, i) => {
                                    let type = cell.type_id;
                                    if (type == cell.type_id) {
                                        return this.renderGuest;
                                    }
                                    return <div> Test Data 2</div>;
                                })
                            }

                            {/* <div className="row">
                    <div className="col text-center">
                        <button className="btn btn-outline-brown">SHOW MORE</button>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12">
                        <h5 className="font-weight-bold">Magazines</h5>
                    </div>
                </div>
                <div className="row border py-3">
                    <div className="col-12">
                        <p>
                            "Manashakti" - a monthly magazine, catering to the likes and needs of every age group in the family. There are about 40 thousand subscribers of this magazine.
                        </p>
                        <p><span className="font-weight-bolder">Language:</span> Marathi</p>
                    </div>
                    <div className="col-1-5">
                        <img src={require("./assets/images/mag1.png")} className="img-fluid" />
                    </div>
                    <div className="col-1-5">
                        <img src={require("./assets/images/mag1.png")} className="img-fluid" />
                    </div>
                    <div className="col-1-5">
                        <img src={require("./assets/images/mag1.png")} className="img-fluid" />
                    </div>
                    <div className="col-1-5">
                        <img src={require("./assets/images/mag1.png")} className="img-fluid" />
                    </div>
                    <div className="col-1-5">
                        <img src={require("./assets/images/mag1.png")} className="img-fluid" />
                    </div>
                    <div className="col-12 text-center mt-4">
                        <button className="btn btn-brown">SUCRIBE NOW</button>
                    </div>
                </div> */}
                        </div>
                        <div className="col-lg-4">
                            <h6 className="color-red mb-1">
                                {t('home.Products in the Cart')}

                            </h6>

                            <div className="col home-box-no-offset">
                                {productIncart.map((info, ii) => {
                                    return (
                                        <div className="row pt-3 border-bottom mb-0" id={'DIV_' + info.product_id}>
                                            <div className="col">
                                                {info.title}

                                            </div>
                                            <div className="col text-right">
                                                <div className="qty mb-3">
                                                    <span>{t('home.Qty')} - {info.quantity}</span>
                                                    {/* <span className="minus border">
                                        <span className="d-flex align-items-center ico-hld">
                                            <i className="fas fa-minus"></i>
                                         </span>
                                </span>
                                <input type="number" className="count" name="qty" value="1"/>
                                <span className="plus border">
                                        <span className="d-flex align-items-center ico-hld">
                                            <i className="fas fa-plus"></i>
                                        </span>
                                </span> */}
                                                    <span className="ml-4"><i name={info.product_id} id={info.product_id} onClick={this.removeFromCart} style={{ cursor: 'pointer' }} className="fas fa-trash-alt"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }

                                <div className="row pt-3 border-bottom mb-0" id="productIncart" style={{ display: 'none' }}>
                                    <div style={{ paddingBottom: '10px' }} className="col">{t('home.No product found in cart')}</div>

                                </div>
                            </div>

                            <div class="row py-3 border-bottom mb-0 align-items-center">
                                <div class="col">
                                    <Link to="/mycart" id="goToCart">
                                        <button class="btn btn-sm btn-brown px-4">{t('home.GO TO CART')}</button>
                                    </Link>
                                    <Link to="/signin" style={{ display: 'none' }} id="goToLogin">
                                        <button class="btn btn-sm btn-brown px-4">{t('home.GO TO CART')}</button>
                                    </Link>
                                </div>
                            </div>


                            <h6 className="color-red mt-4 mb-1">{t('home.Related Events')}</h6>
                            <div className="col mt-3 p-0">
                                <div className="accordion" id="accordionExample">
                                    {cartEvent.map((info, ii) => {
                                        if (ii == 0) {
                                            return (
                                                <div className="card">
                                                    <div aria-expanded="true" className="card-header bg-white" id={"headingOne" + ii} data-toggle="collapse" data-target={'#collapseOne' + ii}>
                                                        <h6 className="mb-0">
                                                            <span className="orangeColor">{info.event_type}</span> <i style={{ cursor: 'pointer' }} className="border circle-ico fa-pull-right fas fa fa-chevron-down"></i>
                                                        </h6>
                                                    </div>

                                                    <div id={"collapseOne" + ii} className="collapse show" aria-labelledby={"headingOne" + ii} data-parent="#accordionExample">
                                                        <div className="card-body px-4 py-0">
                                                            {info.arr_cart.map((cell, i) => {
                                                                return (
                                                                    <div className="row py-3 border-bottom">
                                                                        <div className="col">{cell.title}</div>
                                                                        <div className="col text-right">
                                                                            <Link to={"/event_details/" + cell.event_id}   >
                                                                                <button className="btn btn-sm btn-outline-brown">
                                                                                    {t('home.KNOW MORE')}
                                                                                </button>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            }


                                                        </div>
                                                    </div>


                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className="card">
                                                    <div className="card-header bg-white" id={"headingOne" + ii} data-toggle="collapse" data-target={'#collapseOne' + ii}>
                                                        <h6 className="mb-0">
                                                            <span className="orangeColor">{info.event_type}</span> <i style={{ cursor: 'pointer' }} className="border circle-ico fa-pull-right fas fa fa-chevron-down"></i>
                                                        </h6>
                                                    </div>

                                                    <div id={"collapseOne" + ii} className="collapse" aria-labelledby={"headingOne" + ii} data-parent="#accordionExample">
                                                        <div className="card-body px-4 py-0">
                                                            {info.arr_cart.map((cell, i) => {
                                                                return (
                                                                    <div className="row py-3 border-bottom">
                                                                        <div className="col">{cell.title}</div>
                                                                        <div className="col text-right">
                                                                            <Link to={"/event_details/" + cell.event_id}   >
                                                                                <button className="btn btn-sm btn-outline-brown">
                                                                                    {t('home.KNOW MORE')}
                                                                                </button>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            }


                                                        </div>
                                                    </div>


                                                </div>
                                            )
                                        }

                                    }

                                    )
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer share_current_image_url={this.state.share_current_image_url} share_image_url={this.state.share_image_url} description={this.state.social_description} />
                {/* <div className="container-fluid bg-grey mt-4">
        <div className="row grey-text">
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Residential Study Courses</a>
                <a href="#" className="grey-text d-block py-1">1-day Courses</a>
                <a href="#" className="grey-text d-block py-1">Machine Tests</a>
                <a href="#" className="grey-text d-block py-1">Rational Poojas</a>
                <a href="#" className="grey-text d-block py-1">Free Events</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Books</a>
                <a href="#" className="grey-text d-block py-1">CDs</a>
                <a href="#" className="grey-text d-block py-1">Kits (Set of Books)</a>
                <a href="#" className="grey-text d-block py-1">Experimental Products</a>
                <a href="#" className="grey-text d-block py-1">Ayurvedic Products</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Magazines</a>
                <a href="#" className="grey-text d-block py-1">Gallery</a>
                <a href="#" className="grey-text d-block py-1">Downloads</a>
                <a href="#" className="grey-text d-block py-1">FAQs</a>
                <a href="#" className="grey-text d-block py-1">E-Shopping Help</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">About Us</a>
                <a href="#" className="grey-text d-block py-1">Research</a>
                <a href="#" className="grey-text d-block py-1">Event Calendar</a>
                <a href="#" className="grey-text d-block py-1">Feedback</a>
                <a href="#" className="grey-text d-block py-1">Contact Us</a>
            </div>
        </div>
    </div> */}
                {/* <div className="container-fluid bg-black">
        <p className="text-center py-3 grey-text mb-0">
            © 2000-2017 Manashakti Rest New Way, | All Rights Reserved Worldwide
        </p>
    </div> */}
            </div>
        );
    }
}


Product_type.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Product_type);
