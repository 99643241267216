import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import { postAddress, updateAddress } from "../../store/savedAddress";

const saveAddressActions = Object.freeze({
  update: "UPDATE",
  create: "CREATE",
});

export default function SaveAddressModal({ show, onUpdate, onCreate }) {
  const user_id = localStorage.getItem("user_id");

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(true);
  const [savedAddressLabel, setSavedAddressLabel] = useState("");
  const [labelError, setLabelError] = useState(false);
  const [chooseSaveType, setChooseSaveType] = useState(
    saveAddressActions.create
  );

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  async function onSubmitModalForm() {
    // console.log("onSubmitModalForm");
    // if (!savedAddressLabel) return;

    // const labelRegex = /^[a-zA-Z]{2,12}$/.test(savedAddressLabel);
    const labelRegex = /^[A-Za-z&\-0-9 ]{2,15}$/.test(savedAddressLabel);

    if (chooseSaveType === "UPDATE") {
      // console.log("update address from Modal");
      if (onUpdate) onUpdate();
      setShowModal(false);
    } else if (chooseSaveType === "CREATE" && savedAddressLabel) {
      if (labelRegex) {
        setLabelError(false);
        if (onCreate) onCreate(savedAddressLabel);
        setShowModal(false);
      } else {
        setLabelError(true);
      }
    }
    setChooseSaveType(saveAddressActions.create);
    setSavedAddressLabel("");
  }

  async function handleSubmitModal(e) {
    e.preventDefault();
    // console.log('modal submit');
  }

  return (
    <div>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header>
          <Modal.Title>Select the change address option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitModal}>
            <Form.Group
              className="mb-2"
              onChange={(e) => {
                setChooseSaveType(e.target.value);
              }}
            >
              <Form.Check
                type="radio"
                id="updateAddress"
                name="addresstype"
                value={saveAddressActions.update}
                defaultChecked={chooseSaveType === saveAddressActions.update}
                label="Update Selected Address"
                className="mt-2 mb-2"
              />
              <Form.Check
                type="radio"
                id="addNewAddress"
                name="addresstype"
                value={saveAddressActions.create}
                defaultChecked={chooseSaveType === saveAddressActions.create}
                label="Add as a New Address"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="mb-1">Add address label</Form.Label>
              <Form.Control
                className=""
                minLength={2}
                maxLength={15}
                disabled={chooseSaveType !== saveAddressActions.create}
                onChange={(e) => {
                  setSavedAddressLabel(e.target.value);
                  if (e.target.value.length < 3) {
                    setLabelError(false);
                  }
                }}
                style={{ border: labelError ? '1px solid red' : '' }}
                placeholder="Home"
              />
              { labelError && <p style={{ color: 'red', fontStyle: 'italic', marginLeft: '5px' }}>Please enter valid label</p> }
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-accent"
            disabled={chooseSaveType === "CREATE" && !savedAddressLabel}
            onClick={onSubmitModalForm}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
