import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Footer from "./Footer.js";
import Header from "./Header.js";
import Stepper from "./Stepper";
import "./address.css";
import AddressCarousel from "./components/AddressCarousel.jsx";
import FixedBottomSummary from "./components/_FixedBottomSummary";
import BillingAddressForm from "./components/address/BillingAddressForm.jsx";
import SaveAddressModal from "./components/address/SaveAddressModal.jsx";
import ShippingAddressForm from "./components/address/ShippingAddressForm.jsx";
import {
  serializeCompareBilling,
  // serializeCompareShipping,
  serializeToBillingAddressState,
  serializeToBillingPayload,
  serializeToSavedAddressPayload,
  serializeToShippingAddressState,
  serializeToShippingPayload,
} from "./components/address/serde.js";
import {
  areObjectsEqual,
  fetchAllCartData,
  saveBillingAddressById,
  saveShippingAddressById,
} from "./components/address/utils.js";
import {
  getCartTotalPriceAsync,
  manageHasEventsAsync,
  selectCheckoutSteps,
  selectTotalPrice,
  stepsEnum,
} from "./store/checkoutSlice";
import {
  fetchAddresses,
  postAddress,
  updateAddress,
} from "./store/savedAddress.js";
import "./styles/bs-accordion.css";
import { set } from "date-fns";

const AddressPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const savedAddresses = useSelector((state) => state.address.addresses);

  const checkoutSteps = useSelector(selectCheckoutSteps);
  const totalPrice = useSelector(selectTotalPrice);

  const [tncBox, setTncBox] = useState(true);

  const [active, setActive] = useState("0");

  const [showShipping, setShowShipping] = useState(false);
  const [sameAsShipping, setSameAsShipping] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [shippingAddressState, setShippingAddressState] = useState({});
  const shippingAddressFormRef = useRef();

  const [billingAddressState, setBillingAddressState] = useState({});
  const billingAddressFormRef = useRef();

  // save select saved address box for check update state
  const [selectedSavedAddress, setSelectedSavedAddress] = useState(null);
  // set shipping values for comparing to handle update
  // const [shippingAddressForCheck, setShippingAddressForCheck] = useState({});
  // set billing values for comparing to handle update
  // const [billingAddressForCheck, setBillingAddressForCheck] = useState({});

  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    // console.log(localStorage.getItem("user_id"))
    const user_id = localStorage.getItem("user_id");
    if (user_id) {
      localStorage.removeItem("error");
    } else {
      localStorage.setItem("error", "please login first");
      return history.replace('/signin')
    }
  }, [])

  useEffect(() => {
    fetchAllCartData().then(({ cart, shipping, billing }) => {
      // console.log(cart, billing, shipping);
      setShowShipping(shipping);
      if (!shipping) {
        setActive("1");
        // Without shipping view checkbox state auto true
        setTncBox(true);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getCartTotalPriceAsync());
    dispatch(manageHasEventsAsync());
    dispatch(fetchAddresses());
    console.log('prepod deploy success15');
  }, [dispatch]);

  function compareAddresses() {
    try {
      // console.log("comparing");
      const _serdShipping =
        serializeToShippingAddressState(selectedSavedAddress);
      const _serdBilling = serializeToBillingAddressState(selectedSavedAddress);

      const _shippingEqual = areObjectsEqual(
        _serdShipping,
        shippingAddressState
      );
      // console.log(_serdShipping, shippingAddressState);

      const _billingEqual = areObjectsEqual(_serdBilling, billingAddressState);
      // console.log(_serdBilling, billingAddressState);

      // console.log({ _billingEqual, _shippingEqual });
      if (_billingEqual && _shippingEqual) {
        // console.log("no changes");
        return true;
      }

      return false;
    } catch (error) {
      console.log("compareAddresses -> error", error);
    }
  }

  async function createSavedAddress(label = "Home") {
    try {
      const _serdSavedAddressPayload = serializeToSavedAddressPayload(
        shippingAddressState,
        billingAddressState
      );
      _serdSavedAddressPayload.label = label;
      _serdSavedAddressPayload.user_id = localStorage.getItem("user_id");
      // console.log(_serdSavedAddressPayload);
      dispatch(postAddress(_serdSavedAddressPayload)).then(() => {
        dispatch(fetchAddresses());
        // console.log("Created saved address");
        history.push("/checkout");
        // console.log("Created saved address");
      });
    } catch (error) {
      console.log("createSavedAddress error", error);
    }
  }

  async function updateSavedAddress() {
    try {
      // console.log("updateSavedAddress");
      const _serdSavedAddressPayload = serializeToSavedAddressPayload(
        shippingAddressState,
        billingAddressState
      );
      _serdSavedAddressPayload.id = selectedSavedAddress.id;
      _serdSavedAddressPayload.label = selectedSavedAddress.label;
      // console.log(_serdSavedAddressPayload);
      dispatch(updateAddress(_serdSavedAddressPayload)).then(() => {
        dispatch(fetchAddresses());
        // console.log("Updated saved address");
      });
    } catch (error) {
      console.log("updateSavedAddress error", error);
    }
  }

  async function saveAddresses() {
    try {
      const _shippingAddressPayload =
        serializeToShippingPayload(shippingAddressState);
      const _billingAddressPayload =
        serializeToBillingPayload(billingAddressState);
      // console.log(
      //   "state",
      //   { shippingAddressState, billingAddressState },
      //   "payloads",
      //   { _shippingAddressPayload, _billingAddressPayload }
      // );
      const [saveShipping, saveBilling] = await Promise.all([
        saveShippingAddressById(_shippingAddressPayload, (err, data) => {
          // console.log(err, data);
        }),
        saveBillingAddressById(_billingAddressPayload, (err, data) => {
          // console.log(err, data);
        }),
      ]);
      // console.log({ saveShipping, saveBilling });
    } catch (error) {
      console.log("saveAddresses -> error", error);
    }
  }

  async function handleNext() {
    try {
      // console.log("Handle Next");
      const shippingValid = shippingAddressFormRef.current.validate();
      const billingValid = billingAddressFormRef.current.validate();

      // console.log({ shippingValid, billingValid });
      if (!shippingValid || !billingValid) return;

      await saveAddresses();

      if (selectedSavedAddress) {
        // run save address for shipping & billing
        // & then when success open the modal to create or update
        // follow respective action

        const compareEqual = compareAddresses();
        // console.log({ compareEqual });
        if (compareEqual) {
          // route navigate
          history.push("/checkout");
        } else {
          setShowModal(true);
        }
      } else {
        // console.log("new address");
        createSavedAddress().then(() => {
          // console.log('node js post request success');
          history.push("/checkout");
        });
      }
    } catch (error) {
      console.log("handleNext -> error", error);
    }
  }

  async function handleNextForBillingOnly() {
    try {
      const billingValid = billingAddressFormRef.current.validate();
      // console.log({ billingValid });
      if (!billingValid) return;
      await saveAddresses();
      // if (selectedSavedAddress) {
        // const compareEqual = compareAddresses();
        // console.log({ compareEqual });
        // if (compareEqual) {
        //   history.push("/checkout");
        // } else {
        //   setShowModal(true);
        // }
        history.push('/checkout');
      // } else {
        // console.log("new address");
        // createSavedAddress("Billing").then(() => {
        //   history.push("/checkout");
        // });
      // }
    } catch (error) {
      console.log("handleNextForBillingOnly -> error", error);
    }
  }

  return (
    <div className="position-relative">
      <ToastContainer />
      <Header />

      <div className="mt-4 pt-2 mt-sm-4 pt-sm-4 mt-md-4 pt-md-4 mt-xl-1">
        <Stepper steps={checkoutSteps} currentStep={stepsEnum[2]} />
      </div>

      {/* <pre>{JSON.stringify(savedAddresses, null, 2)}</pre> */}
      {savedAddresses?.length > 0 && (
        <AddressCarousel
          savedAddresses={savedAddresses}
          // selectAddressId={selectAddressId}
          // handleDeleteAddress={handleDeleteAddress}
          // handleSetFormFields={handleSetFormFields}
          onSavedAddressSelect={(savedAddress) => {
            setSelectedSavedAddress(savedAddress);
            setShippingAddressState(
              serializeToShippingAddressState(savedAddress)
            );
            setBillingAddressState(
              serializeToBillingAddressState(savedAddress)
            );
          }}
        />
      )}

      {/* Events 
          Root
            Next Button onClick
              Submit 
                Selected Saved Address
                  Updated ? Modal
                              Create | Update
                Shipping/Billing Save 

          Nestesd
            Shipping/Billing Form onChange
            Same As Shipping Checkbox onChange

          */}

      <div className="custom-flex col-12 col-lg-12">
        <div className="accordion-container right-side">
          <Accordion
            className="container px-0"
            activeKey={active}
            onSelect={(e) => {
              setActive(e);
              setActiveKey(e);
            }}
          >
            {showShipping ? (
              <Accordion.Item eventKey="0" className="border-0">
                <Accordion.Header>
                  <h3 className="fw-bold">
                    <b>Shipping Address</b>
                  </h3>
                </Accordion.Header>
                <Accordion.Body>
                  <ShippingAddressForm
                    ref={shippingAddressFormRef}
                    shippingAddress={shippingAddressState}
                    onFormValueChange={(state) => {
                      // console.log("setting shipping", state);
                      setShippingAddressState(state);
                      // setShippingAddressForCheck(
                      //   serializeCompareShipping(state)
                      // );
                      if (sameAsShipping) {
                        // console.log({ billingAddressState, state });
                        delete state.type
                        setBillingAddressState(state);
                        // setBillingAddressForCheck(
                        //   serializeCompareBilling(state)
                        // );
                      }
                    }}
                    onTnCCheck={(check) => {
                      setTncBox(check);
                    }}
                    onSameAsShipping={(_sameAsShipping, state) => {
                      setSameAsShipping(_sameAsShipping);
                      if (_sameAsShipping && state) {
                         delete state.type
                        setBillingAddressState(state);
                      }
                      // setBillingAddressState(serialize(state));
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ) : null}

            {showShipping ? (
              <Accordion.Item eventKey="1" className="border-0 mt-1">
                <Accordion.Header>
                  <h3 className="fw-bold">
                    <b>Billing Address</b>
                  </h3>
                </Accordion.Header>
                <Accordion.Body>
                  <BillingAddressForm
                    ref={billingAddressFormRef}
                    sameAsShipping={sameAsShipping}
                    billingAddress={billingAddressState}
                    onFormValueChange={(state) => {
                      setBillingAddressState(state);
                      // setBillingAddressForCheck(serializeCompareBilling(state));
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <Accordion.Item eventKey="1" className="border-0 mt-1">
                <Accordion.Header>
                  <h3 className="fw-bold">
                    <b>Billing Address</b>
                  </h3>
                </Accordion.Header>
                <Accordion.Body>
                  <BillingAddressForm
                    ref={billingAddressFormRef}
                    sameAsShipping={sameAsShipping}
                    billingAddress={billingAddressState}
                    onFormValueChange={(state) => {
                      setBillingAddressState(state);
                      delete state.type
                      setShippingAddressState(state);
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </div>
      </div>

      <FixedBottomSummary totalPrice={totalPrice}>
        <div>
          <button
            type="submit"
            form=""
            className="w-100 btn py-2 proceed-button text-nowrap"
            onClick={() => {
              if (showShipping) handleNext();
              else handleNextForBillingOnly();
            }}
            disabled={!tncBox}
          >
            <span className="mr-2">Next</span>{" "}
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
      </FixedBottomSummary>

      <SaveAddressModal
        show={showModal}
        onUpdate={() => {
          updateSavedAddress();
          setShowModal(false);
          // console.log("update");
          history.push("/checkout");
        }}
        onCreate={(label) => {
          createSavedAddress(label);
          setShowModal(false);
          // console.log("create");
          history.push("/checkout");
        }}
      />

      <Footer />
    </div>
  );
};

export default AddressPage;
