import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';



import ReactDOM from 'react-dom';
import FullCalendar from 'fullcalendar-reactwrapper';
import 'fullcalendar/dist/fullcalendar.css';


import Header from './Header.js';
import Footer from './Footer.js';
import * as myConstClass from './constant.js';
import $ from 'jquery';

import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
//import { toast } from 'react-toastify';




//console.log('.push(',{body3});


// async function getMovies(){
//   await fetch(myConstClass.BASE_URL+'product/getProductsFrontEnd')
//     .then((response) => response.json());
// }



class Event_calendar_grid extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            quantityValue: '1',
            selected_city_id: '0',
            selected_topic_id: '0',
            flag: 0,
            posts: [],
            arr_events: [],
            arrSchedule: [],
            eventinfo: [],
            month: [],
            removeFromCart: [],
            topic: [],
            cart: [],
            cartEvent: [],
            productIncart: [],
            arrCity: [],
            guestinfo: {
                title: '',
                type_id: '',
                topic: '',
                schedule_date_selected: '',
            },
            events: [],
        }
        this.handleChange = this.handleChange.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.getAllSchedule = this.getAllSchedule.bind(this);
        this.getBorderDate = this.getBorderDate.bind(this);
    }

    removeFromCart(e) {
        var product_id = e.target.id;
        // console.log(e.target.id);
        var product_quantity = $('#qty' + product_id).val();
        var user_id = localStorage.getItem('user_id');
        console.log(product_quantity);

        if (product_id != '' && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/removeProductFromCart/' + product_id + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ removeinfo: response })
                    var message = this.state.removeinfo.message;
                    var status = this.state.removeinfo.status;
                    if (status == 'true') {
                        //$('#DIV_'+product_id).css('display','none');
                        $('#' + product_id).css('display', 'inherit');
                        $('#addedInCart_' + product_id).css('display', 'none');
                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);

                                })


                            if (user_id > 0) {
                                fetch(myConstClass.BASE_URL + 'cart/getAllMyCartData/' + user_id + '/' + getLanguage())
                                    .then(response => response.json())
                                    .then(response => {
                                        this.setState({ productIncart: response })
                                        //var totalRecords =  this.state.cart[0].title;
                                        const numRows = this.state.productIncart.length;
                                        if (numRows < 1) {
                                            $('#productIncart').css('display', 'block');
                                        } else {
                                            $('#productIncart').css('display', 'none');
                                        }
                                    })
                            } else {
                                $('#productIncart').css('display', 'block');
                            }
                        }




                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                toast('You must have to login', { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
                //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

            }

        }
        //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
        //this.props.offline();    
    }

    handleChange(evt) {
        // check it out: we get the evt.target.name (which will be either "email" or "password")
        // and use it to target the key on our `state` object with the same name, using bracket syntax
        this.setState({ [evt.target.name]: evt.target.value });
        //console.log(evt);
    }
    increaseQuantity(product_id) {
        var qty = parseInt($('#qty' + product_id).val()) + 1;
        console.log(qty);
        $('#qty' + product_id).val(qty);
    }
    decreaseQuantity(product_id) {
        var qty = parseInt($('#qty' + product_id).val()) - 1;
        if (qty < 0) {
            qty = 0;
        }
        console.log(qty);
        $('#qty' + product_id).val(qty);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {

        var user_id = localStorage.getItem('user_id');
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }

        var selected_city_id = localStorage.getItem('selected_city_id');
        if (selected_city_id > 0 && selected_city_id != undefined) {
            this.state.selected_city_id = selected_city_id;
        } else {
            var selected_city_id = 0;
        }
        var selected_topic_id = localStorage.getItem('selected_topic_id');
        if (selected_topic_id > 0 && selected_topic_id != undefined) {
            this.state.selected_topic_id = selected_topic_id;
        } else {
            var selected_topic_id = 0;
        }

        fetch(myConstClass.BASE_URL + 'event/getEventLocationFrontEnd/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ arrCity: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'event/getEventsForCalendarGridFrontEnd' + '/' + getLanguage() + '/' + selected_topic_id + '/' + selected_city_id)
            .then(response => response.json())
            .then(response => this.setState({ events: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'event/getEventCalendarMonth' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ month: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ topic: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'event/getAllEventScheduleCalendarFrontEnd/' + getLanguage() + '/1')
            .then(response => response.json())
            .then(response => {
                this.setState({ arrSchedule: response })

                const numRows = this.state.arrSchedule.length;
                if (numRows > 0) {
                    this.state.guestinfo.schedule_date_selected = this.state.arrSchedule[0].date;
                    // $('#noItemInCart').css('display','none');
                } else {
                    //$('#noItemInCart').css('display','block');
                }

            })

        var user_id = localStorage.getItem('user_id');



    }


    applyFilters(e) {
        //     e.preventDefault();
        //     var user_id = localStorage.getItem('user_id');
        //     $('#applyFilterBtn').css('display','none');
        //     $('#loader').css('display','inline-block');
        //    var topic_id =  $('#topic_id').val();
        //    var month =  $('#month').val();
        //    var city_id =  $('#city_id').val();
        //    if(topic_id == ''){
        //     topic_id = 0;
        //    }
        //    if(city_id == ''){
        //     city_id = 0;
        //    }


        //    fetch(myConstClass.BASE_URL+'event/getEventsForCalendarGridFrontEnd/'+getLanguage()+'/'+topic_id+'/'+city_id+'/'+month)
        //    .then(response => response.json())
        //    .then(response =>this.setState({events: response}))

        //    .catch(err => console.log(err))


        //    setTimeout(function(){ 
        //     $('#applyFilterBtn').css('display','inline-block');
        //     $('#loader').css('display','none');
        //  }, 100);

        //  var schedule_date = month;

        //     if(schedule_date == null || schedule_date == undefined || schedule_date == ''){

        //         var   schedule_date = "";
        //     }else{

        //     }

        //     this.state.arrSchedule=[];
        //     var month =  $('#month').val();
        //     var schedule_date = month;
        //     var d = new Date();
        //      var todays_date = d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate();
        //     var endDate = new Date(schedule_date);
        //     var startDate = new Date(todays_date);
        //     var months = (endDate.getFullYear()*12+endDate.getMonth())-(startDate.getFullYear()*12+startDate.getMonth())

        //     if(months > 0){
        //              for(var i=0; i < months;i++){
        //                  $('.fc-next-button').click();
        //              }

        //          }

        //          if(months < 0){
        //              for(var i=0; i > months;i--){
        //                  $('.fc-prev-button').click();
        //              }
        //          }
        var topic_id = $('#topic_id').val();
        var month = $('#month').val();
        var city_id = $('#city_id').val();
        //alert(language_id);
        if (topic_id == '') {
            topic_id = 0;
        }
        if (city_id == '') {
            city_id = 0;
        }

        localStorage.removeItem('selected_topic_id');
        localStorage.setItem('selected_topic_id', topic_id);
        localStorage.removeItem('selected_city_id');
        localStorage.setItem('selected_city_id', city_id);
        localStorage.removeItem('selected_month_value');
        localStorage.setItem('selected_month_value', month);
        setTimeout(this.props.history.push('/event-calendar'), 500);

    }

    componentDidUpdate(prevProps) {
        const answer_array = this.props.location.pathname.split('/');
        const { flag } = this.state
        // alert(answer_array);
        var user_id = localStorage.getItem('user_id');


        var current_language = localStorage.getItem('current_language');
        //alert(current_language);    
        if (current_language != getLanguage() && flag != 1 && current_language != null) {
            //  alert(current_language);
            localStorage.removeItem('current_language');
            localStorage.setItem('current_language', getLanguage());
            this.setState({ flag: 1 });
        } else {
            localStorage.setItem('current_language', getLanguage());
        }

        if (getLanguage() == 'ma' && flag == 1) {
            this.state.arrSchedule = [];
            // e.preventDefault();
            $('html,body').animate({
                scrollTop: 0
            }, 700);
            this.setState({ flag: 0 })

            fetch(myConstClass.BASE_URL + 'event/getEventLocationFrontEnd/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrCity: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'event/getEventsForCalendarGridFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ events: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'event/getEventCalendarMonth' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ month: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))

            var schedule_date = $('#schedule_date').val();
            if (schedule_date == null || schedule_date == undefined) {
                var schedule_date = "";
            }


            fetch(myConstClass.BASE_URL + 'event/getAllEventScheduleCalendarFrontEnd/' + getLanguage() + '/1')
                .then(response => response.json())
                .then(response => {
                    this.setState({ arrSchedule: response })

                    const numRows = this.state.arrSchedule.length;
                    if (numRows > 0) {
                        this.state.guestinfo.schedule_date_selected = this.state.arrSchedule[0].date;
                        // $('#noItemInCart').css('display','none');
                    } else {
                        //$('#noItemInCart').css('display','block');
                    }

                })


        }

        if (getLanguage() == 'en' && (flag == 1)) {
            this.state.arrSchedule = [];
            $('html,body').animate({
                scrollTop: 0
            }, 700);
            this.setState({ flag: 0 })

            fetch(myConstClass.BASE_URL + 'event/getEventLocationFrontEnd/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrCity: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'event/getEventsForCalendarGridFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ events: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'event/getEventCalendarMonth' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ month: response }))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))


            fetch(myConstClass.BASE_URL + 'event/getAllEventScheduleCalendarFrontEnd/' + getLanguage() + '/1')
                .then(response => response.json())
                .then(response => {
                    this.setState({ arrSchedule: response })

                    const numRows = this.state.arrSchedule.length;
                    if (numRows > 0) {
                        this.state.guestinfo.schedule_date_selected = this.state.arrSchedule[0].date;
                        // $('#noItemInCart').css('display','none');
                    } else {
                        //$('#noItemInCart').css('display','block');
                    }

                })

        }
    }


    getBorderDate() {
        // var border_date = $('#border_date').val();
        // alert(border_date);
        // document.getElementsByTagName('data-date~="2020-05-10"')[0].setAttribute("class", "border_date");
    }
    getAllSchedule() {
        // e.preventDefault();
        // console.log(e);
        this._isMounted = true;
        if (this._isMounted) {
            var schedule_date = $('#schedule_date').val();
            //     alert(schedule_date);

            // $('.fc-future').css('border-color', '#ddd');
            // $('.fc-past').css('border-color', '#ddd');
            // // $(this).removeClass('border_style');
            // // $(this).addClass('border_style');
            // // //$(this).css('border-style', 'single');
            // // $(this).css('border-color', '#ffc107');
            $('[data-date~="' + schedule_date + '"]').css('border-color', '#ffc107');


            var d = new Date();
            var todays_date = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
            //alert(schedule_date);

            var endDate = new Date(schedule_date);
            var startDate = new Date(todays_date);
            // var millisecondsPerDay = 1000 * 60 * 60 * 24;
            // var millisBetween = startDay.getTime() - endDay.getTime();
            var months = (endDate.getFullYear() * 12 + endDate.getMonth()) - (startDate.getFullYear() * 12 + startDate.getMonth())
            // alert(months);

            if (schedule_date != '') {
                fetch(myConstClass.BASE_URL + 'event/getAllEventScheduleCalendarFrontEnd/' + getLanguage() + '/' + schedule_date)
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ arrSchedule: response })

                        const numRows = this.state.arrSchedule.length;
                        if (months > 0) {
                            for (var i = 0; i < months; i++) {
                                //alert('hi');
                                $('.fc-next-button').click();
                            }

                        }

                        if (months < 0) {
                            for (var i = 0; i > months; i--) {
                                // alert('hi');
                                $('.fc-prev-button').click();
                            }
                        }


                        if (numRows > 0) {
                            // this.state.guestinfo.schedule_date_selected = this.state.arrSchedule[0].date;

                        } else {
                            //$('#noItemInCart').css('display','block');
                        }
                        $('[data-date~="' + schedule_date + '"]').css('border-color', '#ffc107');

                    })
            }
        }


        $('[data-date~="' + schedule_date + '"]').css('border-color', '#ffc107');


    }

    renderType = ({ id, title }) => <div className="row">
        <div className="col">
            <div className="col pt-3">
                <h5 className="font-weight-bold">{title}</h5>
            </div>
        </div>
    </div>

    renderGuest = ({ type_id, title, price, quantity }) =>
        <div><div className="row"><div className="col-md-3 mb-3">
            <div className="col border text-center">
                <div className=" py-3">
                    <img src={require("./assets/images/product1.png")} />
                    <div className="my-2">
                        <span className="mr-2">
                            <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                        </span>
                    </div>
                    <p className="font-weight-bold mb-1">{title}</p>
                    <p className="mb-1">Donation Contribution</p>
                    <p className="font-weight-bolder">INR. {price}/-</p>
                    <div className="qty mb-3">
                        <span>Qty-</span>
                        <span className="minus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-minus"></i>
                            </span>
                        </span>
                        <input type="number" className="count" name="qty" value={quantity} />
                        <span className="plus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-plus"></i>
                            </span>
                        </span>
                    </div>
                    <button className="btn btn-brown btn-sm">ADD TO CART</button>
                </div>
            </div></div></div></div>

    render() {
        const { arrSchedule, guestinfo, cartEvent, productIncart, posts, arrCity, month, topic } = this.state
        const { t } = this.props;
        return (
            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-white mb-0">
                                        <li className="breadcrumb-item">
                                            {/* <a href="#">{t('home.home')}</a> */}
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            {/* <a href="#">Products</a> */}
                                            <a href="javascript:void(0)" >{t('home.Events Calendar')}</a>
                                        </li>

                                    </ol>
                                </nav>
                            </div>
                            <div className="row">
                                <div className="col-10">
                                    <h4 style={{ fontSize: '25px' }}>
                                        {t('home.EVENTS CALENDAR')}
                                    </h4>

                                </div>
                                <div className="col-2" style={{ marginTop: '-18px', marginBottom: '10px' }}>
                                    <Link class="btn btn-brown btn-sm mt-3" to={"/event-calendar"}   >{t('home.TABLE VIEW')}</Link>
                                </div>
                            </div>
                            <div className="row bg-light m-0 py-2">
                                <div className="d-flex align-items-center col-lg-2 col-md-12">
                                    <span>
                                        {t('home.Select_filters_to_refine_the_results_below')}:

                       </span>
                                </div>
                                <div className="col-lg-4 col-md-8">
                                    <div className="form-group">
                                        <label for="">{t('home.Topic')}</label>
                                        <select id="topic_id" name="topic_id" className=" form-control form-control-sm">
                                            <option value="">{t('home.All Topic')}</option>
                                            {topic.map((info, ii) => {
                                                if (this.state.selected_topic_id == info.value) {
                                                    return (
                                                        <option value={info.value} selected="selected">{info.label}</option>
                                                    )
                                                } else {
                                                    return (
                                                        <option value={info.value}>{info.label}</option>
                                                    )
                                                }

                                            })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className=" col-md-2 col-lg-2">
                                    <div className="form-group">
                                        <label for="">{t('home.Month')}</label>
                                        <select id="month" name="month" className="form-control form-control-sm">
                                            <option value="">{t('home.All Month')}</option>
                                            {month.map((info, ii) => {
                                                return (
                                                    <option value={info.value}>{info.label}</option>
                                                )
                                            })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col col-lg-2">
                                    <div className="form-group">
                                        <label for="">{t('home.Location')}</label>
                                        <select id="city_id" name="city_id" className="form-control form-control-sm">
                                            <option value="">{t('home.All Location')}</option>
                                            {arrCity.map((info, ii) => {
                                                if (this.state.selected_city_id == info.city_id) {
                                                    return (
                                                        <option value={info.city_id} selected="selected">{info.city_name}</option>
                                                    )
                                                } else {
                                                    return (
                                                        <option value={info.city_id}>{info.city_name}</option>
                                                    )
                                                }

                                            })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col col-lg-2 col-md-4" onClick={this.applyFilters}>
                                    <div className="form-group">
                                        <label for="" className="invisible">{t('home.Apply Filter')}</label>
                                        <button style={{ width: '65%' }} id="applyFilterBtn" className="btn btn-sm btn btn-outline-brown btn-block ">{t('home.APPLY FILTERS')}</button>
                                        <img id="loader" style={{ display: 'none', marginTop: '0px' }} src={myConstClass.IMAGE_URL + 'loader.gif'} />
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="col-lg-10 col-md-12 pt-5" style={{ padding: '21px' }}>


                            <div class="row py-2" style={{ marginTop: '-39px' }}>


                                <div id="example-component">
                                    <FullCalendar

                                        id="your-custom-ID"
                                        header={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'month,agendaWeek,agendaDay'
                                        }}

                                        // can click day/week names to navigate views
                                        editable={true}
                                        //navLinks= {true}

                                        style={{ cursor: 'pointer' }}

                                        // start= {'2020-02-02'}
                                        eventLimit={true} // allow "more" link when too many events
                                        events={this.state.events}
                                        showNonCurrentDates={false}
                                        dayClick={function (date, jsEvent, view) {
                                            //alert('Clicked on: ' + date.format());
                                            // $('#border_date').val( date.format());


                                            //$('[data-date~="2020-05-10"]').css('color','red');


                                            // $('#btn_border_date').click();
                                            // $('.fc-future').css('border', 'none');
                                            //date.preventDefault();

                                            $('.fc-future').css('border-color', '#ddd');
                                            $('.fc-past').css('border-color', '#ddd');
                                            $(this).removeClass('border_style');
                                            $(this).addClass('border_style');
                                            //$(this).css('border-style', 'single');
                                            $(this).css('border-color', '#ffc107');
                                            $('[data-date~="' + date.format() + '"]').css('border-color', '#ffc107');
                                            //selectable: false,
                                        }}

                                        eventClick={function (calEvent, jsEvent, view, resourceObj) {
                                            // alert(calEvent.title);
                                            //  console.log(calEvent.start._i);

                                            $('#schedule_date').val(calEvent.start._i);
                                            //  jsEvent.preventDefault();
                                            $('.fc-future').css('border-color', '#ddd');
                                            $('.fc-past').css('border-color', '#ddd');
                                            // $(this).removeClass('border_style');
                                            // $(this).addClass('border_style');
                                            //$(this).css('border-style', 'single');
                                            $(this).css('border-color', '#ffc107');
                                            // $('[data-date~="'+ calEvent.start._i+'"]').css('border-color','#ffc107');

                                            $('#btn_getAllSchedule').click();

                                            //   this.getAllSchedule
                                            // console.log(calEvent.start._i);

                                        }}
                                        eventRender={function (calEvent, element) {
                                            element.find(".fc-title").append("<br/><b style='font-weight:400'>" + calEvent.description + "</b>");

                                        }}
                                    // eventClick={this.getAllSchedule}
                                    // selectable={true}

                                    />
                                </div>
                                <input type="hidden" name="schedule_date" id="schedule_date" value="" />
                                <input style={{ display: 'none' }} type="button" name="btn_getAllSchedule" id="btn_getAllSchedule" onClick={this.getAllSchedule} />


                                <input type="hidden" name="border_date" id="border_date" value="" />
                                <input style={{ display: 'none' }} type="button" name="btn_border_date" id="btn_border_date" onClick={this.getBorderDate} />

                            </div>


                        </div>
                        <div class="col-lg-2 col-md-12  pt-3" id="eventCalendarGridEventSchedule" >
                            <div class="row">


                                <div class="modal-content border border-warning bg-white box-shadow" id="today_event" style={{ overflowY: 'scroll', maxHeight: '819px' }}>
                                    <div class="modal-header" style={{ borderBottom: '0px', padding: '0.8rem 0.8rem' }}>
                                        <h4 class="modal-title" id="exampleModalScrollableTitle">

                                            {arrSchedule.map((scheduleinfo, ii) => {
                                                if (ii == 0) {
                                                    return (
                                                        <b>
                                                            {scheduleinfo.date}
                                                        </b>
                                                    )
                                                }

                                            })
                                            }

                                        </h4>
                                    </div>
                                    <div class="modal-body CalendarModelBody">
                                        {arrSchedule.map((scheduleinfo, ii) => {
                                            if (ii == 0) {
                                                if (scheduleinfo.is_full == 0) {
                                                    return (
                                                        <div>
                                                            <h4>
                                                                <span class="eventTitle">
                                                                    {scheduleinfo.title}
                                                                </span>
                                                            </h4>
                                                            <span class="eventCityName"> {scheduleinfo.city_name} | {scheduleinfo.duration}-{t('home.day')}
                                                            </span>
                                                            <br />
                                                            <b>{t('home.Status Calendar')}: {t('home.Open Grid')}</b>
                                                            <br />
                                                            {/* <button class="btn btn-brown  mb-2">VIEW DETAILS </button> */}
                                                            <Link style={{ marginTop: '5px' }} class="btn btn-brown  mb-2" to={"/event_details/" + scheduleinfo.event_id}   >{t('home.VIEW DETAILS')}</Link>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div>
                                                            <h4>
                                                                <span class="eventTitle">
                                                                    {scheduleinfo.title}
                                                                </span>
                                                            </h4>
                                                            <span class="eventCityName"> {scheduleinfo.city_name} | {scheduleinfo.duration}-{t('home.day')}
                                                            </span>
                                                            <br />
                                                            <b>{t('home.Status Calendar')}: {t('home.FULL GRID')}</b>
                                                            <br />
                                                            {/* <button class="btn btn-brown  mb-2">VIEW DETAILS </button> */}
                                                            <Link style={{ marginTop: '5px' }} class="btn btn-brown  mb-2" to={"/event_details/" + scheduleinfo.event_id}   >{t('home.VIEW DETAILS')}</Link>
                                                        </div>
                                                    )
                                                }

                                            } else {
                                                if (scheduleinfo.is_full == 0) {
                                                    return (
                                                        <div style={{ marginTop: '15px' }}>
                                                            <h4>
                                                                <span class="eventTitle">
                                                                    {scheduleinfo.title}
                                                                </span>
                                                            </h4>
                                                            <span class="eventCityName"> {scheduleinfo.city_name} | {scheduleinfo.duration}-{t('home.day')}
                                                            </span><br />
                                                            <b>{t('home.Status Calendar')}: {t('home.Open Grid')}</b>
                                                            <br />
                                                            {/* <button class="btn btn-brown  mb-2">VIEW DETAILS </button> */}
                                                            <Link style={{ marginTop: '5px' }} class="btn btn-brown  mb-2" to={"/event_details/" + scheduleinfo.event_id}   >{t('home.VIEW DETAILS')}</Link>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div style={{ marginTop: '15px' }}>
                                                            <h4>
                                                                <span class="eventTitle">
                                                                    {scheduleinfo.title}
                                                                </span>
                                                            </h4>
                                                            <span class="eventCityName"> {scheduleinfo.city_name} | {scheduleinfo.duration}-{t('home.day')}
                                                            </span> <br />
                                                            <b>{t('home.Status Calendar')}: {t('home.FULL GRID')}</b>
                                                            <br />
                                                            {/* <button class="btn btn-brown  mb-2">VIEW DETAILS </button> */}
                                                            <Link style={{ marginTop: '5px' }} class="btn btn-brown  mb-2" to={"/event_details/" + scheduleinfo.event_id}   >{t('home.VIEW DETAILS')}</Link>
                                                        </div>
                                                    )
                                                }

                                            }

                                        })
                                        }
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
                {/* <div className="container-fluid bg-grey mt-4">
        <div className="row grey-text">
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Residential Study Courses</a>
                <a href="#" className="grey-text d-block py-1">1-day Courses</a>
                <a href="#" className="grey-text d-block py-1">Machine Tests</a>
                <a href="#" className="grey-text d-block py-1">Rational Poojas</a>
                <a href="#" className="grey-text d-block py-1">Free Events</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Books</a>
                <a href="#" className="grey-text d-block py-1">CDs</a>
                <a href="#" className="grey-text d-block py-1">Kits (Set of Books)</a>
                <a href="#" className="grey-text d-block py-1">Experimental Products</a>
                <a href="#" className="grey-text d-block py-1">Ayurvedic Products</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">Magazines</a>
                <a href="#" className="grey-text d-block py-1">Gallery</a>
                <a href="#" className="grey-text d-block py-1">Downloads</a>
                <a href="#" className="grey-text d-block py-1">FAQs</a>
                <a href="#" className="grey-text d-block py-1">E-Shopping Help</a>
            </div>
            <div className="col-md-3 py-3">
                <a href="#" className="grey-text d-block py-1">About Us</a>
                <a href="#" className="grey-text d-block py-1">Research</a>
                <a href="#" className="grey-text d-block py-1">Event Calendar</a>
                <a href="#" className="grey-text d-block py-1">Feedback</a>
                <a href="#" className="grey-text d-block py-1">Contact Us</a>
            </div>
        </div>
    </div> */}
                {/* <div className="container-fluid bg-black">
        <p className="text-center py-3 grey-text mb-0">
            © 2000-2017 Manashakti Rest New Way, | All Rights Reserved Worldwide
        </p>
    </div> */}
            </div>

        );
    }
}


Event_calendar_grid.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Event_calendar_grid);

// ReactDOM.render(<Event_calendar_grid />, document.getElementById('root'));
