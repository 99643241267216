import React , { Component }from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import icon from './assets/images/calendar.png';
import call from './assets/images/call.png';
import mail from './assets/images/mail.png';
import address from './assets/images/address.png';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { Media } from 'reactstrap';
import { Editor} from 'react-draft-wysiwyg';
import { EditorState,convertToRaw,convertFromRaw  } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateToHTML} from 'draft-js-export-html'; 
import Header from './Header.js';
import Footer from './Footer.js';

import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,getLanguage,
    translate,
  } from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,  
    Label,
    Row,
    
  } from 'reactstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import ModernDatepicker from 'react-modern-datepicker';
import moment from 'moment';


// ...or load this specific CSS file using a <link> tag in your document

const intAllowedExtensions = ['jpeg', 'jpg', 'gif', 'png', 'doc', 'docx', 'bmp', 'tif', 'pdf', 'xls', 'xlsx', 'odt'];
const intUploadFolderFlag = 0;
const initMultiple = false;
const intItemLimit = 1;


class Centersinmaharashtra extends Component {
    
    constructor(props) {
       
      super(props);
      const { allowedExtensions, multiple, itemLimit } = this.props;
      this.product_id=0;
      this.state = {
        pictures: [],
        arrMagazine:[],
        arr_userInfo:[],
        arrState:[],
        arrCityAll:[],
        profile_picture:'',
        userinfo: {
            profile_picture:'profile.jpg',
        },
        startDate:'',
        flag:0,
        guestinfo:[],
        guestinfo: {
           
           
          },
          eventinfo: {
            
           
          },
          removeinfo:{
            message: '',
            status: '',
          },
          country:[],
          city:[],
         
      }
      
    
      this.validator = new SimpleReactValidator();
      this.applyFilter= this.applyFilter.bind(this);
    //   this.goToEdit = this.goToEdit.bind(this);
      var user_id = localStorage.getItem('user_id');

    

      
  }




  componentDidMount() {
  
    var user_id = localStorage.getItem('user_id');
    // this.getCountry();
    
    fetch(myConstClass.BASE_URL+'magazine/getMagazineTitlesFrontEnd'+'/'+getLanguage())
    .then(response => response.json())
    .then(response =>this.setState({arrMagazine: response}))
    .catch(err => console.log(err))


    fetch(myConstClass.BASE_URL+'cms_contact/getStateCenterInMaharashtra'+'/'+getLanguage()+'/22/2763')
    .then(response => response.json())
    .then(response =>this.setState({arrState: response}))
    .catch(err => console.log(err))

    .catch(err => console.log(err))

    fetch(myConstClass.BASE_URL+'cms_contact/getCityAll'+'/'+getLanguage()+'/22/2')
    .then(response => response.json())
    .then(response =>this.setState({arrCityAll: response}))
    .catch(err => console.log(err))

    .catch(err => console.log(err))
          
  }

  getCityStateWise = _ => {
    var shipping_stateid = $('#state').val();
   // alert(shipping_countryid);
    fetch(myConstClass.BASE_URL+'getCityStateWise/'+shipping_stateid)
    .then(response => response.json())
    .then(response => this.setState({ city: response}))
    .catch(err => console.log(err))
   
  }

  
  getStateCountryWise = _ => {
    var shipping_countryid = $('#country').val();
    //alert(shipping_countryid);
    fetch(myConstClass.BASE_URL+'getStateCountryWise/'+shipping_countryid)
    .then(response => response.json())
    .then(response => this.setState({ shipping_countrystate: response}))
    .catch(err => console.log(err))
   
  }
  getStateCountryWiseEdit(shipping_countryid){
    //var shipping_countryid = $('#country').val();
    //alert(shipping_countryid);
    fetch(myConstClass.BASE_URL+'getStateCountryWise/'+shipping_countryid)
    .then(response => response.json())
    .then(response => this.setState({ shipping_countrystate: response}))
    .catch(err => console.log(err))
   
  }

  getCityStateWiseEdit(shipping_stateid){
    fetch(myConstClass.BASE_URL+'getCityStateWise/'+shipping_stateid)
    .then(response => response.json())
    .then(response => this.setState({ city: response}))
    .catch(err => console.log(err))
  }

  getCountry = _ => {
    fetch(myConstClass.BASE_URL+'getAllCountry')
    .then(response => response.json())
    .then(response => this.setState({ country: response}))
    .catch(err => console.log(err))
   // console.log("this.setState.data",this.state.country);
   
  }

  applyFilter(){
    var city_id = $('#city_id').val();
    if(city_id == '' || city_id == undefined){
        city_id = 0;
    }
    fetch(myConstClass.BASE_URL+'cms_contact/getStateCenterInMaharashtra'+'/'+getLanguage()+'/22/'+city_id)
    .then(response => response.json())
    .then(response =>this.setState({arrState: response}))
    .catch(err => console.log(err))

    .catch(err => console.log(err))
  }

  componentDidUpdate(prevProps) {

    const { flag} = this.state  ;
    var current_language =  localStorage.getItem('current_language');
        
        if(current_language != getLanguage() && flag != 1 && current_language != null){
         //alert(current_language);
             localStorage.removeItem('current_language');
             localStorage.setItem('current_language',getLanguage());
             this.setState({flag:1});
        }
        if(getLanguage()=='ma' && flag==1){
            this.setState({flag:0})
            var user_id = localStorage.getItem('user_id');
 fetch(myConstClass.BASE_URL+'magazine/getMagazineTitlesFrontEnd'+'/'+getLanguage())
    .then(response => response.json())
    .then(response =>this.setState({arrMagazine: response}))
    .catch(err => console.log(err))

    .catch(err => console.log(err))
           
        }
        if(getLanguage()=='en' && (flag==1)){ 
            this.setState({flag:0})
            var user_id = localStorage.getItem('user_id');

 fetch(myConstClass.BASE_URL+'magazine/getMagazineTitlesFrontEnd'+'/'+getLanguage())
    .then(response => response.json())
    .then(response =>this.setState({arrMagazine: response}))
    .catch(err => console.log(err))

    .catch(err => console.log(err))
           
        }
}




  render() {
      
    const {arrCityAll,arrState,arrMagazine,userinfo,country,shipping_countrystate,city,eventinfo, posts,guestinfo,toast} = this.state  
    const { t } = this.props;
  return (
//     <script src="https://cdnjs.cloudflare.com/ajax/libs/react/15.1.0/react.min.js"></script>
// <script src="https://cdnjs.cloudflare.com/ajax/libs/react/15.1.0/react-dom.min.js"></script>

    <div className="App">
         <div>
        {/* <button onClick={this.notify}>Notify !</button> */}
          <ToastContainer />
        </div>
    <Header />
    <div class="container-fluid">

        <div class="container-fluid ">
            <div class="row">
                <div class="col-lg-12 " id="about_nav_bar">
                    <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                        <nav aria-label="breadcrumb" id="breadcrumb">
                            <ol class="breadcrumb bg-white pb-0 mb-0 ml-n2 pl-2">
                                <li class="breadcrumb-item ">
                                <Link   to="/">{t('home.home')}</Link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page"><a href="javascript:void(0)">
                                    
                                    {t('home.Contact Us')}
                                    </a></li>
                            </ol>
                            <p>{t('home.Contact Us')}</p>
                            <div>
                            <Link   to="/contact-us">
                                <button type="button" class="btn btn-link  orangeColor pl-0">{t('home.MAIN CENTER')}</button>
                                </Link>
                                <Link   to="/branches">
                                <button type="button" class="btn btn-link orangeColor ">{t('home.BRANCHES')}</button>
                                </Link>
                                <Link   to="/centers-in-maharashtra">
                                <button type="button" class="btn btn-link active_2">{t('home.CENTRES IN MAHARASHTRA')}</button>
                                </Link>
                                <Link   to="/centers-outside-of-maharashtra">
                                <button type="button" class="btn btn-link orangeColor">{t('home.CENTRES OUTSIDE MAHARASHTRA')}</button>
                                </Link>
                                <Link   to="/centers-outside-india">
                                <button type="button" class="btn btn-link orangeColor">{t('home.CENTRES OUTSIDE INDIA')}</button>
                                </Link>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
           
            <div class="row">

                <div class="col-lg-9 ">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                            <form>
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">{t('home.Select City')}</label>
                                    <select class="form-control" id="city_id" name="city_id" onChange={this.applyFilter}>
                                    <option value="">{t('home.Select City')}</option>
                                        {arrCityAll.map((info, ii) => {
                                            if(info.id == 2763){
                                                return(
                                                    <option value={info.id} selected="selected">{info.name}</option>
                                                ) 
                                            }else{
                                                return(
                                                    <option value={info.id}>{info.name}</option>
                                                ) 
                                            }
                                           
                                            })
                                        }
                                    </select>
                                </div>
                            </form>
                        </div>
                    </div>
                    {arrState.map((state, i) => {
                         return(   
                                              
                    <span>
                        
                        
                    {/* <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-12 pl-0">
                            <div class="col-12 border border-dark mt-1 mb-3 mr-1 OUTSIDE_center ">
                            {state.arr_city.map((city, ii) => {
                                var str = city.name;
                                var city_name = str.charAt(0);
                                    return( 
                                        <div>  
                                                <div class="initial_character ">
                                                    <h5>{city_name}</h5>
                                                </div>
                                                {city.arr_centers.map((centers, iii) => {
                                                    if(iii == 0){
                                                        return(
                                                            <div class="ml-5">
                                                                <h4 class="pt-3 pb-1">{city.name}</h4>
                                                                <p>
                                                                    <span> {centers.contact_person} </span> <span class="ml-3">{centers.mobile} </span>
            
                                                                </p>
                                                            </div>
                                                            ) 
                                                    }else{
                                                        return(
                                                            <div class="ml-5">
                                                                <p>
                                                                    <span> {centers.contact_person} </span> <span class="ml-3">{centers.mobile} </span>
            
                                                                </p>
                                                            </div>
                                                            ) 
                                                    }
                                                   
                                                    })
                                                }
                                        </div>    
                                        )
                               
                                 
                            })
                        }
                            </div>
                            </div>  
                                    
                        </div> */}


{state.arr_city.map((city, ii) => {
                         return(     
                    <div class="row">
                    <table class="views-table cols-3" style={{minWidth:'100%'}}>
                        <thead>
                        <th>
                         <h4 style={{color:'#ed9a16'}}>{city.name}</h4> 
                            </th>
                        </thead>
                        <tbody>
                        
                    {city.arr_centers.map((centers, iii) => {
                         return( 
                       
                               
                                <tr class="even">
                                    <td style={{width:'75%',borderBottom:'1px solid #dee2e6'}} class="views-field views-field-body">
                         <span> <p>{centers.contact_person}</p>
                                        </span>          
                                    </td>
                                    <td style={{width:'25%',borderBottom:'1px solid #dee2e6',textAlign:'left'}} class="views-field views-field-field-contact-number-value">
                                        <p>{centers.mobile}</p>          
                                    </td>
                                   
                                </tr>
          
                            
                                    ) 
                                })
                            }
                            </tbody>
                        </table>
                        </div>
                                ) 
                            })
                        }

                                
                       </span>
                            ) 
                        })
                    }

                        
                </div>





                <div class="col-lg-3" id="eventRightMenuMain">
                {arrMagazine.map((cell, i) => {
                if(cell.magazine_id == 2){
                return(
                <div class="col py-2 border border-warning bg-white box-shadow mb-3">
                 
                    <div class="row d-flex">
                        <div class="col-12">
                        <strong>
                            {cell.magazine_title}
                            </strong>
                        <p>
                        { ReactHtmlParser(cell.description) }     
                        
                        </p>
                        <p>
                            <strong>{t('home.Language')}:</strong> 

                            {cell.language}
                        </p>
                        <p>

                        {cell.images_array.map((info, ii) => {
                            if(ii == 0){
                               if(info.issue_id > 0 && info.issue_id != undefined){
                                    return( 
                                        <Link to={'/magazine_details/'+info.magazine_id+'/'+info.issue_id}>
                                            <img  className="mb-2 magazineImageRight" src={info.image_name} />
                                        </Link>
                                        
                                     )
                                }else{
                                    return( 
                                        <Link to={'/magazine'}>
                                            <img  className="mb-2 magazineImageRight" src={info.image_name} />
                                        </Link>
                                        
                                     )
                                }
                            }else{
                                return( 
                                    <Link to={'/magazine'}>
                                    <img  className="mb-2 magazineImageRight" src={info.image_name} />
                                    </Link>
                                 )
                            }
                            }
                             )} 

                        
                      
                        <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight"   to={'/magazine-subscription/'+cell.magazine_id}>{t('home.SUBSCRIBE')}</Link>
                        </p>
                        </div>
                    </div>
                </div>
                    )
                }else{
                    return(
                        <div class="col py-2 border border-warning bg-white box-shadow mb-3">
                         
                            <div class="row d-flex">
                                <div class="col-12">
                                <strong>
                                    {cell.magazine_title}
                                    </strong>
                                <p>
                                { ReactHtmlParser(cell.description) }     
                                
                                </p>
                                <p>
                                    <strong>{t('home.Language')}:</strong> 
        
                                    {cell.language}
                                </p>
                                <p>
        
                                {cell.images_array.map((info, ii) => {
                                    if(ii == 0){
                                        if(info.product_id > 0){
                                            return( 
                                                <Link to={'/products_details/'+info.title+'/'+info.product_id}>
                                                    <img  className="mb-2 magazineImageRight" src={info.image_name} />
                                                    
                                                    <p >
                                                    <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight"   to={'/products_details/'+info.title+'/'+info.product_id}>{t('home.BUY NOW')}</Link>
                                                    </p>
                                                </Link>
                                             )
                                        }else{
                                            return( 
                                                <Link to={'/products'}>
                                                    <img  className="mb-2 magazineImageRight" src={info.image_name} />
                                                    <p >
                                                    <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight"   to={'/products'}>{t('home.BUY NOW')}</Link>
                                                    </p>
                                                </Link>
                                             )
                                        }
                                        
                                    }else{
                                        if(info.product_id > 0){
                                            return( 
                                                <Link to={'/products_details/'+info.title+'/'+info.product_id}>
                                                    <img  className="mb-2 magazineImageRight" src={info.image_name} />
                                                </Link>
                                             )
                                        }else{
                                            return( 
                                                <Link to={'/products'}>
                                                    <img  className="mb-2 magazineImageRight" src={info.image_name} />
                                                </Link>
                                             )
                                        }
                                    }
                                    }
                                     )} 
                                </p>
                               
                                </div>
                            </div>
                        </div>
                            )
                }
                })}

                    
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </div>
  );
}
}
Centersinmaharashtra.propTypes = {
    t: PropTypes.func.isRequired,
  };
export default translate(Centersinmaharashtra);
