import { toast } from "react-toastify";
import { BASE_URL } from "../../constant";
import { getLanguage } from "react-switch-lang";

export function areObjectsEqual(obj1, obj2) {
  const keys = Object.keys(obj1);
  for (let key of keys) {
    if (!obj1[key] || !obj2[key]) continue;
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}

const FetchHeaders = {
  "Content-Type": "application/json",
};
async function Fetch({ url, method, body, headers, token, ...rest }) {
  try {
    const response = await fetch(url, { method, body, ...rest });
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    } else {
      console.log("FetchError ->  response", response);
    }
  } catch (error) {
    console.log("FetchError -> error", error);
  }
}

export async function fetchAllCartData() {
  try {
    const user_id = localStorage.getItem("user_id");

    const productsUrl = `${BASE_URL}cart/getAllMyCartData/${user_id}/${getLanguage()}`;
    const magazinesUrl = `${BASE_URL}cart/getAllMyCartDataForMagazine/${user_id}/${getLanguage()}`;

    const productsRequest = Fetch({ url: productsUrl, method: "GET" });
    const magazinesRequest = Fetch({ url: magazinesUrl, method: "GET" });

    const [products, magazines] = await Promise.all([
      productsRequest,
      magazinesRequest,
    ]);

    const shipping = products?.length || magazines?.length ? true : false;

    return { cart: { products, magazines }, billing: true, shipping };
  } catch (error) {
    console.log("fetchAllCartData -> error", error);
  }
}

export async function fetchCountries() {
  try {
    const url = `${BASE_URL}getAllCountry`;
    const data = await Fetch({ url, method: "GET" });
    return data;
  } catch (error) {
    console.log("fetchCountries -> error", error);
  }
}

export async function fetchStatesByCountry(countryId) {
  try {
    const url = `${BASE_URL}getStateCountryWise/${countryId}`;
    const data = await Fetch({ url, method: "GET" });
    return data;
  } catch (error) {
    console.log("fetchStatesByCountry -> error", error);
  }
}

export async function fetchCitiesByState(stateId) {
  try {
    const url = `${BASE_URL}getCityStateWise/${stateId}`;
    const data = await Fetch({ url, method: "GET" });
    return data;
  } catch (error) {
    console.log("fetchCitiesByState -> error", error);
  }
}

/**
 * Fetches Billing address for the user.
 * @param {callback} callback - The callback function to handle errors.
 */
export async function fetchBillingAddressById(callback) {
  try {
    const user_id = localStorage.getItem("user_id");
    const url = `${BASE_URL}users/getBillingAddress/1/${user_id}`;
    const data = await Fetch({ url, method: "GET" });
    if (callback) callback(null, data?.[0]);
    return data?.[0];
  } catch (error) {
    console.log("fetchBillingAddressById -> error", error);
    callback(error, null);
  }
}

/**
 * Fetches Shipping address for the user.
 * @param {callback} callback - The callback function to handle errors.
 */
export async function fetchShippingAddressById(callback) {
  try {
    const user_id = localStorage.getItem("user_id");
    const url = `${BASE_URL}users/getBillingAddress/2/${user_id}`;
    const data = await Fetch({ url, method: "GET" });
    if (callback) callback(null, data?.[0]);
    return data?.[0];
  } catch (error) {
    console.log("fetchShippingAddressById -> error", error);
    callback(error, null);
  }
}

/**
 * Saves Billing address for the user.
 * @param {object} payload - The address payload.
 * @param {callback} callback - The callback function to handle errors.
 */
export async function saveBillingAddressById(
  payload,
  callback,
  onSaveCallback,
  onErrorCallback
) {
  try {
    const user_id = localStorage.getItem("user_id");
    const url = `${BASE_URL}users/addAddress/1/${user_id}`;
    const data = await Fetch({
      url,
      method: "POST",
      body: JSON.stringify(payload),
      headers: { ...FetchHeaders },
    });
    // console.log("saveShippingAddressById -> data", data);
    const { message } = data?.[0];
    if (message) {
      toast(message, { type: toast.TYPE.SUCCESS });
      // onSaveCallback();
      if (callback) callback(null, data?.[0]);
      return data?.[0];
    }
  } catch (error) {
    console.log("fetchShippingAddressById -> error", error);
    const { message } = error;
    if (message) {
      toast(message, { type: toast.TYPE.ERROR });
      // onErrorCallback();
      if (callback) callback(error, null);
    }

    console.log("HandleError");
    throw error;
  }
}

/**
 * Saves Shipping address for the user.
 * @param {object} payload - The address payload.
 * @param {callback} callback - The callback function to handle errors.
 */
export async function saveShippingAddressById(
  payload,
  callback
  // onSaveCallback,
  // onErrorCallback
) {
  try {
    const user_id = localStorage.getItem("user_id");
    const url = `${BASE_URL}users/addAddress/2/${user_id}`;
    const data = await Fetch({
      url,
      method: "POST",
      body: JSON.stringify(payload),
      headers: { ...FetchHeaders },
    });
    // console.log("saveShippingAddressById -> data", data);
    const { message } = data?.[0];
    if (message) {
      toast(message, { type: toast.TYPE.SUCCESS });
      // onSaveCallback();
      if (callback) callback(null, data?.[0]);
      return data?.[0];
    }
  } catch (error) {
    console.log("fetchShippingAddressById -> error", error);
    const { message } = error;
    if (message) {
      toast(message, { type: toast.TYPE.ERROR });
      // onErrorCallback();
      if (callback) callback(error, null);
    }

    console.log("HandleError");
    throw error;
  }
}

//
//
/**
 *
 */
function callback(error, data) {}
