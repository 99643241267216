import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/images/logo.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import Header from './Header.js';
import Footer from './Footer.js';
import { Editor } from 'react-draft-wysiwyg';
import $ from 'jquery';
import Swal from 'sweetalert2';
// import 'sweetalert2/src/sweetalert2.scss';
import 'sweetalert2/dist/sweetalert2.min.css';
import dateFormat from 'dateformat';

// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
// import 'react-day-picker/lib/style.css';

import SimpleReactValidator from 'simple-react-validator';
import icon from './assets/images/calendar.png';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    ListGroup,
    ListGroupItem,
    UncontrolledCollapse
} from 'reactstrap';

// import ModernDatepicker from 'react-modern-datepicker';
import moment from 'moment';

import checkoutImage from './assets/images/mycart.png';
import { DatePicker } from "@material-ui/pickers";

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

// import Grid from '@material-ui/core/Grid';
// import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    //     KeyboardTimePicker,
    //     KeyboardDatePicker,
} from '@material-ui/pickers';

class Participants extends Component {
    constructor(props) {
        super(props);
        this.product_id = 0;
        this.state = {
            startDate: '',
            cart: [],
            flag: 0,
            value: 1,
            arr_existingparticipates: [],
            guestinfo: {
                first_name: '',
                middle_name: '',
                last_name: '',
                address: '',
                birth_date_old: '',
                age: '',
                gender: '',
                mobile: '',
                email: '',
                participant_id: '',
                birth_date: '',
                date_of_birth: ''
            },
            eventinfo: {


            },
            removeinfo: {
                message: '',
                status: '',
            },
            editorState: EditorState.createEmpty(),
        }

        this.handleChange = this.handleChange.bind(this);
        this.goToEdit = this.goToEdit.bind(this);
        this.remove = this.remove.bind(this);
        this.validator = new SimpleReactValidator();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addNewParticipant = this.addNewParticipant.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.mobileInvalid = this.mobileInvalid.bind(this);
        //   this.handleChangeNew = this.handleChangeNew.bind(this);

    }
    mobileInvalid(event) {
        // alert('hi');
        event.target.setCustomValidity('Username should only contain lowercase letters. e.g. john');
    }

    handleChange() {
        // alert(this.state.guestinfo.birth_date);
        var date = this.state.guestinfo.birth_date;
        var dateSplit = date.split('-')
        
        if (new Date(date)) {
            var today = new Date();
            var birthDate = new Date(dateSplit[2],parseInt(dateSplit[1])+1,dateSplit[0]);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();


            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            age = age < 0 ? 0 : age;

            this.setState({
                guestinfo: {
                    ...this.state.guestinfo,
                    age: age,
                    birth_date:`${dateSplit[0]}-${parseInt(dateSplit[1])+1}-${dateSplit[2]}`,
                    date_of_birth:`${dateSplit[2]}-${parseInt(dateSplit[1])+1}-${dateSplit[0]}`
                }
            })
            // alert(age)
            $('#age').val(age);
        }
    }

    addNewParticipant() {
        localStorage.removeItem('participate_id');
        var user_id = localStorage.getItem('user_id');
        var participate_id = 0;
        if (user_id > 0) {

            $('#first_name').val('');
            $('#middle_name').val('');
            $('#last_name').val('');
            $('#age').val('');
            $('#mobile').val('');
            $('#email_participant').val('');
            $('#address').val('');
            $('#birth_date_old').val('');
            $('#birth_date').val('');
            $('.DayPickerInput input').val('');
            this.setState({
                guestinfo: {
                    first_name: '',
                    middle_name: '',
                    last_name: '',
                    address: '',
                    birth_date_old: '',
                    age: '',
                    gender: '',
                    mobile: '',
                    email: '',
                    participant_id: '',
                    birth_date: '',
                    date_of_birth: ''
                }
                
            })
            $('#addparticipantsLabel').css('display', 'inline');
            $('#newParticipantsLabel').css('display', 'inline');
            $('#editparticipantsLabel').css('display', 'none');
            $('#EXISTING-PARTICIPANTS').modal('show');
        }

    }

    goToEdit(e) {
        //alert(e);
        var user_id = localStorage.getItem('user_id');

        var participate_id_all = e.target.id;
        console.log(participate_id_all);
        var arr_participate_id = participate_id_all.split('_');
        this.state.guestinfo.participate_id = arr_participate_id[1];
        var participate_id = arr_participate_id[1];
        localStorage.removeItem('participate_id');
        localStorage.setItem('participate_id', participate_id);
        //alert(participate_id);
        if (user_id > 0) {
            fetch(myConstClass.BASE_URL + 'event/getParticipantDetailsFrontEnd/' + participate_id + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    
                    this.setState({ guestinfo: response })
                    $('#addparticipantsLabel').css('display', 'none');
                    $('#newParticipantsLabel').css('display', 'none');
                    $('#editparticipantsLabel').css('display', 'inline');
                    $('#EXISTING-PARTICIPANTS').modal('show');

                })
        }
    }

    handleDayChange(day) { //alert(day);
        console.log("Day : ")
        var utcDate = day.toLocaleDateString();
        $('#birth_date').val(utcDate);
        this.state.guestinfo.birth_date = utcDate;

        this.setState({ selectedDay: day });
    }

    handleSubmit(e) {
        e.preventDefault();
        $('.btnSave').css('display', 'none');
        $('#loader').css('display', 'inline');
        var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
        var email = $("#email_participant").val();
        // alert(email);
        if (!filter.test(email)) {
            //    alert('hi');
            e.preventDefault();
            toast('Invalid email address', { containerId: 'B', type: toast.TYPE.ERROR })

        } else {

            var user_id = localStorage.getItem('user_id');
            console.log('this.refs', $('#pform').serializeArray());
            var participant_id = localStorage.getItem('participate_id');
            if (this.validator.allValid()) {
                const formData = {};
                for (const field in this.refs) {
                    formData[field] = this.refs[field].value;
                }
                console.log('-->', formData);
                fetch(myConstClass.BASE_URL + `event/addUpdateParticipantFrontEnd/` + user_id + '/' + participant_id, {
                    method: 'POST',
                    body: JSON.stringify($('#pform').serializeArray()),
                })
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ eventinfo: response })
                        var message = this.state.eventinfo.message;
                        var status = this.state.eventinfo.status;
                        $('#EXISTING-PARTICIPANTS').modal('show');
                        //console.log('hii'+this.state.guestinfo);
                        if (status == 'true') {
                            $('.btnSave').css('display', 'inline');
                            $('#loader').css('display', 'none');
                            $('#pform')[0].reset();
                            this.state.guestinfo.first_name = '';
                            $('#first_name').val();
                            this.state.guestinfo.last_name = '';
                            $('#last_name').val();
                            //this.state.startDate= this.state.eventinfo.birth_date;
                            var user_id = localStorage.getItem('user_id');
                            if (user_id > 0) {

                                fetch(myConstClass.BASE_URL + 'event/getAllExistingParticipatesMaster' + '/' + user_id + '/' + getLanguage())
                                    .then(response => response.json())
                                    .then(response => {
                                        this.setState({ arr_existingparticipates: response })
                                        //var totalRecords =  this.state.cart[0].title;
                                        const numRows = this.state.arr_existingparticipates.length;
                                        if (numRows < 1) {
                                            $('#noRecordsFoundParticipatesTR').css('display', 'block');
                                            $('#existingParticipateHeadTR').css('display', 'none');
                                        } else {
                                            $('#noRecordsFoundParticipatesTR').css('display', 'none');
                                            $('#existingParticipateHeadTR').css('display', 'contents');
                                        }
                                    })


                            }

                            //$('#address').modal('hide');
                            $('#EXISTING-PARTICIPANTS').modal('hide');
                            $('.btnSave').css('display', 'inline');
                            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        } else {
                            toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                        }
                        //console.log(status);
                    })
                    .catch(err => console.log(err))
            }
            else {
                //alert('here');
                this.validator.showMessages();
                console.log('validations' + this.validator.showMessages());
                e.preventDefault();
                toast('Please fill all required fields.', { containerId: 'B', type: toast.TYPE.ERROR })
                // toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR})

            }


        }
    }


    remove(e) {
        //alert(e);
        console.log(e);

        var user_id = localStorage.getItem('user_id');
        var participate_id_all = e.target.id;
        var arr_participate_id = participate_id_all.split('_');
        this.state.guestinfo.participate_id = arr_participate_id[1];
        var participate_id = arr_participate_id[1];

        if (getLanguage() == 'ma') {
            var are_you_sure = "तुम्हाला खात्री आहे?";
            var revert = "आपण कार्टमधून वस्तू/उपक्रम काढून टाकू इच्छिता ?";
            var deleteConfirm = "होय, काढून टाका";
            var cancel = "नाही";
        } else {
            var are_you_sure = "Are you sure?";
            var revert = "You won't be able to revert this!";
            var deleteConfirm = "Yes, delete it!";
            var cancel = "Cancel";
        }

        Swal.fire({
            title: are_you_sure,
            text: revert,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: cancel,
            confirmButtonText: deleteConfirm
        }).then((result) => {
            if (result.value) {
                // Swal.fire(
                //   'Deleted!',
                //   'Your file has been deleted.',
                //   'success'
                // )
                this.flag = 1;
                fetch(myConstClass.BASE_URL + `event/deleteParticipant/` + participate_id, {
                    method: 'POST',
                    body: e,
                }).then(toast('Participant Deleted Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS }),

                ).then(() => {
                    $('#TR_' + participate_id).css('display', 'none');
                    $('#Card_' + participate_id).css('display', 'none');
                }

                )
                    .catch();
            }
        })
    }





    componentDidMount() {

        //this.state.guestinfo.product_total_price = 0;
        const answer_array = this.props.location.pathname.split('/');

        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });

            localStorage.removeItem('success');
        }

        var error = localStorage.getItem('error');
        if (error != '' && error != null) {
            toast('' + error, { containerId: 'B', type: toast.TYPE.ERROR });
            localStorage.removeItem('error');
        }

        var user_id = localStorage.getItem('user_id');
        if (user_id > 0) {

            fetch(myConstClass.BASE_URL + 'event/getAllExistingParticipatesMaster' + '/' + user_id + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_existingparticipates: response })
                    //var totalRecords =  this.state.cart[0].title;
                    const numRows = this.state.arr_existingparticipates.length;
                    if (numRows < 1) {
                        $('#noRecordsFoundParticipatesTR').css('display', 'block');
                        $('#existingParticipateHeadTR').css('display', 'none');
                    } else {
                        $('#noRecordsFoundParticipatesTR').css('display', 'none');
                        $('#existingParticipateHeadTR').css('display', 'contents');
                    }
                })


        }



    }

    componentDidUpdate(prevProps) {
        const answer_array = this.props.location.pathname.split('/');
    }

    //   handleChange(event) {
    //     this.setState({value: event.target.value});
    //   }


    handleDateChange = (date) => {
        const { guestinfo } = this.state;
        guestinfo['date_of_birth'] = dateFormat(date, "dd-mm-yyyy");;
        if(date) {
        var date_detail = dateFormat(date, "dd-mm-yyyy");
        var date_detail_split = date_detail.split('-')
            var today_date = new Date();
            var birth_date = new Date(date_detail_split[2],parseInt(date_detail_split[1])+1,date_detail_split[0]);
            var age = today_date.getFullYear() - birth_date.getFullYear();
            var m = today_date.getMonth() - birth_date.getMonth();
            if (m < 0 || (m === 0 && today_date.getDate() < birth_date.getDate())) {
                age--;
            }
            guestinfo['age'] = age;
        }
        this.setState({ guestinfo });
    };


    render() {

        const { posts, guestinfo, cart, eventinfo, arr_existingparticipates, openOrders, pastOrders, cancelOrders } = this.state
        const { t } = this.props;
        let dob;
        const dateParts = guestinfo.date_of_birth;
        if(guestinfo.date_of_birth!==null  && guestinfo.date_of_birth !== '') {
            const day =   dateParts[0]+dateParts[1];
            const month = dateParts[3]+dateParts[4];
            const year =  dateParts[6]+dateParts[7]+dateParts[8]+dateParts[9];
            dob =  year+"-"+month+"-"+day;
        } else {
            var today = new Date();
            dob = today.getFullYear() +"-"+ (parseInt(today.getMonth()) + 1) +"-"+today.getDate();
        }
       
        return (

            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-white mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">{t('home.My Account')}</li>
                                        <li className="breadcrumb-item active" aria-current="page">{t('home.MASTER LIST OF PARTICIPANTS')}</li>

                                    </ol>
                                </nav>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h5>{t('home.MASTER LIST OF PARTICIPANTS')}</h5>
                                    {/* <button className="btn btn-brown" style={{float:'right'}} onClick={this.addNewParticipant}>{t('home.Add')}</button> */}
                                </div>
                            </div>
                            <div className="d-xl-block d-lg-block d-none">
                                <div className="row">
                                    <div className="col">
                                        <div className="tab-content">
                                            <div id="all_orders" className="tab-pane active">
                                                <table className="table table-bordered">
                                                    <thead className="bg-light">
                                                        <tr id="existingParticipateHeadTR">
                                                            <th scope="col-1">{t('home.Sr No')}</th>
                                                            <th scope="col ">{t('home.First Name')}</th>
                                                            <th scope="col ">{t('home.Middle Name')}</th>
                                                            <th scope="col ">{t('home.Last Name')}</th>
                                                            <th scope="col ">{t('home.Date of Birth')}</th>
                                                            <th scope="col ">{t('home.Age')}</th>
                                                            <th scope="col ">{t('home.Gender')}</th>
                                                            <th scope="col ">{t('home.Mobile')}</th>
                                                            <th scope="col ">{t('home.Email')}</th>
                                                            <th scope="col ">{t('home.Address')}</th>
                                                            <th scope="col ">{t('home.Action')}</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {arr_existingparticipates.map((info, ii) => {
                                                            return (
                                                                <tr id={'TR_' + info.participant_id}>
                                                                    <td>{ii + 1}</td>
                                                                    <td>{info.first_name}</td>
                                                                    <td>{info.middle_name}</td>
                                                                    <td>{info.last_name}</td>
                                                                    <td>{info.date_of_birth}</td>
                                                                    <td>{info.age}</td>
                                                                    <td>{info.gender}</td>
                                                                    <td>{info.mobile}</td>
                                                                    <td>{info.email}</td>
                                                                    <td>{info.address}</td>
                                                                    <td>

                                                                        <i className="fa fa-edit" style={{ cursor: 'pointer' }} id={'edit_' + info.participant_id} name={'edit_' + info.participant_id} onClick={this.goToEdit}></i>
                                                                        <i id={'delete_' + info.participant_id} name={'delete_' + info.participant_id} className="fa fa-trash" style={{ padding: '10px', color: 'red', cursor: 'pointer' }} aria-hidden="true" onClick={this.remove}></i>
                                                                    </td>
                                                                </tr>
                                                            )

                                                        })
                                                        }
                                                        <tr id="noRecordsFoundParticipatesTR">
                                                            <td colspan="11" style={{ border: 'none' }}>
                                                                {t('home.No Previous Records Found')}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="d-xl-none d-lg-none  d-block">
                                <ListGroup>
                                    {
                                        arr_existingparticipates.map((info, ii) =>

                                            <ListGroupItem key={info.participant_id} className="p-0 my-2" id={'Card_' + info.participant_id}>
                                                <div className="bg-light py-3 px-3 clearfix" >
                                                    <h6 className="float-left">
                                                        {info.first_name}&nbsp;
                                                        {info.middle_name}&nbsp;
                                                        {info.last_name}
                                                    </h6>
                                                    <img style={{ height: '20px', width: '20px', float: 'right', cursor: 'pointer' }} className="image_main_carosal" src={checkoutImage} id={'pariticipant_' + info.participant_id} />
                                                </div>
                                                <UncontrolledCollapse toggler={'pariticipant_' + info.participant_id} className="px-3 py-3">
                                                    <FormGroup row>
                                                        <Label className="font-weight-bold" sm="8" xs="8">
                                                            {t('home.First Name')}&nbsp;:&nbsp;
                                                            {info.first_name}
                                                        </Label>
                                                        <Label sm="4" xs="4">
                                                            <i className="fa fa-edit" style={{ cursor: 'pointer' }} id={'edit_' + info.participant_id} name={'edit_' + info.participant_id} onClick={this.goToEdit}></i>
                                                            &nbsp;&nbsp;
                                                            <i id={'delete_' + info.participant_id} name={'delete_' + info.participant_id} className="fa fa-trash" style={{ padding: '10px', color: 'red', cursor: 'pointer' }} aria-hidden="true" onClick={this.remove}></i>
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label className="font-weight-bold">
                                                            {t('home.Middle Name')}&nbsp;:&nbsp;
                                                        </Label>
                                                        <Label>
                                                            {info.middle_name}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label className="font-weight-bold">
                                                            {t('home.Last Name')}&nbsp;:&nbsp;
                                                        </Label>
                                                        <Label>
                                                            {info.last_name}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label className="font-weight-bold" sm="6" xs="6">
                                                            {t('home.Date of Birth')}&nbsp;:&nbsp;
                                                        </Label>
                                                        <Label className="font-weight-bold" sm="6" xs="6">
                                                            {t('home.Age')}&nbsp;:&nbsp;
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label sm="6" xs="6">
                                                            {info.date_of_birth}
                                                        </Label>
                                                        <Label sm="6" xs="6">
                                                            {info.age}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label className="font-weight-bold">
                                                            {t('home.Gender')}&nbsp;:&nbsp;
                                                        </Label>
                                                        <Label>
                                                            {info.gender}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label className="font-weight-bold">
                                                            {t('home.Mobile')}&nbsp;:&nbsp;
                                                        </Label>
                                                        <Label>
                                                            {info.mobile}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label className="font-weight-bold">
                                                            {t('home.Email')}&nbsp;:&nbsp;
                                                        </Label>
                                                        <Label>
                                                            {info.email}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label className="font-weight-bold">
                                                            {t('home.Address')}&nbsp;:&nbsp;
                                                        </Label>
                                                        <Label>
                                                            {info.address}
                                                        </Label>
                                                    </FormGroup>
                                                </UncontrolledCollapse>
                                            </ListGroupItem>


                                        )

                                    }
                                </ListGroup>
                            </div>

                            <div className="row">
                                <div className="col-12 col-xl-2 col-lg-2 col-md-4 ">
                                    {/* <h5>{t('home.Participants')}</h5> */}
                                    <button className="btn btn-brown btn-block btn-sm" onClick={this.addNewParticipant}>{t('home.ADD NEW PARTICIPANTS')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade z-index-1300" id="EXISTING-PARTICIPANTS" tabindex="-1" role="dialog" aria-labelledby="address" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
                        <div className="modal-content bg-light">
                            <div className="modal-header w-100">
                                <h5 className="modal-title" id="addparticipantsLabel" >
                                    {/* {t('home.MASTER LIST OF EXISTING PARTICIPANTS')} */}
                                    {t('home.Add participants details')} &nbsp;<span id="scheduleTitle"></span>
                                </h5>
                                <h5 className="modal-title" id="editparticipantsLabel" >
                                    {/* {t('home.MASTER LIST OF EXISTING PARTICIPANTS')} */}
                                    {t('home.Edit participants details')} &nbsp;<span id="scheduleTitle"></span>
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="far fa-times-circle"></i>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="row">
                                    <div className="col">
                                        <Form autoComplete="off" action="" id="pform" onSubmit={this.handleSubmit} method="post" encType="multipart/form-data" className="form-horizontal">
                                            <Row className="bg-white">
                                                <Col xl="6" lg="6" md="8" sm="8" xs="8">
                                                    <h6 className="modal-title" id="newParticipantsLabel">{t('home.New Participants Details')}</h6>
                                                </Col>
                                                <Col xl="6" lg="6" md="8" sm="4" xs="4">
                                                    <p className="color-red text-right">*{t('home.Mandatory Fields')}</p>
                                                </Col>
                                                <Col xl="12" lg="12" md="12" sm="12" xs="12">
                                                    <FormGroup row>
                                                        <Label xl="2" lg="2" md="12" sm="12" xs="12">
                                                            {t('home.First Name')}
                                                            <span className="color-red">*</span>
                                                        </Label>
                                                        <Col xl="4" lg="4" md="12" sm="12" xs="12">
                                                            <input value={guestinfo.first_name} onChange={e => { this.setState({ guestinfo: { ...guestinfo, first_name: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('first_name')} type="text" id="first_name" name="first_name" className="form-control form-control-sm" placeholder={t('home.First Name')} required />
                                                            {this.validator.message('first_name', this.state.guestinfo.first_name, 'required')}
                                                        </Col>
                                                        <Label xl="2" lg="2" md="12" sm="12" xs="12">
                                                            {t('home.Middle Name')}
                                                        </Label>
                                                        <Col xl="4" lg="4" md="12" sm="12" xs="12">
                                                            <input value={guestinfo.middle_name} onChange={e => { this.setState({ guestinfo: { ...guestinfo, middle_name: e.target.value } }) }} type="text" id="middle_name" name="middle_name" className="form-control form-control-sm" placeholder={t('home.Middle Name')} />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label xl="2" lg="2" md="12" sm="12" xs="12">
                                                            {t('home.Last Name')}
                                                            <span className="color-red">*</span>
                                                        </Label>
                                                        <Col xl="4" lg="4" md="12" sm="12" xs="12">
                                                            <input value={guestinfo.last_name} onChange={e => { this.setState({ guestinfo: { ...guestinfo, last_name: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('last_name')} type="text" id="last_name" name="last_name" className="form-control form-control-sm" placeholder={t('home.Last Name')} required />
                                                            {this.validator.message('last_name', this.state.guestinfo.last_name, 'required')}
                                                        </Col>
                                                        <Label xl="2" lg="2" md="12" sm="12" xs="12">
                                                            {t('home.Date of Birth')}<span className="color-red">*</span>
                                                        </Label>
                                                        <Col xl="4" lg="4" md="12" sm="12" xs="12">
                                                            {/* <ModernDatepicker
                                                                autocomplete="off"
                                                                className="readonly"
                                                                id="birth_date"
                                                                name="birth_date"
                                                                date={guestinfo.date_of_birth}
                                                                value={guestinfo.date_of_birth}
                                                                format={'DD-MM-YYYY'}
                                                                onChange={(date) => this.setState({ guestinfo: { ...guestinfo, date_of_birth: date } }, () => this.handleChange())}
                                                                placeholder={t('home.Date of Birth')}
                                                                icon={icon}
                                                                className="form-control"
                                                            /> */}
                                                            {/* <DatePicker
                                                                onChange={e => this.setState({ guestinfo: { ...guestinfo, birth_date: e } }, () => this.handleChange())}
                                                                value={guestinfo.birth_date}
                                                                id="birth_date"
                                                                // format="dd-MM-y"
                                                                placeholder={t('home.Date of Birth')}
                                                                // onBlur={() => this.validator.showMessageFor('birth_date')}
                                                                name="birth_date"
                                                                className="form-control readonly"
                                                            /> */}
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                                                <DatePicker
                                                                    disableFuture
                                                                    openTo="year"
                                                                    format="dd-MM-yyyy"
                                                                    id="birth_date"
                                                                    name="birth_date"
                                                                    placeholder="dd-mm-yyyy"
                                                                    views={["year", "month", "date"]}
                                                                    // value={guestinfo.date_of_birth ?new Date(guestinfo.date_of_birth):null}
                                                                    value={dob}
                                                                    // onChange={e => this.setState({ guestinfo: { ...guestinfo, birth_date: new Date(e).getDate()+'-'+(parseInt(new Date(e).getMonth()))+'-'+new Date(e).getFullYear() } }, () => this.handleChange())}
                                                                    onChange={this.handleDateChange}
                                                                    // onBlur={() => this.validator.showMessageFor('birth_date')} 
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                            {/* <input 
                                                                required 
                                                                value={guestinfo.birth_date} 
                                                                onBlur={() => this.validator.showMessageFor('birth_date')} 
                                                                onChange={e => { this.setState({ guestinfo: { ...guestinfo, birth_date: e.target.value } }, () => this.handleChange()) }} 
                                                                type="date" 
                                                                id="birth_date" 
                                                                name="birth_date" 
                                                                max="9999-12-31"
                                                                className="form-control" /> */}
                                                            {/* {this.validator.message('birth_date', guestinfo.birth_date, 'required')} */}
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label xl="2" lg="2" md="12" sm="12" xs="12">
                                                            {t('home.Age')}
                                                            <span className="color-red">*</span>
                                                        </Label>
                                                        <Col xl="4" lg="4" md="12" sm="12" xs="12" >
                                                            <input readonly="readonly" value={guestinfo.age} onChange={e => { this.setState({ guestinfo: { ...guestinfo, age: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('age')} type="number" id="age" name="age" className="form-control form-control-sm" placeholder={t('home.Age')} required />
                                                            {this.validator.message('age', this.state.guestinfo.age, 'required')}
                                                        </Col>
                                                        <Label xl="2" lg="2" md="12" sm="12" xs="12">
                                                            {t('home.Gender')}
                                                        </Label>
                                                        <Col xl="4" lg="4" md="12" sm="12" xs="12">
                                                            <select value={guestinfo.gender} onChange={e => { this.setState({ guestinfo: { ...guestinfo, gender: e.target.value } }) }} id="gender" name="gender" className="form-control form-control-sm">
                                                                <option value="Male" >{t('home.Male')}</option>
                                                                <option value="Female">{t('home.Female')}</option>
                                                            </select>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label xl="2" lg="2" md="12" sm="12" xs="12">
                                                            {t('home.Mobile')}
                                                            <span className="color-red">*</span>
                                                        </Label>
                                                        <Col xl="4" lg="4" md="12" sm="12" xs="12">
                                                            <input type="text" oninvalid={this.mobileInvalid} required="" value={guestinfo.mobile} onChange={e => { this.setState({ guestinfo: { ...guestinfo, mobile: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('mobile')} name="mobile" className="form-control form-control-sm" placeholder={t('home.Mobile')} title="Please enter this field in the appropriate format." />
                                                            {this.validator.message('mobile', this.state.guestinfo.mobile, 'required|min:8|max: 15')}
                                                        </Col>
                                                        <Label xl="2" lg="2" md="12" sm="12" xs="12" >
                                                            {t('home.Email')}
                                                            <span className="color-red">*</span>

                                                        </Label>
                                                        <Col xl="4" lg="4" md="12" sm="12" xs="12">
                                                            <input type="email" value={guestinfo.email} onChange={e => { this.setState({ guestinfo: { ...guestinfo, email: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('email')} id="email_participant" name="email" className="form-control form-control-sm" placeholder={t('home.Email')} required />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label xl="2" lg="2" md="12" sm="12" xs="12">
                                                            {t('home.Address')}
                                                            <span className="color-red">*</span>

                                                        </Label>
                                                        <Col xl="4" lg="4" md="12" sm="12" xs="12" className="px-2">
                                                            <textarea value={guestinfo.address} className="w-100" onChange={e => { this.setState({ guestinfo: { ...guestinfo, address: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('address')} rows="4" id="address" name="address" required>{guestinfo.address}</textarea>
                                                            {this.validator.message('address', this.state.guestinfo.address, 'required')}
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>


                                            <Row className="py-3">
                                                <Col xl={{ size: 2, order: 2 }} lg={{ size: 2, order: 2 }} md={{ size: 2, order: 2 }} sm={{ size: 12, order: 1 }} xs={{ size: 12, order: 1 }} >
                                                    <img style={{ display: 'none' }} id="loader" name="loader" src={myConstClass.IMAGE_URL + 'loader.gif'} className="img-fluid" />
                                                    <button type="submit" className="btn btn-sm btn-outline-brown btnSave btn-block" >{t('home.Save')}</button>
                                                </Col>
                                                <Col xl={{ size: 2, offset: 7, order: 1 }} lg={{ size: 2, offset: 7, order: 1 }} md={{ size: 2, offset: 7, order: 1 }} sm={{ size: 12, order: 2 }} xs={{ size: 12, order: 2 }} >
                                                    <span data-dismiss="modal" aria-label="Close" className="btn-block text-center">{t('home.Cancel')}</span>
                                                </Col>
                                            </Row>

                                        </Form>

                                    </div>
                                </div>
                            </div>



                        </div>
                    </div >
                </div >

                <Footer />
            </div >
        );
    }
}
Participants.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Participants);
