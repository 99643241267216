export function serializeToShippingAddressState(data) {
  try {
    const {
      user_id,
      shipping_first_name,
      shipping_middle_name,
      shipping_last_name,
      shipping_company,
      shipping_address_line_1,
      shipping_address_line_2,
      shipping_landmark,
      shipping_country_id,
      shipping_country_name,
      shipping_state_id,
      shipping_state_name,
      shipping_city_id,
      shipping_city_name,
      shipping_pincode,
      shipping_phone,
      shipping_email,
    } = data;
    return {
      type: "2",
      user_id: user_id,
      user_id_fk: user_id,
      first_name: shipping_first_name,
      middle_name: shipping_middle_name,
      last_name: shipping_last_name,
      company: shipping_company,
      address_line_1: shipping_address_line_1,
      address_line_2: shipping_address_line_2,
      landmark: shipping_landmark,
      country_id: shipping_country_id,
      country_id_fk: shipping_country_id,
      country_name: shipping_country_name,
      state_id: shipping_state_id,
      state_id_fk: shipping_state_id,
      state_name: shipping_state_name,
      city_id: shipping_city_id,
      city_id_fk: shipping_city_id,
      city_name: shipping_city_name,
      pincode: shipping_pincode,
      phone: shipping_phone,
      email: shipping_email,
    };
  } catch (error) {
    console.log(
      "SerializeError -> serializeToShippingAddressState -> error",
      error
    );
  }
}

export function serializeToBillingAddressState(data) {
  try {
    const {
      user_id,
      first_name,
      middle_name,
      last_name,
      company,
      address_line_1,
      address_line_2,
      landmark,
      country_id,
      country_name,
      state_id,
      state_name,
      city_id,
      city_name,
      pincode,
      phone,
      email,
    } = data;
    return {
      type: "1",
      user_id: user_id,
      user_id_fk: user_id,
      first_name: first_name,
      middle_name: middle_name,
      last_name: last_name,
      company: company,
      address_line_1: address_line_1,
      address_line_2: address_line_2,
      landmark: landmark,
      country_id: country_id,
      country_id_fk: country_id,
      country_name: country_name,
      state_id: state_id,
      state_id_fk: state_id,
      state_name: state_name,
      city_id: city_id,
      city_id_fk: city_id,
      city_name: city_name,
      pincode: pincode,
      phone: phone,
      email: email,
    };
  } catch (error) {
    console.log(
      "SerializeError -> serializeToBillingAddressState -> error",
      error
    );
  }
}

export function serializeCompareShipping(data) {
  try {
    const {
      address_line_1,
      address_line_2,
      city_id,
      city_id_fk,
      city_name,
      company,
      country_id,
      country_id_fk,
      country_name,
      email,
      first_name,
      landmark,
      last_name,
      middle_name,
      phone,
      pincode,
      state_id,
      state_id_fk,
      state_name,
      type,
      user_id,
      user_id_fk,
    } = data;
    return {
      shipping_address_line_1: address_line_1,
      shipping_address_line_2: address_line_2,
      shipping_city_id: city_id,
      shipping_city_name: city_name,
      shipping_company: company,
      shipping_country_id: country_id,
      shipping_country_name: country_name,
      shipping_email: email,
      shipping_first_name: first_name,
      shipping_landmark: landmark,
      shipping_last_name: last_name,
      shipping_middle_name: middle_name,
      shipping_phone: phone,
      shipping_pincode: pincode,
      shipping_state_id: state_id,
      shipping_state_name: state_name,
    };
  } catch (error) {
    console.log(
      "SerializeError -> serializeToCompareShipppingtoSavedAddress -> error",
      error
    );
  }
}

export function serializeCompareBilling(data) {
  try {
    const {
      address_line_1,
      address_line_2,
      city_id,
      city_id_fk,
      city_name,
      company,
      country_id,
      country_id_fk,
      country_name,
      email,
      first_name,
      landmark,
      last_name,
      middle_name,
      phone,
      pincode,
      state_id,
      state_id_fk,
      state_name,
      type,
      user_id,
      user_id_fk,
    } = data;
    return {
      address_line_1: address_line_1,
      address_line_2: address_line_2,
      city_id: city_id,
      city_name: city_name,
      company: company,
      country_id: country_id,
      country_name: country_name,
      email: email,
      first_name: first_name,
      landmark: landmark,
      last_name: last_name,
      middle_name: middle_name,
      phone: phone,
      pincode: pincode,
      state_id: state_id,
      state_name: state_name,
    };
  } catch (error) {
    console.log(
      "SerializeError -> serializeToCompareBillingtoSavedAddress -> error",
      error
    );
  }
}

export function serializeToShippingPayload(data) {
  try {
    const payload = [
      { name: "shipping_first_name", value: data.first_name },
      { name: "shipping_middle_name", value: data.middle_name },
      { name: "shipping_last_name", value: data.last_name },
      { name: "shipping_company", value: data.company },
      { name: "shipping_address_line_1", value: data.address_line_1 },
      { name: "shipping_address_line_2", value: data.address_line_2 },
      { name: "shipping_landmark", value: data.landmark },
      { name: "shipping_country", value: data.country_id_fk },
      { name: "shipping_state", value: data.state_id_fk },
      { name: "shipping_city", value: data.city_id_fk },
      { name: "shipping_pincode", value: data.pincode },
      { name: "shipping_phone", value: data.phone },
      { name: "shipping_email", value: data.email },
    ];
    return payload;
  } catch (error) {
    console.log(
      "SerializeError -> serializeToShippingAddressPayload -> error",
      error
    );
  }
}

export function serializeToBillingPayload(data) {
  try {
    const payload = [
      { name: "first_name", value: data.first_name },
      { name: "middle_name", value: data.middle_name },
      { name: "last_name", value: data.last_name },
      { name: "company", value: data.company },
      { name: "address_line_1", value: data.address_line_1 },
      { name: "address_line_2", value: data.address_line_2 },
      { name: "landmark", value: data.landmark },
      { name: "country", value: data.country_id_fk },
      { name: "state", value: data.state_id_fk },
      { name: "city", value: data.city_id_fk },
      { name: "pincode", value: data.pincode },
      { name: "phone", value: data.phone },
      { name: "email", value: data.email },
    ];
    return payload;
  } catch (error) {
    console.log(
      "SerializeError -> serializeToBillingAddressPayload -> error",
      error
    );
  }
}

export function serializeToSavedAddressPayload(shipping, billing) {
  try {
    // console.log("in serde", { shipping, billing });
    const payload = {
      shipping_first_name: shipping?.first_name || "",
      shipping_middle_name: shipping?.middle_name || "",
      shipping_last_name: shipping?.last_name || "",
      shipping_company: shipping?.company || "",
      shipping_address_line_1: shipping?.address_line_1 || "",
      shipping_address_line_2: shipping?.address_line_2 || "",
      shipping_landmark: shipping?.landmark || "",
      shipping_country: shipping?.country_id || shipping?.country_id_fk || "",
      shipping_state: shipping?.state_id || shipping?.state_id_fk || "",
      shipping_city: shipping?.city_id || shipping?.city_id_fk || "",
      shipping_pincode: shipping?.pincode || "",
      shipping_phone: shipping?.phone || "",
      shipping_email: shipping?.email || "",
      first_name: billing.first_name,
      middle_name: billing.middle_name,
      last_name: billing.last_name,
      company: billing.company,
      address_line_1: billing.address_line_1,
      address_line_2: billing.address_line_2,
      landmark: billing.landmark,
      country: billing.country_id,
      state: billing?.state_id || billing?.state_id_fk,
      city: billing?.city_id || billing?.city_id_fk,
      pincode: billing.pincode,
      phone: billing.phone,
      email: billing.email,
      user_id:
        billing?.user_id || shipping?.user_id || shipping?.user_id_fk || "",
    };
    return payload;
  } catch (error) {
    console.log(
      "SerializeError -> serializeToSavedAddressPayload -> error",
      error
    );
  }
}
