import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';

import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { loadReCaptcha } from 'react-recaptcha-google';

import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import Header from './Header.js';
import Footer from './Footer.js';
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';

// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,

} from 'reactstrap';

class Signup extends Component {
    componentDidMount() {
  	    loadReCaptcha(); // Load reCAPTCHA library
    }
    constructor(props) {
        super(props);
        this.product_id = 0;
        this.state = {
            flag: 0,
            guestinfo: {
                user_id: '',
            }
        }
        // this.errors = {
        //     email: false,
        //     first_name: false,
        //     last_name: false,
        //     phone: false,
        //     password: false
        // }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validator = new SimpleReactValidator();
        this.handleReset = this.handleReset.bind(this);
    }

    componentDidMount() {
        //       fetch(myConstClass.BASE_URL+'product/getAvailProdTypes')
        //       .then(response => response.json())
        //       .then(response =>this.setState({types: response}))
        //       .catch(err => console.log(err))


        //       const answer_array = this.props.location.pathname.split('/');
        //        // alert(answer_array);
        //     if(answer_array['2']){

        //   fetch(myConstClass.BASE_URL+'product/getProductInfo/'+answer_array['2']+'/'+getLanguage())
        //   .then(response => response.json())
        //   .then(response =>this.setState({guestinfo: response}))  
        //   .catch(err => console.log(err))

        //   fetch(myConstClass.BASE_URL+'product/getRelatedProducts/'+answer_array['2'])
        //       .then(response => response.json())
        //       .then(response =>this.setState({posts: response}))
        //       .catch(err => console.log(err))

        //     }

    }

    componentDidUpdate(prevProps) {

        const answer_array = this.props.location.pathname.split('/');
        const { flag } = this.state
        // alert(answer_array);
        //    if(answer_array['2'] && getLanguage()=='ma' && flag==0){
        //     this.setState({flag:1})
        //  fetch(myConstClass.BASE_URL+'product/getProductInfo/'+answer_array['2']+'/'+getLanguage())
        //  .then(response => response.json())
        //  .then(response =>this.setState({guestinfo: response}))  
        //  .catch(err => console.log(err))
        //    }

        //    if(answer_array['2'] && getLanguage()=='en' && (flag==1)){
        //     this.setState({flag:0})
        //  fetch(myConstClass.BASE_URL+'product/getProductInfo/'+answer_array['2']+'/'+getLanguage())
        //  .then(response => response.json())
        //  .then(response =>this.setState({guestinfo: response}))  
        //  .catch(err => console.log(err))
        //    }
    }

    handleReset() {
        this.setState({ guestinfo: { ...this.state.guestinfo, password: '', confirm_password: '' }});
    }

    async handleSubmit(e) {
		//Code for recaptcha added on 29.03.23 SGH
		e.preventDefault();
        const { email, first_name, last_name, mobile, password, confirm_password } = this.state.guestinfo;

        // Validate each field
        const isEmailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
        const isFirstNameValid = /^[A-Za-z]{2,50}$/.test(first_name);
        const isLastNameValid = /^[A-Za-z]{2,50}$/.test(last_name);
        const isPhoneValid = /^\d{8,15}$/.test(mobile);
        const isPassword = /^(?!.*\s)(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/.test(password);
		const token = await window.grecaptcha.execute('6LdMWkElAAAAADiLK_36r8e9ZzJPLOoz1mSWVo-J');

        if (!token) {
     		alert('Please verify that you are not a robot.');
		    return;
		}
		//Rest of the Code follows 29.03.23 SGH
        $('#btn_click').css('display', 'none');
        $('#loader').css('display', 'inline');

        if (
            this.validator.allValid() &&
            isEmailValid &&
            isFirstNameValid &&
            isLastNameValid &&
            isPhoneValid &&
            isPassword
            ) {
            // console.log('this.refs', $('#pform').serializeArray());
            const formData = {};
            for (const field in this.refs) {
                formData[field] = this.refs[field].value;
            }
            // console.log('-->', formData);
            fetch(myConstClass.BASE_URL + `users/addUser/`, {
                method: 'POST',
                body: JSON.stringify($('#pform').serializeArray()),


            }).then(response => response.json())
                .then(response => {
                    this.setState({ eventinfo: response })
                    var status = this.state.eventinfo.status;
                    var message = this.state.eventinfo.message;
                    var otp_email = this.state.eventinfo.email;
                    if (status == 'true') {
                        localStorage.removeItem('otp_email');
                        localStorage.setItem('otp_email', otp_email);
                        //var user_id =  this.state.eventinfo.user_details.user_id;
                        //localStorage.setItem('user_id', user_id);
                        localStorage.setItem('success', message);
                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        setTimeout(this.props.history.push('verifyotp'), 10);
                        //toast(message, {containerId: 'B',type: toast.TYPE.SUCCESS}),setTimeout(this.props.history.push('signin'),100);
                    } else {
                        toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
                        $('#btn_click').css('display', 'inline');
                        $('#loader').css('display', 'none');
                        //toast("Wow so easy !");
                    }
                })
                //}).then(toast('User Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS}),setTimeout(this.props.history.push('signup'),100))
                .catch((error) => console.log('error: ', error));
        } else {
            $('#btn_click').css('display', 'inline');
            $('#loader').css('display', 'none');
            //alert('here');
            this.validator.showMessages();
            // console.log('validations' + this.validator.showMessages());
            // toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR})
            if (!email && !first_name && !last_name && !mobile && !password && !confirm_password ) {
                toast.error("All field are required.");
            }
            else if (!isEmailValid || !email) {
                toast.error("Invalid email format. Please enter email.");
                // this.setState({ errors: { email: true } });
                // console.log(this.errors.email);
                // console.log('email field is empty');
                // $('#pform').css('border: ', '5px solid red');
                // $('#useremail').css('border: ', '5px solid red');
                // let useremail = document.querySelectorAll('#useremail');
                // useremail.addEventListener('change', console.log('email field is empty1'));
                // useremail.style.border = "1px solid red";

            }
            else if (!isFirstNameValid || !first_name) {
              toast.error("Invalid first name format. Please enter letters only.");
            }
            // else if (!isMiddleNameValid || !middle_name) {
            //     toast.error("Invalid middle name format. Please enter letters only.");
            // }
            else if (!isLastNameValid || !last_name) {
              toast.error("Invalid last name format. Please enter letters only.");
            }
            else if (!isPhoneValid || !mobile) {
                toast.error("Invalid phone number format. Please enter 10 digits.");
            }
            else if (!isPassword || !password) {
                toast.error("Invalid password format. Password must be at least 8 characters long, contain at least one uppercase letter, one digit, and no spaces.")
            }
            else if (!confirm_password) {
                toast.error("Please enter confirm password.");
            }
        }
		// Reset the reCAPTCHA widget
		if (typeof window.grecaptcha !== 'undefined') {
		  window.grecaptcha.reset();
		}
    }	

    render() {
		const { location } = this.props;
		const isRegisterPage = location.pathname === "/signup";
		// console.log("isRegisterPage: ", isRegisterPage);
		const PagePath2 = location.pathname;
		// console.log("PagePath2: ", PagePath2);

        const { posts, guestinfo, toast, reset } = this.state
        const { t } = this.props;
		
        return (
		  <>
			{isRegisterPage && (
			  <Helmet>
				<script src="https://www.google.com/recaptcha/api.js?render=6LdMWkElAAAAADiLK_36r8e9ZzJPLOoz1mSWVo-J" async defer></script>
			  </Helmet>
			)}

            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />

                <div class="container-fluid">
				
                    <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                            <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb bg-white mb-0">
                                        <li class="breadcrumb-item"><Link to="/">{t('home.home')}</Link></li>
                                        <li class="breadcrumb-item active" aria-current="page">{t('home.Registration')}</li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <h4>{t('home.Registration')}</h4>
                                    <p>{t('home.Please fill in the details below')}: <span class="color-red">* {t('home.Mandatory Fields')}</span></p>
                                </div>
                            </div>
                        </Col>

                        <Col xl="12" lg="12" md="12" sm="12" xs="12" className="px-5">
                            {/* <form> */}
                            <Form action="" id="pform" onSubmit={this.handleSubmit} method="post" encType="multipart/form-data" className="form-horizontal">
                                <div class="row" style={{ marginTop: '-10px' }}>
                                    <div class="form-group col col-md-4">
                                        <label class="color-black font-weight-bolder">{t('home.Email ID')}<span class="color-red">*</span></label>
                                        {/* <input onBlur={() => this.validator.showMessageFor('email')} type="text" class="form-control" id="email" name="email"  placeholder={t('home.Enter Email ID')} /> */}
                                        {/* value={guestinfo.email} */}
                                        <input onChange={e => { this.setState({ guestinfo: { ...guestinfo, email: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('email')} type="email" id="useremail" name="email" class="form-control" placeholder={t('home.Enter email Id')} />
                                        {this.validator.message('email', this.state.guestinfo.email, 'required|email|regex:^\\S+@\\S+\\.\\S+$')}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-12 col-xl-4 col-lg-4 col-md-4">
                                        <label class="color-black font-weight-bolder">{t('home.First Name')}<span class="color-red">*</span></label>
                                        {/* <input onBlur={() => this.validator.showMessageFor('first_name')} type="text" class="form-control" id="first_name" name="first_name"  placeholder={t('home.Enter First Name')} /> */}
                                        <input onChange={e => { this.setState({ guestinfo: { ...guestinfo, first_name: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('first_name')} type="text" id="first_name" name="first_name" class="form-control" placeholder={t('home.Enter First Name')} />
                                        {this.validator.message('first_name', this.state.guestinfo.first_name, 'required|alpha|string|min:2|max:50')}
                                    </div>
                                    <div class="form-group col-12 col-xl-4 col-lg-4 col-md-4">
                                        <label class="color-black font-weight-bolder">{t('home.Middle Name')}</label>
                                        <input onBlur={() => this.validator.showMessageFor('middle_name')} onChange={e => { this.setState({ guestinfo: { ...guestinfo, middle_name: e.target.value } }) }} type="text" class="form-control" id="middle_name" name="middle_name" placeholder={t('home.Enter Middle Name')} />
                                        {/* {this.validator.message('middle_name', this.state.guestinfo.middle_name, 'required|alpha|string|min:2|max:50')} */}

                                    </div>
                                    <div class="form-group col-12 col-xl-4 col-lg-4 col-md-4">
                                        <label class="color-black font-weight-bolder">{t('home.Last Name')}<span class="color-red">*</span></label>
                                        {/* <input type="text" class="form-control" id="last_name" name="last_name" placeholder={t('home.Enter Last Name')} /> */}
                                        <input onBlur={() => this.validator.showMessageFor('last_name')} onChange={e => { this.setState({ guestinfo: { ...guestinfo, last_name: e.target.value } }) }} type="text" id="last_name" name="last_name" class="form-control" placeholder={t('home.Enter Last Name')} />
                                        {this.validator.message('last_name', this.state.guestinfo.last_name, 'required|alpha|string|min:2|max:50')}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col col-md-4">
                                        <label class="color-black font-weight-bolder">{t('home.Mobile')}<span class="color-red">*</span></label>
                                        {/* <input type="number" class="form-control" id="mobile" name="mobile"  placeholder={t('home.Enter Mobile Number')} /> */}
                                        <input onChange={e => { this.setState({ guestinfo: { ...guestinfo, mobile: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('mobile')} type="number" id="mobile" name="mobile" class="form-control" placeholder={t('home.Enter Mobile Number')} />
                                        {this.validator.message('mobile', this.state.guestinfo.mobile, 'required|min:8|max:15')}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-12 col-xl-4 col-lg-4 col-md-4">
                                        <label class="color-black font-weight-bolder">{t('home.Create Password')}<span class="color-red">*</span></label>
                                        <input 
                                          value={this.state.guestinfo.password}
                                          onChange={e => {
                                            const val = e.target.value.replace(" ", "");
                                            this.setState({ guestinfo: { ...guestinfo, password: val } });
                                          }}
                                          onCopy={(e) => e.preventDefault()}
                                          onPaste={(e) => e.preventDefault()}
                                          onBlur={() => this.validator.showMessageFor('password')} type="password" class="form-control" id="password" name="password" placeholder={t('home.Enter Password')}
                                        />
                                        {this.validator.message('password', this.state.guestinfo.password, 'required|min:8|max:15')}
                                    </div>
                                    <div class="form-group col-12 col-xl-4 col-lg-4 col-md-4">
                                        <label class="color-black font-weight-bolder">{t('home.Confirm Password')}<span class="color-red">*</span></label>
                                        <input
                                          value={this.state.guestinfo.confirm_password}
                                          onChange={e => {
                                            const val = e.target.value.replace(" ", "");
                                            this.setState({ guestinfo: { ...guestinfo, confirm_password: val } })
                                          }}
                                          onCopy={(e) => e.preventDefault()}
                                          onPaste={(e) => e.preventDefault()}
                                          onBlur={() => this.validator.showMessageFor('confirm_password')} type="password" class="form-control" id="confirm_password" name="confirm_password" placeholder={t('home.Re-Enter Password')}
                                        />
                                        {this.validator.message('confirm_password', this.state.guestinfo.confirm_password, 'required|min:8|max:15')}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col col-md-4">
                                        <label class="color-black font-weight-bolder">{t('home.Sadhak No')} <span class="color-red">({t('home.For Manashakti Sadhaks only')})</span></label>
                                        <input onChange={e => { this.setState({ guestinfo: { ...guestinfo, sadhak_number: e.target.value } }) }} type="text" class="form-control" id="sadhak_number" name="sadhak_number" placeholder={t('home.Enter Sadhak Number')} />
                                    </div>
                                </div>
								
                                <div class="row">
                                    <div class="form-group col-12 col-xl-2 col-lg-2 col-md-2">
                                        <button id="btn_click" name="btn_click" class="btn btn-brown btn-block">{t('home.REGISTER')}</button>
                                        <img style={{ display: 'none' }} id="loader" name="loader" src={myConstClass.IMAGE_URL + 'loader.gif'} class="img-fluid" />
                                    </div>
                                    <div class="form-group col-12 col-xl-2 col-lg-2 col-md-2">
                                        <button onClick={this.handleReset} type="reset" class="btn btn-outline-brown color-brown  pl-4 pr-4 btn-block">{t('home.RESET')}</button>
                                    </div>
                                    <div class="form-group col-12 col-xl-2 col-lg-2 col-md-2">
                                        <Link to="/" style={{ fontSize: '17px', marginTop: '5px', color: '#9c9ea0', fontWeight: '700', verticalAlign: '-webkit-baseline-middle' }} className="btn-block text-center">
                                            {t('home.CANCEL')}
                                        </Link>
                                    </div>
                                </div>
								

                            </Form>

                        </Col>
                    </Row>

                </div>
                <Footer />
            </div>

		</>

        );
    }
}


// Listen for when the user is leaving the page
window.addEventListener('beforeunload', function (e) {
    // Hide the captcha element
			if (typeof window.grecaptcha !== 'undefined') {
			  window.grecaptcha.reset();
			}
});

Signup.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Signup);
