import React from "react";
import { getLanguage } from "react-switch-lang";
// import { useDispatch, useSelector } from "react-redux";
// import { selectEventCategories } from "../store/categorySlice";
import { BASE_URL } from "../constant";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export default function MagazineTable({
  magazineItems = [],
  setMagazineItems,
  onDataChange,
}) {
  async function handleRemoveMagazine(package_id) {
    const user_id = localStorage.getItem("user_id");

    let are_you_sure = "Are you sure?";
    let revert = "Do you want to remove the item from the cart ?";
    let deleteConfirm = "Yes, delete it!";
    let cancel = "Cancel";
    if (getLanguage() == "ma") {
      are_you_sure = "तुम्हाला खात्री आहे?";
      revert = "आपण कार्टमधून वस्तू/उपक्रम काढून टाकू इच्छिता ?";
      deleteConfirm = "होय, काढून टाका";
      cancel = "नाही";
    }

    try {
      const result = await Swal.fire({
        title: are_you_sure,
        text: revert,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: cancel,
        confirmButtonText: deleteConfirm,
      });

      if (!result.value) return null;

      const removeUrl = `${BASE_URL}cart/removeProductFromCart/${package_id}/${user_id}`;
      const removeReqData = await (await fetch(removeUrl)).json();

      if (removeReqData?.status) {
        toast("" + removeReqData?.message, {
          containerId: "B",
          type: toast.TYPE.SUCCESS,
        });
        const magazineListUrl = `${BASE_URL}cart/getAllMyCartDataForMagazine/${user_id}/${getLanguage()}`;
        const magazineReqData = await (await fetch(magazineListUrl)).json();
        setMagazineItems(magazineReqData);

        onDataChange();
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {magazineItems?.length > 0 && (
        <>
          <div className="card rounded-lg mb-2">
            <h4 className="d-block d-md-none card-header">
              <strong>Magazine Details</strong>
            </h4>
            <div class="d-none d-md-block text-white rounded-top bg-accent">
              <div className="row no-gutters align-items-end m-3">
                <div className="col-6 h6 mb-1">MAGAZINES DETAILS</div>
                <div className="col-2 text-center">
                  <small>SUBSCRIPTION</small>
                </div>
                <div className="col-3 text-center">
                  <small>AMOUNT</small>
                </div>
                <div className="col-1 p-0"></div>
              </div>
            </div>
            <div className="overflow-hidden">
              {magazineItems?.map((item) => (
                <div className="row no-gutters border-bottom p-3">
                  <div className="col-12 col-md-6 mb-1">
                    <h5>{item.magazine_title}</h5>
                    <small className="d-block">{item?.subscription_type}</small>
                  </div>
                  <div className="col-4 col-md-2 d-flex justify-content-center align-items-center">
                    <div className="m-1 d-inline text-center">
                      <small>{item.package_title}</small>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 d-flex justify-content-center align-items-center">
                    <h5>
                      <strong>₹ {item.donation_contribution}</strong>
                    </h5>
                  </div>
                  <div className="col-2 order-4 col-md-1 d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => {
                        handleRemoveMagazine(item?.package_id);
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="add-more-div mb-4">
            <Link
              to="/magazine-subscription/2"
              className="btn btn-outline-success w-100"
            >
              <i className="fas fa-plus me-4"></i>
              <span className="ms-4 ml-4">Add More Magazines</span>
            </Link>
          </div>
        </>
      )}
    </>
  );
}
