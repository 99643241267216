import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import Header from './Header.js';
import Footer from './Footer.js';
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';

// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

class Donationcontribution extends Component {

    constructor(props) {
        var email = localStorage.getItem('email');
        super(props);
        this.product_id = 0;
        this.state = {
            flag: 0,
            month: [],
            year: [],
            guestinfo: {
                user_id: '',
                email: '',
                first_name: '',
                last_name: '',
                sadhak_number: '',
                from_month: '',
                from_year: '',
                to_month: '',
                to_year: '',

            },
            eventinfo: {
                last_name: '',
                sadhak_number: '',
                confirm_password: '',
                email: email,
            },
            removeinfo: {
                message: '',
                status: '',
                encrypted_data: '',
                access_code: '',

            },
            country: [],
            city: [],
            shipping_cityshipping: [],
            shipping_countrystate: [],

        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.proceedToPayment = this.proceedToPayment.bind(this);
        //   this.handleDayChange = this.handleDayChange.bind(this);
        //   this.handleToDayChange = this.handleToDayChange.bind(this);
        this.validator = new SimpleReactValidator();
        this.billing_type = "1";
    }

    //   handleDayChange(day) { alert(day);
    //     var utcDate = day.toLocaleDateString();
    //     $('#birth_date').val(utcDate);
    //     this.state.guestinfo.birth_date_old = utcDate;

    //     this.setState({ selectedDay: day });
    //   }

    //   handleToDayChange(day) { //alert(day);
    //     var utcDate = day.toLocaleDateString();
    //     $('#end_date').val(utcDate);
    //     this.state.eventinfo.end_date = utcDate;
    //     this.setState({ selectedDay: day });
    //   }

    componentDidMount() {

        fetch(myConstClass.BASE_URL + 'event/getDonationContributionMonth' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ month: response }))
            .catch(err => console.log(err))
        fetch(myConstClass.BASE_URL + 'event/getDonationContributionYear' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ year: response }))
            .catch(err => console.log(err))


        var user_id = localStorage.getItem('user_id');
        if (user_id > 0) {
            fetch(myConstClass.BASE_URL + 'users/getUserProfileInfo/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ guestinfo: response })
                    const numRows = this.state.guestinfo.length;
                    //if(numRows > 0){
                    // alert(numRows);
                    this.state.guestinfo.first_name = this.state.guestinfo.first_name;
                    this.state.guestinfo.middle_name = this.state.guestinfo.middle_name;
                    this.state.guestinfo.last_name = this.state.guestinfo.last_name;
                    this.state.guestinfo.phone = this.state.guestinfo.phone;
                    this.state.guestinfo.email = this.state.guestinfo.email;
                    this.state.guestinfo.sadhak_number = this.state.guestinfo.sadhak_number;
                    //}
                })
        }
        //   if(getLanguage() == 'ma'){

        //    const  month = [
        //         {id: 1, title: 'जानेवारी'},
        //         {id: 2, title: 'फेब�?र�?वारी'},
        //         {id: 3, title: 'मार�?च'},
        //         {id: 4, title: '�?प�?रिल'},
        //         {id: 5, title: 'मे'},
        //         {id: 6, title: 'जून'},
        //         {id: 7, title: 'ज�?लै'},
        //         {id: 8, title: 'ऑगस�?ट'},
        //         {id: 9, title: 'सप�?टेंबर'},
        //         {id: 10, title: 'ऑक�?टोबर'},
        //         {id: 11, title: 'नोव�?हेंबर'},
        //         {id: 12, title: 'डिसेंबर'}
        //       ];
        // }else{

        //     const month = [
        //       {id: 1, title: 'January'},
        //       {id: 2, title: 'February'},
        //       {id: 3, title: 'March'},
        //       {id: 4, title: 'April'},
        //       {id: 5, title: 'May'},
        //       {id: 6, title: 'June'},
        //       {id: 7, title: 'July'},
        //       {id: 8, title: 'August'},
        //       {id: 9, title: 'September'},
        //       {id: 10, title: 'October'},
        //       {id: 11, title: 'November'},
        //       {id: 12, title: 'December'}
        //     ];
        // }

        const answer_array = this.props.location.pathname.split('/');
        var user_id = localStorage.getItem('user_id');
        var email = localStorage.getItem('email');
        $('#email').val(email);
        //alert(email);
        this.state.eventinfo.email = email;
        console.log(this.state.eventinfo.email);
        if (user_id > 0) {

        } else {
            setTimeout(this.props.history.push('signin'), 10);
        }

    }



    componentDidUpdate(prevProps) {

        const answer_array = this.props.location.pathname.split('/');
        const { flag } = this.state

    }
    proceedToPayment(e) {
        e.preventDefault();
        var from_month = $('#from_month').val();
        var from_year = $('#from_year').val();

        var to_month = $('#to_month').val();
        var to_year = $('#to_year').val();
        var condition = 1;
        var fDate = new Date("01/"+from_month+"/"+from_year);
        var tDate = new Date("01/"+to_month+"/"+to_year);

        if (tDate < fDate) {
            var condition = 0;
            var message = "To month must be greater or equal to from month / year.";
            toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
        } else if (to_year < from_year) {
            var condition = 0;
            var message = "To year must be greater or equal to from year.";
            toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
        // } else if (to_month < from_month) {
        //     var condition = 0;
        //     var message = "To month must be greater or equal to from month.";
        //     toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
        } else if (from_month == '') {
            var condition = 0;
            var message = "From month must be required.";
            toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
        } else if (from_year == '') {
            var condition = 0;
            var message = "From year must be required.";
            toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
        } else if (to_month == '') {
            var condition = 0;
            var message = "To month must be required.";
            toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
        } else if (to_year == '') {
            var condition = 0;
            var message = "To year must be required.";
            toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
        }
        else {
            var user_id = localStorage.getItem('user_id');
            var donation_amount = $('#donation_amount').val();
            if (donation_amount != '' && user_id != '' && user_id > 0 && donation_amount > 0) {
                var exampleFormControlTextarea1 = $('#exampleFormControlTextarea1').val();
                $('#btn_click').css('display', 'none');
                $('#loader').css('display', 'inline');
                console.log(exampleFormControlTextarea1);
                // fetch(myConstClass.BASE_URL+'payment/goToPayment/'+user_id+'/'+exampleFormControlTextarea1)
                fetch(myConstClass.BASE_URL + `payment/goToPaymentDonationContribution/` + user_id + '/' + donation_amount, {
                    method: 'POST',
                    // body: JSON.stringify($('#exampleFormControlTextarea1').serializeArray()),
                    body: JSON.stringify($('#pform').serializeArray()),

                })
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ removeinfo: response })
                        var message = this.state.removeinfo.message;
                        var status = this.state.removeinfo.status;
                        var order_id = this.state.removeinfo.order_id;
                        if (status == 'true') {
                            $('#btn_click').css('display', 'inline');
                            $('#loader').css('display', 'none');
                            var message = "Your order completed successfully.";
                            localStorage.removeItem('success');
                            this.state.removeinfo.encrypted_data = this.state.removeinfo.encrypted_data;
                            this.state.removeinfo.access_code = this.state.removeinfo.access_code;


                            $("#redirect").submit();

                        } else {
                            $('#btn_click').css('display', 'inline');
                            $('#loader').css('display', 'none');
                            localStorage.removeItem('error');
                            toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                        }
                    })
                    .catch(err => console.log(err))


            } else {
                if (user_id > 0) {


                } else {
                    var message = "Please login to our website.";
                    localStorage.removeItem('error');
                    localStorage.setItem('error', message);
                    this.props.history.push('/signin')

                }

            }
        }

    }

    handleSubmit(e) {
        e.preventDefault();
        var user_id = localStorage.getItem('user_id');
        console.log('this.refs', $('#pform').serializeArray());
        if (this.validator.allValid()) {
            const formData = {};
            for (const field in this.refs) {
                formData[field] = this.refs[field].value;
            }
            console.log('-->', formData);
            fetch(myConstClass.BASE_URL + `users/changePasswordFrontEnd/` + '/' + user_id, {
                method: 'POST',
                body: JSON.stringify($('#pform').serializeArray()),

            })
                .then(response => response.json())
                .then(response => {
                    this.setState({ guestinfo: response })
                    var message = this.state.guestinfo.message;
                    var status = this.state.guestinfo.status;
                    //console.log('hii'+this.state.guestinfo);
                    if (status == 'true') {
                        // $('#address').modal('hide');
                        // $('#pform')[0].reset();

                        const numRows = this.state.guestinfo.length;
                        if (numRows > 0) {
                            this.state.is_billing_address_available = 1;
                        }


                        var message = "Your password has been changed successfully.";
                        localStorage.setItem('success', message);
                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        setTimeout(this.props.history.push('/'), 100);
                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))
        }
        else {
            //alert('here');
            this.validator.showMessages();
            console.log('validations' + this.validator.showMessages());
            e.preventDefault();
            toast('Please fill all required fields.', { containerId: 'B', type: toast.TYPE.ERROR })
            // toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR})

        }
    }


    render() {

        const { removeinfo, month, year, country, shipping_countrystate, city, eventinfo, posts, guestinfo, toast } = this.state
        const { t } = this.props;
        return (

            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />
                <div class="container-fluid">

                    <div class="row" style={{ marginTop: '10px', marginLeft: '-6px' }}>
                        <div class="col-lg-12 ">
                            <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb" style={{ marginLeft: '-3px' }}>
                                    <ol class="breadcrumb bg-white mb-0">
                                        <li class="breadcrumb-item">
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a href="javascript:void(0);">{t('home.My Account')}
                                            </a>
                                        </li>
                                        {/* <li class="breadcrumb-item"><a href="#">{t('home.My Account')}</a></li> */}
                                        <li class="breadcrumb-item active" aria-current="page">{t('home.donation_contribution')}</li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <h4>{t('home.Donation Contribution (Online Dhananand)')}</h4>
                                    <p>{t('home.Donation_contribution_description')}</p>
                                    <p><label>{t('home.Please fill in the details belows')}&nbsp;</label><span class="color-red">* {t('home.Mandatory Fields')}</span>


                                        <Link class="btn btn-light" to="/donation-contribution-list" style={{ marginLeft: '52%' }}>{t('home.View History')}</Link>
                                    </p>
                                </div>

                            </div>
                        </div>

                        <div class="col col-lg-10 ">
                            {/* <form> */}
                            <Form action="" id="pform" method="post" encType="multipart/form-data" className="form-horizontal">

                                <div class="row" style={{ marginTop: '-10px' }}>
                                    <div class="form-group col col-md-4">
                                        <label class="color-black font-weight-bolder">{t('home.First Name')}<span class="color-red">*</span></label>
                                        <input readonly="readonly" value={guestinfo.first_name} onChange={e => { this.setState({ guestinfo: { ...guestinfo, first_name: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('first_name')} type="text" class="form-control" id="first_name" name="first_name" placeholder={t('home.Enter First Name')} />
                                        {/* <input value={eventinfo.first_name}    type="text" id="first_name" name="first_name" class="form-control"  /> */}

                                    </div>

                                </div>
                                <div class="row" >
                                    <div class="form-group col col-md-4">
                                        <label class="color-black font-weight-bolder">{t('home.Last Name')}<span class="color-red">*</span></label>
                                        {/* <input onBlur={() => this.validator.showMessageFor('first_name')} type="text" class="form-control" id="first_name" name="first_name"  placeholder={t('home.Enter First Name')} /> */}
                                        <input readonly="readonly" value={guestinfo.last_name} onChange={e => { this.setState({ guestinfo: { ...guestinfo, last_name: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('last_name')} type="text" id="last_name" name="last_name" class="form-control" placeholder={t('home.Enter last name')} />
                                        {this.validator.message('last_name', this.state.guestinfo.last_name, 'required')}
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="form-group col col-md-4">
                                        <label class="color-black font-weight-bolder">{t('home.Sadhak No')}<span class="color-red">*</span></label>
                                        {/* <input onBlur={() => this.validator.showMessageFor('first_name')} type="text" class="form-control" id="first_name" name="first_name"  placeholder={t('home.Enter First Name')} /> */}
                                        <input readonly="readonly" value={guestinfo.sadhak_number} onChange={e => { this.setState({ guestinfo: { ...guestinfo, sadhak_number: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('sadhak_number')} type="text" id="sadhak_number" name="sadhak_number" class="form-control" placeholder={t('home.Enter sadhak no.')} />
                                        {this.validator.message('sadhak_number', this.state.guestinfo.sadhak_number, 'required')}
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="form-group col-6 col-xl-2 col-lg-2 col-md-2">
                                        <label class="color-black font-weight-bolder">{t('home.From')}<span class="color-red">*</span></label>
                                        <select id="from_month" name="from_month" className="form-control form-control-sm">
                                            <option value="">{t('home.Month')}</option>
                                            {month.map((info, ii) => {
                                                return (
                                                    <option value={info.month}>{info.label}</option>
                                                )
                                            })
                                            }
                                        </select>
                                        {this.validator.message('from_month', this.state.guestinfo.from_month, 'required')}
                                    </div>
                                    <div class="form-group col-6 col-xl-2 col-lg-2 col-md-2">
                                        <label class="color-black font-weight-bolder">&nbsp;</label>
                                        <select id="from_year" name="from_year" className="form-control form-control-sm">
                                            <option value="">{t('home.Year')}</option>
                                            {year.map((info, ii) => {
                                                return (
                                                    <option value={info.year}>{info.year}</option>
                                                )
                                            })
                                            }
                                        </select>
                                        {this.validator.message('from_year', this.state.guestinfo.from_year, 'required')}
                                    </div>

                                    <div class="form-group col-6 col-xl-2 col-lg-2 col-md-2">
                                        <label class="color-black font-weight-bolder">{t('home.To')}<span class="color-red">*</span></label>
                                        <select id="to_month" name="to_month" className="form-control form-control-sm">
                                            <option value="">{t('home.Month')}</option>
                                            {month.map((info, ii) => {
                                                return (
                                                    <option value={info.month}>{info.label}</option>
                                                )
                                            })
                                            }
                                        </select>
                                        {this.validator.message('to_month', this.state.guestinfo.to_month, 'required')}

                                    </div>
                                    <div class="form-group col-6 col-xl-2 col-lg-2 col-md-2">
                                        <label class="color-black font-weight-bolder">&nbsp;</label>
                                        <select id="to_year" name="to_year" className="form-control form-control-sm">
                                            <option value="">{t('home.Year')}</option>
                                            {year.map((info, ii) => {
                                                return (
                                                    <option value={info.year}>{info.year}</option>
                                                )
                                            })
                                            }
                                        </select>
                                        {this.validator.message('to_year', this.state.guestinfo.to_year, 'required')}
                                    </div>

                                </div>


                                <div class="row">
                                    <div class="form-group col-12 col-xl-4 col-lg-4 col-md-4">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>{t('home.Rs')}</InputGroupText>
                                            </InputGroupAddon>
                                            <Input value={guestinfo.donation_amount} onChange={e => { this.setState({ guestinfo: { ...guestinfo, donation_amount: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('donation_amount')} type="number" id="donation_amount" name="donation_amount" class="form-control" placeholder={t('home.Add desired amount')} />
                                        </InputGroup>
                                        {this.validator.message('donation_amount', this.state.guestinfo.donation_amount, 'required')}



                                    </div>

                                </div>



                                <div class="row">
                                    <div class="form-group col-12 col-xl-3 col-lg-3 col-md-3 mt-2">
                                        <button id="btn_click" name="btn_click" onClick={this.proceedToPayment} class="btn btn-brown btn-block">
                                            {t('home.PROCEED TO PAYMENT')}
                                            {/* {t('home.ADD TO CART CAPITAL')} */}
                                        </button>
                                        {/* <Link  class="btn btn-light"  to="/" style={{marginLeft:'10px'}}>{t('home.Cancel')}</Link> */}
                                    </div>
                                </div>

                            </Form>
                            <form method="post" id="redirect" name="redirect" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
                                <input type='hidden' name='encRequest' value={removeinfo.encrypted_data} />
                                <input type='hidden' name='access_code' value={removeinfo.access_code} />
                                <input type="submit" name="btn_submit" id="btn_submit" style={{ display: 'none' }} />
                            </form>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
Donationcontribution.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Donationcontribution);
