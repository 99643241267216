import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../constant";
import { store } from "./index";

export const stepsEnum = Object.freeze({
  0: "Cart",
  1: "Event",
  2: "Address",
  3: "Checkout",
});

const STEPS = [stepsEnum[0], stepsEnum[1], stepsEnum[2], stepsEnum[3]];
const STEPS_NO_EVENTS = [stepsEnum[0], stepsEnum[2], stepsEnum[3]];

const checkoutSlice = createSlice({
  name: "checkout",
  initialState: {
    checkoutSteps: STEPS,
    hasEvents: false,
    hasProducts: false,
    totalPrice: 0,
    tncEventsChecked: false,
    tncProductsChecked: false,
  },
  reducers: {
    setHasEvents(state, action) {
      state.hasEvents = action.payload;
      if (state.hasEvents) {
        state.checkoutSteps = STEPS;
      } else {
        state.checkoutSteps = STEPS_NO_EVENTS;
      }
    },
    setHasProducts(state, action) {
      state.hasProducts = action.payload;
    },
    setCheckoutSteps(state, action) {
      state.checkoutSteps = action.payload;
    },
    setTotalPrice(state, action) {
      state.totalPrice = action.payload;
    },
    setTncEventsChecked(state, action) {
      state.tncEventsChecked = action.payload;
    },
    setTncProductsChecked(state, action) {
      state.tncProductsChecked = action.payload;
    },
  },
});

export const {
  setHasEvents,
  setHasProducts,
  setCheckoutSteps,
  setTotalPrice,
  setTncEventsChecked,
  setTncProductsChecked,
} = checkoutSlice.actions;

export const manageHasProductsAsync = createAsyncThunk(
  "checkout/ManageHasProducts",
  async (_, thunkAPI) => {
    try {
      const user_id = localStorage.getItem("user_id");
      const eventItemsUrl = `${BASE_URL}cart/getAllMyCartDataForEvents/${user_id}`;
      const eventItemsData = await (await fetch(eventItemsUrl)).json();

      const machineTestItemsUrl = `${BASE_URL}cart/getAllMyCartDataForMachineTestEvents/${user_id}`;
      const machineTestItemsData = await (
        await fetch(machineTestItemsUrl)
      ).json();

      if (eventItemsData.length + machineTestItemsData.length > 0) {
        thunkAPI.dispatch(setHasEvents(true));
      } else {
        thunkAPI.dispatch(setHasEvents(false));
        thunkAPI.dispatch(setTncEventsChecked(true));
      }

      thunkAPI.dispatch(getCartTotalPriceAsync());
    } catch (error) {
      console.log(error);
    }
  }
);

export const manageHasEventsAsync = createAsyncThunk(
  "checkout/ManageHasEvents",
  async (_, thunkAPI) => {
    try {
      const user_id = localStorage.getItem("user_id");
      const eventItemsUrl = `${BASE_URL}cart/getAllMyCartDataForEvents/${user_id}`;
      const eventItemsData = await (await fetch(eventItemsUrl)).json();

      const machineTestItemsUrl = `${BASE_URL}cart/getAllMyCartDataForMachineTestEvents/${user_id}`;
      const machineTestItemsData = await (
        await fetch(machineTestItemsUrl)
      ).json();

      if (eventItemsData.length + machineTestItemsData.length > 0) {
        thunkAPI.dispatch(setHasEvents(true));
      } else {
        thunkAPI.dispatch(setHasEvents(false));
        thunkAPI.dispatch(setTncEventsChecked(true));
      }

      thunkAPI.dispatch(getCartTotalPriceAsync());
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCartTotalPriceAsync = createAsyncThunk(
  "checkout/GetCartTotalPrice",
  async (_, thunkAPI) => {
    try {
      const user_id = localStorage.getItem("user_id");
      const cartTotalUrl = `${BASE_URL}cart/getCartTotalPrice/${user_id}`;
      const cartTotalData = await (await fetch(cartTotalUrl)).json();

      const productsUrl = `${BASE_URL}cart/getAllMyCartData/${user_id}`;
      const productsData = await (await fetch(productsUrl)).json();

      if (productsData.length) {
        thunkAPI.dispatch(setHasProducts(true));

        if (productsData[0].total_shipping_charges) {
          const result =
            cartTotalData?.cart_total_price -
            +productsData[0].total_shipping_charges;
          thunkAPI.dispatch(setTotalPrice(result));
          return;
        }

        thunkAPI.dispatch(setTotalPrice(cartTotalData?.cart_total_price));
      } else {
        thunkAPI.dispatch(setHasProducts(false));
        thunkAPI.dispatch(setTotalPrice(cartTotalData?.cart_total_price));
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const selectCheckoutSteps = (state) => state.checkout.checkoutSteps;
export const selectHasEvents = (state) => state.checkout.hasEvents;
export const selectHasProducts = (state) => state.checkout.hasProducts;
export const selectTotalPrice = (state) => state.checkout.totalPrice;
export const selectTncEventsChecked = (state) =>
  state.checkout.tncEventsChecked;
export const selectTncProductsChecked = (state) =>
  state.checkout.tncProductsChecked;

export default checkoutSlice.reducer;
