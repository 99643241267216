import React , { Component }from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Header from './Header.js';
import Footer from './Footer.js';

import $ from 'jquery';

// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,getLanguage,
    translate,
  } from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';


class Logout extends Component {
    
    constructor(props) {
       
      super(props);
      this.state = {
        eventinfo: {
            user_id: '',
            first_name: '',
            last_name: '',
            middle_name: '',
            email: '',
            mobile: '',
          },
        flag:0,
       
      }
     // this.handleSubmit = this.handleSubmit.bind(this);
  }

  
  notify = () => toast("Wow so easy !");
  componentDidMount() {
    document.cookie = 'auth_cookie=1; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    var key  = localStorage.getItem('key');
    localStorage.clear();


     setLanguage(key);
        setLanguageCookie('lang_key');
        localStorage.removeItem('key');
        localStorage.setItem('key', key);
        this.handleSetLanguage(key);


    var message = "You have logged out successfully.";
    localStorage.setItem('success', message);
    //setTimeout(this.props.history.push('signin'),10);
     setTimeout(this.props.history.push('/'),10);
      // var message =  localStorage.getItem('success');
      // if(message != '' && message != null ){
      //   toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});
      //   localStorage.removeItem('success');
      // }

          
  }

  componentDidUpdate(prevProps) {
    
  }

  handleSetLanguage = (key) => () => {

        if (key == 'en') {
            this.key = 'ma';
        } else {
            this.key = 'en';
        }
        setLanguage(this.key);
        setLanguageCookie('lang_key');
    };


  render() {
      
    const { posts,guestinfo,toast} = this.state  
    const { t } = this.props;
  return (
    
    <div className="App">
        <div>
        {/* <button onClick={this.notify}>Notify !</button> */}
          <ToastContainer />
        </div>
    <Header />
    
    <Footer />
    </div>
  );
}
}
Logout.propTypes = {
    t: PropTypes.func.isRequired,
  };
export default translate(Logout);
