import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backgroundEvent from './assets/images/machine-tests-bg.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import './assets/fontawesome/css/all.min.css';
import Header from './Header.js';
import Footer from './Footer.js';
import pdf from './assets/images/pdf.png';
import image from './assets/images/jpg.png';
import excel from './assets/images/excel.png';
import doc from './assets/images/doc.png';
import * as myConstClass from './constant.js';
import $ from 'jquery';
import { Base64 } from 'js-base64';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
//import { toast } from 'react-toastify';
//console.log('.push(',{body3});

class Download extends Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: '',
            eventdetails: {
                event_type: '',
                event_description: '',
            },
            guestinfo: {
                format_date: '',
                event_type: '',
                event_type_id: '',
                topic_id: '',
                sub_topic_id: '',
                event_type_name: '',
                event_id: '0',
                schedule_event_name: '',
                language_id: '',
                researchTitle: '',
                currentYear: '',
            },
            flag: 0,
            quantityValue: '1',
            posts: [],
            arrMagazine: [],
            arr_downloads: [],
            eventinfo: [],
            languages: [],
            topic: [],
            eventTypes: [],
            scheduleEvent: [],
            cart: [],
            cartEvent: [],
            removeFromCart: [],
            eventdetails: [],
            month: [],
            year: [],
            types: []
        }
        this.applyFilters = this.applyFilters.bind(this);
        this.showMore = this.showMore.bind(this);
        this.keyEnterPress = this.keyEnterPress.bind(this);
    }

    keyEnterPress(e) {
        if (e.which === 13) {
            $('.m-auto').click();
            e.preventDefault();
        }
    }

    showMore(e) {
        e.preventDefault();
        var currentlimit = $('#limit').val();
        var newlimit = parseInt(currentlimit) + 5;
        this.state.limit = newlimit
        // $('#limit').val(newlimit);
        this.applyFilters(e);
    }

    componentDidMount() {
        this.state.limit = 5;
        var current_language = getLanguage();
        localStorage.setItem('current_language', current_language);
        var currentTime = new Date();
        var year = currentTime.getFullYear();
        this.state.guestinfo.currentYear = year;
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }
        var user_id = localStorage.getItem('user_id');
        const answer_array = this.props.location.pathname.split('/');
        var research = answer_array['2'];

        // fetch(myConstClass.BASE_URL + 'download/getDownloadFilesFrontEnd' + '/' + getLanguage())
        fetch(`${myConstClass.NodeAPI}?language=${getLanguage()}`)
            .then(response => response.json())
            .then(response => {
                this.setState({ arr_downloads: response })
                // console.log('gallery: ', response.length, response);
                var total = this.state.arr_downloads.length;
                if (total < 1) {
                    $('#notAvailable').css('display', 'inline');
                    $('#showMoreBtn').css('display', 'none');
                } else {
                    $('#notAvailable').css('display', 'none');
                }
                if (total > 0) {
                    var total_downloads = this.state.arr_downloads[0].total_downloads;
                    var limit = this.state.limit;
                    // alert(total_downloads);
                    if (total_downloads <= limit) {
                        $('#showMoreBtn').css('display', 'none')
                    } else {
                        $('#showMoreBtn').css('display', 'inline')
                    }
                }
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
            })
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ arrMagazine: response }))
            .catch(err => console.log(err))
            .catch(err => console.log(err))
    }

    componentDidUpdate(prevProps) {
        const { flag } = this.state;
        var current_language = localStorage.getItem('current_language');

        if (current_language != getLanguage() && flag != 1 && current_language != null) {
            //alert(current_language);
            localStorage.removeItem('current_language');
            localStorage.setItem('current_language', getLanguage());
            this.setState({ flag: 1 });
        }
        if (getLanguage() == 'ma' && flag == 1) {
            this.setState({ flag: 0 })
            var user_id = localStorage.getItem('user_id');
            const answer_array = this.props.location.pathname.split('/');

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrMagazine: response }))
                .catch(err => console.log(err))
                .catch(err => console.log(err))

            var search_keyword = $('#search_keyword').val();
            if (search_keyword == '' || search_keyword == undefined) {
                // search_keyword = 0;
                search_keyword = '';
            }
            var filetype = $('#filetype').val();
            if (filetype == '' || filetype == undefined) {
                // filetype = 0;
                filetype = '1';
            }
            var limit = this.state.limit;

            // fetch(myConstClass.BASE_URL + 'download/getDownloadFilesFrontEnd' + '/' + getLanguage() + '/' + limit + '/' + Base64.encode(search_keyword) + '/' + filetype)
            fetch(`${myConstClass.NodeAPI}?language=${getLanguage()}&limit=${limit}&search_input=${search_keyword}&type=${filetype}`)
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_downloads: response })
                    var total = this.state.arr_downloads.length;
                    if (total < 1) {
                        $('#notAvailable').css('display', 'inline');
                        $('#showMoreBtn').css('display', 'none');
                    } else {
                        $('#notAvailable').css('display', 'none');
                    }
                    if (total > 0) {
                        var total_downloads = this.state.arr_downloads[0].total_downloads;
                        // alert(total_downloads);
                        var limit = this.state.limit;
                        if (total_downloads <= limit) {
                            $('#showMoreBtn').css('display', 'none')
                        } else {
                            $('#showMoreBtn').css('display', 'inline')
                        }
                    }
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                })
        }

        if (getLanguage() == 'en' && (flag == 1)) {
            this.setState({ flag: 0 })
            var user_id = localStorage.getItem('user_id');
            const answer_array = this.props.location.pathname.split('/');
            var research = answer_array['2'];

            fetch(myConstClass.BASE_URL + 'magazine/getMagazineTitlesFrontEnd' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ arrMagazine: response }))
                .catch(err => console.log(err))
                .catch(err => console.log(err))

            var search_keyword = $('#search_keyword').val();
            if (search_keyword == '' || search_keyword == undefined) {
                // search_keyword = 0;
                search_keyword = '';
            }
            var filetype = $('#filetype').val();
            if (filetype == '' || filetype == undefined) {
                // filetype = 0;
                filetype = '1';
            }
            //  alert(filetype);
            var limit = this.state.limit;

            // fetch(myConstClass.BASE_URL + 'download/getDownloadFilesFrontEnd' + '/' + getLanguage() + '/' + limit + '/' + Base64.encode(search_keyword) + '/' + filetype)
            fetch(`${myConstClass.NodeAPI}?language=${getLanguage()}&limit=${limit}&search_input=${search_keyword}&type=${filetype}`)
                .then(response => response.json())
                .then(response => {
                    this.setState({ arr_downloads: response })
                    var total = this.state.arr_downloads.length;
                    if (total < 1) {
                        $('#notAvailable').css('display', 'inline');
                        $('#showMoreBtn').css('display', 'none');
                    } else {
                        $('#notAvailable').css('display', 'none');
                    }
                    if (total > 0) {
                        var total_downloads = this.state.arr_downloads[0].total_downloads;
                        // alert(total_downloads);
                        var limit = this.state.limit;
                        if (total_downloads <= limit) {
                            $('#showMoreBtn').css('display', 'none')
                        } else {
                            $('#showMoreBtn').css('display', 'inline')
                        }
                    }
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                })
        }
    }

    applyFilters(e) {
        e.preventDefault();
        const answer_array = this.props.location.pathname.split('/');
        var search_keyword = $('#search_keyword').val();
        if (search_keyword == '' || search_keyword == undefined) {
            search_keyword = '';
        }
        var filetype = $('#filetype').val();
        if (filetype == '' || filetype == undefined) {
            filetype = '1';
        }
        var limit = this.state.limit;
        // console.log({ 'language': getLanguage(), 'limit': limit, 'search_input': search_keyword, 'filetype': filetype });

        // fetch(myConstClass.BASE_URL + 'download/getDownloadFilesFrontEnd' + '/' + getLanguage() + '/' + limit + '/' + Base64.encode(search_keyword) + '/' + filetype)
        fetch(`${myConstClass.NodeAPI}?language=${getLanguage()}&limit=${limit}&search_input=${search_keyword}&type=${filetype}`)
            .then(response => response.json())
            .then(response => {
                this.setState({ arr_downloads: response });
                console.log('response: ', response);
                var total = this.state.arr_downloads.length;
                if (total < 1) {
                    $('#notAvailable').css('display', 'inline');
                    $('#showMoreBtn').css('display', 'none');
                } else {
                    $('#notAvailable').css('display', 'none');
                }
                if (total > 0) {
                    var total_downloads = this.state.arr_downloads[0].total_downloads;
                    // alert(total_downloads);
                    var limit = this.state.limit;
                    if (total_downloads <= limit) {
                        $('#showMoreBtn').css('display', 'none')
                    } else {
                        $('#showMoreBtn').css('display', 'inline')
                    }
                }
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
            })
    }

    renderType = ({ id, title }) => <div className="row">
        <div className="col">
            <div className="col pt-3">
                <h5 className="font-weight-bold">{title}</h5>
            </div>
        </div>
    </div>

    renderGuest = ({ type_id, title, price, quantity }) =>
        <div><div className="row"><div className="col-md-3 mb-3">
            <div className="col border text-center">
                <div className=" py-3">
                    <img src={require("./assets/images/product1.png")} />
                    <div className="my-2">
                        <span className="mr-2">
                            <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                        </span>
                    </div>
                    <p className="font-weight-bold mb-1">{title}</p>
                    <p className="mb-1">Donation Contribution</p>
                    <p className="font-weight-bolder">INR. {price}/-</p>
                    <div className="qty mb-3">
                        <span>Qty-</span>
                        <span className="minus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-minus"></i>
                            </span>
                        </span>
                        <input type="number" className="count" name="qty" value={quantity} />
                        <span className="plus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-plus"></i>
                            </span>
                        </span>
                    </div>
                    <button className="btn btn-brown btn-sm">ADD TO CART</button>
                </div>
            </div></div></div></div>

    render() {
        const { month, year, arrMagazine, arr_downloads, cartEvent, eventdetails, scheduleEvent, eventTypes, posts, types, languages, topic, guestinfo } = this.state
        const { t } = this.props;
        return (
            <div className="App">
                <div>
                    <ToastContainer />
                </div>
                <Header />
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-10  ml-2" id="about_nav_bar">
                            <div class="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb" id="breadcrumb">
                                    <ol class="breadcrumb bg-white pb-0 mb-0 ml-n2 pl-2">
                                        <li class="breadcrumb-item ">
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li class="breadcrumb-item active" aria-current="page"><a href="#">{t('home.Download File')}</a></li>
                                    </ol>
                                    <p>{t('home.Download File')}</p>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-9 col-md-9 col-sm-12 ">
                            <div class="row">
                                <div class="col-lg-8 col-md-11 col-sm-12 ">
                                    <form>
                                        <div class="form-row">
                                            <div class="col-lg-6 col-md-6 col-sm-12 ">
                                                <label for="exampleFormControlSelect1"><b>{t('home.Search Download')}</b></label>
                                                <input id="search_keyword" type="text" class="form-control" placeholder={t('home.Enter Keywords')} onKeyPress={this.keyEnterPress} />
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-6">
                                                <label for="exampleFormControlSelect1"><b>{t('home.File Type')}</b></label>
                                                <select id="filetype" name="filetype" class="form-control">
                                                    <option value="">{t('home.All')}</option>
                                                    <option value="1">{t('home.PDF')}</option>
                                                    <option value="2">{t('home.jpeg')}</option>
                                                    <option value="3">{t('home.XLS')}</option>
                                                    <option value="4">{t('home.Document')}</option>
                                                </select>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-6 downloadSearchButton">
                                                <label for="exampleFormControlSelect1" class="hide_element text-white">{t('home.File')}</label>
                                                <button type="button" class="btn btn-outline-brown m-auto " onClick={this.applyFilters}>{t('home.Search Download')}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="row pt-2">
                                <div class="col-12">
                                    {arr_downloads.map((downloads, i) => {
                                        return (
                                            <div class="border border-dark p-2 mb-2">
                                                <div class="row">
                                                    <div className="col">
                                                        <h5>{downloads.title}</h5>
                                                    </div>
                                                </div>
                                                {downloads.Download_Files.map((files, ii) => {
                                                // {downloads.arr_files.map((files, ii) => {
                                                    if (files.type == 1) {
                                                        return (
                                                            <div class="row mb-3">
                                                                <div class="col-xl-10 col-lg-10 col-8  align-self-center pt-8 text-break">
                                                                    <img src={pdf} width="23px" />&nbsp;&nbsp;{files.filename}
                                                                </div>
                                                                <div class="col-xl-2 col-lg-2 col-4">
                                                                    <a target="_blank" href={files.image_name} download={files.image_name}>
                                                                        <button class="btn btn-brown btn-sm">{t('home.Download')}</button>
                                                                    </a>
                                                                </div>

                                                            </div>
                                                        )
                                                    } else if (files.type == 2) {
                                                        return (
                                                            <div class="row mb-3">
                                                                <div class="col-xl-10 col-lg-10 col-8 align-self-center pt-8 text-break">
                                                                    <img src={image} style={{ width: '26px', height: '41px' }} width="24px" /> &nbsp;&nbsp;
                                                                    {files.filename}
                                                                </div>
                                                                <div class="col-xl-2 col-lg-2 col-4">
                                                                    <a href="" download={files.image_name}>
                                                                        <button type="submit" class="btn btn-brown btn-sm" download>{t('home.Download')}</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                    } else if (files.type == 3) {
                                                        return (
                                                            <div class="row mb-3">
                                                                <div class="col-xl-10 col-lg-10 col-8 align-self-center pt-8 text-break">
                                                                    <img src={excel} style={{ width: '26px', height: '41px' }} /> &nbsp;&nbsp;
                                                                    {files.filename}
                                                                </div>
                                                                <div class="col-xl-2 col-lg-2 col-4">
                                                                    <a target="_blank" href={files.image_name}>
                                                                        <button class="btn btn-brown btn-sm">{t('home.Download')}</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div class="row mb-3">
                                                                <div class="col-xl-10 col-lg-10 col-8 align-self-center pt-8 text-break">
                                                                <img src={doc} style={{ width: '26px', height: '34px' }} />&nbsp;&nbsp;
                                                                    {files.filename}
                                                                </div>
                                                                <div class="col-xl-2 col-lg-2 col-4">
                                                                    <a target="_blank" href={files.image_name}>
                                                                        <button class="btn btn-brown btn-sm">{t('home.Download')}</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        )
                                    })}

                                    <div class="row mt-4">
                                        <button type="button" id="showMoreBtn" class="btn btn-brown btn-sm mx-auto mt-3" onClick={this.showMore}>{t('home.Show More')}</button>
                                        <input type="hidden" name="limit" id="limit" value={this.state.limit} />
                                        <p id="notAvailable" style={{ display: 'none', marginLeft: '50%' }}>
                                            {t('home.Not available')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12 magazineListingMagazine">
                            {arrMagazine.map((cell, i) => {
                                if (cell.magazine_id == 2) {
                                    return (
                                        <div class="col py-2 border border-warning bg-white box-shadow mb-3">
                                            <div class="row d-flex">
                                                <div class="col-12">
                                                    <strong>
                                                        {cell.magazine_title}
                                                    </strong>
                                                    <p>
                                                        {ReactHtmlParser(cell.description)}
                                                    </p>
                                                    <p>
                                                        <strong>{t('home.Language')}:</strong>
                                                        {cell.language}
                                                    </p>
                                                    <p>
                                                        {cell.images_array.map((info, ii) => {
                                                            if (ii == 0) {
                                                                if (info.issue_id > 0 && info.issue_id != undefined) {
                                                                    return (
                                                                        <Link to={'/magazine_details/' + info.magazine_id + '/' + info.issue_id}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Link to={'/magazine'}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>
                                                                    )
                                                                }
                                                            } else {
                                                                return (
                                                                    <Link to={'/magazine'}>
                                                                        <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                    </Link>
                                                                )
                                                            }
                                                        }
                                                        )}
                                                        <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight btn-block" to={'/magazine-subscription/' + cell.magazine_id}>{t('home.SUBSCRIBE')}</Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div class="col py-2 border border-warning bg-white box-shadow mb-3">
                                            <div class="row d-flex">
                                                <div class="col-12">
                                                    <strong>
                                                        {cell.magazine_title}
                                                    </strong>
                                                    <p>
                                                        {ReactHtmlParser(cell.description)}
                                                    </p>
                                                    <p>
                                                        <strong>{t('home.Language')}:</strong>
                                                        {cell.language}
                                                    </p>
                                                    <p>
                                                        {cell.images_array.map((info, ii) => {
                                                            if (ii == 0) {
                                                                if (info.product_id > 0) {
                                                                    return (
                                                                        <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            <p >
                                                                                <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight btn-block" to={'/products_details/' + info.title + '/' + info.product_id}>{t('home.BUY NOW')}</Link>
                                                                            </p>
                                                                        </Link>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Link to={'/products'}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                            <p >
                                                                                <Link class="btn btn-brown btn-sm mb-2 magazineImageButtonRight btn-block" to={'/products'}>{t('home.BUY NOW')}</Link>
                                                                            </p>
                                                                        </Link>
                                                                    )
                                                                }
                                                            } else {
                                                                if (info.product_id > 0) {
                                                                    return (
                                                                        <Link to={'/products_details/' + info.title + '/' + info.product_id}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Link to={'/products'}>
                                                                            <img className="mb-2 magazineImageRight" src={info.image_name} />
                                                                        </Link>
                                                                    )
                                                                }
                                                            }
                                                        }
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

Download.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Download);
