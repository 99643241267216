import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./FixedBottomSummary.css";
import { useSelector } from "react-redux";
import { selectHasProducts } from "../store/checkoutSlice";

export default function FixedBottomSummary({ totalPrice = 0, children }) {
  const history = useHistory();
  const hasProducts = useSelector(selectHasProducts);

  function handleBack() {
    history.goBack();
  }

  const location = useLocation();
  const isCartPage = location.pathname === "/mycart";

  return (
    <div id="bottom-summary" className="">
      <div className="bg-white container rounded-lg shadow pt-2">
        <div className="d-block d-md-none d-flex justify-content-between">
          <div>
            <div>
              <strong>Total Donation Contribution</strong>
            </div>
            {hasProducts && (
              <div>
                <small>{`(Without Shipping Charges)`}</small>
              </div>
            )}
          </div>
          <div className="h1 text-nowrap">
            <b>₹ {totalPrice}</b>
          </div>
        </div>

        <div className="py-2 d-flex justify-content-between align-items-center">
          <div>
            <button
              className={
                "w-100 btn py-2 proceed-button text-nowrap " +
                (isCartPage ? "d-none" : "d-inline")
              }
              onClick={handleBack}
            >
              <i className="fas fa-chevron-left fw-bold"></i>{" "}
              <span className="ml-1 fw-bold">Back</span>
            </button>
          </div>

          <div className="d-flex align-items-center">
            <div className="d-none d-md-block mobile-content-container mr-5 mobile-center">
              <div>
                <strong>Total Donation Contribution</strong>
              </div>
              {hasProducts && (
                <div>
                  <small>{`(Without Shipping Charges)`}</small>
                </div>
              )}
              <div className="h1">
                <b>₹ {totalPrice}</b>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
