import React, { useEffect } from "react";
import { getLanguage } from "react-switch-lang";
import { useDispatch, useSelector } from "react-redux";
import { selectEventCategories } from "../store/categorySlice";
import { BASE_URL } from "../constant";
import { Link } from "react-router-dom";
import { setHasEvents } from "../store/checkoutSlice";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { routes } from "../routes.constants";

export default function EventTable({
  eventItems = [],
  setEventItems,
  onDataChange,
}) {
  const dispatch = useDispatch();
  const eventCategories = useSelector(selectEventCategories);

  useEffect(() => {
    // TODO: This isn't working with eventItems change, maybe change was not a deep copy...
    updateCheckoutSteps();
  }, [eventItems]);

  // const [removeKey, setRemoveKey] = useState("0");

  function updateCheckoutSteps(externalEventItems = null) {
    const _events = externalEventItems ?? eventItems;
    if (_events.length > 0) {
      dispatch(setHasEvents(true));
    } else {
      dispatch(setHasEvents(false));
    }
  }

  async function handleRemoveEvent({ event_type_id, event_id, schedule_id }) {
    const user_id = localStorage.getItem("user_id");

    let are_you_sure = "Are you sure?";
    let revert = "Do you want to remove the item from the cart ?";
    let deleteConfirm = "Yes, delete it!";
    let cancel = "Cancel";
    if (getLanguage() == "ma") {
      are_you_sure = "तुम्हाला खात्री आहे?";
      revert = "आपण कार्टमधून वस्तू/उपक्रम काढून टाकू इच्छिता ?";
      deleteConfirm = "होय, काढून टाका";
      cancel = "नाही";
    }

    try {
      const result = await Swal.fire({
        title: are_you_sure,
        text: revert,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: cancel,
        confirmButtonText: deleteConfirm,
      });

      if (!result.value) return null;

      let removeUrl = `${BASE_URL}cart/removeProductFromCart/${event_id}/${user_id}`;

      if (+event_type_id === 3) {
        removeUrl = `${BASE_URL}cart/removeProductFromCart/${event_id}/${user_id}`;
      } else {
        removeUrl = `${BASE_URL}cart/removeProductFromCart/${schedule_id}/${user_id}`;
      }

      console.log({ event_type_id, event_id, schedule_id }, removeUrl);

      const removeReqData = await (await fetch(removeUrl)).json();

      if (removeReqData?.status) {
        toast("" + removeReqData?.message, {
          containerId: "B",
          type: toast.TYPE.SUCCESS,
        });

        const eventListUrl = `${BASE_URL}cart/getAllMyCartDataForEvents/${user_id}/${getLanguage()}`;
        const eventReqData = await (await fetch(eventListUrl)).json();

        const machineEventListUrl = `${BASE_URL}cart/getAllMyCartDataForMachineTestEvents/${user_id}/${getLanguage()}`;
        const machineEventReqData = await (
          await fetch(machineEventListUrl)
        ).json();

        setEventItems([...eventReqData, ...machineEventReqData]);

        updateCheckoutSteps(eventReqData);

        onDataChange();
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {eventItems?.length > 0 && (
        <>
          <div className="card rounded-lg mb-2">
            <h4 className="d-block d-md-none card-header">
              <strong>Event Details</strong>
            </h4>
            <div class="d-none d-md-block text-white rounded-top bg-accent">
              <div className="row no-gutters align-items-end m-3">
                <div className="col-6 h6 mb-1">EVENT DETAILS</div>
                <div className="col-2 text-center">
                  <small>PARTICIPANTS</small>
                </div>
                <div className="col-3 text-center">
                  <small>AMOUNT</small>
                </div>
                <div className="col-1 p-0"></div>
              </div>
            </div>
            <div className="overflow-hidden">
              {eventItems.map((evs) => (
                <>
                  <div>
                    {evs?.arr_cart.map((ev) => (
                      <>
                        <div className="row no-gutters border-bottom p-3">
                          <div className="col-12 col-md-6 mb-1">
                            <h5>{ev?.title}</h5>
                            <small className="d-block">
                              <strong>{ev?.venue_address}</strong>
                            </small>
                            <small className="d-block mt-2 fst-italic">
                              <b>{evs?.event_type}</b>
                            </small>
                          </div>
                          <div className="col-4 col-md-2 d-flex justify-content-center align-items-center">
                            <span className="m-1 d-inline rounded form-control text-monospace text-center">
                              {+ev?.parent + +ev?.child}
                            </span>
                          </div>
                          <div className="col-6 col-md-3 d-flex justify-content-center align-items-center">
                            <h5>
                              <strong>
                                ₹{" "}
                                {+(ev?.first_price * ev?.parent) +
                                  +(ev?.second_price * ev?.child)}
                              </strong>
                            </h5>
                          </div>
                          <div className="col-2 order-4 col-md-1 d-flex justify-content-center align-items-center">
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => {
                                console.log(
                                  ev?.event_id,
                                  ev?.schedule_id,
                                  evs?.event_type_id
                                );

                                handleRemoveEvent({
                                  event_type_id: evs?.event_type_id,
                                  event_id: ev?.event_id,
                                  schedule_id: ev?.schedule_id,
                                });
                              }}
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="add-more-div mb-4">
            <Link
              to={routes.eventsCalendar}
              className="btn btn-outline-success w-100"
            >
              <i className="fas fa-plus me-4"></i>
              <span className="ms-4 ml-4">Add More Events</span>
            </Link>
          </div>
        </>
      )}
    </>
  );
}
