// Mycart copy.js
// ~~~~~~~~~~~~~~~~~~~~~~~~~~ TIGMANSHU'S CODE ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, Redirect } from "react-router-dom";
import { getLanguage } from "react-switch-lang";
import { ToastContainer } from "react-toastify";
import Footer from "./Footer.js";
import Header from "./Header.js";
import Stepper from "./Stepper";
import EventTable from "./components/_EventTable";
import FixedBottomSummary from "./components/_FixedBottomSummary";
import MagazineTable from "./components/_MagazineTable";
import ProductTable from "./components/_ProductTable";
import * as myConstClass from "./constant.js";
import { BASE_URL } from "./constant.js";
import { routes } from "./routes.constants";
import {
  getCartTotalPriceAsync,
  selectCheckoutSteps,
  selectTotalPrice,
  setHasEvents,
  setHasProducts,
  stepsEnum,
} from "./store/checkoutSlice";
import "./yourCart.css";

const Mycart = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const checkoutSteps = useSelector(selectCheckoutSteps);
  const totalPrice = useSelector(selectTotalPrice);

  const [cartItems, setCartItems] = useState([]);
  const [eventItems, setEventItems] = useState([]);
  const [magazineItems, setMagazineItems] = useState([]);

  useEffect(() => {
    // console.log(localStorage.getItem("user_id"))
    const user_id = localStorage.getItem("user_id");
    if (user_id) {
      localStorage.removeItem("error");
    } else {
      localStorage.setItem("error", "please login first");
      return history.replace('/signin')
    }
  }, [])

  useEffect(() => {
    dispatch(getCartTotalPriceAsync());
    dispatch(getCartTotalPriceAsync());
    getCartItemsCount();
    fetchData();
  }, [dispatch]);

  async function getCartItemsCount() {
    try {
      const user_id = localStorage.getItem("user_id");
      const cartCountUrl = `${myConstClass.BASE_URL}cart/getTotalCartCount/${user_id}`;
      const cartCountData = await (await fetch(cartCountUrl)).json();

      const count = cartCountData.totalcartcount;

      $(".lblCartCount").html(count);
      if (+count === 0) {
        $("#ContinueToCheckoutMainDiv").css("display", "none");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchProducts() {
    try {
      const user_id = localStorage.getItem("user_id");
      const produtsUrl = `${BASE_URL}cart/getAllMyCartData/${user_id}/${getLanguage()}`;
      const productsData = await (await fetch(produtsUrl)).json();

      if (productsData.length) {
        dispatch(setHasProducts(true));
      } else {
        dispatch(setHasEvents(false));
      }

      setCartItems(productsData);
      return productsData;
    } catch (error) {
      console.log("fetchProducts", error);
    }
  }

  async function fetchEvents() {
    try {
      const user_id = localStorage.getItem("user_id");
      const eventItemsUrl = `${BASE_URL}cart/getAllMyCartDataForEvents/${user_id}/${getLanguage()}`;
      // const eventItemsData = await (await fetch(eventItemsUrl)).json();
      const machineTestItemsUrl = `${BASE_URL}cart/getAllMyCartDataForMachineTestEvents/${user_id}/${getLanguage()}`;
      // const machineTestItemsData = await (
      //   await fetch(machineTestItemsUrl)
      // ).json();

      const [eventItemsData, machineTestItemsData] = await Promise.all([
        (await fetch(eventItemsUrl)).json(),
        (await fetch(machineTestItemsUrl)).json(),
      ]);

      if (eventItemsData.length + machineTestItemsData.length > 0) {
        dispatch(setHasEvents(true));
      } else {
        dispatch(setHasEvents(false));
      }

      setEventItems([...eventItemsData, ...machineTestItemsData]);
      return [...eventItemsData, ...machineTestItemsData];
    } catch (error) {
      console.log("fetchEvents", error);
    }
  }

  async function fetchMagazines() {
    try {
      const user_id = localStorage.getItem("user_id");
      const magazinesUrl = `${BASE_URL}cart/getAllMyCartDataForMagazine/${user_id}/${getLanguage()}`;
      const magazinesData = await (await fetch(magazinesUrl)).json();

      if (magazinesData.length) {
      }

      setMagazineItems(magazinesData);
      return magazinesData;
    } catch (error) {
      console.log("fetchMagazines", error);
    }
  }

  async function fetchData() {
    try {
      const [events, products, magazines] = await Promise.all([
        fetchEvents(),
        fetchProducts(),
        fetchMagazines(),
      ]);
    } catch (error) {
      console.log("fetchData", error);
    }
  }

  const handleProceed = () => {
    if (eventItems.length > 0) {
      history.push("/eventDetails");
    } else {
      history.push("/address");
      // window.location.href = "/address";
    }
  };

  return (
    <>
      <ToastContainer />
      <Header />

      <div className="mt-4 pt-2 mt-sm-4 pt-sm-4 mt-md-4 pt-md-4 mt-xl-1">
        <Stepper steps={checkoutSteps} currentStep={stepsEnum[0]} />
      </div>
      <div className="container">
        <EventTable
          eventItems={eventItems}
          setEventItems={setEventItems}
          onDataChange={() => {
            dispatch(getCartTotalPriceAsync());
            getCartItemsCount();
          }}
        />

        <ProductTable
          cartItems={cartItems}
          setCartItems={setCartItems}
          onDataChange={() => {
            dispatch(getCartTotalPriceAsync());
            getCartItemsCount();
          }}
        />

        <MagazineTable
          magazineItems={magazineItems}
          setMagazineItems={setMagazineItems}
          onDataChange={() => {
            dispatch(getCartTotalPriceAsync());
            getCartItemsCount();
          }}
        />
      </div>
      {eventItems?.length > 0 ||
      cartItems?.length > 0 ||
      magazineItems?.length > 0 ? (
        <>
          <div className="container my-4 px-3 flex-wrap">
            <div className="row align-items-center">
              <div className="pl-5 col-12 col-md-9"></div>
            </div>
          </div>

          <FixedBottomSummary totalPrice={totalPrice}>
            <div>
              <button
                className="w-100 btn py-2 proceed-button text-nowrap"
                onClick={handleProceed}
              >
                <span className="mr-1 fw-bold">Next</span>{" "}
                <i className="fas fa-chevron-right fw-bold"></i>
              </button>
            </div>
          </FixedBottomSummary>
        </>
      ) : (
        <>
          <div className="container my-4 px-3 flex-wrap">
            Your Cart is Currently Empty. Add Some{" "}
            <Link to={routes.eventsCalendar}>Events</Link> or{" "}
            <Link to={routes.moreProducts}>Products</Link> and visit here to
            Checkout
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

export default Mycart;
