import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  fetchCitiesByState,
  fetchCountries,
  fetchShippingAddressById,
  fetchStatesByCountry,
} from "./utils";
import { hasError, validateField, validateMessages } from "./validation";

// const ShippingFieldsEnum = {
//   shipping_first_name: "shipping_first_name",
//   shipping_middle_name: "shipping_middle_name",
//   shipping_last_name: "shipping_last_name",
//   shipping_company: "shipping_company",
//   shipping_address_line_1: "shipping_address_line_1",
//   shipping_address_line_2: "shipping_address_line_2",
//   shipping_landmark: "shipping_landmark",
//   shipping_country: "shipping_country",
//   shipping_state: "shipping_state",
//   shipping_city: "shipping_city",
//   shipping_pincode: "shipping_pincode",
//   shipping_phone: "shipping_phone",
//   shipping_email: "shipping_email",
// };
// const sfe = ShippingFieldsEnum;

// const shippingInitialState = {
//   shipping_first_name: "",
//   shipping_middle_name: "",
//   shipping_last_name: "",
//   shipping_company: "",
//   shipping_address_line_1: "",
//   shipping_address_line_2: "",
//   shipping_landmark: "",
//   shipping_country: "",
//   shipping_state: "",
//   shipping_city: "",
//   shipping_pincode: "",
//   shipping_phone: "",
//   shipping_email: "",
// };

const ShippingFieldsEnum = {
  shipping_first_name: "first_name",
  shipping_middle_name: "middle_name",
  shipping_last_name: "last_name",
  shipping_company: "company",
  shipping_address_line_1: "address_line_1",
  shipping_address_line_2: "address_line_2",
  shipping_landmark: "landmark",
  shipping_country: "country",
  shipping_country_id: "country_id",
  shipping_country_id_fk: "country_id_fk",
  shipping_state: "state",
  shipping_state_id: "state_id",
  shipping_state_id_fk: "state_id_fk",
  shipping_city: "city",
  shipping_city_id: "city_id",
  shipping_city_id_fk: "city_id_fk",
  shipping_pincode: "pincode",
  shipping_phone: "phone",
  shipping_email: "email",
};
const sfe = ShippingFieldsEnum;
const initialState = {
  first_name: "",
  middle_name: "",
  last_name: "",
  company: "",
  address_line_1: "",
  address_line_2: "",
  landmark: "",
  country: "",
  country_id: "",
  country_id_fk: "",
  state: "",
  state_id_fk: "",
  city: "",
  city_id_fk: "",
  pincode: "",
  phone: "",
  email: "",
};

const ShippingAddressForm = forwardRef(function ShippingAddressForm(
  { shippingAddress, onTnCCheck, onFormValueChange, onSameAsShipping },
  ref
) {
  const [state, setState] = useState({ ...initialState });
  const [errors, setErrors] = useState({ ...initialState });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [tnc, setTnC] = useState(true);
  const [sameAsShipping, setSameAsShipping] = useState(true);

  useEffect(() => {
    if (sameAsShipping) onSameAsShipping(sameAsShipping, null);
    (async () => {
      const [_shippingAddress, _countries] = await Promise.all([
        fetchShippingAddressById(),
        fetchCountries(),
      ]);
      setCountries(_countries);

      if (!_shippingAddress) return;
      setState(_shippingAddress);
      onFormValueChange(_shippingAddress);

      const { country_id_fk, state_id_fk, city_id_fk } = _shippingAddress;
      const [_states, _cities] = await Promise.all([
        fetchStatesByCountry(country_id_fk),
        fetchCitiesByState(state_id_fk),
      ]);
      setStates(_states);
      setCities(_cities);
    })();
  }, []);

  useEffect(() => {
    setState(shippingAddress);
  }, [shippingAddress]);

  useEffect(() => {
    // setState(shippingAddress);

    (async () => {
      const [_states, _cities] = await Promise.all([
        fetchStatesByCountry(shippingAddress.country_id_fk),
        fetchCitiesByState(shippingAddress.state_id_fk),
      ]);
      setStates(_states);
      setCities(_cities);
    })();
  }, [shippingAddress.country_id_fk, shippingAddress.state_id_fk]);

  function downloadShippingTnC() {
    const url = "TnCProducts.pdf";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Terms & Conditions for Products.pdf");
    document.body.appendChild(link);
    link.click();
  }

  function handleInputOnChange(key, value) {
    const _state = { ...state, [key]: value };
    setState(_state);
    onFormValueChange(_state);
  }

  useImperativeHandle(ref, () => ({
    validate() {
      const _errors = {};
      _errors[sfe.shipping_first_name] = validateField(
        state[sfe.shipping_first_name],
        "First Name",
        {
          required: true,
          regex: {
            pattern: /^[A-Za-z]+$/,
            message: validateMessages.onlyAlphabets,
          },
        }
      );
      _errors[sfe.shipping_last_name] = validateField(
        state[sfe.shipping_last_name],
        "Last Name",
        {
          required: true,
          regex: {
            pattern: /^[A-Za-z]+$/,
            message: validateMessages.onlyAlphabets,
          },
        }
      );

      _errors[sfe.shipping_address_line_1] = validateField(
        state[sfe.shipping_address_line_1],
        "Address",
        { required: true, regex: null }
      );

      // console.log(
      //   state[sfe.shipping_country_id],
      //   state[sfe.shipping_state_id_fk],
      //   state[sfe.shipping_city_id_fk]
      // );
      _errors[sfe.shipping_country] = validateField(
        state[sfe.shipping_country_id],
        "Country",
        { required: true, regex: null }
      );
      _errors[sfe.shipping_state] = validateField(
        state[sfe.shipping_state_id_fk],
        "State",
        { required: true, regex: null }
      );
      _errors[sfe.shipping_city] = validateField(
        state[sfe.shipping_city_id_fk],
        "City",
        { required: true, regex: null }
      );

      _errors[sfe.shipping_pincode] = validateField(
        state[sfe.shipping_pincode],
        "Pincode",
        {
          required: true,
          regex: { pattern: /^\d{4,6}$/, message: validateMessages.pincode },
        }
      );
      _errors[sfe.shipping_phone] = validateField(
        state[sfe.shipping_phone],
        "Phone",
        {
          required: true,
          regex: { pattern: /^\d{10}$/, message: validateMessages.phone },
        }
      );
      _errors[sfe.shipping_email] = validateField(
        state[sfe.shipping_email],
        "Email",
        {
          required: true,
          regex: {
            // pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: validateMessages.email,
          },
        }
      );

      setErrors(_errors);
      // console.log("Shipping has errors", _errors, hasError(_errors));
      return !hasError(_errors);
    },
  }));

  return (
    <div>
      {/* <pre>{JSON.stringify({ errors }, null, 2)}</pre> */}
      <form className="row">
        <div className="col-lg-4 form-group">
          <label
            htmlFor="shipping_first_name"
            className="form-label fw-bold required"
          >
            First Name
          </label>
          <input
            className={`form-control w-100 ${
              errors.first_name ? "is-invalid" : ""
            }`}
            placeholder="Enter First Name"
            id="shipping_first_name"
            value={state.first_name}
            onChange={(e) => {
              handleInputOnChange(sfe.shipping_first_name, e.target.value);
            }}
          />
          {errors.first_name && (
            <div className="invalid-feedback">{errors.first_name}</div>
          )}
        </div>

        <div className="col-lg-4 form-group">
          <label htmlFor="shipping_middle_name" className="form-label fw-bold">
            Middle Name
          </label>
          <input
            className={`form-control w-100 ${
              errors.middle_name ? "is-invalid" : ""
            }`}
            placeholder="Enter Middle Name"
            id="shipping_middle_name"
            value={state.middle_name}
            onChange={(e) => {
              handleInputOnChange(sfe.shipping_middle_name, e.target.value);
            }}
          />
          {errors.middle_name && (
            <div className="invalid-feedback">{errors.middle_name}</div>
          )}
        </div>

        <div className="col-lg-4 form-group">
          <label
            htmlFor="shipping_last_name"
            className=" form-label fw-bold required"
          >
            Last Name
          </label>
          <input
            className={`form-control w-100 ${
              errors.last_name ? "is-invalid" : ""
            }`}
            placeholder="Enter Last Name"
            id="shipping_last_name"
            value={state.last_name}
            onChange={(e) => {
              handleInputOnChange(sfe.shipping_last_name, e.target.value);
            }}
          />
          {errors.last_name && (
            <div className="invalid-feedback">{errors.last_name.message}</div>
          )}
        </div>

        <div className="col-12 form-group">
          <label htmlFor="shipping_company" className=" form-label fw-bold">
            Company / Institution / Organization Name
          </label>
          <input
            className={`form-control w-100 ${
              errors.company ? "is-invalid" : ""
            }`}
            placeholder="Enter Company Name"
            id="shipping_company"
            name="Company Name"
            value={state.company}
            onChange={(e) => {
              handleInputOnChange(sfe.shipping_company, e.target.value);
            }}
          />
          {errors.company && (
            <div className="invalid-feedback">{errors.company}</div>
          )}
        </div>

        <div className="col-12 form-group">
          <label
            htmlFor="shipping_address_line_1"
            className=" form-label fw-bold required"
          >
            Address Line 1
          </label>
          <input
            className={`form-control w-100 ${
              errors.address_line_1 ? "is-invalid" : ""
            }`}
            placeholder="Enter Flat No./House No."
            id="shipping_address_line_1"
            value={state.address_line_1}
            onChange={(e) => {
              handleInputOnChange(sfe.shipping_address_line_1, e.target.value);
            }}
          />
          {errors.address_line_1 && (
            <div className="invalid-feedback">{errors.address_line_1}</div>
          )}
        </div>

        <div className="col-lg-6 form-group">
          <label
            htmlFor="shipping_address_line_2"
            className=" form-label fw-bold"
          >
            Address Line 2
          </label>
          <input
            className={`form-control w-100 ${
              errors.address_line_2 ? "is-invalid" : ""
            }`}
            placeholder="Enter Apartment/Society Name"
            id="shipping_address_line_2"
            value={state.address_line_2}
            onChange={(e) => {
              handleInputOnChange(sfe.shipping_address_line_2, e.target.value);
            }}
          />
          {errors.address_line_2 && (
            <div className="invalid-feedback">
              {errors.address_line_2.message}
            </div>
          )}
        </div>

        <div className="col-lg-6 form-group">
          <label htmlFor="shipping_landmark" className=" form-label fw-bold">
            Landmark
          </label>
          <input
            id="shipping_landmark"
            className={`form-control w-100 ${
              errors.landmark ? "is-invalid" : ""
            }`}
            placeholder="Enter Nearest Landmark"
            value={state.landmark}
            onChange={(e) => {
              handleInputOnChange(sfe.shipping_landmark, e.target.value);
            }}
          />
          {errors.landmark && (
            <div className="invalid-feedback">{errors.landmark}</div>
          )}
        </div>

        <div className="col-lg-6 form-group">
          <label
            htmlFor="shipping_country"
            className="form-label fw-bold required"
          >
            Country
          </label>
          <select
            className={`form-control w-100 ${
              errors.country ? "is-invalid" : ""
            }`}
            id="shipping_country"
            value={state?.country_id}
            onChange={(e) => {
              const _state = {
                ...state,
                [sfe.shipping_country_id]: e.target.value,
                [sfe.shipping_country_id_fk]: e.target.value,
                [sfe.shipping_country]: countries?.find(
                  (el) => el.id === e.target.value
                )?.name,

                [sfe.shipping_state_id]: "",
                [sfe.shipping_state_id_fk]: "",
                [sfe.shipping_state]: "",

                [sfe.shipping_city_id]: "",
                [sfe.shipping_city_id_fk]: "",
                [sfe.shipping_city]: "",
              };

              setState(_state);
              onFormValueChange(_state);

              fetchStatesByCountry(e.target.value).then((_states) => {
                setStates(_states);
              });
            }}
          >
            <option value="">Select Country</option>
            {countries?.map((country) => (
              <option key={country.id} value={country.id}>
                {country.name}
              </option>
            ))}
          </select>
          {errors.country && (
            <div className="invalid-feedback">{errors.country}</div>
          )}
        </div>

        <div className="col-lg-6 form-group">
          <label
            htmlFor="shipping_state"
            className="form-label fw-bold required"
          >
            State / Province
          </label>
          <select
            className={`form-control w-100 ${errors.state ? "is-invalid" : ""}`}
            id="shipping_state"
            value={state?.state_id_fk}
            onChange={(e) => {
              // handleInputOnChange(sfe.shipping_state, e.target.value);
              // handleInputOnChange(sfe.shipping_state_id, e.target.value);
              // handleInputOnChange(sfe.shipping_state_id_fk, e.target.value);
              const _state = {
                ...state,
                [sfe.shipping_state_id]: e.target.value,
                [sfe.shipping_state_id_fk]: e.target.value,
                [sfe.shipping_state]: states?.find(
                  (el) => el.id === e.target.value
                )?.name,

                [sfe.shipping_city_id]: "",
                [sfe.shipping_city_id_fk]: "",
                [sfe.shipping_city]: "",
              };

              setState(_state);
              onFormValueChange(_state);

              fetchCitiesByState(e.target.value).then((_cities) => {
                setCities(_cities);
              });
            }}
          >
            <option value="">Select State</option>
            {states?.map((state) => (
              <option key={state.id} value={state.id}>
                {state.name}
              </option>
            ))}
          </select>
          {errors.state && (
            <div className="invalid-feedback">{errors.state}</div>
          )}
        </div>

        <div className="col-lg-6 form-group">
          <label
            htmlFor="shipping_city"
            className="form-label fw-bold required"
          >
            City
          </label>
          <select
            className={`form-control w-100 ${errors.city ? "is-invalid" : ""}`}
            id="shipping_city"
            value={state?.city_id_fk}
            onChange={(e) => {
              const _state = {
                ...state,
                [sfe.shipping_city_id]: e.target.value,
                [sfe.shipping_city_id_fk]: e.target.value,
                [sfe.shipping_city]: states?.find(
                  (el) => el.id === e.target.value
                )?.name,
              };

              setState(_state);
              onFormValueChange(_state);
            }}
          >
            <option value="">Select City</option>
            {cities?.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
          {errors.city && <div className="invalid-feedback">{errors.city}</div>}
        </div>

        <div className="col-lg-6 form-group">
          <label
            htmlFor="shipping_pincode"
            className=" form-label fw-bold required"
          >
            Pincode
          </label>
          <input
            className={`form-control w-100 ${
              errors.pincode ? "is-invalid" : ""
            }`}
            minLength={4}
            maxLength={6}
            placeholder="Enter Pincode"
            id="shipping_pincode"
            value={state.pincode}
            onChange={(e) => {
              handleInputOnChange(sfe.shipping_pincode, e.target.value);
            }}
          />
          {errors.pincode && (
            <div className="invalid-feedback">{errors.pincode}</div>
          )}
        </div>

        <div className="col-lg-6 form-group">
          <label
            htmlFor="shipping_phone"
            className=" form-label fw-bold required"
          >
            Phone Number
          </label>
          <input
            className={`form-control w-100 ${errors.phone ? "is-invalid" : ""}`}
            placeholder="Enter Phone No."
            id="shipping_phone"
            value={state.phone}
            onChange={(e) => {
              handleInputOnChange(sfe.shipping_phone, e.target.value);
            }}
          />
          {errors.phone && (
            <div className="invalid-feedback">{errors.phone}</div>
          )}
        </div>

        <div className="col-lg-6 form-group">
          <label
            htmlFor="shipping_email"
            className=" form-label fw-bold required"
          >
            Email
          </label>

          <input
            className={`form-control w-100 ${errors.email ? "is-invalid" : ""}`}
            placeholder="Enter Email Address"
            id="shipping_email"
            type="email"
            value={state.email}
            onChange={(e) => {
              handleInputOnChange(sfe.shipping_email, e.target.value);
            }}
          />
          {errors.email && (
            <div className="invalid-feedback">{errors.email}</div>
          )}
        </div>

        <div className="col-12 form-group">
          <div className="custom-control position-static custom-checkbox">
            <input
              type="checkbox"
              id="tnc"
              className="custom-control-input cursor-pointer"
              checked={tnc}
              onChange={(e) => {
                const check = e.target.checked;
                setTnC(check);
                onTnCCheck(check);
              }}
            />
            <label
              htmlFor="tnc"
              className="custom-control-label cursor-pointer"
            >
              Read all the{" "}
              <b
                className="text-primary cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  downloadShippingTnC();
                }}
              >
                Terms and Conditions
              </b>
            </label>
          </div>

          <div className="custom-control custom-checkbox mt-2">
            <input
              className="custom-control-input cursor-pointer"
              type="checkbox"
              id="sameAsShipping"
              checked={sameAsShipping}
              onChange={(e) => {
                const value = e.target.checked;
                setSameAsShipping(value);
                // if (value) {
                onSameAsShipping(value, state);
                // }

                // setActive(e.target.checked ? "0" : "1");
              }}
            />
            <label
              htmlFor="sameAsShipping"
              className="custom-control-label cursor-pointer"
            >
              Save this as Billing Address
            </label>
          </div>
        </div>
      </form>
      {/* <pre>{JSON.stringify({ state, tnc, sameAsShipping }, null, 2)}</pre> */}
    </div>
  );
});

export default ShippingAddressForm;
