import { configureStore } from "@reduxjs/toolkit";
import categoryReducer from "./categorySlice";
import checkoutReducer from "./checkoutSlice";
import addressRouter from "./savedAddress";

export const store = configureStore({
  reducer: {
    category: categoryReducer,
    checkout: checkoutReducer,
    address: addressRouter
  }
});
