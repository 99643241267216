import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLanguage } from "react-switch-lang";
import {
  fetchCategoriesAsync,
  selectProductCategories,
} from "../store/categorySlice";
import { BASE_URL } from "../constant";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export default function ProductTable({
  cartItems = [],
  setCartItems,
  onDataChange,
}) {
  const dispatch = useDispatch();
  const productCategoies = useSelector(selectProductCategories);

  useEffect(() => {
    dispatch(fetchCategoriesAsync());
  }, []);

  async function handleQuantityChange(product_id, newQuantity) {
    // console.log(product_id, newQuantity);
    const user_id = localStorage.getItem("user_id");
    try {
      const addUrl = `${BASE_URL}cart/addProductToCart/${product_id}/${newQuantity}/${user_id}`;
      const addReqData = await (await fetch(addUrl)).json();

      if (addReqData.status === "true") {
        const updatedListUrl = `${BASE_URL}cart/getAllMyCartData/${user_id}/${getLanguage()}`;
        const updatedListReqData = await (await fetch(updatedListUrl)).json();
        setCartItems(updatedListReqData);
        toast("" + addReqData?.message, {
          containerId: "B",
          type: toast.TYPE.SUCCESS,
        });
        onDataChange();
      } else {
        toast("" + addReqData?.message, {
          containerId: "B",
          type: toast.TYPE.ERROR,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleRemoveProduct(product_id) {
    const user_id = localStorage.getItem("user_id");

    let are_you_sure = "Are you sure?";
    let revert = "Do you want to remove the item from the cart ?";
    let deleteConfirm = "Yes, delete it!";
    let cancel = "Cancel";
    if (getLanguage() == "ma") {
      are_you_sure = "तुम्हाला खात्री आहे?";
      revert = "आपण कार्टमधून वस्तू/उपक्रम काढून टाकू इच्छिता ?";
      deleteConfirm = "होय, काढून टाका";
      cancel = "नाही";
    }

    try {
      const result = await Swal.fire({
        title: are_you_sure,
        text: revert,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: cancel,
        confirmButtonText: deleteConfirm,
      });

      if (!result.value) return null;

      const removeUrl = `${BASE_URL}cart/removeProductFromCart/${product_id}/${user_id}`;
      const removeReqData = await (await fetch(removeUrl)).json();
      if (removeReqData?.status) {
        toast("" + removeReqData?.message, {
          containerId: "B",
          type: toast.TYPE.SUCCESS,
        });
        const productListUrl = `${BASE_URL}cart/getAllMyCartData/${user_id}/${getLanguage()}`;
        const productReqData = await (await fetch(productListUrl)).json();
        setCartItems(productReqData);
        onDataChange();
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {cartItems?.length > 0 && (
        <>
          <div className="card rounded mb-2">
            <h4 className="d-block d-md-none card-header">
              <strong>Product Details</strong>
            </h4>
            <div class="d-none d-md-block text-white rounded-top bg-accent">
              <div className="row no-gutters align-items-end m-3">
                <div className="col-6 h6 mb-1">PRODUCT DETAILS</div>
                <div className="col-2 text-center">
                  <small>QUANTITIES</small>
                </div>
                <div className="col-3 text-center">
                  <small>AMOUNT</small>
                </div>
                <div className="col-1 p-0"></div>
              </div>
            </div>
            <div className="overflow-hidden">
              {cartItems?.map((item) => (
                <div className="row no-gutters border-bottom p-3">
                  <div className="col-12 col-md-6 mb-1">
                    <h5>{item?.title}</h5>
                    <small className="d-block">
                      {
                        productCategoies?.find((el) => el.id === item.type_id)
                          ?.title
                      }
                    </small>
                  </div>
                  <div className="col-4 col-md-2 d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => {
                        handleQuantityChange(item.product_id, -1);
                      }}
                      disabled={+item?.quantity === 1}
                    >
                      <i className="fas fa-minus"></i>
                    </button>
                    <span className="m-1 d-inline border rounded form-control text-monospace text-center">
                      {item.quantity}
                    </span>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => {
                        handleQuantityChange(item.product_id, 1);
                      }}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </div>
                  <div className="col-6 col-md-3 d-flex justify-content-center align-items-center">
                    <h5>
                      <strong>₹ {+item?.price}</strong>
                    </h5>
                  </div>
                  <div className="col-2 order-4 col-md-1 d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => {
                        handleRemoveProduct(item?.product_id);
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="add-more-div mb-4">
            <Link to="/products" className="btn btn-outline-success w-100">
              <i className="fas fa-plus me-4"></i>
              <span className="ms-4 ml-4">Add More Products</span>
            </Link>
          </div>
        </>
      )}
    </>
  );
}
