import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/images/logo.png';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import Header from './Header.js';
import Footer from './Footer.js';
import { Editor } from 'react-draft-wysiwyg';
import $ from 'jquery';
import { Base64 } from 'js-base64';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Helmet } from "react-helmet";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import 'react-image-lightbox/style.css';

// Translation Higher Order Component
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,

} from 'reactstrap';
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage, getLanguage,
  translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';


class Aboutus extends Component {

  constructor(props) {
    super(props);
    this.event_id = 0;
    this.state = {
      photoIndex: 0,
      startIndex: 0,
      lightboxOpen: false,
      share_current_image_url: '',
      posts: [],
      selected: '1',
      types: [],
      english_pdf_url: '',
      hindi_pdf_url: '',
      marathi_pdf_url: '',
      selected: 1,
      scheduleEvent: [],
      eventtopic: [],
      flag: 0,
      images: [],
      isOpen: false,
      review: [],
      feedback: [],
      arrVideo: [],
      images: [],
      cartEvent: [],
      arrMagazine: [],
      machineCartEvent: [],
      guestinfo: {
        social_description: '',
        share_image_url: '',
        pdfUrl: '',
        type_id: '',
        no_of_pages: '',
        total_like: '',
        total_review: '',
        title: '',
        author: '',
        quantity: '',
        price: '',
        description: '',
        images_array: [],
        arr_event_languages: [],
        current_event_type_id: '',
      },
      editorState: EditorState.createEmpty(),
    }
    this.handleChange = this.handleChange.bind(this);
    this.submitReview = this.submitReview.bind(this);
    this.submitLike = this.submitLike.bind(this);

    this.readMore = this.readMore.bind(this);
    this.readLess = this.readLess.bind(this);

    this.showMore = this.showMore.bind(this);
    this.showLess = this.showLess.bind(this);
    this.showallReview = this.showallReview.bind(this);

    this.showMoreFeedback = this.showMoreFeedback.bind(this);
    this.showLessFeedback = this.showLessFeedback.bind(this);
    this.changeURL = this.changeURL.bind(this);
    this.openGallery = this.openGallery.bind(this);

    this.openGalleryVideo = this.openGalleryVideo.bind(this);
    this.closeGallery = this.closeGallery.bind(this);
  }
  playVideo() {
    this.setState({ lightboxOpen: true });
  }
  openGalleryVideo(e) {

    $("#openGallery").css({ 'display': 'flex' });
    this.setState({ lightboxOpen: true });
    // var id = e.target.id;
    $('html,body').animate({
      scrollTop: 0
    }, 700);
    $('.slide-ico').css('display', 'none');
    this.state.photoIndex = e.target.id;
    $('#videoCloseIcon').css('display', 'inline');
    $('body').css('overflow', 'hidden');
    // $("#openGallery > div").css("z-index","99999");
    // $("#openGallery > div").css("z-index","99999 !important");
    // $( "#openGallery div" ).first().css("z-index","99999 !important");
    $('.fixed-top').css('z-index', '-9999999');
    $('.jss1').css('display', 'none !important');
    $('.jss1').attr("style", "display: none !important");
    //  setTimeout(function(){
    //    alert('hi');
    //     $('.ytp-large-play-button').click(); }, 3000);

    //alert('hi');
  }
  closeGallery() {
    this.setState({ lightboxOpen: false });
    $('#videoCloseIcon').css('display', 'none');
    $('.slide-ico').css('display', 'block');
    $('body').css('overflow', 'scroll');
    // $('.fixed-top').css('z-index','9999999');
    $('.fixed-top').css('z-index', '9999999');
    $('#openGallery').css('display', 'none');
  }
  changeURL() {
    var radioValue = $("input[name='gridRadios']:checked").val();
    var english_pdf_url = this.state.english_pdf_url;
    var hindi_pdf_url = this.state.hindi_pdf_url;
    var marathi_pdf_url = this.state.marathi_pdf_url;
    if (radioValue == 1) {
      window.open(marathi_pdf_url, '_blank');
    } else if (radioValue == 2) {
      window.open(hindi_pdf_url, '_blank');
    } else {
      window.open(english_pdf_url, '_blank');
    }


  }

  showallReview() {
    $('#ShowAllReviewDiv').css('display', 'none');
    const answer_array = this.props.location.pathname.split('/');
    fetch(myConstClass.BASE_URL + 'review/getNewsReviewFrontEnd/' + answer_array['2'] + '/200')
      .then(response => response.json())
      .then(response => this.setState({ review: response }))
      .catch(err => console.log(err))

  }


  showMore(e) {
    var review_id_all = e.target.id;
    var arr_review_id = review_id_all.split('_');
    var review_id = arr_review_id[1];
    $('#showMoreDiv_' + review_id).css('display', 'none');
    $('#showLessDiv_' + review_id).css('display', 'block');
  }

  showLess(e) {
    var review_id_all = e.target.id;
    var arr_review_id = review_id_all.split('_');
    var review_id = arr_review_id[1];
    $('#showMoreDiv_' + review_id).css('display', 'block');
    $('#showLessDiv_' + review_id).css('display', 'none');
  }

  showMoreFeedback(e) {
    var review_id_all = e.target.id;
    var arr_review_id = review_id_all.split('_');
    var review_id = arr_review_id[1];
    $('#showMoreFeedbackDiv_' + review_id).css('display', 'none');
    $('#showLessFeedbackDiv_' + review_id).css('display', 'block');
  }

  showLessFeedback(e) {
    var review_id_all = e.target.id;
    var arr_review_id = review_id_all.split('_');
    var review_id = arr_review_id[1];
    $('#showMoreFeedbackDiv_' + review_id).css('display', 'block');
    $('#showLessFeedbackDiv_' + review_id).css('display', 'none');
  }

  readMore(e) {
    // var product_id = e.target.id;
    $('#ReadMoreDiv').css('display', 'none');
    $('#ReadLessDiv').css('display', 'block');
  }

  readLess(e) {
    // var product_id = e.target.id;
    $('#ReadMoreDiv').css('display', 'block');
    $('#ReadLessDiv').css('display', 'none');
  }



  submitLike() {
    const answer_array = this.props.location.pathname.split('/');
    var user_id = localStorage.getItem('user_id');
    if (user_id != '' && user_id != null && user_id > 0) {

      fetch(myConstClass.BASE_URL + 'review/saveNewLikeFrontEnd/' + user_id + '/' + answer_array['2'])
        .then(response => response.json())
        .then(response => {
          this.setState({ eventinfo: response })
          var status = this.state.eventinfo.status;
          var message = this.state.eventinfo.message;
          if (status == 'true') {
            var productLikeCount = $('#productLikeCount').html();
            var productLikeCountNew = parseInt(productLikeCount) + 1;
            $('#productLikeCount').html(productLikeCountNew);
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            //setTimeout(this.props.history.push('/products'),1000);
          } else {
            toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
            //toast("Wow so easy !");
          }
        })
        .catch(err => console.log(err))
      // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});


    } else {
      //var error =  'You must have login for submitting review.';
      localStorage.removeItem('error');
      localStorage.setItem('error', 'You must have login for like product.');
      // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
      // this.props.history.push('/signin');
      setTimeout(this.props.history.push('/signin'), 1000);
    }

    //alert(user_review);
  }
  openGallery(e) {
    this.setState({ isOpen: true });
    // var id = e.target.id;
    this.state.photoIndex = e.target.id;
  }

  submitReview(e) {
    e.preventDefault();
    console.log('this.refs', $('#pform').serializeArray());
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
    const answer_array = this.props.location.pathname.split('/');
    var user_review_current = $('#user_review').val();
    var user_review = $.trim(user_review_current);
    //alert(user_review);
    var user_id = localStorage.getItem('user_id');
    if (user_id != '' && user_id != null && user_id > 0) {
      if (user_review != '') {
        var message = 'Your review added successfully,once admin approval will display it.';

        // fetch(myConstClass.BASE_URL+'review/saveEventReviewFrontEnd/'+user_id+'/'+answer_array['2']+'/'+user_review)
        fetch(myConstClass.BASE_URL + `review/saveNewsReviewFrontEnd/` + user_id + '/' + answer_array['2'], {
          method: 'POST',
          body: JSON.stringify($('#pform').serializeArray()),

        })
          .then(response => response.json())
          .then(response => {
            this.setState({ eventinfo: response })
            var status = this.state.eventinfo.status;
            var message = this.state.eventinfo.message;
            if (status == 'true') {
              toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
              //setTimeout(this.props.history.push('/products'),1000);
            } else {
              toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
              //toast("Wow so easy !");
            }
          })
          .catch(err => console.log(err))
        // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});

        $('#user_review').val('');
      } else {
        var message = "Please enter review.";
        toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
      }


    } else {
      //var error =  'You must have login for submitting review.';
      localStorage.removeItem('error');
      localStorage.setItem('error', 'You must have login for submitting review.');
      // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
      // this.props.history.push('/signin');
      setTimeout(this.props.history.push('/signin'), 1000);
    }

    //alert(user_review);
  }


  handleChange(event) {
    this.setState({ value: event.target.value });
  }


  componentDidMount() {
    console.log("Props : ", this.props)
    //   fetch(myConstClass.BASE_URL+'product/getAvailProdTypes')
    //   .then(response => response.json())
    //   .then(response =>this.setState({types: response}))
    //   .catch(err => console.log(err))

    var user_id = localStorage.getItem('user_id');
    const answer_array = this.props.location.pathname.split('/');
    // alert(answer_array);
    if (answer_array['2']) {

      fetch(myConstClass.BASE_URL + 'cms/getAboutUsInfoFrontEnd/' + answer_array['2'] + '/' + getLanguage())
        .then(response => response.json())
        .then(response => {
          this.setState({ guestinfo: response })

          // var desc = Base64.decode(this.state.guestinfo.description);
          this.state.guestinfo.description = this.state.guestinfo.description;
          this.state.guestinfo.pdfUrl = this.state.guestinfo.english_pdf_url;
          this.state.social_description = this.state.guestinfo.social_description;
          this.state.english_pdf_url = this.state.guestinfo.english_pdf_url;
          this.state.hindi_pdf_url = this.state.guestinfo.hindi_pdf_url;
          this.state.marathi_pdf_url = this.state.guestinfo.marathi_pdf_url;

        })
        .then(response => {
          this.setState({
            editorState: EditorState.createWithContent(
              convertFromRaw(JSON.parse(this.state.guestinfo.description))
            )
          })
        }).then(console.log('hi' + this.state.guestinfo.title))
        .catch(err => console.log(err))



    }




    fetch(myConstClass.BASE_URL + 'cms/getAboutUsImagesFrontEnd')
      .then(response => response.json())
      .then(response => {
        this.setState({ images: response })
        response.map((item, ii) => {
          // alert(item);
          if (ii == '0') {
            this.setState({ share_current_image_url: window.location.href });
            this.setState({ share_image_url: item.src });
            if (item.src) {
              $("meta[property='og\\:image']").attr("content", item.src);
            }

            // alert('asd'+this.state.share_image_url);
          }
        });
        var numRow = this.state.images.length;
        if (numRow < 2) {
          $('.prevImage').css('display', 'none')
        }

      })
      .catch(err => console.log(err))




    fetch(myConstClass.BASE_URL + 'cms/getAboutUsVideoFrontEnd')
      .then(response => response.json())
      .then(response => {
        this.setState({ arrVideo: response })
        var numRow = this.state.arrVideo.length;
        if (numRow < 2) {
          $('.prevVideo').css('display', 'none')
        }
        if (numRow > 0) {
          $('#carouselExampleControls1').css('display', 'inline');
        } else {
          $('#carouselExampleControls1').css('display', 'none');
        }
      })
      .catch(err => console.log(err))

    // var current_event_type_id =  this.state.guestinfo.current_event_type_id ;



  }


  componentDidUpdate(prevProps) {


    const answer_array = this.props.location.pathname.split('/');
    const { flag } = this.state
    var current_product_type = answer_array['2'];

    // $(".cmsClass").addClass("orangeColor");
    // $(".cmsClass").removeClass("aboutUSCMS");

    // $(".cmsClass").css('font-size', '16px');
    // $('#' + current_product_type).css('color', 'black');
    // $('#' + current_product_type).addClass("aboutUSCMS");
    // $('#' + current_product_type).removeClass("orangeColor");
    console.log($('#' + current_product_type))
    //$('#'+current_product_type).css('font-size','16px');

    var old_product_type = localStorage.getItem('old_product_type');
    localStorage.setItem('old_product_type', current_product_type);

    //alert(old_product_type);
    if (old_product_type != '' && current_product_type != old_product_type && old_product_type != null && old_product_type > 0) {
      this.setState({ flag: 1 });
      //alert('hi');
    } else {
      var current_language = localStorage.getItem('current_language');

      if (current_language != getLanguage() && flag != 1 && current_language != null) {
        //alert(current_language);
        localStorage.removeItem('current_language');
        localStorage.setItem('current_language', getLanguage());
        this.setState({ flag: 1 });
      }
    }

    var user_id = localStorage.getItem('user_id');

    if (getLanguage() == 'ma' && flag == 1) {
      this.setState({ flag: 0 })

      fetch(myConstClass.BASE_URL + 'cms/getAboutUsInfoFrontEnd/' + answer_array['2'] + '/' + getLanguage())
        .then(response => response.json())
        .then(response => {
          this.setState({ guestinfo: response })

          // var desc = Base64.decode(this.state.guestinfo.description);
          this.state.guestinfo.description = this.state.guestinfo.description;
          this.state.social_description = this.state.guestinfo.social_description;
        })
        .then(response => {
          this.setState({
            editorState: EditorState.createWithContent(
              convertFromRaw(JSON.parse(this.state.guestinfo.description))
            )
          })
        }).then(console.log('hi' + this.state.guestinfo.title))
        .catch(err => console.log(err))


    }

    if (getLanguage() == 'en' && (flag == 1)) {

      this.setState({ flag: 0 })

      fetch(myConstClass.BASE_URL + 'cms/getAboutUsInfoFrontEnd/' + answer_array['2'] + '/' + getLanguage())
        .then(response => response.json())
        .then(response => {
          this.setState({ guestinfo: response })

          // var desc = Base64.decode(this.state.guestinfo.description);
          this.state.guestinfo.description = this.state.guestinfo.description;
          this.state.social_description = this.state.guestinfo.social_description;


        })
        .then(response => {
          this.setState({
            editorState: EditorState.createWithContent(
              convertFromRaw(JSON.parse(this.state.guestinfo.description))
            )
          })
        }).then(console.log('hi' + this.state.guestinfo.title))
        .catch(err => console.log(err))


    }
  }


  render() {

    const { photoIndex, lightboxOpen, isOpen, selected, arrMagazine, cartEvent, machineCartEvent, images, arrVideo, feedback, review, scheduleEvent, posts, guestinfo, eventtopic } = this.state
    const { t } = this.props;
    return (

      <div className="App">
        <i style={{ cursor: 'pointer', display: 'none' }} onClick={this.closeGallery} className="fa fa-times" aria-hidden="true" id="videoCloseIcon2"></i>

        <div>

          <ToastContainer />
        </div>
        <Header />
        <div className="container-fluid">

          <div className="row">
            <div className="col-lg-9 ">
              <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                <nav aria-label="breadcrumb" id="breadcrumb">
                  <ol className="breadcrumb bg-white mb-0 ml-n2">
                    <li className="breadcrumb-item"> <Link to="/">{t('home.home')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"> <Link to="/about-us/6">{t('home.About Us')}</Link></li>
                  </ol>
                </nav>
              </div>
              <div className="d-xl-block d-lg-block d-md-block d-none">
                <div className="row">
                  <div className="col site_map" id="about_nav_bar">
                    <ul style={{ paddingInlineStart: '0px !important' }}>

                      <li style={{ cursor: 'pointer' }} >
                        <Link style={{ color: 'black' }} className={this.props.location.pathname.split('/')[2] === '6' ? "aboutUSCMS cmsClass" : "orangeColor cmsClass font-weight-bold"} to="/about-us/6" id="6">
                          {t('home.ABOUT US')}
                        </Link>
                      </li>

                      <li style={{ cursor: 'pointer' }} className="chlid">
                        <Link
                          className={this.props.location.pathname.split('/')[2] === '1' ? "aboutUSCMS cmsClass" : "orangeColor cmsClass font-weight-bold"}

                          to="/about-us/1"
                          id="1">
                          {t('home.VISION & MISSION')}
                        </Link>
                      </li>

                      <li style={{ cursor: 'pointer' }} className="chlid">
                        <Link
                          className={this.props.location.pathname.split('/')[2] === '2' ? "aboutUSCMS cmsClass" : "orangeColor cmsClass font-weight-bold"}
                          to="/about-us/2"
                          id="2">
                          {t('home.FIRST THINKER')}
                        </Link>
                      </li>

                      <li style={{ cursor: 'pointer' }} className="chlid">
                        <Link
                          className={this.props.location.pathname.split('/')[2] === '8' ? "aboutUSCMS cmsClass" : "orangeColor cmsClass font-weight-bold"}
                          to="/about-us/8"
                          id="8">
                          {t('home.NEW WAY PHILOSOPY')}
                        </Link>
                      </li>

                      <li style={{ cursor: 'pointer' }} className="chlid">
                        <Link
                          className={this.props.location.pathname.split('/')[2] === '3' ? "aboutUSCMS cmsClass" : "orangeColor cmsClass font-weight-bold"}
                          to="/about-us/3"
                          id="3">
                          {t('home.VALUES')}
                        </Link>
                      </li>

                      <li style={{ cursor: 'pointer' }} className="chlid">
                        <Link
                          className={this.props.location.pathname.split('/')[2] === '4' ? "aboutUSCMS cmsClass" : "orangeColor cmsClass font-weight-bold"}
                          to="/about-us/4"
                          id="4">
                          {t('home.WORK AREAS')}
                        </Link>
                      </li>

                      <li style={{ cursor: 'pointer' }} className="chlid">
                        <Link
                          className={this.props.location.pathname.split('/')[2] === '5' ? "aboutUSCMS cmsClass" : "orangeColor cmsClass font-weight-bold"}
                          to="/about-us/5"
                          id="5">
                          {t('home.COMMENTS OF WORLD THINKERS')}
                        </Link>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid" id="text_style">
          <div className="d-xl-block d-lg-block d-md-block d-none">
            <div className="row my-2 mt-3">
              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <div className="row" style={{ marginLeft: '1px' }}>
                  <div className="mb-4 col-lg-12 col-md-5 col-sm-5">

                    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                      <div className="carousel-inner">
                        {images.map((info, ii) => {
                          if (ii == 0) {
                            // return( <div className="carousel-item active"> <img className="d-block w-100 img-fluid" src={myConstClass.IMAGE_URL+'event/'+info.image_name}  /></div>
                            return (
                              <div className="carousel-item active ">
                                <img id={ii} onClick={this.openGallery} className="d-block w-100 img-fluid-responsive aboutUsImageStyle1" src={info.src} />
                              </div>

                            )
                          } else {
                            return (
                              <div className="carousel-item ">

                                <img style={{ cursor: 'pointer' }} id={ii} onClick={this.openGallery} className="d-block w-100 img-fluid-responsive aboutUsImageStyle1" src={info.src} />

                              </div>
                            )
                          }
                        }

                        )}

                      </div>
                      <a className="carousel-control-prev prevImage" style={{ opacity: 1 }} href="#carouselExampleControls" role="button" data-slide="prev">
                        <i className="fas fa-chevron-left slide-ico"></i>
                        <span className="sr-only">{t('home.Previous')}</span>
                      </a>
                      <a className="carousel-control-next prevImage" style={{ opacity: 1 }} href="#carouselExampleControls" role="button" data-slide="next">
                        <i className="fas fa-chevron-right slide-ico"></i>
                        <span className="sr-only">{t('home.Next')}</span>
                      </a>
                    </div>


                  </div>
                  <div className="mb-4 col-lg-12 col-md-5 col-sm-5">

                    <div id="carouselExampleControls1" className="carousel slide" data-ride="carousel">
                      <div className="carousel-inner">

                        {arrVideo.map((info, ii) => {
                          if (ii == 0) {
                            return (
                              <div className="carousel-item active wrapper">


                                <img style={{ cursor: 'pointer' }} id={ii} onClick={this.openGalleryVideo} src={info.you_tube_url} className="d-block w-100 img-fluid-responsive aboutUsImageStyle1" alt="..." />
                                <div className="overlay">
                                  <a href="#" className="icon" title="User Profile">
                                    <i className="fab fa-youtube color-red" onClick={this.openGalleryVideo}></i>
                                  </a>
                                </div>



                              </div>
                            )
                          } else {
                            // return( <div className="carousel-item "> <img className="d-block w-100 img-fluid" src={myConstClass.IMAGE_URL+'event/'+info.image_name}  /></div>
                            return (
                              <div className="carousel-item wrapper">


                                <img style={{ cursor: 'pointer' }} onClick={this.openGalleryVideo} src={info.you_tube_url} className="d-block w-100 img-fluid-responsive aboutUsImageStyle1" alt="..." />
                                <div className="overlay">
                                  <a href="#" className="icon" title="User Profile">
                                    <i className="fab fa-youtube color-red" onClick={this.openGalleryVideo}></i>
                                  </a>
                                </div>



                              </div>
                            )
                          }
                        }

                        )}
                        <a className="carousel-control-prev mx-2" style={{ opacity: 1 }} href="#carouselExampleControls1" role="button" data-slide="prev">
                          <i className="fas fa-chevron-left slide-ico"></i>
                          <span className="sr-only">{t('home.Previous')}</span>
                        </a>
                        <a className="carousel-control-next mx-2" style={{ opacity: 1 }} href="#carouselExampleControls1" role="button" data-slide="next">
                          <i className="fas fa-chevron-right slide-ico"></i>
                          <span className="sr-only">{t('home.Next')}</span>
                        </a>
                      </div>

                    </div>

                  </div>

                </div>
              </div>

              <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 ulPaddingForDescription" id="container">
                {ReactHtmlParser(guestinfo.description)}
              </div>

              <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 " style={{ marginTop: '-10px' }}>

                <div className="row">
                  <div className="p-3">
                    <div className="col border border-dark bg-white  mb-3">

                      <h5 className="font-weight-bolder ml-2 pt-3">{t('home.Manashakti Information Booklet')} </h5>

                      <div className="row">
                        <p className="pb-1 pt-1 mx-3 ">
                          {t('home.about_us_desc')}

                        </p>
                      </div>

                      <div className="row">
                        <fieldset className="form-group mx-3">
                          <div className="row">

                            <div className="col-md-4 col-sm-4 col-xs-4">
                              <div className="form-check">


                                <input value="1" className="form-check-input check_box defaultMarathi" type="radio" name="gridRadios" id="gridRadios1" checked={(this.state.selected == '1')} onChange={(e) => this.setState({ selected: e.target.value })} />
                                <label className="form-check-label" for="gridRadios1">
                                  {t('home.Marathi')}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-4">
                              <div className="form-check">
                                <input value="2" className="form-check-input check_box " type="radio" name="gridRadios" id="gridRadios2" checked={(this.state.selected == '2')} onChange={(e) => this.setState({ selected: e.target.value })} />
                                <label className="form-check-label" for="gridRadios2">

                                  {t('home.Hindi')}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-4">
                              <div className="form-check">
                                <input className="form-check-input check_box " type="radio" name="gridRadios" id="gridRadios3" value="3" checked={(this.state.selected == '3')} onChange={(e) => this.setState({ selected: e.target.value })} />
                                <label className="form-check-label" for="gridRadios3">
                                  {t('home.English')}
                                </label>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>

                      <div className="row">
                        {/* {guestinfo.pdfUrl} */}
                        {/* <a style={{cursor:'pointer'}} className="btn btn-brown btn-sm mb-2 pdf_button" href={guestinfo.pdfUrl} target="_blank">{t('home.DOWNLOAD PDF')}</a> */}
                        <button onClick={this.changeURL} className="btn btn-brown btn-sm mb-2 pdf_button ">{t('home.DOWNLOAD PDF')}</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div className="d-xl-none d-lg-none d-md-none d-block">
            <h5 className="text-uppercase">
              {
                this.props.location.pathname === "/about-us/1" ?
                  t('home.VISION & MISSION')
                  : (null)
              }
              {
                this.props.location.pathname === "/about-us/2" ?
                  t('home.FIRST THINKER')
                  : (null)
              }
              {
                this.props.location.pathname === "/about-us/8" ?
                  t('home.NEW WAY PHILOSOPY')
                  : (null)
              }
              {
                this.props.location.pathname === "/about-us/3" ?
                  t('home.VALUES')
                  : (null)
              }
              {
                this.props.location.pathname === "/about-us/4" ?
                  t('home.WORK AREAS')
                  : (null)
              }
              {
                this.props.location.pathname === "/about-us/5" ?
                  t('home.COMMENTS OF WORLD THINKERS')
                  : (null)
              }
            </h5>

            {/* Images and Videos */}
            <div className="row my-3">
              <div className="col-12">

                <div id="carouselExampleControlsM" className="carousel slide" data-ride="carousel">
                  <div className="carousel-inner">
                    {images.map((info, ii) => {
                      if (ii == 0) {
                        return (
                          <div className="carousel-item active ">
                            <img id={ii} onClick={this.openGallery} className="d-block w-100 img-fluid-responsive aboutUsImageStyle1" src={info.src} />
                          </div>
                        )
                      } else {

                        return (
                          <div className="carousel-item ">
                            <img id={ii} onClick={this.openGallery} className="d-block w-100 img-fluid-responsive aboutUsImageStyle1" src={info.src} />
                          </div>
                        )
                      }
                    }

                    )}
                  </div>
                  <a className="carousel-control-prev prevImage mx-2" style={{ opacity: 1 }} href="#carouselExampleControlsM" role="button" data-slide="prev">
                    <i className="fas fa-chevron-left slide-ico"></i>
                    <span className="sr-only">{t('home.Previous')}</span>
                  </a>
                  <a className="carousel-control-next prevImage mx-2" style={{ opacity: 1 }} href="#carouselExampleControlsM" role="button" data-slide="next">
                    <i className="fas fa-chevron-right slide-ico"></i>
                    <span className="sr-only">{t('home.Next')}</span>
                  </a>
                </div>
              </div>

              <div className="col-12 mt-4">

                <div id="carouselExampleControls1M" className="carousel slide" data-ride="carousel">
                  <div className="carousel-inner">

                    {arrVideo.map((info, ii) => {
                      if (ii == 0) {
                        // return( <div className="carousel-item active"> <img className="d-block w-100 img-fluid" src={myConstClass.IMAGE_URL+'event/'+info.image_name}  /></div>
                        return (
                          <div className="carousel-item active wrapper">
                            <img style={{ cursor: 'pointer' }} id={ii} onClick={this.openGalleryVideo} src={info.you_tube_url} className="d-block w-100 img-fluid-responsive aboutUsImageStyle1" alt="..." />
                            <div className="overlay">
                              <a href="#" className="icon" title="User Profile">
                                <i className="fab fa-youtube color-red" onClick={this.openGalleryVideo}></i>
                              </a>
                            </div>
                          </div>
                        )
                      } else {
                        return (
                          <div className="carousel-item wrapper">
                            <img style={{ cursor: 'pointer' }} onClick={this.openGalleryVideo} src={info.you_tube_url} className="d-block w-100 img-fluid-responsive aboutUsImageStyle1" alt="..." />
                            <div className="overlay">
                              <a href="#" className="icon" title="User Profile">
                                <i className="fab fa-youtube color-red" onClick={this.openGalleryVideo}></i>
                              </a>
                            </div>
                          </div>
                        )
                      }
                    }

                    )}
                    <a className="carousel-control-prev mx-3" style={{ opacity: 1 }} href="#carouselExampleControls1M" role="button" data-slide="prev">
                      <i className="fas fa-chevron-left slide-ico"></i>
                      <span className="sr-only">{t('home.Previous')}</span>
                    </a>
                    <a className="carousel-control-next mx-3" style={{ opacity: 1 }} href="#carouselExampleControls1M" role="button" data-slide="next">
                      <i className="fas fa-chevron-right slide-ico"></i>
                      <span className="sr-only">{t('home.Next')}</span>
                    </a>
                  </div>

                </div>
              </div>


            </div>

            <div className="row my-2">
              <div className="col px-4">
                {ReactHtmlParser(guestinfo.description)}
              </div>
            </div>

            <div className={this.props.location.pathname.split("/")[2] === '6' ? "d-block" : "d-none"}>
              <Row className="px-1">
                <Col sm="12" xs="12" className="bg-light py-2 border mb-2">
                  <Link className="orangeColor cmsClass" to="/about-us/1">
                    <div className="float-left text-dark">
                      {t('home.VISION & MISSION')}
                    </div>
                    <div className="float-right text-dark">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </div>
                  </Link>
                </Col>
                <Col sm="12" xs="12" className="bg-light py-2 border mb-2">
                  <Link className="orangeColor cmsClass" to="/about-us/2">
                    <div className="float-left text-dark">
                      {t('home.FIRST THINKER')}
                    </div>
                    <div className="float-right text-dark">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </div>
                  </Link>
                </Col>
                <Col sm="12" xs="12" className="bg-light py-2 border mb-2">
                  <Link className="orangeColor cmsClass" to="/about-us/8">
                    <div className="float-left text-dark">
                      {t('home.NEW WAY PHILOSOPY')}
                    </div>
                    <div className="float-right text-dark">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </div>
                  </Link>
                </Col>
                <Col sm="12" xs="12" className="bg-light py-2 border mb-2">
                  <Link className="orangeColor cmsClass" to="/about-us/3">
                    <div className="float-left text-dark">
                      {t('home.VALUES')}
                    </div>
                    <div className="float-right text-dark">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </div>
                  </Link>
                </Col>
                <Col sm="12" xs="12" className="bg-light py-2 border mb-2">
                  <Link className="orangeColor cmsClass" to="/about-us/4">
                    <div className="float-left text-dark">
                      {t('home.WORK AREAS')}
                    </div>
                    <div className="float-right text-dark">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </div>
                  </Link>
                </Col>
                <Col sm="12" xs="12" className="bg-light py-2 border mb-2">
                  <Link className="orangeColor cmsClass" to="/about-us/5">
                    <div className="float-left text-dark">
                      {t('home.COMMENTS OF WORLD THINKERS')}
                    </div>
                    <div className="float-right text-dark">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </div>
                  </Link>
                </Col>

              </Row>

            </div>

            <div className="row">
              <div className="p-3">
                <div className="col border border-dark bg-white  mb-3">

                  <h5 className="font-weight-bolder ml-2 pt-3">{t('home.Manashakti Information Booklet')} </h5>

                  <div className="row">
                    <p className="pb-1 pt-1 mx-3 ">
                      {t('home.about_us_desc')}

                    </p>
                  </div>

                  <div className="row">
                    <fieldset className="form-group mx-3">
                      <div className="row">

                        <div className="col-md-4 col-sm-4 col-xs-4">
                          <div className="form-check">


                            <input value="1" className="form-check-input check_box " type="radio" name="gridRadios_m" id="gridRadios1_m" checked={(this.state.selected == '1')} onChange={(e) => this.setState({ selected: e.target.value })} />
                            <label className="form-check-label" for="gridRadios1_m">
                              {t('home.Marathi')}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-4">
                          <div className="form-check">
                            <input value="2" className="form-check-input check_box " type="radio" name="gridRadios_m" id="gridRadios2_m" checked={(this.state.selected == '2')} onChange={(e) => this.setState({ selected: e.target.value })} />
                            <label className="form-check-label" for="gridRadios2_m">

                              {t('home.Hindi')}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-4">
                          <div className="form-check">
                            <input className="form-check-input check_box " type="radio" name="gridRadios_m" id="gridRadios3_m" value="3" checked={(this.state.selected == '3')} onChange={(e) => this.setState({ selected: e.target.value })} />
                            <label className="form-check-label" for="gridRadios3_m">
                              {t('home.English')}
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className="row">
                    <button onClick={this.changeURL} className="btn btn-brown btn-sm mb-2 pdf_button ">{t('home.DOWNLOAD PDF')}</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div >


        <Footer share_image_url={this.state.share_image_url} description={guestinfo.social_description} share_current_image_url={this.state.share_current_image_url} />
        {
          isOpen && (
            <Lightbox
              mainSrc={this.state.images[photoIndex]['image_url']}
              nextSrc={this.state.images[(photoIndex + 1) % this.state.images.length]['image_url']}
              prevSrc={this.state.images[(photoIndex + this.state.images.length - 1) % this.state.images.length]['image_url']}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + this.state.images.length - 1) % this.state.images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % this.state.images.length,
                })
              }
            />
          )
        }


        <div id="openGallery" className="openGallerydiv" >
          <span ><i style={{ cursor: 'pointer' }} onClick={this.closeGallery} class="fa fa-times" aria-hidden="true" id="videoCloseIcon"></i></span>;

          {lightboxOpen && (
            <ReactImageVideoLightbox

              data={this.state.arrVideo}
              startIndex={this.state.startIndex}
              showResourceCount={true}
              onCloseCallback={this.callbackFunction} />
          )}

        </div>
      </div >
    );
  }
}
Aboutus.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(Aboutus);
